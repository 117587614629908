import * as EX from '@core/extension';

import { faFile as faFileRegular } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faFile } from '@fortawesome/pro-solid-svg-icons/faFile';
import { faPaintBrush } from '@fortawesome/pro-solid-svg-icons/faPaintBrush';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faThList } from '@fortawesome/pro-solid-svg-icons/faThList';

export const COMMANDS = {
  addPage: 'add:page',
  addSubpage: 'add:subpage',
  showHubSettings: 'show:hub:settings',
  showPageSettings: 'show:page:settings',
  showTheme: 'show:theme',
};

export const MENUS = {
  actions: EX.EDITOR_MENUS.toolbar.primary.concat(['actions']),
  settings: EX.EDITOR_MENUS.toolbar.primary,
};

export class HubExtension extends EX.Spec implements EX.ISpec {
  public readonly id: string = '.hub';
  public readonly name: string = 'Hub';
  public readonly longName: string = 'Documentation Hub';
  public readonly icon: EX.IIcon = faBook;

  public get supportedModes() {
    return [
      this.editor.supportedModes.read,
      this.editor.supportedModes.design,
      this.editor.supportedModes.code,
    ];
  }

  public registerCommands = () => {
    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.addPage,
        label: 'Add Page',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.addSubpage,
        label: 'Add Subpage',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.showTheme,
        label: 'Show Theme',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.showHubSettings,
        label: 'Show Hub Settings',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.showPageSettings,
        label: 'Show Page Settings',
      })
    );
  };

  public registerMenus = ({ mode }: EX.IModeChangedEvent) => {
    switch (mode) {
      case EX.EDITOR_MODES.read:
        break;
      case EX.EDITOR_MODES.design:
      case EX.EDITOR_MODES.code:
        this._registerMenuActions();
        this._registerMenuPageSettings();
        this._registerMenuTheme();
        this._registerMenuSettings();
        break;
      default:
    }
  };

  private _registerMenuActions = () => {
    this.menus.push(
      this.menuRegistry.registerSubmenu(MENUS.actions, {
        label: 'Add',
        icon: faPlus,
        color: EX.UI.colors.purple,
      })
    );

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.actions, {
        id: COMMANDS.addPage,
        commandId: COMMANDS.addPage,
        label: 'Page',
        description: 'Add a new top level page.',
        icon: faFile,
      })
    );

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.actions, {
        id: COMMANDS.addSubpage,
        commandId: COMMANDS.addSubpage,
        label: 'Subpage',
        description: 'Add a new subpage, nested under the current page.',
        icon: faFileRegular,
      })
    );
  };

  private _registerMenuPageSettings = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.settings, {
        id: COMMANDS.showPageSettings,
        commandId: COMMANDS.showPageSettings,
        label: 'Table of Contents',
        icon: faThList,
      })
    );
  };

  private _registerMenuTheme = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.settings, {
        id: COMMANDS.showTheme,
        commandId: COMMANDS.showTheme,
        label: 'Theme',
        icon: faPaintBrush,
      })
    );
  };

  private _registerMenuSettings = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.settings, {
        id: COMMANDS.showHubSettings,
        commandId: COMMANDS.showHubSettings,
        label: 'Settings',
        icon: faCog,
      })
    );
  };
}
