import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { faHeading } from '@fortawesome/pro-solid-svg-icons/faHeading';
import { faIndustry } from '@fortawesome/pro-solid-svg-icons/faIndustry';
import { faEyeDropper } from '@fortawesome/pro-solid-svg-icons/faEyeDropper';
import { faPaintBrush } from '@fortawesome/pro-solid-svg-icons/faPaintBrush';

import ColorPicker from '@platform/components/ColorPicker';
import FeatureBadge from '@platform/components/FeatureBadge';
import SimpleSection from '@platform/components/SimpleSection';

import { Button, Icon } from '@core/ui';

import { checkIsWhite } from '@platform/utils/colors';
import { defaultHubTheme } from '@platform/utils/hubs';
import { hasSubscriptionFeature } from '@platform/utils/billing';
import { textures, textColors, backgroundColors } from '@platform/utils/hubs';

const Circle = props => {
  const { className, backgroundColor, isActive, icon, onClick } = props;

  const isWhite = checkIsWhite(backgroundColor);

  const outerStyle = {
    padding: 4,
  };
  if (isActive && !isWhite) {
    outerStyle.borderColor = backgroundColor;
  }

  const innerStyle = {
    backgroundColor,
  };

  return (
    <div
      className={cn('cursor-pointer rounded-full border-2', className, {
        'border-darken': isActive && isWhite,
        'border-transparent': !isActive,
      })}
      style={outerStyle}
      onClick={onClick}
    >
      <div
        className={cn('rounded-full h-12 w-12', {
          [`flex items-center justify-center text-${isWhite ? 'darken' : 'white'}`]: !!icon,
          'border border-darken': isWhite,
        })}
        style={innerStyle}
      >
        {icon && <Icon icon={icon} />}
      </div>
    </div>
  );
};

const Bar = props => {
  const { className, backgroundColor, backgroundImage, isActive, onClick } = props;

  const isWhite = !backgroundColor || checkIsWhite(backgroundColor);

  const outerStyle = {};

  if (isActive && !isWhite) {
    outerStyle.borderColor = backgroundColor;
  }

  const innerStyle = {
    backgroundColor,
  };

  if (backgroundImage) {
    innerStyle.backgroundImage = `url(${backgroundImage})`;
  }

  return (
    <div
      className={cn('cursor-pointer w-full rounded-lg p-1 border-2', className, {
        'border-darken': isActive && isWhite,
        'border-transparent my-2': !isActive,
      })}
      style={outerStyle}
      onClick={onClick}
    >
      <div
        className={cn('rounded-lg h-12', {
          'border border-darken': isWhite,
        })}
        style={innerStyle}
      />
    </div>
  );
};

class HubTheme extends React.Component {
  getTheme = () => {
    const { store } = this.props;

    return Object.assign({}, defaultHubTheme, _.get(store.parsed, 'theme'));
  };

  renderTexture = (texture, index) => {
    const { store } = this.props;
    const theme = this.getTheme();
    const isActive = theme.texture === texture;

    return (
      <Bar
        key={index}
        isActive={isActive}
        backgroundColor={theme.background}
        backgroundImage={texture}
        onClick={() => store.updateParsed('set', 'theme.texture', texture)}
      />
    );
  };

  renderColorSection = ({ path, title, icon, colors }) => {
    const { store, hasFeature, namespace } = this.props;

    const theme = this.getTheme();
    const currentColor = theme[path];
    const customColor = !_.includes(colors, currentColor) && currentColor;

    return (
      <SimpleSection
        className="mb-12"
        title={title}
        icon={icon}
        actions={<FeatureBadge feature="theme" show={!hasFeature} namespace={namespace} />}
      >
        <div className="flex items-center justify-around flex-wrap -mx-4">
          {_.map(colors, (color, index) => {
            const isActive = currentColor === color;

            return (
              <Circle
                key={index}
                isActive={isActive}
                backgroundColor={color}
                onClick={() => store.updateParsed('set', ['theme', path], color)}
              />
            );
          })}

          <ColorPicker
            color={customColor}
            onChangeComplete={({ hex }) => store.updateParsed('set', ['theme', path], hex)}
            trigger={
              customColor ? (
                <Circle backgroundColor={customColor} icon={faEyeDropper} isActive />
              ) : (
                <div className="h-12 w-12 m-1 flex items-center justify-around">
                  <Button className="h-full w-full" icon={faEyeDropper} rounded />
                </div>
              )
            }
          />
        </div>
      </SimpleSection>
    );
  };

  render() {
    const { className, hasFeature, namespace } = this.props;

    return (
      <div className={className}>
        {this.renderColorSection({
          path: 'primary',
          title: 'Primary Color',
          icon: faPaintBrush,
          colors: textColors,
        })}

        {this.renderColorSection({
          path: 'secondary',
          title: 'Secondary Color',
          icon: faPaintBrush,
          colors: textColors,
        })}

        {this.renderColorSection({
          path: 'background',
          title: 'Background',
          icon: faHeading,
          colors: backgroundColors,
        })}

        <div className="border mb-12" />

        <SimpleSection
          className="mb-12"
          title="Texture"
          icon={faIndustry}
          actions={<FeatureBadge feature="theme" show={!hasFeature} namespace={namespace} />}
        >
          <div>{_.map(textures, this.renderTexture)}</div>
        </SimpleSection>
      </div>
    );
  }
}

export default inject(({ appStore, hubEditorStore, namespaceService }) => {
  const store = hubEditorStore.activeEditor;

  const namespace = namespaceService.current || {};
  const hasFeature = hasSubscriptionFeature(namespace, 'theme');

  return {
    store,
    namespace,
    hasFeature,
  };
})(observer(HubTheme));
