import React from 'react';
import _ from 'lodash';
import { Button, Message, Segment, Header, Form, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import FormFileButton from '@platform/components/FormFileButton';
import ErrorMessage from '@platform/components/ErrorMessage';

import { alert } from '@platform/utils/alert';

import OrgSettingsLayout from './_layout';

class OrgSettings extends React.Component {
  componentWillMount() {
    this.updateUiOrg(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateUiOrg(nextProps);
  }

  updateUiOrg = props => {
    const { ui, updateUi, orgService } = props;
    const org = orgService.current;

    if (!ui.org && !_.isEmpty(org)) {
      updateUi('set', 'org', _.cloneDeep(org));
    }
  };

  render() {
    const { ui, updateUi, orgService } = this.props;

    const org = ui.org || {};
    const original = orgService.current;
    const orgChanged = !_.isEqual(_.omit(org, 'avatar_url'), _.omit(original, 'avatar_url'));

    return (
      <OrgSettingsLayout activeTab="options">
        <div className="mb-6">
          <Segment attached="bottom">
            <Header as="h3" className="mb-3" dividing>
              Basic Info
            </Header>

            <ErrorMessage
              className="mb-6"
              error={ui.basicInfoError}
              onDismiss={() => updateUi('set', 'basicInfoError', null)}
            />

            <Form loading={ui._savingForm1}>
              <Form.Input
                label="Name"
                name="name"
                placeholder="Name"
                value={org.name || ''}
                onChange={(e, { value }) => updateUi('set', 'org.name', value)}
              />

              <Form.Input
                label="Path"
                name="path"
                placeholder="path"
                value={org.path || ''}
                onChange={(e, { value }) => updateUi('set', 'org.path', value)}
              />

              <Message warning size="tiny">
                <Icon name="exclamation circle" />
                DANGER. URL path will change to "<strong>/{org.path}</strong>
                ". This WILL affect anything that references any exports from this org. Proceed with
                caution.
              </Message>

              <Form.Input
                label="Description"
                name="description"
                placeholder="A quick description about this organization..."
                value={org.description || ''}
                onChange={(e, { value }) => updateUi('set', 'org.description', value)}
              />

              <div className="flex mt-3">
                <div className="mr-2">
                  <Button
                    content="Save"
                    primary
                    disabled={orgService.isSaving || !orgChanged}
                    onClick={e => {
                      e.preventDefault();
                      if (!orgChanged) {
                        return;
                      }

                      updateUi('set', '_savingForm1', true);
                      updateUi('set', 'basicInfoError', null);

                      orgService
                        .update(original.id, {
                          name: org.name,
                          path: org.path,
                          description: org.description,
                        })
                        .then(result => {
                          updateUi('set', 'org', _.clone(result));

                          if (result.path !== original.path) {
                            window.location.replace(`/${result.path}/settings`);
                          }

                          updateUi('set', '_savingForm1', false);
                        })
                        .catch(err => {
                          alert.error('There was an error updating the organization.');
                          updateUi('set', 'basicInfoError', err);
                          updateUi('set', '_savingForm1', false);
                        });
                    }}
                  />
                </div>

                <div>
                  <Button
                    content="Reset"
                    disabled={!orgChanged}
                    onClick={e => {
                      e.preventDefault();
                      updateUi('set', 'org', _.cloneDeep(original));
                    }}
                  />
                </div>
              </div>
            </Form>
          </Segment>
        </div>

        <div className="mb-6">
          <Segment attached="bottom" loading={orgService.isSaving}>
            <Header as="h3" className="mb-3" dividing>
              Org Image
            </Header>

            <ErrorMessage
              className="mb-6"
              error={ui.imageError}
              onDismiss={() => updateUi('set', 'imageError', null)}
            />

            <div className="flex mt-4">
              <FormFileButton
                icon="file outline"
                name="Choose File"
                buttonProps={{
                  primary: true,
                  basic: true,
                  disabled: orgService.isSaving,
                }}
                onChange={e => {
                  e.preventDefault();
                  updateUi('set', 'imageError', null);

                  const file = _.get(e, 'target.files[0]');
                  orgService
                    .updateImage(original.id, {
                      file,
                    })
                    .then(result => {
                      updateUi('set', 'org', _.clone(result));
                      alert.success('Org image updated.');
                    })
                    .catch(err => {
                      alert.error('Error updating image.');
                      updateUi('set', 'imageError', err);
                    });
                }}
                loading={orgService.isSaving}
              />
            </div>
          </Segment>
        </div>

        <Segment attached="bottom">
          <Header as="h3" className="mb-6" dividing color="red">
            Danger Zone
          </Header>

          <div className="mt-6">
            <div>Once you delete a Org, there is no going back. Please be certain.</div>
            <div className="mt-4">
              <Button
                basic
                color="red"
                icon="trash"
                content="Delete this Org"
                onClick={() => {
                  const r = window.confirm(
                    `Are you sure you want to remove ${
                      org.name
                    }?\n\nThis action cannot be undone and will permanently delete all associated projects, files and published documentation.`
                  );
                  if (!r) return;

                  orgService
                    .remove(original.id)
                    .then(() => {
                      window.location.href = '/';
                    })
                    .catch(e => {
                      alert.error('There was an error removing the organization.');
                      console.error('There was an error removing the organization.', e);
                    });
                }}
              />
            </div>
          </div>
        </Segment>
      </OrgSettingsLayout>
    );
  }
}

export default inject((stores, props) => {
  const { appStore, orgService, routerStore } = stores;
  const orgId = _.get(routerStore, 'location.params.namespaceId');

  return {
    orgId,
    orgService,
    routerStore,
    ...appStore.injectUi(`OrgSettings-${orgId}`),
  };
})(observer(OrgSettings));
