/**
 * Temporary workaround until consolidate new editor architecture.
 */

import { computed, observable, action, flow } from 'mobx';
import _ from 'lodash';
import pluralize from 'pluralize';
import numeral from 'numeral';
import { alert } from '@platform/utils/alert';

import { getPlan, findPlans, PLANS } from '@platform/utils/billing';

export default class BillingStore {
  rootStore = null;

  @observable
  namespaceId;

  @observable
  namespaceType;

  @observable
  isSubscribing;

  @observable
  _email;

  @observable
  _card;

  @observable
  _coupon;

  @observable
  _selectedPlatformPlan;

  @observable
  _selectedDocsPlan;

  @observable
  _showCardForm;

  @observable
  _interval;

  constructor({ rootStore }) {
    this.rootStore = rootStore;
  }

  init(namespaceId, namespaceType) {
    this.namespaceId = namespaceId;
    this.namespaceType = namespaceType;
    this.reset();
  }

  reset() {
    this._card = {};
    this._email = undefined;
    this._coupon = undefined;
    this._selectedPlatformPlan = undefined;
    this._selectedDocsPlan = undefined;
    this._showCardForm = undefined;
  }

  @computed
  get isDirty() {
    return this.plansChanged || this.emailChanged || this.couponChanged || this.cardChanged
      ? true
      : false;
  }

  /**
   * email
   */

  @computed
  get emailChanged() {
    return this.email !== this.currentEmail;
  }

  @computed
  get currentEmail() {
    return _.get(this.rootStore, 'stores.billingService.subscription.billing.account.email');
  }

  @computed
  get email() {
    return typeof this._email !== 'undefined' ? this._email : this.currentEmail;
  }

  set email(email) {
    this._email = email;
  }

  /**
   * coupon
   */

  @computed
  get hideCoupon() {
    return !!_.get(this.rootStore, 'stores.billingService.subscription.billing.subscription_id');
  }

  @computed
  get couponChanged() {
    return this._coupon ? true : false;
  }

  @computed
  get coupon() {
    return this._coupon;
  }

  set coupon(coupon) {
    this._coupon = coupon;
  }

  /**
   * card
   */

  @computed
  get showCardForm() {
    return _.isEmpty(this.previousCard) || this._showCardForm;
  }

  set showCardForm(show) {
    this._showCardForm = show;
  }

  @computed
  get previousCard() {
    return _.get(this.rootStore, 'billingService.subscription.customer.sources.data[0]', {});
  }

  @computed
  get isCardValid() {
    if (this.hasSubscription && !this.cardChanged) return true;

    return _.get(this.card, 'complete');
  }

  @computed
  get cardChanged() {
    return !_.isEmpty(this.card) && !this.card.empty ? true : false;
  }

  @computed
  get card() {
    return this._card;
  }

  set card(card) {
    this._card = card;
  }

  /**
   * plans
   */

  @computed
  get platformPlans() {
    return findPlans({
      namespaceType: this.namespaceType,
      type: 'platform',
      interval: this.interval,
    });
  }

  @computed
  get docsPlans() {
    return findPlans({ namespaceType: this.namespaceType, type: 'docs', interval: this.interval });
  }

  @computed
  get plansChanged() {
    if (this.currentPlatformPlan !== this.selectedPlatformPlan) return true;
    if (this.currentDocsPlan !== this.selectedDocsPlan) return true;
    return false;
  }

  @computed
  get currentPlatformPlan() {
    const { interval } = this;
    const subscription = _.get(this.rootStore, 'stores.namespaceService.current.subscription');
    return _.get(
      getPlan({ subscription, type: 'platform' }),
      'plan',
      `platform.open_source.${interval}`
    );
  }

  @computed
  get currentDocsPlan() {
    const subscription = _.get(this.rootStore, 'stores.namespaceService.current.subscription');
    return _.get(getPlan({ subscription, type: 'docs' }), 'plan', `docs.basic.${this.interval}`);
  }

  @computed
  get currentInterval() {
    return (
      _.get(this.rootStore, 'stores.namespaceService.current.subscription.interval') || 'yearly'
    );
  }

  @computed
  get selectedPlatformPlan() {
    return this._selectedPlatformPlan || this.currentPlatformPlan;
  }

  set selectedPlatformPlan(plan) {
    this._selectedPlatformPlan = plan;
  }

  @computed
  get selectedDocsPlan() {
    return this._selectedDocsPlan || this.currentDocsPlan;
  }

  set selectedDocsPlan(plan) {
    this._selectedDocsPlan = plan;
  }

  @computed
  get interval() {
    return this._interval || this.currentInterval;
  }

  /**
   * actions
   */

  @action
  updateInterval = interval => {
    if (this.selectedPlatformPlan) {
      this._selectedPlatformPlan = [
        ...this.selectedPlatformPlan.split('.').slice(0, -1),
        interval,
      ].join('.');
    }

    if (this.selectedDocsPlan) {
      this._selectedDocsPlan = [...this.selectedDocsPlan.split('.').slice(0, -1), interval].join(
        '.'
      );
    }

    this._interval = interval;
  };

  subscribe = flow(function* subscribe(stripe) {
    if (this.isSubscribing) return;

    if (!this.hasSubscription) {
      if (!this.isCardValid) {
        alert.error('Please enter a valid payment info and try again.');
        return;
      }
    }

    let confirmText;
    if (this.computedUsage.price <= 0) {
      confirmText = `You are about to unsubscribe. Are you sure?`;
    }

    const c = confirmText ? window.confirm(confirmText) : true;
    if (!c) return;

    this.isSubscribing = true;

    try {
      let cardToken;

      // only need to generate a token if changing and valid
      if (this.cardChanged && this.isCardValid) {
        const { token } = yield stripe.createToken();
        cardToken = token;
      }

      return this.createSubscription(cardToken);
    } catch (err) {
      this.isSubscribing = true;
      alert.error(`Error subscribing. ${String(err)}`);
    }
  }).bind(this);

  createSubscription = flow(function* createSubscription(token) {
    const { namespaceId, namespaceType } = this;

    const hasSubscription = this.hasSubscription;
    const totalPrice = this.computedUsage.price;
    const billingService = _.get(this.rootStore, 'stores.billingService');
    const orgService = _.get(this.rootStore, 'stores.orgService');
    const userService = _.get(this.rootStore, 'stores.userService');

    const subscription = _.get(billingService, 'subscription.subscription', {});
    const selectedPlatformPlan = this.selectedPlatformPlan;
    const selectedDocsPlan = this.selectedDocsPlan;

    const newSubscriptionData = {
      items: [],
    };

    if (selectedPlatformPlan) {
      newSubscriptionData.items.push({
        plan: selectedPlatformPlan,
      });
    }

    if (selectedDocsPlan) {
      newSubscriptionData.items.push({
        plan: selectedDocsPlan,
      });
    }

    if (this.coupon) {
      newSubscriptionData.coupon = this.coupon;
    }

    if (this.email) {
      newSubscriptionData.email = this.email;
    }

    if (token) {
      newSubscriptionData.source = token.id;
    }

    const targetFunc =
      subscription && !_.isEmpty(subscription)
        ? billingService.updateSubscription
        : billingService.subscribe;

    try {
      yield targetFunc(namespaceId, newSubscriptionData);
      if (!billingService.error) {
        if (totalPrice > 0) {
          if (hasSubscription) {
            alert.success(`Successfully updated subscription`);
          } else {
            alert.success(`Successfully subscribed`);
          }
        } else {
          alert.success('Successfully unsubscribed');
        }

        // refresh the current namespace (will return the new subscription so that feature gates re-render etc)
        if (namespaceType === 'org') {
          yield orgService.get(namespaceId);
        } else {
          yield userService.getAuthorizedUser({ force: true });
        }
        this.isSubscribing = false;

        this.reset();
      }

      this.isSubscribing = false;
    } catch (err) {
      alert.error(`Error subscribing. Please try again or let us know if this persists!`);
      this.isSubscribing = false;
    }

    this.isSubscribing = false;
  }).bind(this);

  /**
   * usage
   */

  @computed
  get hasSubscription() {
    return (
      _.get(this.rootStore, 'stores.billingService.subscription.subscription.status') === 'active'
    );
  }

  @computed
  get currentUsage() {
    return _.get(this.rootStore, 'stores.billingService.usage', {});
  }

  @computed
  get billingAccount() {
    return _.get(this.rootStore, 'stores.billingService.subscription.billing', {});
  }

  @computed
  get computedUsage() {
    const currentUsage = this.currentUsage;
    const billingAccount = this.billingAccount;

    const platformPlan = _.find(PLANS, { id: this.selectedPlatformPlan });
    const docsPlan = _.find(PLANS, { id: this.selectedDocsPlan });
    const activeMemberCount = _.get(currentUsage, 'active_member_count');
    const passiveMemberCount = _.get(currentUsage, 'passive_member_count');
    const memberCount = activeMemberCount + passiveMemberCount;
    const discount = _.get(billingAccount, 'discount');

    const computedUsage = {
      price: 0,
      lineItems: [],
    };

    if (platformPlan) {
      if (platformPlan.min && platformPlan.price) {
        const isMinimum = activeMemberCount < platformPlan.min;

        computedUsage.lineItems.push({
          description: `${platformPlan.product.title} Plan`,
          details: isMinimum
            ? `${platformPlan.min} ${pluralize('active member', platformPlan.min)} minimum`
            : undefined,
          price: isMinimum ? platformPlan.min * platformPlan.price : '',
          interval: 'month',
        });

        computedUsage.price +=
          activeMemberCount >= platformPlan.min ? 0 : platformPlan.min * platformPlan.price;
      } else {
        computedUsage.lineItems.push({
          description: `${platformPlan.product.title} Plan`,
          price: platformPlan.price || 'Free',
          interval: 'month',
        });

        computedUsage.price += platformPlan.price || 0;
      }

      const namespace = _.get(currentUsage, ['namespaces', 0]);
      const satisfiesMin = !_.isEmpty(currentUsage) && memberCount >= platformPlan.min;

      if (activeMemberCount) {
        computedUsage.lineItems.push({
          description: `${activeMemberCount} ${pluralize('active member', activeMemberCount)}`,
          details: satisfiesMin ? `$${platformPlan.price} / month each` : undefined,
          price: satisfiesMin ? activeMemberCount * platformPlan.price : undefined,
          members: namespace.active_members,
          interval: 'month',
          child: true,
        });

        if (satisfiesMin) {
          computedUsage.price += activeMemberCount * platformPlan.price;
        }
      }

      if (passiveMemberCount) {
        computedUsage.lineItems.push({
          description: `${passiveMemberCount} ${pluralize('passive member', passiveMemberCount)}`,
          members: namespace.passive_members,
          interval: 'month',
          child: true,
        });
      }
    }

    if (docsPlan) {
      computedUsage.lineItems.push({
        description: `${_.capitalize(docsPlan.product.title)} Docs`,
        price: docsPlan.price || 'Free',
        interval: 'month',
      });

      computedUsage.price += docsPlan.price;
    }

    if (discount) {
      let price;

      if (discount.coupon_percent_off) {
        price = `-${discount.coupon_percent_off}% off`;
        computedUsage.price =
          computedUsage.price - computedUsage.price * (discount.coupon_percent_off / 100);
      }

      if (discount.coupon_amount_off) {
        price = `-${numeral(discount.coupon_amount_off / 100).format('$0,0.00')} off`;
        computedUsage.price = computedUsage.price - discount.coupon_amount_off / 100;
      }

      computedUsage.lineItems.push({
        description: `Active coupon: ${discount.coupon_id}`,
        price,
      });
    }

    if (this.interval === 'yearly') {
      computedUsage.price = computedUsage.price * 12;
      computedUsage.lineItems.push({
        description: 'Total',
        price: computedUsage.price,
        interval: 'year',
      });
    } else {
      computedUsage.lineItems.push({
        description: 'Total',
        price: computedUsage.price,
        interval: 'month',
      });
    }

    return computedUsage;
  }

  /**
   * UI
   */

  @computed
  get isValid() {
    if (!this.isCardValid) {
      // card required for new subscriptions
      if (!this.hasSubscription) return false;

      // if nothing else has changed, we need a valid card
      if (!this.emailChanged && !this.plansChanged) return false;
    }

    if (!this.hasSubscription && this.computedUsage.price <= 0) return false;

    return true;
  }

  @computed
  get subscribeText() {
    const usage = this.computedUsage;

    let text;
    if (this.hasSubscription) {
      if (this.isSubscribing) {
        return 'Updating';
      } else if (this.plansChanged) {
        if (this.computedUsage.price <= 0) {
          return 'Unsubscribe';
        }

        text = 'Update';
      } else if (this.emailChanged && !this.cardChanged) {
        return 'Update Email';
      } else if (this.cardChanged && !this.emailChanged) {
        return 'Update Card';
      } else {
        return 'Subscribed';
      }
    } else {
      if (this.isSubscribing) {
        return 'Subscribing';
      } else {
        text = 'Subscribe';
      }
    }

    if (usage.price) {
      text += ` @ ${numeral(usage.price).format('$0,0.00')} / ${
        this.interval === 'yearly' ? 'year' : 'month'
      }`;
    }

    return text;
  }
}
