import React from 'react';
import { inject, observer } from 'mobx-react';

import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import { Modal } from '@core/ui';

import Onboarding from './components/Onboarding';
import OrgCreate from './components/OrgCreate';
import ProjectCreate from './components/ProjectCreate';

const Modals = ({ appStore, routerStore, userService }) => {
  const params = _.get(routerStore, 'location.params', {});

  return [
    <Modal
      key="onboard"
      show={appStore.activeModal === 'onboard'}
      renderContent={() => <Onboarding />}
      fullscreen
    />,
    <Modal
      key="new-org"
      show={appStore.activeModal === 'new-org'}
      renderContent={() => (
        <div className="mx-auto my-32 rounded-lg shadow" style={{ maxWidth: 800 }}>
          <OrgCreate />
        </div>
      )}
      onDidHide={() => {
        routerStore.setQueryParams({ modal: null });
      }}
      closeIcon={faTimes}
      fullscreen
    />,
    <Modal
      key="new-project"
      show={
        appStore.activeModal === 'new-project' &&
        (userService.showPersonalProjects || params.namespaceId || params.orgId || params.teamId)
      }
      renderContent={() => (
        <div className="mx-auto my-32 rounded-lg shadow" style={{ maxWidth: 800 }}>
          <ProjectCreate />
        </div>
      )}
      onDidHide={() => {
        routerStore.setQueryParams({ modal: null });
      }}
      closeIcon={faTimes}
      fullscreen
    />,
  ];
};

export default inject(({ appStore, routerStore, userService }) => {
  return {
    appStore,
    routerStore,
    userService,
  };
})(observer(Modals));
