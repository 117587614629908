import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import FormInput from '../FormInput';
import JsonSchemaEditor from '../JsonSchemaEditor';
import MarkdownEditor from '../MarkdownEditor';
import EditorContentSection from '../EditorContentSection';

const SwaggerDefinition = props => {
  const {
    id,
    pathKey,
    dereffedValue,
    value,
    onChange,
    onPathKeyChange,
    parsed,
    readOnly,
    editorId,
    ui,
    updateUi,
    clearUi,
  } = props;

  return (
    <div className="SwaggerDefinition">
      <EditorContentSection>
        <div className="flex">
          <FormInput
            className="flex-1 pr-2"
            value={ui.hasOwnProperty('pathKey') ? ui.pathKey : pathKey || ''}
            onChange={e => {
              updateUi('set', ['pathKey'], e.target.value);
            }}
            onBlur={() => {
              onPathKeyChange(pathKey, ui.pathKey, () => {
                clearUi();
              });
            }}
            fluid
            label="key"
            tip="The key property for this definition. This MUST be unique within this spec."
            required
          />

          <FormInput
            className="flex-1 pl-2"
            value={_.get(value, 'title', '')}
            onChange={e => {
              onChange('set', ['title'], e.target.value);
            }}
            fluid
            label="title"
            tip="An optional, pretty title."
            required
          />
        </div>

        <div className="mt-6">
          <MarkdownEditor
            id={`${id}:definition`}
            value={_.get(value, 'description')}
            placeholder="Write your description here..."
            onChange={val => {
              onChange('set', ['description'], val);
            }}
            tip="An optional description."
          />
        </div>

        <div className="mt-6">
          <JsonSchemaEditor
            id={`${pathKey}:body`}
            schema={value}
            dereferencedSchema={dereffedValue}
            definitions={_.get(parsed, 'definitions')}
            readOnly={readOnly}
            editorStore="oas2EditorStore"
            editorId={editorId}
            onSchemaChange={schema => {
              onChange('set', [], schema);
            }}
            refBuilderProps={{
              localData: parsed,
              fileFilter: { modeling: [/oas2/] },
              routeDataTargets: { oas2: ['definitions'] },
              targets: 'required',
            }}
          />
        </div>
      </EditorContentSection>
    </div>
  );
};

export default inject(({ appStore }, { id }) => {
  return {
    ...appStore.injectUi(id),
  };
})(observer(SwaggerDefinition));
