import React from 'react';
import { Button } from 'semantic-ui-react';

class SSO extends React.Component {
  render() {
    return (
      <div
        className="px-4 h-100 flex flex-col items-center justify-center"
        style={{ minHeight: 700 }}
      >
        <div className="text-white mb-6 text-xl font-bold">
          Single sign-on (SSO) is enabled. Click below to continue.
        </div>
        <Button secondary content="Authenticate" size="large" onClick={this.props.entrypoint} />
      </div>
    );
  }
}

export default SSO;
