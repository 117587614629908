/**
 * Temporary workaround until consolidate new editor architecture.
 */
import _ from 'lodash';
import { observable, reaction, action } from 'mobx';
import pluralize from 'pluralize';

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments';
import { faDatabase } from '@fortawesome/pro-regular-svg-icons/faDatabase';
import { faCodeBranch } from '@fortawesome/pro-solid-svg-icons/faCodeBranch';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';

import { getConfigVar } from '@platform/utils/config';
import { findFileType } from '@platform/utils/projects';

import '@core/command';
import '@core/menu';

import { container } from '@core/ioc';

import { SYMBOLS as MENU_SYMBOLS } from '@core/menu/types';
import { SYMBOLS as COMMAND_SYMBOLS } from '@core/command/types';
import {
  SYMBOLS as EDITOR_SYMBOLS,
  MODES as EDITOR_MODES,
  MENUS as EDITOR_MENUS,
} from '@core/editor';

import { colors } from '@core/ui/enums';
import { CssExtension } from '@core/spec-css';
import { HubExtension } from '@core/spec-hub';
import { Oas2Extension } from '@core/spec-oas2';
import { Oas3Extension } from '@core/spec-oas3';
import { HtmlExtension } from '@core/spec-html';
import { PrismExtension } from '@core/spec-prism';
import { ScenariosExtension } from '@core/spec-scenarios';
import { LintExtension } from '@core/spec-lint';

import { Collection as DisposableCollection } from '@core/disposable';
import { isHubBuilder } from '@platform/format-hubs/utils';
import { DiagnosticSeverity } from '@stoplight/types';

const isServer = typeof window === 'undefined';

export const COMMANDS = {
  openEnvironmentEditor: 'open:environmentEditor',
  toggleDiscussions: 'toggle:discussions',
  toggleValidations: 'toggle:validations',
  toggleVersion: 'toggle:versions',
};

let Editor = {};
let MenuRegistry = {};
let CommandRegistry = {};

let hubExtension = {};
let oas2Extension = {};
let oas3Extension = {};
let scenariosExtension = {};
let prismExtension = {};
let htmlExtension = {};
let cssExtension = {};
let lintExtension = {};

if (!isServer) {
  window.Container = container;

  if (!isHubBuilder()) {
    CommandRegistry = container.get(COMMAND_SYMBOLS.CommandRegistry);
    MenuRegistry = container.get(MENU_SYMBOLS.MenuRegistry);
    Editor = container.get(EDITOR_SYMBOLS.Editor);
  }

  hubExtension = new HubExtension();
  oas2Extension = new Oas2Extension();
  oas3Extension = new Oas3Extension();
  scenariosExtension = new ScenariosExtension();
  prismExtension = new PrismExtension();
  htmlExtension = new HtmlExtension();
  cssExtension = new CssExtension();
  lintExtension = new LintExtension();
}

export class FileReader {
  rootStore = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  async read(uri) {
    /**
     * TODO:
     *
     * Should take the filepath, ie `./spec.yml`, and use the project service to fetch it.
     */
  }
}

export class CoreEditorManager {
  _rootStore;
  _editor = Editor;
  _commandRegistry = CommandRegistry;
  _menuRegistry = MenuRegistry;
  _activated = false;
  _notifications = new DisposableCollection();
  _disposables = new DisposableCollection();

  constructor(props) {
    this._rootStore = props.rootStore;
  }

  activate() {
    if (typeof window === 'undefined' || this._activated) return;

    this._disposables.dispose();
    this._activated = true;
    this.registerCommands();
    this.registerMenus();
    this.registerReactions();
    window.__SL.coreEditorManager = this;
  }

  setEnabledPanels = panels => {
    this._editor.setEnabledPanels(panels);
  };

  registerReactions = () => {
    this._disposables.push({
      dispose: reaction(
        () => ({
          isProjectDisabled: _.get(this._rootStore.stores, 'projectStore.current.isDisabled'),
        }),
        action(({ isProjectDisabled }) => {
          this._notifications.dispose();
          if (isProjectDisabled) {
            this._notifications.push(
              this._editor.addNotification({
                id: 'project:disabled',
                severity: DiagnosticSeverity.Warning,
                message:
                  'Your trial has expired! To continue editing files, please subscribe to a Platform plan or make this project public.',
                onClick: () => {
                  this._rootStore.stores.routerStore.push(
                    `/${this._rootStore.stores.orgService.current.path}/settings/billing`
                  );
                },
              })
            );
          }
        }),
        {
          name: 'isProjectDisabled',
          delay: 100,
          fireImmediately: true,
        }
      ),
    });
  };

  registerCommands = () => {
    this._commandRegistry.registerCommand(
      { id: COMMANDS.environmentEditor, label: 'Open Environments' },
      {
        execute: () => {
          this._rootStore.stores.appStore.openModal('environments');
        },
      }
    );

    this._commandRegistry.registerCommand(
      {
        id: COMMANDS.toggleDiscussions,
        label: 'Toggle Discussions Panel',
      },
      {
        execute: props => {
          this._editor.setActivePanel('discussions');
        },
      }
    );

    this._commandRegistry.registerCommand(
      {
        id: COMMANDS.toggleValidations,
        label: 'Toggle Validations Panel',
      },
      {
        execute: () => {
          this._editor.setActivePanel('validations');
        },
      }
    );

    this._commandRegistry.registerCommand(
      {
        id: COMMANDS.toggleVersion,
        label: 'Toggle Versions Panel',
      },
      {
        execute: () => {
          this._editor.setActivePanel('versions');
        },
      }
    );
  };

  registerMenus = () => {
    if (!this._menuRegistry.registerMenuAction) return;

    this._menuRegistry.registerMenuAction(EDITOR_MENUS.tabs.secondary, {
      id: 'validations',
      commandId: COMMANDS.toggleValidations,
      data: () => {
        const { errors, warnings } = this._editor;

        const filePath = _.get(this._rootStore.stores.projectStore, 'current.currentFile.path');
        const fileType = findFileType({ filePath: filePath });

        // don't show validaitons for these file types
        if (!filePath || ['config', 'markdown', 'html'].includes(fileType)) {
          // if validation panel is open close it
          const coreEditorStore = this._rootStore.stores.coreEditorStore;
          if (
            coreEditorStore &&
            _.get(coreEditorStore._editor, '_activePanel.id') === 'validations'
          ) {
            coreEditorStore.executeCommand('toggle:validations');
          }

          return {};
        }

        let menuProps = {};

        if (errors.length > 0) {
          menuProps = {
            label: `${errors.length} Errors`,
            icon: faExclamationCircle,
            color: colors.negative,
          };
        } else if (warnings.length > 0) {
          menuProps = {
            label: `${warnings.length} Warnings`,
            icon: faExclamationCircle,
            color: colors.warning,
          };
        } else {
          menuProps = {
            label: 'Valid',
            icon: faCheck,
            color: colors.positive,
          };
        }

        return menuProps;
      },
    });

    this._menuRegistry.registerMenuAction(EDITOR_MENUS.tabs.secondary, {
      id: 'discussions',
      commandId: COMMANDS.toggleDiscussions,
      data: () => {
        const currentOrg = _.get(this._rootStore.stores.orgService, 'current');

        if (!currentOrg) return {};

        const discussionCount = _.get(
          this._rootStore.stores.projectService,
          'current.open_discussions_count',
          0
        );

        return {
          label: `${discussionCount} ${pluralize('Discussion', discussionCount)}`,
          icon: faComments,
        };
      },
    });

    this._menuRegistry.registerMenuAction(EDITOR_MENUS.tabs.secondary, {
      id: 'environmentEditor',
      commandId: COMMANDS.environmentEditor,
      data: () => {
        // TODO DOESN'T RE-RENDER WHY????

        const activeEnv = _.get(this._rootStore.stores.projectStore, 'current.activeEnvKey');

        return {
          label: _.capitalize(activeEnv || 'Env.'),
          icon: faDatabase,
        };
      },
    });

    this._menuRegistry.registerMenuAction(EDITOR_MENUS.tabs.secondary, {
      id: 'versions',
      commandId: COMMANDS.toggleVersion,
      data: () => {
        let currentVersion;

        const ref = _.get(this._rootStore.stores.routerStore, 'location.params.ref');
        if (ref) {
          currentVersion = _.last(_.split(decodeURIComponent(ref), '/'));
        }

        return {
          label: currentVersion ? `v${currentVersion}` : 'Version',
          icon: faCodeBranch,
        };
      },
    });
  };

  executeCommand = (commandId, ...args) => {
    const handlers = _.get(this._commandRegistry, '_handlers', {});

    const commandHandlers = handlers[commandId]; // is an array can have multiple handlers

    if (commandHandlers) {
      // execute the first
      this._commandRegistry.executeCommand(commandId, ...args);
    } else {
      console.warn(`No command handler registered assosciated with commandId: ${commandId}`);
    }
  };
}

export class CoreEditorWrapper {
  // the entity's pretty id (srn)
  id = '';

  room = null;
  rootStore = null;
  embedded = false;

  _commandRegistry = CommandRegistry;
  _editor = Editor;
  _menuRegistry = MenuRegistry;
  _disposables = new DisposableCollection();
  _listeners = new DisposableCollection();
  _activated = false;
  _otherUsersEditing = {}; // is anybody else editing this file?

  activate() {
    if (this._activated || typeof window === 'undefined') return;
    this._activated = true;

    this.room = _.invoke(this.rootStore.Websocket, 'room', `projectFile:${this.id}`);

    if (!_.isEmpty(this._editor)) {
      if (this._extension) {
        this._disposables.push(this._editor.activateSpecExtension(this._extension));
      } else if (this._editor.setEnabledModes) {
        this._editor.setEnabledModes([EDITOR_MODES.code]);
      }

      this._editor.setIsDirty(this.isDirty);

      this._editor.resetValidations();

      if (_.get(this.rootStore.stores.projectStore, 'current.isReadonly')) {
        this._editor.setSaveHandler();

        // Add notification handler
        this._disposables.push({
          dispose: reaction(
            () => [EDITOR_MODES.design, EDITOR_MODES.code].includes(this._editor.activeMode),
            action(isDesignMode => {
              if (isDesignMode) {
                this._editor.addNotification({
                  id: 'write-access-warning',
                  severity: DiagnosticSeverity.Error,
                  message:
                    'You do not have write access to this project. Please ask an org admin for access.',
                  dismissable: false,
                });
              } else {
                this._editor.clearNotifications('write-access-warning');
              }
            }),
            {
              name: 'Write Access Warning Message',
              delay: 100,
              fireImmediately: true,
            }
          ),
        });
      } else {
        this._editor.setSaveHandler(this.save.bind(this));
      }

      this.setDefaultCommitMessage();

      this._disposables.push(this._editor.onDidChangeMode(this.onDidChangeMode));
      this._disposables.push(this._editor.onDidReset(this.onDidReset));
      this.onDidChangeMode({ mode: this._editor.activeMode });

      this.addExternalEditingNotification();
      this.setupListeners();
      this.onActivate();
      this.dereferenceParsed();
    }
  }

  deactivate() {
    if (!this._activated) return;
    this._activated = false;
    this._disposables.dispose();

    // Only leave the room if we have no changes
    if (this.room && !this.isDirty) {
      this.room.leave();
      this.room = null;
    }

    this.onDeactivate();
  }

  setupListeners = () => {
    const room = this.room;

    // only add the listeners once, don't clean them up
    if (!room || this._listeners.length) return;

    // only join once
    room.join({ dirty: this.isDirty });

    room.on('did_join_room', data => {
      // This is needed when we reconnect to a room after API restart
      if (this.isDirty) {
        room.send('is_editing_file', { dirty: this.isDirty });
      }
    });

    room.on('did_update_file', () => {
      // only update the files "original" contents if we still have changes
      this.load({ doConfirm: false, originalOnly: this.isDirty });
    });

    room.on('did_update_userMeta', data => {
      // if data.users, someone else is editing this file!
      this._otherUsersEditing = data.usernames || {};

      if (this._activated) {
        this.addExternalEditingNotification();
      }
    });

    // track isDirty
    this._disposables.push({
      dispose: reaction(
        () => this.isDirty,
        isDirty => {
          const { currentFile, currentFileRoom } =
            _.get(this.rootStore.stores.projectStore, 'current') || {};

          if (currentFile) {
            currentFile.setDirtyCommitId();
          }

          if (currentFileRoom) {
            currentFileRoom.send('is_editing_file', { dirty: isDirty });
          }

          this.addExternalEditingNotification();
          this._editor.setIsDirty(isDirty);
        },
        {
          name: 'isDirtyChanged',
          fireImmediately: true,
        }
      ),
    });

    // track isStale
    this._disposables.push({
      dispose: reaction(
        () => _.get(this.rootStore.stores.projectStore, 'current.currentFile.isStale'),
        isStale => {
          if (isStale) {
            this._editor.addNotification({
              id: 'stale',
              severity: DiagnosticSeverity.Error,
              message:
                'File saved since you last loaded it. Saving now will overwrite the new changes, proceed with caution!',
              dismissable: false,
            });
          } else {
            this._editor.clearNotifications('stale');
          }
        },
        {
          name: 'isStaleChanged',
          fireImmediately: true,
        }
      ),
    });

    this._disposables.push({
      dispose: reaction(
        () => ({
          query: this.rootStore.stores.routerStore.location.query || {},
        }),
        action(({ query }) => {
          const { activeMode = {} } = this._editor;

          if (query.view && activeMode.id !== EDITOR_MODES.read.id) {
            this._editor.setActiveMode(EDITOR_MODES.read);
          } else if (query.edit && activeMode.id === EDITOR_MODES.read.id) {
            this._editor.setActiveMode(EDITOR_MODES.design);
          }
        }),
        {
          name: 'routeQueryChanged',
          delay: 100,
          fireImmediately: true,
        }
      ),
    });
  };

  addExternalEditingNotification() {
    const severity = this.isDirty ? DiagnosticSeverity.Error : DiagnosticSeverity.Warning;
    const currentUsername = _.get(this.rootStore, 'stores.userService.authorizedUser.username');
    const currentSocketId = _.get(this.rootStore, 'Websocket.connection.id');
    const usernameSocketIdMapping = this._otherUsersEditing || {};

    // Remove our socket ID from the mapping
    if (currentUsername && currentSocketId && usernameSocketIdMapping[currentUsername]) {
      usernameSocketIdMapping[currentUsername] = _.without(
        usernameSocketIdMapping[currentUsername],
        currentSocketId
      );

      // If that was the only socket ID, then remove us from the usernames list
      if (usernameSocketIdMapping[currentUsername].length === 0) {
        delete usernameSocketIdMapping[currentUsername];
      }
    }

    // Grab just the usernames from the mapping since we've already removed our socket ID
    const usernames = Object.keys(usernameSocketIdMapping);

    // If no usernames, then no one is currently editing
    const notificationId = 'collab:editing';
    if (!usernames.length) {
      this._editor.clearNotifications(notificationId);
      return;
    }

    // The message we will show to the user
    let message;

    // If we're in the list, replace our username with "You"
    if (usernameSocketIdMapping[currentUsername]) {
      // If we're the only username in the list, handle it
      if (usernames.length === 1) {
        // If we're the only one editing this file, just return that to us
        return this._disposables.push(
          this._editor.addNotification({
            id: notificationId,
            severity,
            message:
              'You are editing this file in another window or tab. Please save those changes before editing here.',
            dismissable: false,
          })
        );
      }

      // Replace our username with "You"
      usernames[usernames.indexOf(currentUsername)] = 'You';
    }

    let combineUsernames = '';
    if (usernames.length === 2) {
      combineUsernames = usernames.join(' and ');
    } else {
      combineUsernames = usernames.join(', ');
    }

    if (severity === DiagnosticSeverity.Error) {
      message = `${combineUsernames} also ${
        usernames.length > 1 ? 'have' : 'has'
      } in progress edits! Please coordinate to make sure that you don't overwrite each other's changes.`;
    } else {
      message = `${combineUsernames} ${
        usernames.length > 1 ? 'are' : 'is'
      } currently editing this file! Please wait until they are finished before editing it yourself.`;
    }

    this._disposables.push(
      this._editor.addNotification({
        id: notificationId,
        severity,
        message,
        dismissable: false,
      })
    );
  }

  setDefaultCommitMessage() {
    // set a default commit message summary
    const parts = this.id;
    if (!parts) return;

    this._editor.setCommitMessage({
      summary: `update ${parts.split(':')[2]}`,
    });
  }

  onActivate = () => {
    // class can override if needed
  };

  onDeactivate = () => {
    // class can override if needed
  };

  onDidReset = () => {
    console.warn('Any class that inherits from CoreEditorWrapper must implement onDidReset!');
  };
}

export {
  Editor,
  MenuRegistry,
  CommandRegistry,
  hubExtension,
  cssExtension,
  oas2Extension,
  oas3Extension,
  htmlExtension,
  prismExtension,
  scenariosExtension,
  lintExtension,
};
