let workerInstance;

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
  const Worker = require('./editor.worker.js');
  const WorkerPool = require('webworker-promise/lib/pool');
  workerInstance = WorkerPool.create({
    create: () => new Worker(),
    maxThreads: 5, // optional, default is 2, max numbers of workers to create if necessary
    maxConcurrentPerWorker: 1, // optional, default is 1
  });
}

export default workerInstance;
