import _ from 'lodash';
import { observable, computed, action } from 'mobx';

import GoogleAnalytics from '@platform/utils/googleAnalytics';
import {
  newApi,
  newRule,
  cleanInstance,
  routeRequest,
  buildInstanceUrl,
  disablePrismSubdomains,
} from '@platform/utils/instances';
import { createURL } from '@platform/utils/url';
import { safeParse } from '@platform/utils/json';
import { getConfigVar } from '@platform/utils/config';
import { buildExportUrl } from '@platform/utils/entities';
import { filterSpec } from '@platform/utils/instances/filter';
import { computeSidebarTree } from '@platform/utils/instances/tree';

import { COMMANDS as PRISM_COMMANDS } from '@core/spec-prism';

import collectionEditorStore, { CollectionEditor } from './collectionEditorStore';
import { prismExtension } from './coreEditor';

// extends collection editor, because we want to be able to add scenarios
class InstanceEditor extends CollectionEditor {
  _extension = prismExtension;

  fileHash;
  disablePrismSubdomains;
  collectionEditor;

  @observable
  apiId = '';

  @observable
  ruleId = '';

  @observable.ref
  currentScenarioPath = [];

  constructor(props) {
    super(props);

    this.fileHash = _.get(this.rootStore.stores.projectStore, 'current.currentFileHash');
    this.disablePrismSubdomains = disablePrismSubdomains({ fileHash: this.fileHash });
    this.instanceUrl = buildInstanceUrl({
      fileHash: this.fileHash,
    });

    // If prism subdomains are disabled, we need to add the file hash to the query
    let before;
    if (this.disablePrismSubdomains) {
      before = {
        script: `input.query.set('__id', '${this.fileHash}');`,
      };
    }

    this.collectionEditor = this.rootStore.stores.collectionEditorStore.initEditor({
      editPath: '#/scenarios/dummy/steps/0',
      entityType: 'collections',
      orgId: this.orgId,
      embedded: true,
      entity: {
        id: this.id,
        data: {
          scenarios: {
            dummy: {
              steps: [
                {
                  id: 'dummy',
                  type: 'http',
                  input: {
                    method: 'get',
                    url: this.hosts[0],
                  },
                  before,
                },
              ],
            },
          },
        },
      },
    });
  }

  activate() {
    super.activate();

    if (this.rootStore.isClient) {
      this.registerCommands();
    }
  }

  registerCommands = () => {
    this._disposables.push(
      this._commandRegistry.registerHandler(PRISM_COMMANDS.openInstance, {
        execute: () => {
          const instanceUrl = buildInstanceUrl({
            fileHash: this.fileHash,
            docs: true,
          });

          window.open(instanceUrl, '_blank');
        },
      })
    );
  };

  cleanLocal(data) {
    return cleanInstance({ collection: safeParse(data) });
  }

  get _computeSidebarTree() {
    return computeSidebarTree;
  }

  get _filterSpec() {
    return filterSpec;
  }

  // aggregates all unique hosts across apis in this instance
  @computed
  get hosts() {
    let hosts = [];
    const apis = this.parsed.apis || [];

    for (const i in apis) {
      if (!apis[i]) continue;

      const api = apis[i];
      const apiHosts = api.hosts || [];
      for (const apiHost of apiHosts) {
        const hostname = apiHost.replace(/^http(s?):\/\//, '');

        hosts.push(`http://${hostname}`);
        hosts.push(`https://${hostname}`);
      }
    }

    const [protocol, host] = _.split(getConfigVar('SL_PRISM_HOST'), '://');

    let prismHost = host;

    if (!this.disablePrismSubdomains) {
      prismHost = `${this.fileHash}.${host}`;
    }

    return _.uniq([`${protocol}://${prismHost}`, ...hosts]);
  }

  @computed
  get apiIds() {
    return _.keys(this.parsed.apis);
  }

  @computed
  get currentApi() {
    return _.get(this.parsed, ['apis', this.apiId]);
  }

  @action
  addApi(a) {
    const api = a || newApi();

    // update spec immediately, so that it's written to file (if in filemode) before we route
    // to the editor
    this.updateParsed('push', ['apis'], api, { immediate: true });

    this.goToPath(['apis', _.size(this.parsed.apis) - 1]);

    GoogleAnalytics.track({
      eventCategory: 'Instances',
      eventAction: 'add',
      eventLabel: `Add Api - ${window.Electron ? 'Desktop' : 'Web'}`,
    });

    return api;
  }

  @computed
  get ruleIds() {
    return _.keys(this.parsed.rules);
  }

  @action
  addRule(r) {
    const rule = r || newRule();

    // update spec immediately, so that it's written to file (if in filemode) before we route
    // to the editor
    this.updateParsed('push', ['rules'], rule, { immediate: true });

    this.goToPath(['rules', _.size(this.parsed.rules) - 1]);

    GoogleAnalytics.track({
      eventCategory: 'Instances',
      eventAction: 'add',
      eventLabel: `Add Rule - ${window.Electron ? 'Desktop' : 'Web'}`,
    });

    return rule;
  }

  @computed
  get currentConfigBlocks() {
    return routeRequest({
      method: _.get(this.collectionEditor, 'currentStep.data.input.method'),
      url: _.get(this.collectionEditor, 'currentStep.data.input.url'),
      apis: this.parsed.apis,
      rules: this.parsed.rules,
      scenarios: this.parsed.scenarios,
    });
  }

  // computes select options based on all connected scenario collections
  @computed
  get scenarioOptions() {
    // build up local scenario options
    const localScenarios = _.get(this.parsed, 'scenarios', {});
    let scenarioOptions = _.map(localScenarios, (scenario, scenarioId) => ({
      text: `${scenario.name || scenarioId} [#/scenarios/${scenarioId}]`,
      value: `#/scenarios/${scenarioId}`,
    }));

    // build up remote scenario options
    const remoteScenarioOptions = [];
    const { connectedCollections = [] } = this;
    _.forEach(connectedCollections, collection => {
      _.forEach(_.get(collection, 'data.scenarios', {}), (scenario, scenaroId) => {
        remoteScenarioOptions.push({
          text: `${scenario.name || scenaroId} -- ${collection.id}#/scenarios/${scenaroId}`,
          description: '@remote',
          value: buildExportUrl({
            entityType: 'scenarios',
            entity: collection,
            path: `scenarios/${scenaroId}`,
          }),
        });
      });
    });
    scenarioOptions = scenarioOptions.concat(remoteScenarioOptions);
    scenarioOptions = _.compact(scenarioOptions);

    return scenarioOptions;
  }
}

export default class InstanceEditorStore extends collectionEditorStore {
  editorClass = InstanceEditor;

  filterParsed(parsed) {
    return cleanInstance({ instance: parsed });
  }
}
