import createBrowserHistory from 'history/createBrowserHistory';
import * as _ from 'lodash';
import { configure } from 'mobx';
import { Provider as MobxProvider } from 'mobx-react';
import * as React from 'react';
import { render } from 'react-dom';

// @ts-ignore
import Router from 'react-router-dom/Router';

import { createRootStore } from '@platform/stores';
import { getConfigVar } from '@platform/utils/config';
import GoogleAnalytics from '@platform/utils/googleAnalytics';
import Intercom from '@platform/utils/intercom';
import { initLogging, registerLogger } from '@platform/utils/logging';
import { syncHistoryWithStore } from '@platform/utils/router';

const initConfig = require('@server/config');

import { App } from './app';
import { createRoutes } from './routes';

configure({ enforceActions: 'observed' });

_.set(window, '__SL.config', initConfig());

initLogging();

GoogleAnalytics.init();
Intercom.init();

// @ts-ignore
if (typeof window !== 'undefined') {
  _.set(window, 'Offline.options', {
    checks: { xhr: { url: `${getConfigVar('SL_API_HOST')}/health` } },
    requests: false, // don't replay requests on reconnection
  });
}

const log = registerLogger('main', 'client');
log.info(`Stoplight v${getConfigVar('APP_VERSION')}`);

const rootStore = createRootStore({ createRoutes, location: window.location });
_.set(window, '__SL.rootStore', rootStore);

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, rootStore.stores.routerStore);
_.set(window, '__SL.history', history);

// TS freaks about if we don't clobber the type.. not sure why
const AnyApp: any = App;

render(
  <MobxProvider {...rootStore.stores}>
    <Router history={history}>
      <AnyApp />
    </Router>
  </MobxProvider>,
  document.getElementById('root')
);
