import _ from 'lodash';
import { computeTreeData } from '@platform/utils/entities';
import { safeParse } from '@platform/utils/json';

// TODO: need meta and routeFunc
export const computeTree = (specData, props = {}) => {
  const { currentPath = [], location, editor, meta, routeFunc } = props;

  const data = safeParse(specData || {});

  const folderProps = {
    data,
    currentPath,
    location,
    editor,
    meta,
  };

  const activePage = _.get(editor, 'activePage.data', {});

  return computeTreeData(_.get(activePage, 'data.children'), {
    ...folderProps,
    treePath: currentPath.slice(0, 2).concat(['data', 'children']), // Just the page path + data.children - ['pages', 'foo'], for example
    openByDefaultToDepth: 1,
    routeFunc,
  });
};
