import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Image } from 'semantic-ui-react';

import { Icon, Popup } from '@core/ui';

import { Link } from '@platform/utils/router';

const PageGutter = props => {
  const { className, sections = [] } = props;

  const sectionElems = [];

  _.forEach(sections, (section, sectionIndex) => {
    const tabElems = [];

    _.forEach(section.tabs, (tab, tabIndex) => {
      const classNames = cn(
        className,
        tab.className,
        `h-14 text-lg flex items-center text-white hover:text-white justify-center  hover:opacity-100 cursor-pointer`,
        {
          'opacity-50': !tab.isActive && !tab.popup,
          'opacity-75': !tab.isActive && tab.popup,
          'opacity-100': tab.isActive,
        }
      );

      const childElem = (
        <div
          className={cn(tab.className, 'flex items-center justify-center rounded print:hidden')}
          style={{ width: 32, height: 32 }}
          title={tab.name}
        >
          {tab.icon && <Icon icon={tab.icon} />}
          {tab.image && <Image src={tab.image} />}
        </div>
      );

      if (tab.popup) {
        tabElems.push(
          <Popup
            key={tabIndex}
            posX="right"
            posY="center"
            {...tab.popup}
            renderTrigger={(attributes, { isVisible, isOver, showPopup, hidePopup }) => {
              return (
                <div
                  className={cn(classNames, { 'opacity-100': isVisible })}
                  id={tab.htmlId}
                  {...attributes}
                >
                  {childElem}
                </div>
              );
            }}
          />
        );
      } else if (tab.href) {
        tabElems.push(
          <Link
            key={tabIndex}
            className={classNames}
            to={tab.href}
            id={tab.htmlId}
            onClick={tab.onClick}
          >
            {childElem}
          </Link>
        );
      } else {
        tabElems.push(
          <div
            key={tabIndex}
            className={classNames}
            onClick={tab.onClick || _.noop}
            id={tab.htmlId}
          >
            {childElem}
          </div>
        );
      }
    });

    sectionElems.push(
      <div className={section.className || ''} key={sectionIndex}>
        {tabElems}

        {sections[parseInt(sectionIndex) + 1] && (
          <div className="h-16 text-xl text-white flex items-center justify-center is-divider">
            <div className="w-1 h-1" />
          </div>
        )}
      </div>
    );
  });

  return (
    <div className={cn(className, 'flex flex-col w-16 z-50 bg-lighten-50')}>{sectionElems}</div>
  );
};

export default PageGutter;
