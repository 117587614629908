const UNITS = {
  platform: 'active member',
  docs: '',
};

const PRODUCTS = Object.freeze({
  'platform.open_source': {
    type: 'platform',
    title: 'Open Source',
    unit: UNITS.platform,
    features: ['API modeling, mocking, and testing', 'Unlimited public projects'],
  },

  'platform.team': {
    type: 'platform',
    namespaceType: 'org',
    title: 'Team',
    unit: UNITS.platform,
    features: [
      'API modeling, mocking, and testing',
      'Unlimited public and private projects',
      'Up to 10 members',
    ],
  },

  'platform.business': {
    type: 'platform',
    namespaceType: 'org',
    title: 'Business',
    unit: UNITS.platform,
    features: [
      'API modeling, mocking, and testing',
      'Unlimited public and private projects',
      'Unlimited members',
      'Priority support',
    ],
  },

  'docs.basic': {
    type: 'docs',
    title: 'Basic',
    unit: UNITS.docs,
    features: ['Unlimited visits', 'Publish to docs.stoplight.io'],
  },

  'docs.essential': {
    type: 'docs',
    title: 'Essential',
    subtitle: '1 domain',
    unit: UNITS.docs,
    features: ['Custom domains', 'Basic theming', 'Build history & instant rollbacks'],
  },

  'docs.standard': {
    type: 'docs',
    title: 'Standard',
    subtitle: '10 domains',
    unit: UNITS.docs,
    features: [
      'Custom CSS & white labeling',
      'Custom JavaScript',
      'Username/password login page',
      'Analytics and Auth0 integrations',
    ],
  },

  'docs.pro': {
    type: 'docs',
    namespaceType: 'org',
    title: 'Pro',
    subtitle: '∞ domains',
    unit: UNITS.docs,
    features: [
      'Unlimited domains',
      'Downloadable docs',
      'Custom base path',
      'SAML single sign-on',
      'OAuth token generation',
    ],
  },
});

const PLANS = Object.freeze([
  {
    id: 'platform.open_source.monthly',
    price: 0,
    max: 5,
    interval: 'monthly',
    product: PRODUCTS['platform.open_source'],
  },
  {
    id: 'platform.open_source.yearly',
    price: 0,
    max: 5,
    interval: 'yearly',
    product: PRODUCTS['platform.open_source'],
  },

  {
    id: 'platform.team.monthly',
    price: 29,
    min: 1,
    max: 100,
    interval: 'monthly',
    product: PRODUCTS['platform.team'],
  },
  {
    id: 'platform.team.yearly',
    price: 24,
    min: 1,
    max: 100,
    interval: 'yearly',
    product: PRODUCTS['platform.team'],
  },

  {
    id: 'platform.business.monthly',
    price: 59,
    min: 10,
    max: Infinity,
    interval: 'monthly',
    product: PRODUCTS['platform.business'],
  },
  {
    id: 'platform.business.yearly',
    price: 49,
    min: 10,
    max: Infinity,
    interval: 'yearly',
    product: PRODUCTS['platform.business'],
  },

  {
    id: 'docs.basic.monthly',
    price: 0,
    interval: 'monthly',
    product: PRODUCTS['docs.basic'],
  },
  {
    id: 'docs.basic.yearly',
    price: 0,
    interval: 'yearly',
    product: PRODUCTS['docs.basic'],
  },

  {
    id: 'docs.essential.monthly',
    price: 79,
    interval: 'monthly',
    product: PRODUCTS['docs.essential'],
  },
  {
    id: 'docs.essential.yearly',
    price: 69,
    interval: 'yearly',
    product: PRODUCTS['docs.essential'],
  },

  {
    id: 'docs.standard.monthly',
    price: 179,
    interval: 'monthly',
    product: PRODUCTS['docs.standard'],
  },
  {
    id: 'docs.standard.yearly',
    price: 159,
    interval: 'yearly',
    product: PRODUCTS['docs.standard'],
  },

  {
    id: 'docs.pro.monthly',
    price: 399,
    interval: 'monthly',
    product: PRODUCTS['docs.pro'],
  },
  {
    id: 'docs.pro.yearly',
    price: 349,
    interval: 'yearly',
    product: PRODUCTS['docs.pro'],
  },
]);

module.exports = {
  PRODUCTS,
  PLANS,
};
