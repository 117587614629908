import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Popup, Icon } from 'semantic-ui-react';

import Terminal from '@platform/components/Terminal';
import LearnMore from '@platform/components/LearnMore';

const InstanceTerminal = props => {
  const { message, fileSystemStore, editor, projectService } = props;

  const project = projectService.current || {};

  const isPrivate = project.visibility === 'private';

  const writeFile = _.get(fileSystemStore, 'currentMapping.isActive');
  const writeFilePath = _.get(fileSystemStore, 'currentMapping.filePath');

  let terminalText = message;

  if (!terminalText) {
    if (writeFile && writeFilePath) {
      terminalText = `serve "${writeFilePath}"`;
    } else {
      terminalText = `serve "${editor.exportUrl}"`;
    }
  }

  let lines = [
    {
      type: 'command',
      name: message ? null : 'prism',
      text: terminalText,
    },
  ];

  if (isPrivate && !message) {
    lines.unshift({ type: 'command', name: 'prism', text: 'login' });
  }

  return (
    <div className="InstanceEditor-terminal" id="tutorial-instance-terminal">
      <div className="SplitEditor-parsedTitle">
        Run Locally
        <span className="SpecEditor-widgetGroupTip ml-2">
          <Popup
            trigger={<Icon name="question circle" />}
            size="small"
            position="top center"
            hoverable
            wide
          >
            <div>
              <div>Serve Prism via from the command line to start mocking requests locally.</div>

              <div className="mt-3">If you don't have Prism installed already:</div>

              <div className="mt-3">
                <b>macOS/Linux</b> - from your command line:
              </div>

              <div>
                <code>
                  curl https://raw.githubusercontent.com/stoplightio/prism/2.x/install.sh | sh
                </code>
              </div>
              <div className="mt-3">
                <b>Windows:</b>
              </div>
              <div>
                Download from{' '}
                <a
                  href="https://github.com/stoplightio/prism/releases"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </div>
              <div className="mt-3">
                <LearnMore className="mt-2 d-b" feature="run-a-simple-mock-server" />
              </div>
            </div>
          </Popup>
        </span>
      </div>

      <Terminal className="mt-2" lines={lines} light />
    </div>
  );
};

export default inject((stores, { orgId, editorId }) => {
  const { fileSystemStore, collectionEditorStore, projectService } = stores;

  const editor = collectionEditorStore.getEditor({ id: editorId });

  return {
    fileSystemStore,
    projectService,
    editor,
  };
})(observer(InstanceTerminal));
