import { types, flow, getEnv } from 'mobx-state-tree';
import _ from 'lodash';

import { encodeFileId } from '@platform/utils/instances';
import { filterFiles } from '@platform/utils/projects';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

const Base = types
  .model({
    basePath: '/projects/:projectId/files',
    perPage: 100,
  })
  .views(self => {
    return {};
  })
  .actions(self => {
    const originalUpdate = self.update;
    const originalUpdateRecord = self.updateRecord;

    return {
      /**
       * Files don't really have an ID, so we return projectId:ref:filePath.
       * Note that we'll have to figure out what to do for things that depend on this when filePath is changed.
       */
      computeFileId({ project_id, ref, path } = {}) {
        if (project_id && ref && path) {
          return `${project_id}:${ref}:${path}`;
        }
      },

      /**
       * This is used mostly for the prism conductor and multi subdomains.
       */
      computeFileIdHash(id) {
        if (id) {
          return encodeFileId(id);
        }
      },

      /**
       * Overload updateRecord to transform the record to the format the client expects.
       * API doesn't include project_id, for example.
       */
      updateRecord(record, { params = {}, opts = {} } = {}) {
        if (!record) {
          return;
        }

        // pull out some unecessary props (we use path, not file_path)
        const {
          file_name,
          file_path,
          ref,
          branch,
          project_id,
          content,
          encoding,
          ...props
        } = record;

        // we need to store the ref and project_id with the file, so that we can effectively
        // get all files for a given project/ref (this data is not returned by api)
        const extendedRecord = {
          name: file_name,
          path: file_path,

          ref:
            branch ||
            ref ||
            _.get(opts, 'query.ref') ||
            _.get(getEnv(self).rootStore.stores.routerStore, 'location.params.ref'),

          project_id:
            project_id ||
            _.get(params, 'projectId') ||
            _.get(getEnv(self).rootStore.stores.projectService, 'current.id'),

          ...props,

          content: encoding === 'base64' ? atob(content) : content,
        };

        // we store a special id for files
        extendedRecord.id = self.computeFileId(extendedRecord) || props.id;

        return originalUpdateRecord(extendedRecord);
      },

      filterFiles({ files = [], fileFilter } = {}) {
        return filterFiles({ files, fileFilter });
      },

      // need to overload base update because API does not return new content, and we
      // want to make sure that it is updated in the local store
      update: flow(function*(path, data, params, opts) {
        getEnv(self).rootStore.stores.appStore.removeError('parseEditorData');

        yield originalUpdate(
          path,
          data,
          params,
          Object.assign({}, opts || {}, { skipStore: true })
        );

        const ref = data.branch;

        const id = self.computeFileId({
          project_id: _.get(params, 'projectId'),
          ref,
          path,
        });

        if (id) {
          self.updateRecord({
            id,
            ref,
            content: data.content,
          });
        }
      }),
    };
  });

export const Service = types
  .compose(
    BaseStore,
    BaseService,
    Base
  )
  .named('ProjectFileService');

export const create = ({ data, env, options = {} }) => {
  return Service.create(data, env);
};
