import _ from 'lodash';
import { build as buildObjectRouter } from '@platform/utils/objectRouter';
import { stepToken, stepName } from '@platform/utils/collections';

export const scenarioKeyRegex = /before|scenarios|after|done|utilities/;

export default ({
  CollectionEditorOverview,
  ScenarioEditorOverview,
  CollectionCoverage,
  CollectionOutput,
  ScenarioStep,
} = {}) => {
  const propsData = {
    root: {
      component: CollectionEditorOverview,
      sidebarOptions: {
        parseChildren: true,
      },
    },
    coverage: {
      component: CollectionCoverage,
    },
    result: {
      component: CollectionOutput,
    },
    scenario: {
      component: ScenarioEditorOverview,
      sidebarOptions: {
        parseChildren: ['steps'],
        childLinkFactory: (key, data) => {
          return {
            name: 'Scenario Config',
            icon: 'setting',
          };
        },
        nameFactory: (key, data) => {
          return (data && data.name) || key;
        },
        actionsFactory: (key, data, opts) => {
          return [
            {
              id: 'clone',
              icon: 'clone',
              type: 'action',
              tip: 'Clone scenario.',
              onClick: () => {
                opts.editor.cloneScenario({ path: [...opts.treePath, key] }, { navigate: true });
              },
            },
          ];
        },
      },
    },
    steps: {
      sidebarOptions: {
        parseChildren: true,
        openByDefault: true,
        nameFactory: (key, data) => {
          const size = _.size(data || []);
          return `steps [${size}]`;
        },
        noDefaultActions: true,
        actionsFactory: (key, data, opts) => {
          return [
            {
              id: 'add',
              icon: 'plus',
              type: 'action',
              tip: 'Add step.',
              onClick: () => {
                opts.editor.addStep({ path: opts.treePath }, { navigate: true });
              },
            },
          ];
        },
      },
    },
    step: {
      component: ScenarioStep,
      sidebarOptions: {
        tokenFactory: (key, data) => stepToken(data),
        nameFactory: (key, data) => stepName(data, { skipToken: true }),
        classNameFactory: (key, data, opts) => {
          const { meta = {} } = opts || {};

          const path = opts.treePath.concat(key).join('/');

          // if there is a failure being displayed, add a class to style the entire item
          return _.find(meta[path], { className: 'c-negative' }) ? 'is-negative' : '';
        },
        actionsFactory: (key, data, opts) => {
          return [
            {
              id: 'add',
              icon: 'plus',
              type: 'action',
              tip: 'Add a step after this one.',
              onClick: () => {
                opts.editor.addStep(
                  { path: opts.treePath.slice(0, 2), afterIndex: parseInt(key) },
                  { navigate: true }
                );
              },
            },
            {
              id: 'clone',
              icon: 'clone',
              type: 'action',
              tip: 'Clone step.',
              onClick: () => {
                opts.editor.cloneStep(
                  { path: [...opts.treePath, parseInt(key)] },
                  { navigate: true }
                );
              },
            },
          ];
        },
      },
    },
  };

  const contentRouterMap = new Map();

  contentRouterMap.set(['settings', 'testing'], propsData.coverage);
  contentRouterMap.set([/result/], propsData.result);
  contentRouterMap.set([scenarioKeyRegex, /.*/], propsData.scenario);
  contentRouterMap.set([scenarioKeyRegex, /.*/, 'steps'], propsData.steps);
  contentRouterMap.set([scenarioKeyRegex, /.*/, 'steps', /.*/], propsData.step);
  contentRouterMap.set([], propsData.root);

  const contentRouter = buildObjectRouter(contentRouterMap);

  return {
    propsData,
    scenarioKeyRegex,
    contentRouter,
  };
};
