import _ from 'lodash';
import { observable, computed, action } from 'mobx';

import GoogleAnalytics from '@platform/utils/googleAnalytics';
import { safeParse } from '@platform/utils/json';

import Base from './base';

const typeMappings = {
  spec: 'spec',
  specs: 'spec',
  collection: 'collection',
  collections: 'collection',
  scenario: 'collection',
  scenarios: 'collection',
  instance: 'instance',
  instances: 'instance',
};

export default class FileSystemStore extends Base {
  persist = [
    {
      watch: 'lastUpdated',
      key: 'fileMappings',
    },
  ];

  rootStore = null;

  // which editor are we looking at?
  @observable
  currentId;

  // holds information on how ids map to files
  // and wether we are working with a file or stoplight
  // {
  //   id: '123',
  //   filePath: '/foo/bar.json',
  //   isActive: false,
  // }
  //
  // this is persisted in local storage
  @observable
  fileMappings = [];

  // subscribe to this to be notified when any mappings are
  // updated
  @observable
  lastUpdated = 0;

  // structure to hold file watchers any active fileMappings
  // will have a corresponding watcher (same id), that watches
  // the filed pointed to and calls any registered listeners on
  // change
  // {
  //   watcher - the watcher itself (https://github.com/paulmillr/chokidar)
  //   path - the path, which is also stored in the corresponding fileMapping
  //   data - the latest data, as read from the file @ path
  //   handleChange - a function that takes a path, and will read and then call all registered handlers
  //   lastUpdated - the last time the file @ path was changed
  //   silent - when set to true, the next change to the watched file won't trigger handleChange
  // }
  @observable.shallow
  watchers = {};

  constructor({ rootStore }) {
    super();
    this.rootStore = rootStore;
    this.init();
  }

  @computed
  get currentIndex() {
    if (_.isEmpty(this.currentId)) {
      return -1;
    }

    return _.findIndex(this.fileMappings.slice(), fm => {
      return _.last((fm.id || '').split('-')) === _.last(this.currentId.split('-'));
    });
  }

  @computed
  get currentMapping() {
    const index = this.currentIndex;
    return _.get(this.fileMappings, [index]);
  }

  @computed
  get isCurrentActive() {
    return _.get(this.currentMapping, 'isActive');
  }

  findById(id) {
    return _.find(this.fileMappings, { id });
  }

  findActiveMapping(type, id) {
    return _.find(this.fileMappings, { id: `${typeMappings[type]}-${id}`, isActive: true });
  }

  writeFile(filePath, data) {
    if (filePath) {
      try {
        window.Electron.fs.writeJSONSync(filePath, safeParse(data), { spaces: 2 });
      } catch (e) {
        console.warn(`error trying to write ${filePath}`, e);
        throw e;
      }
    }
  }

  readFileMapping = fileMapping => {
    const rootStore = this.rootStore;

    return new Promise((resolve, reject) => {
      if (!fileMapping || _.isEmpty(fileMapping.filePath)) {
        console.warn('fileMapping.filePath is empty', fileMapping);
        return reject('fileMapping.filePath is empty', fileMapping);
      }

      window.Electron.fs.readFile(fileMapping.filePath, 'utf8', (err, data) => {
        if (err) {
          rootStore.stores.appStore.addError(err, { id: fileMapping.id });
          return reject(err);
        }

        rootStore.stores.appStore.removeError(fileMapping.id);

        resolve({
          ...fileMapping,
          data,
        });
      });
    });
  };

  readFileMappings(fileMappings) {
    const actions = [];

    for (const fileMapping of fileMappings) {
      actions.push(this.readFileMapping(fileMapping));
    }

    return Promise.all(actions)
      .then(res => {
        return res;
      })
      .catch(err => {
        if (err) {
          this.rootStore.stores.appStore.addError(err);
        }
      });
  }

  @action
  initWatcher({ id, mapping, defaultData, reload, registerOnChange, onLoad }) {
    if (_.isEmpty(id) || !window.Electron) {
      return;
    }

    let watcherData = this.watchers[id];
    if (!watcherData) {
      watcherData = {
        path: mapping.filePath,

        // this function fetches the current data @ path, or return the defaultData
        data: () => {
          let data = defaultData;

          if (!_.isEmpty(mapping.filePath)) {
            const fileExists = window.Electron.fs.existsSync(mapping.filePath);
            if (fileExists) {
              try {
                data = window.Electron.fs.readFileSync(mapping.filePath, 'utf8');
              } catch (e) {
                try {
                  this.writeFile(mapping.filePath, data);
                } catch (err) {
                  console.warn(`Error fetching file at path '${mapping.filePath}'`, err);
                  this.rootStore.stores.appStore.addError(
                    `Error fetching file at path '${mapping.filePath}'`
                  );

                  if (watcherData.watcher) {
                    watcherData.watcher.unwatch(mapping.filePath);
                  }

                  this.unsetMapping(id);
                }
              }
            } else {
              this.rootStore.stores.appStore.addError(`File not found at '${mapping.filePath}'`);
            }
          }

          return safeParse(data, data);
        },

        createWatcher: filePath => {
          watcherData.watcher = window.Electron.fileWatcher.watch(filePath, {
            depth: 1,
          });

          // catch registered handlers on file change
          watcherData.watcher.on('change', watcherData.handleChange);
        },

        // this function reads the latest data, and calls all registered listeners with the data
        handleChange: path => {
          if (watcherData.silent) {
            watcherData.silent = false;
            return;
          }

          try {
            watcherData.data = window.Electron.fs.readFileSync(path, 'utf8');
            watcherData.lastUpdated = new Date().getTime();

            _.forOwn(watcherData.onChangeFuncs, onChangeFunc => {
              onChangeFunc(watcherData.data);
            });

            if (watcherData.onLoad) {
              watcherData.onLoad(watcherData.data);
            }
          } catch (err) {
            console.warn(`Error watching file at path '${path}'`, err);
            this.rootStore.stores.appStore.addError(`Error watching file at path '${path}'`);
            this.unsetMapping(id);
          }
        },

        onLoad,

        // a mapping of registered listeners
        onChangeFuncs: registerOnChange
          ? {
              [registerOnChange.id]: registerOnChange.onChange,
            }
          : {},

        lastUpdated: new Date().getTime(),
      };

      // initial load
      if (mapping.isActive && watcherData.onLoad) {
        watcherData.onLoad(watcherData.data());
      }

      this.watchers[id] = watcherData;
    } else {
      if (registerOnChange) {
        // register the new listener, or override an existing one
        watcherData.onChangeFuncs[registerOnChange.id] = registerOnChange.onChange;
        this.watchers[id] = watcherData;
      }

      if (onLoad) {
        // register the new onLoad listener, or override an existing one
        watcherData.onLoad = onLoad;
      }
    }

    // create a new watcher if if the mapping is currently active and we have a filePath to watch
    if (!watcherData.watcher && mapping.isActive && !_.isEmpty(mapping.filePath)) {
      watcherData.createWatcher(mapping.filePath);
    }

    // we can indicate that the watcher should immediately fire a reload
    // which will cause it to read the file from disk, and call any registered
    // onChangeFuncs
    if (reload && mapping.isActive && mapping.filePath) {
      watcherData.handleChange(mapping.filePath);
    }
  }

  @action
  initMapping(props = {}, { reload, registerOnChange } = {}) {
    if (typeof window === 'undefined') {
      return;
    }

    const { id, type, defaultData, filePath = null, isActive = false, onLoad } = props;

    if (_.isEmpty(id) || !window.Electron) {
      return;
    }

    if (!typeMappings[type]) {
      const msg = `${type} file mapping type is not supported`;
      console.warn(msg);

      if (window.Bugsnag) {
        window.Bugsnag.notify('InvalidFilemappingType', msg);
      }

      return;
    }

    const currentId = `${typeMappings[type]}-${id}`;
    this.currentId = currentId;
    const currentMapping = this.currentMapping;

    if (currentMapping) {
      this.initWatcher({
        id: this.currentId,
        mapping: currentMapping,
        defaultData,
        registerOnChange,
        reload,
        onLoad,
      });

      return currentMapping;
    }

    const mapping = {
      id: this.currentId,
      filePath,
      isActive,
    };

    this.fileMappings.push(mapping);
    if (props.isActive) {
      this.lastUpdated = new Date().getTime();
    }

    this.initWatcher({
      id: this.currentId,
      mapping,
      defaultData,
      registerOnChange,
      reload,
      onLoad,
    });

    return mapping;
  }

  @action
  unsetMapping(id) {
    this.fileMappings = _.reject(this.fileMappings, { id });
  }

  @action
  toggleActive() {
    const mapping = this.currentMapping;

    if (!mapping) {
      return;
    }

    mapping.isActive = !mapping.isActive;
    this.lastUpdated = new Date().getTime();

    let type = 'Unknown';
    if (_.includes(mapping.id, 'collection')) {
      type = 'Scenario';
    } else if (_.includes(mapping.id, 'spec')) {
      type = 'Spec';
    }

    GoogleAnalytics.track({
      eventCategory: `${type}s`,
      eventAction: mapping.isActive ? 'turnOnLocalFile' : 'turnOffLocalFile',
    });

    // if no longer active, and we have an active watcher, stop watching
    const watcherData = this.watchers[mapping.id];
    if (watcherData && watcherData.watcher && mapping.filePath) {
      if (mapping.isActive) {
        watcherData.watcher.add(mapping.filePath);
        watcherData.handleChange(mapping.filePath);
      } else {
        watcherData.watcher.unwatch(mapping.filePath);
      }
    }

    // check that the file still exists
    if (mapping.isActive && mapping.filePath) {
      try {
        window.Electron.fs.readFileSync(mapping.filePath, 'utf8');
      } catch (err) {
        console.warn(`Error fetching file at path '${mapping.filePath}'`, err);
        this.rootStore.stores.appStore.addError(
          `Error fetching file at path '${mapping.filePath}'`
        );
      }
    }
  }

  @action
  updateFilePath(filePath) {
    const mapping = this.currentMapping;

    if (!mapping) {
      return;
    }

    const oldFilePath = mapping.filePath;
    mapping.filePath = filePath;
    this.lastUpdated = new Date().getTime();

    const watcherData = this.watchers[mapping.id];
    if (watcherData) {
      // if there is an active watcher unregister the old path, register the new
      if (watcherData.watcher) {
        if (!_.isEmpty(oldFilePath)) {
          watcherData.watcher.unwatch(oldFilePath);
        }

        // register the new filePath to watch
        watcherData.watcher.add(filePath);
      } else {
        // create the watcher if it did not previously exist
        watcherData.createWatcher(mapping.filePath);
      }

      // trigger the change functions for the new filePath
      watcherData.handleChange(mapping.filePath);
    }
  }

  @action
  writeCurrentFile(data, { silent, throwError } = {}) {
    const mapping = this.currentMapping;
    if (!mapping) {
      return;
    }

    const watcher = this.watchers[mapping.id];
    if (watcher && silent) {
      watcher.silent = true;
    }

    try {
      this.writeFile(mapping.filePath, data);
    } catch (e) {
      if (watcher && silent) {
        watcher.silent = false;
      }

      this.unsetMapping(mapping.id);

      if (throwError) {
        throw e;
      }
    }
  }

  @action
  readCurrentFile(data, { silent } = {}) {
    const mapping = this.currentMapping;
    if (!mapping) {
      return;
    }

    return this.readFileMapping(mapping);
  }

  @action
  setCurrentId(id) {
    this.currentId = id;
  }
}
