import React from 'react';
import cn from 'classnames';

import { registerLogger } from '@platform/utils/logging';
import { RouterLayoutSlot, RouterRedirect } from '@platform/utils/router';

import ErrorBoundary from '../ErrorBoundary';

const log = registerLogger('components', 'MainLayout');

class MainLayout extends React.PureComponent {
  render() {
    log.debug('Render');

    const { fullscreen, overlay } = this.props;

    // Note: the w-full on line ~38 is needed to prevent overscroll on mobile!

    return (
      <div
        className={cn('flex min-h-screen', {
          'h-full': fullscreen,
        })}
      >
        <RouterRedirect />

        <RouterLayoutSlot id="htmlHead" />

        <div className="flex z-5" style={{ boxShadow: '0 0px 2px black' }}>
          <RouterLayoutSlot id="leftGutter" />
          <RouterLayoutSlot id="leftSidebar" />
        </div>

        <div className="flex flex-col flex-1 z-1">
          <RouterLayoutSlot id="globalHeader" />

          <div className="flex flex-1">
            <div
              className={cn('flex flex-col flex-1 relative w-full', {
                'h-full': fullscreen,
              })}
            >
              {overlay}

              <ErrorBoundary>
                <RouterLayoutSlot id="bodyHeader" />
              </ErrorBoundary>

              <ErrorBoundary>
                <RouterLayoutSlot id="bodyContent" className="flex-1 relative" />
              </ErrorBoundary>

              <ErrorBoundary>
                <RouterLayoutSlot id="bodyFooter" />
              </ErrorBoundary>
            </div>

            <RouterLayoutSlot id="rightSidebar" />

            <RouterLayoutSlot id="rightGutter" />
          </div>

          <RouterLayoutSlot id="globalFooter" />
        </div>
      </div>
    );
  }
}

export default MainLayout;
