import React from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Icon } from 'semantic-ui-react';
import cn from 'classnames';
import _ from 'lodash';

import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import FormInputMap from '../FormInputMap';
import MarkdownEditor from '../MarkdownEditor';
import MarkdownViewer from '../MarkdownViewer';
import EditorContentSection from '../EditorContentSection';

import SwaggerParameters from '../SwaggerParameters';
import { SwaggerExtensions } from '../SwaggerExtensions';

import { authUrlOptions, accessUrlOptions } from '@platform/utils/authorization';

const ReadOnly = props => {
  const { value = {} } = props;

  let header;

  switch (value.type) {
    case 'apiKey':
      header = `API Key ${_.capitalize(value.in)}`;
      break;
    case 'oauth2':
      header = `OAuth2 ${_.startCase(value.flow)} Flow`;
      break;
    case 'basic':
      header = 'HTTP Basic Auth';
      break;
    default:
      break;
  }

  return (
    <div className="SwaggerSecurityDefinition">
      <div className="font-bold">
        <Icon name="check" /> {header}
      </div>

      {value.type === 'oauth2' && value.authorizationUrl ? (
        <div className="mt-6">
          <h4>Authorization Url</h4>
          <div>{value.authorizationUrl}</div>
        </div>
      ) : null}

      {value.type === 'oauth2' && value.tokenUrl ? (
        <div className="mt-6">
          <h4>Token Url</h4>
          <div>{value.tokenUrl}</div>
        </div>
      ) : null}

      {value.type === 'oauth2' && !_.isEmpty(value.scopes) ? (
        <div className="mt-6">
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">Scopes</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(value.scopes, (scope, key) => {
                return (
                  <Table.Row key={key}>
                    <Table.Cell collapsing>
                      <code>{key}</code>
                    </Table.Cell>
                    <Table.Cell>{scope}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      ) : null}

      <MarkdownViewer value={value.description} className="mt-6" />
    </div>
  );
};

let Editor = props => {
  const {
    id,
    pathKey,
    value = {},
    onChange,
    onPathKeyChange,
    ui,
    updateUi,
    clearUi,
    currentPath,
  } = props;

  let typeInputs;
  let urlInputs;

  const typeSelector = className => {
    return (
      <FormSelect
        key={'type'}
        className={cn(className, 'flex-1 mt-6')}
        value={value.type}
        fluid
        label="type"
        tip="The type of the security scheme."
        required
        options={[
          {
            text: 'basic',
            value: 'basic',
          },
          {
            text: 'apiKey',
            value: 'apiKey',
          },
          {
            text: 'oauth2',
            value: 'oauth2',
          },
        ]}
        onChange={v => {
          if (v === value.type) {
            return;
          }

          if (v !== 'oauth2') {
            onChange('unset', ['flow']);
            onChange('unset', ['authorizationUrl']);
            onChange('unset', ['tokenUrl']);
            onChange('unset', ['scopes']);
          }

          if (v !== 'apiKey') {
            onChange('unset', ['name']);
            onChange('unset', ['in']);
          }

          if (value.type !== 'apiKey' && v === 'apiKey') {
            // set default valid apiKey securityDefinition object
            onChange('set', [], {
              type: 'apiKey',
              in: 'header',
              name: '',
            });
          } else if (value.type !== 'oauth2' && v === 'oauth2') {
            // set default valid oauth2 securityDefinition object
            onChange('set', [], {
              type: 'oauth2',
              flow: 'accessCode',
              authorizationUrl: value.authorizationUrl || authUrlOptions[0],
              tokenUrl: value.tokenUrl || accessUrlOptions[0],
            });
          } else {
            onChange('set', [], {
              type: v,
            });
          }
        }}
      />
    );
  };

  const authorizationUrlSelect = className => {
    return (
      <FormSelect
        key="authorizationUrl"
        className={cn(className, 'flex-1 mt-6')}
        value={value.authorizationUrl}
        onChange={v => {
          onChange('set', ['authorizationUrl'], v);
        }}
        label="authorization url"
        tip="The authorization URL to be used for this flow. This SHOULD be in the form of a URL."
        options={_.uniqBy(
          _.concat(authUrlOptions.map(u => ({ text: u, value: u })), [
            { text: value.authorizationUrl, value: value.authorizationUrl },
          ]),
          'value'
        )}
        allowAdditions
        search
        required
        fluid
      />
    );
  };

  const tokenUrlSelect = className => {
    return (
      <FormSelect
        key="tokenUrl"
        className={cn(className, 'flex-1 mt-6')}
        value={value.tokenUrl}
        onChange={v => {
          onChange('set', ['tokenUrl'], v);
        }}
        label="token url"
        tip="The token URL to be used for this flow. This SHOULD be in the form of a URL."
        options={_.uniqBy(
          _.concat(accessUrlOptions.map(u => ({ text: u, value: u })), [
            { text: value.tokenUrl, value: value.tokenUrl },
          ]),
          'value'
        )}
        allowAdditions
        search
        required
        fluid
      />
    );
  };

  // TODO refreshUrls are valid optional data for oauth2 but our validator throws and error with it included
  // const refreshUrl = className => {
  //   return (
  //     <FormInput
  //       key="refreshUrl"
  //       className={cn(className, 'flex-1 mt-6')}
  //       value={value.tokenUrl}
  //       onChange={v => {
  //         onChange('set', ['tokenUrl'], v);
  //       }}
  //       label="refresh url"
  //       tip="The refresh URL to be used for this flow. This SHOULD be in the form of a URL."
  //       options={_.uniqBy(
  //         _.concat(accessUrlOptions.map(u => ({ text: u, value: u })), [
  //           { text: value.tokenUrl, value: value.tokenUrl },
  //         ]),
  //         'value'
  //       )}
  //       fluid
  //     />
  //   );
  // };

  switch (value.flow) {
    case 'accessCode':
      urlInputs = [authorizationUrlSelect('pr-2'), tokenUrlSelect('pl-2')];
      break;
    case 'implicit':
      urlInputs = [authorizationUrlSelect()];
      break;
    case 'password':
    case 'application':
      urlInputs = [tokenUrlSelect()];
  }

  switch (value.type) {
    case 'apiKey':
      typeInputs = (
        <div className="flex">
          {typeSelector('pr-2')}
          <FormSelect
            key="in"
            className="flex-1 pl-2 pr-2 mt-6"
            value={value.in}
            onChange={v => {
              onChange('set', ['in'], v);
            }}
            fluid
            label="in"
            tip="The location of the API key."
            required
            options={[
              {
                text: 'query',
                value: 'query',
              },
              {
                text: 'header',
                value: 'header',
              },
            ]}
          />
          <FormInput
            key="name"
            className="flex-1 pl-2 mt-6"
            value={value.name}
            onChange={e => {
              onChange('set', ['name'], e.target.value);
            }}
            fluid
            label="name"
            tip="The name of the header or query parameter to be used."
            required
          />
        </div>
      );
      break;
    case 'oauth2':
      typeInputs = [
        <div key="1" className="flex">
          {typeSelector('pr-2')}
          <FormSelect
            key={'flow'}
            className="flex-1 pl-2 mt-6"
            value={value.flow}
            onChange={v => {
              onChange('set', ['flow'], v);

              switch (v) {
                case 'accessCode':
                  onChange(
                    'set',
                    ['authorizationUrl'],
                    value.authorizationUrl || authUrlOptions[0]
                  );
                  onChange('set', ['tokenUrl'], value.tokenUrl || accessUrlOptions[0]);
                  break;
                case 'implicit':
                  onChange(
                    'set',
                    ['authorizationUrl'],
                    value.authorizationUrl || authUrlOptions[0]
                  );
                  onChange('unset', ['tokenUrl']);
                  break;
                case 'password':
                case 'application':
                  onChange('unset', ['authorizationUrl']);
                  onChange('set', ['tokenUrl'], value.tokenUrl || accessUrlOptions[0]);
                  break;
              }
            }}
            fluid
            label="flow"
            tip="The flow used by the OAuth2 security scheme."
            required
            options={[
              {
                text: 'Authorization Code',
                value: 'accessCode',
              },
              {
                text: 'Implicit',
                value: 'implicit',
              },
              {
                text: 'Password Credentials',
                value: 'password',
              },
              {
                text: 'Client Credentials',
                value: 'application',
              },
            ]}
          />
        </div>,
        <div key="inputs" className="flex flex-wrap">
          {urlInputs}
        </div>,
      ];

      break;
    default:
      typeInputs = typeSelector();
      break;
  }

  return (
    <div className="SwaggerSecurityDefinition">
      <EditorContentSection>
        <div className="flex">
          <FormInput
            className="flex-1"
            value={ui.hasOwnProperty('pathKey') ? ui.pathKey : pathKey || ''}
            onChange={e => {
              updateUi('set', ['pathKey'], e.target.value);
            }}
            onBlur={() => {
              onPathKeyChange(pathKey, ui.pathKey, () => {
                // TODO: rename all usages of the old securityDefinition key to the new one in the spec
                clearUi();
              });
            }}
            fluid
            label="key"
            size="big"
            tip="The key property for this security definition. This MUST be unique within this spec."
            required
          />
        </div>

        {typeInputs}

        {value.type === 'oauth2' ? (
          <div className="flex mt-6">
            <FormInputMap
              className="flex-1"
              fields={value.scopes}
              handleUpdate={(t, p, v) => {
                onChange(t, ['scopes'].concat(p), v);
              }}
              fluid
              label="scopes"
              tip="The available scopes for the OAuth2 security scheme."
              required
              addText="Add Scope"
              namePlaceholder="scope name, ie &quot;write:post&quot;"
              valuePlaceholder="scope description, ie &quot;modify posts in your account&quot;"
            />
          </div>
        ) : null}

        <div className="mt-6">
          <MarkdownEditor
            id={`${id}:security`}
            value={value.description}
            placeholder="Security scheme description..."
            onChange={val => {
              onChange('set', ['description'], val);
            }}
            tip="An optional description."
          />
        </div>
      </EditorContentSection>

      <EditorContentSection id="SwaggerSecurityDefinition:extensions" title="Extensions">
        <SwaggerExtensions
          id={id}
          value={value}
          onChange={(t, p, v) => {
            let value = v;

            // When moving, make sure to only move within the value object since we're just renaming the x-key
            if (t === 'move') {
              value = currentPath.concat(value);
            }

            onChange(t, p, value);
          }}
        />
      </EditorContentSection>
    </div>
  );
};

Editor = inject(({ appStore }, { id }) => {
  return {
    ...appStore.injectUi(id),
  };
})(observer(Editor));

const SwaggerSecurityDefinition = props => {
  const { readOnly } = props;

  if (readOnly) {
    return <ReadOnly {...props} />;
  }

  return <Editor {...props} />;
};

export default SwaggerSecurityDefinition;
