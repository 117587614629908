import { IMenuAction } from '@core/menu';
import { IconProps, IIcon } from '@core/ui';
import { TreeItem } from 'react-sortable-tree';

// Lock File

export interface ILock {
  files: ILockFile[];
}

export interface ILockFile {
  path: string;
  title?: string;
  heading?: boolean;
  hidden?: boolean;
}

// Tree

export type ITreeChildId = string;

export interface ITreeChildCollection<T> {
  [id: string]: T;
}

export interface ITree {
  id: string;
  data: IFlatTreeChild[];
  childOptsFactory: (child: ITreeChild) => ITreeChildOpts;
  onChange?: (nextPath: string[], prevPath: string[]) => void;
  canDrag?: boolean | ((opts: any) => boolean);
  canDrop?: boolean | ((opts: any) => boolean);
  selected?: ITreeChildCollection<boolean>;
  expanded?: ITreeChildCollection<boolean>;
}

export interface IFlatTreeChild {
  path: string;
  id?: ITreeChildId;
}

export interface ITreeChild extends TreeItem {
  path: string;
  id?: ITreeChildId;
  children?: ITreeChild[];
}

export interface ITreeChildOpts {
  title: string;
  className?: string;
  iconProps?: IconProps;
  isActive?: boolean;
  isDisabled?: boolean;
  isSelectable?: boolean;
  canToggle?: boolean;
  meta?: IIcon[] | Array<{ className: string; title: string }>;
  actions?: IMenuAction[];
  onClick?: () => void;
}

// Virtual File

export interface IVirtualFile {
  id: string;
  name: string;
  type: string;
  path: string;
  mode: string;
}

export interface IStore {
  readonly scaffoldBlockPxWidth: number;
  readonly rowHeight: number;
  readonly slideRegionSize: number;

  readonly flatData?: IFlatTreeChild[];
  readonly treeData?: ITreeChild[];

  activate(data: any): void;
  setTreeData(treeData: ITreeChild[]): void;
  setFlatData(flatData: IFlatTreeChild[]): void;
  updateTreeData(flatData: IFlatTreeChild[]): void;
  getFlatDataFromTree(treeData: ITreeChild[]): IFlatTreeChild[];
}

export interface IManager {
  getById(id: string): IStore;
}

export const SYMBOLS = {
  Store: 'Store',
  Manager: 'Manager',
};
