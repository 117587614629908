import React from 'react';
import { Button, Form, Message, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import ErrorMessage from '@platform/components/ErrorMessage';

import { simpleSlugify } from '@platform/utils/general';
import { alert } from '@platform/utils/alert';

import PageContent from '../../components/PageContent';

class TeamSettings extends React.Component {
  componentWillMount() {
    this.updateUiTeam(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateUiTeam(nextProps);
  }

  team() {
    const { teamService } = this.props;
    return teamService.current || {};
  }

  updateUiTeam = props => {
    const team = this.team(props);
    const { ui, updateUi } = props;

    if (!ui.team && !_.isEmpty(team)) {
      updateUi('set', 'team', _.clone(team));
    }
  };

  render() {
    const { ui, updateUi, teamService, routerStore, orgId } = this.props;

    const team = ui.team || {};
    const original = this.team();
    const changed = !_.isEqual(team, original);

    return (
      <PageContent>
        <div className="flex-grow">
          <ErrorMessage error={teamService.error} className="mb-6" />

          <Form warning={team.path !== original.path}>
            <Form.Input
              label="Team Name"
              placeholder="Team Name"
              value={team.name || ''}
              onChange={(e, { value }) => {
                updateUi('set', ['team', 'name'], value);
              }}
            />

            <Form.Input
              label="Path"
              placeholder="path"
              value={team.path}
              onChange={(e, { value }) => updateUi('set', 'team.path', value)}
            />

            <Message warning size="tiny">
              <Icon name="exclamation circle" />
              Note, URL identifier will change to &quot;
              <strong>
                {original.parent_id}
                /teams/
                {simpleSlugify(team.path)}
              </strong>
              &quot;. Please be careful when changing this value. Changing this value WILL CHANGE
              team&#39;s URL.
            </Message>

            <Form.TextArea
              label="Description"
              rows={3}
              placeholder="What does this team do?"
              value={team.description || ''}
              onChange={(e, { value }) => {
                updateUi('set', ['team', 'description'], value);
              }}
            />

            <Button
              content="Save"
              disabled={teamService.isSaving || !changed}
              onClick={e => {
                e.preventDefault();

                if (!changed) {
                  return;
                }

                teamService
                  .update(original.id, _.pick(team, ['name', 'path', 'description']))
                  .then(updatedTeam => {
                    updateUi('set', 'team', _.clone(updatedTeam));

                    if (updatedTeam.path !== original.path) {
                      window.location.replace(`/${orgId}/teams/${updatedTeam.path}/settings`);
                    }
                  })
                  .catch(e => {
                    alert.error('There was an error updating the team.');
                    console.error('There was an error updating the team.', e);
                  });
              }}
            />

            <Button
              basic
              content="Reset"
              disabled={!changed}
              onClick={e => {
                e.preventDefault();
                updateUi('set', 'team', _.clone(original));
              }}
            />

            <Button
              basic
              negative
              icon="trash"
              content="Delete Team"
              floated="right"
              disabled={teamService.isRemoving}
              loading={teamService.isRemoving}
              onClick={() => {
                const r = window.confirm(
                  `Are you sure you want to remove the ${
                    team.name
                  } team?\nThis cannot be reverted and all information will be lost forever.`
                );

                if (r) {
                  teamService
                    .remove(original.id)
                    .then(() => {
                      alert.warning('Team deleted.');
                      routerStore.push({ pathname: `/${orgId}/teams` });
                    })
                    .catch(e => {
                      alert.error('There was an error removing the team.');
                      console.error('There was an error removing the team.', e);
                    });
                }
              }}
            />
          </Form>
        </div>
      </PageContent>
    );
  }
}

export default inject((stores, props) => {
  const { appStore, routerStore, teamService } = stores;
  const { orgId, teamId } = _.get(routerStore, 'location.params', {});

  return {
    ...appStore.injectUi(`TeamSettings-${orgId}-${teamId}`),
    orgId,
    teamService,
    routerStore,
  };
})(observer(TeamSettings));
