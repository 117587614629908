import { action, computed, observable } from 'mobx';

import { IDisposable } from '@core/disposable';
import * as disposable from '@core/disposable';
import { Emitter, Event } from '@core/emitter';
import { provideSingleton } from '@core/ioc';

import {
  ICommand,
  ICommandHandler,
  ICommandObj,
  ICommandRegistry,
  ICommandState,
  IShortcutOptions,
  SYMBOLS,
} from './types';

let keyboardJS: any;

export class Command implements ICommand {
  public id: string;

  @observable
  private _enabled: boolean = false;

  @observable
  private _executing: boolean = false;

  @observable
  private _visible: boolean = false;

  private _didUpdateEmitter = new Emitter<ICommand>();

  constructor(command: ICommandObj) {
    this.id = command.id;
  }

  @computed
  get enabled() {
    return this._enabled;
  }

  @computed
  get executing() {
    return this._executing;
  }

  @computed
  get visible() {
    return this._visible;
  }

  get onDidUpdate(): Event<ICommand> {
    return this._didUpdateEmitter.event;
  }

  @action
  public update(change: ICommandState): void {
    if (!change) return;

    const { enabled, executing, visible } = change;
    if (enabled === this.enabled && executing === this.executing && visible === this.visible)
      return;

    if (enabled !== void 0) this._enabled = enabled;
    if (executing !== void 0) this._executing = executing;
    if (visible !== void 0) this._visible = visible;

    this._didUpdateEmitter.fire(this);
  }
}

/**
 * The command registry manages commands and handlers.
 */
@provideSingleton(SYMBOLS.CommandRegistry)
export class CommandRegistry implements ICommandRegistry {
  private _commands: { [id: string]: ICommand } = {};
  private _handlers: { [id: string]: ICommandHandler[] } = {};
  private _shortcuts: { [id: string]: IDisposable } = {};

  constructor() {
    if (typeof window !== 'undefined') {
      keyboardJS = require('keyboardjs');
      keyboardJS.watch();
    }
  }

  /**
   * Get all registered commands.
   */
  get commands(): ICommand[] {
    const commands: ICommand[] = [];
    for (const id of this.commandIds) {
      const cmd = this.getCommand(id);
      if (cmd) {
        commands.push(cmd);
      }
    }
    return commands;
  }

  /**
   * Get all registered commands identifiers.
   */
  get commandIds(): string[] {
    return Object.keys(this._commands);
  }

  /**
   * Get a command for the given command identifier.
   */
  public getCommand(id: string): ICommand | undefined {
    return this._commands[id];
  }

  /**
   * Register the given command and handler if present.
   *
   * Throw if a command is already registered for the given command identifier.
   */
  public registerCommand(command: ICommandObj, handler?: ICommandHandler): IDisposable {
    if (handler) {
      const toDispose = new disposable.Collection();
      toDispose.push(this._registerCommand(command));
      toDispose.push(this.registerHandler(command.id, handler));
      return toDispose;
    }

    return this._registerCommand(command);
  }

  /**
   * Register the given handler for the given command identifier.
   */
  public registerHandler(commandId: string, handler: ICommandHandler): IDisposable {
    let handlers = this._handlers[commandId];
    if (!handlers) {
      this._handlers[commandId] = handlers = [];
    }

    handlers.push(handler);
    this.refreshCommandState(commandId);

    return disposable.create(() => {
      const idx = handlers.indexOf(handler);
      if (idx >= 0) {
        handlers.splice(idx, 1);
      }

      this.refreshCommandState(commandId);
    });
  }

  /**
   * Register the given shortcut for the given command identifier.
   */
  public registerShortcut(shortcut: string, options: IShortcutOptions): IDisposable {
    if (!keyboardJS) return disposable.NOOP;

    const { commandId, execute } = options;

    if (commandId) {
      if (!this._commands[commandId]) {
        throw Error(`Cannot bind '${shortcut}' to unregistered command with ID '${commandId}'.`);
      }
    } else if (!execute) {
      throw Error(
        `Cannot bind '${shortcut}' shortcut. commandId or execute function must be provided.`
      );
    }

    if (this._shortcuts[shortcut]) {
      throw Error(`The '${shortcut}' has already been registered.`);
    }

    this._shortcuts[shortcut] = keyboardJS.bind(shortcut, (e: any) => {
      e.preventDefault();

      if (commandId) {
        if (this.isActive(commandId)) {
          this.executeCommand(commandId);
        }
      } else if (execute) {
        execute(e);
      }
    });

    return disposable.create(() => {
      const sh = this._shortcuts[shortcut];
      if (sh) {
        keyboardJS.unbind(shortcut, sh);
        delete this._shortcuts[shortcut];
      }
    });
  }

  /**
   * Test whether there is an active handler for the given command.
   */
  public isActive(commandId: string, ...args: any[]): boolean {
    return this.getActiveHandler(commandId, ...args) !== undefined;
  }

  /**
   * Test whether there is a visible handler for the given command.
   */
  public isVisible(commandId: string, ...args: any[]): boolean {
    return this.getVisibleHandler(commandId, ...args) !== undefined;
  }

  /**
   * Test whether there is a handler that is currently executing for the given command.
   */
  public isExecuting(commandId: string, ...args: any[]): boolean {
    return this.getExecutingHandler(commandId, ...args) !== undefined;
  }

  /**
   * Execute the active handler for the given command and arguments.
   *
   * Reject if a command cannot be executed.
   */
  public executeCommand<T>(commandId: string, ...args: any[]): Promise<T | undefined> {
    const handler = this.getActiveHandler(commandId, ...args);
    if (handler) {
      return Promise.resolve(handler.execute(...args));
    }

    return Promise.reject(`The command '${commandId}' cannot be executed. There are no active
        handlers available for the command.`);
  }

  /**
   * Get a visible handler for the given command or `undefined`.
   */
  public getVisibleHandler(commandId: string, ...args: any[]): ICommandHandler | void {
    const handlers = this._handlers[commandId];
    if (handlers) {
      for (const handler of handlers) {
        if (!handler.isVisible || handler.isVisible(...args)) {
          return handler;
        }
      }
    }

    // return this.getHandlerWithState('isVisible', true, commandId, ...args);
  }

  /**
   * Get a handler that is currently executing for the given command or `undefined`.
   */
  public getExecutingHandler(commandId: string, ...args: any[]): ICommandHandler | void {
    const handlers = this._handlers[commandId];
    if (handlers) {
      for (const handler of handlers) {
        if (handler.isExecuting && handler.isExecuting(...args)) {
          return handler;
        }
      }
    }

    // return this.getHandlerWithState('isExecuting', false, commandId, ...args);
  }

  /**
   * Get an active handler for the given command or `undefined`.
   */
  public getActiveHandler(commandId: string, ...args: any[]): ICommandHandler | void {
    const handlers = this._handlers[commandId];
    if (handlers) {
      for (const handler of handlers) {
        if (!handler.isActive || handler.isActive(...args)) {
          return handler;
        }
      }
    }

    // return this.getHandlerWithState('isActive', true, commandId, ...args);
  }

  /**
   * Re-compute and set command props such as enabled.
   */
  public refreshCommandState(commandId: string, ...args: any[]) {
    const command = this.getCommand(commandId);
    if (!command) return;

    command.update({
      enabled: this.isActive(commandId, ...args),
      executing: this.isExecuting(commandId, ...args),
      visible: this.isVisible(commandId, ...args),
    });
  }

  private _registerCommand(command: ICommandObj): IDisposable {
    if (this._commands[command.id]) {
      throw Error(`A command with ID '${command.id}' is already registered.`);
    }

    this._commands[command.id] = new Command(command);

    let shortcut: IDisposable;
    if (command.shortcut) {
      shortcut = this.registerShortcut(command.shortcut, { commandId: command.id });
    }

    return disposable.create(() => {
      if (shortcut) {
        shortcut.dispose();
      }

      delete this._commands[command.id];
    });
  }
}
