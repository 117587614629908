import React from 'react';

const Oauth = () => {
  if (typeof window !== 'undefined') {
    if (window.opener) {
      window.opener.postMessage(window.location.href, '*');
    } else {
      window.location.href = '/';
    }
  }

  return <div>Oauth Handled</div>;
};

export default Oauth;
