import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faHeart } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff';

import { alert } from '@platform/utils/alert';
import { b64DecodeUnicode } from '@platform/utils/strings';
import { accessLevels, getAccessRole } from '@platform/utils/acl';

import { colors, sizes, Button } from '@core/ui';

class Invite extends React.Component {
  componentWillMount() {
    const { routerStore, currentOrg, accessLevel } = this.props;

    if (currentOrg.path && !accessLevel) {
      routerStore.push(`/${currentOrg.path}`);
    }
  }

  getHumanRole = accessLevel => {
    return _.toLower(getAccessRole(accessLevel, { human: true }));
  };

  join = async () => {
    const {
      token,
      accessLevel,
      currentOrg,
      routerStore,
      currentUser,
      orgMemberService,
    } = this.props;

    const humanRole = this.getHumanRole(accessLevel);

    if (accessLevel <= currentOrg.access_level) {
      alert.warning(
        `You are already a ${this.getHumanRole(currentOrg.access_level)} in this organization.`
      );
      routerStore.push(`/${currentOrg.path}`);
      return;
    }

    const data = { access_level: accessLevel };
    const params = { orgId: currentOrg.id };
    const options = {
      basePath: orgMemberService.invitePath,
      query: { token },
    };

    try {
      if (currentOrg.access_level) {
        await orgMemberService.update(currentUser.id, data, params, options);
      } else {
        await orgMemberService.create({ user_id: currentUser.id, ...data }, params, options);
      }

      alert.success(`You have been added to ${currentOrg.name} as a ${humanRole}.`);
    } catch (error) {
      alert.error(_.get(orgMemberService, 'error.message') || String(error));
    }

    let query = '';
    if (!currentUser.role) {
      query = '?modal=onboard';
    }

    routerStore.push(`/${currentOrg.path}${query}`);
  };

  render() {
    const {
      token,
      currentOrg,
      accessLevel,
      routerStore,
      currentUser,
      orgMemberService,
    } = this.props;

    const { isCreating, isLoading, isUpdating } = orgMemberService;

    let actions;
    let roleDescription;
    const humanRole = this.getHumanRole(accessLevel);

    if (humanRole === 'contributor') {
      roleDescription = (
        <div className="text-lg mb-6">
          Contributors can view and <span className="underline">create projects</span> in this org.
        </div>
      );
    } else if (humanRole === 'reader') {
      roleDescription = (
        <div className="text-lg mb-6">
          Readers can <span className="underline">view projects</span> in this org.
        </div>
      );
    }

    if (_.isEmpty(currentUser)) {
      const redirectUrl = `/${currentOrg.path}/invite?token=${token}`;

      actions = [
        <Button
          key="login"
          className="mr-4"
          size={sizes.lg}
          icon={faPowerOff}
          color={colors.accent}
          onClick={() => {
            routerStore.push(`/login?r=${redirectUrl}`);
          }}
        >
          Login
        </Button>,
        <Button
          key="register"
          icon={faHeart}
          size={sizes.lg}
          color={colors.accent}
          onClick={() => {
            routerStore.push(`/join?r=${redirectUrl}`);
          }}
        >
          Register
        </Button>,
      ];
    } else {
      actions = [
        <Button
          key="Join"
          size={sizes.lg}
          className="m-a w-1/2"
          color={colors.positive}
          disabled={isCreating || isLoading || isUpdating}
          loading={isCreating || isUpdating}
          onClick={this.join}
        >
          Accept
        </Button>,
      ];
    }

    return (
      <div className="flex flex-col w-1/3 m-a px-6 py-12 border-2 border-blue bg-white">
        <div className="ta-c">
          <div className="text-3xl font-bold mb-6">
            You have been invited to join {currentOrg.name} as a {humanRole}.
          </div>

          {roleDescription}

          <div>{actions}</div>
        </div>
      </div>
    );
  }
}

export default inject(({ userService, routerStore, orgService, orgMemberService }) => {
  const token = _.get(routerStore.location, 'query.token');

  let tokenParts = [];
  if (token) {
    tokenParts = _.split(b64DecodeUnicode(token), ':');
  }

  return {
    token,
    routerStore,
    orgMemberService,
    accessLevel: accessLevels[_.toUpper(tokenParts[1])],
    currentOrg: orgService.current || {},
    currentUser: userService.authorizedUser || {},
  };
})(observer(Invite));
