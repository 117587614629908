import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { computeHubThemeStyles } from '@platform/utils/hubs';
import { replaceVariables } from '@platform/utils/variables';
import ErrorBoundary from '@platform/components/ErrorBoundary';
import PageLoading from '@platform/components/PageLoading';

import HubHeader from '../HubHeader/viewer';
import Page from '../Page/viewer';

class HubViewer extends React.Component {
  static propTypes = {
    // should be a hub viewer store
    store: PropTypes.object.isRequired,
  };

  render() {
    const { id, store, releases, currentVersion, versionService } = this.props;
    const {
      nav,
      activePagePath,
      theme,
      parsed = {},
      hasHeader,
      hasSidebar,
      currentEnv,
      isLoading,
    } = store;

    if (isLoading) {
      return (
        <div className="pin absolute flex items-center justify-center">
          <PageLoading inverted={false} />
        </div>
      );
    }

    const hubElems = [];

    const computedTheme = computeHubThemeStyles(theme);
    if (computedTheme) {
      hubElems.push(<style key="th">{computedTheme}</style>);
    }

    hubElems.push(
      <HubHeader
        key="1"
        id={id}
        nav={replaceVariables(nav, { env: currentEnv })}
        theme={theme}
        hasHeader={hasHeader}
        hasSidebar={hasSidebar}
        activePagePath={activePagePath}
        buildPath={store.buildViewPath}
        currentVersion={currentVersion.name}
        versions={_.map(releases, release => {
          return {
            name: release.name,
            onClick: () => versionService.changeBranch(release.branch),
          };
        })}
        includeSearch="Search is only enabled in published Hubs."
      />
    );

    hubElems.push(<ErrorBoundary key="2"><Page id={id} store={store} /></ErrorBoundary>);

    hubElems.push(
      <div key="3" className="pin absolute flex z--1" role="presentation">
        <div className="h-full flex-1 bg-grey-lightest" />
        <div className="h-full flex-1 bg-white" />
      </div>
    );

    return <div className="Hub flex flex-col h-full">{hubElems}</div>;
  }
}

export default inject((stores, props) => {
  const { releaseService, versionService } = stores;

  const currentVersion = versionService.current || {};

  return {
    versionService,
    currentVersion,
    releases: versionService.isReleased(currentVersion.branch) ? releaseService.currentList : [],
  };
})(observer(HubViewer));
