import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

import { alert } from '@platform/utils/alert';
import { filterMap } from '@platform/utils/projects';
import PageLoading from '@platform/components/PageLoading';
import SplitSidebar from '@platform/components/SplitSidebar';
import { PanelSections } from '@platform/components/PanelSections';

import { Icon } from '@core/ui/Icon';

class FileTree extends React.Component {
  sections = () => {
    const { projectStore, updateUi } = this.props;
    const { current } = projectStore;

    const { filteredFiles = {}, isReadonly } = current || {};

    return _.map(filteredFiles, (files, name) => {
      const filter = filterMap[name] || {};

      const sectionActions = [];

      // if there are no files, we want to enable adding even if noAdd is normally on
      if (!isReadonly && (!filter.noAdd || _.isEmpty(files))) {
        sectionActions.push({
          contentFactory() {
            return (
              <div
                key={name}
                className="SplitSidebarSection-action"
                onClick={() => {
                  updateUi('set', 'type', filter.type);
                  const current = projectStore.current;

                  if (current) {
                    current.goToFile(undefined);
                  }
                }}
              >
                <Icon icon={faPlus} />
              </div>
            );
          },
        });
      }

      return {
        name,
        actions: sectionActions,
        treeData: this.treeData({ filter, files }),
      };
    });
  };

  treeData = ({ files, filter }) => {
    const { projectService, projectStore, electronStore, routerStore } = this.props;
    const currentProjectStore = projectStore.current || {};
    const { currentRef, currentFilePath, isReadonly } = currentProjectStore;
    const project = projectService.current;

    let data = _.map(files, file => {
      const { path = '', name } = file;
      const isActive = path === currentFilePath;

      let href;
      if (!isActive) {
        href = `/${project.path_with_namespace}/${encodeURIComponent(currentRef)}/${path}`;
      }

      const fileActions = [
        {
          icon: 'share',
          onClick() {
            if (electronStore.enabled) {
              electronStore.Electron.shell.openExternal(file.exportUrl);
            } else {
              window.open(file.exportUrl, '_blank');
            }
          },
        },
      ];

      if (!isReadonly && !filter.noRemove) {
        fileActions.push({
          icon: 'trash',
          type: 'negative',
          onClick() {
            const r = window.confirm('Are you sure you want to remove this file?');
            if (!r) return;

            currentProjectStore
              .removeFile(path)
              .then(() => {
                alert.warning('File removed.');
                routerStore.replace(
                  `/${project.path_with_namespace}/${encodeURIComponent(currentRef)}`
                );
              })
              .catch(e => {
                alert.error(_.get(e, 'message', String(e)));
              });
          },
        });
      }

      return {
        name: _.dropRight(path.split('.')).join('.') || 'UNNAMED',
        href,
        isActive,
        actions: fileActions,
      };
    });

    if (_.includes(data, undefined)) {
      data = _.compact(data);
    }
    return data;
  };

  renderFiles = () => {
    const sections = this.sections();

    return [
      <SplitSidebar
        key="ProjectFileTree-SplitSideBar"
        sections={sections}
        inverted
        noItemsPlaceholder={'No Files'}
      />,
    ];
  };

  render() {
    const { projectStore } = this.props;
    const currentProjectStore = projectStore.current || {};

    const isLoading =
      !currentProjectStore.currentVersion ||
      !currentProjectStore.fileService ||
      !currentProjectStore.fileTreeLoaded;

    if (isLoading) {
      return <PageLoading key="ProjectFileTree-Loading" size="small" />;
    }

    return (
      <div className="py-1 text-white pin absolute overflow-y-scroll">{this.renderFiles()}</div>
    );
  }
}

const InjectedFileTree = inject(stores => {
  const { projectService, projectStore, electronStore, appStore, routerStore } = stores;

  return {
    ...appStore.injectUi('ProjectCreate'),
    projectService,
    projectStore,
    electronStore,
    appStore,
    routerStore,
  };
})(observer(FileTree));

class ProjectFileTree extends React.Component {
  render() {
    const { headerProps = {} } = this.props;

    return (
      <div className="w-80 h-full z-20 relative">
        <PanelSections
          header={headerProps}
          sections={[
            {
              title: 'Project Files',
              collapsible: false,
              renderChildren: () => {
                return <InjectedFileTree {...this.props} />;
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default ProjectFileTree;
