import { safeStringify } from '@platform/utils/json';

const WebworkerPromise = require('webworker-promise');

const RemoteWorker = require('worker-loader!./worker');

export default class Client {
  constructor() {
    if (window === 'undefined') return;

    this.worker = new WebworkerPromise(new RemoteWorker());
    this._on = this.worker.on.bind(this.worker);
    this._send = this.worker.exec.bind(this.worker);
  }

  send(op, message) {
    return this._send(op, safeStringify(message));
  }

  on(on, cb) {
    return this._on(on, cb);
  }

  dispose() {
    this.worker.terminate();
  }
}
