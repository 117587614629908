import React from 'react';
import _ from 'lodash';
import { Button, Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { alert } from '@platform/utils/alert';
import { getConfigVar } from '@platform/utils/config';

import ErrorMessage from '../ErrorMessage';
import FormInput from '../FormInput';
import AccountFormNav from '../AccountFormNav';
import AccountSSO from '../AccountSSO';
import AccountLogin from '../AccountLogin';

function AccountForgotPassword(props) {
  const { ui = {}, updateUi, clearUi, userService, routerStore } = props;

  if (getConfigVar('SL_SSO_ENTRYPOINT')) {
    return (
      <AccountSSO entrypoint={() => (document.location.href = getConfigVar('SL_SSO_ENTRYPOINT'))} />
    );
  } else if (getConfigVar('GITHUB_URL')) {
    return <AccountSSO entrypoint={() => userService.openOAuthPopup('github')} />;
  } else if (getConfigVar('SL_LOGIN_PATH')) {
    // don't display the forgot password page if custom login is used (ie, SSO is active)
    return <AccountLogin props />;
  }

  return (
    <div className="AccountForm">
      <AccountFormNav active="forgot-pass" />

      <div className="AccountForm-inner pt-6">
        {ui.error ? (
          <ErrorMessage
            className="pb-6"
            error={ui.error}
            onDismiss={() => {
              updateUi('set', 'error', null);
            }}
          />
        ) : null}

        {!_.isEmpty(ui.successMessage) ? (
          <Message content={ui.successMessage} success size="large" />
        ) : (
          <form
            disabled={getConfigVar('ENTERPRISE_MAIL_DISABLED')}
            onSubmit={e => {
              e.preventDefault();

              if (_.isEmpty(ui.email)) {
                updateUi(
                  'set',
                  'error',
                  'Please add an email address to receive your forgot password instructions.'
                );
                updateUi('set', 'working', false);
                updateUi('set', 'successMessage', null);
                return;
              }

              updateUi('set', 'error', null);
              updateUi('set', 'working', true);

              userService.forgotPassword(
                { email: ui.email },
                result => {
                  alert.success(
                    'If a Stoplight account exists for the email provided, the reset password email has been sent!'
                  );

                  setTimeout(() => {
                    routerStore.push({ pathname: '/login' });
                    clearUi();
                  }, 1000);
                },
                error => {
                  updateUi('set', 'successMessage', null);
                  updateUi('set', 'working', false);
                  updateUi('set', 'error', error);
                }
              );
            }}
          >
            <div className="AccountForm-input-group">
              <FormInput
                input={{
                  value: ui.email || '',
                  onChange(e, { value }) {
                    updateUi('set', 'email', _.trim(value));
                  },
                }}
                disabled={ui.working}
                placeholder="Your Stoplight Account Email"
                fluid
              />
            </div>

            <div className="pt-3">
              <Button
                className="AccountForm-cta"
                disabled={ui.working || getConfigVar('ENTERPRISE_MAIL_DISABLED') ? true : false}
                loading={ui.working}
                type="submit"
                secondary
                fluid
              >
                {getConfigVar('ENTERPRISE_MAIL_DISABLED')
                  ? 'Disabled: SMTP Not Setup'
                  : 'Send Link To Email'}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default inject(({ appStore, userService, routerStore }) => ({
  userService,
  routerStore,
  ...appStore.injectUi('AccountForgotPassword'),
}))(observer(AccountForgotPassword));
