import { observable, action } from 'mobx';
import _ from 'lodash';

import Base from './base';

export default class ElectronStore extends Base {
  enabled = typeof window !== 'undefined' && window.Electron ? true : false;
  prismEnabled = typeof window !== 'undefined' && window.Electron ? true : false;
  Electron = typeof window !== 'undefined' ? window.Electron : {};
  rootStore = null;

  @observable
  port = '4010';

  @observable
  status = 'stopped';

  @observable
  error = null;

  @observable
  checkingForUpdate = false;

  @observable
  updateAvailable = false;

  @observable
  updateError = false;

  @observable.ref
  updateDownloaded = null;

  @observable.ref
  updateInfo;

  @observable.ref
  updateDownloadProgress;

  constructor(opts) {
    super();

    this.rootStore = opts.rootStore;
    this.init();

    if (opts.rootStore.isElectron) {
      this.enabled = true;
    }

    if (typeof window === 'undefined') {
      return;
    }

    if (!this.prismEnabled) {
      return;
    }

    window.Electron.ipc.on(
      'proxy.stopped',
      action(code => {
        this.status = 'stopped';
        if (code === 2) {
          this.error = `Proxy could not be started. Is port ${this.port} open?`;
        }
      })
    );

    window.Electron.ipc.on(
      'proxy.start.resolve',
      action(() => {
        this.status = 'started';
      })
    );

    window.Electron.ipc.on(
      'proxy.start.reject',
      action(e => {
        this.status = 'stopped';
        this.error = e;
      })
    );

    window.Electron.ipc.on(
      'proxy.stop.resolve',
      action(() => {
        this.status = 'stopped';
      })
    );

    window.Electron.ipc.on(
      'proxy.stop.reject',
      action(() => {
        this.status = 'started';
        this.error = 'Error stopping Prism';
      })
    );

    window.Electron.ipc.on(
      'updater.checking-for-update',
      action(() => {
        console.log('updater.checking-for-update');
        this.checkingForUpdate = true;
        this.updateError = false;
        this.updateInfo = undefined;
      })
    );

    window.Electron.ipc.on(
      'updater.update-available',
      action((e, info) => {
        console.log('updater.update-available', info);
        this.checkingForUpdate = false;
        this.updateAvailable = true;
        this.updateInfo = info;
      })
    );

    window.Electron.ipc.on(
      'updater.update-downloaded',
      action((e, info) => {
        console.log('updater.update-downloaded', info);
        this.checkingForUpdate = false;
        this.updateError = false;
        this.updateInfo = info;
        this.updateDownloaded = true;
        this.updateDownloadProgress = undefined;
      })
    );

    window.Electron.ipc.on(
      'updater.update-not-available',
      action(() => {
        console.log('updater.update-not-available');
        this.checkingForUpdate = false;
        this.updateError = false;
        this.updateInfo = undefined;
      })
    );

    window.Electron.ipc.on(
      'updater.error',
      action((e, error) => {
        console.log('updater.error', error);
        this.checkingForUpdate = false;
        this.updateError = error;
      })
    );

    window.Electron.ipc.on(
      'updater.download-progress',
      action((e, progressObj) => {
        console.log('updater.download-progress', progressObj);
        this.checkingForUpdate = false;
        this.updateError = false;
        this.updateDownloadProgress = progressObj;
      })
    );

    window.Electron.ipc.on('route.settings', (e, info) => {
      this.rootStore.stores.routerStore.push({
        pathname: '/desktop/settings',
      });
    });

    _.set(window, 'Electron.events.onOpenAbout', () => {
      this.rootStore.stores.appStore.openModal('AppAbout');
    });

    this.start();
  }

  get manualUpdateRequired() {
    return false;
  }

  @action
  start() {
    if (typeof window === 'undefined') {
      return;
    }

    this.status = 'starting';
    window.Electron.ipc.send('proxy.start');
  }

  @action
  stop() {
    if (typeof window === 'undefined') {
      return;
    }

    this.status = 'stopping';
    window.Electron.ipc.send('proxy.stop');
  }

  installUpdate() {
    if (typeof window === 'undefined') {
      return;
    }

    window.Electron.ipc.send('updater.install');
  }

  downloadUpdate() {
    if (typeof window === 'undefined') {
      return;
    }

    window.Electron.ipc.send('updater.download');
  }

  checkForUpdates() {
    if (typeof window === 'undefined') {
      return;
    }

    if (process.RUN_CONTEXT === 'linux') {
      window.Electron.shell.openExternal(
        'https://github.com/stoplightio/stoplight-app/releases/latest'
      );
    } else {
      window.Electron.ipc.send('updater.check');
    }
  }
}
