import React from 'react';
import { Icon } from 'semantic-ui-react';
import _ from 'lodash';
import cn from 'classnames';

import { Link } from '@platform/utils/router';

const GlobalNavAction = action => {
  const { id, type, name, icon, badge, className = '', href, ...htmlProps } = action;

  const cname = cn(
    'rounded-sm px-3 py-1/2 font-bold select-none transition-all text-sm text-white bg-lighten-100 hover:bg-lighten-200 cursor-pointer',
    `is-${type || 'button'}`,
    className
  );

  const innerElem = (
    <div>
      {icon ? (
        <span className="mr-1/2">
          <Icon name={icon} />
        </span>
      ) : null}

      {name}

      {type === 'dropdown' ? <Icon name="caret down" className="is-right" /> : null}

      {!_.isUndefined(badge) ? (
        <span className="GlobalNav-action-badge bg-lighten-100">{badge}</span>
      ) : null}
    </div>
  );

  if (href) {
    return (
      <Link key={id} className={cname} href={href} {...htmlProps}>
        {innerElem}
      </Link>
    );
  }

  return (
    <div key={id} className={cname} {...htmlProps}>
      {innerElem}
    </div>
  );
};

export default GlobalNavAction;
