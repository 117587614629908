import React from 'react';
import AutosizeInput from 'react-input-autosize';
import cn from 'classnames';

import FormInputLabel from '../FormInputLabel';

const FormAutosizeInput = props => {
  const {
    className = '',
    value,
    label,
    tip,
    required,
    onChange,
    placeholder,
    bold,
    transparent,
    outlined,
    active,
    ...inputProps
  } = props;

  return (
    <div
      className={cn('FormAutosizeInput', className, {
        'is-bold': bold,
        'is-transparent': transparent,
        'is-outlined': outlined,
        'is-active': active,
      })}
    >
      <FormInputLabel label={label} tip={tip} required={required} />

      <AutosizeInput
        onChange={e => onChange(e, e.target)}
        value={value}
        placeholder={placeholder}
        {...inputProps}
      />
    </div>
  );
};

export default FormAutosizeInput;
