import { types } from 'mobx-state-tree';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/orgs/:orgId/custom_attributes',
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      self.skipStore = true;

      return {};
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('OrgAttributeService');

  return Service.create(data, env);
};
