import { IDisposable } from '@core/disposable';
import { Event } from '@core/emitter';
import { IMenuNode } from '@core/menu';
import { IExtension, IStringObject } from '@core/types';
import { colors, IIcon } from '@core/ui';
import { IPositionObj, JSONPath as UnistPath } from '@core/unist';
import { DiagnosticSeverity } from '@stoplight/types';

export const SYMBOLS = {
  Editor: 'Editor',
};

export interface IEditorMode {
  id: string;
  name: string;
  verb?: string;
  summary?: string;
  icon?: IIcon;
  color?: colors;
}

export interface IEditorPanel extends IMenuNode {
  computeSections: (editor: IEditor) => any;
}

export type ISaveHandler = (
  {
    commitMessage,
  }: {
    commitMessage: ICommitMessage;
  }
) => Promise<any>;

export interface IValidation {
  spec?: string;
  severity: DiagnosticSeverity;
  message: string;
  details?: string;
  ruleId?: string;
  path?: UnistPath;
  pos?: IPositionObj;
  route?: IRouteLocation;
}

export interface INotification {
  id?: string;
  severity: DiagnosticSeverity;
  message: string;
  dismissable?: boolean;
  onClick?: () => void;
}

export interface IModeChangedEvent {
  mode?: IEditorMode;
}

export interface IPanelChangedEvent {
  panel?: IEditorPanel;
}

export interface ICommitMessage {
  summary: string;
}

export interface IIsDirtyChangedEvent {
  isDirty: boolean;
}

export interface IResetEvent {}

export interface IRouteLocation {
  pathname?: string;
  query?: IStringObject<any>;
}

export interface IRouteOptions {
  replace?: boolean;
}

export interface IEditor {
  readonly supportedModes: IStringObject<IEditorMode>;
  readonly primaryToolbar: IMenuNode;
  readonly secondaryToolbar: IMenuNode;
  readonly pluginToolbar: IMenuNode;
  readonly secondaryTabs: IMenuNode;
  readonly activeMode?: IEditorMode;
  readonly enabledModes: IEditorMode[];
  readonly activePanel?: IEditorPanel;
  readonly enabledPanels?: IEditorPanel[];
  readonly commitMessage: ICommitMessage;
  readonly isDirty: boolean;
  readonly isReadonly: boolean;
  readonly isShowingCommitMessage: boolean;
  readonly isSaving: boolean;
  readonly isActivated: boolean;

  readonly notifications: INotification[];
  readonly validations: IValidation[];
  readonly info: IValidation[];
  readonly warnings: IValidation[];
  readonly errors: IValidation[];
  readonly activeValidations: IValidation[];
  readonly activeValidationSeverity?: DiagnosticSeverity;
  readonly activeSpecExtension?: IExtension;

  readonly onDidChangeMode: Event<IModeChangedEvent>;
  readonly onDidChangeDirty: Event<IIsDirtyChangedEvent>;
  readonly onDidChangePanel: Event<IPanelChangedEvent>;
  readonly onDidReset: Event<IResetEvent>;

  addValidation(validation: IValidation): IDisposable;
  addValidations(validations: IValidation[]): IDisposable;
  setActivePanel(id?: string): void;
  setEnabledPanels(panels: IEditorPanel[]): void;
  resetValidations(): void;
  clearNotifications(id?: string): void;
  addNotification(notification: INotification, context?: string): IDisposable;
  removeNotification(notification: INotification): void;
  reset(): void;
  save(): void;
  setActiveMode(mode: IEditorMode | null): void;
  setEnabledModes(modes: IEditorMode[]): void;
  setIsDirty(isDirty: boolean): void;
  setCommitMessage(message: ICommitMessage): void;
  showCommitMessage(show: boolean): void;
  route(location: IRouteLocation, opts?: IRouteOptions): void;
  activateSpecExtension(extension?: IExtension): IDisposable;

  // (MM) TODO: remove once save implementation is moved from platform to core
  setSaveHandler(handler: ISaveHandler): void;
}
