import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import AutosizeInput from 'react-input-autosize';
import { Icon, Menu, Segment, Button, Message, Dropdown } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { hashToPath, pathToHash } from '@platform/utils/history';
import { safeParse } from '@platform/utils/json';
import { exportUrlToSrn } from '@platform/utils/entities';
import { Link } from '@platform/utils/router';

import CodeEditor from '../CodeEditor';
import FormSelect from '../FormSelect';
import RefBuilder from '../RefBuilder';
import JsonSchemaViewer from '../JsonSchemaViewer';
import ErrorMessage from '../ErrorMessage';
import SimpleMdEditor from '../SimpleMdEditor';
import Popup from '../Popup';
import { isInTypeGroup, removeValidations, isOfType } from './utils';
import { changeType } from './changeType';
import {
  combinerTypes,
  complexTypes,
  basicTypes,
  rootTypes,
  realTypes,
  allTypes,
} from './schemaTypes';

class SchemaEditor extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    rootName: PropTypes.string,
    disableAddRow: PropTypes.bool,
    readOnlyInfoProps: PropTypes.object,
    whitelistTypes: PropTypes.array,
    disableName: PropTypes.bool,
    hideRemove: PropTypes.bool,
    definitions: PropTypes.object,
    getRefLink: PropTypes.func,
    readOnly: PropTypes.bool,
    styles: PropTypes.object,
    editorStore: PropTypes.string,
    editorId: PropTypes.string,
    specFlavor: PropTypes.oneOf(['draft4', 'oas2', 'oas3']),

    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
    dereferencedSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    schema: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    updateSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onSchemaChange: PropTypes.func,

    ui: PropTypes.shape({
      schema: PropTypes.object,
      error: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
    }),
    updateUi: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.jsonSchemaStore.initContainer({
      id: this.props.id,
      schema: this.props.schema,
      onSchemaChange: this.props.onSchemaChange,
      definitions: this.props.definitions,
      editorStore: this.props.editorStore,
      editorId: this.props.editorId,
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.id !== this.props.id) {
      this.props.jsonSchemaStore.removeContainer({
        id: this.props.id,
      });
    }

    if (nextProps.schemaContainer) {
      nextProps.schemaContainer.updateDefinitions(nextProps.definitions);
      if (!nextProps.schemaContainer.error) {
        nextProps.schemaContainer.updateSchema(nextProps.schema, { external: true });
      }
    } else {
      nextProps.jsonSchemaStore.initContainer({
        id: nextProps.id,
        schema: nextProps.schema,
        onSchemaChange: nextProps.onSchemaChange,
        definitions: nextProps.definitions,
        editorStore: this.props.editorStore,
        editorId: this.props.editorId,
      });
    }
  }

  componentWillUnmount() {
    this.props.jsonSchemaStore.removeContainer({
      id: this.props.id,
    });
  }

  render() {
    const {
      id,
      refBuilderProps,
      schemaContainer,
      definitions,
      disableName,
      rootName,
      disableAddRow,
      ui,
      updateUi,
      readOnlyInfoProps = {},
      whitelistTypes,
      readOnly,
      editOnly,
      example,
      getRefLink,
      hideRemove,
      styles = {},
      hideRoot,
      specFlavor,
      dereferencedSchema,
    } = this.props;

    const { label = 'schema' } = readOnlyInfoProps;

    if (!schemaContainer) {
      return <div>initializing</div>;
    }

    let { activeTab = 'viewer' } = ui;

    if (example) {
      activeTab = _.get(ui, 'activeTab', 'editor');
    } else if (readOnly) {
      activeTab = 'viewer';
    } else if (editOnly) {
      activeTab = 'editor';
    }

    let tabElem;
    if (readOnly || activeTab === 'viewer') {
      tabElem = (
        <JsonSchemaViewer
          name={id}
          schema={schemaContainer.schema}
          dereferencedSchema={dereferencedSchema}
          schemas={definitions || {}}
          hideRoot={hideRoot}
          forApiDocs
        />
      );
    } else if (activeTab === 'editor') {
      tabElem = (
        <div>
          {!example ? (
            <div className="p-3">
              <Button
                content={ui.generatingFromExample ? 'Generate!' : 'Generate From JSON'}
                icon="magic"
                size="tiny"
                primary={ui.generatingFromExample}
                compact
                basic={!ui.generatingFromExample}
                onClick={() => {
                  if (ui.generatingFromExample) {
                    schemaContainer.generateFromExample(ui.example);
                    updateUi('set', 'example', '');
                    updateUi('set', 'generatingFromExample', false);
                  } else {
                    updateUi('set', 'generatingFromExample', true);
                  }
                }}
              />

              {ui.generatingFromExample ? (
                <span className="ml-2">
                  <Button
                    content="Cancel"
                    icon="remove"
                    size="tiny"
                    compact
                    basic
                    onClick={() => {
                      updateUi('set', 'generatingFromExample', false);
                    }}
                  />
                </span>
              ) : null}
            </div>
          ) : null}

          {ui.generatingFromExample ? (
            <div>
              <div className="pb-3 pl-3 pr-3">
                <Message
                  info
                  compact
                  size="mini"
                  content="Paste or write a JSON example below, and then click the &quot;Generate&quot; button above to build a schema."
                />
              </div>

              <CodeEditor
                name={`${id}-schema-example`}
                value={ui.example}
                mode="json"
                autogrow
                maxLines={20}
                noFill
                onChange={value => {
                  updateUi('set', 'example', value);
                }}
                wordWrap
              />
            </div>
          ) : (
            <JsonSchemaEditor.Gui
              id={id}
              refBuilderProps={refBuilderProps}
              lastUpdated={schemaContainer.lastUpdated}
              schema={schemaContainer.transformed}
              definitionDropdownData={schemaContainer.definitionDropdownData}
              disableName={disableName}
              hideRemove={hideRemove}
              rootName={rootName}
              disableAddRow={disableAddRow}
              readOnlyInfoProps={readOnlyInfoProps}
              readOnly={readOnly}
              whitelistTypes={whitelistTypes}
              getRefLink={getRefLink}
              onChangeHandler={this.handleGuiSchemaChange}
              specFlavor={specFlavor}
            />
          )}
        </div>
      );
    } else if (activeTab === 'schema') {
      tabElem = (
        <div>
          <div className="p-3">
            <Message
              info
              compact
              size="mini"
              content="The underlying raw JSON schema. Cut and paste properties to move them around."
            />
          </div>

          <CodeEditor
            name={`${id}-schema`}
            value={schemaContainer.invalidSchema || schemaContainer.schema}
            mode="json"
            autogrow
            maxLines={20}
            noFill
            wordWrap
            readOnly={readOnly}
            onBlur={v => {
              schemaContainer.updateSchema(v, { immediate: true });
            }}
          />
        </div>
      );
    } else if (activeTab === 'example') {
      tabElem = (
        <div>
          <div className="p-3">
            <Button
              content={'Generate Schema From Example'}
              icon="magic"
              size="tiny"
              primary
              compact
              basic
              onClick={() => {
                schemaContainer.generateFromExample(ui.example);
                updateUi('set', 'activeTab', 'editor');
              }}
            />
          </div>
          <div className="pb-3 pl-3 pr-3">
            <Message
              info
              compact
              size="mini"
              content="Paste or write a JSON example below, and then click the &quot;Generate&quot; button above to build a schema from the example."
            />
          </div>

          <CodeEditor
            name={`${id}-schema-example`}
            value={ui.example}
            mode="json"
            autogrow
            maxLines={20}
            noFill
            onChange={value => {
              updateUi('set', 'example', value);
              schemaContainer.generateFromExample(value);
            }}
            wordWrap
          />
        </div>
      );
    }

    let menuElem;
    if (readOnly) {
      menuElem = <div className="FormInputLabel">{label}</div>;
    } else if (example) {
      menuElem = (
        <Menu attached="top" tabular size="tiny" compact>
          <Menu.Item
            name="Editor"
            active={activeTab === 'editor'}
            onClick={() => {
              updateUi('set', 'activeTab', 'editor');
            }}
          />
          <Menu.Item
            name="Raw Schema"
            active={activeTab === 'schema'}
            onClick={() => {
              updateUi('set', 'activeTab', 'schema');
            }}
          />
          <Menu.Item
            name="Example"
            active={activeTab === 'example'}
            onClick={() => {
              updateUi('set', 'activeTab', 'example');
            }}
          />
        </Menu>
      );
    } else if (!editOnly) {
      menuElem = (
        <Menu attached="top" tabular size="tiny" compact>
          <Menu.Item
            name="Viewer"
            active={readOnly || activeTab === 'viewer'}
            onClick={() => {
              updateUi('set', 'activeTab', 'viewer');
            }}
          />

          <Menu.Item
            name="Editor"
            active={activeTab === 'editor'}
            onClick={() => {
              updateUi('set', 'activeTab', 'editor');
            }}
          />

          <Menu.Item
            name="Raw Schema"
            active={activeTab === 'schema'}
            onClick={() => {
              updateUi('set', 'activeTab', 'schema');
            }}
          />
        </Menu>
      );
    }

    let contentElem;
    if (menuElem) {
      contentElem = (
        <Segment attached={readOnly ? true : 'bottom'}>
          <ErrorMessage error={schemaContainer.error} className="mt-3 ml-3 mr-3" />

          {tabElem}
        </Segment>
      );
    } else {
      contentElem = (
        <div>
          <ErrorMessage error={schemaContainer.error} className="mt-3 ml-3 mr-3" />

          {tabElem}
        </div>
      );
    }

    return (
      <div
        className="JSE flex flex-col w-full sl-jse sl-schema-colors flex-1"
        style={styles.root || {}}
      >
        {menuElem}
        {contentElem}
      </div>
    );
  }

  toggleTab = name => {
    const { ui } = this.props;

    if (name === ui.isOpenTab) {
      return;
    }

    this.props.updateUi('set', ['isOpenTab'], name);
  };

  handleGuiSchemaChange = (t, p, v) => {
    this.props.schemaContainer.updateTransformed(t, p, v);
  };
}

const JsonSchemaEditor = inject(({ appStore, jsonSchemaStore }, { id }) => ({
  jsonSchemaStore,
  schemaContainer: jsonSchemaStore.getContainer(id),
  ...appStore.injectUi(id),
}))(observer(SchemaEditor));

const baseSchemaProps = [
  '$ref',
  'type',
  'children',
  '$schema',
  'oneOf',
  'allOf',
  'anyOf',
  '_isOpen',
  'definitions',
];
const baseSchemaExtraProps = ['title', 'name', 'default', 'description'];
const distinctValidationProps = [
  'additionalProperties',
  'maxProperties',
  'minProperties',
  'uniqueItems',
  'maxItems',
  'minItems',
  'maxLength',
  'minLength',
  'pattern',
  'format',
  'maximum',
  'minimum',
  'multipleOf',
  'enum',
  'deprecated',
];

JsonSchemaEditor.checkbox = ({ id, name, ...extra }) => (
  <div className="d-i">
    <span className="mr-2">
      <input {...extra} type="checkbox" id={`jse-validation-${id}`} />
    </span>
    <label htmlFor={`jse-validation-${id}`}>{name}</label>
  </div>
);

JsonSchemaEditor.select = ({
  id,
  name,
  value,
  options,
  onChange,
  placeholder,
  disabled,
  multiple,
  allowAdditions,
}) => {
  return (
    <FormSelect
      multiple
      allowAdditions
      search
      fluid
      disabled={disabled}
      placeholder={placeholder}
      options={options}
      value={multiple ? value || [] : value}
      onChange={onChange}
    />
  );
};

const commonValidations = [
  {
    enum: {
      elem: (
        <JsonSchemaEditor.select
          placeholder="type an option and press enter to add"
          multiple
          allowAdditions
        />
      ),
      elemType: 'select',
    },
  },
  {
    format: {
      elemFactory({ options = [] }) {
        return (
          <Dropdown
            placeholder="optional format"
            fluid
            selection
            allowAdditions
            search
            selectOnBlur={false}
            options={_.uniqBy(options, 'value')}
          />
        );
      },
      elemType: 'dropdown',
      optionsFactory(types) {
        let options = [{ key: 'none', text: 'none', value: 'none' }];

        _.forEach(types, type => {
          options = options.concat(formatValidations[type]);
        });

        return _.uniqBy(_.compact(options), 'value');
      },
    },
  },
];

const integerValidations = [
  {
    minimum: {
      elem: <input type="number" />,
      elemType: 'text',
    },
    exclusiveMinimum: {
      elem: <JsonSchemaEditor.checkbox name="Exclusive?" />,
      elemType: 'checkbox',
    },
  },
  {
    maximum: {
      elem: <input type="number" />,
      elemType: 'text',
    },
    exclusiveMaximum: {
      elem: <JsonSchemaEditor.checkbox name="Exclusive?" />,
      elemType: 'checkbox',
    },
  },
  {
    multipleOf: {
      elem: <input type="number" placeholder=">= 0" />,
      elemType: 'text',
    },
  },
];

const numberValidations = [
  {
    minimum: {
      elem: <input type="number" />,
      elemType: 'text',
    },
    exclusiveMinimum: {
      elem: <JsonSchemaEditor.checkbox name="Exclusive?" />,
      elemType: 'checkbox',
    },
  },
  {
    maximum: {
      elem: <input type="number" />,
      elemType: 'text',
    },
    exclusiveMaximum: {
      elem: <JsonSchemaEditor.checkbox name="Exclusive?" />,
      elemType: 'checkbox',
    },
  },
  {
    multipleOf: {
      elem: <input type="number" placeholder=">= 0" />,
      elemType: 'text',
    },
  },
  {},
];

const stringValidations = [
  {
    minLength: {
      elem: <input type="number" placeholder=">= 0" />,
      elemType: 'integer',
    },
    maxLength: {
      elem: <input type="number" placeholder=">= 0" />,
      elemType: 'integer',
    },
  },
  {
    pattern: {
      elem: <input type="text" placeholder="^[A-Za-z0-9 -_]+" />,
      elemType: 'text',
    },
  },
];

const arrayValidations = [
  {
    uniqueItems: {
      elem: <JsonSchemaEditor.checkbox name="Unique Items" />,
      elemType: 'checkbox',
    },
  },
  {
    minItems: {
      elem: <input type="number" placeholder=">= 0" />,
      elemType: 'text',
    },
    maxItems: {
      elem: <input type="number" placeholder=">= 0" />,
      elemType: 'text',
    },
  },
];

const objectValidations = [
  {
    additionalProperties: {
      elem: <JsonSchemaEditor.checkbox name="Disallow Additional Properties" />,
      elemType: 'checkbox',
    },
  },
  {
    minProperties: {
      elem: <input type="number" placeholder=">= 0" />,
      elemType: 'text',
    },
    maxProperties: {
      elem: <input type="number" placeholder=">= 0" />,
      elemType: 'text',
    },
  },
];

const formatValidations = {
  integer: [
    { key: 'int32', text: 'int32', value: 'int32' },
    { key: 'int64', text: 'int64', value: 'int64' },
  ],
  number: [
    { key: 'float', text: 'float', value: 'float' },
    { key: 'double', text: 'double', value: 'double' },
  ],
  string: [
    { key: 'date', text: 'date', value: 'date' },
    { key: 'time', text: 'time', value: 'time' },
    { key: 'date-time', text: 'date-time', value: 'date-time' },
    { key: 'uri', text: 'uri', value: 'uri' },
    { key: 'email', text: 'email', value: 'email' },
    { key: 'hostname', text: 'hostname', value: 'hostname' },
    { key: 'password', text: 'password', value: 'password' },
    { key: 'ipv4', text: 'ipv4', value: 'ipv4' },
    { key: 'ipv6', text: 'ipv6', value: 'ipv6' },
    { key: 'uuid', text: 'uuid', value: 'uuid' },
    { key: 'binary', text: 'binary', value: 'binary' },
    { key: 'byte', text: 'byte', value: 'byte' },
  ],
};

const validations = {
  common: commonValidations,
  number: numberValidations,
  integer: integerValidations,
  string: stringValidations,
  array: arrayValidations,
  object: objectValidations,
};

JsonSchemaEditor.Gui = class extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    refBuilderProps: PropTypes.object,
    schema: PropTypes.object,
    disableName: PropTypes.bool,
    hideRemove: PropTypes.bool,
    definitionDropdownData: PropTypes.array.isRequired,
    rootName: PropTypes.string,
    disableAddRow: PropTypes.bool,
    readOnlyInfoProps: PropTypes.object,
    whitelistTypes: PropTypes.array,
    onChangeHandler: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    getRefLink: PropTypes.func,
    routerStore: PropTypes.object,
    specFlavor: PropTypes.string,
  };

  render() {
    const schema = this.props.schema;

    return (
      <div className="flex sl-jse_gui pos-r h-full">
        <div className="sl-jse_gui_rows">{this.reduceSchemaToRows(schema, null, 0, 0, false)}</div>
      </div>
    );
  }

  reduceSchemaToRows = (schema, path, level, index, isCombinerChild, isArrayChild) => {
    const {
      rootName,
      disableName,
      disableAddRow,
      readOnlyInfoProps,
      whitelistTypes,
      readOnly,
      getRefLink,
      hideRemove,
      routerStore,
      specFlavor,
    } = this.props;

    const rows = [];
    const isRoot = level === 0 ? true : false;
    const nextPath = path ? path : [];

    let children = [];
    let type = schema.type;
    const value = null;
    let subtype = null;
    let refPath = null;
    const name = isRoot ? rootName || 'root' : schema.name;
    let childCount = null;
    let enumValue = schema.enum;
    let format = schema.format;
    let includeThisRow = true;
    let extraProps = {};
    let subtypeExtraProps = {};
    let isOpen = false;

    // Root is isOpen by default
    if (isRoot && typeof schema._isOpen === 'undefined') {
      isOpen = true;
    } else {
      isOpen = schema._isOpen;
    }

    let combiner;
    if (schema.allOf) {
      combiner = 'allOf';
    } else if (schema.anyOf) {
      combiner = 'anyOf';
    } else if (schema.oneOf) {
      combiner = 'oneOf';
    }

    if (combiner) {
      type = combiner;
      childCount = schema[combiner] ? schema[combiner].length : 0;

      if (isArrayChild) {
        includeThisRow = false;
      }

      if (isOpen || !includeThisRow) {
        const nextLevel = includeThisRow ? level + 1 : level;
        children = this.reduceCombinerToRows(
          schema[combiner],
          nextPath.concat(combiner),
          nextLevel,
          0
        );
      }
    } else if (isOfType('object', type)) {
      if (isArrayChild) {
        includeThisRow = false;
      }

      childCount = schema.children ? schema.children.length : 0;

      if (isOpen || !includeThisRow) {
        const nextLevel = includeThisRow ? level + 1 : level;
        for (const i in schema.children) {
          if (!Object.prototype.hasOwnProperty.call(schema.children, i)) {
            continue;
          }

          children = children.concat(
            this.reduceSchemaToRows(
              schema.children[i],
              nextPath.concat(['children', i]),
              nextLevel,
              i,
              false
            )
          );
        }
      }
    } else if (isOfType('array', type)) {
      const arrayRoot = schema.children && schema.children[0] ? schema.children[0] : {};

      if (arrayRoot.allOf) {
        subtype = 'allOf';
      } else if (arrayRoot.anyOf) {
        subtype = 'anyOf';
      } else if (arrayRoot.oneOf) {
        subtype = 'oneOf';
      } else {
        subtype = arrayRoot.hasOwnProperty('$ref') ? '$ref' : arrayRoot.type;
      }

      enumValue = arrayRoot.enum;
      format = arrayRoot.format;

      if (subtype === '$ref') {
        refPath = arrayRoot.$ref;
      } else {
        subtypeExtraProps = _.omit(arrayRoot, baseSchemaProps);
      }

      if (
        isOpen &&
        subtype !== '$ref' &&
        (isInTypeGroup(combinerTypes, subtype) || isInTypeGroup(complexTypes, subtype))
      ) {
        children = children.concat(
          this.reduceSchemaToRows(
            arrayRoot,
            nextPath.concat(['children', 0]),
            level + 1,
            0,
            false,
            true
          )
        );
      }
    } else if (typeof schema === 'object' && '$ref' in schema) {
      type = '$ref';
      refPath = schema.$ref;
    } else if (schema.$ref) {
      // ref, enum, format.. some other weird potentially type-less case
      type = '$ref';
      refPath = schema.$ref;
    }

    if (includeThisRow) {
      extraProps = _.omit(schema, baseSchemaProps);
      const key = JSON.stringify(nextPath);
      rows.push(
        <JsonSchemaEditor.Row
          id={this.props.id}
          refBuilderProps={this.props.refBuilderProps}
          level={level}
          key={key}
          path={nextPath}
          disableName={disableName}
          hideRemove={hideRemove}
          name={name}
          rootName={rootName}
          disableAddRow={disableAddRow}
          readOnlyInfoProps={readOnlyInfoProps}
          whitelistTypes={whitelistTypes}
          isCombinerChild={isCombinerChild}
          type={type}
          value={value}
          subtype={subtype}
          refPath={refPath}
          enumValue={enumValue}
          format={format}
          extraProps={extraProps}
          subtypeExtraProps={subtypeExtraProps}
          childCount={childCount}
          isOpen={isOpen}
          readOnly={readOnly}
          definitionDropdownData={this.props.definitionDropdownData}
          changeHandler={this.handleChange}
          propsChangeHandler={this.handlePropsChange}
          typeChangeHandler={changeType.bind(null, this.props)}
          removeHandler={this.handleRemove}
          addHandler={this.handleAdd}
          isOpenRowHandler={this.isOpenRowHandler}
          getRefLink={getRefLink}
          routerStore={routerStore}
          specFlavor={specFlavor}
        />
      );
    }

    return rows.concat(children);
  };

  reduceCombinerToRows = (schema, path, level) => {
    let rows = [];

    for (const i in schema) {
      if (!Object.prototype.hasOwnProperty.call(schema, i)) {
        continue;
      }

      const nextPath = path.concat([i]);
      const childRows = this.reduceSchemaToRows(schema[i], nextPath, level + 1, i, true);
      rows = rows.concat(childRows);
    }

    return rows;
  };

  handleChange = (t, path, e) => {
    this.props.onChangeHandler(t, path, _.get(e, 'target.value'));
  };

  handlePropsChange = (path, props) => {
    const schema = this.props.schema;
    let target = path.length ? _.get(schema, path) : schema;
    target = _.cloneDeep(_.pick(target, baseSchemaProps));
    _.merge(target, props);
    this.props.onChangeHandler('set', path, target);
  };

  handleRemove = path => {
    const schema = _.cloneDeep(this.props.schema);
    const targetPath = _.clone(path);
    const index = targetPath.pop();
    _.pullAt(_.get(schema, targetPath), index);
    this.props.onChangeHandler('set', [], schema);
  };

  handleAdd = (parentType, parentSubtype, path, prependPath) => {
    const schema = this.props.schema;
    let newPath = path;

    // isOpen when we add
    // root is isOpen by default, so we skip that
    if (newPath.length > 0) {
      this.props.onChangeHandler('set', newPath.concat('_isOpen'), true);
    }

    if (prependPath) {
      newPath = newPath.concat(prependPath);
    }

    // We need to key into the first child, for subtypes
    if (parentSubtype) {
      newPath = newPath.concat(0);
    }

    // Default value is string, and object for combiners
    let child;
    if (isInTypeGroup(combinerTypes, parentType)) {
      child = {
        type: 'object',
        properties: {},
      };
    } else {
      child = {
        type: 'string',
      };
    }

    // Subtypes take precedence
    if (isInTypeGroup(combinerTypes, parentSubtype)) {
      newPath = newPath.concat(parentSubtype);
    } else if (isInTypeGroup(combinerTypes, parentType)) {
      newPath = newPath.concat(parentType);
    } else {
      newPath = newPath.concat('children');
    }

    const children = _.cloneDeep(_.get(schema, newPath)) || [];
    children.push(child);

    this.props.onChangeHandler('set', newPath, children);
  };

  isOpenRowHandler = path => {
    const schema = this.props.schema;
    const newPath = path.concat('_isOpen');
    if (_.get(schema, newPath)) {
      this.props.onChangeHandler('unset', newPath, null);
    } else {
      this.props.onChangeHandler('set', newPath, true);
    }
  };
};

JsonSchemaEditor.Gui = inject('routerStore')(JsonSchemaEditor.Gui);

const pluckValidationKeys = validation => {
  let k = [];
  for (const v of validation) {
    k = k.concat(Object.keys(v));
  }
  return k;
};

const pruneProps = (props, type) => {
  let allowedProps = ['required', 'deprecated', 'readOnly', 'writeOnly'];

  allowedProps = allowedProps.concat(pluckValidationKeys(validations.common));
  for (const t of type) {
    const v = validations[t];
    if (v) {
      allowedProps = allowedProps.concat(pluckValidationKeys(v));
    }
    allowedProps = _.uniq(allowedProps);
  }

  const newProps = _.pick(props, baseSchemaExtraProps.concat(allowedProps));
  if (newProps.enum) {
    newProps.enum = safeParse(newProps.enum, newProps.enum);
  } else {
    delete newProps.enum;
  }

  // additionalProperties is inverted in the gui.. we ask them to specify if we are disallowing additional
  // properties
  if (newProps.additionalProperties) {
    delete newProps.additionalProperties;
  }

  // prune empty newProps
  return _.omit(newProps, p => {
    return _.includes(['boolean', 'number'], typeof p) ? false : _.isEmpty(p);
  });
};

const saveRow = (saveHandler, { type, subtype, ref, extraProps, subtypeExtraProps }) => {
  // normalize the fields
  const cleanExtraProps = pruneProps(extraProps, type);
  const cleanSubtypeExtraProps = pruneProps(subtypeExtraProps, subtype);

  let cleanType = type;
  if (type.length === 0) {
    cleanType = null;
  } else if (type.length === 1) {
    cleanType = type[0];
  }

  let cleanSubtype = subtype;
  if (subtype.length === 0) {
    cleanSubtype = null;
  } else if (subtype.length === 1) {
    cleanSubtype = subtype[0];
  }

  let cleanRef = ref;
  if (!isOfType('$ref', type) && !isOfType('$ref', subtype)) {
    cleanRef = null;
  }

  saveHandler(cleanType, cleanSubtype, cleanRef, cleanExtraProps, cleanSubtypeExtraProps);
};

JsonSchemaEditor.TypeSelectorSection = ({
  whitelistTypes,
  sectionName,
  type,
  types,
  clickHandler,
}) => {
  const elems = [];
  let cleanTypes = types;

  // allow us to only show certain types in a json schema editor
  if (whitelistTypes) {
    cleanTypes = _.intersection(types, whitelistTypes);

    // If we're in the regular primitives section, allow whitelistTypes to add
    // arbitrary types
    if (sectionName === 'types') {
      const extraTypes = _.difference(whitelistTypes, allTypes);
      cleanTypes = cleanTypes.concat(extraTypes);
    }
  }

  if (!cleanTypes.length) {
    return null;
  }

  for (const t of cleanTypes) {
    const isOn = isOfType(t, type) || (t === 'none' && type.length === 0);

    const onClass = t === '$ref' ? 'ref' : t;
    elems.push(
      <div
        className={cn('sl-jse_row_details_section_item flex items-center justify-center', {
          [`sl--${onClass} on`]: isOn,
        })}
        key={`t-${t}`}
        onClick={() => {
          clickHandler(t === 'none' ? null : t);
        }}
      >
        {t}
      </div>
    );
  }

  return (
    <div className="sl-jse_row_details_section">
      <div className="sl-jse_row_details_section_name">{sectionName}</div>
      <div className="sl-jse_row_details_section_items sl--box-list flex">{elems}</div>
    </div>
  );
};

JsonSchemaEditor.RefSelector = ({ id, path, refPath, onChange, refBuilderProps }) => {
  return (
    <div className="sl-jse_row_details_section">
      <div className="sl-jse_row_details_section_name">$ref target</div>
      <RefBuilder
        id={`${id}:${path}`}
        $ref={refPath || ''}
        initialSource="local"
        size="small"
        force
        onComplete={value => {
          onChange(value);
        }}
        {...refBuilderProps}
      />
    </div>
  );
};

JsonSchemaEditor.TypeSelector = ({
  id,
  path,
  refBuilderProps,
  definitionDropdownData,
  whitelistTypes,
  type,
  subtype,
  refPath,
  extraProps,
  subtypeExtraProps,
  handleSave,
  handleClose,
}) => {
  const customBasicTypes = _.difference(allTypes, combinerTypes);

  let arraySubtypeSelector;
  if (isOfType('array', type)) {
    const subtypes = _.difference(allTypes, ['array']);
    arraySubtypeSelector = (
      <JsonSchemaEditor.TypeSelectorSection
        whitelistTypes={whitelistTypes}
        sectionName="array items type"
        type={subtype}
        types={['none'].concat(subtypes)}
        clickHandler={choice => {
          let newType = [];

          if (choice) {
            if (!isOfType(choice, combinerTypes)) {
              // erase any combiners
              newType = _.difference(subtype, combinerTypes);
            }

            if (choice === '$ref') {
              newType = ['$ref'];
            } else {
              _.pull(newType, '$ref');

              if (isOfType(choice, newType)) {
                _.pull(newType, choice);
              } else {
                newType.push(choice);
              }
            }
          }

          saveRow(handleSave, { type, subtype: newType, refPath, extraProps, subtypeExtraProps });
        }}
      />
    );
  }

  let refSelector;
  if (isOfType('$ref', type) || isOfType('$ref', subtype)) {
    refSelector = (
      <JsonSchemaEditor.RefSelector
        id={id}
        path={path}
        refBuilderProps={refBuilderProps}
        refPath={refPath}
        onChange={ref => {
          saveRow(handleSave, { type, subtype, ref, extraProps, subtypeExtraProps });
        }}
      />
    );
  }

  const combinerTypesSection = (
    <JsonSchemaEditor.TypeSelectorSection
      whitelistTypes={whitelistTypes}
      sectionName="combination types"
      type={type}
      types={combinerTypes}
      clickHandler={choice => {
        saveRow(handleSave, { type: choice, subtype: [], refPath, extraProps, subtypeExtraProps });
      }}
    />
  );

  const typeSection = (
    <JsonSchemaEditor.TypeSelectorSection
      whitelistTypes={whitelistTypes}
      sectionName="types"
      type={type}
      types={customBasicTypes}
      clickHandler={choice => {
        // erase any combiners
        let newType = _.difference(type, combinerTypes);

        if (choice) {
          if (choice === '$ref') {
            newType = ['$ref'];
          } else {
            _.pull(newType, '$ref');
            if (isOfType(choice, type)) {
              _.pull(newType, choice);
            } else {
              newType.push(choice);
            }
          }
        } else {
          newType = [];
        }

        // If array is no longer present, we can't have a subtype
        let newSubtype = subtype;
        if (!isOfType('array', newType)) {
          newSubtype = [];
        }

        // Can't have an array and an object at the same time
        if (choice === 'array') {
          _.pull(newType, 'object');
        } else if (choice === 'object') {
          _.pull(newType, 'array');
        }

        saveRow(handleSave, {
          type: newType,
          subtype: newSubtype,
          refPath,
          extraProps,
          subtypeExtraProps,
        });
      }}
    />
  );

  return (
    <div className="JSE-selector flex flex-col sl-schema-colors">
      {combinerTypesSection}
      {typeSection}
      {arraySubtypeSelector}
      {refSelector}
    </div>
  );
};

JsonSchemaEditor.ValidationSelectorSection = opts => {
  const {
    propKey,
    type,
    subtype,
    props,
    isCombinerChild,
    isArrayChild,
    handleUpdateProp,
    handleUpdateSelect,
    handleUpdateCheckbox,
  } = opts;

  const customValidations = _.clone(validations);
  const currentType = _.clone(type);

  // combiners, plain null type doesn't get validation..
  if (
    isInTypeGroup(combinerTypes, currentType) ||
    (isOfType('null', currentType) && currentType.length === 1)
  ) {
    return null;
  }

  // treat number and integer the same
  if (_.includes(currentType, 'number', 'integer')) {
    _.pull(currentType, 'integer');
  }

  // remove enum validation for boolean and $ref
  if (
    ((isOfType('$ref', currentType) || isOfType('boolean', currentType)) &&
      currentType.length === 1) ||
    isArrayChild
  ) {
    customValidations.common = customValidations.common.filter(v => !v.enum);
  }
  // $ref cannot be required when it's inside of a combiner
  if (isOfType('$ref', currentType) && isCombinerChild) {
    customValidations.common = customValidations.common.filter(v => !v.required);
  }

  // remove format when not applicable
  if (
    !_.some(currentType, o => _.includes(['string', 'number', 'integer', 'array'], o)) ||
    isArrayChild
  ) {
    customValidations.common = customValidations.common.filter(v => !v.format);
  }

  const sections = [];
  for (const t of ['common'].concat(currentType)) {
    let targetValidationGroups = customValidations[t];

    if (!targetValidationGroups) {
      continue;
    }

    const lineItems = [];

    for (const vg in targetValidationGroups) {
      if (!Object.prototype.hasOwnProperty.call(targetValidationGroups, vg)) {
        continue;
      }

      const columns = [];
      const targetValidations = targetValidationGroups[vg];

      for (const v in targetValidations) {
        // don't allow required on subtypes
        if (propKey === 'subtypeExtraProps' && v === 'required') {
          continue;
        }

        const validation = targetValidations[v];
        let elem = validation.elem || validation.elemFactory;

        if (!elem) {
          continue;
        }

        let name = v;
        let value = props[v];
        const newProps = {};
        const factoryProps = {};

        const handleDropdown = () => {
          newProps.value = value && value !== 'none' ? value : '';

          let optionTypes = _.clone(type);

          if (_.includes(optionTypes, 'array')) {
            optionTypes = optionTypes.concat(subtype);
          }

          const opts = validation.optionsFactory ? validation.optionsFactory(optionTypes) : [];

          factoryProps.options = opts;

          if (value) {
            factoryProps.options.push({
              key: newProps.value,
              text: newProps.value,
              value: newProps.value,
            });
          }

          factoryProps.options = _.uniq(_.compact(factoryProps.options));

          newProps.onChange = (e, { value }) => {
            let newVal = value;

            if (value === 'none') newVal = '';

            handleUpdateProp(propKey, v, newVal);
          };
        };

        switch (validation.elemType) {
          case 'checkbox':
            newProps.id = `${propKey}-${v}`;

            // additionalProperties is presented backwards in the ui
            if (v === 'additionalProperties') {
              newProps.checked = typeof value !== 'undefined' && value === false ? true : false;
            } else {
              newProps.checked = value ? true : false;
            }

            name = '';
            newProps.onChange = handleUpdateCheckbox.bind(null, propKey, v);
            break;
          case 'text':
            newProps.value = typeof value !== 'undefined' ? value : '';
            newProps.onChange = ({ target: { value } }) => handleUpdateProp(propKey, v, value);
            break;
          case 'integer':
            newProps.value = typeof value !== 'undefined' ? value : '';
            newProps.onChange = ({ target: { value } }) =>
              handleUpdateProp(propKey, v, value === '' ? value : parseInt(value));
            break;
          case 'select':
            newProps.value = typeof value !== 'undefined' ? value : '';
            newProps.options = _.map(value, v => ({ text: String(v), value: v }));
            newProps.onChange = handleUpdateSelect.bind(null, propKey, v);
            break;

          case 'dropdown':
            handleDropdown();
            break;
        }

        elem = React.cloneElement(
          validation.elemFactory ? validation.elemFactory(factoryProps) : elem,
          newProps
        );

        columns.push(
          <div
            className="sl-jse_row_details_line_item_column flex-1 flex items-center"
            key={`c-${v}`}
          >
            <span className="sl--name">{name}</span>
            <span className="sl--input flex-1">{elem}</span>
          </div>
        );
      }

      if (columns.length) {
        lineItems.push(
          <div className="sl-jse_row_details_line_item  flex items-center" key={`li-${vg}`}>
            {columns}
          </div>
        );
      }
    }

    if (lineItems.length) {
      sections.push(
        <div className="sl-jse_row_details_section sl--validation flex flex-col" key={`s-${t}`}>
          <div className="sl-jse_row_details_section_name">
            {propKey === 'subtypeExtraProps' ? `array[${t}] validations` : `${t} validations`}
          </div>
          <div className="sl-jse_row_details_line_items flex flex-col">{lineItems}</div>
        </div>
      );
    }
  }

  return <div className="JSE-validationSection pb-4">{sections}</div>;
};

JsonSchemaEditor.ValidationSelector = props => {
  const {
    type,
    subtype,
    extraProps,
    subtypeExtraProps,
    isCombinerChild,
    handleUpdateProp,
    handleUpdateSelect,
    handleUpdateCheckbox,
  } = props;

  let subtypeValidationSection;
  if (isOfType('array', type)) {
    if (subtype.length) {
      subtypeValidationSection = (
        <JsonSchemaEditor.ValidationSelectorSection
          propKey="subtypeExtraProps"
          type={subtype}
          props={subtypeExtraProps}
          isArrayChild={true}
          handleUpdateProp={handleUpdateProp}
          handleUpdateSelect={handleUpdateSelect}
          handleUpdateCheckbox={handleUpdateCheckbox}
        />
      );
    }
  }

  return (
    <div className="JSE-validationSelector JSE-selector flex flex-col">
      <JsonSchemaEditor.ValidationSelectorSection
        propKey="extraProps"
        type={type}
        props={extraProps}
        subtype={subtype}
        isCombinerChild={isCombinerChild}
        handleUpdateProp={handleUpdateProp}
        handleUpdateSelect={handleUpdateSelect}
        handleUpdateCheckbox={handleUpdateCheckbox}
      />

      {subtypeValidationSection}
    </div>
  );
};

JsonSchemaEditor.Row = class extends React.Component {
  static propTypes = {
    refBuilderProps: PropTypes.object,
    id: PropTypes.string,
    level: PropTypes.number,
    path: PropTypes.array.isRequired,
    name: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    subtype: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    refPath: PropTypes.string,
    enumValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    format: PropTypes.string,
    extraProps: PropTypes.object,
    subtypeExtraProps: PropTypes.object,
    rootName: PropTypes.string,
    disableAddRow: PropTypes.bool,
    readOnlyInfoProps: PropTypes.object,
    whitelistTypes: PropTypes.array,
    childCount: PropTypes.number,
    isCombinerChild: PropTypes.bool,
    disableName: PropTypes.bool,
    hideRemove: PropTypes.bool,
    isOpen: PropTypes.bool,
    definitionDropdownData: PropTypes.array.isRequired,
    changeHandler: PropTypes.func.isRequired,
    propsChangeHandler: PropTypes.func.isRequired,
    typeChangeHandler: PropTypes.func.isRequired,
    removeHandler: PropTypes.func.isRequired,
    addHandler: PropTypes.func.isRequired,
    isOpenRowHandler: PropTypes.func.isRequired,
    getRefLink: PropTypes.func,
    routerStore: PropTypes.object,
    specFlavor: PropTypes.string,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
  }

  key = () => {
    return JSON.stringify(this.props.path);
  };

  render() {
    const {
      level,
      type,
      subtype,
      refPath,
      extraProps,
      subtypeExtraProps,
      readOnly,
      rootName,
      disableAddRow,
      isCombinerChild,
      hideRemove,
      specFlavor,
    } = this.props;

    let propPrefix = '';
    if (specFlavor === 'oas2') {
      propPrefix = 'x-';
    }

    const innerStyle = {};

    // root name means we're doing headers or query string, no initial indentation
    if (rootName) {
      innerStyle.paddingLeft = (level > 0 ? level - 1 : level) * 15 + 55;
    } else {
      innerStyle.paddingLeft = level * 15 + 55;
    }

    let validationElem;
    let readWriteElem;
    let requiredElem;
    let nullableElem;

    if (isInTypeGroup(realTypes, type)) {
      if (type !== '$ref') {
        const numValidations = _.uniq(
          Object.keys(
            _.merge(
              _.pick(extraProps, distinctValidationProps),
              _.pick(subtypeExtraProps, distinctValidationProps)
            )
          )
        ).length;

        if (type !== 'boolean') {
          validationElem = (
            <Popup
              on="click"
              position="left center"
              wide="very"
              size="small"
              triggerFactory={() => {
                return (
                  <div className="sl-jse_row_meta_item is-clickable flex items-center">
                    {`${numValidations} validations`}
                  </div>
                );
              }}
              contentFactory={() => {
                return this.renderValidationSelector();
              }}
            />
          );
        }

        if (specFlavor === 'oas3') {
          let readWriteText = 'Read + Write';
          let popupText =
            'This property is relevant to both read and write contexts. For example, request and response bodies.';
          if (extraProps.readOnly) {
            readWriteText = 'Read Only';
            popupText =
              'This property is relevant only in read contexts. For example, a response body.';
          } else if (extraProps.writeOnly) {
            readWriteText = 'Write Only';
            popupText =
              'This property is relevant only in write contexts. For example, a request body.';
          }

          readWriteElem = (
            <Popup
              content={popupText}
              triggerFactory={() => {
                return (
                  <div
                    className={`sl-jse_row_meta_item is-clickable flex items-center ${
                      extraProps.readOnly || extraProps.writeOnly ? 'is-on' : ''
                    }`}
                    onClick={() => {
                      const props = _.clone(extraProps);
                      if (props.readOnly) {
                        delete props.readOnly;
                        props.writeOnly = true;
                      } else if (props.writeOnly) {
                        delete props.writeOnly;
                      } else {
                        props.readOnly = true;
                      }

                      this.handleSaveDetails(type, subtype, refPath, props, subtypeExtraProps);
                    }}
                  >
                    {readWriteText}
                  </div>
                );
              }}
            />
          );
        }

        // not using nullable for now, until support in prism
        // if (false && (specFlavor === 'oas2' || specFlavor === 'oas3')) {
        //   nullableElem = (
        //     <div
        //       className={`sl-jse_row_meta_item is-clickable flex items-center ${
        //         extraProps[`${propPrefix}nullable`] ? 'is-on' : ''
        //       }`}
        //       onClick={() => {
        //         const props = _.clone(extraProps);
        //         if (props[`${propPrefix}nullable`]) {
        //           delete props[`${propPrefix}nullable`];
        //         } else {
        //           props[`${propPrefix}nullable`] = true;
        //         }

        //         this.handleSaveDetails(type, subtype, refPath, props, subtypeExtraProps);
        //       }}
        //     >
        //       <span className="mr-2">
        //         <Icon
        //           fitted
        //           name={extraProps[`${propPrefix}nullable`] ? 'checkmark box' : 'square outline'}
        //         />
        //       </span>
        //       Nullable
        //     </div>
        //   );
        // }
      }

      if (!isCombinerChild) {
        requiredElem = (
          <div
            className={`sl-jse_row_meta_item is-clickable flex items-center ${
              extraProps.required ? 'is-on' : ''
            }`}
            onClick={() => {
              const props = _.clone(extraProps);
              if (props.required) {
                delete props.required;
              } else {
                props.required = true;
              }

              this.handleSaveDetails(type, subtype, refPath, props, subtypeExtraProps);
            }}
          >
            <span className="mr-2">
              <Icon fitted name={extraProps.required ? 'checkmark box' : 'square outline'} />
            </span>
            Required
          </div>
        );
      }
    }

    return (
      <div className={`sl-jse_row sl--${level}`}>
        {!readOnly && type !== '$ref' && this.renderBasicsHandle()}
        {!readOnly && !disableAddRow && this.renderAddButton()}

        <div className="sl-jse_row_wrapper">
          <div className="sl-jse_row_inner flex w-full" style={innerStyle}>
            {this.renderCollapseHandle()}
            {this.renderName()}
            {this.renderType()}
            {this.renderChildCount()}

            <div className="flex-1" />

            <div className="sl-jse_row_meta  flex">
              {validationElem}
              {readWriteElem}
              {nullableElem}
              {requiredElem}
              {!readOnly && !hideRemove && this.renderRemoveButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAddButton = () => {
    const { type, subtype, addHandler, path } = this.props;

    let includeButton = false;
    const addForType = type;
    let addForSubtype = null;
    const addToPath = path;
    let prependPath = null;

    if (isInTypeGroup(rootTypes, type) && !isOfType('array', type)) {
      includeButton = true;
    } else if (isInTypeGroup(rootTypes, subtype) && !isOfType('array', subtype)) {
      includeButton = true;
      addForSubtype = subtype;

      // If we're working within an array subtype, we need to prepend children, which
      // is where array subtypes are keyed
      prependPath = 'children';
    }

    if (includeButton) {
      return (
        <div
          className="sl-jse_row_add  flex items-center justify-center"
          onClick={() => {
            addHandler(addForType, addForSubtype, addToPath, prependPath);
          }}
        >
          <Icon fitted name="plus" />
        </div>
      );
    }

    return '';
  };

  renderCollapseHandle = () => {
    const { level, type, subtype, isOpen, isOpenRowHandler, path } = this.props;

    // no handle for root node
    if (level === 0) {
      return '';
    }

    if (
      (isInTypeGroup(rootTypes, type) && !isOfType('array', type)) ||
      isInTypeGroup(rootTypes, subtype)
    ) {
      return (
        <div className="sl-jse_row_collapse-handle">
          <div
            className="sl-jse_row_collapse-handle_inner  flex items-center justify-center"
            onClick={() => {
              isOpenRowHandler(path);
            }}
          >
            <Icon fitted name={isOpen ? 'caret down' : 'caret right'} />
          </div>
        </div>
      );
    }

    return '';
  };

  renderName = () => {
    const {
      level,
      name = '',
      disableName,
      isCombinerChild,
      path,
      changeHandler,
      readOnly,
      rootName,
    } = this.props;

    if (isCombinerChild || (level === 0 && !rootName)) {
      return '';
    }

    if (readOnly) {
      return <div className="flex items-center sl-jse_name sl--disabled">{name}</div>;
    }

    if (level === 0 || disableName) {
      return (
        <div className="flex items-center sl-jse_name sl--disabled">
          <AutosizeInput value={name} type="text" readOnly disabled />
        </div>
      );
    }

    return (
      <div className="flex items-center sl-jse_name">
        <AutosizeInput
          type="text"
          value={name}
          readOnly={readOnly}
          onChange={e => {
            changeHandler('set', path.concat('name'), e);
          }}
          placeholder="field"
        />
      </div>
    );
  };

  renderBasicsHandle = () => {
    const { name, level, rootName, extraProps } = this.props;

    // If we're overriding the root name, we don't allow modifications here
    let disabled;
    if (level === 0 && rootName) {
      disabled = true;
    }

    const { description, example, deprecated } = extraProps;

    let active = description || example || deprecated || extraProps.default;

    return (
      <Popup
        triggerFactory={() => {
          return (
            <div
              className={cn('sl-jse_row_details_handle items-center justify-center flex', {
                'sl--disabled': disabled,
                'is-active': active,
              })}
              title={disabled ? '' : `View ${name} property details.`}
            >
              <Icon fitted name="pencil" />
            </div>
          );
        }}
        contentFactory={() => {
          return this.renderBasicsSection();
        }}
        on="click"
        position="top right"
        wide="very"
        size="small"
      />
    );
  };

  renderBasicsSection = () => {
    const { currentExtraProps } = _calculateRowState(this.props);

    const { id, path, hideDefault, changeHandler } = this.props;

    let deprecatedValue = currentExtraProps.deprecated;
    let defaultValue = currentExtraProps.default;
    let example = currentExtraProps.example;
    if (!hideDefault && defaultValue && typeof defaultValue === 'object') {
      try {
        defaultValue = JSON.stringify(defaultValue);
      } catch (e) {}
    }

    return (
      <div className="sl-jse_row_basics">
        <div className="sl-jse_row_details_section">
          <div className="sl-jse_row_details_section_items">
            <div className="sl-jse_row_details_line_items">
              <div className="sl-jse_row_details_line_item flex items-center">
                <span className="sl--input flex-1">
                  <JsonSchemaEditor.checkbox
                    id="deprecated"
                    name="Deprecated? (Not supported in OAS2)"
                    checked={deprecatedValue}
                    onClick={() => {
                      if (deprecatedValue) {
                        changeHandler('unset', path.concat(['deprecated']));
                      } else {
                        changeHandler('set', path.concat(['deprecated']), {
                          target: {
                            value: true,
                          },
                        });
                      }
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="sl-jse_row_details_section">
          {!hideDefault ? (
            <div className="sl-jse_row_details_section_name">Default Value</div>
          ) : null}

          {!hideDefault ? (
            <div className="sl-jse_row_details_section_items">
              <div className="sl-jse_row_details_line_items">
                <div className="sl-jse_row_details_line_item flex items-center">
                  <span className="sl--input flex-1">
                    <input
                      type="text"
                      value={defaultValue || ''}
                      onChange={e => {
                        if (_.isEmpty(e.target.value)) {
                          changeHandler('unset', path.concat(['default']));
                        } else {
                          changeHandler('set', path.concat(['default']), e);
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="sl-jse_row_details_section">
          {!hideDefault ? <div className="sl-jse_row_details_section_name">Example</div> : null}

          {!hideDefault ? (
            <div className="sl-jse_row_details_section_items">
              <div className="sl-jse_row_details_line_items">
                <div className="sl-jse_row_details_line_item flex items-center">
                  <span className="sl--input flex-1">
                    <input
                      type="text"
                      value={_.toString(example) || ''}
                      onChange={e => {
                        if (_.isEmpty(e.target.value)) {
                          changeHandler('unset', path.concat(['example']));
                        } else {
                          changeHandler('set', path.concat(['example']), e);
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="sl-jse_row_details_section">
          <div className="sl-jse_row_details_section_name">Description</div>
          <div className="sl-jse_row_details_section_items">
            <div className="sl-jse_row_details_line_items">
              <SimpleMdEditor
                id={`${id}:${path}`}
                className="no-border"
                placeholder="Write markdown here..."
                value={currentExtraProps.description || ''}
                onChange={({ value }) => {
                  if (_.isEmpty(value)) {
                    changeHandler('unset', path.concat(['description']));
                  } else {
                    changeHandler('set', path.concat(['description']), {
                      target: {
                        value,
                      },
                    });
                  }
                }}
                hideToolbar
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleSaveDetails = (newType, newSubtype, newRef, newProps, newSubtypeProps) => {
    const {
      path,
      type,
      subtype = null,
      refPath,
      subtypeExtraProps,
      typeChangeHandler,
      propsChangeHandler,
    } = this.props;

    if (!(_.isArray(type) && type.length === 1) && !(_.isArray(newType) && newType.length === 1)) {
      if (type !== newType || subtype !== newSubtype || newRef !== refPath) {
        typeChangeHandler(null, type, newType, path, subtype, newSubtype, newRef);
        return;
      }
    }

    if (!_.isEqual(subtypeExtraProps, newSubtypeProps)) {
      propsChangeHandler(path.concat(['children', 0]), newSubtypeProps);
    } else {
      propsChangeHandler(path, newProps);
    }
  };

  renderType = () => {
    const {
      level,
      type,
      isCombinerChild,
      subtype,
      enumValue,
      format,
      refPath,
      rootName,
      getRefLink,
      routerStore,
    } = this.props;

    if (level === 0 && rootName) {
      return '';
    }

    let types;
    if (type instanceof Array) {
      types = type;
    } else {
      types = [type];
    }

    let refLink;
    const typeElems = [];
    for (const t in types) {
      if (!Object.prototype.hasOwnProperty.call(types, t)) {
        continue;
      }

      let innerType = types[t];
      let subtypeText = innerType !== subtype ? subtype || '' : '';
      let specialText = '';

      if (!innerType) {
        innerType = 'noType';
      }

      if (refPath && innerType === '$ref') {
        specialText = ` [${exportUrlToSrn({ url: refPath })}]`;

        if (_.startsWith(refPath, '#/')) {
          let path;
          if (getRefLink) {
            path = getRefLink(refPath);
          } else {
            // convert back and forth to take care of things like / -> ~
            path = routerStore.buildQueryParams(
              { edit: pathToHash({ path: hashToPath({ hash: refPath }) }) },
              { preserve: true }
            );
          }

          refLink = (
            <Link className="JSE-refLink" to={path}>
              (go to ref)
            </Link>
          );
        }
      } else if (innerType !== 'null') {
        if (enumValue) {
          specialText += ` :: enum[${
            typeof enumValue === 'string' || !enumValue.join ? 'invalid enum' : enumValue.join(', ')
          }]`;
        }

        if (format) {
          specialText += ` :: format( ${format} )`;
        }
      }

      if (innerType === 'array') {
        if (refPath) {
          subtypeText = `[$ref( ${refPath} )]`;

          if (getRefLink) {
            refLink = <a href={getRefLink(refPath)}>(go to ref)</a>;
          }
        } else if (subtypeText) {
          subtypeText = `[${subtypeText}${specialText}]`;
        }
        specialText = '';
      } else {
        subtypeText = '';
      }

      typeElems.push(
        <span className="sl-jse_row_type" key={this.key() + innerType}>
          <span
            className={`sl--${innerType === '$ref' ? 'ref' : innerType}`}
            dangerouslySetInnerHTML={{ __html: innerType + subtypeText + specialText }}
          />
          <span className="sl--or">{types[parseInt(t) + 1] ? 'or' : ''}</span>
        </span>
      );
    }

    let spacer;
    if (!isCombinerChild && (level > 0 || rootName)) {
      spacer = <span className="sl--spacer">:</span>;
    }

    return (
      <div className="inline-flex items-center sl-jse_row_types">
        {spacer}

        <Popup
          triggerFactory={() => {
            return <span className="flex-1">{typeElems.length ? typeElems : '--'}</span>;
          }}
          contentFactory={() => {
            return this.renderTypeSelector();
          }}
          on="click"
          position="right center"
          wide="very"
          size="small"
        />

        {refLink}
      </div>
    );
  };

  renderTypeSelector = () => {
    const {
      currentType,
      currentSubtype,
      currentRef,
      currentExtraProps,
      currentSubtypeExtraProps,
    } = _calculateRowState(this.props);

    const { id, path, definitionDropdownData, whitelistTypes, refBuilderProps } = this.props;

    return (
      <JsonSchemaEditor.TypeSelector
        id={id}
        path={path}
        refBuilderProps={refBuilderProps}
        definitionDropdownData={definitionDropdownData}
        whitelistTypes={whitelistTypes}
        type={currentType}
        subtype={currentSubtype}
        refPath={currentRef}
        extraProps={currentExtraProps}
        subtypeExtraProps={currentSubtypeExtraProps}
        handleSave={this.handleSaveDetails}
      />
    );
  };

  renderValidationSelector = () => {
    const {
      currentType,
      currentSubtype,
      currentRef,
      currentExtraProps,
      currentSubtypeExtraProps,
    } = _calculateRowState(this.props);

    return (
      <JsonSchemaEditor.ValidationSelector
        type={currentType}
        subtype={currentSubtype}
        extraProps={currentExtraProps}
        subtypeExtraProps={currentSubtypeExtraProps}
        handleUpdateProp={(propKey, field, value) => {
          let target;
          switch (propKey) {
            case 'extraProps':
              target = currentExtraProps;
              break;
            case 'subtypeExtraProps':
              target = currentSubtypeExtraProps;
              break;
            default:
          }

          if (value === '') {
            delete target[field];
          } else {
            target[field] = value;
          }

          saveRow(this.handleSaveDetails, {
            type: currentType,
            subtype: currentSubtype,
            ref: currentRef,
            extraProps: currentExtraProps,
            subtypeExtraProps: currentSubtypeExtraProps,
          });
        }}
        handleUpdateSelect={(propKey, field, value) => {
          let target;
          switch (propKey) {
            case 'extraProps':
              target = currentExtraProps;
              break;
            case 'subtypeExtraProps':
              target = currentSubtypeExtraProps;
              break;
            default:
          }

          if (_.isEmpty(value)) {
            delete target[field];
          } else {
            target[field] = _.map(value, v => safeParse(v, v));
          }

          saveRow(this.handleSaveDetails, {
            type: currentType,
            subtype: currentSubtype,
            ref: currentRef,
            extraProps: currentExtraProps,
            subtypeExtraProps: currentSubtypeExtraProps,
          });
        }}
        handleUpdateCheckbox={(propKey, field) => {
          let target;
          switch (propKey) {
            case 'extraProps':
              target = currentExtraProps;
              break;
            case 'subtypeExtraProps':
              target = currentSubtypeExtraProps;
              break;
            default:
          }

          if (field === 'required' && target.required) {
            delete target.required;

            // additionalProperties is reversed in ui
          } else if (
            field === 'additionalProperties' &&
            _.isUndefined(target.additionalProperties)
          ) {
            target[field] = false;
          } else {
            target[field] = !target[field];
          }

          saveRow(this.handleSaveDetails, {
            type: currentType,
            subtype: currentSubtype,
            ref: currentRef,
            extraProps: currentExtraProps,
            subtypeExtraProps: currentSubtypeExtraProps,
          });
        }}
      />
    );
  };

  renderChildCount = () => {
    const { childCount, type, level, rootName } = this.props;

    if (level === 0 && rootName) {
      return '';
    }

    let countElem;
    if (childCount !== null) {
      if ((type instanceof Array && _.includes(type, 'array')) || type === 'array') {
        countElem = `[${childCount}]`;
      } else {
        countElem = `{${childCount}}`;
      }
      return (
        <div className="sl-jse_row_child-count flex items-center justify-center">{countElem}</div>
      );
    }

    return '';
  };

  renderRemoveButton = () => {
    const { path, removeHandler } = this.props;

    return (
      <div
        className="sl-jse_row_meta_item sl--remove items-center justify-center flex"
        onClick={() => {
          removeHandler(path);
        }}
        title={'remove this property.'}
      >
        <Icon fitted name="remove circle" />
      </div>
    );
  };
};

const _calculateRowState = props => {
  const extraProps = _.clone(props.extraProps || {});
  const subtypeExtraProps = _.clone(props.subtypeExtraProps || {});

  // convert to array for easier logic here
  let currentType = [];
  if (props.type instanceof Array) {
    currentType = props.type;
  } else if (props.type) {
    currentType = [props.type];
  }

  return {
    currentType,
    currentSubtype: _.isArray(props.subtype) ? props.subtype : props.subtype ? [props.subtype] : [],
    currentRef: props.refPath,
    currentExtraProps: extraProps,
    currentSubtypeExtraProps: subtypeExtraProps,
  };
};

export default JsonSchemaEditor;
