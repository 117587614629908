import { ICommandRegistry, SYMBOLS as COMMAND_SYMBOLS } from '@core/command';
import { Collection as DisposableCollection } from '@core/disposable';
import { IEditor, IEditorMode, IModeChangedEvent, SYMBOLS as EDITOR_SYMBOLS } from '@core/editor';
import { lazyInject } from '@core/ioc';
import { IMenuRegistry, SYMBOLS as MENU_SYMBOLS } from '@core/menu';
import { IExtension } from '@core/types';
import { IIcon } from '@core/ui';

export interface ISpec extends IExtension {
  readonly id: string;
  readonly name: string;
  readonly longName: string;
  readonly supportedModes?: IEditorMode[];
  readonly icon?: IIcon;

  readonly menuRegistry: IMenuRegistry;
  readonly commandRegistry: ICommandRegistry;
}

export class Spec implements ISpec {
  public readonly id: string = '';
  public readonly name: string = '';
  public readonly longName: string = '';
  public readonly supportedModes?: IEditorMode[];

  @lazyInject(EDITOR_SYMBOLS.Editor)
  // @ts-ignore
  public readonly editor: IEditor;

  @lazyInject(MENU_SYMBOLS.MenuRegistry)
  // @ts-ignore
  public readonly menuRegistry: IMenuRegistry;

  @lazyInject(COMMAND_SYMBOLS.CommandRegistry)
  // @ts-ignore
  public readonly commandRegistry: ICommandRegistry;

  protected registerCommands?: (evt: IModeChangedEvent) => void;
  protected registerMenus?: (evt: IModeChangedEvent) => void;
  protected registerPlugins?: (evt: IModeChangedEvent) => void;
  protected onDidChangeMode?: (evt: IModeChangedEvent) => void;

  protected readonly disposables: DisposableCollection = new DisposableCollection();
  protected commands: DisposableCollection = new DisposableCollection();
  protected menus: DisposableCollection = new DisposableCollection();
  protected transforms: DisposableCollection = new DisposableCollection();
  protected linters: DisposableCollection = new DisposableCollection();
  private _isActivated = false;

  public dispose = () => {
    this.deactivate();
  };

  public activate = () => {
    if (this._isActivated) return;
    this._isActivated = true;

    this.disposables.push(this.editor.onDidChangeMode(this._onDidChangeMode));
    this._activateModes();

    const activeMode = this.editor.activeMode;
    this._registerCommands({ mode: activeMode });
    this._registerMenus({ mode: activeMode });
  };

  public deactivate = () => {
    if (!this._isActivated) return;
    this._isActivated = false;

    this.disposables.dispose();
    this.commands.dispose();
    this.menus.dispose();
    this.transforms.dispose();
    this.linters.dispose();
  };

  private _activateModes = () => {
    if (this.supportedModes) this.editor.setEnabledModes(this.supportedModes);
  };

  private _registerCommands = (evt: IModeChangedEvent) => {
    // cleanup old commands when registering
    this.commands.dispose();

    if (this.registerCommands) this.registerCommands(evt);
  };

  private _registerMenus = (evt: IModeChangedEvent) => {
    // cleanup old menus when registering
    this.menus.dispose();

    if (this.registerMenus) this.registerMenus(evt);
  };

  private _onDidChangeMode = (evt: IModeChangedEvent) => {
    if (this.onDidChangeMode) this.onDidChangeMode(evt);

    // re-paint menus on mode change
    this._registerMenus(evt);
  };
}
