import { action, observable, reaction } from 'mobx';

import { ICommandRegistry, SYMBOLS as CommandSymbols } from '@core/command';
import * as disposable from '@core/disposable';
import { IDisposable } from '@core/disposable';
import { inject, provideSingleton } from '@core/ioc';
import { colors } from '@core/ui';
import { IIcon } from '@core/ui';

import {
  IActionMenuNode,
  IMenuAction,
  IMenuNode,
  IMenuNodeOpts,
  IMenuRegistry,
  MenuPath,
  SYMBOLS,
} from './types';

export const MAIN_MENU_BAR: MenuPath = ['menubar'];

export class CompositeMenuNode implements IMenuNode, IDisposable {
  public label: string;
  public description?: string;
  public icon?: IIcon;
  public color?: colors;
  public active?: boolean;
  public commandId?: string;

  @observable
  protected readonly _children: IMenuNode[] = [];

  constructor(public readonly id: string, public opts: IMenuNodeOpts = {}) {
    this.label = opts.label || '';
    this.description = opts.description || '';
    this.icon = opts.icon;
    this.color = opts.color;
    this.commandId = opts.commandId;
  }

  get children(): ReadonlyArray<IMenuNode> {
    return this._children;
  }

  get sortString() {
    return this.id;
  }

  get isSubmenu(): boolean {
    return this.opts.label ? true : false;
  }

  public getNodeIndex(node: IMenuNode) {
    return this._children.findIndex(c => c.id === node.id);
  }

  public getNode(node: IMenuNode) {
    return this._children[this.getNodeIndex(node)];
  }

  @action.bound
  public removeNode(node: IMenuNode) {
    const idx = this.getNodeIndex(node);
    if (idx >= 0) {
      this._children.splice(idx, 1);
    }
  }

  @action.bound
  public addNode(node: IMenuNode): IDisposable {
    this._children.push(node);

    this._children.sort((m1, m2) => {
      if (!m1.order || !m2.order) {
        return 0;
      }

      if (m1.order < m2.order) {
        return -1;
      } else if (m1.order > m2.order) {
        return 1;
      } else {
        return 0;
      }
    });

    return disposable.create(() => {
      this.removeNode(node);
    });
  }

  public dispose() {
    // noop
  }
}

export class ActionMenuNode implements IActionMenuNode, IDisposable {
  public id: string;
  public commandId: string;

  @observable
  public label?: string;

  @observable
  public description?: string;

  @observable
  public icon?: IIcon;

  @observable
  public color?: colors;

  @observable
  public loading?: boolean;

  @observable
  public disabled?: boolean;

  @observable
  public active?: boolean;

  private _reactionDisposer: any;

  constructor(menuAction: IMenuAction, protected readonly commands: ICommandRegistry) {
    this.id = menuAction.id;
    this.commandId = menuAction.commandId;
    this.loading = menuAction.loading;
    this.disabled = menuAction.disabled;
    this.active = menuAction.active;

    if (menuAction.data) {
      this._reactionDisposer = reaction(
        (): IMenuNodeOpts => (menuAction.data ? menuAction.data() : {}),
        this._updateData,
        {
          fireImmediately: true,
        }
      );
    } else {
      this._updateData(menuAction);
    }
  }

  public execute = (...args: any[]): Promise<any | undefined> => {
    return this.commands.executeCommand(this.commandId, ...args);
  };

  public dispose() {
    if (this._reactionDisposer) {
      this._reactionDisposer();
    }
  }

  @action.bound
  private _updateData(opts: IMenuNodeOpts) {
    this.label = opts.label;
    this.description = opts.description;
    this.icon = opts.icon;
    this.color = opts.color;
  }
}

@provideSingleton(SYMBOLS.MenuRegistry)
export class MenuRegistry implements IMenuRegistry {
  protected readonly root = new CompositeMenuNode('');
  protected menuItemsByCommandId: {
    [key: string]: IActionMenuNode[];
  } = {};

  @inject(CommandSymbols.CommandRegistry)
  // @ts-ignore
  private commands: ICommandRegistry;

  public getMenu(menuPath: MenuPath = []): CompositeMenuNode {
    return this.findGroup(menuPath);
  }

  public updateActionsForCommandId(
    commandId: string,
    transformer: (node: IActionMenuNode) => void
  ): void {
    const commandGroup = this.menuItemsByCommandId[commandId] || [];

    for (const i in commandGroup) {
      if (!commandGroup[i]) continue;

      const node = commandGroup[i];
      transformer(node);
    }
  }

  public registerMenuAction(menuPath: MenuPath, item: IMenuAction): IDisposable {
    const parent = this.findGroup(menuPath);
    const actionNode = new ActionMenuNode(item, this.commands);

    const node = parent.addNode(actionNode);
    const mapping = this.registerCommandIdMapping(item.commandId, actionNode);

    return disposable.create(() => {
      node.dispose();
      mapping.dispose();
    });
  }

  public registerSubmenu(menuPath: MenuPath, opts: IMenuNodeOpts = {}): IDisposable {
    if (menuPath.length === 0) {
      throw new Error('The sub menu path cannot be empty.');
    }

    const index = menuPath.length - 1;
    const menuId = menuPath[index];
    const groupPath = index === 0 ? [] : menuPath.slice(0, index);
    const parent = this.findGroup(groupPath);

    let groupNode = this.findSubMenu(parent, menuId, opts);
    if (!groupNode) {
      groupNode = new CompositeMenuNode(menuId, opts);
      return parent.addNode(groupNode);
    }

    if (!groupNode.label) {
      groupNode.label = opts.label || '';
    } else if (groupNode.label !== opts.label) {
      throw new Error("The group '" + menuPath.join('/') + "' already has a different label.");
    }

    return disposable.create(() => {
      if (parent) {
        parent.removeNode(groupNode);
      }
    });
  }

  @action.bound
  protected registerCommandIdMapping(commandId: string, node: ActionMenuNode): IDisposable {
    const commandGroup = this.menuItemsByCommandId[commandId] || [];
    commandGroup.push(node);
    this.menuItemsByCommandId[commandId] = commandGroup;

    return disposable.create(() => {
      const commandGroupx = this.menuItemsByCommandId[commandId];
      if (commandGroupx && node) {
        const idx = this.menuItemsByCommandId[commandId].indexOf(node);
        if (idx >= 0) {
          this.menuItemsByCommandId[commandId].splice(idx, 1);
        }
      }
    });
  }

  protected findGroup(menuPath: MenuPath): CompositeMenuNode {
    let currentMenu = this.root;
    for (const segment of menuPath) {
      currentMenu = this.findSubMenu(currentMenu, segment);
    }

    return currentMenu;
  }

  protected findSubMenu(
    current: CompositeMenuNode,
    menuId: string,
    opts: IMenuNodeOpts = {}
  ): CompositeMenuNode {
    const sub = current.children.find(e => e.id === menuId);
    if (sub instanceof CompositeMenuNode) {
      return sub;
    }

    if (sub) {
      throw Error(`'${menuId}' is not a menu group.`);
    }

    const newSub = new CompositeMenuNode(menuId, opts);
    current.addNode(newSub);

    return newSub;
  }
}
