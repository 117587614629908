import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Popup, Button, Segment, Header } from 'semantic-ui-react';

import ErrorMessage from '@platform/components/ErrorMessage';
import MarkdownEditor from '@platform/components/MarkdownEditor';
import FormTitleInput from '@platform/components/FormTitleInput';
import ScenarioStep from '@platform/components/ScenarioEditor/step';
import EntityEditorGroup from '@platform/components/EntityEditorGroup';
import EditorContentSection from '@platform/components/EditorContentSection';

import { encodeFileId } from '@platform/utils/instances';

import InstanceApis from './apis';
import InstanceRules from './rules';
import InstanceUrl from './instanceUrl';
import InstanceTerminal from './instanceTerminal';

class Overview extends React.Component {
  render() {
    const { collectionEditorStore, projectStore, editor, userService } = this.props;

    const collectionEditor = collectionEditorStore.getEditor({ id: editor.id });
    const currentRunning = editor.currentRunning;
    const fileName = _.get(projectStore, 'current.currentFileName', '');
    const fileId = _.get(projectStore, 'current.currentFileId', '');
    const fileHash = _.get(projectStore, 'current.currentFileHash', '');

    return (
      <div className="InstanceOverview">
        <EditorContentSection>
          {fileHash.length > 63 ? (
            <ErrorMessage
              className="mb-6"
              error={`The '${fileName}' file name is too long, please give it a shorter name.`}
            />
          ) : null}

          <FormTitleInput
            className="mb-6 mt-3"
            value={_.get(editor, 'parsed.name', '')}
            placeholder="Instance Name..."
            onChange={(e, { value }) => {
              editor.updateParsed('set', ['name'], value);
            }}
            inputStyle={{
              padding: 5,
              fontWeight: 'bold',
            }}
          />

          <MarkdownEditor
            id={editor.id}
            className="CollectionEditor-notes mt-3"
            placeholder="Optional instance description..."
            value={_.get(editor, 'parsed.description', '')}
            onChange={markdownVal => {
              editor.updateParsed('set', ['description'], markdownVal);
            }}
          />

          <EntityEditorGroup
            id="tutorial-instance-triggers"
            className="mt-6"
            name="Serve This Prism Instance"
            padded
            contentFactory={() => (
              <div>
                <InstanceTerminal
                  editorId={editor.id}
                  message={
                    userService.isLoggedIn ? null : 'Login or register to serve from terminal.'
                  }
                />
                <br />
                <InstanceUrl />
              </div>
            )}
          />

          <Segment>
            <div className="mb-4">
              <Header
                as="h4"
                dividing
                className="mb-3"
                content="Send Test Requests"
                subheader={`Instance URL: ${editor.instanceUrl}`}
              />

              <Button.Group size="tiny">
                {currentRunning ? (
                  <Button
                    negative
                    icon="stop"
                    content="Stop"
                    onClick={() => {
                      collectionEditor.stopStep();
                    }}
                  />
                ) : (
                  <Button
                    icon="play"
                    content="Send"
                    secondary
                    onClick={() => {
                      collectionEditor.runStep(undefined, {
                        spec: editor.parsed,
                        headers: {
                          'Session-Cookie': userService.sessionCookie,
                        },
                      });
                    }}
                  />
                )}
                <Popup
                  content="Clear HTTP Request"
                  trigger={
                    <Button
                      icon="erase"
                      onClick={() => {
                        collectionEditor.resetCurrentPath(null, { skipConfirm: true });
                      }}
                    />
                  }
                />
              </Button.Group>
            </div>

            <ScenarioStep
              editorId={editor.id}
              inputProps={{
                hosts: editor.hosts,
                standalone: true,
                tabWhitelist: ['settings', 'headers', 'body', 'query', 'auth'],
                headers: {
                  foo: 'bar',
                },
              }}
            />
          </Segment>
        </EditorContentSection>

        <EditorContentSection id="InstanceOverview:apis" title="APIs">
          <div className="OverviewMatched">
            <div className="OverviewMatched-section">
              <div className="OverviewMatched-title">Matched API</div>

              <InstanceApis editorId={editor.id} />
            </div>
          </div>
        </EditorContentSection>

        <EditorContentSection id="InstanceOverview:rules" title="Rules">
          <div className="OverviewMatched-section">
            <div className="OverviewMatched-title">Matched Rules</div>

            <InstanceRules editorId={editor.id} />
          </div>
        </EditorContentSection>
      </div>
    );
  }
}

export default inject(
  ({ collectionEditorStore, instanceEditorStore, projectStore, userService }, { editorId }) => {
    return {
      editor: instanceEditorStore.getEditor({ id: editorId }),
      collectionEditorStore,
      userService,
      projectStore,
    };
  }
)(observer(Overview));
