import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import AccountForgotPassword from '@platform/components/AccountForgotPassword';

import { alert } from '@platform/utils/alert';

const ForgotPasswordPage = props => {
  const { userService, routerStore } = props;

  const { isLoggedIn, loggingIn } = userService;

  if (isLoggedIn && !loggingIn && routerStore.history && typeof window !== 'undefined') {
    alert.success('Welcome back! Re-routing to your account...');

    setTimeout(() => {
      window.location.href = _.get(routerStore, 'location.query.r', '/');
    }, 1000);
  }

  return (
    <div className="px-4 h-full flex items-center justify-center" style={{ minHeight: 700 }}>
      <AccountForgotPassword />
    </div>
  );
};

export default inject('userService', 'routerStore')(observer(ForgotPasswordPage));
