import React from 'react';
import _ from 'lodash';
import { Popup, Button, Checkbox, Icon, Message } from 'semantic-ui-react';

import { convertParameterToSchema } from '@platform/utils/specs/http';
import { safeParse, safeStringify } from '@platform/utils/json';

import MarkdownEditor from '../MarkdownEditor';
import SpecExamples from '../SpecExamples';
import FormSelect from '../FormSelect';
import FormInput from '../FormInput';
import JsonSchemaEditor from '../JsonSchemaEditor';
import RefBuilder from '../RefBuilder';
import Modal from '../Modal';

const typeOptions = {
  string: {
    validations: ['minLength', 'maxLength', 'pattern'],
    formats: [
      {
        text: 'no format',
        value: '',
      },
      {
        text: 'byte',
        value: 'byte',
      },
      {
        text: 'binary',
        value: 'binary',
      },
      {
        text: 'date',
        value: 'date',
      },
      {
        text: 'date-time',
        value: 'date-time',
      },
      {
        text: 'password',
        value: 'password',
      },
      {
        text: 'email',
        value: 'email',
      },
    ],
  },
  number: {
    validations: ['minimum', 'maximum', 'multipleOf', 'exclusiveMinimum', 'exclusiveMaximum'],
    formats: [
      {
        text: 'no format',
        value: '',
      },
      {
        text: 'float',
        value: 'float',
      },
      {
        text: 'double',
        value: 'double',
      },
    ],
  },
  integer: {
    validations: ['minimum', 'maximum', 'multipleOf', 'exclusiveMinimum', 'exclusiveMaximum'],
    formats: [
      {
        text: 'no format',
        value: '',
      },
      {
        text: 'int32',
        value: 'int32',
      },
      {
        text: 'int64',
        value: 'int64',
      },
    ],
  },
  boolean: {},
  array: {
    validations: ['uniqueItems', 'maxItems'],
  },
};

const protectedKeys = ['name', 'in', 'required', 'type', 'description', 'items'];

class SwaggerParameter extends React.Component {
  handleUpdateType = value => {
    const { handleUpdate, parameterIndex, parameter } = this.props;
    if (parameter.type === value) return;

    handleUpdate('set', ['type'], value, { parameterIndex });

    if (value === 'array') {
      handleUpdate('set', ['items', 'type'], 'string', { parameterIndex });
    } else {
      // remove items from spec if not array type
      handleUpdate('unset', ['items'], null, { parameterIndex });
    }

    // unset other options inappropriate validations, formats and defaults
    _.forEach(parameter, (v, k) => {
      if (
        !_.includes(protectedKeys, k) &&
        !_.includes(_.get(typeOptions, [value, 'validations']), k)
      )
        handleUpdate('unset', [k], null, { parameterIndex });
    });
  };

  handleUpdateArrayType = value => {
    const { handleUpdate, parameterIndex } = this.props;
    if (_.isEmpty(value)) {
      handleUpdate('unset', ['items', 'type'], null, { parameterIndex });
    } else {
      handleUpdate('set', ['items', 'type'], value, { parameterIndex });
    }
  };

  handleUpdateFormat = value => {
    const { handleUpdate, parameterIndex } = this.props;
    if (_.isEmpty(value)) {
      handleUpdate('unset', ['format'], null, { parameterIndex });
    } else {
      handleUpdate('set', ['format'], value, { parameterIndex });
    }
  };

  handleUpdateRef = value => {
    const { handleUpdate, parameterIndex, $ref, parameter } = this.props;
    if (value === $ref || (!value && !$ref)) {
      return;
    }

    if (value) {
      handleUpdate(
        'set',
        [],
        {
          $ref: value,
        },
        { parameterIndex }
      );
    } else {
      handleUpdate(
        'set',
        [],
        {
          in: parameter.in,
        },
        { parameterIndex }
      );
    }
  };

  handleUpdateEnum = value => {
    const mappedEnums = _.map(value, v => {
      return safeParse(v, v);
    });

    const { handleUpdate, parameterIndex } = this.props;
    if (_.isEmpty(value)) {
      handleUpdate('unset', ['enum'], null, { parameterIndex });
    } else {
      handleUpdate('set', ['enum'], mappedEnums, { parameterIndex });
    }
  };

  renderButtonGroup = () => {
    const {
      id,
      handleUpdate,
      parameter = {},
      blacklist = [],
      parameterIndex,
      $ref,
      readOnly,
      handleRemove,
    } = this.props;

    return (
      <Button.Group>
        {!_.includes(blacklist, 'ref') ? (
          <Modal
            triggerFactory={({ handleOpen }) => {
              return (
                <Button
                  size="small"
                  icon="linkify"
                  secondary={$ref ? true : false}
                  onClick={() => handleOpen()}
                />
              );
            }}
            contentFactory={({ handleClose }) => {
              return (
                <div>
                  <Message info size="tiny">
                    De-duplicate common parameters, and then $ref them.
                  </Message>

                  {this.renderRefBuilder(handleClose)}
                </div>
              );
            }}
          />
        ) : null}

        <Popup
          trigger={<Button icon="book" secondary={!_.isEmpty(parameter.description)} />}
          position="top right"
          wide="very"
          size="small"
          hoverable
        >
          <MarkdownEditor
            id={`${id}:param`}
            placeholder="description"
            className="w-96"
            value={parameter.description || ''}
            disabled={$ref || readOnly ? true : false}
            onChange={v => {
              if (!v) {
                handleUpdate('unset', ['description'], null, { parameterIndex });
              } else {
                handleUpdate('set', ['description'], v, { parameterIndex });
              }
            }}
          />
        </Popup>
        <Popup
          trigger={
            <Button
              icon="setting"
              secondary={_.some(
                _.get(typeOptions, [parameter.type, 'validations'], []).concat([
                  'enum',
                  'allowEmptyValue',
                ]),
                e => _.includes(_.keys(parameter), e)
              )}
            />
          }
          position="top center"
          wide="very"
          size="small"
          hoverable
        >
          {this.renderExtraProps()}
        </Popup>

        {!_.includes(blacklist, 'required') ? (
          <Popup
            trigger={
              <Button
                icon="exclamation"
                secondary={parameter.required}
                disabled={$ref || readOnly ? true : false}
                onClick={() => {
                  if (parameter.required) {
                    handleUpdate('unset', ['required'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['required'], true, { parameterIndex });
                  }
                }}
              />
            }
            content={`Required: determines whether this parameter is mandatory.
    If the parameter is in "path", this property is required and its value MUST be true.`}
            size="tiny"
            position="top center"
            wide
          />
        ) : null}

        {!readOnly && handleRemove ? (
          <Button
            icon="trash"
            onClick={() => {
              handleRemove(parameterIndex);
            }}
          />
        ) : null}
      </Button.Group>
    );
  };

  renderExtraProps = () => {
    const { handleUpdate, parameter = {}, parameterIndex, $ref, readOnly } = this.props;

    return (
      <div className="w-96">
        <div className="mt-3">
          <Checkbox
            checked={parameter.allowEmptyValue || false}
            readOnly={$ref || readOnly ? true : false}
            onChange={() => {
              if (parameter.allowEmptyValue) {
                handleUpdate('unset', ['allowEmptyValue'], null, { parameterIndex });
              } else {
                handleUpdate('set', ['allowEmptyValue'], true, { parameterIndex });
              }
            }}
            label={
              <label>
                allowEmptyValue
                <Popup
                  trigger={
                    <span className="ml-2">
                      <Icon name="question circle" />
                    </span>
                  }
                  content={`Sets the ability to pass empty-valued parameters.
      This is valid only for either query or formData parameters and allows you to
      send a parameter with a name only or an empty value.`}
                  size="tiny"
                  position="top right"
                  wide
                  basic
                />
              </label>
            }
          />
        </div>
        {parameter.type !== 'array' && parameter.type !== 'file' ? (
          <div className="mt-3">
            <FormSelect
              multiple
              allowAdditions
              search
              fluid
              readOnly={$ref || readOnly ? true : false}
              placeholder="enum (possible values)"
              options={_.map(parameter.enum, e => ({ text: e, value: e }))}
              value={parameter.enum || []}
              onChange={this.handleUpdateEnum}
            />
          </div>
        ) : null}
        {parameter.type === 'number' || parameter.type === 'integer' ? (
          <div>
            <div className="mt-3 flex items-center">
              <FormInput
                type="number"
                prefix="maximum"
                className="flex-1 mr-3"
                size="small"
                value={parameter.hasOwnProperty('maximum') ? parameter.maximum.toString() : ''}
                readOnly={$ref || readOnly ? true : false}
                onChange={(e, { value }) => {
                  if (value === '') {
                    handleUpdate('unset', ['maximum'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['maximum'], parseInt(value), { parameterIndex });
                  }
                }}
                fluid
              />

              <Checkbox
                checked={parameter.exclusiveMaximum || false}
                readOnly={$ref || readOnly ? true : false}
                onChange={() => {
                  if (parameter.exclusiveMaximum) {
                    handleUpdate('unset', ['exclusiveMaximum'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['exclusiveMaximum'], true, { parameterIndex });
                  }
                }}
                label={<label>exclusive</label>}
              />
            </div>

            <div className="mt-3 flex items-center">
              <FormInput
                type="number"
                prefix="minimum"
                className="flex-1 mr-3"
                size="small"
                readOnly={$ref || readOnly ? true : false}
                value={parameter.hasOwnProperty('minimum') ? parameter.minimum.toString() : ''}
                onChange={(e, { value }) => {
                  if (value === '') {
                    handleUpdate('unset', ['minimum'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['minimum'], parseInt(value), { parameterIndex });
                  }
                }}
                fluid
              />

              <Checkbox
                checked={parameter.exclusiveMinimum || false}
                readOnly={$ref || readOnly ? true : false}
                onChange={() => {
                  if (parameter.exclusiveMinimum) {
                    handleUpdate('unset', ['exclusiveMinimum'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['exclusiveMinimum'], true, { parameterIndex });
                  }
                }}
                label={<label>exclusive</label>}
              />
            </div>

            <div className="mt-3">
              <FormInput
                type="number"
                prefix="multipleOf"
                size="small"
                readOnly={$ref || readOnly ? true : false}
                value={
                  parameter.hasOwnProperty('multipleOf') ? parameter.multipleOf.toString() : ''
                }
                onChange={(e, { value }) => {
                  if (value === '') {
                    handleUpdate('unset', ['multipleOf'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['multipleOf'], parseInt(value), {
                      parameterIndex,
                    });
                  }
                }}
                fluid
              />
            </div>
          </div>
        ) : null}
        {parameter.type === 'string' ? (
          <div>
            <div className="mt-3">
              <FormInput
                prefix="maxLength"
                type="number"
                size="small"
                readOnly={$ref || readOnly ? true : false}
                value={parameter.hasOwnProperty('maxLength') ? parameter.maxLength.toString() : ''}
                onChange={(e, { value }) => {
                  if (value === '') {
                    handleUpdate('unset', ['maxLength'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['maxLength'], parseInt(value), {
                      parameterIndex,
                    });
                  }
                }}
                fluid
              />
            </div>

            <div className="mt-3">
              <FormInput
                prefix="minLength"
                type="number"
                size="small"
                readOnly={$ref || readOnly ? true : false}
                value={parameter.hasOwnProperty('minLength') ? parameter.minLength.toString() : ''}
                onChange={(e, { value }) => {
                  if (value === '') {
                    handleUpdate('unset', ['minLength'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['minLength'], parseInt(value), {
                      parameterIndex,
                    });
                  }
                }}
                fluid
              />
            </div>
          </div>
        ) : null}
        {parameter.type !== 'array' && parameter.type !== 'file' ? (
          <div className="mt-3">
            <FormInput
              prefix="pattern"
              size="small"
              readOnly={$ref || readOnly ? true : false}
              value={parameter.pattern || ''}
              onChange={(e, { value }) => {
                if (_.isEmpty(value)) {
                  handleUpdate('unset', ['pattern'], null, { parameterIndex });
                } else {
                  handleUpdate('set', ['pattern'], value, { parameterIndex });
                }
              }}
              fluid
            />
          </div>
        ) : null}
        {parameter.type === 'array' ? (
          <div>
            <div className="mt-3">
              <FormInput
                type="number"
                prefix="maxItems"
                size="small"
                readOnly={$ref || readOnly ? true : false}
                value={parameter.hasOwnProperty('maxItems') ? parameter.maxItems.toString() : ''}
                onChange={(e, { value }) => {
                  if (value === '') {
                    handleUpdate('unset', ['maxItems'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['maxItems'], parseInt(value), {
                      parameterIndex,
                    });
                  }
                }}
                fluid
              />
            </div>

            <div className="mt-3">
              <FormInput
                type="number"
                prefix="minItems"
                size="small"
                readOnly={$ref || readOnly ? true : false}
                value={parameter.hasOwnProperty('minItems') ? parameter.minItems.toString() : ''}
                onChange={(e, { value }) => {
                  if (value === '') {
                    handleUpdate('unset', ['minItems'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['minItems'], parseInt(value), {
                      parameterIndex,
                    });
                  }
                }}
                fluid
              />
            </div>
            <div className="mt-3">
              <Checkbox
                checked={parameter.uniqueItems || false}
                readOnly={$ref || readOnly ? true : false}
                onChange={() => {
                  if (parameter.uniqueItems) {
                    handleUpdate('unset', ['uniqueItems'], null, { parameterIndex });
                  } else {
                    handleUpdate('set', ['uniqueItems'], true, { parameterIndex });
                  }
                }}
                label={<label>uniqueItems</label>}
              />
            </div>
          </div>
        ) : null}
        <div className="pb-3" />
      </div>
    );
  };

  renderParameter = () => {
    const { handleUpdate, parameter = {}, parameterIndex, $ref, readOnly, root } = this.props;

    let extraTypes = [];
    if (parameter.in === 'formData') {
      extraTypes = [
        {
          text: 'file',
          value: 'file',
        },
      ];
    }

    let types = _.map(typeOptions, (v, k) => {
      return { text: k, value: k };
    }).concat(extraTypes);

    const formats =
      parameter.type !== 'array'
        ? _.get(typeOptions[parameter.type], 'formats') || []
        : _.get(typeOptions[_.get(parameter, 'items.type')], 'formats') || [];

    return (
      <div className="flex" key="main-parameter">
        <FormInput
          label={root ? 'name' : ''}
          className="pr-2 flex-1"
          value={parameter.name || ''}
          onChange={(e, { value }) => {
            handleUpdate('set', ['name'], value, { parameterIndex });
          }}
          readOnly={$ref || readOnly ? true : false}
          placeholder="name"
          fluid
        />

        <FormSelect
          label={root ? 'type' : ''}
          className="pr-2 flex-1"
          readOnly={$ref || readOnly ? true : false}
          value={parameter.type || ''}
          onChange={this.handleUpdateType}
          options={types}
          fluid
        />

        {parameter.type === 'array' ? (
          <FormSelect
            label={root ? 'array type' : ''}
            className="pr-2 flex-1"
            readOnly={$ref || readOnly ? true : false}
            value={_.get(parameter.items, 'type') || ''}
            onChange={this.handleUpdateArrayType}
            options={_.map(['string', 'integer', 'number', 'boolean'], v => {
              return { text: v, value: v };
            })}
            fluid
          />
        ) : null}

        {!_.includes(['boolean', 'file'], parameter.type) &&
        !_.includes(['boolean', 'file'], _.get(parameter, 'items.type')) ? (
          <FormSelect
            label={root ? 'format' : ''}
            className="pr-2 flex-1"
            placeholder="optional format"
            readOnly={$ref || readOnly ? true : false}
            value={parameter.format || ''}
            onChange={this.handleUpdateFormat}
            options={formats}
            fluid
          />
        ) : null}

        {parameter.type !== 'array' ? (
          <FormInput
            label={root ? 'default ' : ''}
            className="pr-2 flex-1"
            value={safeStringify(parameter.default) || ''}
            onChange={(e, { value }) => {
              if (_.isEmpty(value)) {
                handleUpdate('unset', ['default'], null, { parameterIndex });
              } else {
                handleUpdate('set', ['default'], value, { parameterIndex });
              }
            }}
            onBlur={() => {
              if (_.includes(['integer', 'boolean'], parameter.type)) {
                try {
                  handleUpdate('set', ['default'], JSON.parse(parameter.default), {
                    parameterIndex,
                  });
                } catch (e) {}
              }
            }}
            placeholder="optional default"
            readOnly={$ref || readOnly ? true : false}
            fluid
          />
        ) : null}

        {root ? null : this.renderButtonGroup()}
      </div>
    );
  };

  renderParameterBody = () => {
    const {
      id,
      handleUpdate,
      definitions,
      parameter = {},
      dereferencedParameter = {},
      parameterIndex,
      $ref,
      readOnly,
      preview,
      editorId,
      parsed,
    } = this.props;

    return (
      <div>
        <MarkdownEditor
          id={`${id}:param:body`}
          placeholder="write description here..."
          value={_.get(parameter, 'description', '')}
          disabled={$ref || readOnly ? true : false}
          onChange={v => {
            if (!v) {
              handleUpdate('unset', ['description'], null, { parameterIndex });
            } else {
              handleUpdate('set', ['description'], v, { parameterIndex });
            }
          }}
          readOnly={preview}
        />

        <div className="mt-3">
          <JsonSchemaEditor
            id={`${id}:param:body`}
            schema={_.get(parameter, 'schema', '')}
            dereferencedSchema={_.get(dereferencedParameter, 'schema', '')}
            definitions={definitions}
            disabled={$ref || readOnly ? true : false}
            editorStore="oas2EditorStore"
            editorId={editorId}
            onSchemaChange={schema => {
              handleUpdate('set', ['schema'], schema, { parameterIndex });
            }}
            readOnly={preview}
            refBuilderProps={{
              localData: parsed,
              fileFilter: { modeling: [/oas2/] },
              routeDataTargets: { oas2: ['definitions'] },
              targets: 'required',
            }}
          />
        </div>

        <div className="mt-4">
          <SpecExamples
            id="param-body"
            schema={_.get(parameter, 'schema', '')}
            dereferencedSchema={_.get(dereferencedParameter, 'schema', '')}
            definitions={definitions}
            examples={_.get(parameter, 'x-examples', {})}
            handleUpdate={(t, p, v) => {
              handleUpdate(t, ['x-examples'].concat(p), v, { parameterIndex });
            }}
            readOnly={preview}
          />
        </div>
      </div>
    );
  };

  renderPreview = () => {
    const {
      handleUpdate,
      definitions,
      parameter = {},
      parameterIndex,
      $ref,
      readOnly,
      preview,
      editorId,
    } = this.props;

    return (
      <JsonSchemaEditor
        rootName={parameter.name}
        id={`param-${parameter.in}`}
        schema={{ properties: { [parameter.name]: convertParameterToSchema(parameter) } }}
        definitions={definitions}
        disabled={$ref || readOnly ? true : false}
        editorStore="oas2EditorStore"
        editorId={editorId}
        onSchemaChange={schema => {
          handleUpdate('set', ['schema'], schema, { parameterIndex });
        }}
        readOnly={preview}
      />
    );
  };

  renderRefBuilder = handleClose => {
    const { $ref, parsed } = this.props;

    return (
      <RefBuilder
        $ref={$ref}
        localData={parsed}
        onComplete={value => {
          this.handleUpdateRef(value);

          handleClose();
        }}
        fileFilter={{ modeling: [/oas2/] }}
        routeDataTargets={{ oas2: ['parameters'] }}
        initialSource="local"
        targets="required"
        size="small"
        confirm
      />
    );
  };

  renderRootProps = () => {
    const { id, handleUpdate, parameter = {}, parameterIndex, $ref, readOnly } = this.props;

    return (
      <div key="root-props">
        <MarkdownEditor
          id={`${id}:param`}
          className="mt-8 mb-8"
          placeholder="description"
          value={parameter.description || ''}
          disabled={$ref || readOnly ? true : false}
          onChange={v => {
            if (!v) {
              handleUpdate('unset', ['description'], null, { parameterIndex });
            } else {
              handleUpdate('set', ['description'], v, { parameterIndex });
            }
          }}
        />
        {this.renderExtraProps()}
      </div>
    );
  };

  render = () => {
    const { parameter = {}, preview, root } = this.props;

    let paramElem;
    if (parameter.in === 'body') {
      paramElem = this.renderParameterBody();
    } else if (preview) {
      paramElem = this.renderPreview();
    } else {
      paramElem = [this.renderParameter(), root ? this.renderRootProps() : null];
    }

    return <div className="SwaggerParameter">{paramElem}</div>;
  };
}

export default SwaggerParameter;
