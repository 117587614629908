import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';

class DropdownMenu extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    floatLeft: PropTypes.bool,
    disabled: PropTypes.bool,
    dropdownOptions: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.string,
      divider: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  };

  renderOptions = () => {
    const { options, value, onChange, disabled } = this.props;

    return _.map(options, (option, index) => {
      if (option.divider) {
        return <Dropdown.Divider key={index} />;
      }

      const optionValue = _.isUndefined(option.value) ? option.title : option.value;
      const active = optionValue === value;
      let icon = <Icon name="radio" />;

      if (option.icon) {
        icon = <Icon name={option.icon} />;
      } else if (active) {
        icon = <Icon name="check circle outline" />;
      }

      return (
        <Dropdown.Item
          key={optionValue}
          active={active}
          value={value}
          disabled={option.disabled || disabled}
          onClick={() => {
            if (option.disabled || disabled) {
              return;
            }

            if (option.onClick) {
              option.onClick(optionValue);
            } else {
              onChange(optionValue);
            }
          }}
        >
          <div className="flex items-center">
            <div className="mr-3">{icon}</div>
            <div>
              <div className={option.description ? 'pb-2' : ''}>{option.title}</div>
              {option.description ? <small className="c-muted">{option.description}</small> : null}
            </div>
          </div>
        </Dropdown.Item>
      );
    });
  };

  render() {
    const { value, options, className, disabled, floatLeft, dropdownOptions } = this.props;

    const option = _.find(options, { value }) || {};

    return (
      <Dropdown
        className={`DropdownMenu ${floatLeft ? 'floatLeft' : ''} ${className}`}
        text={option.title || value || ''}
        floating
        labeled
        disabled={disabled}
        {...dropdownOptions}
      >
        <Dropdown.Menu>{this.renderOptions()}</Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default DropdownMenu;
