import _ from 'lodash';
import { types, flow } from 'mobx-state-tree';

import { computed } from 'mobx';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/projects/:projectId/versions',
      perPage: 100,
    })
    .views(self => {
      return {
        get currentList() {
          const project_id = _.get(env.rootStore, 'stores.projectService.current.id');
          if (!project_id) return;

          return self
            .findLocal({
              query: {
                project_id,
              },
              sort: {
                name: -1,
              },
            })
            .get();
        },

        get current() {
          const project = _.get(env.rootStore.stores.projectStore, 'current') || {};
          if (!project.id || !project.currentRef) return;

          return _.first(
            self
              .findLocal({
                query: {
                  branch: project.currentRef,
                  project_id: project.id,
                },
                limit: 1,
              })
              .get()
          );
        },

        get latest() {
          return _.first(self.currentList);
        },

        changeBranch(branch) {
          const { routerStore, projectService, projectStore } = env.rootStore.stores || {};
          const { path_with_namespace } = _.get(projectService, 'current') || {};
          const { currentRef, currentFilePath } = _.get(projectStore, 'current') || {};

          if (!branch || currentRef === branch || !path_with_namespace) return;

          let pathname = `/${path_with_namespace}/${encodeURIComponent(branch)}`;
          if (currentFilePath) {
            pathname = `${pathname}/${currentFilePath}`;
          }

          routerStore.push({
            pathname,
            search: routerStore.location.search,
          });
        },

        isReleased(branch) {
          return computed(() => {
            const releases = _.get(env.rootStore.stores, 'releaseService.currentList') || [];

            // if branch is in list of releases that means it was released
            return releases.find(r => r.branch === branch || r.name === branch);
          }).get();
        },

        getVersion(ref) {
          if (!ref) return;

          const [prefix, versionNumber] = ref.split('/');

          if (prefix === 'version' && versionNumber) {
            return versionNumber;
          }
        },

        get nextAvailableVersion() {
          const [major, minor] = _.split(_.get(self.current, 'name'), '.');

          let nextMinor = Number(minor);
          while (_.find(self.currentList, { name: `${major}.${nextMinor}` })) {
            nextMinor++;
          }

          return `${major}.${nextMinor}`;
        },
      };
    })
    .actions(self => {
      const originalUpdateRecord = self.updateRecord;
      const originalRemove = self.remove;

      return {
        /**
         * Overload updateRecord to transform the record to the format the client expects.
         * API doesn't include project_id, for example.
         */
        updateRecord(record, { params = {} } = {}) {
          if (!record) {
            return;
          }

          const project_id =
            record.project_id ||
            _.get(
              params,
              'projectId',
              _.get(env.rootStore.stores.routerStore, 'location.params.projectId')
            );

          const extendedRecord = {
            project_id,
            ...record,
          };

          // versions don't come with ids :(
          extendedRecord.id = `${project_id}:${record.name}`;

          return originalUpdateRecord(extendedRecord);
        },

        remove: flow(function*(name, params, opts = {}) {
          const branch = yield originalRemove(
            name,
            params,
            Object.assign({}, opts || {}, { skipStore: true })
          );

          const project_id =
            branch.project_id ||
            _.get(
              params,
              'projectId',
              _.get(env.rootStore.stores.routerStore, 'location.params.projectId')
            );

          return self.removeRecord(`${project_id}:${name}`);
        }),
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('VersionService');

  return Service.create(data, env);
};
