let workerInstance;

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
  const WebworkerPromise = require('webworker-promise');
  const Worker = require('./css.worker.js');

  workerInstance = new WebworkerPromise(new Worker('css.worker.js'));
}

export default workerInstance;
