import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';

import FormRadio from '@platform/components/FormRadio';
import FormInput from '@platform/components/FormInput';
import FormTextarea from '@platform/components/FormTextarea';
import ErrorMessage from '@platform/components/ErrorMessage';
import FeatureBadge from '@platform/components/FeatureBadge';
import FormInputLabel from '@platform/components/FormInputLabel';

import { getConfigVar } from '@platform/utils/config';

import { alert } from '@platform/utils/alert';

import { colors, Icon, Layout } from '@core/ui';

const VisibilityToggle = ({ className, checked, onChange, title, description, icon }) => {
  return (
    <div className={cn('flex items-center', className)}>
      <div className="cur-p">
        <FormRadio name="Access" checked={checked} onChange={onChange} />
      </div>

      <div className="flex items-center cur-p" onClick={onChange}>
        <div
          className={`text-3xl text-center ${checked ? 'text-active' : 'text-muted'}`}
          style={{ width: 50 }}
        >
          <Icon icon={icon} />
        </div>

        <div className="flex-1">
          <div className="pr-4 font-semibold">{title}</div>
          <small className="text-muted">{description}</small>
        </div>
      </div>
    </div>
  );
};

class ProjectCreate extends React.Component {
  handleFormInput = (t, p, v) => {
    this.props.updateUi(t, `form.${p}`, v);
  };

  handleClearFormInput = e => {
    if (e) {
      e.preventDefault();
    }

    this.props.updateUi('set', 'form', {});
  };

  handleSave = () => {
    const { projectService, ui, routerStore, namespace } = this.props;

    const newProject = ui.form || {};
    if (!newProject.visibility) {
      newProject.visibility = 'private';
    }

    const params = {
      namespaceId: namespace.id,
    };

    const opts = {};
    if (namespace.namespace_type === 'user') {
      opts.basePath = '/projects';
      params.namespaceType = 'users';
    } else {
      params.namespaceType = 'orgs';
    }

    projectService
      .create(newProject, params, opts)
      .then(result => {
        this.handleClearFormInput();
        routerStore.push({
          pathname: `/${result.path_with_namespace}`,
          search: '?new=1',
        });
        alert.success('Tada, project created.');
      })
      .catch(e => {
        alert.error('There was an error creating the project.');
        console.error('There was an error creating the project.', e);
      });
  };

  render() {
    const { ui, project, projectService, namespace = {}, header } = this.props;

    let { form } = ui;
    const newProject = form || {
      visibility: 'private',
    };

    const isPublic = newProject.visibility === 'public';

    const name = newProject.hasOwnProperty('name') ? newProject.name : _.get(project, 'name', '');
    const description = newProject.hasOwnProperty('description')
      ? newProject.description
      : _.get(project, 'description', '');
    const nextEnabled = !projectService.isCreating && _.size(name) > 1;
    const subtitle =
      'A project can represent a large API, a group of microservices, standalone documentation, and more.';

    let visibility;
    if (
      !getConfigVar('SL_NO_PROJECT_VISIBILITY') ||
      getConfigVar('SL_NO_PROJECT_VISIBILITY') == 'false'
    ) {
      visibility = (
        <div className="mt-10">
          <FormInputLabel label="PROJECT VISIBILITY" />

          <VisibilityToggle
            className="mt-2"
            title="Private"
            description="You choose who can see and edit this project."
            icon={faLock}
            checked={!isPublic}
            onChange={() => this.handleFormInput('set', 'visibility', 'private')}
          />

          <VisibilityToggle
            className="mt-4"
            title="Public"
            description="Anyone can see this project. You choose who can edit."
            icon={faGlobe}
            checked={isPublic}
            onChange={() => this.handleFormInput('set', 'visibility', 'public')}
          />
        </div>
      );
    }

    return (
      <Layout
        header={
          header || {
            title: `${namespace.username || namespace.name} / New Project`,
            subtitle,
            bgClassName: 'bg-blue-light',
          }
        }
        footer={{
          right: {
            actions: [
              {
                children: projectService.isCreating ? 'Saving...' : 'Next',
                rightIcon: !projectService.isCreating && faArrowRight,
                color: nextEnabled ? colors.accent : colors.grey,
                disabled: !nextEnabled,
                onClick: this.handleSave,
              },
            ],
          },
        }}
        content={
          <div className="bg-white">
            <ErrorMessage error={projectService.error} onDismiss={projectService.clearError} />

            <div className="flex p-12">
              <div className="flex flex-1 flex-col">
                {header && (
                  <div className="mb-10 text-muted bg-grey-lightest p-4 rounded-md">
                    <Icon className="mr-1" icon={faExclamationCircle} /> {subtitle}
                  </div>
                )}

                <FormInput
                  label="PROJECT NAME"
                  name="name"
                  placeholder="Pick something short and memorable..."
                  value={name}
                  onChange={(e, { value }) => {
                    this.handleFormInput('set', 'name', value);
                  }}
                  fluid
                />

                <FormTextarea
                  className="mt-6"
                  label="PROJECT DESCRIPTION"
                  placeholder="Purpose or description of this project..."
                  value={description}
                  onChange={(e, { value }) => {
                    this.handleFormInput('set', 'description', value);
                  }}
                  style={{ minHeight: '50px' }}
                  minRows={3}
                  bordered
                />

                {visibility}
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

export default inject((stores, { namespace }) => {
  const { appStore, routerStore, projectService, namespaceService } = stores;

  const n = namespace || namespaceService.current || {};
  return {
    ...appStore.injectUi(`ProjectCreate-${n.id}`),
    routerStore,
    projectService,
    namespace: n,
  };
})(observer(ProjectCreate));
