import _ from 'lodash';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';

import { Box, Flex } from '@stoplight/ui-kit';

import { Link } from '@platform/utils/router';

const SidebarFilter = props => {
  const {
    displayName,
    filterName,
    items,
    canCreate,
    hideIfEmpty,
    settingsPathname,
    namespaceStore,
    metaIcon,
    multiple = true,
    onUpdate = _.noop,
  } = props;

  const { current = {} } = namespaceStore;
  const activeItems = _.get(current.activeFilters, filterName, []);

  if (hideIfEmpty && _.isEmpty(items)) {
    return null;
  }

  const toggleFilter = val => {
    // needed for the ALL case
    onUpdate(val);

    if (_.isArray(val)) {
      val.forEach(v => current.updateActiveFilter('unset', [filterName, v]));
      return;
    }

    if (!_.includes(activeItems, val)) {
      const newValue = multiple ? [...activeItems, val] : [val];
      current.updateActiveFilter('set', filterName, newValue);
      return;
    }

    current.updateActiveFilter('unset', [filterName, val]);
  };

  const filters = [
    {
      name: 'All',
      active: _.isEmpty(activeItems),
      showBadge: false,
      onToggle: () => toggleFilter(_.map(items, item => item.value || item.name)),
    },
    ...items,
  ];

  return (
    <Box p="14px 12px" mb="20px" border="1px solid rgb(218, 225, 231)" width="200px">
      <Flex pb="8px" mb="5px" alignItems="center" borderBottom="1px solid rgb(218, 225, 231)">
        <Box flex={1} fontSize="12px" fontWeight={600}>
          {displayName}
        </Box>

        <Box>
          {canCreate &&
            settingsPathname && (
              <Link className="c-muted" to={{ pathname: settingsPathname }}>
                <Icon name={metaIcon || 'setting'} className="is-standalone" />
              </Link>
            )}
        </Box>
      </Flex>

      {_.map(filters, (item, i) => {
        const { active, name, value, description, primary_color, showBadge = true } = item;
        const isActive = active || _.includes(activeItems, value || name);
        const color = primary_color || '#999';
        const onClick = item.onToggle || toggleFilter;

        return (
          <Box
            key={i}
            pt="6px"
            cursor="pointer"
            fontWeight={isActive ? 600 : 400}
            fontSize=".85em"
            color={isActive ? 'rgb(59, 153, 252)' : 'rgba(0,0,0,.5)'}
            css={{
              userSelect: 'none',
              ':hover': { color: isActive ? 'rgb(59, 153, 252)' : 'black' },
            }}
            onClick={() => onClick(value || name)}
          >
            <Flex alignItems="center" height="100%">
              {showBadge && (
                <Box
                  borderRadius="50%"
                  width="10px"
                  height="10px"
                  mt="1px"
                  position="relative"
                  mr="4px"
                  border={`1px solid ${color}`}
                  backgroundColor={isActive ? color : null}
                >
                  {isActive && (
                    <Box
                      as={Icon}
                      name="check"
                      size="small"
                      position="absolute"
                      left="-1px"
                      color="white"
                    />
                  )}
                </Box>
              )}

              <Box flex={1}>{name}</Box>
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

export default inject('namespaceStore')(observer(SidebarFilter));
