import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import cn from 'classnames';
import SortableTree from '@platform/components/SortableTree';

const HubSidebarEditor = props => {
  const { store = {}, className = '' } = props;
  const { activePage, computedSidebarTree } = store;

  const parsedPath = _.get(activePage, 'parsedPath', []);

  if (!computedSidebarTree) {
    return;
  }

  return (
    <div
      className={cn(
        'HubSidebar-wrapper flex bg-grey-lightest border-r border-grey-light',
        className
      )}
    >
      <div className={cn('HubSidebar', 'flex-grow', className)}>
        <SortableTree
          className="HubSidebar-inner flex flex-col"
          items={computedSidebarTree}
          currentPath={_.last(parsedPath)}
          onChange={(fromPath, toPath) => {
            const newPath = parsedPath.concat(toPath);

            if (_.join(fromPath) === _.join(newPath)) return;

            store.updateParsed('move', fromPath, newPath);

            setTimeout(() => {
              store.goToPath(newPath);
            }, 0);
          }}
        />
      </div>
    </div>
  );
};

export default observer(HubSidebarEditor);
