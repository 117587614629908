import { types, flow, getEnv } from 'mobx-state-tree';
import _ from 'lodash';

import { getConfigVar } from '@platform/utils/config';
import { isValidUrl } from '@platform/utils/url';
import { pathToHash } from '@platform/utils/history';
import { routes, sharedTargets, buildOptions, refUrlProperties } from '@platform/utils/refBuilder';
import { isValidInternalRef, fetchRef } from '@platform/utils/refProxy';
import { safeStringify, safeParse } from '@platform/utils/json';
import { fileFormat } from '@platform/utils/url';
import { defaultFileFilter } from '@platform/utils/projects';

import { BaseStore } from './_base';
import { BaseManager, BaseInstance } from './_manager';
import { BaseService } from './services/_base';

export const create = ({ data, env, options = {} }) => {
  const RefBuilder = types
    .model({
      originalRef: types.maybe(types.string),
      $ref: types.maybe(types.string),
      fileFilter: types.frozen(),
      routeDataTargets: types.frozen(),
      localData: types.optional(types.frozen()),
      targets: types.maybe(types.union(types.boolean, types.string)),
      context: types.maybe(types.boolean),

      currentProjectId: types.maybe(types.number),
      currentProjectPath: types.maybe(types.array(types.string)),
      currentFilePath: types.maybe(types.string),

      source: types.optional(types.string, 'currentProject'),
      file: types.frozen(),
      target: types.frozen(),
      externalUrl: types.maybe(types.string),

      fileQuery: types.maybe(types.string),
      targetQuery: types.maybe(types.string),

      fileList: types.frozen(),
      specData: types.frozen(),

      isTargetLoading: false,
      isUrlLoading: false,

      isUrlValid: true,
      urlError: types.maybe(types.string),

      initializing: true,
      isInitialized: false,
    })
    .views(self => {
      return {
        get builtRef() {
          if (!self.isComplete) return;

          let ref = '';

          switch (self.source) {
            case 'currentProject':
              ref = `./${_.get(self, 'file.title')}`;
              break;

            case 'shared':
              return self.target.exporturl;

            case 'external':
              ref = self.externalUrl;
              break;

            default:
          }

          // checks for circular/self references and strips everything but the target
          if (self.isRefCurrentFile) {
            ref = '';
          }

          let hash = _.get(self.target, 'key', undefined);
          if (hash) {
            hash = `#/${_.join(safeParse(hash), '/')}`;
            ref += hash;
          }

          return ref;
        },

        get dirty() {
          return self.originalRef !== self.builtRef;
        },

        get errorMessage() {
          let message;
          if (self.isUrlLoading || self.initializing || self.isComplete) return;

          if (self.isTargetRequired && self.file)
            message = 'A target is required for this file type.';

          if (self.urlError) {
            message = self.urlError;
          } else if (!self.isUrlValid && self.source === 'external') {
            message = 'Invalid Url.';
          }

          return message;
        },

        get fileResults() {
          const results = {};

          const re = new RegExp(_.escapeRegExp(self.fileQuery), 'i');

          _.forEach(self.fileList, (files, category) => {
            _.forEach(files, file => {
              const title = _.last(_.split(file.id, ':'));

              if (re.test(category) || re.test(title)) {
                if (_.get(results, [category, 'results'])) {
                  results[category].results.push({
                    key: file.id,
                    title,
                  });
                } else {
                  results[category] = {
                    name: category,
                    results: [
                      {
                        key: file.id,
                        title,
                        data: file,
                      },
                    ],
                  };
                }
              }
            });
          });

          return results;
        },

        isValidRef(u) {
          // check internal || local || external
          return isValidInternalRef(u) || _.startsWith('#/') || isValidUrl(u);
        },

        get isComplete() {
          if (self.isTargetRequired || self.source === 'local' || self.source === 'shared') {
            return self.target && self.targetQuery;
          } else if (self.source === 'currentProject') {
            return self.file && self.fileQuery;
          } else if (self.source === 'external' && self.externalUrl) {
            return self.isUrlValid;
          }
        },

        get isRefCurrentFile() {
          // removes target
          const baseReferenceLink = _.split(self.referenceLink, '?')[0];
          return baseReferenceLink === `${getConfigVar('SL_APP_HOST')}${self.currentFilePath}`;
        },

        get isTargetRequired() {
          const file = fileFormat({ file: _.get(self, 'file.title') });
          if (
            file.format === 'hub' ||
            (self.targets && self.targets !== 'optional') ||
            self.source === 'local'
          ) {
            return true;
          }

          return false;
        },

        get referenceLink() {
          if (!self.isComplete) return;

          let path = self.currentProjectPath;
          let file;
          let referenceLink;

          switch (self.source) {
            case 'currentProject':
            case 'local':
              file =
                _.get(self, 'file.title') || _.last(_.split(_.get(self, 'currentFilePath'), '/'));

              if (file) {
                path = _.join(_.concat(path.toJS(), file), '/');
              } else {
                path = self.currentFilePath;
              }

              referenceLink = `${getConfigVar('SL_APP_HOST')}${path}`;
              break;

            case 'shared':
              return self.target.referencelink;

            case 'external':
              referenceLink = self.externalUrl;
              break;

            default:
              return;
          }

          if (self.target) {
            const path = safeParse(self.target.key);

            const viewpath = self.target.viewpath;

            const file = fileFormat({ file: self.file });

            // if the case of a hub the link should be the viewpath
            // if hub and no viewPath, send to file root path NOT the edit
            let param = {};

            if (file.format === 'hub' && !viewpath) {
              // hmm?
            } else if (viewpath) {
              referenceLink += viewpath;
            } else {
              param = getEnv(self).rootStore.stores.routerStore.buildQueryParams(
                { edit: pathToHash({ path }), view: undefined },
                { preserve: true }
              );
              referenceLink += _.get(param, 'search', '');
            }
          }

          return referenceLink;
        },

        get sourceOptions() {
          let options = ['currentProject'];

          // adds local only if relevant
          if (!_.isEmpty(self.localData)) {
            options.unshift('local');
          }

          if (self.context) {
            options.push('shared');
          }

          options.push('external');

          const nameMap = {
            local: 'This File',
            currentProject: 'This Project',
            shared: 'Shared / Common',
            external: 'External URL',
          };

          return buildOptions(options, item => nameMap[item]);
        },

        get targetResults() {
          // hardcoded prism helpers
          if (self.source === 'shared') {
            return sharedTargets();
          }

          const matchingRoutes = routes({ spec: self.specData, targets: self.routeDataTargets });

          const re = new RegExp(_.escapeRegExp(self.targetQuery), 'i');
          const isMatch = (route, target) => {
            return (
              (re.test(route) || re.test(target.data.name) || re.test(_.last(target.path))) &&
              // removes x-paths
              _.split(_.last(target.path), '-')[0].toUpperCase() !== 'X'
            );
          };

          const results = {};
          _.forEach(matchingRoutes, (value, category) => {
            _.forEach(value.matched, target => {
              if (isMatch(category, target)) {
                if (_.get(results, [category, 'results'])) {
                  results[category].results.push({
                    key: safeStringify(target.path),
                    title: target.data.name,
                    viewpath: target.data.viewPath,
                  });
                } else {
                  results[category] = {
                    name: category,
                    results: [
                      {
                        key: safeStringify(target.path),
                        title: target.data.name,
                        viewpath: target.data.viewPath,
                      },
                    ],
                  };
                }
              }
            });
          });

          return results;
        },
      };
    })
    .actions(self => {
      return {
        initial: opts => {
          const {
            $ref,
            routeDataTargets = {},
            initialSource = 'currentProject',
            fileFilter,
            currentProjectId,
            currentProjectPath,
            currentFilePath,
            localData = {},
            targets,
            context,
            force,
          } = _.cloneDeep(opts); // need to make sure save objects are unique per refBuilder instance

          // don't re-init if $ref has not changed
          if (self.$ref && _.isEqual(self.$ref, $ref)) {
            return;
          }

          const properties = refUrlProperties({ u: $ref, projectId: currentProjectId });

          self.$ref = $ref;
          self.routeDataTargets = routeDataTargets;
          self.source = _.get(properties, 'source', initialSource);
          self.fileFilter = fileFilter || _.cloneDeep(defaultFileFilter);
          self.currentProjectId = currentProjectId;
          self.currentProjectPath = currentProjectPath;
          self.currentFilePath = currentFilePath;
          self.localData = localData;
          self.targets = targets;
          self.context = context;

          if (force || !self.isInitialized) {
            self.isInitialized = true;
            return self.reset();
          }
        },

        reset: () => {
          if (self.$ref || self.source === 'local') {
            // gets $ref properties
            const properties = refUrlProperties({ u: self.$ref, projectId: self.currentProjectId });
            const $source = _.get(properties, 'source', self.source);
            const $file = _.get(properties, ['sri', 'file']);
            const $hash = _.get(properties, ['target', 'hash']);
            const $hashPath = safeStringify(_.get(properties, ['target', 'path'], []));

            let externalUrl;

            switch ($source) {
              case 'local':
                self.source = 'local';
                break;

              case 'currentProject':
                self.source = 'currentProject';
                self.file = { title: $file };
                self.fileQuery = $file;
                break;

              // temporary while we find a more permenant solution to shared
              case 'shared':
                self.source = 'shared';
                self.target = _.get(properties, 'target');
                self.targetQuery = _.get(properties, 'target.title');

                self.originalRef = self.$ref;
                self.initializing = false;
                return;

              default:
                self.source = 'external';

                // strips the target hash
                externalUrl = self.$ref.replace($hash, '');

                self.externalUrl = externalUrl;

                if (self.isValidRef(externalUrl)) self.isUrlValid = true;
            }

            if ($hash) {
              self.target = { key: $hashPath, title: $hash };
              self.targetQuery = $hash;
            }
          }

          self.originalRef = self.$ref;
          self.initializing = false;
        },

        handleChange(target, data = {}, force) {
          self.clear(target);

          if (!force && _.isEqual(self[target], data)) {
            return;
          }

          self[target] = data;

          if (_.has(self, `${target}Query`)) {
            _.set(self, `${target}Query`, data.title);
          }

          if (target === 'externalUrl') {
            self.fetchExternalUrl();
          }
        },

        setQuery(target, value) {
          _.set(self, `${target}Query`, value);
        },

        fetchExternalUrl: flow(function*() {
          self.isUrlLoading = true;

          let res;
          let url = self.externalUrl;

          if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
          }

          try {
            res = yield fetchRef({ url, deref: 'all' });
            self.specData = _.get(res, 'data');
            self.isUrlValid = !_.isEmpty(self.targetResults) || !self.targets;
            if (!self.isUrlValid) {
              self.urlError = 'Invalid data from the Url';
            }
          } catch (e) {
            self.specData = undefined;
            self.isUrlValid = false;
            self.urlError = e.message || 'Invalid Url';
          }

          self.isUrlLoading = false;
        }),

        loadFiles() {
          const projectStore = getEnv(self).rootStore.stores.projectStore;

          const files = _.get(projectStore, 'current.currentFiles').get();

          const filteredFiles = projectStore.fileService.filterFiles({
            files,
            fileFilter: self.fileFilter,
          });

          self.fileList = filteredFiles;
        },

        loadSpecData: flow(function*() {
          self.isTargetLoading = true;

          let data;
          let projectStore;
          let file;

          switch (self.source) {
            case 'local':
              data = self.localData;
              break;

            case 'currentProject':
              projectStore = getEnv(self).rootStore.stores.projectStore.current;

              file = yield projectStore.getFile(_.get(self, 'file.title'));

              data = _.get(file, 'record.data.content');
              break;

            case 'external':
              data = self.specData;
              break;

            default:
              data = {};
          }

          self.specData = data || {};
          self.isTargetLoading = false;
        }),

        pristine() {
          self.originalRef = self.builtRef;
        },

        clear(key) {
          const keys = [
            'source',
            'file',
            'fileQuery',
            'externalUrl',
            'urlError',
            'specData',
            'target',
            'targetQuery',
          ];

          const sub = _.slice(keys, _.indexOf(keys, key));

          _.forEach(sub, key => {
            self[key] = undefined;
          });
        },
      };
    });

  const RefBuilderInstance = types
    .compose(
      BaseStore,
      BaseInstance,
      BaseService,
      RefBuilder
    )
    .named('RefBuilderInstance');

  const Base = types
    .model({
      instances: types.optional(types.array(RefBuilderInstance), []),
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      self.instanceModel = RefBuilderInstance;

      return {};
    });

  const Service = types
    .compose(
      BaseStore,
      BaseManager,
      Base
    )
    .named('RefBuilderStore');

  return Service.create(data, env);
};
