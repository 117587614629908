import axios from 'axios';
import Cookies from 'js-cookie';
import { getConfigVar } from '@platform/utils/config';

export const submitForm = ({ formId, fields }) => {
  const portalId = getConfigVar('HUBSPOT_PORTAL_ID');

  if (!portalId || !formId || !fields || !fields.length) {
    return Promise.resolve();
  }

  return axios({
    method: 'post',
    url: `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
    data: {
      fields,
      context: {
        hutk: Cookies.get('hubspotutk'),
      },
    },
  }).catch(err => console.error('Error submitting HubSpot form', err.message));
};
