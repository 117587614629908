import React from 'react';
import cn from 'classnames';
import pluralize from 'pluralize';
import { inject, observer } from 'mobx-react';

import { faGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';

import { colors, sizes, Button, Icon, Modal, Layout } from '@core/ui';

import { alert } from '@platform/utils/alert';
import { getTrial } from '@platform/utils/billing';
import { getConfigVar } from '@platform/utils/config';

import ImageIcon from '../ImageIcon';

const TrialModal = props => {
  const { appStore, namespace, routerStore, trialUntilService, service } = props;
  const {
    avatar_url,
    name,
    path: namespacePath,
    id: namespaceId,
    namespace_type: namespaceType,
  } = namespace;

  const prefix = 'Organization';
  const billingPath = `/${namespacePath}/settings/billing`;

  return (
    <Modal
      width={600}
      show={appStore.activeModal === 'trial-expired'}
      onDidHide={() => appStore.closeModal()}
      renderContent={() => {
        return (
          <Layout
            header={{ title: `${prefix} Trial Expired` }}
            content={
              <div className="flex flex-col items-center justify-center py-6 text-lg text-center w-2/3 m-a">
                <div className="flex items-center">
                  <ImageIcon imageUrl={avatar_url} iconName={name} />

                  <div className="font-bold ml-3">{name}</div>
                </div>

                <div className="mt-6">
                  <div>
                    To continue using paid features, please <b>subscribe to a paid plan</b> or
                    continue on the free <b>Open Source plan</b>.
                  </div>
                </div>

                <div className="mt-8 mb-4 text-left">
                  <div>
                    <Icon className="mr-2 text-green" icon={faCheck} /> The open source plan will
                    allow you to continue working with <b>public</b> projects.
                  </div>
                  <div className="border-t border-grey-light mt-4 pt-4">
                    <Icon className="mr-2 text-orange" icon={faExclamationCircle} /> In order to
                    continue working in <b>private</b> projects, a subscription is required.
                  </div>
                </div>

                <div className="mt-6 flex items-center">
                  <Button
                    className="mr-4"
                    size={sizes.lg}
                    color={colors.blue}
                    loading={trialUntilService.isRemoving}
                    onClick={() => {
                      routerStore.replace(billingPath);
                    }}
                  >
                    Subscribe
                  </Button>

                  <Button
                    size={sizes.lg}
                    loading={trialUntilService.isRemoving}
                    onClick={() => {
                      trialUntilService.remove(true, { namespaceId, namespaceType }).then(() => {
                        alert.success(
                          'Open Source plan selected! You can upgrade at anytime from the billing page.'
                        );

                        service.get(namespaceId);

                        appStore.closeModal();
                      });
                    }}
                  >
                    Open Source
                  </Button>
                </div>
              </div>
            }
          />
        );
      }}
    />
  );
};

const InjectedTrialModal = inject((stores, props) => {
  const { appStore, routerStore, trialUntilService } = stores;
  const { namespace } = props;

  return {
    appStore,
    routerStore,
    trialUntilService,
    service:
      namespace && namespace.namespace_type === 'org' ? stores.orgService : stores.userService,
  };
})(observer(TrialModal));

const TrialBadge = props => {
  const { className, namespace, routerStore, appStore, namespaceService } = props;

  const trial = getTrial(namespace.subscription);
  const isOrg = namespace.namespace_type === 'org';
  const canUpdate = namespaceService.canUser({ action: 'update' });

  if (!trial || !getConfigVar('STRIPE_PK') || (!canUpdate && isOrg)) return null;

  const isExpired = trial <= 0;

  // don't show on personal, since we're likely getting rid of it and it confuses our main use case (orgs)
  if (!isOrg) {
    return <div />;
  }

  return (
    <div>
      <div
        className={cn(
          'text-rounded py-1/2 px-2 text-sm text-white cur-p hover:opacity-85',
          isExpired ? 'bg-red' : 'bg-green',
          className
        )}
        onClick={() => {
          if (isExpired) {
            return appStore.openModal('trial-expired');
          }

          routerStore.replace(`/${namespace.path}/settings/billing`);
        }}
      >
        {!isExpired && <Icon className="mx-1/2" icon={faGift} />}
        Organization
        {isExpired ? ' Trial Expired' : ` Trial: ${trial} ${pluralize('Day', trial)}`}
      </div>

      {isExpired && <InjectedTrialModal namespace={namespace} />}
    </div>
  );
};

export default inject((stores, props) => {
  const { appStore, routerStore, namespaceService } = stores;

  return {
    appStore,
    routerStore,
    namespaceService,
    namespace: namespaceService.current || {},
  };
})(observer(TrialBadge));
