import { types } from 'mobx-state-tree';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

import { getConfigVar } from '@platform/utils/config';

export const create = ({ data, env, options = {} }) => {
  let basePath = '/session';

  // allow overriding login API path (required for LDAP SSO)
  const customLogin = getConfigVar('SL_LOGIN_PATH');
  if (customLogin) {
    basePath = customLogin;
  }

  const Base = types
    .model({
      basePath,
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      self.skipStore = true;

      return {};
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('SessionService');

  return Service.create(data, env);
};
