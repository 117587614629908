import _ from 'lodash';

import { oas2RefRouter } from '../hubs/oas';
import { hubRefRouter } from '../hubs/index';
import { scenariosRefRouter } from '../hubs/scenarios';
import {
  dataFormat,
  urlSource,
  getUrlTarget,
  stoplightResourceIdentifier,
  getFileFromURL,
  fileFormat,
} from '../url';

const { getConfigVar } = require('@platform/utils/config');

export const routes = ({ spec = {}, targets = {} }) => {
  let data = {};
  const format = dataFormat({ data: spec });
  switch (format) {
    case 'oas':
      data = oas2RefRouter({ spec, targets: _.get(targets, 'oas2', []) });
      break;
    case 'hub':
      data = hubRefRouter({ spec, targets: _.get(targets, 'hub', []) });
      break;
    case 'scenario':
      data = scenariosRefRouter({ spec, targets: _.get(targets, 'scenario', []) });
      break;
    default:
  }

  return data;
};

export const refUrlProperties = ({ u, projectId } = {}) => {
  if (!u) {
    return {};
  }

  const source = urlSource({ u });
  const target = getUrlTarget({ u });

  let sri = {};
  if (source.source === 'internal') {
    sri = stoplightResourceIdentifier({ u });
  }

  const file = getFileFromURL({ u });
  const format = fileFormat(file);

  if ((projectId && projectId === sri.projectId) || (!_.isEmpty(sri) && !sri.id)) {
    source.source = 'currentProject';
  } else if (source.source === 'internal') {
    // internal (other projects) not supported by refbuilder
    source.source = 'external';
  }

  // temporary while we have something more permenant for mapping
  let shared;
  _.forEach(sharedTargets(), (value, key) => {
    return _.forEach(value.results, result => {
      if (result.exporturl === u) {
        shared = { source: 'shared', target: result };
      }
    });
  });

  if (shared) return shared;

  return { u, source: source.source, format, sri, target };
};

export const sharedTargets = () => {
  return {
    'prism-helpers': {
      name: 'prism-helpers',
      results: [
        {
          key: 'before-mock',
          title: 'Before Mock Scenario',
          exporturl:
            getConfigVar('SL_APP_HOST') + '/misc/scenarios/helper-scenarios.json#/scenarios/b-mock',
          referencelink: 'https://docs.stoplight.io/mocking/introduction',
        },
        {
          key: 'after-mock',
          title: 'After Mock Scenario',
          exporturl:
            getConfigVar('SL_APP_HOST') + '/misc/scenarios/helper-scenarios.json#/scenarios/a-mock',
          referencelink: 'https://docs.stoplight.io/mocking/introduction',
        },
        {
          key: 'validate',
          title: 'Validate Scenario',
          exporturl:
            getConfigVar('SL_APP_HOST') +
            '/misc/scenarios/helper-scenarios.json#/scenarios/validate',
          referencelink: 'https://docs.stoplight.io/testing/leveraging-openapi/contract-testing',
        },
      ],
    },
  };
};

/*
loops through an object { category: [items] } to build semantic-ui search results
with categories { category: { name: category, results: [{ key, title }] } }

optionally takes:
a function to minipulate category value if its not an array
a function to manipulate results item to store as results key
a function to manipulate results item to store as results title
a function to filter by matching on category or results item
*/
export const buildCategoryResults = (
  obj = {},
  arr = value => value,
  key = item => item,
  title = item => item,
  isMatch = () => true
) => {
  const results = {};
  if (!_.isEmpty(obj)) {
    _.forEach(obj, (value, category) => {
      _.forEach(arr(value), item => {
        if (isMatch(category, item)) {
          if (_.get(results, [category, 'results'])) {
            results[category].results.push({
              key: key(item),
              title: title(item),
            });
          } else {
            results[category] = {
              name: category,
              results: [{ key: key(item), title: title(item) }],
            };
          }
        }
      });
    });
  }

  return results;
};

/*
loops through an array [items] to build semantic-ui search results
 [{ key, title }]

optionally takes:
a function to manipulate results item to store as results key
a function to manipulate results item to store as results title
a function to filter by matching item
*/

export const buildResults = (
  list = [],
  key = item => item,
  title = item => item,
  isMatch = () => true
) => {
  const results = [];
  _.forEach(list, item => {
    if (isMatch(item)) {
      results.push({ key: key(item), title: title(item) });
    }
  });

  return results;
};

/*
loops through an array [items] to build semantic-ui dropdown options
 [{ text, value }]

optionally takes:
a function to manipulate results item to store as results text
a function to manipulate results item to store as results value
a function to filter by matching item
*/

export const buildOptions = (
  list = [],
  text = item => item,
  value = item => item,
  isMatch = () => true
) => {
  const results = [];
  _.forEach(list, item => {
    if (isMatch(item)) {
      results.push({ text: text(item), value: value(item) });
    }
  });

  return results;
};
