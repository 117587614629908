import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Segment, Header, Button } from 'semantic-ui-react';

import FormInput from '@platform/components/FormInput';
import FormSelect from '@platform/components/FormSelect';
import FormCheckbox from '@platform/components/FormCheckbox';
import FeatureBadge from '@platform/components/FeatureBadge';
import { getConfigVar } from '@platform/utils/config';
import { hasSubscriptionFeature } from '@platform/utils/billing';

import OrgSettingsLayout from './_layout';

class OrgSSOPage extends React.Component {
  render() {
    const { org = {}, ui, updateUi, clearUi, orgService } = this.props;
    const { loginUrl, domains = [] } = ui;
    const { current = {} } = orgService;

    const enabled = hasSubscriptionFeature(current, 'sso');

    return (
      <OrgSettingsLayout activeTab="sso">
        <Segment attached="bottom">
          <Header
            as="h3"
            className="mb-2"
            dividing
            content={
              <div className="flex items-center">
                <div className="flex-1">Enable SAML Single Sign-on</div>
                <FeatureBadge
                  feature="sso"
                  show={!enabled}
                  namespace={{ ...current, namespace_type: 'org' }}
                />
              </div>
            }
            subheader="Enable your team to log in via Okta, OneLogin, or other SAML SSO providers."
          />

          <FormInput
            className="mb-2"
            label="Login URL from your SSO Provider"
            tip="Users will be redirected here to login."
            placeholder="https://myssoprovider.com/auth/stoplight/saml"
            value={loginUrl}
            onChange={(e, { value }) => {
              updateUi('set', 'loginUrl', value);
            }}
            fluid
          />

          <FormInput
            className="mb-2"
            label="SAML callback URL to add to your SSO provider's configuration"
            value={`${getConfigVar('SL_API_HOST')}/sso/${org.id}/saml/callback`}
            fluid
            readOnly
          />

          <FormSelect
            className="mb-2"
            label="Whitelisted email domains"
            placeholder="contact support@stoplight.io to enable this feature"
            tip="Any user that attempts to register or sign-in to Stoplight with these domains will be forwarded to your SSO provider."
            value={domains}
            multiple
            fluid
            readOnly
            allowAdditions
            search
          />

          <FormCheckbox
            label="Auto-provision users on first login"
            tip="If a user does not already have an account when logging into Stoplight via your SSO provider, one will be created for them."
            checked
            inline
            disabled
          />

          <div className="mt-2">
            <Button
              content="Save"
              disabled={orgService.isSaving || _.isEmpty(ui) || !enabled}
              primary={!_.isEmpty(ui)}
              onClick={this.handleSave}
            />

            <Button
              basic
              content="Reset"
              disabled={_.isEmpty(ui)}
              onClick={e => {
                e.preventDefault();
                clearUi();
              }}
            />
          </div>
        </Segment>
      </OrgSettingsLayout>
    );
  }
}

export default inject(({ appStore, orgService }, props) => {
  const org = orgService.current || {};

  return {
    org,
    orgService,
    ...appStore.injectUi(`OrgSSOPage:${org.id}`),
  };
})(observer(OrgSSOPage));
