import React from 'react';

import HtmlViewer from '@platform/components/HtmlViewer';

import EntityEditor from '../EntityEditor';
import ThemeWrapper from '../ThemeWrapper';

import HtmlEditorCode from './spec';

const contentTabs = [
  {
    id: 'read',
    component: ThemeWrapper,
    propFactory: ({ editor }) => {
      return {
        component: HtmlViewer,
        componentProps: {
          value: editor.spec,
          className: 'bg-white overflow-y-auto',
        },
      };
    },
  },
  {
    id: 'code',
    component: ThemeWrapper,
    propFactory: ({ editor }) => {
      return {
        component: HtmlEditorCode,
        componentProps: {
          editor,
          preview: editor.preview,
        },
      };
    },
  },
];

const HtmlEditor = props => {
  return (
    <EntityEditor
      editorStoreKey="htmlEditorStore"
      contentTabs={contentTabs}
      contentRouterData={{}}
      {...props}
      disableLocal
    />
  );
};

export default HtmlEditor;
