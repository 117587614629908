import * as React from 'react';

const cn: any = require('classnames');

import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';

import { NavLink } from '@platform/utils/router';

import { IUriComponents } from '@core/uri';

import { Icon, IIcon, themes } from '@core/ui';

export interface IPanelHeader {
  path: Array<{
    name: string;
    uri?: IUriComponents;
    className?: string;
  }>;
  isLarge?: boolean;
  theme?: themes;
  noUpper?: boolean;
  handleClose?: any;
  closeIcon?: IIcon;
  className?: string;
}

export const PanelHeader = (props: IPanelHeader) => {
  const { path, isLarge, theme = themes.light, noUpper, handleClose, closeIcon, className } = props;

  const pathElems: any = [];
  for (const i in path) {
    if (!path[i]) continue;

    const p = path[i];
    const pClassName = cn(
      {
        'text-sm': !isLarge || (parseInt(i) === 0 && path.length === 1),
        'text-white': theme === themes.dark,
        'font-bold': path.length === 0 || !path[parseInt(i) + 1],
      },
      p.className
    );

    if (p.uri) {
      pathElems.push(
        <NavLink key={i} uri={p.uri} className={pClassName}>
          {p.name}
        </NavLink>
      );
    } else {
      pathElems.push(
        <div key={i} className={pClassName}>
          {p.name}
        </div>
      );
    }

    if (!isLarge && path[parseInt(i) + 1]) {
      pathElems.push(
        <div key={`${i}d`} className="px-2 opacity-85 cursor-default">
          /
        </div>
      );
    }
  }

  return (
    <div
      className={cn(className, 'px-4 flex items-center', {
        'bg-darken-300': theme === themes.dark,
        'h-10': !isLarge,
        'py-3': isLarge,
      })}
    >
      <div
        className={cn('flex-1 flex overflow-hidden', {
          'flex-col': isLarge,
          uppercase: !noUpper,
          'text-grey-darker': theme === themes.light,
          'opacity-85 text-white': theme === themes.dark,
        })}
      >
        {pathElems}
      </div>

      {handleClose && (
        <div
          className={cn('cursor-pointer h-6 w-6 flex items-center justify-center', {
            'hover:bg-darken-50': theme === themes.light,
            'hover:bg-lighten-100': theme === themes.dark,
            'hover:text-lighten-500': theme === themes.dark,
            'text-lighten-300': theme === themes.dark,
          })}
          onClick={handleClose}
        >
          <Icon icon={closeIcon || faTimes} />
        </div>
      )}
    </div>
  );
};
