import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { Button, Input, Icon, Popup, Message } from 'semantic-ui-react';

import { stepResultMeta, stepName, stepToken } from '@platform/utils/collections';
import { pathToHash } from '@platform/utils/history';
import { Link } from '@platform/utils/router';

import CollectionTerminal from '../CollectionTerminal';
import CollectionTriggerByUrl from '../CollectionTriggerByUrl';
import SortableList from '../SortableList';
import BarListItem from '../BarListItem';
import FormInput from '../FormInput';
import MarkdownEditor from '../MarkdownEditor';
import UsedEnvironmentVariables from '../UsedEnvironmentVariables';
import FormSelect from '../FormSelect';
import EntityEditorGroup from '../EntityEditorGroup';
import EditorContentSection from '../EditorContentSection';

const StepList = inject('routerStore')(
  observer(({ routerStore, editor, handleUpdate, ui, updateUi, readOnly, hideResults }) => {
    if (!editor.id) {
      return null;
    }

    const currentlyRunningCollection = editor.collectionRunning;
    const scenarioId = editor.currentScenarioId;
    const scenarioPath = editor.currentScenarioPath;
    const currentlyRunningScenario = editor.scenariosRunning.get(scenarioId);
    const currentlyRunning = editor.stepsRunning;
    const results = editor.stepResultsMap;
    const steps = _.get(editor, ['currentScenario', 'data', 'steps']);

    const sortableItems = _.compact(
      (steps || []).map((step, i) => {
        if (!step) {
          return null;
        }

        const resultData = stepResultMeta({
          stepResult: _.get(results, [`${scenarioId}-${i}`, 'data']),
        });
        const isRunning =
          currentlyRunningCollection ||
          currentlyRunningScenario ||
          currentlyRunning.get(`${scenarioId}-${i}`);
        const isStepEdited = ui.editingStepId === i;

        return {
          id: i,
          data: step,
          disableDrag: readOnly || isStepEdited,
          elem: (
            <BarListItem
              className="ScenarioStepList-item"
              withBorder={steps[i + 1] ? true : false}
              parts={[
                {
                  padded: true,
                  elem: () => (
                    <Icon name="bars" color="grey" style={{ cursor: 'move' }} disabled={readOnly} />
                  ),
                },
                {
                  fill: true,
                  padded: isStepEdited,
                  elem: () => {
                    let tokenElem;

                    const token = stepToken(step, { maxLength: 4 });
                    if (token && token.name) {
                      tokenElem = (
                        <div className={cn('ScenarioStepList-token font-bold', token.className)}>
                          {token.name}
                        </div>
                      );
                    }

                    if (isStepEdited) {
                      return (
                        <div className="flex items-center ScenarioStepList-name">
                          {tokenElem}
                          <Input
                            className="flex-1"
                            defaultValue={stepName(step, {
                              skipToken: true,
                              mappedScenarioNames: editor.mappedNames,
                            })}
                            onChange={(e, { value }) =>
                              handleUpdate('set', ['steps', i, 'name'], value)
                            }
                            autoFocus={isStepEdited}
                            onFocus={e => {
                              e.target.value = _.get(step, 'name', '');
                            }}
                            onBlur={e => {
                              e.target.value = _.get(step, 'name', '') || e.target.defaultValue;
                              updateUi('set', 'editingStepId', null);
                            }}
                            onKeyDown={({ key }) => {
                              if (key === 'Enter' || key === 'Escape') {
                                updateUi('set', 'editingStepId', null);
                              }
                            }}
                          />
                        </div>
                      );
                    }

                    return (
                      <Link
                        className="flex items-center ScenarioStepList-name"
                        id={`tutorial-scenario-step-${i}`}
                        to={routerStore.buildQueryParams(
                          {
                            edit: pathToHash({
                              path: editor.currentScenarioPath.concat(['steps', i]),
                            }),
                          },
                          { preserve: true }
                        )}
                      >
                        {tokenElem}
                        {stepName(step, {
                          skipToken: true,
                          mappedScenarioNames: editor.mappedNames,
                        })}
                      </Link>
                    );
                  },
                },
                {
                  padded: true,
                  hide: resultData.status === 'not run',
                  className: resultData.className,
                  elem: () => resultData.status,
                },
                {
                  elem: () => (
                    <Popup
                      content="Run step"
                      trigger={
                        <Button
                          icon="play"
                          onClick={() => editor.runStep(i)}
                          loading={isRunning ? true : false}
                          disabled={isRunning || hideResults ? true : false}
                          size="small"
                          secondary
                          basic
                        />
                      }
                    />
                  ),
                },
                {
                  elem: () =>
                    isStepEdited ? (
                      <Popup
                        content="Finish editing"
                        trigger={
                          <Button
                            basic
                            color="green"
                            icon="check"
                            onClick={() => updateUi('set', 'editingStepId', null)}
                            size="small"
                          />
                        }
                      />
                    ) : (
                      <Popup
                        content="Edit step name"
                        trigger={
                          <Button
                            basic
                            icon="pencil"
                            onClick={() => updateUi('set', 'editingStepId', i)}
                            size="small"
                            disabled={readOnly}
                          />
                        }
                      />
                    ),
                },
                {
                  elem: () => (
                    <Popup
                      content="Duplicate step"
                      trigger={
                        <Button
                          basic
                          icon="copy"
                          onClick={() => editor.cloneStep({ path: [...scenarioPath, 'steps', i] })}
                          size="small"
                          disabled={readOnly}
                        />
                      }
                    />
                  ),
                },
                {
                  elem: () => (
                    <Popup
                      content="Remove step"
                      trigger={
                        <Button
                          basic
                          icon="trash"
                          color="red"
                          onClick={() => editor.removeStep(i)}
                          disabled={readOnly || steps.length <= 1}
                          size="small"
                        />
                      }
                    />
                  ),
                },
              ]}
            />
          ),
        };
      })
    );

    return (
      <SortableList
        items={sortableItems}
        handleReorder={(fromIndex, toIndex) => {
          if (!readOnly) {
            editor.reorderSteps(fromIndex, toIndex);
          }
        }}
      />
    );
  })
);

function ScenarioOverview(props) {
  const {
    id,
    editorId,
    editor,
    currentScenario = {},
    handleUpdate,
    orgId,
    ui = {},
    updateUi,
    clearUi,
    pathKey,
    onPathKeyChange,
    hideResults,
    hideTriggers,
    readOnly,
    userService,
  } = props;

  return (
    <div className="ScenarioOverview">
      <EditorContentSection>
        {readOnly ? (
          <Message info size="small">
            <Icon name="info" /> This editor is in read only mode.
          </Message>
        ) : null}

        <div className="flex">
          <FormInput
            className="pr-2"
            value={ui.hasOwnProperty('pathKey') ? ui.pathKey : pathKey || ''}
            onChange={e => {
              updateUi('set', ['pathKey'], e.target.value);
            }}
            onBlur={() => {
              onPathKeyChange(pathKey, ui.pathKey, () => {
                clearUi();
              });
            }}
            label="ID"
            tip="The ID for this scenario. MUST be unique within this collection."
            required
          />

          <FormInput
            className="flex-1 pl-2 pr-2"
            input={{
              value: _.get(currentScenario, 'data.name', ''),
              onChange(e) {
                handleUpdate('set', ['name'], e.target.value);
              },
              readOnly,
            }}
            label="name"
            tip="An optional, pretty name for this scenario."
            fluid
          />

          <FormSelect
            className="flex-1 pl-2"
            value={currentScenario.stage}
            onChange={value => {
              if (value !== currentScenario.stage) {
                editor.moveProp(
                  [currentScenario.stage, currentScenario.key],
                  [value, currentScenario.key],
                  () => {
                    editor.sortScenarios(value);
                  }
                );
              }
            }}
            disabled={readOnly}
            options={[
              { text: 'Before', value: 'before' },
              { text: 'Scenarios', value: 'scenarios' },
              { text: 'After', value: 'after' },
              { text: 'Utilities', value: 'utilities' },
            ]}
            placeholder="Scenario Type"
            label="stage"
            fluid
          />
        </div>

        <div className="mt-6">
          <MarkdownEditor
            className="ScenarioEditor-notes"
            placeholder="Optional scenario notes..."
            value={_.get(currentScenario, 'data.description', '')}
            readOnly={readOnly}
            onChange={markdownVal => {
              handleUpdate('set', ['description'], markdownVal);
            }}
          />
        </div>

        <UsedEnvironmentVariables editorId={editor.id} className="mt-6" />

        <EntityEditorGroup
          id="scenario-steps"
          className="mt-6"
          name="Steps"
          defaultOpen
          contentFactory={() => (
            <StepList
              editor={editor}
              handleUpdate={handleUpdate}
              ui={ui}
              updateUi={updateUi}
              readOnly={readOnly}
              hideResults={hideResults}
            />
          )}
          actions={
            readOnly
              ? []
              : [
                  {
                    icon: 'plus',
                    content: 'Add Step',
                    secondary: true,
                    onClick: () => {
                      const step = editor.addStep();
                      updateUi('set', 'editingStepId', step.id);
                    },
                  },
                ]
          }
        />

        {!hideTriggers ? (
          <EntityEditorGroup
            id="collection-triggers"
            className="mt-6"
            name="Trigger This Scenario"
            padded
            contentFactory={() => (
              <div>
                <CollectionTerminal
                  orgId={orgId}
                  scenarioIds={[id.replace(`${editorId}-`, '')]}
                  editorId={editor.id}
                  message={
                    userService.isLoggedIn ? null : 'Login or register to run from terminal.'
                  }
                />
                <br />
                <CollectionTriggerByUrl srn={editor.id} pathKey={pathKey} editorId={editor.id} />
              </div>
            )}
          />
        ) : null}
      </EditorContentSection>
    </div>
  );
}

export default inject(({ appStore, collectionEditorStore, userService }, { editorId }) => {
  const editor = collectionEditorStore.getEditor({ id: editorId });

  return {
    editor,
    userService,
    currentScenario: editor.currentScenario,
    handleUpdate: (t, p, v) => {
      if (!_.isEmpty(editor)) {
        editor.updateCurrentParsed(t, p, v);
      }
    },
    ...appStore.injectUi('ScenarioEditor'),
  };
})(observer(ScenarioOverview));
