import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import SidebarFilter from '@platform/components/SidebarFilter';
import SidebarFilterSettings from '@platform/components/SidebarFilterSettings';

import PageContent from '../../components/PageContent';
import ProjectCreate from '../../components/ProjectCreate';
import ProjectList from '../../components/ProjectList';

class OrgProjects extends React.Component {
  componentWillMount() {
    const { routerStore, tagStoreInstance } = this.props;

    const namespaceId = _.get(routerStore, 'location.params.namespaceId');
    if (!namespaceId) {
      return;
    }

    this.findItems({ page: 1 });
    tagStoreInstance.find({ namespaceId });
  }

  findItems = ({ search, page } = {}) => {
    const { routerStore, projectService } = this.props;

    const namespaceId = _.get(routerStore, 'location.params.namespaceId');
    const query = { order_by: 'name', sort: 'asc' };
    let skipPagination = false;

    if (search) {
      query.search = search;
      skipPagination = true;
    } else {
      query.page = page || projectService.getCurrentPage() + 1;
    }

    projectService.find(
      {
        namespaceType: 'orgs',
        namespaceId,
      },
      {
        query,
        skipPagination,
      }
    );
  };

  render() {
    const { org, orgService, projectService, tagStoreInstance, namespaceStore } = this.props;

    const projects = projectService.currentList;
    const tags = tagStoreInstance.tags;
    const activeTags = _.get(namespaceStore.current, 'activeFilters.tags', []);
    const activeFilterSettings = _.get(namespaceStore.current, 'activeFilterSettings.tags', {});

    const canView = orgService.canUser({ action: 'view' });
    const canUpdate = orgService.canUser({ action: 'update' });
    const canCreateProject = orgService.canUser({ action: 'create:project' });

    let contentElem;
    if (
      projectService.isFinding ||
      !canCreateProject ||
      !_.isEmpty(projects) ||
      !_.isEmpty(activeTags)
    ) {
      contentElem = [
        <div key="1" className="flex-auto">
          <ProjectList
            namespace={{ ...org, namespace_type: 'org' }}
            projects={projects}
            tags={tags}
            loadMore={this.findItems}
            onSearch={searchQuery => {
              this.findItems({ search: searchQuery });
            }}
            displayFullTags={activeFilterSettings.displayFullTags}
          />
        </div>,

        <div key="2" className="ml-6">
          <SidebarFilterSettings filterName="tags" />

          {canView && (
            <SidebarFilter
              filterName="tags"
              displayName="Lifecycle Tags"
              items={_.filter(tags, t => t.type === 'lifecycle')}
              settingsPathname={`/${org.path}/settings/filters`}
              canCreate={canUpdate}
              hideIfEmpty
            />
          )}

          <SidebarFilter
            filterName="tags"
            displayName="Other Tags"
            items={_.filter(tags, t => !t.type)}
            settingsPathname={`/${org.path}/settings/filters`}
            canCreate={canUpdate}
            hideIfEmpty
          />
        </div>,
      ];
    } else {
      contentElem = (
        <div className="w-100 mx-auto my-12 rounded-lg shadow" style={{ maxWidth: 800 }}>
          <ProjectCreate />
        </div>
      );
    }

    return <PageContent>{contentElem}</PageContent>;
  }
}

export default inject(
  ({ orgService, projectService, routerStore, tagStore, namespaceStore }, props) => {
    const org = orgService.current || {};
    const tagStoreInstance = tagStore.register(`OrgProjects-${org.id}`);

    return {
      org,
      orgService,
      projectService,
      routerStore,
      tagStoreInstance,
      namespaceStore,
    };
  }
)(observer(OrgProjects));
