module.exports = {
  NODE_ENV: 'production',
  RELEASE_STAGE: 'production',
  BUILD_ID: '000',

  // SL_APP_HOST: 'https://next.stoplight.io',
  // SL_API_HOST: 'https://next-api.stoplight.io',
  // SL_GITLAB_HOST: 'https://gitlab.stoplight.io',
  // SL_PRISM_HOST: 'https://prism.stoplight.io',
  // SL_PUBS_HOST: 'tech-docs.io',
  // SL_PUBS_INGRESS: 'ingress.tech-docs.io',
  // SL_MIGRATION_HOST:
  //   'https://gu4denr2nvqxg5dfoi5g22lhojqxi2lpnyxhgy3fnzqxe2lpomxhs3lm.prism.stoplight.io',

  // AWS_BUCKET: 'user-content.stoplight.io',
  // GITHUB_CLIENT_ID: '756956c5ddce1b579de6',
  // GTM_KEY: 'GTM-TQGKXNW',
  // GA_KEY: 'UA-73790375-8',
  // INTERCOM_KEY: 'g7eo4oae',
  // STRIPE_PK: 'pk_live_1zLROeDhR4a35o2OMgvChIfG',
  // ACCOUNT_DOCK_KEY: 'ad_acco_4weuvzhpcgx15l8n',
  // BUGSNAG_KEY: '45837fb90fa3085732214a960e9545ea',
  // FULLSTORY_KEY: '7CQBR',
  // HEAP_KEY: '1518412553',
};
