import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Accordion from '@platform/components/Accordion';

const userFAQ = [
  {
    q: 'Is my credit card information secure?',

    a:
      'Yes. All credit card processing is handled through Stripe, and your card information is never sent to, or stored on, our servers.',
  },

  {
    q: 'What kind of payments do you accept?',

    a:
      'We accept all major credit cards including American Express, Discover, Mastercard, and Visa.',
  },

  {
    q: 'Is there a free trial?',

    a: 'Yes. Every new account has 14 days to try out the platform.',
  },

  {
    q: 'When will I be charged?',

    a:
      'After entering your card details, your card will be charged for the initial 30 day or annual billing cycle, depending on the interval chosen.',
  },

  {
    q: 'Can I upgrade / downgrade anytime?',

    a: 'Yes. You can easily adjust your plan and update through the Billing settings.',
  },
];

const orgFAQ = [
  {
    q: 'What counts as an active member?',

    a:
      'A member is considered active if they have updated any projects or project files in the past 90 days. Otherwise, they are considered a passive member. Newly invited members will always start as passive until they create or update a project.',
  },

  {
    q: 'Will my billing automatically adjust as members change between passive and active?',

    a:
      'Yes, we will automatically detect changes to member status and adjust your bill accordingly. Your account will receive prorated credits or charges as member status changes.',
  },

  {
    q: 'Is my credit card information secure?',

    a:
      'Yes. All credit card processing is handled through Stripe, and your card information is never sent to, or stored on, our servers.',
  },

  {
    q: 'What kind of payments do you accept?',

    a:
      'We accept all major credit cards including American Express, Discover, Mastercard, and Visa. For certain business plans, we can set up a custom net terms plan. Please send an email to support@stoplight.io.',
  },

  {
    q: 'When will I be charged?',

    a:
      'After entering your card details, your card will be charged for the initial 30 day or annual billing cycle, depending on the interval chosen.',
  },

  {
    q: 'Do you offer discounts for open source, non-profits, or educational institutions?',

    a:
      'Yes. Please send an email to support@stoplight.io for more info. To provide the discount, we will ask for more information based on the type of discount.',
  },
];

class BillingFAQ extends React.Component {
  render() {
    const { className, namespaceType } = this.props;

    return (
      <Accordion
        id={`Billing-${namespaceType}`}
        className={cn('BillingFAQ', className)}
        exclusive
        panels={_.map(namespaceType === 'user' ? userFAQ : orgFAQ, (item, index) => {
          return {
            key: 0,
            title: item.q,
            content: <div className="pl-8">{item.a}</div>,
          };
        })}
        fluid
      />
    );
  }
}

BillingFAQ.propTypes = {
  className: PropTypes.string,
  namespaceType: PropTypes.string,
};

export default BillingFAQ;
