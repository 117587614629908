import React from 'react';
import { inject, observer } from 'mobx-react';

import { PanelSections } from '@platform/components/PanelSections';

import ReleaseNotes from './release-notes';
import ReleaseCreate from './release-create';
import { VersionTree } from './version-tree';
import { VersionCreate } from './version-create';

const VersionPanel = props => {
  const { projectStore, versionService } = props;
  const { isReadonly, currentRef, currentVersion } = projectStore.current || {};

  const isReleased = versionService.isReleased(currentRef);

  const sections = [
    {
      extended: true,
      renderChildren: () => <VersionTree />,
    },
  ];

  if (!isReadonly && currentVersion) {
    sections.push({
      title: isReleased ? 'Edit Release' : 'Create Release',
      titleColor: 'black',
      titleBgColor: 'grey-light',
      extended: false,
      renderChildren: () => <ReleaseCreate />,
    });
  }

  if (isReadonly && isReleased) {
    sections.push({
      title: 'Release Notes',
      titleColor: 'black',
      titleBgColor: 'grey-light',
      extended: false,
      renderChildren: () => <ReleaseNotes />,
    });
  }

  if (!isReadonly) {
    sections.push({
      title: 'New Version',
      titleColor: 'black',
      titleBgColor: 'grey-light',
      extended: false,
      renderChildren: () => <VersionCreate />,
    });
  }

  return (
    <PanelSections
      header={{
        path: [{ name: 'Versions & Releases', className: 'text-black text-base' }],
        noUpper: true,
      }}
      sections={sections}
    />
  );
};

export default inject('projectStore', 'versionService')(observer(VersionPanel));
