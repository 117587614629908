import React from 'react';
import _ from 'lodash';
import { Button, Divider, Header } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import Accordion from '@platform/components/Accordion';
import ErrorMessage from '@platform/components/ErrorMessage';
import FormInput from '@platform/components/FormInput';
import FormTextarea from '@platform/components/FormTextarea';
import UserSearchDropdown from '@platform/components/UserSearchDropdown';

import { alert } from '@platform/utils/alert';
import { simpleSlugify } from '@platform/utils/general';
import { getConfigVar } from '@platform/utils/config';

class TeamCreate extends React.Component {
  componentWillMount() {
    const { routerStore } = this.props;

    const membersQS = _.get(routerStore, 'location.query.members', []);

    if (!_.isEmpty(membersQS)) {
      this.handleFormInput('set', 'members', _.split(membersQS, ','));
    }
  }

  handleFormInput = (t, p, v) => {
    this.props.updateUi(t, `form.${p}`, v);
  };

  handleClearFormInput = e => {
    if (e) {
      e.preventDefault();
    }

    this.props.updateUi('set', 'form', {});
  };

  handleCreate = e => {
    e.preventDefault();

    const { ui = {}, org = {}, query = {}, routerStore, teamService } = this.props;

    const newTeam = ui.form || {};

    teamService
      .create(newTeam, query)
      .then(result => {
        this.handleClearFormInput();
        alert.success('Team created.');
        routerStore.push({
          pathname: `/${org.path}/teams/${result.path}`,
        });
      })
      .catch(e => {
        alert.error('There was an error creating the team. Please try again.');
      });
  };

  render() {
    const {
      ui = {},
      team = {},
      org = {},
      firstTime,
      teamService,
      routerStore,
      orgMemberService,
    } = this.props;

    const newTeam = ui.form || {};

    return (
      <div className="EntityCreate">
        <Accordion
          id="TeamCreate"
          styled
          fluid
          defaultActiveIndex={0}
          panels={[
            {
              key: 0,
              content: (
                <div className="p-6">
                  <div className="pt-4">
                    <Header as="h2" textAlign="center">
                      Create {firstTime ? 'Your First' : 'a New'} Team
                      <Header.Subheader>
                        Teams make it easier to control access for groups of people.
                      </Header.Subheader>
                    </Header>
                  </div>

                  <Divider />

                  <div className="mt-6">
                    <ErrorMessage
                      className="mb-6"
                      error={teamService.error}
                      onDismiss={teamService.clearError}
                    />

                    <div className="flex mb-6">
                      <div className="flex-1 pr-3">
                        <FormInput
                          label="Team Name"
                          placeholder="A department, project group, etc."
                          value={
                            newTeam.hasOwnProperty('name') ? newTeam.name : _.get(team, 'name', '')
                          }
                          onChange={(e, { value }) => {
                            this.handleFormInput('set', 'name', value);
                          }}
                          onBlur={() => {
                            if (_.isEmpty(newTeam.path)) {
                              this.handleFormInput('set', 'path', simpleSlugify(newTeam.name));
                            }
                          }}
                          fluid
                        />
                      </div>

                      <div className="flex-grow">
                        <FormInput
                          label="Team Path"
                          prefix={`${getConfigVar('SL_APP_HOST')}/${org.path}/teams/`}
                          placeholder="engineering"
                          value={
                            newTeam.hasOwnProperty('path') ? newTeam.path : _.get(team, 'path', '')
                          }
                          onChange={(e, { value }) => {
                            this.handleFormInput('set', 'path', value);
                          }}
                          onBlur={() => {
                            this.handleFormInput('set', 'path', simpleSlugify(newTeam.path));
                          }}
                          fluid
                        />
                      </div>
                    </div>

                    <FormTextarea
                      className="mb-6"
                      label="Description"
                      name="description"
                      minRows={1}
                      maxRows={4}
                      placeholder="What does this team do?"
                      value={
                        newTeam.hasOwnProperty('description')
                          ? newTeam.description
                          : _.get(team, 'description', '')
                      }
                      onChange={(e, { value }) => {
                        this.handleFormInput('set', 'description', value);
                      }}
                      bordered
                    />

                    <UserSearchDropdown
                      id={`${org.id}:org`}
                      label="Add Members From This Organization"
                      placeholder="Type, then press enter."
                      value={newTeam.members}
                      excludedMembers={newTeam.members}
                      handleChange={(e, { value }) => this.handleFormInput('set', 'members', value)}
                      handleSearch={({ searchQuery } = {}) => {
                        return orgMemberService.find(
                          { orgId: org.id },
                          { query: { query: searchQuery } }
                        );
                      }}
                      loading={orgMemberService.isFinding}
                      allowAdditions={false}
                      noResultsMessage="Add members to this organization before adding them to a team."
                      multiple
                      fluid
                    />
                  </div>
                </div>
              ),
            },
          ]}
        />

        <div className="EntityCreate-actions flex items-center">
          <div className="mr-6">
            <Button
              primary
              disabled={teamService.isSaving || _.size(newTeam.name) < 2}
              loading={teamService.isSaving}
              onClick={this.handleCreate}
            >
              Create Team
            </Button>
          </div>

          {!firstTime && (
            <div>
              <Button
                basic
                onClick={() => {
                  routerStore.push({
                    pathname: `/${org.path}/teams`,
                  });
                }}
              >
                Back To List Of Teams
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default inject((stores, props) => {
  const { routerStore, orgService, teamService, orgMemberService, appStore } = stores;

  const org = orgService.current;

  return {
    ...appStore.injectUi(`TeamCreate-${org.id}`),
    query: { orgId: org.id },
    org,
    routerStore,
    teamService,
    orgMemberService,
  };
})(observer(TeamCreate));
