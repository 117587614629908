import _ from 'lodash';
import md5 from 'js-md5';

export const identicon = value => {
  if (_.isEmpty(value)) {
    return 'https://www.gravatar.com/avatar/xxx?d=identicon';
  }
  let imgSrc = '';

  try {
    imgSrc = md5(value);
  } catch (e) {}

  return `https://www.gravatar.com/avatar/${imgSrc}?d=identicon`;
};
