import * as EX from '@core/extension';

import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faMagic } from '@fortawesome/pro-solid-svg-icons/faMagic';
import { faPaintBrush } from '@fortawesome/pro-solid-svg-icons/faPaintBrush';

export const COMMANDS = {
  format: 'format:html',
  showTheme: 'show:theme',
  togglePreview: 'toggle:preview',
};

export const MENUS = {
  actions: EX.EDITOR_MENUS.toolbar.primary,
};

export class HtmlExtension extends EX.Spec implements EX.ISpec {
  public readonly id: string = '.html';
  public readonly name: string = 'HTML';
  public readonly longName: string = 'HTML';

  public get supportedModes() {
    return [this.editor.supportedModes.read, this.editor.supportedModes.code];
  }

  public registerCommands = () => {
    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.format,
        label: 'Format HTML',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.showTheme,
        label: 'Show Theme',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.togglePreview,
        label: 'Toggle Preview',
      })
    );
  };

  public registerMenus = ({ mode }: EX.IModeChangedEvent) => {
    switch (mode) {
      case EX.EDITOR_MODES.read:
        this._registerTheme();
        break;
      case EX.EDITOR_MODES.code:
        this._registerFormat();
        this._registerTheme();
        this._registerPreview();
        break;
      default:
    }
  };

  private _registerFormat = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(EX.EDITOR_MENUS.toolbar.primary, {
        id: COMMANDS.format,
        commandId: COMMANDS.format,
        label: 'Format HTML',
        icon: faMagic,
      })
    );
  };

  private _registerTheme = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(EX.EDITOR_MENUS.toolbar.primary, {
        id: COMMANDS.showTheme,
        commandId: COMMANDS.showTheme,
        label: 'Theme',
        icon: faPaintBrush,
      })
    );
  };

  private _registerPreview = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(EX.EDITOR_MENUS.toolbar.primary, {
        id: COMMANDS.togglePreview,
        commandId: COMMANDS.togglePreview,
        label: 'Preview',
        icon: faEye,
      })
    );
  };
}
