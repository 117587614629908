import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';

import { combinePaths } from '@platform/utils/general';
import shortid from '@platform/utils/shortid';
import JsonPathCacheParentContainer from '@platform/components/JsonPathCacheParentContainer';
import Accordion from '@platform/components/Accordion';
import FormAutosizeInput from '@platform/components/FormAutosizeInput';

import BlockList from '../BlockList/editor';

const INVALID_BLOCK_TYPES = ['accordion'];

class AccordionBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = { accordionId: shortid() };
  }

  updatePanel = (panelIndex, t, p, v, options) => {
    const { onChangeData } = this.props;

    const path = combinePaths(['children', panelIndex], p);

    onChangeData(t, path, v, options);
  };

  removePanel = panelIndex => {
    const { onChangeData } = this.props;
    onChangeData('pull', ['children'], panelIndex);
  };

  handleAddPanel = () => {
    const { data = {}, onChangeData } = this.props;
    const { children = [] } = data;

    const panelCount = _.size(children);
    const newTab = {
      title: `Panel ${panelCount + 1}`,
      blocks: [],
    };

    onChangeData('push', ['children'], newTab);
  };

  renderPanels = () => {
    const { data = {}, childCacheIds, editor, cache, blockPath, updateCache } = this.props;
    const { children = [] } = data;

    if (!childCacheIds) {
      return null;
    }

    const panels = _.map(children, (child, index) => {
      return {
        key: index,
        title: () => (
          <div className="flex items-center">
            <Icon name="dropdown" />
            <div>
              <Icon name="pencil" />
              <FormAutosizeInput
                value={child.title}
                onChange={(e, target) => this.updatePanel(index, 'set', ['title'], target.value)}
                placeholder="Panel Name"
                transparent
                bold
              />
            </div>
            <div className="ml-a">
              <Icon
                name="trash"
                onClick={() => {
                  this.removePanel(index);
                }}
              />
            </div>
          </div>
        ),
        content: (
          <BlockList
            store={editor}
            blocks={child.blocks}
            parentPath={blockPath.concat(['data', 'children', index])}
            relativePath={editor.currentRelativePath}
            cache={cache}
            updateCache={updateCache}
            invalidBlockTypes={INVALID_BLOCK_TYPES}
            blockProps={{ isNested: true }}
            isNested
          />
        ),
      };
    });

    panels.push({
      key: _.size(panels) + 1,
      title: () => (
        <div className="flex items-center">
          <Icon name="plus" />
          <div>Add new panel</div>
        </div>
      ),
      content: <div />,
      onClick: this.handleAddPanel,
    });

    return panels;
  };

  render() {
    const { WrapperFactory } = this.props;
    const { accordionId } = this.state;

    const contentElem = [];
    contentElem.push(
      <Accordion
        id={`Accordion-Editor-${accordionId}`}
        key={`Accordion-Editor-${accordionId}`}
        fluid
        exclusive={false}
        panels={this.renderPanels()}
      />
    );

    return <WrapperFactory {...this.props} contentElem={contentElem} />;
  }
}

AccordionBlock.displayName = 'HubAccordionBlock';

export default JsonPathCacheParentContainer({
  cacheKeyProp: 'cacheKey',
  basePathProp: 'blockPath',
  path: ['data', 'children'],
  childrenProp: 'data.children',
})(observer(AccordionBlock));
