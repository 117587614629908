import _ from 'lodash';
import { observable, action, flow, reaction } from 'mobx';

import { URI } from '@core/uri';
import { create as createDisposable } from '@core/disposable';

import { computeLocationMatch } from '@platform/utils/router';
import { getAccessRole } from '@platform/utils/acl';
import { buildParams } from '@platform/utils/history';
import { computeBillingInfo } from '@platform/utils/billing';
import { stringifyQuery, parseQuery } from '@platform/utils/query';

export class RouterStore {
  history = null;
  rootStore = null;
  routes = [];
  globalQueryParams = {};

  _routeInterceptors = [];

  @observable
  isReady = false;

  @observable
  location = null;

  prevLocation = null;

  @observable.ref
  match = null;

  @observable.ref
  props = {};

  @observable.ref
  layout = {};

  @observable.ref
  loadDataError = null;

  constructor({ rootStore, routes, location, ...extraProps }) {
    this.rootStore = rootStore;
    this.routes = routes;

    Object.assign(this, extraProps);

    // Setup the initial location. Useful when rendered on the server.
    if (!_.isEmpty(location)) {
      this.handleLocationChange(location);
    }

    reaction(
      () => ({
        match: this.match,
        props:
          this.match &&
          this.match.computeProps({
            context: { props: this.props, stores: this.rootStore.stores },
          }),
      }),
      action(({ match, props }) => {
        if (!_.isEqual(props, this.props)) {
          this.props = props;
        }

        if (match) {
          this.layout = match.computeLayout({ context: { props: this.props } });
        }
      }),
      {
        name: 'props changed',
        fireImmediately: true,
      }
    );
  }

  /**
   *
   * @param {function} interceptor
   * {
   *   type: string ("push" | "replace" | "go")
   *   location
   * }
   *
   * should return location object or undefined to cancel route
   */
  registerRouteInterceptor(interceptor) {
    this._routeInterceptors.push(interceptor);

    return createDisposable(() => {
      const index = this._routeInterceptors.indexOf(interceptor);
      if (index !== -1) {
        this._routeInterceptors.splice(index, 1);
      }
    });
  }

  _triggerRouteInterceptors({ type, location }) {
    location = this.parseLocation(location);

    for (const interceptor of this._routeInterceptors) {
      location = interceptor({ type, location });

      if (!location) return;
    }

    return location;
  }

  get onWillUpdateRoute() {
    return this._willUpdateRouteEmitter.event;
  }

  toJSON() {
    return _.pick(this, 'match', 'props', 'loadDataError');
  }

  /**
   * When the location changes, we need to calculate the current route data, params, and query.
   * Then, we merge that into the normal browser location object that is passed in.
   */

  @action
  handleLocationChange = location => {
    const pathChanged = _.get(this.lastLocation, 'pathname') !== _.get(location, 'pathname');
    this.lastLocation = location;

    this.computeMatch(location);

    /**
     * Trigger the appropriate loadData functions for this route, which will populate the
     * service stores with the data we need for the page.
     *
     * Only needed on the client, for page transitions.
     * Only needed when the path has actually changed (not just query or hash).
     */
    if (this.rootStore.isClient && pathChanged) {
      this.loadMatchData();
    }

    this.location = {
      params: _.get(this.match, 'params', {}),
      query: _.get(this.match, 'query', {}),
      host: this.rootStore.isClient ? _.get(window, 'location.host', '') : location.host,

      // allow location to override params and query. useful for ssr and testing
      ...location,
    };

    if (this.rootStore.isClient && pathChanged) {
      this.updateAnalytics();

      if (typeof Electron !== 'undefined') {
        const onRouteDidChange = _.get(window, 'Electron.listeners.onRouteDidChange');
        if (onRouteDidChange) {
          onRouteDidChange({
            userId: _.get(this.rootStore, 'stores.userService.authorizedUser.id'),
          });
        }
      }
    }
  };

  /**
   * If the org has changed, and the user is a member, send the org data up to intercom.
   */
  updateAnalytics = () => {
    if (
      !this.rootStore ||
      !this.rootStore.stores ||
      !this.rootStore.stores.projectService ||
      !this.rootStore.stores.userService
    )
      return;

    const info = {};

    // Project info
    const project = _.get(this.rootStore.stores.projectService, 'current') || {};
    const project_name = _.get(window, 'intercomSettings.project_name');
    if (project.name !== project_name) {
      info.project_name = project.name;
    }

    // Company info
    const { id, name, web_url, access_level, subscription } =
      _.get(this.rootStore.stores.orgService, 'current') || {};
    const companyId = _.get(window, 'intercomSettings.company.id');
    if (access_level && id !== companyId) {
      const org_role = _.toLower(getAccessRole(access_level));

      info.company = _.pickBy({
        ...computeBillingInfo({ subscription }),
        id,
        name,
        web_url,
        company_id: id,
        role: org_role,
      });

      info[`org_${org_role}`] = name;
    }

    // Don't update if nothing has changed
    if (_.isEmpty(info)) return;

    this.rootStore.stores.userService.updateAnalytics(info, { targets: ['intercom'] });
  };

  @action
  computeMatch = location => {
    const loc = location || this.location;
    if (!loc) {
      console.error('Cannot computeMatch, no location set');
      return;
    }

    this.match = computeLocationMatch(this.routes, loc);
  };

  loadMatchData = flow(function*() {
    if (!this.match) {
      return;
    }

    const result = yield this.match.loadData();
    this.isReady = true;

    if (result.error) {
      this.loadDataError = result.error;
    } else {
      this.loadDataError = null;
    }

    return result;
  }).bind(this);

  /*
   * History methods
   */

  parseLocation = location => {
    if (typeof location === 'string') {
      location = URI.parse(location).location;
    }

    if (location.search && !location.query) {
      location.query = parseQuery(location.search);
    }

    return location;
  };

  /*
  History allows passing strings or objects to its respective functions,
  interceptors requires location to be an object so check for string and parse
  */
  @action
  push = location => {
    if (!this.history) return;

    location = this._triggerRouteInterceptors({ type: 'push', location });
    if (!location) return;

    this.prevLocation = this.location;
    return this.history.push(location);
  };

  @action
  replace = location => {
    if (!this.history) return;

    location = this._triggerRouteInterceptors({ type: 'replace', location });
    if (!location) return;

    this.prevLocation = this.location;
    return this.history.replace(location);
  };

  go = location => {
    if (!this.history) return;

    location = this._triggerRouteInterceptors({ type: 'go', location });
    if (!location) return;

    return this.history.go(location);
  };

  goBack = () => {
    if (!this.history) return;

    return this.history.goBack();
  };

  goForward = () => {
    if (!this.history) return;

    return this.history.goForward();
  };

  shouldPreventRedirect = () => {
    return false;
  };

  buildQueryParams = (params, options = {}) => {
    if (!this.rootStore.isClient) return;

    const location = this.location;
    const query = buildParams(location, params, {
      preserve: true,
      ...options,
    });

    return {
      ...location,
      query,
      search: _.isEmpty(query) ? '' : `?${stringifyQuery(query, { encode: false, ...options })}`,
    };
  };

  setQueryParams = (params, options = {}) => {
    const location = this.buildQueryParams(params, options) || {};

    if (_.get(this.location, 'search') === location.search) return;

    if (options.replace) {
      return this.replace(location);
    }

    return this.push(location);
  };

  buildHash = (hash, options = {}) => {
    const location = {
      ...this.location,
      hash,
    };

    return location;
  };

  setHash = (hash, options = {}) => {
    if (!this.rootStore.isClient) {
      return;
    }

    const location = this.buildHash(hash, options);

    if (options.replace) {
      return this.replace(location);
    }

    return this.push(location);
  };

  scrollToHash = hash => {
    setTimeout(() => {
      const elementId = _.replace(hash || this.location.hash, '#', '');
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }, 200);
  };
}
