import React from 'react';
import { observer } from 'mobx-react';

import CodeEditor from '@platform/components/CodeEditor';

const Spec = props => {
  const { editor, className, placeholder } = props;
  const spec = editor.spec;

  return (
    <CodeEditor
      className={className}
      name={`spec-${editor.id}-${editor.currentPath}`}
      value={editor.spec || ''}
      mode={editor.currentSpecMode}
      placeholder={placeholder}
      onChange={value => {
        if (editor && spec !== value) {
          editor.updateSpec(value);
        }
      }}
      highlightPath={editor.currentPath}
      wordWrap
      preserveScrollPosition
    />
  );
};

export default observer(Spec);
