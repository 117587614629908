import React from 'react';
import _ from 'lodash';

import SimpleMdEditor from '@platform/components/SimpleMdEditor';
import RefBuilder from '@platform/components/RefBuilder';

const TextBlock = props => {
  const { id, WrapperFactory, data = '', onChangeData, editor } = props;

  let contentElem = (
    <SimpleMdEditor
      id={id}
      className="no-border"
      placeholder="Write markdown here..."
      value={data}
      onChange={({ value }) => {
        onChangeData('set', [], value);
      }}
      context={{
        a({ href, title, target, children }) {
          const props = {};

          // pass internal paths through the store's buildViewPath function
          if (editor && (_.startsWith(href, '/') || _.startsWith(href, '#'))) {
            props.href = editor.buildViewPath(href);
          }

          return props;
        },
      }}
    />
  );

  if (_.isObject(data)) {
    contentElem = (
      <RefBuilder
        id={id}
        className="m-3"
        filePlaceholder="choose markdown file"
        fileFilter={{ markdown: [/\.md/] }}
        $ref={_.get(data, '$ref', '')}
        onComplete={value => {
          onChangeData('set', ['$ref'], value);
        }}
      />
    );
  }

  return <WrapperFactory {...props} headerProps={{ anchor: true }} contentElem={contentElem} />;
};

export default TextBlock;
