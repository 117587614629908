import React from 'react';
import { Form, Button, Popup, Segment } from 'semantic-ui-react';
import _ from 'lodash';

import { alert } from '@platform/utils/alert';

import DesktopSettingsLayout from './_layout';

class DesktopSettingsNetworking extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getConfig();
  }

  getConfig = () => {
    if (typeof window !== 'undefined') {
      this.config = _.get(window, 'Electron.config');
      return _.pick(this.config.get('networking'), 'proxy');
    }

    return {};
  };

  handleUpdate = (t, p, v) => {
    const state = this.state;
    _.set(state, p, v);
    this.setState(state);
  };

  handleSave = () => {
    for (const prop in this.state) {
      this.config.set(`networking.${prop}`, this.state[prop]);
    }

    this.handleReset();
    alert.success('Settings Updated. They will take effect after you restart Stoplight.');
  };

  handleReset = () => {
    this.setState(this.getConfig());
  };

  render() {
    if (!this.config) {
      return <Segment>This is only supported in the desktop app.</Segment>;
    }

    const state = this.state;
    const isDirty = !_.isEqual(state, this.getConfig());

    return (
      <DesktopSettingsLayout activeTab="networking">
        <Segment>
          <Form>
            <Popup
              position="bottom right"
              size="tiny"
              wide
              trigger={
                <Form.Field
                  label="Proxy Url"
                  name="proxy.url"
                  placeholder={'for example, `http://127.0.0.1:2819`'}
                  value={_.get(this.state, ['proxy', 'url'], '')}
                  control="input"
                  onChange={e => this.handleUpdate('set', 'proxy.url', e.target.value)}
                  size="tiny"
                />
              }
              content="If traffic from the Stoplight desktop app should be routed through a local proxy, specify the host here. Will default to HTTPS_PROXY or HTTP_PROXY environment variable."
            />

            <Popup
              position="bottom right"
              size="tiny"
              wide
              trigger={
                <Form.Field
                  label="Proxy Bypass"
                  name="proxy.bypass"
                  placeholder={'for example, `<local>,*.example.com`'}
                  value={_.get(this.state, ['proxy', 'bypass'], '')}
                  control="input"
                  onChange={e => this.handleUpdate('set', 'proxy.bypass', e.target.value)}
                  size="tiny"
                />
              }
              content="Only applicable when Proxy Url is used. If some traffic should not pass through the proxy, specify it here. Will default to NO_PROXY environment variable."
            />

            <Popup
              position="bottom right"
              size="tiny"
              wide
              trigger={
                <Form.Field
                  label="Proxy Basic Auth User"
                  name="proxy.user"
                  value={_.get(this.state, ['proxy', 'user'], '')}
                  control="input"
                  onChange={e => this.handleUpdate('set', 'proxy.user', e.target.value)}
                  size="tiny"
                />
              }
              content="If your proxy requires basic authentication, specify the username here. Will default to HTTPS_PROXY_USER or HTTP_PROXY_USER environment variable."
            />

            <Popup
              position="bottom right"
              size="tiny"
              wide
              trigger={
                <Form.Field
                  label="Proxy Basic Auth Pass"
                  name="proxy.pass"
                  type="password"
                  value={_.get(this.state, ['proxy', 'pass'], '')}
                  control="input"
                  onChange={e => this.handleUpdate('set', 'proxy.pass', e.target.value)}
                  size="tiny"
                />
              }
              content="If your proxy requires basic authentication, specify the password here. Will default to HTTPS_PROXY_PASS or HTTP_PROXY_PASS environment variable."
            />

            <div className="pt-1">
              <Button
                primary={isDirty}
                disabled={!isDirty}
                content="Save Settings"
                onClick={this.handleSave}
              />

              <Button
                icon="remove"
                content="Reset"
                floated="right"
                basic
                disabled={!isDirty}
                onClick={this.handleReset}
              />
            </div>
          </Form>
        </Segment>
      </DesktopSettingsLayout>
    );
  }
}

export default DesktopSettingsNetworking;
