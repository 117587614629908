import ceil = require('lodash/ceil');
import debounce = require('lodash/debounce');
import isEmpty = require('lodash/isEmpty');
import isEqual = require('lodash/isEqual');
import pickBy = require('lodash/pickBy');

import defaultAxios, { AxiosInstance } from 'axios';
import { action, flow, observable, toJS } from 'mobx';
import qs = require('qs');

import { IAxiosRequest, IQuery, IStore, IStoreOpts } from '../types';

export default class ExplorerStore implements IStore {
  public axios?: AxiosInstance;
  public request: IAxiosRequest = {};
  public groupId?: number;
  public url: string = '';

  @observable
  public isSearching = false;

  @observable
  public query: IQuery = {};

  @observable
  public debouncedQuery: IQuery = {};

  @observable
  public pageInfo: IStore['pageInfo'] = {
    perPage: 20,
    totalPages: 1,
  };

  @observable
  public nodes = [];

  @observable
  public changelogs = [];

  constructor(props: IStoreOpts) {
    const { groupId, apiHost, query = {}, url = '' } = props;

    this.groupId = groupId;
    this.url = url;

    if (apiHost) {
      this.axios = defaultAxios.create({
        baseURL: apiHost,
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'indicies' });
        },
        withCredentials: true,
      });
    }

    this.updateSearchQuery(query, undefined, { force: true });
  }

  @action
  public updateUrl = (url: string) => {
    this.url = url;
  };

  @action
  public updateSearchQuery = (value: any, filter?: string, opts?: { force: boolean }) => {
    this.isSearching = true;

    // no filter set query to value
    this.query = filter ? Object.assign({}, this.query, { [filter]: value }) : value;

    // clean falsy values
    this.query = pickBy(this.query);

    // call debounced search
    this.search(opts);
  };

  @action
  public search = debounce(
    flow(function*(this: ExplorerStore, opts = { force: false }) {
      const query = toJS(this.query);
      const debouncedQuery = toJS(this.debouncedQuery);
      const request = toJS(this.request);

      // if new query equals debounced query don't re-search
      if ((isEqual(debouncedQuery, query) && !opts.force) || !this.axios) {
        // if old request is finished running for this query update to false
        if (isEmpty(request)) {
          this.isSearching = false;
        }
        return;
      }

      // update debounced
      this.debouncedQuery = this.query;

      // cancel previous request if still running so they don't batch
      if (request.cancel) {
        request.cancel();
        this.request.cancel = undefined;
      }

      // fetch nodes
      try {
        const { search, type, page = '' } = query;

        this.request.promise = this.axios.request({
          method: 'get',
          url: this.url,
          proxy: false,
          params: {
            orgId: this.groupId,
            search,
            type,
            page: page || 1,
            per_page: this.pageInfo.perPage,
            sort: this.url.includes('changelog') ? 'createdAt' : 'updatedAt',
            direction: 'desc',
          },
          cancelToken: new defaultAxios.CancelToken(canceler => {
            this.request.cancel = canceler;
          }),
        });

        const res = yield this.request.promise;
        if (res.data) {
          let totalNodes = 0;
          // nodes.list endpoint
          if (res.data.search) {
            const { search = {} } = res.data;
            const { nodes = [], totalCount } = search;

            this.nodes = nodes || [];
            totalNodes = totalCount;
          }

          // nodes.changelog endpoint
          if (res.data.nodeVersionSnapshotChangelogs) {
            const { nodeVersionSnapshotChangelogs = {} } = res.data;
            const { nodes = [], totalCount } = nodeVersionSnapshotChangelogs;

            this.changelogs = nodes || [];
            totalNodes = totalCount;
          }

          // compute pagination info
          const totalPages = ceil(totalNodes / this.pageInfo.perPage);

          this.pageInfo = {
            ...this.pageInfo,
            totalPages: totalPages || 1,
          };
        }

        // successful request remove it
        this.request = {};
        this.isSearching = false;
      } catch (e) {
        // if the error was from a canceled request, we are running a new one and don't throw and keep isSearching
        if (defaultAxios.isCancel(e)) return;
        // else throw error and remove old request
        this.request = {};
        this.isSearching = false;
        console.error(e);
        throw e;
      }
    }),
    250,
    {
      trailing: true,
    }
  ) as IStore['search'];
}
