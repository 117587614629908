import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { colors, Button } from '@core/ui';

import { alert } from '@platform/utils/alert';
import { Link } from '@platform/utils/router';
import { accessLevels, accessRolesHuman, accessRoles } from '@platform/utils/acl';

import Modal from '../Modal';
import ErrorMessage from '../ErrorMessage';
import UserSearchDropdown from '../UserSearchDropdown';
import FormInput from '../FormInput';
import DropdownMenu from '../DropdownMenu';
import MagicInvite from '../MagicInvite';
import LearnMore from '../LearnMore';

const InviteMembersModal = props => {
  const {
    ui,
    updateUi,
    clearUi,
    noSearch,
    noResultsMessage,
    addMemberPlaceholder = 'Invite by username or email',

    error,
    clearError,
    handleSave,
    inverted,
    currentMembers,

    appStore,
    orgMemberService,
    orgService,
    entityType,
    memberAccessLevel,
    currentAccessLevel,
  } = props;

  const { invitedMember } = ui;

  const isLoading = entityType !== 'org' && orgMemberService.isFinding;
  const selectedAccessRole = ui.memberRole || memberAccessLevel;
  const currentOrg = _.get(orgService, 'current', {});
  const activeSubscription = _.get(currentOrg, 'subscription.status') === 'active';
  const isOwner = accessLevels.OWNER <= currentAccessLevel;

  let roleOptions = [];

  if (isOwner) {
    roleOptions.push({
      title: _.capitalize(accessRolesHuman[accessLevels.OWNER]),
      value: accessLevels.OWNER,
      description: (
        <div>
          Owner permissions + can <span className="underline">update/delete</span> this {entityType}
          's settings.
        </div>
      ),
    });
  }

  roleOptions = roleOptions.concat([
    {
      title: _.capitalize(accessRolesHuman[accessLevels.ADMIN]),
      value: accessLevels.ADMIN,
      description: (
        <span>
          Contributor permissions + can
          <span className="underline">
            {' '}
            manage members
            {entityType === 'org' ? ' and teams ' : ' '}
          </span>
          in this {entityType}.
        </span>
      ),
    },
    {
      title: _.capitalize(accessRolesHuman[memberAccessLevel]),
      value: memberAccessLevel,
      description: (
        <span>
          Contributors can view and <span className="underline">create projects</span> in this{' '}
          {entityType}.
        </span>
      ),
    },
  ]);

  if (entityType === 'org') {
    roleOptions = roleOptions.concat([
      {
        title: _.capitalize(accessRolesHuman[accessLevels.GUEST]),
        value: accessLevels.GUEST,
        description: <span>Readers can view projects in this {entityType}.</span>,
      },
    ]);
  }

  return (
    <Modal
      title={
        <div>
          <div>Invite Members</div>
          {activeSubscription && (
            <div className="text-sm font-normal">
              Fair use billing means you only pay for members that are actively contributing.{' '}
              <LearnMore feature="fair-use-billing" />
            </div>
          )}
        </div>
      }
      size="small"
      forceOpen={appStore.activeModal === 'invite'}
      triggerButton={{
        primary: true,
        basic: !inverted,
        content: 'Invite',
        inverted,
        onClick: () => {
          appStore.openModal('invite');
        },
      }}
      cancelButton={{
        content: 'Close',
        disabled: ui.loading,
        onClick: (e, elem) => {
          appStore.closeModal();
          clearUi();
        },
      }}
      confirmButton={{
        positive: true,
        icon: 'add user',
        content: `Invite ${_.capitalize(accessRolesHuman[selectedAccessRole])}`,
        loading: ui.loading,
        disabled: ui.loading || !invitedMember,
        onClick(e, elem) {
          if (!invitedMember) return;

          updateUi('set', 'loading', true);
          handleSave(invitedMember, selectedAccessRole)
            .then(() => {
              clearUi();
              alert.success(
                `Invited ${invitedMember} as a ${_.toLower(accessRolesHuman[selectedAccessRole])}`
              );
            })
            .catch(() => {
              updateUi('set', 'loading', false);
              alert.error('Invitation failed');
            });
        },
      }}
      contentFactory={() => {
        let errorCtaElem;
        if (isOwner && error && _.includes(error.message, 'plan')) {
          errorCtaElem = (
            <Link to={`/${currentOrg.path}/settings/billing`} className="mt-2">
              <Button color={colors.green}>Update Your Plan</Button>
            </Link>
          );
        }

        return (
          <div>
            <ErrorMessage className="mb-6" error={error} onDismiss={clearError}>
              {errorCtaElem}
            </ErrorMessage>

            <div className="flex">
              <DropdownMenu
                className="icon"
                value={selectedAccessRole}
                onChange={value => {
                  updateUi('set', 'memberRole', value);
                }}
                options={roleOptions}
                dropdownOptions={{
                  button: true,
                }}
              />

              {noSearch ? (
                <FormInput
                  className="flex-1 pl-3"
                  value={ui.invitedMember}
                  placeholder={addMemberPlaceholder}
                  onChange={(e, { value }) => updateUi('set', 'invitedMember', value)}
                  fluid
                />
              ) : (
                <UserSearchDropdown
                  id={`org:${currentOrg.id}`}
                  className="flex-1 pl-3"
                  excludedMembers={currentMembers}
                  handleChange={(e, { value }) => updateUi('set', 'invitedMember', value)}
                  placeholder={addMemberPlaceholder}
                  handleSearch={({ searchQuery } = {}) => {
                    // if we are adding memebers to an org we don't want don't want to search
                    if (entityType === 'org') return;

                    return orgMemberService.find(
                      { orgId: currentOrg.id },
                      { query: { query: searchQuery } }
                    );
                  }}
                  loading={isLoading}
                  allowAdditions={entityType === 'org'}
                  searchOnChange={entityType !== 'org'}
                  selectOnBlur={false}
                  value={ui.invitedMember}
                  noResultsMessage={noResultsMessage}
                  fluid
                />
              )}
            </div>

            {entityType === 'org' && (
              <MagicInvite className="mt-6" role={_.toLower(accessRoles[selectedAccessRole])} />
            )}
          </div>
        );
      }}
      onClose={() => {
        appStore.closeModal();
        updateUi('unset', 'invitedMember');
        updateUi('unset', 'loading');
        updateUi('set', 'options', []);
      }}
    />
  );
};

export default inject(({ appStore, orgMemberService, orgService }, { id }) => ({
  ...appStore.injectUi(`invite-members-${id}`),
  appStore,
  orgMemberService,
  orgService,
}))(observer(InviteMembersModal));
