import React from 'react';
import _ from 'lodash';
import FormTitleInput from '@platform/components/FormTitleInput';
import FormTextarea from '@platform/components/FormTextarea';
import ColorPicker from '@platform/components/ColorPicker';

import BlockAction from '../BlockAction/editor';
import { computeTheme } from '../../utils';

import { defaultTheme } from './index';

const BlockHero = props => {
  const { WrapperFactory, data = {}, onChangeData, theme = {}, onChangeTheme } = props;

  const { title = '', body = '', actions = [] } = data;

  const computedTheme = computeTheme({ theme, defaultTheme });
  const baseBgStyle = _.get(computedTheme, 'styles.base.background', {});
  const baseTextStyle = _.get(computedTheme, 'styles.base.text', {});

  const contentElem = [
    <FormTitleInput
      key="t"
      className="HubBlock-content-title"
      placeholder="Title"
      value={title}
      onChange={(e, { value }) => onChangeData('set', ['title'], value)}
      inputStyle={baseTextStyle}
    />,

    <FormTextarea
      key="b"
      className="HubBlock-content-body"
      placeholder="Optional body..."
      value={body}
      minRows={1}
      onChange={(e, { value }) => onChangeData('set', ['body'], value)}
      inputStyle={baseTextStyle}
    />,

    <div key="a" className="HubBlock-content-actions text-center">
      {_.map(actions, (action, index) => {
        const actionTheme = _.get(theme, ['styles', 'actions', index], {});

        return (
          <BlockAction
            key={index}
            action={action}
            index={index}
            onChangeData={(t, p, v) => onChangeData(t, ['actions', index].concat(p), v)}
            onChangeTheme={(t, p, v) => onChangeTheme(t, ['styles', 'actions', index].concat(p), v)}
            theme={actionTheme}
          />
        );
      })}

      <BlockAction
        key={actions.length}
        onChangeData={(t, p, v) => onChangeData(t, ['actions', actions.length].concat(p), v)}
      />
    </div>,
  ];

  const headerActions = [];
  headerActions.push(
    <ColorPicker
      inline
      color={baseTextStyle.color}
      label="Text Color"
      onChangeComplete={color => {
        if (color.hex === _.get(defaultTheme, ['styles', 'base', 'text', 'color'])) {
          onChangeTheme('unset', ['styles', 'base', 'text', 'color']);
        } else {
          onChangeTheme('set', ['styles', 'base', 'text', 'color'], color.hex);
        }
      }}
    />
  );
  headerActions.push(
    <ColorPicker
      inline
      color={baseBgStyle.backgroundColor}
      label="Background Color"
      onChangeComplete={color => {
        if (
          color.hex === _.get(defaultTheme, ['styles', 'base', 'background', 'backgroundColor'])
        ) {
          onChangeTheme('unset', ['styles', 'base', 'background', 'backgroundColor']);
        } else {
          onChangeTheme('set', ['styles', 'base', 'background', 'backgroundColor'], color.hex);
        }
      }}
    />
  );

  return (
    <WrapperFactory
      {...props}
      headerProps={{ anchor: true }}
      headerActions={headerActions}
      contentClassName="flex flex-col items-center"
      contentProps={{ style: baseBgStyle }}
      contentElem={contentElem}
      className="flex flex-col justify-center"
    />
  );
};

export default BlockHero;
