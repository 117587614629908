import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons/faSyncAlt';
import { faArrowAltFromBottom } from '@fortawesome/pro-solid-svg-icons/faArrowAltFromBottom';

import FormTextarea from '@platform/components/FormTextarea';
import LearnMore from '@platform/components/LearnMore';
import { colors, sizes, Button } from '@core/ui';
import { alert } from '@platform/utils/alert';

const ReleaseCreate = props => {
  const {
    ui,
    clearUi,
    updateUi,
    projectService,
    projectStore,
    releaseService,
    versionService,
  } = props;

  const { currentVersion } = projectStore.current || {};
  const isReleased = versionService.isReleased(currentVersion);

  let message;
  if (ui.hasOwnProperty('message')) {
    message = ui.message;
  } else if (isReleased) {
    message = isReleased.message;
  }

  const buttons = [];
  let descriptionText;

  if (isReleased) {
    descriptionText = `Rerelease v${currentVersion} if you made any updates since last release.`;

    buttons.push([
      <Button
        key="re"
        className="mr-2"
        icon={faSyncAlt}
        loading={releaseService.isUpdating}
        disabled={releaseService.isCreating}
        onClick={() => {
          if (releaseService.isUpdating) return;

          releaseService
            .update(currentVersion, { message }, { projectId: projectService.current.id })
            .then(res => {
              alert.success(`Release v${currentVersion} updated.`);
              clearUi();
            })
            .catch(err =>
              alert.error(
                releaseService.error.message || 'Something went wrong while removing this release.'
              )
            );
        }}
        color={colors.accent}
        size={sizes.sm}
      >
        Rerelease
      </Button>,

      <Button
        key="delete"
        icon={faTrash}
        loading={releaseService.isRemoving}
        disabled={releaseService.isCreating}
        onClick={() => {
          if (releaseService.isRemoving) return;

          const r = window.confirm(`Are you sure you want to remove release v${currentVersion}`);
          if (!r) return;

          releaseService
            .remove(currentVersion, { projectId: projectService.current.id })
            .then(res => {
              alert.warning(`Release ${currentVersion} removed.`);
              clearUi();
            })
            .catch(err =>
              alert.error(
                _.get(releaseService, 'error.message') ||
                  'Something went wrong while removing this release.'
              )
            );
        }}
        color={colors.negative}
        size={sizes.sm}
      />,
    ]);
  } else {
    descriptionText = `Create a release when v${currentVersion} is ready to be included in your published documentation.`;
    buttons.push(
      <Button
        key="create"
        icon={faArrowAltFromBottom}
        loading={releaseService.isCreating}
        disabled={releaseService.isCreating}
        onClick={() => {
          if (releaseService.isCreating) return;

          releaseService
            .create({ tag_name: currentVersion, message }, { projectId: projectService.current.id })
            .then(res => {
              alert.success(`Release v${currentVersion} created.`);
              clearUi();
            })
            .catch(err =>
              alert.error(
                _.get(releaseService, 'error.message') || 'Something went wrong while releasing.'
              )
            );
        }}
        color={colors.accent}
        size={sizes.sm}
      >
        Release
      </Button>
    );
  }

  return (
    <div className="px-3 py-4">
      <div>{descriptionText}</div>

      <FormTextarea
        className="-mx-3 py-4" // text area has internal padding, this is to offset
        placeholder="Optional release notes..."
        value={message}
        onChange={(e, { value }) => {
          updateUi('set', 'message', value);
        }}
        minRows={1}
      />

      <div className="flex items-center">
        {buttons}

        <LearnMore className="ml-a pl-2 text-sm" feature="releasing" />
      </div>
    </div>
  );
};

export default inject(stores => {
  const { appStore, releaseService, projectService, projectStore, versionService } = stores;

  return {
    releaseService,
    versionService,
    projectService,
    projectStore,
    ...appStore.injectUi('VersionPanel'),
  };
})(observer(ReleaseCreate));
