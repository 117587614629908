import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import SidebarFilter from '@platform/components/SidebarFilter';
import SidebarFilterSettings from '@platform/components/SidebarFilterSettings';

import PageContent from '../../components/PageContent';
import ProjectList from '../../components/ProjectList';

class TeamProjects extends React.Component {
  componentWillMount() {
    this.findItems({ page: 1 });
  }

  findItems = ({ search, page } = {}) => {
    const { teamProjectService, teamService } = this.props;

    // redirect current user to root projects if they are looking at their own page
    const query = {};

    let skipPagination = false;

    if (search) {
      query.search = search;
      skipPagination = true;
    } else {
      query.page = page || teamProjectService.getCurrentPage() + 1;
    }

    if (teamService.current) {
      teamProjectService.find(
        {
          teamId: teamService.current.id,
        },
        {
          query,
          skipPagination,
        }
      );
    }
  };

  render() {
    const { org, namespaceStore, teamService, teamProjectService, tagStoreInstance } = this.props;

    const team = teamService.current;
    const projects = teamProjectService.currentList;
    const tags = tagStoreInstance.tags;
    const activeTags = _.get(namespaceStore.current, 'activeFilters.tags', []);
    const activeFilterSettings = _.get(namespaceStore.current, 'activeFilterSettings.tags', {});

    return (
      <PageContent>
        <div className="flex-grow">
          <ProjectList
            namespace={{ ...team, namespace_type: 'team' }}
            projects={projects}
            loadMore={this.findItems}
            tags={tags}
            onSearch={searchQuery => {
              this.findItems({ search: searchQuery });
            }}
            displayFullTags={activeFilterSettings.displayFullTags}
          />
        </div>

        <div className="ml-6">
          <SidebarFilterSettings filterName="tags" />

          <SidebarFilter
            filterName="tags"
            displayName="Lifecycle Tags"
            items={_.filter(tags, t => t.type === 'lifecycle')}
            settingsPathname={`/${org.path}/settings/filters`}
            hideIfEmpty
          />
          <SidebarFilter
            filterName="tags"
            listName="Other Tags"
            items={_.filter(tags, t => !t.type)}
            settingsPathname={`/${org.path}/settings/filters`}
            hideIfEmpty
          />
        </div>
      </PageContent>
    );
  }
}

export default inject(
  ({ orgService, teamService, teamProjectService, tagStore, namespaceStore }, props) => {
    const org = orgService.current || {};
    const tagStoreInstance = tagStore.register(`TeamProjects-${org.id}`);

    return {
      org,
      teamService,
      teamProjectService,
      tagStoreInstance,
      namespaceStore,
    };
  }
)(observer(TeamProjects));

// export default inject(
//   'namespaceStore',
//   'orgService',
//   'teamService',
//   'teamProjectService',
//   'appStore',
//   'routerStore'
// )(observer(TeamProjects));
