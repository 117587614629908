import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { faCodeBranch } from '@fortawesome/pro-solid-svg-icons/faCodeBranch';
import { faArrowAltFromBottom as faArrowAltSolid } from '@fortawesome/pro-solid-svg-icons/faArrowAltFromBottom';
import { faArrowAltFromBottom as faArrowAltRegular } from '@fortawesome/pro-regular-svg-icons/faArrowAltFromBottom';

import PageLoading from '@platform/components/PageLoading';
import { versionSortDesc } from '@platform/utils/versions';

import { colors } from '@core/ui';

import { Tree } from '@core/ui-tree';

const VersionTreeComponent = props => {
  const { versionService, projectStore, projectService, routerStore, clearUi } = props;

  let versionList = versionService.currentList || [];

  if (versionService.isFinding && versionList.length === 0) {
    return (
      <PageLoading size="small" inverted={false}>
        Loading versions...
      </PageLoading>
    );
  }

  // array of paths
  const data = [];

  versionList = _.sortBy(versionList, versionSortDesc);
  versionList.forEach((version, index) => {
    const { name } = version;

    const folderPath = `Version ${name.split('.')[0]}`;

    if (!data.find(path => path.path === folderPath)) {
      data.push({ path: folderPath, expanded: true });
    }

    // create a path for the next highest release in each version
    data.push({ path: `${folderPath}/${name}` });
  });

  return (
    <Tree
      id="versionTree"
      className="w-100"
      data={data}
      canDrag={false}
      childOptsFactory={child => {
        const [folder, version] = _.split(child.path, '/');

        if (!version) {
          return {
            className: 'cursor-pointer',
            title: folder,
            iconProps: { icon: faCodeBranch, color: colors.warning },
          };
        }

        const isReleased = versionService.isReleased(version);
        const isActive = _.get(projectStore, 'current.currentVersion') === version;

        let meta;
        if (isReleased) {
          meta = [
            {
              title: 'Released',
              className: cn(
                'text-sm font-bold',
                isActive ? 'text-white' : 'text-muted text-darken-300'
              ),
            },
          ];
        }

        return {
          className: cn('cursor-pointer', { 'bg-active text-white': isActive }),
          title: version,
          canToggle: false,
          iconProps: {
            icon: isReleased ? faArrowAltSolid : faArrowAltRegular,
            color: isActive ? colors.white : colors.darken,
          },
          onClick: () => {
            let encodedVersion = encodeURIComponent(`version/${version}`);

            let versionPath = `/${projectService.current.path_with_namespace}/${encodedVersion}`;

            const currentFilePath = _.get(projectStore, 'current.currentFilePath');
            if (currentFilePath) {
              versionPath = `${versionPath}/${currentFilePath}`;
            }

            routerStore.push(`${versionPath}${routerStore.location.search || ''}`);
            clearUi();
          },
          meta,
        };
      }}
    />
  );
};

export const VersionTree = inject(stores => {
  const { appStore, versionService, projectService, projectStore, routerStore } = stores;

  return {
    versionService,
    projectService,
    projectStore,
    routerStore,
    ...appStore.injectUi('VersionPanel'),
  };
})(observer(VersionTreeComponent));
