import * as EX from '@core/extension';

import { faMagic } from '@fortawesome/pro-solid-svg-icons/faMagic';

export const COMMANDS = {
  format: 'format',
  close: 'close:theme',
};

export const MENUS = {
  actions: EX.EDITOR_MENUS.toolbar.primary,
};

export class CssExtension extends EX.Spec implements EX.ISpec {
  public readonly id: string = '.css';
  public readonly name: string = 'CSS';
  public readonly longName: string = 'CSS';

  public get supportedModes() {
    return [this.editor.supportedModes.code];
  }

  public registerCommands = () => {
    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.format,
        label: 'Format CSS',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.close,
        label: 'Close CSS',
      })
    );
  };

  public registerMenus = () => {
    this._registerFormat();
  };

  private _registerFormat = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(EX.EDITOR_MENUS.toolbar.primary, {
        id: COMMANDS.format,
        commandId: COMMANDS.format,
        label: 'Format CSS',
        icon: faMagic,
      })
    );
  };
}
