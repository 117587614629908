import React from 'react';
import cn from 'classnames';

import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

import { Icon } from '@core/ui';

import PageLoading from '../PageLoading';

import './main.css';

class SidebarPopout extends React.Component {
  render() {
    const {
      title,
      subtitle,
      actions,
      header,
      menu,
      loading,
      children,
      noContentMessage,
      inverted,
      handleClose,
      className, // className for children wrapper not the outer component
    } = this.props;

    let contentElem;
    if (loading) {
      contentElem = <PageLoading size="small" inverted={inverted} />;
    } else if (noContentMessage) {
      contentElem = (
        <div className="pin absolute flex items-center justify-center p-10 text-2xl font-extrabold text-muted">
          {noContentMessage}
        </div>
      );
    } else {
      contentElem = [];

      if (title || subtitle || actions || header) {
        contentElem.push(
          <div
            key="1"
            className="sticky pin-t z-10 bg-grey-lighter border-b border-grey-light pl-6 pr-6"
          >
            {title || subtitle ? (
              <div className="mt-6 mb-6">
                {title && (
                  <div className="text-2xl font-extrabold truncate" title={title}>
                    {title}
                  </div>
                )}
                {subtitle && (
                  <div className="text-muted text-sm truncate" title={subtitle}>
                    {subtitle}
                  </div>
                )}
              </div>
            ) : null}

            {actions && <div className="mt-6 mb-6">{actions}</div>}

            {header && <div className="mt-6 mb-6">{header}</div>}
          </div>
        );
      }

      if (menu) {
        contentElem.push(
          <div ckey="2" className="sticky">
            {menu}
          </div>
        );
      }

      if (children) {
        contentElem.push(
          <div key="3" className={cn(className, 'flex-grow h-full overflow-scroll')}>
            {children}
          </div>
        );
      }
    }

    return (
      <div className="relative z-30">
        <div
          className={cn(
            'SidebarPopout-inner flex flex-col h-full absolute pin-y pin-l',
            inverted ? 'bg-black' : 'bg-white'
          )}
        >
          {contentElem}

          {handleClose && (
            <div>
              <div
                className={cn(
                  'absolute pin-t pin-r z-10 cursor-pointer w-12 h-12 flex items-center justify-center text-xl',
                  inverted
                    ? 'text-lighten-200 hover:text-lighten hover:bg-lighten-50'
                    : 'text-darken-200 hover:text-darken hover:bg-darken-50'
                )}
                onClick={handleClose}
              >
                <Icon icon={faTimes} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SidebarPopout;
