import React from 'react';
import _ from 'lodash';
import Script from 'react-load-script';
import { Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';

import { Button, ButtonGroup, Icon, colors, sizes } from '@core/ui';

import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';
import { faList } from '@fortawesome/pro-solid-svg-icons/faList';
import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faMagic } from '@fortawesome/pro-solid-svg-icons/faMagic';
import { faListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faCommentAlt } from '@fortawesome/pro-solid-svg-icons/faCommentAlt';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';

import PageLoading from '@platform/components/PageLoading';
import ErrorMessage from '@platform/components/ErrorMessage';

import Intercom from '@platform/utils/intercom';
import { Link } from '@platform/utils/router';
import { getTrial } from '@platform/utils/billing';
import { getConfigVar } from '@platform/utils/config';

import BillingFAQ from '../BillingFAQ';
import BillingSection from '../BillingSection';
import BillingCardForm from '../BillingCardForm';
import BillingPlanSelector from '../BillingPlanSelector';
import BillingUsageBreakdown from '../BillingUsageBreakdown';

class BillingComponent extends React.Component {
  constructor(props) {
    super(props);

    this.isServer = typeof document === 'undefined';
    this.isElectron = !this.isServer && window.Electron;
  }

  componentWillMount() {
    const { namespaceId, billingService, billingStore, namespaceType } = this.props;

    billingStore.init(namespaceId, namespaceType);
    billingService.getUsage(namespaceId);
    billingService.getSubscription(namespaceId);
  }

  renderBillingCardForm() {
    const { billingStore } = this.props;

    if (this.isElectron) {
      const appHost = getConfigVar('SL_APP_HOST');

      return (
        <div className="flex items-center h-10 text-white pl-4 bg-negative">
          <Icon icon={faExclamationTriangle} className="mr-3" />

          <div className="flex-1">
            Please go to{' '}
            <Link className="text-white hover:text-white underline" href={appHost}>
              {appHost}
            </Link>{' '}
            to manage billing.
          </div>
        </div>
      );
    }

    const { isSubscribing, subscribeText, isValid, isDirty, interval } = billingStore;

    return (
      <div>
        <div className="flex items-center mb-4">
          {this.renderSubscriptionButton({
            disabled: isSubscribing || !isValid || !isDirty,
            loading: isSubscribing,
            text: subscribeText,
          })}

          <ButtonGroup>
            <Button
              color={interval === 'yearly' ? colors.primary : colors.grey}
              size={sizes.lg}
              onClick={() => {
                billingStore.updateInterval('yearly');
              }}
            >
              Yearly
            </Button>
            <Button
              color={interval === 'monthly' ? colors.primary : colors.grey}
              size={sizes.lg}
              onClick={() => {
                billingStore.updateInterval('monthly');
              }}
            >
              Monthly
            </Button>
          </ButtonGroup>

          <div className="text-muted ml-4 text-sm">save up to 20% with yearly billing</div>
        </div>

        {this.props.stripeLoaded && (
          <BillingCardForm className="flex-1" billingStore={billingStore} />
        )}
      </div>
    );
  }

  renderSubscriptionButton({ disabled, loading, text }) {
    const { stripe, billingStore } = this.props;
    if (!stripe) return null;

    return (
      <Button
        loading={loading}
        disabled={disabled}
        className="mr-3"
        icon={faBolt}
        color={disabled ? colors.grey : colors.accent}
        size={sizes.lg}
        onClick={() => {
          billingStore.subscribe(stripe);
        }}
      >
        {text}
      </Button>
    );
  }

  renderAccountDockButton() {
    if (!this.props.accountDockLoaded) {
      return;
    }

    const customer = _.get(this.props.billingService, 'subscription.billing.account_id');

    if (this.isServer || !customer || !window.AccountDock) {
      return null;
    }

    const accountDock = window.AccountDock.configure({
      key: getConfigVar('ACCOUNT_DOCK_KEY'),
      customer,
    });

    return (
      <div className="flex items-center mt-6">
        <Button
          icon={faList}
          color={colors.secondary}
          size={sizes.lg}
          onClick={() => {
            accountDock.open();
          }}
        >
          Billing History
        </Button>

        <div className="text-muted text-sm ml-4">*new invoices can take up to 24 hours to show</div>
      </div>
    );
  }

  render() {
    const { billingStore, namespaceType, namespaceId, billingService, namespace } = this.props;

    const {
      platformPlans,
      docsPlans,
      selectedPlatformPlan,
      selectedDocsPlan,
      isSubscribing,
      computedUsage = {},
    } = billingStore;

    let message = '';
    const billing = _.get(billingService, 'usage.billing') || {};
    if (billing.provider === 'manual') {
      message =
        'This organization is setup with a manual invoice. Please contact billing@stoplight.io to manage your subscription.';
    } else if (
      billing.namespace_id &&
      billing.namespace_type === 'org' &&
      billing.namespace_id !== namespaceId
    ) {
      message =
        'This organization is connected to the billing account of another organization. Please manage billing in your main organization. Contact billing@stoplight.io if you have any questions.';
    }

    if (message) {
      return (
        <div className="flex items-center py-2 text-white pl-4 bg-green">
          <Icon icon={faExclamationTriangle} className="mr-4" />
          <div className="flex-1">{message}</div>
        </div>
      );
    }

    return (
      <div>
        {getTrial(namespace.subscription) <= 0 && (
          <ErrorMessage
            className="mb-6"
            error="Your organization's trial has expired! Subscribe to a plan to use paid features."
          />
        )}

        <div className="opacity-85 px-4 text-3xl flex items-center">
          <div className="font-bold">Organization Billing</div>

          {Intercom.sdk() && (
            <Button
              icon={faCommentAlt}
              className="ml-6"
              size={sizes.sm}
              color={colors.secondary}
              onClick={() => {
                Intercom.show();
              }}
            >
              Need Help?
            </Button>
          )}
        </div>

        <div className="sticky z-30 t-1 bg-white pin-t p-4 mb-8 bg-white" style={{ top: -1 }}>
          {this.renderBillingCardForm()}

          <ErrorMessage
            className="mt-2"
            error={billingService.error}
            onDismiss={() => billingService.setError()}
          />
        </div>

        <div className="px-4">
          <div className="mb-10 relative">
            {(isSubscribing || billingService.isGettingSubscription) && (
              <div className="absolute pin bg-lighten-500 z-20 opacity-1" onClick={_.noop}>
                <Loader active />
              </div>
            )}

            <BillingSection className="mb-14" title="Choose a Platform Plan" icon={faMagic}>
              <BillingPlanSelector
                namespaceType={namespaceType}
                plans={platformPlans}
                selectedPlan={selectedPlatformPlan}
                onPlanClick={plan => (billingStore.selectedPlatformPlan = plan)}
              />

              {namespaceType !== 'org' && (
                <div className="border border-grey-light rounded mt-4">
                  <div className="py-3 px-4">
                    <span className="font-bold text-positive">
                      Setting up Stoplight for your company?
                    </span>{' '}
                    <Link className="text-black underline" to="/orgs/new">
                      Create an organization instead!
                    </Link>
                  </div>
                </div>
              )}
            </BillingSection>

            <BillingSection className="mb-14" title="Choose a Docs Plan" icon={faBook}>
              <BillingPlanSelector
                namespaceType={namespaceType}
                plans={docsPlans}
                selectedPlan={selectedDocsPlan}
                onPlanClick={plan => (billingStore.selectedDocsPlan = plan)}
              />
            </BillingSection>

            <BillingSection className="mb-14" title="Usage Breakdown" icon={faListOl}>
              <div className="p-1/2 relative">
                {billingService.isGettingUsage && (
                  <div className="absolute pin bg-lighten-500 z-20 opacity-1" onClick={_.noop}>
                    <Loader active />
                  </div>
                )}

                <BillingUsageBreakdown usage={computedUsage} />

                {this.renderAccountDockButton()}
              </div>
            </BillingSection>

            <BillingSection className="mb-14" title="FAQs" icon={faQuestionCircle}>
              <BillingFAQ namespaceType={namespaceType} />
            </BillingSection>
          </div>
        </div>
      </div>
    );
  }
}

const MobxInjectedBilling = inject((stores, props) => {
  const { billingService, orgService, userService, billingStore } = stores;

  return {
    billingService,
    orgService,
    userService,
    billingStore,
  };
})(observer(BillingComponent));

const StripeInjectedBilling = injectStripe(MobxInjectedBilling);

class BillingWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stripeLoaded: false,
      accountDockLoaded: false,
    };

    this.isServer = typeof document === 'undefined';
    this.isElectron = !this.isServer && window.Electron;
  }

  handleScriptError = (name, err) => {
    // TODO: log error to bugsnag
    this.setState({
      [`${name}Error`]: err,
    });
  };

  handleScriptLoad = name => {
    this.setState({
      [`${name}Loaded`]: true,
    });
  };

  render() {
    const { stripeLoaded, stripeError, accountDockLoaded, accountDockError } = this.state;

    const components = [];

    if (!this.isElectron && !stripeLoaded) {
      components.push(
        <Script
          key="stripe"
          url="https://js.stripe.com/v3"
          onError={err => this.handleScriptError('stripe', err)}
          onLoad={() => this.handleScriptLoad('stripe')}
        />
      );
    }

    if (!accountDockLoaded) {
      components.push(
        <Script
          key="accountdoc"
          url={
            getConfigVar('RELEASE_STAGE') === 'production'
              ? 'https://static.accountdock.com/app.min.js'
              : 'https://sandbox.accountdock.com/app.min.js'
          }
          onError={err => this.handleScriptError('accountDock', err)}
          onLoad={() => this.handleScriptLoad('accountDock')}
        />
      );
    }

    if (stripeError) {
      components.push(<div key="main"> {stripeError || accountDockError} </div>);
    } else if (stripeLoaded) {
      components.push(
        <StripeProvider key="main" apiKey={getConfigVar('STRIPE_PK')}>
          <Elements>
            <StripeInjectedBilling
              {...this.props}
              stripeLoaded={stripeLoaded}
              accountDockLoaded={accountDockLoaded}
            />
          </Elements>
        </StripeProvider>
      );
    } else if (this.isElectron) {
      components.push(
        <MobxInjectedBilling key="main" {...this.props} accountDockLoaded={accountDockLoaded} />
      );
    } else {
      components.push(
        <PageLoading key="loading" inverted={false}>
          Loading billing information...
        </PageLoading>
      );
    }

    return components;
  }
}

export default BillingWrapper;
