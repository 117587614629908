export const services = {
  user: require('./user'),
  billing: require('./billing'),
  comment: require('./comment'),
  commit: require('./commit'),
  docs: require('./docs'),
  discussion: require('./discussion'),
  event: require('./event'),
  impersonationToken: require('./impersonationToken'),
  namespace: require('./namespace'),
  org: require('./org'),
  orgAttribute: require('./orgAttribute'),
  orgInviteToken: require('./orgInviteToken'),
  orgMember: require('./orgMember'),
  project: require('./project'),
  projectBranch: require('./projectBranch'),
  projectMember: require('./projectMember'),
  projectTeam: require('./projectTeam'),
  protectedBranch: require('./protectedBranch'),
  session: require('./session'),
  team: require('./team'),
  teamMember: require('./teamMember'),
  teamProject: require('./teamProject'),
  trialUntil: require('./trialUntil'),
  release: require('./release'),
  version: require('./version'),
};

export const createService = (props = {}) => {
  const { rootStore, name, data = {}, options } = props;

  return services[name].create({
    data,
    env: {
      rootStore,
    },
    options,
  });
};
