import _ from 'lodash';
import { filter } from '../json/compose';

// dynamic rules based on the search query expression
const rules = expression => {
  return {
    scenario: {
      recursive({ currentPath = [] }) {
        return _.size(currentPath) <= 4;
      },
      matcher({ parentPath = [], key, value }) {
        return _.size(parentPath) === 1;
      },
      searchFactory({ currentPath, value }) {
        return {
          currentPath,
          value,
        };
      },
    },
  };
};

export const filterSpec = (spec, expression) => {
  return filter(
    { spec, rules: rules(expression) },
    {
      search: {
        query: expression,
      },
    }
  );
};
