import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Icon, Popup, Image, Button } from 'semantic-ui-react';

import { blockTypes, subpageOptions } from '../../utils/editor';

const BlockTypes = props => {
  const { updateCache, handleInsert, blockIndex, blockTypeBlacklist, disabled } = props;

  const content = [];

  _.forOwn(blockTypes, (blockType, blockTypeIndex) => {
    if (_.includes(blockTypeBlacklist, blockType.type)) return;

    const triggerElem = (
      <div
        key={blockTypeIndex}
        className={cn('HubBlockInsert-option flex items-center justify-center', {
          'is-disabled': disabled,
        })}
        onClick={
          disabled
            ? null
            : () => {
                handleInsert({ blockIndex, type: blockType.type, data: blockType.data });
                updateCache('unset', ['addingAtId']);
              }
        }
      >
        <div className="HubBlockInsert-option-icon">
          {blockType.src ? <Image src={blockType.src} size="tiny" centered /> : null}
          {blockType.icon ? <i className={`icon ${blockType.icon}`} /> : null}
        </div>
      </div>
    );

    if (disabled) {
      content.push(triggerElem);
    } else {
      content.push(
        <Popup
          key={blockTypeIndex}
          trigger={triggerElem}
          header={blockType.name}
          content={blockType.description}
          position={'bottom center'}
        />
      );
    }
  });

  return content;
};

const BlockListInsert = props => {
  const {
    blockIndex,
    blockPath,
    id,
    handleInsert,
    cache,
    updateCache,
    store,
    blockTypeBlacklist = [],
    isLast,
    isStandalone,
    isNested,
  } = props;

  let copiedBlock = _.get(store, 'copiedBlock.block');

  if (copiedBlock && _.includes(blockTypeBlacklist, copiedBlock.type)) {
    copiedBlock = null;
  }

  const addMode =
    !copiedBlock && (isStandalone || _.get(cache, ['addingAtId']) === id || (isLast && !isNested));

  let triggerElem;
  if (copiedBlock) {
    triggerElem = (
      <div className="HubBlockPaste flex items-center">
        <div
          className="HubBlockPaste-name is-active mr-6"
          onClick={() => {
            handleInsert({ blockIndex, ...copiedBlock });
            store.setCopiedBlock();
          }}
        >
          <Icon name="paste" /> Paste Block Here
        </div>
        <div
          className="HubBlockPaste-name flex-1"
          onClick={() => {
            store.cancelCopiedBlock();
          }}
        >
          Cancel
        </div>
      </div>
    );
  } else {
    triggerElem = (
      <Icon
        className={cn('HubBlockInsert-icon', { 'is-active': addMode })}
        name="plus circle"
        size="big"
        onClick={() => {
          if (addMode) {
            updateCache('unset', ['addingAtId']);
          } else {
            updateCache('set', ['addingAtId'], id);
          }
        }}
      />
    );
  }

  let titleElem;
  let afterElem;
  let subpageType = {};
  if (isStandalone && !isNested) {
    const { activeSubpage = {} } = store;
    const { inferredType } = activeSubpage;
    subpageType = activeSubpage.data ? subpageOptions[inferredType] || {} : {};

    titleElem = (
      <div className="HubBlockInsert-title">
        {subpageType.insertBlockText || 'Choose a Block to Start Adding Content'}
      </div>
    );
  }

  let contentElem;
  if (addMode) {
    contentElem = (
      <BlockTypes
        updateCache={updateCache}
        handleInsert={handleInsert}
        blockPath={blockPath}
        blockIndex={blockIndex}
        blockTypeBlacklist={blockTypeBlacklist}
        disabled={subpageType.noBlocks}
      />
    );
  }

  return (
    <div
      key={`${blockIndex}-insert`}
      className={cn(
        'HubBlockInsert',
        'flex-1 flex flex-col items-center justify-center flex-wrap',
        {
          'is-active': addMode,
          'not-active': !addMode,
          'is-pasting': copiedBlock,
          'is-first': blockIndex === 0,
          'is-last': isLast,
          'is-standalone': isStandalone,
          'is-nested': isNested,
          'is-root': !isNested,
        }
      )}
    >
      {titleElem}

      <div className="HubBlockInsert-options flex-1 flex items-center justify-center flex-wrap">
        {triggerElem}
        {contentElem}
      </div>

      {afterElem}
    </div>
  );
};

export default BlockListInsert;
