import { types } from 'mobx-state-tree';
import _ from 'lodash';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/orgs/:orgId/teams',
      singleBasePath: '/teams',
      perPage: 100,
    })
    .views(self => {
      return {
        // returns the team for the current browser location, from local records
        get current() {
          const { teamId } = _.get(env.rootStore, 'stores.routerStore.location.params', {});
          if (!teamId) {
            return;
          }

          return _.first(
            self
              .findLocal({
                query: {
                  $or: [{ id: teamId }, { path: teamId }],
                },
                limit: 1,
              })
              .get()
          );
        },

        // returns the teams for the current browser location, from local records
        get currentList() {
          const org = _.get(env.rootStore, 'stores.orgService.current');
          if (!org) {
            return;
          }

          return self
            .findLocal({
              query: {
                parent_id: org.id,
              },
            })
            .get();
        },
      };
    })
    .actions(self => {
      return {};
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('TeamService');

  return Service.create(data, env);
};
