import React from 'react';
import _ from 'lodash';
import { Popup, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import LearnMore from '../LearnMore';
import Terminal from '../Terminal';

const CollectionTerminal = props => {
  const { message, scenarioIds = [], fileSystemStore, variables, editor } = props;

  const writeFile = _.get(fileSystemStore, 'currentMapping.isActive');
  const writeFilePath = _.get(fileSystemStore, 'currentMapping.filePath');

  let terminalText = message;

  if (!terminalText) {
    if (writeFile && writeFilePath) {
      terminalText = `conduct "${writeFilePath}"`;
    } else {
      terminalText = `conduct "${editor.exportUrl}"`;
    }

    if (!_.isEmpty(scenarioIds)) {
      const paths = [];
      _.forEach(scenarioIds, id => {
        paths.push(`--path "${id.replace('/', '')}"`);
      });
      terminalText += ` ${paths.join(' ')}`;
    }

    _.forEach(variables, (v, k) => {
      if (v) {
        terminalText += ` --env "${k}=${v}"`;
      }
    });
  }

  return (
    <div className="ScenarioEditor-terminal" id="tutorial-scenario-terminal">
      <div className="SplitEditor-parsedTitle flex items-center">
        <div>Run From Terminal</div>
        <div className="SpecEditor-widgetGroupTip ml-2">
          <Popup
            trigger={<Icon name="question circle" />}
            size="small"
            position="top center"
            hoverable
            wide
          >
            <div>
              <div>
                Use Prism to run your scenarios outside of Stoplight, from the command line. Perfect
                for CI integration.
              </div>
              <div className="mt-3">If you don't have Prism installed already:</div>

              <div className="mt-3">
                <b>macOs/Linux</b> - from your command line:
              </div>

              <div>
                <code>
                  curl https://raw.githubusercontent.com/stoplightio/prism/2.x/install.sh | sh
                </code>
              </div>

              <div className="mt-3">
                <b>Windows:</b>
              </div>

              <div>
                Download from{' '}
                <a href="https://github.com/stoplightio/prism/releases" target="_blank">
                  here
                </a>
                .
              </div>

              <div className="mt-3">
                <LearnMore className="mt-2 d-b" feature="run-in-terminal" />
              </div>
            </div>
          </Popup>
        </div>
      </div>

      <Terminal
        className="mt-2"
        lines={[
          {
            type: 'command',
            name: message ? null : 'prism',
            text: terminalText,
          },
        ]}
        light
      />
    </div>
  );
};

export default inject((stores, { orgId, editorId }) => {
  const { fileSystemStore, collectionEditorStore } = stores;

  const editor = collectionEditorStore.getEditor({ id: editorId });

  return {
    fileSystemStore,
    editor,
    variables: editor.currentPopulatedEnvVariables,
    connectedSpecs: editor.connectedSpecs || [],
  };
})(observer(CollectionTerminal));
