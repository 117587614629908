// This was copied from react-ui-tree
import JSTree from 'js-tree';
const proto = JSTree.prototype;

proto.updateNodesPosition = function(collapsedNodes = {}) {
  let top = 1;
  let left = 1;
  let root = this.getIndex(1);
  let self = this;

  root.top = top++;
  root.left = left++;

  if (root.children && root.children.length) {
    walk(root.children, root, left);
  }

  function walk(children, parent, left, collapsed) {
    let height = 1;
    children.forEach(function(id) {
      let node = self.getIndex(id);
      if (collapsed) {
        node.top = null;
        node.left = null;
      } else {
        node.top = top++;
        node.left = left;
      }

      if (node.children && node.children.length) {
        height += walk(node.children, node, left + 1, collapsed || collapsedNodes[node.id]);
      } else {
        node.height = 1;
        height += 1;
      }
    });

    parent.height = height;

    if (collapsedNodes[parent.id]) {
      parent.height = 1;
    }

    return parent.height;
  }
};

proto.move = function(fromId, toId, placement, collapsedNodes = {}) {
  if (fromId === toId || toId === 1) return;

  let obj = this.remove(fromId);
  let index = null;

  if (placement === 'before') index = this.insertBefore(obj, toId);
  else if (placement === 'after') index = this.insertAfter(obj, toId);
  else if (placement === 'prepend') index = this.prepend(obj, toId);
  else if (placement === 'append') index = this.append(obj, toId);

  this.updateNodesPosition(collapsedNodes);
  return index;
};

proto.getNodeByTop = function(top) {
  let indexes = this.indexes;
  for (let id in indexes) {
    if (indexes.hasOwnProperty(id)) {
      if (indexes[id].top === top) return indexes[id];
    }
  }
};

export default JSTree;
