import * as React from 'react';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV';

import { Icon, Popup } from '@core/ui';

import { URI } from '@core/uri';

import { Link } from './Link';
import { isDescendant } from './utils';

const cn: any = require('classnames');

export class TreeRowContent extends React.Component<any> {
  public render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      draggedNode,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      didDrop,
      lowerSiblingCounts,
      listIndex,
      swapFrom = 0,
      swapLength = 0,
      swapDepth,
      // treeId, // Not needed, but preserved for other renderers
      // isOver, // Not needed, but preserved for other renderers
      // parentNode, // Needed for dndManager

      node,
      path,
      ...rowProps
    } = this.props;

    const { className, title, iconProps, onClick, meta, href, actions } = rowProps as any;

    const isDraggedNode = draggedNode && draggedNode.id === node.id;
    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    // Construct the scaffold representing the structure of the tree
    const scaffold: JSX.Element[] = [];

    if (lowerSiblingCounts && lowerSiblingCounts.length) {
      // @ts-ignore
      _.forEach(lowerSiblingCounts, (lowerSiblingCount: number, index: number) => {
        scaffold.push(<div key={`pre_${1 + index}`} style={{ width: scaffoldBlockPxWidth }} />);

        if (treeIndex !== listIndex && index === swapDepth) {
          // This row has been shifted, and is at the depth of
          // the line pointing to the new destination
          let highlightLineClass = '';

          if (listIndex === swapFrom + swapLength - 1) {
            // This block is on the bottom (target) line
            // This block points at the target block (where the row will go when released)
            highlightLineClass = 'highlightBottomLeftCorner';
          } else if (treeIndex === swapFrom) {
            // This block is on the top (source) line
            highlightLineClass = 'highlightTopLeftCorner';
          } else {
            // This block is between the bottom and top
            highlightLineClass = 'highlightLineVertical';
          }

          scaffold.push(
            <div
              key={`highlight_${1 + index}`}
              style={{
                width: scaffoldBlockPxWidth,
                left: scaffoldBlockPxWidth * Number(index),
              }}
              className={cn('absolute pin-t', highlightLineClass)}
            />
          );
        }
      });
    }

    let metaItems: any;
    if (meta && meta.length) {
      // @ts-ignore
      metaItems = meta.map((metaItem, index) => {
        if (metaItem.type === 'icon') {
          return <Icon {...metaItem} key={index} className={cn('mx-2', metaItem.className)} />;
        }

        return (
          <div key={index} className={cn('mx-2', metaItem.className)}>
            {metaItem.title}
          </div>
        );
      });
    }

    let actionMenu: any;
    if (actions && actions.length) {
      actionMenu = (
        <Popup
          posX="right"
          posY="center"
          padding={1}
          renderTrigger={(attributes: any) => {
            return (
              <div {...attributes} className="py-2 px-6">
                <Icon icon={faEllipsisV} />
              </div>
            );
          }}
          renderContent={(attributes: any) => {
            // @ts-ignore
            const actionItems = actions.map((actionItem, index) => {
              return (
                <div
                  key={index}
                  className={cn('mb-2', actionItem.className)}
                  onClick={actionItem.onClick}
                >
                  {actionItem.icon && <Icon icon={actionItem.icon} />}

                  {actionItem.title}
                </div>
              );
            });

            return (
              <div className="bg-white rounded shadow py-2 px-6" {...attributes}>
                {actionItems}
              </div>
            );
          }}
        />
      );
    }

    let caretIcon: any;
    if (toggleChildrenVisibility && node && node.children && node.children.length) {
      caretIcon = <Icon icon={node.expanded ? faCaretDown : faCaretRight} />;
    }

    const rowChildren = connectDragPreview(
      <div className="flex items-center h-full w-full">
        {scaffold}

        <div
          className="row-expander flex items-center justify-center"
          style={{ width: 14, marginRight: 5 }}
        >
          {caretIcon}
        </div>

        <div
          className={cn(
            'flex-1 truncate relative',
            isDraggedDescendant ? 'opacity-50' : 'opacity-100',
            {
              'row-landing-pad': isLandingPadActive,
              'row-cancel-pad': isLandingPadActive && !canDrop,
              'row-search-match': isSearchMatch,
              'row-search-focus': isSearchFocus,
            }
          )}
        >
          <div className="flex items-center justify-between relative">
            {iconProps && (
              <div
                className="flex items-center justify-center"
                style={{ width: 20, marginRight: 5 }}
              >
                <Icon {...iconProps} className={cn(iconProps.className)} />
              </div>
            )}

            <div className="flex-1">{title || node.title}</div>

            {metaItems && <div className="UITreeRow-meta flex justify-end">{metaItems}</div>}

            {actionMenu && (
              <div className="UITreeRow-actions flex justify-end relative">{actionMenu}</div>
            )}
          </div>
        </div>
      </div>
    );

    const nodeContent = (
      <div
        key={treeIndex}
        title={title || node.title || ''}
        className={cn('UITreeRow h-full w-full', className, {
          'is-dragging': isDraggedNode,
          'hide-meta': !!actionMenu,
          'is-active': node.isActive || (rowProps && rowProps.isActive),
        })}
        onClick={(e: any) => {
          if (onClick) {
            onClick(e, node);
          }

          if (toggleChildrenVisibility) {
            toggleChildrenVisibility({
              node,
              path,
              treeIndex,
            });
          }
        }}
      >
        {href ? (
          <Link className="h-full w-full" uri={URI.parse(href)}>
            {rowChildren}
          </Link>
        ) : (
          <div className="h-full w-full">{rowChildren}</div>
        )}
      </div>
    );

    return canDrag ? connectDragSource(nodeContent, { dropEffect: 'copy' }) : nodeContent;
  }
}
