import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import AccountResetPassword from '@platform/components/AccountResetPassword';

const ResetPasswordPage = props => {
  const { routerStore } = props;

  const token = _.get(routerStore, 'location.query.reset_password_token');

  return (
    <div className="px-4 h-full flex items-center justify-center" style={{ minHeight: 700 }}>
      <AccountResetPassword token={token} />
    </div>
  );
};

export default inject('routerStore')(observer(ResetPasswordPage));
