import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Button, Segment, Header, Message, Form, Icon } from 'semantic-ui-react';

import ErrorMessage from '@platform/components/ErrorMessage';
import EntityTagsDropdown from '@platform/components/EntityTagsDropdown';
import { alert } from '@platform/utils/alert';
import { getConfigVar } from '@platform/utils/config';
import { simpleSlugify } from '@platform/utils/general';

class ProjectSettings extends React.Component {
  componentWillMount() {
    const { tagStoreInstance, projectService } = this.props;
    const namespaceId = _.get(projectService, 'current.namespace.id');

    this.updateUiProject(this.props);

    if (namespaceId) {
      tagStoreInstance.find({ namespaceId });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.updateUiProject(nextProps);
  }

  updateUiProject({ ui, projectService, updateUi }) {
    const project = projectService.current;
    if (!ui.project && !_.isEmpty(project)) {
      updateUi('set', 'project', _.clone(project));
    }
  }

  renderPublic() {
    const { projectService, ui, updateUi } = this.props;

    const project = ui.project || {};
    const original = projectService.current;
    const namespaceId = _.get(original, 'namespace.id');

    if (project.visibility === 'public') {
      return (
        <div className="flex items-center">
          <div className="flex-1">
            <Header as="h4">Make this project private</Header>
            <div>
              This project is public, which means that <strong>anyone</strong> can{' '}
              <strong>read</strong> it.
            </div>
          </div>

          <div className="ml-4">
            <Button
              basic
              icon="hide"
              content="Make private"
              disabled={projectService.isSaving}
              onClick={() => {
                const r = window.confirm('Are you sure you want to make this project private?');
                if (!r) return;

                projectService
                  .update(project.id, { visibility: 'private' }, { query: { namespaceId } })
                  .then(updatedProject => {
                    updateUi('set', 'project', _.clone(updatedProject));
                    alert.success(`${project.name} is now private.`);
                  })
                  .catch(e => {
                    alert.error('There was an error updating the project.');
                    console.error('There was an error updating the project.', e);
                  });
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="flex items-center">
        <div className="flex-1">
          <Header as="h4">Make this project public.</Header>

          <div>
            If you make this project public, <strong>anyone</strong> will be able to{' '}
            <strong>read</strong> it. They will NOT be able to view members or make any changes.
          </div>
        </div>

        <div className="ml-4">
          <Button
            basic
            icon="unhide"
            content="Go public"
            disabled={projectService.isSaving}
            onClick={() => {
              const r = window.confirm(`Are you sure you want to make this project public?`);
              if (!r) return;

              projectService
                .update(project.id, { visibility: 'public' }, { query: { namespaceId } })
                .then(updatedProject => {
                  updateUi('set', 'project', _.clone(updatedProject));
                  alert.success(`${project.name} is now public.`);
                });
            }}
          />
        </div>
      </div>
    );
  }

  renderDangerZone() {
    const { ui = {}, projectService, routerStore } = this.props;

    const project = ui.project;
    const original = projectService.current;
    const namespaceId = _.get(original, 'namespace.id');
    const namespacePath = _.get(original, 'namespace.path');
    const namespaceKind = _.get(original, 'namespace.kind');

    let renderPublic;
    if (
      !getConfigVar('SL_NO_PROJECT_VISIBILITY') ||
      getConfigVar('SL_NO_PROJECT_VISIBILITY') == 'false'
    ) {
      renderPublic = this.renderPublic();
    }

    return (
      <Segment.Group>
        <Segment attached="top" inverted color="red">
          <Header as="h3">Danger Zone</Header>
        </Segment>
        <Segment attached="bottom">
          {renderPublic}

          <div className="flex mt-6 items-center">
            <div className="flex-1">
              <Header as="h4">Delete this project</Header>
              <div>Once you delete a project, there is no going back. Please be certain.</div>
            </div>
            <div className="ml-4">
              <Button
                basic
                color="red"
                icon="trash"
                content={`Delete this project`}
                onClick={() => {
                  projectService
                    .remove(project.id, { query: { namespaceId } })
                    .then(() => {
                      alert.success(`${project.name} has been destroyed.`);

                      routerStore.push({
                        pathname: `/${namespaceKind === 'user' ? '' : namespacePath}`,
                      });
                    })
                    .catch(e => {
                      alert.error('There was an error removing the project.');
                      console.error('There was an error removing the project.', e);
                    });
                }}
              />
            </div>
          </div>
        </Segment>
      </Segment.Group>
    );
  }

  render() {
    const { projectService, tagStoreInstance, ui, updateUi } = this.props;

    const project = ui.project || {};
    const original = projectService.current || {};
    const tags = tagStoreInstance.tags;
    const editedTags = ui.project.tag_list || original.tag_list.toJS();
    const namespaceId = _.get(original, 'namespace.id');
    const namespacePath = _.get(original, 'namespace.path');
    const namespaceKind = _.get(original, 'namespace.kind');
    const projectChanged = !_.isEqual(project, original);
    const canDeleteProject = projectService.canUser({ action: 'remove:project' });
    const canUpdateProject = projectService.canUser({ action: 'push:project' });

    let tagsDropdownPlaceholder;
    switch (namespaceKind) {
      case 'user':
        tagsDropdownPlaceholder = 'Define tags in your profile settings. Assign them here.';
        break;
      case 'group':
        tagsDropdownPlaceholder = 'Define tags in your org settings. Assign them here.';
        break;
      default:
        tagsDropdownPlaceholder = 'Define tags and assign them here.';
    }

    return (
      <div className="p-32 pin absolute overflow-y-auto">
        <Segment.Group>
          <Segment secondary attached="top">
            <Header as="h3">Settings</Header>
          </Segment>
          <Segment attached="bottom" loading={projectService.isSaving}>
            <Form error={projectService.error} warning={project.path !== original.path}>
              <ErrorMessage error={projectService.error} className="mb-4" />

              <div className="flex mb-3">
                <div className="flex-1">
                  <Form.Input
                    label="Project Name"
                    placeholder="Pick something short and memorable..."
                    value={project.name || ''}
                    readOnly={!canUpdateProject}
                    onChange={(e, { value }) => {
                      updateUi('set', 'project.name', value);
                    }}
                  />
                </div>

                <div className="ml-3">
                  <Form.Input label="Project ID" value={project.id} readOnly />
                </div>
              </div>

              <Form.Input
                label="Path"
                placeholder="Path"
                value={project.path || ''}
                readOnly={!canUpdateProject}
                onChange={(e, { value }) => updateUi('set', 'project.path', value)}
              />

              <Message warning size="tiny">
                <Icon name="exclamation circle" />
                Warning: The project URL will be updated to
                <strong>
                  {getConfigVar('SL_APP_HOST')}/{namespacePath}/{simpleSlugify(project.path)}
                </strong>
                . This will break any git remotes or external links that point to the current
                project URL.
              </Message>

              <Form.TextArea
                label="Project Description"
                placeholder="Purpose or description of this project..."
                value={project.description || ''}
                readOnly={!canUpdateProject}
                onChange={(e, { value }) => updateUi('set', 'project.description', value)}
              />

              <EntityTagsDropdown
                orgTags={_.map(tags, tag => {
                  return {
                    key: tag.ixd,
                    type: tag.type || 'other',
                    text: tag.name,
                    value: tag.name,
                    description: _.truncate(tag.description, { length: 100 }),
                  };
                })}
                projectTags={editedTags}
                isSaving={projectService.isSaving}
                label="Project Tags"
                labelStyle={{ color: 'black' }}
                placeholder={tagsDropdownPlaceholder}
                handleOnChange={updatedTags => {
                  if (_.size(updatedTags) < _.size(editedTags)) {
                    this.setState({ isRemovingTag: true });
                  }

                  updateUi('set', 'project.tag_list', updatedTags);
                  setTimeout(() => this.setState({ isRemovingTag: false }), 0);
                }}
              />

              {canUpdateProject ? (
                <div className="mt-6">
                  <Button
                    content="Save"
                    disabled={projectService.isSaving || !projectChanged}
                    onClick={e => {
                      e.preventDefault();

                      if (!projectChanged) {
                        return;
                      }

                      projectService
                        .update(
                          original.id,
                          {
                            ..._.pick(project, ['name', 'path', 'description', 'tag_list']),
                          },
                          {
                            query: {
                              namespaceId,
                            },
                          }
                        )
                        .then(updatedProject => {
                          updateUi('set', 'project', _.clone(updatedProject));

                          if (updatedProject && updatedProject.path !== original.path) {
                            window.location.replace(
                              `/${namespacePath}/${updatedProject.path}/settings`
                            );
                          }
                        });
                    }}
                  />

                  <Button
                    basic
                    content="Reset"
                    disabled={!projectChanged}
                    onClick={e => {
                      e.preventDefault();
                      updateUi('set', 'project', _.clone(original));
                    }}
                  />
                </div>
              ) : null}
            </Form>
          </Segment>
        </Segment.Group>

        {canDeleteProject ? this.renderDangerZone() : null}
      </div>
    );
  }
}

export default inject((stores, props) => {
  const { projectService, appStore, routerStore, tagStore } = stores;
  const project = projectService.current || {};
  const tagStoreInstance = tagStore.register(`Project-${project.id}`);

  return {
    projectService,
    routerStore,
    tagStoreInstance,
    ...appStore.injectUi(`${project.id}:ProjectSettings`),
  };
})(observer(ProjectSettings));
