import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Menu, Segment, Loader } from 'semantic-ui-react';
import { safeStringify } from '@platform/utils/json';

import PageLoading from '../PageLoading';
import ScenarioStats from '../ScenarioStats';
import HttpBodyViewer from '../HttpBodyViewer';
import EditorContentSection from '../EditorContentSection';
import ScenarioLogs from '../ScenarioLogs';

function Debug({ editor = {} }) {
  const { currentResult, currentRunning } = editor;

  const logs = _.get(currentResult, 'data.output.body.logs');
  if (currentRunning || _.isEmpty(logs)) {
    return null;
  }

  return (
    <EditorContentSection id="CollectionOutput:debug" title="Debug">
      <ScenarioLogs id={currentResult.id} logs={logs} />
    </EditorContentSection>
  );
}
const InjectedDebug = inject(({ collectionEditorStore }, { editorId }) => ({
  editor: collectionEditorStore.getEditor({ id: editorId }),
}))(observer(Debug));

const CollectionOutput = props => {
  const { id, ui, updateUi, className = '', editor } = props;
  const { currentResult, collectionRunning, currentCollectionStats } = editor;

  const { activeResultTab = 'body' } = ui;

  // BUILD THE TAB CONTENT

  let contentElem;
  if (collectionRunning) {
    contentElem = <PageLoading inverted={false} />;
  } else {
    switch (activeResultTab) {
      case 'body':
        contentElem = (
          <HttpBodyViewer
            name={`${id}-result`}
            contentType={_.get(currentResult, ['data', 'output', 'headers', 'Content-Type'], 'n/a')}
            value={
              _.get(currentResult, ['data', 'output'])
                ? safeStringify(_.get(currentResult, ['data', 'output', 'body'], ''))
                : 'Results will show here after you click the Run Collection button at the top of the editor.'
            }
            uiCache={ui.body}
            uiCacheScope={['body']}
            updateUiCache={updateUi}
            noTypeSelector
          />
        );
        break;
      default:
        contentElem = <div>Please select a tab.</div>;
        break;
    }
  }

  // BUILD THE TABS

  // BODY TAB

  const bodyName = <span>Raw Result</span>;

  const menuElem = (
    <Menu attached="top" tabular>
      <Menu.Item name="body" active>
        {bodyName}
      </Menu.Item>
    </Menu>
  );

  let outputElem;
  if (collectionRunning) {
    outputElem = (
      <div className="p-6 text-center">
        <Loader indeterminate active inline>
          Running
        </Loader>
      </div>
    );
  } else if (!_.isEmpty(currentResult)) {
    outputElem = (
      <div className={`CollectionOutput-result ${className}`}>
        {menuElem}
        <Segment attached="bottom">{contentElem}</Segment>
      </div>
    );
  } else {
    outputElem = (
      <h4 className="c-muted font-bold text-center">
        click the run collection button to view output
      </h4>
    );
  }

  return (
    <div className="CollectionOutput">
      <EditorContentSection>
        <ScenarioStats items={currentCollectionStats} />
      </EditorContentSection>

      <InjectedDebug editorId={editor.id} />

      <EditorContentSection id="CollectionOutput:result" title="Output" canToggle={false}>
        {outputElem}
      </EditorContentSection>
    </div>
  );
};

export default inject(({ appStore, collectionEditorStore }, { editorId }) => ({
  editor: collectionEditorStore.getEditor({ id: editorId }),
  ...appStore.injectUi(`${editorId}:CollectionOutput`),
}))(observer(CollectionOutput));
