import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';

import JSTree from '@platform/utils/jsTree';

import Node from './node';

const xDragTriggerThreshold = 20;
const xMinDragDistance = 7; // this value is chosen experimentally

class Tree extends React.Component {
  static propTypes = {
    tree: PropTypes.object.isRequired,
    renderNode: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.initState(props),
      collapsedNodes: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this._updated) {
      this.setState(this.initState(nextProps, this.state));
    } else {
      this._updated = false;
    }
  }

  initState = (props = {}, state = {}) => {
    const { currentPath = 'nodes' } = props;

    const tree = new JSTree(props.tree);
    tree.renderNode = props.renderNode;
    tree.updateNodesPosition(_.get(state, ['collapsedNodes', currentPath]));

    return {
      tree: tree,
      dragging: {
        id: null,
        left: null,
        top: null,
        width: null,
        height: null,
      },
    };
  };

  setDragging = ({ id, width, height, top, left } = {}) => {
    this.setState({
      dragging: {
        id,
        width,
        height,
        top,
        left,
      },
    });
  };

  getDraggingDom = () => {
    const { tree, dragging = {} } = this.state;
    const { id, top, left, width } = dragging;

    if (!id) return null;

    const index = tree.getIndex(id);

    // TODO: Find a better way of handling this addition
    const style = {
      top: top - dragging.height,
      left,
      width,
    };

    return (
      <div className="SortableTree-draggable" style={style}>
        <Node tree={tree} index={index} depth={1} isCollapsed={this.isCollapsed} isDragNode />
      </div>
    );
  };

  dragStart = (id, dom, e) => {
    const top = dom.offsetTop;
    const left = dom.offsetLeft;
    const dragging = {
      id,
      width: dom.offsetWidth,
      height: dom.offsetHeight,
      left,
      top: top + dom.offsetHeight,
    };

    this.dragging = dragging;

    this._startX = left;
    this._startY = top;
    this._offsetX = e.clientX;
    this._offsetY = e.clientY;
    this._start = true;

    window.addEventListener('mousemove', this.drag);
    window.addEventListener('mouseup', this.dragEnd);
  };

  drag = e => {
    if (
      this._start &&
      Math.abs(this._offsetX - e.clientX) < xMinDragDistance &&
      Math.abs(this._offsetY - e.clientY) < xMinDragDistance
    ) {
      e.preventDefault();
      return;
    }

    const { currentPath = 'nodes' } = this.props;
    const { tree, dragging, collapsedNodes = {} } = this.state;

    const nodes = collapsedNodes[currentPath];

    if (this._start) {
      this.setDragging(this.dragging);
      this._start = false;
      return;
    }

    let newIndex = null;
    let index = tree.getIndex(dragging.id);

    if (!index) return;

    const _startX = this._startX;
    const _startY = this._startY;
    const _offsetX = this._offsetX;
    const _offsetY = this._offsetY;

    // TODO: Find a better way of handling this addition
    const pos = {
      left: _startX + e.clientX - _offsetX,
      top: _startY + e.clientY - _offsetY + dragging.height,
    };
    dragging.left = pos.left;
    dragging.top = pos.top;

    const diffX = dragging.left - (index.left - 2) * xDragTriggerThreshold;
    const diffY = dragging.top - dragging.height / 2 - (index.top - 2) * dragging.height;

    if (diffX < 0) {
      // left
      if (index.parent && !index.next) {
        newIndex = tree.move(index.id, index.parent, 'after', nodes);
      }
    } else if (diffX > xDragTriggerThreshold) {
      // right
      if (index.prev) {
        const prevNode = tree.getIndex(index.prev).node;
        if (!this.isCollapsed(index.prev) && !prevNode.leaf) {
          newIndex = tree.move(index.id, index.prev, 'append', nodes);
        }
      }
    }

    if (newIndex) {
      index = newIndex;
      dragging.id = newIndex.id;
    }

    if (diffY < 0) {
      // up
      const above = tree.getNodeByTop(index.top - 1);
      newIndex = tree.move(index.id, above.id, 'before', nodes);
    } else if (diffY > dragging.height) {
      // down
      if (index.next) {
        const below = tree.getIndex(index.next);
        if (below.children && below.children.length && !this.isCollapsed(below.id)) {
          newIndex = tree.move(index.id, index.next, 'prepend', nodes);
        } else {
          newIndex = tree.move(index.id, index.next, 'after', nodes);
        }
      } else {
        const below = tree.getNodeByTop(index.top + index.height);
        if (below && below.parent !== index.id) {
          if (below.children && below.children.length && !this.isCollapsed(below.id)) {
            newIndex = tree.move(index.id, below.id, 'prepend', nodes);
          } else {
            newIndex = tree.move(index.id, below.id, 'after', nodes);
          }
        }
      }
    }

    if (newIndex) {
      dragging.id = newIndex.id;
    }

    this.setState({
      tree,
      dragging,
    });
  };

  dragEnd = () => {
    const { tree, dragging = {} } = this.state;

    const { node = {} } = tree.getIndex(dragging.id) || {};

    this.setDragging({});

    this.change(tree, node.parsedPath);
    window.removeEventListener('mousemove', this.drag);
    window.removeEventListener('mouseup', this.dragEnd);
  };

  isCollapsed = nodeId => {
    const { currentPath = 'nodes' } = this.props;
    const { collapsedNodes = {} } = this.state;

    return _.get(collapsedNodes, [currentPath, nodeId]);
  };

  toggleCollapse = (nodeId, { collapsed } = {}) => {
    const { currentPath = 'nodes' } = this.props;
    const { collapsedNodes = {}, tree } = this.state;
    const index = tree.getIndex(nodeId);
    const { node } = index;

    if (!node.children) return;

    const nodes = _.get(collapsedNodes, [currentPath, nodeId]);

    const val = typeof collapsed !== 'undefined' ? collapsed : !nodes;

    if (val) {
      _.set(collapsedNodes, [currentPath, nodeId], true);
    } else {
      _.unset(collapsedNodes, [currentPath, nodeId]);
    }

    tree.updateNodesPosition(collapsedNodes[currentPath]);

    this.setState({
      tree,
      collapsedNodes,
    });
  };

  change = (tree, fromPath) => {
    this._updated = true;

    if (this.props.onChange) {
      this.props.onChange({ tree: tree.obj, fromPath });
    }
  };

  render() {
    const { tree, dragging } = this.state;
    const { hideRoot } = this.props;
    const draggingDom = this.getDraggingDom();

    const draggingId = dragging && dragging.id;

    return (
      <div className={cn('SortableTree-inner', { 'is-dragging': draggingId })}>
        {draggingDom}

        <Node
          key={1}
          tree={tree}
          index={tree.getIndex(1)}
          draggingId={draggingId}
          toggleCollapse={this.toggleCollapse}
          isCollapsed={this.isCollapsed}
          onDragStart={this.dragStart}
          hidden={hideRoot}
          isRoot
        />
      </div>
    );
  }
}

export default Tree;
