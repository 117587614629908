import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { Icon } from 'semantic-ui-react';

import AccountCreate from '@platform/components/AccountCreate';
import PageLoading from '@platform/components/PageLoading';
import { isOnPrem, getConfigVar } from '@platform/utils/config';
import { submitForm } from '@platform/utils/hubspot';

class JoinPage extends React.Component {
  componentWillMount() {
    const { isLoggedIn, routerStore } = this.props;

    if (isLoggedIn && routerStore.location.pathname === '/join') {
      routerStore.replace('/');
    }
  }

  render() {
    const { loggingIn, isLoggedIn, routerStore } = this.props;
    const { r, nonce, sso } = _.get(routerStore, 'location.query') || {};

    const onPrem = isOnPrem();
    let contentElem;

    if (loggingIn) {
      contentElem = <PageLoading>Logging In...</PageLoading>;
    } else if (isLoggedIn) {
      contentElem = <PageLoading>Redirecting...</PageLoading>;
    } else {
      contentElem = (
        <AccountCreate
          github
          message={sso && `You will be redirected to ${sso} after logging in.`}
          onSuccess={user => {
            let redirect = r;

            if (sso === 'discourse') {
              redirect = `${redirect || '/'}?nonce=${nonce}&id=${user.id}&email=${user.email}`;
            }

            const formId = getConfigVar('HUBSPOT_JOIN_FORM_ID');
            if (formId) {
              submitForm({
                formId,
                fields: [
                  {
                    name: 'email',
                    value: user.email,
                  },
                ],
              });
            }

            if (redirect) {
              setTimeout(() => {
                window.location.href = redirect || '/';
              }, 1000);
            } else if (!onPrem) {
              routerStore.replace('/?modal=onboard');
            }
          }}
        />
      );
    }

    return (
      <div
        className="px-4 h-full flex flex-col items-center justify-center"
        style={{ minHeight: 700 }}
      >
        {!sso &&
          !isOnPrem() && (
            <div
              className="bg-lighten-200 text-white py-3 px-3 rounded mb-10"
              style={{
                backgroundColor: 'rgba(78, 55, 232, 0.8)',
                borderRadius: '4px',
                fontSize: '14px',
              }}
            >
              <Icon name="rocket" />
              Announcing{' '}
              <a
                style={{ color: 'white', fontWeight: 700 }}
                href="https://stoplight.io/studio"
                target="_blank"
              >
                Stoplight Studio
              </a>
              , Stoplight's next-generation API designer, with support for Git, OpenAPIv3, and more.
              Get started{' '}
              <a
                style={{ color: 'white', fontWeight: 700, textDecoration: 'underline' }}
                href="https://stoplight.io/studio"
                target="_blank"
              >
                here
              </a>
              .
            </div>
          )}
        {contentElem}
      </div>
    );
  }
}

export default inject(({ userService, routerStore }) => {
  return {
    routerStore,
    isLoggedIn: userService.isLoggedIn,
    loggingIn: userService.loggingIn,
    user: userService.authorizedUser,
  };
})(observer(JoinPage));
