import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Message } from 'semantic-ui-react';

import { faPaintBrush } from '@fortawesome/pro-solid-svg-icons/faPaintBrush';

import { Icon } from '@core/ui';

import FileCreateWidget from '../FileCreateWidget';
import DiscussionWidget from '../DiscussionWidget';

const ProjectLanding = props => {
  const { routerStore, projectStore, orgService } = props;

  const { isReadonly } = projectStore.current || {};

  const isNew = _.get(routerStore.location, 'query.new');

  return (
    <div className="w-100 p-32 overflow-auto">
      <Message
        info
        header="Beta support for OpenAPI v3 is now live!"
        content="Linting, code
            editing and documentation are currently supported. Visual design coming soon!"
        style={{ maxWidth: 750 }}
        className="mb-16 flex-1"
      />

      <div className="flex mb-16 bg-grey-lightest rounded-md shadow-sm " style={{ maxWidth: 750 }}>
        <div className="flex items-center px-10 text-3xl bg-darken-50 text-darken">
          <Icon icon={faPaintBrush} />
        </div>

        <div className="py-4 px-6 flex-1">
          <div className="text-2xl text-darken-500 mb-2">
            {isNew ? 'Welcome to your new project' : 'Project Designer'}
          </div>

          <div className="text-darken">
            {isReadonly && (
              <div className="font-bold mb-2">You only have read access to this project.</div>
            )}
            <div>Projects contain articles, API references, tests, and more.</div>

            {!isReadonly && <div>Create or import a file via the form below.</div>}

            <div>Browse via the sidebar to the left.</div>
          </div>
        </div>
      </div>

      {!isReadonly && <FileCreateWidget />}
      {orgService.current && <DiscussionWidget />}
    </div>
  );
};

export default inject('routerStore', 'projectStore', 'orgService')(observer(ProjectLanding));
