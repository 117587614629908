import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import SplitSidebar from '../SplitSidebar';

export { default as PageTitle } from './pageTitle';
export { default as SectionTitle } from './sectionTitle';

class EntityViewer extends React.Component {
  static propTypes = {
    orgId: PropTypes.string.isRequired,
    entity: PropTypes.object.isRequired,
    editorStore: PropTypes.object.isRequired,
    contentRouterData: PropTypes.object.isRequired,
    className: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  componentWillMount() {
    this.initEditor(this.props, {
      reload: true,
    });
  }

  componentWillUnmount() {
    if (this.props.fileSystemStore) {
      this.props.fileSystemStore.setCurrentId(null);
    }
  }

  componentWillReceiveProps(nextProps) {
    const entityId = _.get(this.props, 'entity.id');
    const nextEntityId = _.get(nextProps, 'entity.id');

    if (entityId !== nextEntityId) {
      this.initEditor(nextProps);
    }
  }

  getEditor = () => {
    const { editorStore, entity = {} } = this.props;

    return editorStore.getEditor({ id: entity.id }) || {};
  };

  initEditor = (props = {}, { reload, force } = {}) => {
    const editor = props.editorStore.initEditor(
      {
        orgId: _.get(props, 'orgId'),
        entity: props.entity,
        entityType: props.entityData.type,
        contentRouterData: props.contentRouterData,
        error: props.error,
        readOnly: true,
      },
      {
        force,
      }
    );
  };

  render = () => {
    const { orgId, entity = {}, className, embedded } = this.props;

    const editor = this.getEditor();

    const {
      currentContent = {},
      sidebarSections,
      id,
      currentPath,
      dereferencedParsed,
      currentParsed,
      currentDereferencedParsed,
      propsData,
      connectedSpecs,
    } = editor;

    const { component: Component, header: Header, componentProps } = currentContent;

    return (
      <div
        className={cn('EntityViewer flex flex-col', className, {
          'EntityViewer-embedded': embedded,
        })}
      >
        {Header ? (
          <div className="EntityViewer-header">
            <Header editor={editor} />
          </div>
        ) : null}

        <div className="EntityViewer-body flex">
          {sidebarSections ? (
            <div className="EntityViewer-sidebar">
              <SplitSidebar sections={sidebarSections} />
            </div>
          ) : null}

          {Component && (
            <div className="EntityViewer-content flex-1">
              <Component
                readOnly
                preview
                id={`${id}-${editor.currentPath}`}
                editorId={id}
                orgId={orgId}
                entityId={entity.id}
                pathKey={_.last(currentPath)}
                currentPath={currentPath}
                parsed={dereferencedParsed}
                value={currentParsed}
                dereffedValue={currentDereferencedParsed}
                propsData={propsData}
                connectedSpecs={connectedSpecs}
                {...componentProps}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default observer(EntityViewer);
