import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import FormInputLabel from '../FormInputLabel';

class FormDropdown extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    tip: PropTypes.string,
    size: PropTypes.string,
    loc: PropTypes.string,
    required: PropTypes.bool,
    clearSearchOnSelect: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
    };
  }

  clearSearchQuery = () => {
    this.setState({ searchQuery: '' });
  };

  handleSearchChange = (event, data) => {
    this.setState({ searchQuery: data.searchQuery });

    const { onSearchChange } = this.props;

    if (onSearchChange) {
      onSearchChange(event, data);
    }
  };

  handleChange = (event, data) => {
    const { onChange, clearSearchOnSelect } = this.props;

    if (onChange) {
      onChange(event, data);
    }

    if (clearSearchOnSelect) {
      this.clearSearchQuery();
    }
  };

  render() {
    const {
      className = '',
      label,
      labelStyle,
      tip,
      required,
      size,
      loc,
      readOnly,
      multiple,

      // need clearSearchOnSelect so its not included in dropdownProps
      clearSearchOnSelect,

      ...dropdownProps
    } = this.props;

    const { searchQuery } = this.state;

    let value = this.props.value;

    // semantic freaks out if multiple is true and we don't pass an array
    if (!value && multiple) {
      value = [];
    }

    return (
      <div className={`FormDropdown ${className}`}>
        {label ? (
          <FormInputLabel label={label} style={labelStyle} tip={tip} required={required} />
        ) : null}
        <Dropdown
          {...dropdownProps}
          className={cn('FormDropdown-inner', {
            [`is-${size}`]: size,
            [`is-${loc}`]: loc,
            ['readOnly']: readOnly,
          })}
          value={value}
          multiple={multiple}
          searchQuery={searchQuery}
          onSearchChange={this.handleSearchChange}
          onChange={this.handleChange}
          onBlur={this.clearSearchQuery}
        />
      </div>
    );
  }
}

export default FormDropdown;
