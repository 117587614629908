import axios from 'axios';
import _ from 'lodash';

import { getConfigVar } from '../config';
import { createURL } from '../url';

export const upload = ({ file, name, sessionCookie }) => {
  let signedUrl = '';

  return axios
    .request({
      method: 'get',
      url: `${getConfigVar('SL_API_HOST')}/aws/sign`,
      params: {
        filename: name,
        filetype: file.type,
      },
      headers: {
        'Session-Cookie': sessionCookie,
      },
      withCredentials: true,
    })
    .then(res => {
      signedUrl = _.get(res, 'data.signedUrl');

      return axios.request({
        method: 'put',
        url: signedUrl,
        headers: {
          'Content-Type': file.type,
        },
        data: file,
      });
    })
    .then(() => {
      const url = createURL(signedUrl);
      return Promise.resolve(`//${url.host}${url.pathname}`);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};
