import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import PublishList from '../PublishList';
import PublishDomain from '../PublishDomain';
import PublishViewer from '../PublishViewer';

class ProjectPublish extends React.Component {
  render() {
    const { routerStore, projectStore, headerProps, namespaceService, projectService } = this.props;

    const projectId = _.get(projectStore, 'current.id');
    const domain = _.toLower(_.get(routerStore, 'location.query.domain'));
    const canPublish = projectService.canUser({ action: 'publish:project' });

    return (
      <div className="flex h-full">
        <PublishList
          namespace={namespaceService.current}
          headerProps={headerProps}
          canPublish={canPublish}
          projectId={projectId}
          domain={domain}
        />

        {domain ? (
          <PublishViewer
            namespace={namespaceService.current}
            headerProps={headerProps}
            canPublish={canPublish}
            projectId={projectId}
            domain={domain}
          />
        ) : (
          <PublishDomain
            namespace={namespaceService.current}
            headerProps={headerProps}
            canPublish={canPublish}
            projectId={projectId}
            domain={domain}
          />
        )}
      </div>
    );
  }
}

export default inject('routerStore', 'projectStore', 'namespaceService', 'projectService')(
  observer(ProjectPublish)
);
