import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject } from 'mobx-react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

import PageLoading from '@platform/components/PageLoading';
import { PanelSections } from '@platform/components/PanelSections';
import { sizes, themes, Button, ListScroller } from '@core/ui';

import GraphQL from '@platform/containers/GraphQL';

class PublishList extends React.Component {
  renderAddButton = content => {
    return (
      <Button key="button" size={sizes.sm} theme={themes.dark} onClick={() => this.setDomain()}>
        {content || 'Publish to a New Domain'}
      </Button>
    );
  };

  render() {
    const { domain, docs = [], headerProps = {}, isLoading, routerStore } = this.props;

    const domainIndex = _.findIndex(docs, { domain });

    const actions = [];
    if (docs.length) {
      actions.push(this.renderAddButton('New'));
    }

    const sections = [
      {
        title: 'Published Domains',
        collapsible: false,
        actions: [
          {
            icon: faPlus,
            onClick: () => {
              routerStore.setQueryParams({
                domain: null,
              });
            },
          },
        ],
        renderChildren: () => {
          if (isLoading) {
            return <PageLoading size="small" />;
          }

          return (
            <ListScroller
              rowCount={docs.length}
              rowHeight={37}
              scrollToIndex={domainIndex}
              noRowsRenderer={() => {
                return (
                  <div className="text-muted-light py-6 text-center">Nothing to show here</div>
                );
              }}
              rowRenderer={({ key, index, style }) => {
                const doc = docs[index];
                const isActive = domain === doc.domain;

                return (
                  <div
                    key={key}
                    style={style}
                    className={cn(
                      'flex items-center text-white text-sm pl-3 pr-1 hover:bg-lighten-50 opacity-85 border-r-4 border-transparent',
                      {
                        'opacity-1': isActive,
                        'bg-lighten-50': isActive,
                        'border-blue': isActive,
                        'cursor-pointer': !isActive,
                      }
                    )}
                    onClick={() => {
                      routerStore.setQueryParams({
                        domain: doc.domain === domain ? null : doc.domain,
                      });
                    }}
                  >
                    <div className="flex-1 truncate">
                      <div className="truncate">{doc.domain}</div>
                    </div>
                  </div>
                );
              }}
            />
          );
        },
      },
    ];

    return (
      <div className="w-80 h-full z-20">
        <PanelSections header={headerProps} actions={actions} sections={sections} />
      </div>
    );
  }
}

const InjectedPublishList = inject('routerStore')(PublishList);

export default props => {
  const { projectId } = props;

  return (
    <GraphQL
      id="PublishList"
      service="docsService"
      methods={{
        list: {
          variables: {
            projectId,
            orderBy: { field: 'domain', direction: 'ASC' },
          },
        },
      }}
      renderProp={({ list }) => {
        return (
          <InjectedPublishList
            {...props}
            docs={list.data.docs}
            isLoading={list.loading}
            error={list.error}
          />
        );
      }}
    />
  );
};
