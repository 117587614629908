import _ from 'lodash';

import { computeTreeData } from '../entities';
import { safeParse } from '../json';
import { keyArrangeDeep, keyArrangeSortByArray } from '../general';

const treeMapping = {
  swagger: {},
  info: {},
  tags: {},
  host: {},
  basePath: {},
  schemes: {},
  consumes: {},
  produces: {},
  securityDefinitions: {
    icon: 'lock',
    children: {
      type: 'items',
    },
  },
  paths: {
    children: {
      type: 'folders',
    },
  },
  definitions: {
    children: {
      type: 'items',
    },
  },
  parameters: {
    name: () => 'parameters',
    children: {
      type: 'items',
    },
  },
  responses: {
    name: () => 'responses',
    children: {
      type: 'items',
    },
  },
};

export const propOrder = Object.keys(treeMapping);

const _computeReadOnlySidebarTree = (data, props) => {
  const { currentPath = [], location = {}, editor } = props;

  const folderProps = {
    data,
    currentPath,
    location,
    editor,
    pathRouting: true,
    hideItemActions: true,
    noDefaultActions: true,
  };

  const infoTree = [
    {
      name: 'Info',
      isActive: _.isEmpty(currentPath),
      href: {
        pathname: `/${_.get(editor, 'entity.namespace.full_path')}/specs/${_.get(
          editor,
          'entity.path'
        )}`,
        search: location.search,
      },
      noToggle: true,
    },
  ];

  const sortedPaths = keyArrangeDeep(data.paths, null, 1);

  const paths = {};
  for (const path in sortedPaths) {
    if (!sortedPaths[path]) continue;

    paths[path] = _.omit(sortedPaths[path], 'parameters');
  }

  return [
    {
      name: 'Overview',
      treeData: infoTree,
    },
    {
      name: 'Paths',
      treeData: computeTreeData(paths, {
        ...folderProps,
        treePath: ['paths'],
      }),
    },
  ];
};

const _computeSidebarTree = (data, props) => {
  const {
    currentPath = [],
    location = {},
    editor,
    hideSectionActions,
    hideItemActions,
    meta,
  } = props;

  const folderProps = {
    data,
    currentPath,
    location,
    editor,
    hideItemActions,
    meta,
  };

  const sorter = keyArrangeSortByArray(propOrder);
  const sortedData = {};
  for (const prop of propOrder) {
    sortedData[prop] = data[prop] ? keyArrangeDeep(data[prop], sorter, 2) : undefined;
  }
  const unsortedProps = _.difference(_.keys(data), propOrder);
  for (const prop of unsortedProps) {
    sortedData[prop] = data[prop];
  }

  const basics = _.omit(
    sortedData,
    'securityDefinitions',
    'paths',
    'definitions',
    'parameters',
    'responses',
    'swagger',
    'info',
    'host',
    'basePath',
    'schemes',
    'consumes',
    'produces',
    'security'
  );
  const basicsTree = [
    {
      name: 'home',
      isActive: _.isEmpty(currentPath),
      href: {
        pathname: location.pathname,
        query: {
          ...(location.query || {}),
          edit: '/',
        },
      },
      noToggle: true,
      icon: 'home',
    },
    ...computeTreeData(basics, {
      ...folderProps,
      treePath: [],
    }),
  ];

  const securityTree = computeTreeData(sortedData.securityDefinitions, {
    ...folderProps,
    treePath: ['securityDefinitions'],
  });

  const pathsTree = computeTreeData(sortedData.paths, {
    ...folderProps,
    treePath: ['paths'],
  });

  const modelsTree = computeTreeData(sortedData.definitions, {
    ...folderProps,
    treePath: ['definitions'],
  });

  const paramsTree = computeTreeData(_.pick(sortedData, 'parameters', 'responses'), {
    ...folderProps,
    treePath: [],
  });

  return [
    {
      treeData: basicsTree,
    },
    {
      name: 'Security',
      treeData: securityTree,
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add Security Scheme',
                content: 'Security schemes that you can use globally and in operations.',
              },
              onClick() {
                editor.addSecurityDefinition();
              },
            },
          ]
        : [],
    },
    {
      name: 'Paths',
      treeData: pathsTree,
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add Operation',
                content: 'Unique HTTP method + paths in your API. For example, "GET /users".',
              },
              onClick() {
                editor.addPath({ path: '/newPath', methods: ['get'] });
              },
            },
          ]
        : [],
    },
    {
      name: 'Models',
      treeData: modelsTree,
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add Model',
                content:
                  'Models help to reduce duplication. Reference them from other areas in your spec.',
              },
              onClick() {
                editor.addModel();
              },
            },
          ]
        : [],
    },
    {
      name: 'Shared',
      treeData: paramsTree,
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add Shared Parameter',
                content:
                  'Common path, header, and query parameters that you can use in your spec. Reduces duplication.',
              },
              onClick() {
                editor.addSharedParameter();
              },
            },
            {
              icon: 'plus',
              tip: {
                header: 'Add Shared Response',
                content: 'Common responses that you can use in your spec. Reduces duplication.',
              },
              onClick() {
                editor.addSharedResponse();
              },
            },
          ]
        : [],
    },
  ];
};

export const computeSidebarTree = (specData, props = {}) => {
  const { readOnly } = props;

  const data = safeParse(specData || {});

  if (readOnly) {
    return _computeReadOnlySidebarTree(data, props);
  }

  return _computeSidebarTree(data, props);
};
