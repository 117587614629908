import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

import HubTheme from '@platform/components/HubTheme';
import SplitPane from '@platform/components/SplitPane';
import PageLoading from '@platform/components/PageLoading';
import FeatureBadge from '@platform/components/FeatureBadge';

import { colors, sizes, Button, Layout } from '@core/ui';

import { alert } from '@platform/utils/alert';
import { hasSubscriptionFeature } from '@platform/utils/billing';

import EditorContainer from '../EditorContainer';
import EntityEditorCode from '../EntityEditor/spec';

class ThemeWrapper extends React.Component {
  componentWillMount() {
    this.init();
  }

  componentWillUnmount() {
    const { cssEditorStore, routerStore } = this.props;

    routerStore.setQueryParams({ theme: null }, { preserve: true });
    _.invoke(cssEditorStore.activeEditor, 'deactivate');
  }

  componentWillUpdate(nextProps, nextState) {
    const { showTheme } = this.props;
    const { showTheme: nextShowTheme } = nextProps;

    const editor = this.getCssEditor();
    if (!editor._activated && nextShowTheme && nextShowTheme !== showTheme) {
      if (_.isEmpty(editor)) {
        this.init(nextProps);
      } else {
        editor.activate();
      }
    }
  }

  init = (props = this.props) => {
    const { projectStore, routerStore } = props;

    if (!projectStore.current) return;

    if (projectStore.current.themeFile && projectStore.current.themeFile.hasData) {
      return this.initEditor(props);
    }

    projectStore.current.getFile('theme.css').then(file => {
      if (file) {
        return this.initEditor(props);
      }

      if (projectStore.current.isReadonly) return;

      // if no theme.css create it
      projectStore.current
        .createFile({
          file_path: 'theme.css',
          commit_message: `add theme.css`,
          content: '',
        })
        .then(file => {
          this.initEditor(props);
        })
        .catch(e => {
          alert.error('Error creating theme file.');
          routerStore.setQueryParams({ theme: null }, { preserve: true });
        });
    });
  };

  initEditor = (props = this.props) => {
    const { projectStore, cssEditorStore, showTheme, prependClass } = props;

    const entity = _.get(projectStore, 'current.themeFile');
    if (!entity) {
      return;
    }

    cssEditorStore.initEditor(
      {
        entity,
        hideCloseButton: this.hasTabs(),
        prependClass,
        embedded: !showTheme,
      },
      { force: true }
    );
  };

  getCssEditor = () => {
    const { cssEditorStore, projectStore } = this.props;

    return cssEditorStore.getEditor({ id: _.get(projectStore.current, 'themeFile.id') }) || {};
  };

  hasTabs = () => {
    const { tabs } = this.props;

    if (tabs && tabs.length === 1 && tabs[0] === 'css') {
      return false;
    }

    return !_.isEmpty(tabs);
  };

  renderCloseButton = () => {
    const { routerStore } = this.props;

    return (
      <div key="sidebar-button" className="mt-a text-center">
        <Button
          className="m-6"
          icon={faTimes}
          color={colors.accent}
          size={sizes.lg}
          onClick={() => {
            routerStore.setQueryParams({ theme: null }, { preserve: true });
          }}
        >
          Close Theming
        </Button>
      </div>
    );
  };

  renderSideBar = () => {
    const { namespace, routerStore, tabs } = this.props;

    if (!this.hasTabs()) return null;

    const activeTab = _.get(routerStore, 'location.query.theme', tabs[0]);

    let sideBarTabs = [];

    if (_.includes(tabs, 'general')) {
      sideBarTabs.push({
        key: '1',
        title: (
          <div className="flex">
            Theme
            <FeatureBadge
              className="ml-a"
              feature="theme"
              show={!hasSubscriptionFeature(namespace, 'theme')}
              namespace={namespace}
            />
          </div>
        ),
        active: activeTab === 'general',
        onClick: () => routerStore.setQueryParams({ theme: 'general' }),
      });
    }

    if (_.includes(tabs, 'css')) {
      sideBarTabs.push({
        key: '2',
        title: (
          <div className="flex">
            Custom CSS
            <FeatureBadge
              className="ml-a"
              feature="css"
              show={!hasSubscriptionFeature(namespace, 'css')}
              namespace={namespace}
            />
          </div>
        ),
        active: activeTab === 'css',
        onClick: () => routerStore.setQueryParams({ theme: 'css' }),
      });
    }

    sideBarTabs.push(this.renderCloseButton);

    return sideBarTabs;
  };

  renderCssEditor = () => {
    const cssEditor = this.getCssEditor();
    const hasEditor = !_.isEmpty(cssEditor);

    return (
      <div className="flex-1 relative">
        <EditorContainer
          className="Editor flex-1 relative z-0 border-l border-lighten-50"
          hideToolbar={!hasEditor}
          hidePanel={!hasEditor}
        >
          {hasEditor ? (
            <EntityEditorCode
              editor={cssEditor}
              placeholder={'/* Write CSS here */\n\nh1 { color: "red"; }'}
            />
          ) : (
            <PageLoading inverted={false} />
          )}
        </EditorContainer>
      </div>
    );
  };

  renderThemeEditor = () => {
    const { routerStore } = this.props;

    let content;
    let label;

    const activeTab = _.get(routerStore, 'location.query.theme', 'css');
    switch (activeTab) {
      case 'general':
        content = (
          <div className="fill-space overflow-auto">
            <HubTheme className="p-6" />
          </div>
        );
        break;
      default:
        content = this.renderCssEditor();

        label = (
          <div className="absolute pin-r pin-b z-10">
            <div className="text-sm text-grey text-right mr-3 mb-1">theme.css</div>
          </div>
        );
        break;
    }

    let sidebar = this.renderSideBar();
    if (sidebar) {
      sidebar = {
        sections: [
          {
            className: 'h-100',
            children: sidebar,
          },
        ],
      };
    }

    return (
      <Layout
        key="editor"
        sidebar={sidebar}
        content={
          <div className="flex-1 flex relative">
            {label}
            {content}
          </div>
        }
      />
    );
  };

  renderViewer = () => {
    const { component: Component, componentProps } = this.props;
    const cssEditor = this.getCssEditor();

    return (
      <div key="viewer" className="ThemeWrapper bg-white flex flex-col h-100 w-100">
        <Component {...componentProps} />
        {cssEditor.cleanCss && <style dangerouslySetInnerHTML={{ __html: cssEditor.cleanCss }} />}
      </div>
    );
  };

  render() {
    const { showTheme } = this.props;

    if (!showTheme) {
      return this.renderViewer();
    }

    return (
      <SplitPane
        id="hub:theme:viewer"
        split="horizontal"
        defaultSize="60%"
        pane2Style={{ background: 'white' }}
        renderProp={() => {
          return [this.renderViewer(), this.renderThemeEditor()];
        }}
        noPersist
      />
    );
  }
}

export default inject((stores, props) => {
  const { routerStore, cssEditorStore, projectStore, namespaceService } = stores;

  return {
    cssEditorStore,
    projectStore,
    namespace: namespaceService.current || {},
    routerStore,
    showTheme: _.has(routerStore.location, 'query.theme'),
  };
})(observer(ThemeWrapper));
