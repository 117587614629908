import _ from 'lodash';
import { observable, computed, action } from 'mobx';

import { Collection as DisposableCollection } from '@core/disposable';

import { alert } from '@platform/utils/alert';
import { filterFiles, publishFileFilter } from '@platform/utils/projects';
import { cleanSlashes } from '@platform/utils/url';

class PublishStore {
  rootStore;
  _activated = false;
  _buildListeners = new DisposableCollection(); // these can be destroyed when a build finishes

  domain;

  @observable
  doc;

  @observable
  filePath;

  @observable
  basePath;

  // Auth
  @observable
  loginSettings = {
    title: '',
    subtitle: '',
    buttonText: '',
  };

  @observable
  basicAuth = [];

  @observable
  auth0 = {
    entryPoint: '',
    clientSecret: '',
  };

  @observable
  saml = {
    entryPoint: '',
  };

  @observable
  customCSS;

  @observable
  customJS;

  @observable
  whitelabel;

  @observable
  variables;

  // Integrations
  @observable
  integrations = {
    segment: '',
    intercom: '',
    googleAnalytics: '',
    gtm: '',
  };

  @observable
  redirects = [];

  @observable
  setLive = true;

  @observable
  error;

  @observable
  _publishing = false;

  @observable
  buildLogs = observable.map({});

  @observable
  showLogs = observable.map({});

  constructor({ domain, rootStore }) {
    this.domain = domain;
    this.rootStore = rootStore;
  }

  @action
  activate = ({ doc }) => {
    this._activated = true;
    this.doc = doc;

    this.setupBuildListeners();

    // TODO: Handle this with a config class
    const config = _.get(this.doc, 'config') || {};

    // Default to config file or first available file
    const firstFile = _.first(this.files.documentation) || _.first(this.files.modeling) || {};
    this.filePath = config.filePath || firstFile.path;

    if (_.isEmpty(config)) return;

    this.setBuildConfig(config);
  };

  @action
  deactivate() {
    this._activated = false;
  }

  @action.bound
  setupBuildListeners() {
    this._buildListeners.dispose();

    const currentRoom = this.projectStore.currentRoom;
    if (!currentRoom) return;

    this._buildListeners.push(
      currentRoom.on(
        'did_create_buildLog',
        action(message => {
          if (message.domain !== this.domain) return;

          const buildLogs = this.buildLogs.get(message.buildId);
          if (buildLogs) {
            this.buildLogs.set(message.buildId, buildLogs.concat([message]));
          } else {
            this.buildLogs.set(message.buildId, [message]);
          }
        })
      )
    );

    this._buildListeners.push(
      currentRoom.on(
        'did_update_build',
        action((build = {}) => {
          if (build.domain !== this.domain) return;

          this._publishing = false;

          if (build.err) {
            alert.error(build.err);
          } else if (build.setLive) {
            alert.success(`Successfully published to ${this.domain}!`);
          } else {
            alert.success('Build finished! You can now preview and set it live.');
          }

          this.rootStore.stores.docsService.refetchQueries(['doc', 'docBuilds']);
        })
      )
    );
  }

  @computed
  get canPublish() {
    return this.rootStore.stores.projectService.canUser({ action: 'publish:project' });
  }

  @computed
  get projectId() {
    return this.projectStore.id;
  }

  @computed
  get isPublishing() {
    return this._publishing;
  }

  @computed
  get files() {
    // Gets filterd list of files from latest release (or current version if no releases)
    return filterFiles({
      files: this.projectStore.branchFiles(this.latestRelease.branch).get(),
      fileFilter: publishFileFilter,
    });
  }

  @computed
  get projectStore() {
    return this.rootStore.stores.projectStore.current || {};
  }

  @computed
  get latestRelease() {
    const latestRelease = this.rootStore.stores.releaseService.latest;
    if (latestRelease) {
      return {
        ...latestRelease,
        isReleased: true,
      };
    }

    const latestVersion =
      this.rootStore.stores.versionService.current ||
      this.rootStore.stores.versionService.latest ||
      {};

    return {
      ...latestVersion,
      isReleased: false,
    };
  }

  /**
   * Computes the build config's auth object
   */
  @computed
  get auth() {
    const auth = {
      ...this.loginSettings,
    };

    const saml = _.pickBy(this.saml);
    if (saml.entryPoint) {
      auth.saml = saml;
    }

    const auth0 = _.pickBy(this.auth0);
    if (auth0.entryPoint) {
      auth.auth0 = auth0;
    }

    if (_.compact(this.basicAuth).length) {
      auth.basic = {};

      _.forEach(this.basicAuth, loginInfo => {
        auth.basic[_.toLower(loginInfo.username)] = {
          password: loginInfo.password,
          data: loginInfo.data,
        };
      });
    }

    return auth;
  }

  @action
  setBuildConfig = (config = {}) => {
    this.basePath = config.basePath || '';

    if (config.integrations) {
      this.integrations = _.pick(
        config.integrations,
        'segment',
        'intercom',
        'googleAnalytics',
        'gtm'
      );
    }

    this.customCSS = config.customCSS;
    this.customJS = config.customJS;
    this.whitelabel = config.whitelabel;
    this.variables = config.variables;

    if (config.auth) {
      const { title, subtitle, buttonText, basic, saml, auth0 } = config.auth || {};

      this.loginSettings = {
        title,
        subtitle,
        buttonText,
      };

      this.basicAuth = _.map(basic, (value, username) => {
        return {
          username,
          password: value.password,
          data: value.data,
        };
      });

      if (saml) {
        this.saml = _.pick(saml, 'entryPoint');
      }

      if (auth0) {
        this.auth0 = _.pick(auth0, 'entryPoint', 'clientSecret');
      }
    }

    if (config.redirects) {
      this.redirects = [];
      _.forEach(config.redirects, redirect => {
        if (!redirect.to || !redirect.from) return;

        this.redirects.push({
          to: redirect.to,
          from: redirect.from,
        });
      });
    }
  };

  /**
   * Computes the build config
   */
  @computed
  get buildConfig() {
    const auth = _.pickBy(this.auth);
    const integrations = _.pickBy(this.integrations);

    const redirects = [];
    _.forEach(this.redirects, redirect => {
      const to = _.trim(redirect.to);
      const from = _.trim(redirect.from);
      if (!to || !from) return;

      redirects.push({
        to: cleanSlashes(`/${to}`),
        from: cleanSlashes(`/${from}`),
      });
    });

    return _.pickBy({
      auth: _.isEmpty(auth) ? undefined : auth,
      integrations: _.isEmpty(integrations) ? undefined : integrations,
      redirects,
      filePath: this.filePath,
      basePath: this.basePath,
      customCSS: this.customCSS,
      customJS: this.customJS,
      whitelabel: this.whitelabel,
      variables: this.variables,
    });
  }

  @action.bound
  setValue(path, value) {
    _.set(this, path, value);
  }

  @action.bound
  updateArray(prop, transformation, path, value) {
    if (transformation === 'pull') {
      if (this[prop].length === 1) {
        this[prop].clear();
      } else {
        _.pullAt(this[prop], value);
      }
    } else if (transformation === 'push') {
      this[prop].push(value);
    } else if (transformation === 'set') {
      const [index, key] = _.isArray(path) ? path : _.split(path, '.');
      const item = this[prop][index];

      this[prop][index] = {
        ...item,
        [key]: value,
      };
    } else {
      console.error(`${prop} ${transformation} not supported`);
    }
  }

  @action.bound
  toggleShowLogs(buildId, value) {
    this.showLogs.set(buildId, typeof value === 'undefined' ? !this.showLogs.get(buildId) : value);
  }

  createRelease = () => {
    if (this.latestRelease && this.latestRelease.isReleased) return Promise.resolve();

    return this.rootStore.stores.releaseService.create(
      { tag_name: this.latestRelease.name, message: '' },
      { projectId: this.projectId }
    );
  };

  getLatestFiles = () => {
    this.projectStore.getFileTree({ force: true, ref: this.latestRelease.branch });
  };
}

export default class PublishStoreManager {
  rootStore;

  @observable
  stores = new Map();

  constructor(props) {
    Object.assign(this, props);
  }

  @computed
  get current() {
    const domain = _.get(this.rootStore.stores, 'routerStore.location.query.domain');
    return this.stores.get(domain);
  }

  @action.bound
  getByDomain(domain, options) {
    let store = this.stores.get(domain);

    if (!store) {
      store = new PublishStore({ domain: domain, rootStore: this.rootStore, ...options });
      this.stores.set(domain, store);
    }

    return store;
  }
}
