import React from 'react';
import { inject, observer } from 'mobx-react';
import ReactSplitPane from 'react-split-pane';

import './main.css';

const SplitPane = props => {
  const {
    renderProp,
    className,
    split = 'vertical',
    minSize,
    maxSize, // if negative, stops that many pixels before the end
    defaultSize,
    pane = {},
    updateSplitPane,
    style,
    paneStyle,
    pane1Style,
    pane2Style,
    resizerStyle,
  } = props;

  const computedDefault = pane.size || defaultSize;

  return (
    <ReactSplitPane
      className={className}
      split={split}
      minSize={minSize || 5}
      maxSize={maxSize || -5}
      defaultSize={computedDefault}
      style={style}
      paneStyle={paneStyle}
      pane1Style={pane1Style}
      pane2Style={pane2Style}
      resizerStyle={resizerStyle}
      onDragFinished={size => {
        if (updateSplitPane) {
          updateSplitPane({
            size,
          });
        }
      }}
    >
      {renderProp()}
    </ReactSplitPane>
  );
};

export default inject(({ appStore }, { id, noPersist }) => ({
  ...(id && !noPersist ? appStore.injectSplitPane(id) : null),
}))(observer(SplitPane));
