import * as EX from '@core/extension';

import { faPuzzlePiece } from '@fortawesome/pro-solid-svg-icons/faPuzzlePiece';

export class Oas3Extension extends EX.Spec implements EX.ISpec {
  public readonly id: string = '.oas3';
  public readonly name: string = 'OAS v3';
  public readonly longName: string = 'OpenAPI v3 Specification';
  public readonly icon: EX.IIcon = faPuzzlePiece;

  public get supportedModes() {
    return [this.editor.supportedModes.read, this.editor.supportedModes.code];
  }
}
