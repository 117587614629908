import _ from 'lodash';
import { computed } from 'mobx';

import * as Fragments from './fragments';

import { GQLService } from '../_gql';

export class DiscussionStore extends GQLService {
  // TODO:
  // can probably have a general activate function for each GQLService that creates the queries and mutations based on api route/method
  // @observable isSubscribing = false;

  constructor(props) {
    super(props);

    this.info = this.createQuery({
      baseKey: 'discussion',
      query: Fragments.discussions.info,
      localQueries: {
        discussion: {
          typename: 'Discussion',
          single: true,
          filterFunc: ({ records, variables }) => {
            return _.find(records, {
              projectId: variables.projectId,
              iid: variables.discussionIId,
            });
          },
        },
      },
    });

    this.list = this.createQuery({
      baseKey: 'discussions',
      query: Fragments.discussions.list,
      localQueries: {
        discussions: {
          typename: 'Discussion',
          filterFunc: ({ records, variables }) => {
            return _.filter(
              records,
              _.omitBy(
                {
                  projectId: variables.projectId,
                  state: variables.state,

                  // only filter on file path if asking for it
                  file: variables.filePath
                    ? {
                        path: variables.filePath,
                      }
                    : undefined,
                },
                _.isUndefined
              )
            );
          },
        },
      },
    });

    this.createHandler = this.createMutation({
      baseKey: 'createDiscussion',
      mutation: Fragments.discussions.create,
    });

    this.updateHandler = this.createMutation({
      baseKey: 'updateDiscussion',
      mutation: Fragments.discussions.update,
    });

    this.setStateHandler = this.createMutation({
      baseKey: 'setStateDiscussion',
      mutation: Fragments.discussions.setState,
    });
  }

  /**
   * Which discussion is currently active? Designated with the query string "discussion".
   */
  @computed
  get activeId() {
    return (
      Number(_.get(this, 'rootStore.stores.routerStore.location.query.discussion')) || undefined
    );
  }
}

export const create = ({ data, env, options = {} }) => {
  return new DiscussionStore({ rootStore: env.rootStore });
};
