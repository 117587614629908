import React from 'react';

import EntityEditor from '../EntityEditor';
import MarkdownEditor from '../EntityEditor/markdown';

const contentTabs = [
  {
    id: 'code',
    component: MarkdownEditor,
  },
];

const MdEditor = props => {
  const { entity, ...extraProps } = props;

  return [
    <EntityEditor
      key="1"
      entity={entity}
      editorStoreKey="rawEditorStore"
      contentRouterData={{}}
      contentTabs={contentTabs}
      {...extraProps}
    />,
  ];
};

export default MdEditor;
