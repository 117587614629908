import React from 'react';
import { observer } from 'mobx-react';

import MarkdownEditor from '@platform/components/MarkdownEditor';

const Markdown = props => {
  const { editor } = props;
  const spec = editor.spec;

  return (
    <MarkdownEditor
      id={`spec-${editor.id}-${editor.currentPath}`}
      value={editor.spec || ''}
      className="h-full"
      onChange={value => {
        if (editor && spec !== value) {
          editor.updateSpec(value);
        }
      }}
      borderless
      preview
    />
  );
};

export default observer(Markdown);
