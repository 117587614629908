import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';

import Accordion from '@platform/components/Accordion';
import FormInput from '@platform/components/FormInput';
import FormInputList from '@platform/components/FormInputList';
import LearnMore from '@platform/components/LearnMore';
import SimpleSection from '@platform/components/SimpleSection';

import { hasSubscriptionFeature } from '@platform/utils/billing';
import { getConfigVar } from '@platform/utils/config';
import { getProtocol } from '@platform/utils/publishing';

import AccordionTitle from './accordionTitle';

const Authorization = props => {
  const { namespace, publishStore } = props;

  if (!publishStore.canPublish) return null;

  const { basicAuth = [], auth0 = {}, saml = {}, loginSettings = {} } = publishStore;

  const basicEnabled = hasSubscriptionFeature(namespace, 'auth_basic');
  const auth0Enabled = hasSubscriptionFeature(namespace, 'auth_auth0');
  const samlEnabled = hasSubscriptionFeature(namespace, 'auth_saml');

  const auth0Callback = `${getProtocol()}://${publishStore.domain ||
    `{subdomain}.${getConfigVar('SL_PUBS_HOST')}`}/_/auth0/callback`;

  return (
    <SimpleSection className="PublishViewer-providers mt-12" title="Authorization" icon={faLock}>
      <Accordion
        id="Authorization"
        styled
        fluid
        exclusive={false}
        panels={[
          {
            title: (
              <AccordionTitle
                title="Login Page Settings"
                checked={loginSettings.title}
                namespace={namespace}
              />
            ),
            content: (
              <div>
                <div>Customize the title and subtitle of your login page.</div>

                <FormInput
                  className="mt-4"
                  label="Title"
                  tip="This text will be displayed at the top of your login page."
                  placeholder="Welcome to our Hub!"
                  value={loginSettings.title}
                  onChange={(e, { value }) => {
                    publishStore.setValue('loginSettings.title', value);
                  }}
                  disabled={!basicEnabled && !loginSettings.title}
                  required
                  fluid
                />

                <FormInput
                  className="mt-4"
                  label="Subtitle"
                  tip="This text will be displayed below the login page title."
                  placeholder="To view the docs, please log in."
                  value={loginSettings.subtitle}
                  onChange={(e, { value }) => {
                    publishStore.setValue('loginSettings.subtitle', value);
                  }}
                  disabled={!basicEnabled && !loginSettings.subtitle}
                  required
                  fluid
                />

                <FormInput
                  className="mt-4"
                  label="Button Text"
                  tip="This is the text that will be displayed on the login button. You can style the button using custom CSS."
                  placeholder="Click here to log in with Example Company"
                  value={loginSettings.buttonText}
                  onChange={(e, { value }) => {
                    publishStore.setValue('loginSettings.buttonText', value);
                  }}
                  disabled={!basicEnabled && !loginSettings.buttonText}
                  required
                  fluid
                />
              </div>
            ),
          },
          {
            title: (
              <AccordionTitle
                title="Username/Password Logins"
                checked={!_.isEmpty(basicAuth)}
                namespace={namespace}
                feature="auth_basic"
              />
            ),
            content: (
              <div>
                <div>
                  Add multiple username/password combinations logins to protect your documentation.
                </div>

                <FormInputList
                  className="flex flex-col mt-4 w-full"
                  addText="Add username and password login"
                  fields={basicAuth.slice()}
                  properties={[
                    {
                      type: 'text',
                      label: 'Username',
                      name: 'username',
                      divider: ':',
                    },
                    {
                      type: 'password',
                      label: 'Password',
                      name: 'password',
                    },
                  ]}
                  handleUpdate={(t, p, v) => {
                    publishStore.updateArray('basicAuth', t, p, v);
                  }}
                  disabled={!basicEnabled && _.isEmpty(basicAuth.slice())}
                  preserveProp
                />
              </div>
            ),
          },
          {
            title: (
              <AccordionTitle
                title="Auth0"
                checked={auth0.entryPoint && auth0.clientSecret}
                namespace={namespace}
                feature="auth_auth0"
              />
            ),
            content: (
              <div>
                <div>
                  <a href="https://auth0.com/" target="_blank" rel="noopener noreferrer">
                    Auth0
                  </a>{' '}
                  is a third party authentication system that provides a login portal for your
                  documentation.
                </div>

                <div className="mt-4">
                  For more information, check out our docs on the{' '}
                  <LearnMore feature="auth0">Auth0 integration</LearnMore>
                </div>

                <FormInput
                  className="mt-4"
                  label="Hosted Login Page"
                  placeholder="example.auth0.com/login?client={client_id}"
                  value={auth0.entryPoint}
                  onChange={(e, { value }) => {
                    publishStore.setValue('auth0.entryPoint', _.trim(value));
                  }}
                  disabled={!auth0Enabled && !auth0.entryPoint}
                  fluid
                  required
                />

                <FormInput
                  className="mt-4"
                  label="Client Secret"
                  tip="Your client secrets is used to authenticate users during login."
                  type="password"
                  value={auth0.clientSecret}
                  onChange={(e, { value }) => {
                    publishStore.setValue('auth0.clientSecret', _.trim(value));
                  }}
                  disabled={!auth0Enabled && !auth0.clientSecret}
                  fluid
                  required
                />

                <div className="mt-4">
                  Please add <span className="underline font-bold">{auth0Callback}</span> to your{' '}
                  <span className="font-bold">Allow Callback URLs</span> in Auth0.
                </div>
              </div>
            ),
          },
          {
            title: (
              <AccordionTitle
                title="SAML"
                checked={saml.entryPoint}
                namespace={namespace}
                feature="auth_saml"
              />
            ),
            content: (
              <div>
                <FormInput
                  className="mt-4"
                  label="Entry Point"
                  tip="This is where users will go to authenticate - it should serve up your SAML login page."
                  placeholder="https://sso.example.com/login"
                  value={saml.entryPoint}
                  onChange={(e, { value }) => {
                    publishStore.setValue('saml.entryPoint', _.trim(value));
                  }}
                  disabled={!samlEnabled && !saml.entryPoint}
                  required
                  fluid
                />

                <div className="mt-4">
                  Please add a callback to{' '}
                  <span className="underline font-bold">
                    {getConfigVar('SL_API_HOST')}
                    /simple-sso/
                    {publishStore.domain || 'your-domain.com'}
                    /saml/callback
                  </span>
                  .
                </div>
              </div>
            ),
          },
        ]}
      />
    </SimpleSection>
  );
};

export default inject((stores, props) => {
  const { publishStore } = stores;

  return {
    publishStore: publishStore.current,
  };
})(observer(Authorization));
