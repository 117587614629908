import React from 'react';
import _ from 'lodash';
import { Button, Menu } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import PageLoading from '@platform/components/PageLoading';
import UserActivity from '@platform/pages/users/activity';
import UserExplorer from '@platform/pages/users/explorer';

import { isFeatureEnabled } from '@platform/utils/acl';

const StartPage = props => {
  const { userService } = props;
  const { authorizedUser } = userService;

  if (userService.isLoading) {
    return <PageLoading inverted={false} size="small" style={{ margin: 20 }} text="" />;
  }

  if (isFeatureEnabled({ user: authorizedUser, feature: 'user-explorer' })) {
    return <UserExplorer />;
  }

  return <UserActivity />;
};

export default inject('userService')(observer(StartPage));
