export const versionSortDesc = o => {
  let sum = 0;
  let parts = o.name.split('.');
  for (let i = 0; i < parts.length; i++) {
    const e = parts.length - i;
    try {
      sum += Number(parts[i]) * Math.pow(100, e);
    } catch (error) {
      // noop
    }
  }
  return -sum;
};

export const versionSortAsc = o => {
  let sum = 0;
  let parts = o.name.split('.');
  for (let i = 0; i < parts.length; i++) {
    const e = parts.length - i;
    try {
      sum += Number(parts[i]) * Math.pow(100, e);
    } catch (error) {
      // noop
    }
  }
  return sum;
};
