import { getConfigVar } from '../config';

let visible = true;

const show = key => {
  const Zendesk = sdk(key);

  if (Zendesk) {
    Zendesk.livechat.window.show();
    visible = true;
  }
};

const toggle = key => {
  const Zendesk = sdk(key);

  if (visible) {
    shutdown();
  } else {
    show();
  }
};

const shutdown = key => {
  const Zendesk = sdk(key);

  if (Zendesk) {
    Zendesk.livechat.hideAll();
    visible = false;
  }
};

const script = _key => {
  const key = _key || getConfigVar('ZENDESK_KEY');

  if (key) {
    return `<script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}"> </script>`;
  }

  return '';
};

const sdk = _key => {
  const key = _key || getConfigVar('ZENDESK_KEY');

  if (key && typeof window !== 'undefined' && window.$zopim) {
    return window.$zopim;
  }
};

export default {
  toggle,
  script,
  sdk,
};
