import gql from 'graphql-tag';

const discussion = gql`
  fragment discussion on Discussion {
    id
    iid
    body
    summary
    state
    projectId
    file {
      id
      path
    }
    creator {
      id
      avatar
      username
    }
    commentCount
    createdAt
    updatedAt
    lastActivityAt
  }
`;

const pageInfo = gql`
  fragment pageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;

export const discussions = {
  info: gql`
    query DiscussionInfo($projectId: Int!, $discussionIId: Int!) {
      discussion(projectId: $projectId, discussionIId: $discussionIId) {
        ...discussion
      }
    }
    ${discussion}
  `,

  list: gql`
    query DiscussionList($projectId: Int!, $filePath: String, $state: DiscussionState) {
      discussions(projectId: $projectId, filePath: $filePath, state: $state) {
        nodes {
          ...discussion
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${discussion}
    ${pageInfo}
  `,

  create: gql`
    mutation DiscussionCreate($input: CreateDiscussionInput!) {
      createDiscussion(input: $input) {
        ...discussion
      }
    }
    ${discussion}
  `,

  update: gql`
    mutation DiscussionUpdate(
      $projectId: Int!
      $discussionIId: Int!
      $input: UpdateDiscussionInput!
    ) {
      updateDiscussion(projectId: $projectId, discussionIId: $discussionIId, input: $input) {
        ...discussion
      }
    }
    ${discussion}
  `,

  setState: gql`
    mutation DiscussionSetState(
      $projectId: Int!
      $discussionIId: Int!
      $input: SetDiscussionStateInput!
    ) {
      setDiscussionState(projectId: $projectId, discussionIId: $discussionIId, input: $input) {
        ...discussion
      }
    }
    ${discussion}
  `,
};
