const _ = require('lodash');

const production = require('./production');
const staging = require('./staging');
const development = require('./development');

const envs = {
  production,
  staging,
  development,
};

module.exports = (vars = {}, options = {}) => {
  const { env = '' } = options;

  let config =
    envs[env] || envs[process.env.RELEASE_STAGE] || envs[process.env.NODE_ENV] || development;

  // window.__SL is set by the node server
  if (typeof window !== 'undefined') {
    config = Object.assign({}, config, _.get(window, 'env', {}));
  }

  config = Object.assign(
    {
      DEFAULT_LOCALE: 'en',
    },
    config,
    vars
  );

  if (process.env) {
    _.keys(Object.assign(production, staging, development)).forEach(key => {
      if (_.has(process.env, key)) {
        config[key] = process.env[key];
      }
    });
  }

  return config;
};
