import _ from 'lodash';
import { observable, action, reaction, computed } from 'mobx';

import Base from './base';

const chapters = [
  {
    name: 'Swagger Modeling',
    description: 'Robust tooling to work directly with the Swagger API specification.',
    time: 0,
  },
  {
    name: 'Visual Modeling',
    description: 'OR create Swagger 10x faster with our visual tools, no prior knowledge needed.',
    time: 10,
  },
  {
    name: 'Local Files',
    description:
      'Our desktop app can read/write from your local file system for seamless integration with existing processes - your favorite IDE, git, spec generation from code, you name it.',
    time: 40,
  },
  {
    name: 'One Click Tests',
    description:
      'Connect your API specification to Scenarios for coverage reports and instant test generation. Batteries included.',
    time: 70,
  },
  {
    name: 'Contract Testing',
    description:
      "Turn your API specification into a single point of truth, and save time while you're at it.",
    time: 140,
  },
  {
    name: 'Complex Request Chaining',
    description:
      'Easily capture and save values for re-use. Chain together steps to handle complex test cases.',
    time: 155,
  },
  {
    name: 'CI Integration',
    description:
      'A single command will run your scenarios outside of Stoplight, on any operating system. Magic.',
    time: 200,
  },
];

const bannerImgs = {
  testing: 'https://cdn.stoplight.io/scenarios/home-alt-banner.png',
  modeling: 'https://cdn.stoplight.io/scenarios/home-alt-spec.png',
  coverage: 'https://cdn.stoplight.io/scenarios/home-alt-coverage.png',
};

const bannerLinks = {
  testing: '/scenario-editor',
  modeling: '/spec-editor',
  coverage: '/scenario-editor',
};

export default class HomeStore extends Base {
  video = null;

  @observable
  videoPaused = false;

  @observable
  videoFinished = false;

  @observable
  progressPercent = false;

  @observable
  debouncedCurrentTime = false;

  @observable
  _currentTime = false;

  @observable
  topStep = 1;

  @observable
  activeBanner = 'testing';

  constructor() {
    super();
    this.init();
  }

  setupReactions() {
    reaction(
      () => {
        return {
          currentTime: this._currentTime,
        };
      },
      ({ currentTime }) => {
        this.debouncedCurrentTime = currentTime;
        this.progressPercent = (currentTime / this.videoDuration) * 100;
      },
      {
        name: 'currentTimeChanged',
        // delay: 1000,
      }
    );
  }

  @computed
  get chapters() {
    const computedChapters = [];
    _.forOwn(chapter, (chapter, i) => {
      const index = parseInt(i);

      let isActive = false;
      let isDone = false;
      if (
        this.debouncedCurrentTime >= chapter.time &&
        chapters[index + 1] &&
        chapters[index + 1].time > this.debouncedCurrentTime
      ) {
        isActive = true;
      } else if (this.debouncedCurrentTime > chapter.time) {
        if (!chapters[index + 1]) {
          isActive = true;
        } else {
          isDone = true;
        }
      }

      computedChapters.push({
        ...chapter,
        isActive,
        isDone,
      });
    });

    return computedChapters;
  }

  @computed
  get currentChapter() {
    return _.find(this.chapters, { isActive: true });
  }

  @action.bound
  initializeVideo(video) {
    this.video = video;
    this.video.playbackRate = 1.5;
  }

  @action.bound
  toggleVideo() {
    if (this.video.paused) {
      this.video.play();
      this.videoPaused = false;
      this.videoFinished = false;
    } else {
      this.video.pause();
      this.videoPaused = true;
    }
  }

  @action.bound
  onVideoCanPlay() {
    this.videoDuration = this.video.duration;
  }

  @action.bound
  onVideoTimeUpdate() {
    this._currentTime = this.video.currentTime;
  }

  @action.bound
  onVideoEnd() {
    this.videoPaused = true;
    this.videoFinished = true;
  }

  @action
  updateCurrentTime(time) {
    this.video.currentTime = time;
    this.debouncedCurrentTime = time;
    this.progressPercent = (time / this.videoDuration) * 100;
    this.video.play();
  }

  @action
  setTopStep(step) {
    this.topStep = step;
  }

  @action
  setActiveBanner(type) {
    this.activeBanner = type;
  }

  @computed
  get activeBannerImg() {
    return bannerImgs[this.activeBanner];
  }

  @computed
  get activeBannerLink() {
    return bannerLinks[this.activeBanner];
  }
}
