import _ from 'lodash';
import { filter } from '../json/compose';

const rules = () => {
  return {
    Tags: {
      recursive({ currentPath = [], key, value }) {
        return currentPath[0] === 'tags' && _.size(currentPath) <= 3;
      },
      matcher({ parentPath = [], key, value }) {
        return parentPath[0] === 'tags';
      },
      searchFactory({ key, value }) {
        return {
          key,
          value,
        };
      },
    },
    SecuritySchemes: {
      recursive({ currentPath = [], key, value }) {
        return currentPath[0] === 'securityDefinitions' && _.size(currentPath) <= 2;
      },
      matcher({ parentPath = [], key, value }) {
        return parentPath[0] === 'securityDefinitions';
      },
      searchFactory({ key }) {
        return {
          key,
        };
      },
    },
    Paths: {
      recursive({ currentPath = [], key, value }) {
        return currentPath[0] === 'paths' && _.size(currentPath) <= 2;
      },
      matcher({ parentPath = [], key, value }) {
        return parentPath[0] === 'paths';
      },
      searchFactory({ currentPath }) {
        return {
          currentPath,
        };
      },
    },
    Operations: {
      recursive({ currentPath = [], key, value }) {
        return currentPath[0] === 'paths' && _.size(currentPath) <= 3;
      },
      matcher({ parentPath = [], key, value }) {
        return parentPath[0] === 'paths' && _.size(parentPath) === 2;
      },
      searchFactory({ currentPath, value }) {
        return {
          currentPath,
          ..._.pick(value, ['tags', 'security']),
        };
      },
    },
    Models: {
      recursive({ currentPath = [], key, value }) {
        return currentPath[0] === 'definitions' && _.size(currentPath) <= 2;
      },
      matcher({ parentPath = [], key, value }) {
        return parentPath[0] === 'definitions';
      },
      searchFactory({ currentPath }) {
        return {
          currentPath,
        };
      },
    },
    Shared: {
      recursive({ currentPath = [], key, value }) {
        return _.includes(['responses', 'parameters'], currentPath[0]) && _.size(currentPath) <= 3;
      },
      matcher({ parentPath = [], key, value }) {
        return _.includes(['responses', 'parameters'], parentPath[0]);
      },
      searchFactory({ currentPath }) {
        return {
          currentPath,
          tags: ['shared'],
        };
      },
    },
  };
};

export const filterSpec = (spec, expression) => {
  return filter(
    { spec, rules: rules() },
    {
      search: {
        query: expression,
      },
    }
  );
};
