import _ from 'lodash';
import { uniqueSlugify } from '@platform/utils/general';

import * as sidebar from './sidebar/editor';
export const sidebarUtils = sidebar;

import { getInferredSubpageType } from './index';

// Notes on this in the utils/index file
export const blockConfig = {
  text: {},
  code: {},
  tabs: {},
  container: {},
  jsonSchema: {},
  http: {
    paddedInner: true,
  },
  image: {},
  callout: {},
  html: {},
  hero: {},
  barList: {},
  cardList: {},
  operation: {},
  ref: {},
};

export const blockTypes = [
  {
    id: 'text',
    type: 'text',
    icon: 'align center',
    name: 'Text Block',
    description: 'Just a plain ole text block.',
    data: '',
  },
  {
    id: 'jsonSchema',
    type: 'jsonSchema',
    src: '/images/bracket.png',
    name: 'JSON Schema',
    description: 'A good way to describe a JSON object.',
    data: {
      type: 'object',
      properties: {},
    },
  },
  {
    id: 'http',
    type: 'http',
    icon: 'send',
    name: 'HTTP Request Maker',
    description: 'Send an HTTP request.',
    data: {},
  },
  {
    id: 'code',
    type: 'code',
    icon: 'code',
    name: 'Code',
    description: 'Code with syntax highlighting.',
    data: '',
  },
  {
    id: 'image',
    type: 'image',
    icon: 'image',
    name: 'Image',
    description: 'Not another cat gif.',
    data: '',
  },
  {
    id: 'tabs',
    type: 'tabs',
    icon: 'columns',
    name: 'Tabs',
    description: 'More information in one spot.',
    data: {
      children: [{ title: 'Tab 1', blocks: [] }, { title: 'Tab 2', blocks: [] }],
    },
  },
  // {
  //   id: 'operation',
  //   type: 'operation',
  //   icon: 'inbox',
  //   name: 'API Operation',
  //   description: 'Connect one of your API operations.',
  //   data: {},
  // },
  // {
  //   id: 'ref',
  //   type: 'ref',
  //   icon: 'usb',
  //   name: 'Reference',
  //   description: 'Use one of your saved blocks',
  //   data: {},
  // },
  {
    id: 'callout',
    type: 'callout',
    icon: 'info circle',
    name: 'Callout',
    description: 'Attention! Useful for alerts, notices, etc.',
    config: {
      type: 'info',
    },
    data: {},
  },
  // {
  //   id: 'hero',
  //   type: 'hero',
  //   icon: 'announcement',
  //   name: 'Hero',
  //   description: 'A large header with title, description, and action.',
  //   data: {
  //     children: [{}],
  //   },
  // },
  // {
  //   id: 'barList',
  //   type: 'barList',
  //   icon: 'list',
  //   name: 'Bar list',
  //   data: {
  //     children: [{}],
  //   },
  // },
  // {
  //   id: 'cardList',
  //   type: 'cardList',
  //   icon: 'block layout',
  //   name: 'Card list',
  //   data: {
  //     children: [{}],
  //   },
  // },
  {
    id: 'html',
    type: 'html',
    icon: 'html5',
    name: 'HTML',
    data: '<h1>Hello World!</h1>',
  },
  // {
  //   id: 'container',
  //   type: 'container',
  //   icon: 'window maximize',
  //   name: 'Container List',
  //   data: { children: [{}] },
  // },
  // {
  //   id: 'accordion',
  //   type: 'accordion',
  //   icon: 'indent',
  //   name: 'Accordion',
  //   data: { children: [{}] },
  // },
];

export const subpageOptions = {
  page: {
    name: 'Subpage',
    description: 'Use subpages to build up content with blocks.',
    warning: 'This will remove any nested subpages.',
    canRef: true,
  },
  group: {
    name: 'Group',
    description: 'A group has subpages nested inside of it.',
    warning: '',
    canRef: true,
    insertBlockText: 'Blocks You Add to a Group Will Be Shown When Readers Navigate Directly to It',
  },
  divider: {
    name: 'Divider',
    icon: 'minus',
    description: 'A divider helps separate content in the sidebar.',
    warning: 'This will remove the path and any blocks or nested subpages.',
    insertBlockText: 'Dividers Cannot Have Blocks',
    noBlocks: true,
    noChildren: true,
  },
  link: {
    name: 'External Link',
    icon: 'linkify',
    description: 'A link points to an external website.',
    warning: 'This will remove any blocks or nested subpages.',
    insertBlockText: 'External Links Cannot Have Blocks',
    noBlocks: true,
    noChildren: true,
  },
};

export const calculateChildPaths = ({ parent = {}, urlPath = '', jsonPath = [] }) => {
  let paths = [];

  const parentUrlPath = _.get(parent, 'route.path');
  let newUrlPath = `${urlPath}${parentUrlPath || ''}`;
  if (parentUrlPath) {
    paths.push({
      urlPath: newUrlPath,
      jsonPath,
    });
  }

  const children = _.get(parent, 'data.children');
  _.forEach(children, (child, index) => {
    paths = paths.concat(
      calculateChildPaths({
        parent: child,
        urlPath: newUrlPath,
        jsonPath: jsonPath.concat(['data', 'children', String(index)]),
      })
    );
  });

  _.forEach(parent.pages, (child, key) => {
    paths.push({
      urlPath: `${newUrlPath}${key}`,
      jsonPath: jsonPath.concat(['pages', key]),
    });

    paths = paths.concat(
      calculateChildPaths({
        parent: child,
        urlPath: `${newUrlPath}${key}`,
        jsonPath: jsonPath.concat(['pages', key]),
      })
    );
  });

  return paths;
};

/**
 * Gets all of the paths at the same subpage depth
 *
 * data: the parsed data to look through
 * parsedPath: the parsedPath to the current subpage ['data', 'children', '0']
 */
export const getSubpageNeighborPaths = ({ data = {}, parsedPath = [] }) => {
  const currentPath = _.get(data, parsedPath.concat(['route', 'path']));
  const subpages = _.get(data, _.dropRight(parsedPath, 1), []);

  const paths = [];

  for (const subpage of subpages) {
    const path = _.get(subpage, 'route.path');

    if (path && path !== currentPath) {
      paths.push(path);
    }
  }

  return paths;
};

/**
 *
 * @param {*} data - initial subpage data
 * @param {*} existing - existing route.paths with starting slash trimmed
 */
export const buildSubpage = (_data, existing) => {
  const data = _data || {};
  const type = data.type || getInferredSubpageType(data);
  const title = data.title || `New Subpage`;

  const subpage = {
    title,
  };

  if (type !== 'divider') {
    let path = _.get(data, 'route.path');

    if (type === 'link') {
      path = 'https://stoplight.io';
    } else {
      let input = title;

      if (!_.isEmpty(path)) {
        input = _.trimStart(path, '/');
      }

      path = `/${uniqueSlugify({ input, existing })}`;
    }

    subpage.route = { path };

    // TODO: support internal path type
  }

  // let's just create a blank subpage for now
  // if (type === 'page') {
  //   const subpageBlocks = _.get(data, 'data.blocks') || [{ type: 'text', data: `# ${title}` }];
  //   _.set(subpage, 'data.blocks', subpageBlocks);
  // } else if (type === 'group') {
  //   const subpageChildren = _.get(data, 'data.children') || [
  //     { title: 'New Subpage', route: { path: '/new-subpage' } },
  //   ];
  //   _.set(subpage, 'data.children', subpageChildren);

  //   if (_.get(data, 'data.blocks')) {
  //     _.set(subpage, 'data.blocks', _.get(data, 'data.blocks'));
  //   }
  // }

  return subpage;
};
