import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import pluralize from 'pluralize';
import TimeAgo from 'react-timeago';
import { inject } from 'mobx-react';
import { Table, Message, Popup, Button } from 'semantic-ui-react';

import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';

import TableList from '@platform/components/TableList';
import ProjectSettingsPopup from '@platform/components/ProjectSettingsPopup';

import { getHighestRole } from '@platform/utils/acl';
import { Link } from '@platform/utils/router';

import { Icon } from '@core/ui';

const ProjectList = props => {
  const {
    namespaceStore,
    routerStore,
    namespace = {},
    projects = [],
    tags = [],
    displayFullTags,
    loadMore,
    onSearch,
    projectService,
    userService,
    orgService,
  } = props;

  const { current = {} } = namespaceStore;
  const { updateActiveFilter = _.noop, activeFilters = {} } = current;
  const { tags: projectTags = [] } = activeFilters;

  let canCreateProject;
  if (namespace.namespace_type === 'user') {
    canCreateProject = _.get(userService.authorizedUser, 'username') === namespace.username;
  } else if (namespace.namespace_type === 'org') {
    canCreateProject = orgService.canUser({ action: 'create:project' });
  }

  return (
    <TableList
      className="ProjectList"
      loading={!projectService.isSearching && projectService.isLoading && _.isEmpty(projects)}
      canAdd={canCreateProject}
      scope={namespace.id}
      items={projects.slice().sort((a, b) => a.name.localeCompare(b.name))}
      searchProps={['name']}
      noItemsFactory={() => {
        if (_.isEmpty(projectTags)) {
          return <Message content="There are no projects to show here." info />;
        }

        return <Message content="No projects match the currently active filters." warning />;
      }}
      actionButton={() => {
        if (canCreateProject) {
          return (
            <Button
              basic
              primary
              onClick={() => {
                routerStore.setQueryParams({ modal: 'new-project' });
              }}
            >
              {namespace.namespace_type === 'org' ? 'New Project' : 'New Personal Project'}
            </Button>
          );
        } else if (namespace.namespace_type === 'org') {
          return (
            <Popup
              on="hover"
              content="You are a 'reader' in this organization. Your administrator must change your role before you can create projects."
              trigger={
                <Button basic primary>
                  New Project
                </Button>
              }
            />
          );
        }

        return null;
      }}
      cellFactories={[
        (project, key) => {
          const openDiscussions = project.open_discussions_count || 0;

          return (
            <Table.Cell key={project.id}>
              <Link to={{ pathname: `/${project.path_with_namespace}` }} className="block">
                <div className="flex items-center font-bold">
                  <div className="mr-3">{project.name}</div>

                  {_.map(project.tag_list, (tag, i) => {
                    const namespaceTag = _.find(tags, { name: tag });
                    if (!namespaceTag) return null;

                    const isActive = _.includes(projectTags, tag);
                    const primaryColor = namespaceTag.primary_color || '#999';
                    const style = {
                      borderColor: primaryColor,
                      color: primaryColor,
                    };

                    if (isActive) {
                      style.backgroundColor = primaryColor;
                    }

                    const onClick = e => {
                      e.stopPropagation();
                      e.preventDefault();

                      if (isActive) {
                        current.updateActiveFilter('unset', ['tags', tag]);
                      } else {
                        current.updateActiveFilter('set', 'tags', [...projectTags, tag]);
                      }
                    };

                    if (displayFullTags) {
                      return (
                        <div
                          className={cn('ProjectList-tag-full', {
                            active: isActive,
                          })}
                          style={style}
                          onClick={onClick}
                        >
                          {tag}
                        </div>
                      );
                    }

                    return (
                      <Popup
                        key={i}
                        on="hover"
                        size="tiny"
                        offset={11}
                        content={tag}
                        trigger={
                          <div
                            className={cn('ProjectList-tag', {
                              active: isActive,
                            })}
                            style={style}
                            onClick={onClick}
                          />
                        }
                      />
                    );
                  })}
                </div>
                <small className="flex text-muted">
                  <div>
                    {openDiscussions} {pluralize('discussion', openDiscussions)}
                  </div>

                  <div className="ml-2 mr-2 font-bold">&middot;</div>

                  <div>
                    active <TimeAgo date={project.last_activity_at} minPeriod={60} />
                  </div>

                  {!_.isEmpty(project.description) && (
                    <div className="ml-2 mr-2 font-bold">&middot;</div>
                  )}

                  <div>{_.truncate(project.description, { length: 50 })}</div>
                </small>
              </Link>
            </Table.Cell>
          );
        },
        (project, key) => {
          let role;
          if (namespace.namespace_type !== 'user') {
            role = getHighestRole({
              entity: project,
              explicit: true,
            });
          }

          return (
            <Table.Cell key={key} textAlign="right">
              <div className="flex items-center justify-end">
                {role && (
                  <div className="pl-3">
                    <small className="text-muted">{role.toLowerCase()}</small>
                  </div>
                )}

                {project.visibility === 'public' && (
                  <div className="pl-3 text-muted">
                    <Popup
                      hoverable
                      offset={9}
                      on="hover"
                      size="small"
                      position="top right"
                      content="This project is public."
                      trigger={
                        <div>
                          <Icon icon={faGlobe} />
                        </div>
                      }
                    />
                  </div>
                )}

                <div className="pl-3">
                  <ProjectSettingsPopup namespace={namespace} project={project} tags={tags} />
                </div>
              </div>
            </Table.Cell>
          );
        },
      ]}
      footer={
        loadMore &&
        projectService.loadMore && (
          <Button
            className="w-1/2"
            onClick={loadMore}
            disabled={projectService.isFinding}
            loading={projectService.isFinding}
          >
            Load More
          </Button>
        )
      }
      onSearch={onSearch}
    />
  );
};

export default inject(
  'namespaceStore',
  'routerStore',
  'projectService',
  'userService',
  'orgService'
)(ProjectList);
