import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Menu, Icon } from 'semantic-ui-react';
import { combinePaths } from '@platform/utils/general';
import JsonPathCacheParentContainer from '@platform/components/JsonPathCacheParentContainer';
import FormAutosizeInput from '@platform/components/FormAutosizeInput';

import BlockList from '../BlockList/editor';

import { getActiveTab, handleChangeTab } from './index';

const INVALID_BLOCK_TYPES = [];

class TabsBlock extends React.Component {
  componentWillMount() {
    const { data = {} } = this.props;

    if (_.isEmpty(data.children)) {
      this.handleAddTab();
    }
  }

  getActiveTab = () => {
    return getActiveTab(this.props);
  };

  handleChangeTab = (e, { name }) => {
    const { updateCache } = this.props;
    handleChangeTab({ id: name, updateCache });
  };

  updateActiveTab = (t, p, v, options) => {
    const { onChangeData } = this.props;

    const activeTab = this.getActiveTab();

    const path = combinePaths(['children', activeTab.index], p);

    onChangeData(t, path, v, options);
  };

  removeActiveTab = () => {
    const { onChangeData, childCacheIds = [], updateCache } = this.props;

    const activeTab = this.getActiveTab();
    if (!activeTab) {
      return;
    }

    const nextTabId = childCacheIds[0] === activeTab.id ? childCacheIds[1] : childCacheIds[0];
    onChangeData('pull', ['children'], activeTab.index);

    if (nextTabId !== activeTab.id) {
      this.handleChangeTab(null, { name: nextTabId });
    } else {
      updateCache('unset', ['activeTabId']);
    }
  };

  handleAddTab = () => {
    const { data = {}, onChangeData } = this.props;
    const { children = [] } = data;

    const tabCount = _.size(children);
    const newTab = {
      title: `Tab ${tabCount + 1}`,
      blocks: [],
    };

    onChangeData('push', ['children'], newTab);
  };

  renderTabs = () => {
    const { data = {}, childCacheIds, cache = {} } = this.props;
    const { children = [] } = data;

    if (!childCacheIds) {
      return null;
    }

    const leftItems = [
      <Menu.Item key="add" className="HubBlock-tab" icon="plus" onClick={this.handleAddTab} />,
    ];

    const tabs = _.map(children, (tab, index) => {
      const id = childCacheIds[index];
      const isActive = cache.activeTabId ? id === cache.activeTabId : Number(index) === 0;

      return (
        <Menu.Item
          key={id}
          name={id}
          content={
            isActive ? (
              <div>
                <Icon name="pencil" />
                <FormAutosizeInput
                  value={tab.title}
                  onChange={(e, target) => this.updateActiveTab('set', ['title'], target.value)}
                  placeholder="Tab Name"
                  transparent
                  bold
                  active
                />
              </div>
            ) : (
              tab.title
            )
          }
          className="HubBlock-tab hover:text-active"
          active={isActive}
          onClick={this.handleChangeTab}
        />
      );
    });

    const rightItems = [
      <div key="div" className="flex-1" />,
      <Menu.Item
        key="remove"
        className="HubBlock-tab hover:text-active"
        icon="trash"
        position="right"
        onClick={this.removeActiveTab}
      />,
    ];

    return leftItems.concat(tabs, rightItems);
  };

  render() {
    const { WrapperFactory, editor, cache, updateCache } = this.props;

    const activeTab = this.getActiveTab();
    const { blocks = [] } = activeTab;

    const contentElem = [];
    contentElem.push(
      <Menu key="tabItems" attached="top" className="HubBlock-tabs">
        {this.renderTabs()}
      </Menu>
    );

    if (activeTab.id) {
      contentElem.push(
        <div key="tabContent" className="HubBlock-tab-content">
          <BlockList
            store={editor}
            blocks={blocks}
            parentPath={activeTab.path}
            relativePath={editor.currentRelativePath}
            cache={cache}
            updateCache={updateCache}
            invalidBlockTypes={INVALID_BLOCK_TYPES}
            blockProps={{ isNested: true }}
            isNested
          />
        </div>
      );
    }

    return <WrapperFactory {...this.props} contentElem={contentElem} />;
  }
}

TabsBlock.displayName = 'HubTabsBlockEditor';

export default JsonPathCacheParentContainer({
  cacheKeyProp: 'cacheKey',
  basePathProp: 'blockPath',
  path: ['data', 'children'],
  childrenProp: 'data.children',
})(observer(TabsBlock));
