import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import PageHeader from '@platform/components/PageHeader';

const TeamPageHeader = props => {
  const { activeTab, orgService, teamService } = props;
  const { name, path } = orgService.current || {};
  const { current: team = {} } = teamService;

  const canUpdate = teamService.canUser({ action: 'update' });

  const crumbs = [
    {
      children: name || '...',
      href: { pathname: `/${path}` },
    },
    {
      children: 'Teams',
      href: { pathname: `/${path}/teams` },
    },
    {
      children: team.name || '...',
      href: { pathname: `/${path}/teams/${team.path}` },
    },
  ];

  const tabs = [
    {
      label: 'Members',
      name: 'people',
      icon: 'user',
    },
    {
      name: 'activity',
      icon: 'rss',
    },
    {
      name: 'projects',
      icon: 'user',
    },
    {
      name: 'settings',
      icon: 'setting',
      visible: canUpdate,
    },
  ];

  return (
    <PageHeader
      tabs={tabs}
      activeTab={activeTab}
      crumbs={crumbs}
      imageUrl={team.avatar_ur}
      iconName={team.name}
      subtitle={team.description}
      basePath={`/${path}/teams/${team.path}`}
    />
  );
};

export default inject('orgService', 'teamService')(observer(TeamPageHeader));
