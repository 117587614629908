import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Input, Select } from 'semantic-ui-react';
import { httpMethods } from '@platform/utils/http';
import { renameObjectKey } from '@platform/utils/general';

class SwaggerMethodPath extends React.Component {
  handleUpdateMethod = newMethod => {
    const { routerStore, editor, currentPath = [] } = this.props;

    const path = currentPath[1];
    const method = currentPath[2];

    if (newMethod === method) {
      return;
    }

    let r = true;
    const existing = _.get(editor, ['parsed', 'paths', path, newMethod]);
    if (existing) {
      r = window.confirm(`The ${newMethod} method already exists at ${path}, replace it?`);
    }

    if (r) {
      const newPath = renameObjectKey(
        _.get(editor, ['parsed', 'paths', path], {}),
        method,
        newMethod
      );
      editor.updateParsed('set', ['paths', path], newPath);
      routerStore.setQueryParams({ path: JSON.stringify(['paths', path, newMethod]) });
    }
  };

  render = () => {
    const { editor, currentPath = [], ui, updateUi, clearUi } = this.props;

    const path = currentPath[1];
    const method = currentPath[2];

    return (
      <div>
        <Input
          value={ui.hasOwnProperty('path') ? ui.path : path || ''}
          size="big"
          placeholder="/path/to/resource"
          actionPosition="left"
          fluid
          action
          onChange={e => {
            updateUi('set', ['path'], e.target.value || '/');
          }}
          onBlur={() => {
            editor.renamePath(path, ui.path, () => {
              clearUi();
              editor.goToPath(['paths', ui.path, method]);
            });
          }}
        >
          <Select
            placeholder="choose method"
            value={method || ''}
            options={_.map(httpMethods, (m, k) => ({
              text: m.name,
              value: k,
            }))}
            onChange={(e, { value }) => {
              editor.renameProp(['paths', path], method, value, () => {
                clearUi();
              });
            }}
          />

          <input />
        </Input>
      </div>
    );
  };
}

export default inject(({ routerStore, oas2EditorStore, appStore }, { id, editorId }) => {
  const editor = oas2EditorStore.getEditor({ id: editorId });

  return {
    routerStore,
    editor,
    currentPath: _.get(editor, 'currentPath'),
    ...appStore.injectUi(`${id}-methodpath`),
  };
})(observer(SwaggerMethodPath));
