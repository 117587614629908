import * as React from 'react';

import { IPanelHeader, PanelHeader } from '../PanelHeader';
import { IPanelSection, PanelSection } from './section';

export interface IPanelSections {
  sections: IPanelSection[];
  header?: IPanelHeader;
  search?: any;
}

export class PanelSections extends React.Component<IPanelSections> {
  public render() {
    const { header, search } = this.props;
    const sections = this.props.sections || [];

    return (
      <div className="flex flex-col h-full">
        {header && <PanelHeader {...header} />}

        {search && <div className="px-4 pb-3">{search}</div>}

        {sections.map((section, i) => {
          return <PanelSection key={section.id || i} {...section} />;
        })}
      </div>
    );
  }
}
