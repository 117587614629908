import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import SplitPane from '@platform/components/SplitPane';

import DiscussionInfo from './discussion-info';
import DiscussionList from './discussion-list';
import GraphQL from '@platform/containers/GraphQL';

const DiscussionPanel = props => {
  const { ui, updateUi, projectStore, discussionService } = props;

  const activeId = discussionService.activeId;
  const currentProject = projectStore.current || {};

  const projectId = currentProject.id;
  const branch = currentProject.currentRef;
  const filePath = currentProject.currentFilePath;

  const data = _.get(ui, [projectId, filePath], {});
  const updateData = (t, p, v) => updateUi(t, [projectId, filePath].concat(p), v);
  const onError = errorMessage => updateData('set', 'discussionsError', errorMessage);

  const variables = {
    projectId,
    state: data.state || 'open',
    orderBy: { field: 'lastActivityAt', direction: 'DESC' },
  };

  if (filePath) {
    variables.filePath = filePath;
  }

  const listElem = (
    <GraphQL
      key="list"
      id={`DiscussionsPanelList:${projectId}:${filePath}`}
      service="discussionService"
      methods={{
        list: {
          variables,
          pollInterval: 10000,
        },
        createHandler: {
          onError,
        },
      }}
      renderProp={({ list, createHandler, setStateHandler }) => {
        return (
          <DiscussionList
            ui={data}
            list={list}
            branch={branch}
            filePath={filePath}
            activeId={activeId}
            projectId={projectId}
            updateUi={updateData}
            createHandler={createHandler}
          />
        );
      }}
    />
  );

  if (!activeId) {
    return listElem;
  }

  const infoElem = (
    <GraphQL
      key="discussions"
      id={`DiscussionsPanelList:${projectId}:${filePath}`}
      service="discussionService"
      methods={{
        info: {
          variables: { projectId, discussionIId: activeId },
          pollInterval: 10000,
          onError,
        },
        setStateHandler: {
          onError,
        },
      }}
      renderProp={({ info, setStateHandler }) => {
        return (
          <DiscussionInfo
            ui={data}
            info={info}
            activeId={activeId}
            projectId={projectId}
            updateUi={updateData}
            setStateHandler={setStateHandler}
          />
        );
      }}
    />
  );

  return (
    <SplitPane
      id="editor:panel:discussions"
      minSize={120}
      maxSize={-160}
      defaultSize="50%"
      split="horizontal"
      style={{ position: 'relative' }}
      resizerStyle={{ border: '3px solid rgb(221,231,240)', height: 0, cursor: 'grab' }}
      renderProp={() => {
        return [listElem, infoElem];
      }}
    />
  );
};

export default inject(({ appStore, projectStore, discussionService }) => {
  return {
    projectStore,
    discussionService,
    ...appStore.injectUi('DiscussionPanel'),
  };
})(observer(DiscussionPanel));
