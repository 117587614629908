import React from 'react';
import { inject, observer } from 'mobx-react';

import PageHeader from '@platform/components/PageHeader';
import { isFeatureEnabled } from '@platform/utils/acl';

const weekday = new Array(7);
weekday[0] = 'Sunday';
weekday[1] = 'Monday';
weekday[2] = 'Tuesday';
weekday[3] = 'Wednesday';
weekday[4] = 'Thursday';
weekday[5] = 'Friday';
weekday[6] = 'Saturday';

const UserPageHeader = props => {
  const { activeTab, userService } = props;

  const { current, authorizedUser = {}, showPersonalProjects } = userService;
  const { id, name, username, avatar_url } = current || authorizedUser;

  const displayName = name || username;
  const isSelf = id && id === authorizedUser.id;

  const crumbs = [
    { children: isSelf ? `Happy ${weekday[new Date().getDay()]} ${displayName}!` : displayName },
  ];

  let tabs = [
    {
      name: 'explorer',
      icon: 'search',
      visible: isFeatureEnabled({ user: authorizedUser, feature: 'user-explorer' }),
    },
    {
      name: 'activity',
      icon: 'rss',
    },
    {
      name: 'projects',
      icon: 'cube',
      visible: showPersonalProjects,
    },
    {
      name: 'settings',
      icon: 'settings',
      path: '/profile',
      visible: isSelf,
    },
  ];

  return (
    <PageHeader
      tabs={tabs}
      activeTab={activeTab}
      crumbs={crumbs}
      basePath={isSelf ? '' : `/${username}`}
      imageUrl={avatar_url}
    />
  );
};

export default inject('userService')(observer(UserPageHeader));
