import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { buildConductorUrl } from '@platform/utils/collections';

import Terminal from '../Terminal';

class CollectionTriggerByUrl extends React.Component {
  render() {
    const { pathKey, scenarioId, variables, projectStore } = this.props;

    let triggerUrl = buildConductorUrl({
      fileId: _.get(projectStore, 'current.currentFileId'),
      path: pathKey ? `/scenarios/${pathKey}` : undefined,
      variables,
    });

    return (
      <div className="ScenarioEditor-terminal" id="tutorial-scenario-terminal">
        <div className="SplitEditor-parsedTitle">
          Trigger By URL
          <span className="SpecEditor-widgetGroupTip ml-2">
            <Popup
              trigger={<Icon name="question circle" />}
              size="small"
              position="top center"
              hoverable
              wide
            >
              Visit this URL to trigger this {scenarioId ? 'scenario' : 'collection'}.<br />
              <br />
              You can also send a POST request, and include the environment variables in the request
              body.
              <br />
              <br />
              <b>NOTE:</b> This reads from the Stoplight database, so make sure you have saved your
              collection back to Stoplight.
            </Popup>
          </span>
        </div>

        <Terminal className="mt-2" light>
          <a href={triggerUrl} target="_blank">
            {triggerUrl}
          </a>
        </Terminal>
      </div>
    );
  }
}

export default inject(({ collectionEditorStore, projectStore }, { editorId }) => ({
  variables: collectionEditorStore.getEditor({ id: editorId }).currentPopulatedEnvVariables,
  projectStore,
}))(observer(CollectionTriggerByUrl));
