import React from 'react';
import _ from 'lodash';
import { Tab } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import ErrorMessage from '@platform/components/ErrorMessage';

import { Icon } from '@core/ui';

const LandingPageWidget = ({ id, icon, title, panes, error, onDismissError, ui, updateUi }) => {
  const tabPanes = _.map(panes, pane => ({
    menuItem: pane.title,
    render: () => (
      <Tab.Pane>
        <div className="py-2 px-1">{pane.content}</div>
      </Tab.Pane>
    ),
  }));

  return (
    <div className="flex flex-col w-100 mb-16" style={{ maxWidth: 750 }}>
      {(icon || title) && (
        <div className="flex items-center mb-6 text-2xl pl-1 text-darken-300">
          {icon && <Icon icon={icon} />}{' '}
          {title && <div className="font-semibold ml-3">{title}</div>}
        </div>
      )}

      {error && <ErrorMessage className="mb-6" error={error} onDismiss={onDismissError} />}

      <Tab
        panes={tabPanes}
        activeIndex={_.get(ui, 'tabData.activeIndex', 0)}
        onTabChange={(e, data) => {
          updateUi('set', 'tabData', data);
        }}
      />
    </div>
  );
};

export default inject(({ appStore }, { id }) => {
  return {
    ...appStore.injectUi(`Widget-${id}`),
  };
})(observer(LandingPageWidget));
