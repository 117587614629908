import _ from 'lodash';

const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const isEqualPaths = (src, dst) => {
  return _.isEqual(_.join(src, '.'), _.join(dst, '.'));
};

export const move = ({ data = {}, srcPath = [], dstPath = [] }) => {
  if (isEqualPaths(srcPath, dstPath)) return data;

  let computedData = data;

  // fetch the src
  const srcParentPath = _.initial(srcPath);
  const srcRelativePath = _.last(srcPath);
  const src = _.get(computedData, srcPath);
  if (_.isUndefined(src)) {
    throw new Error('source path does not exist');
  }

  // fetch the destination
  const dstParentPath = _.initial(dstPath);
  const dstRelativePath = _.last(dstPath);
  const dst = _.get(computedData, dstPath);
  const isParentShared = isEqualPaths(srcParentPath, dstParentPath);
  if (!isParentShared && !_.isUndefined(dst) && !isNumeric(dstRelativePath)) {
    throw new Error('Destination path already exists.');
  }

  // fetch the destination parent (the place we are moving to)
  let dstParent = _.size(dstParentPath) > 0 ? _.get(computedData, dstParentPath) : computedData;

  if (isParentShared) {
    if (_.isArray(dstParent) && isNumeric(srcRelativePath) && isNumeric(dstRelativePath)) {
      dstParent.splice(dstRelativePath, 0, dstParent.splice(srcRelativePath, 1)[0]);
    } else {
      _.set(dstParent, dstRelativePath, src);
      // Clean up source
      _.unset(dstParent, srcRelativePath);
    }

    if (_.isEmpty(dstParentPath)) {
      computedData = dstParent;
    } else {
      computedData = _.set(computedData, dstParentPath, dstParent);
    }
  } else {
    // fetch the source parent (the place we are moving from, where our data is currently located)
    let srcParent = _.size(srcParentPath) > 0 ? _.get(computedData, srcParentPath) : computedData;

    if (_.isArray(srcParent) && isNumeric(srcRelativePath)) {
      srcParent.splice(srcRelativePath, 1);
    } else {
      _.unset(srcParent, srcRelativePath);
    }

    if (_.isEmpty(srcParentPath)) {
      computedData = srcParent;
    } else {
      computedData = _.set(computedData, srcParentPath, srcParent);
    }

    let dstParent = _.size(dstParentPath) > 0 ? _.get(computedData, dstParentPath) : computedData;

    if (_.isUndefined(dstParent)) {
      computedData = _.set(computedData, dstPath, src);
    } else {
      if (_.isArray(dstParent) && isNumeric(dstRelativePath)) {
        dstParent.splice(dstRelativePath, 0, src);
      } else {
        dstParent = _.set(dstParent, [dstRelativePath], src);
      }

      if (_.isEmpty(dstParentPath)) {
        computedData = dstParent;
      } else {
        computedData = _.set(computedData, dstParentPath, dstParent);
      }
    }
  }

  return computedData;
};
