import _ from 'lodash';
import { types, flow } from 'mobx-state-tree';

import { BaseStore } from '../_base';
import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/users/:userId/impersonation_tokens',
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      const originalFind = self.find;

      return {
        find: flow(function*(params, opts = {}) {
          let data;

          try {
            data = yield originalFind(params);
          } catch (error) {
            self.error = _.get(error, 'response.data', String(error));
            throw error;
          }

          // removes stoplight token so user can't revoke
          data.shift();

          return data;
        }),
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('ImpersonationTokenService');

  return Service.create(data, env);
};
