import React from 'react';

import { safeParse, safeStringify } from '@stoplight/json';
import FormInputList from '../FormInputList';

export interface ISwaggerExtensions {
  id: string; // A unique identifier to determine if we need to update our state
  value: any;
  onChange(transformation: string, path: string, value: string): void;
}

export interface ISwaggerExtensionFormItem {
  key: string;
  value: string;
  error?: string;
}

interface IState {
  fields: ISwaggerExtensionFormItem[];
  originalFields: ISwaggerExtensionFormItem[];
}

export class SwaggerExtensions extends React.Component<ISwaggerExtensions, IState> {
  constructor(props: ISwaggerExtensions) {
    super(props);

    const originalFields = getExtensions(props.value);
    this.state = {
      fields: originalFields,
      originalFields,
    };
  }

  // When ID or outside value changes, reset our state
  public componentDidUpdate({ id }) {
    const newFields = getExtensions(this.props.value);
    const { originalFields } = this.state;

    if (id !== this.props.id || safeStringify(newFields) !== safeStringify(originalFields)) {
      this.setState({
        fields: newFields,
        originalFields: newFields,
      });
    }
  }

  // Handle calling the onChange handler to upadte our parent's data
  private _handleUpdateParent(index: number, opts: { skipError: boolean } = { skipError: false }) {
    const { fields, originalFields } = this.state;

    const newField = fields[index] || {};
    const { key: newKey, value: newValue, error } = newField;

    const originalField = originalFields[index] || {};
    const { key: oldKey, value: oldValue } = originalField;

    // don't call update if there is an error in this fields
    if (error && !opts.skipError) return;

    // update value if changed
    if (newValue !== oldValue) {
      this.props.onChange('set', oldKey || newKey, safeParse(newValue) || newValue);
    }

    // update key if changed
    if (oldKey && newKey !== oldKey) {
      this.props.onChange('move', oldKey, newKey);
    }
  }

  // Handle updating our internal state
  private _updateFieldAtIndex(index: number, path: string, value: any) {
    const { fields } = this.state;
    const field = fields[index];
    const updatedField = {
      ...field,
      [path]: value,
    };

    this.setState({
      fields: [...fields.slice(0, index), updatedField, ...fields.slice(index + 1, fields.length)],
    });
  }

  // Remove a field at the given index
  private _removeFieldAtIndex(index: number) {
    const { onChange } = this.props;
    const { fields, originalFields } = this.state;

    // Remove from our internal state
    this.setState({
      fields: [...fields.slice(0, index), ...fields.slice(index + 1, fields.length)],
    });

    // if there's an original key, remove it as well
    const originalField = originalFields[index];
    if (originalField && originalField.key) {
      onChange('unset', originalField.key, originalField.key);
    }
  }

  public render() {
    const { fields } = this.state;

    return (
      <FormInputList
        className="flex flex-col mt-4 w-full"
        fields={fields}
        properties={[
          {
            type: 'text',
            label: 'Extension',
            name: 'key',
            divider: ':',
            showError: true,
            onChange: (index: number, value: string) => {
              this._updateFieldAtIndex(index, 'key', value);
            },
            onBlur: (index: number, value: string) => {
              // Show an error if the "x-" was removed
              if (!extensionRegexp.test(value)) {
                this._updateFieldAtIndex(index, 'error', 'Extensions must start with "x-"');
                return;
              }

              const matchingFields = fields.filter(field => field.key === value);
              if (matchingFields.length > 1) {
                this._updateFieldAtIndex(index, 'error', `Extension "${value}" already exists`);
                return;
              }

              // Unset the error and move the key
              this._updateFieldAtIndex(index, 'error', undefined);
              // use skip error because the field at this index won't get updated til render
              this._handleUpdateParent(index, { skipError: true });
            },
          },
          {
            type: 'textarea',
            label: 'Value',
            name: 'value',
            minRows: 1,
            onChange: (index: number, value: any) => {
              this._updateFieldAtIndex(index, 'value', value);
            },
            onBlur: (index: number, value: any) => {
              this._handleUpdateParent(index);
            },
          },
        ]}
        handleUpdate={(transformation: 'push' | 'pull', path: string[], index: number) => {
          if (transformation === 'push') {
            // Just add this new item to state
            this.setState({
              fields: [
                ...fields,
                {
                  key: 'x-',
                  value: '',
                },
              ],
            });
          } else if (transformation === 'pull') {
            this._removeFieldAtIndex(index);
          }
        }}
        preserveProp
      />
    );
  }
}

// Regexp to determine if a key is an x-extension
const extensionRegexp = /^x-/;

// Given an object, it gets the x-extensions
function getExtensions(obj: any) {
  const extensions: ISwaggerExtensionFormItem[] = [];

  for (const key in obj) {
    if (extensionRegexp.test(key)) {
      extensions.push({
        key,
        value: typeof obj[key] === 'string' ? obj[key] : safeStringify(obj[key]),
      });
    }
  }

  return extensions;
}
