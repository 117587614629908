import _ from 'lodash';
import { types, flow } from 'mobx-state-tree';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/billing/:namespaceId',
      isGettingUsage: false,
      isGettingSubscription: false,
      usage: types.optional(types.frozen()),
      subscription: types.optional(types.frozen()),
    })
    .actions(self => {
      return {
        getUsage: flow(function*(namespaceId) {
          if (!namespaceId) {
            return;
          }

          self.isGettingUsage = true;

          try {
            const res = yield self.send(
              {
                method: 'get',
                path: self.basePath + '/usage',
              },
              { params: { namespaceId } }
            );

            self.usage = res.data;
          } catch (err) {
            self.setError(_.get(err, 'response.data', String(err)));
          }

          self.isGettingUsage = false;
        }),

        getSubscription: flow(function*(namespaceId) {
          if (!namespaceId) {
            return;
          }

          self.isGettingSubscription = true;

          try {
            const res = yield self.send(
              {
                method: 'get',
                path: self.basePath + '/subscription',
              },
              { params: { namespaceId } }
            );

            self.subscription = res.data;
          } catch (err) {
            self.setError(_.get(err, 'response.data', String(err)));
          }

          self.isGettingSubscription = false;
        }),

        subscribe: flow(function*(namespaceId, data) {
          if (!namespaceId) {
            return;
          }

          self.isLoading = true;

          try {
            const res = yield self.send(
              {
                method: 'post',
                path: self.basePath + '/subscription',
                data,
              },
              { params: { namespaceId } }
            );

            self.subscription = res.data;
          } catch (err) {
            self.setError(_.get(err, 'response.data', String(err)));
          }

          self.isLoading = false;
        }),

        updateSubscription: flow(function*(namespaceId, data) {
          if (!namespaceId) {
            return;
          }

          self.isLoading = true;

          try {
            const res = yield self.send(
              {
                method: 'put',
                path: self.basePath + '/subscription',
                data,
              },
              { params: { namespaceId } }
            );

            self.subscription = res.data;
          } catch (err) {
            self.setError(_.get(err, 'response.data', String(err)));
          }

          self.isLoading = false;
        }),
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('BillingService');

  return Service.create(data, env);
};
