import _ from 'lodash';

export const isInTypeGroup = (targets, type) => {
  return type instanceof Array
    ? _.intersection(type, targets).length > 0
    : _.includes(targets, type);
};

const validationKeys = {
  number: ['minimum', 'maximum', 'multipleOf', 'exclusiveMinimum', 'exclusiveMaximum'],
  integer: ['minimum', 'maximum', 'multipleOf', 'exclusiveMinimum', 'exclusiveMaximum'],
  string: ['minLength', 'maxLength', 'pattern'],
  array: ['uniqueItems', 'maxItems'],
  object: ['additionalProperties', 'minProperties', 'maxProperties'],
};

export const removeValidations = (schema = {}, path = []) => {
  let s = schema;

  if (!_.isEmpty(path)) {
    s = _.get(schema, path, {});
  }

  let { type = [] } = s;

  if (!_.isArray(type)) {
    type = [type];
  }

  _.forEach(validationKeys, (valArray, valType) => {
    if (!_.includes(type, valType) && valType !== 'common') {
      _.forEach(valArray, val => {
        if (_.has(s, val)) {
          _.unset(schema, path.concat(val));
        }
      });
    }
  });

  return schema;
};

// type can be an array of types or a single string type
export const isOfType = (target, t) => {
  return t instanceof Array ? _.includes(t, target) : target === t;
};
