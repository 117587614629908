import React from 'react';
import ReactDOM from 'react-dom';

export default (Component, { threshold = 150 } = {}) =>
  class extends React.PureComponent {
    constructor(props) {
      super(props);

      this.hasScrolled = true;
    }

    componentDidMount() {
      this.handleAutoScroll();
    }

    componentDidUpdate() {
      this.handleAutoScroll();
    }

    handleAutoScroll = () => {
      if (!this.props.autoscroll) return;

      const node = this.node;

      if (this.hasScrolled && node.clientHeight < node.scrollHeight) {
        node.scrollTop = node.scrollHeight - node.clientHeight;
      }
    };

    handleScroll = e => {
      if (!this.props.autoscroll) return;

      const node = this.node;
      const bottom = node.scrollTop + node.clientHeight;
      this.hasScrolled = bottom >= node.scrollHeight - threshold;
    };

    render() {
      return (
        <Component
          {...this.props}
          ref={el => {
            this.node = ReactDOM.findDOMNode(el);
          }}
          onScroll={this.handleScroll}
        />
      );
    }
  };
