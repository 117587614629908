import React from 'react';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import { pureCompare } from '@platform/utils/hoc';

import SpecExamples from '../SpecExamples';
import EntityWidgetGroup from '../EntityWidgetGroup';
import SwaggerParameterGroup from '../SwaggerParameterGroup';
import MarkdownEditor from '../MarkdownEditor';
import MarkdownViewer from '../MarkdownViewer';
import JsonSchemaEditor from '../JsonSchemaEditor';
import FormInputMap from '../FormInputMap';
import FormInputLabel from '../FormInputLabel';

const SwaggerResponse = props => {
  const {
    id,
    parsed,
    response,
    dereffedResponse,
    definitions,
    readOnly,
    handleUpdate,
    editorId,
  } = props;

  const description = _.get(response, 'description', '');
  const headers = _.get(response, 'headers');

  let descriptionElem;
  if (readOnly) {
    if (!_.isEmpty(description)) {
      descriptionElem = (
        <div className="SwaggerResponse-description mt-6">
          <FormInputLabel className="pl-0" label="description" />
          <MarkdownViewer className="text-sm" value={description} />
        </div>
      );
    }
  } else {
    descriptionElem = (
      <MarkdownEditor
        id={`${id}:res`}
        placeholder="Response body description..."
        className="mt-6"
        value={description}
        onChange={v => {
          handleUpdate('set', ['description'], v);
        }}
      />
    );
  }

  let headersElem;
  if (readOnly) {
    if (!_.isEmpty(headers)) {
      let headerSchema = { type: 'object', properties: {} };
      _.forEach(headers, (value, name) => {
        _.set(headerSchema, ['properties', name], value);
      });

      headersElem = (
        <JsonSchemaEditor schema={headerSchema} readOnly readOnlyInfoProps={{ label: 'headers' }} />
      );
    }
  } else {
    headersElem = (
      <EntityWidgetGroup
        name="headers"
        tip="A list of headers that are sent with the response."
        action={
          <Button
            icon="plus"
            onClick={() => {
              const newHeaders = _.cloneDeep(_.get(response, 'headers', {}));
              newHeaders[`new${new Date().getTime()}`] = {
                type: 'string',
              };
              handleUpdate('set', ['headers'], newHeaders);
            }}
            size="mini"
            secondary
            compact
          />
        }
      >
        <FormInputMap
          fields={headers}
          handleUpdate={(t, p, v) => {
            handleUpdate(t, ['headers'].concat(p), v);
          }}
          noAdd
          renderFactory={({ fields, onChange, onRemove }) => {
            return (
              <SwaggerParameterGroup
                blacklist={['required', 'ref']}
                parameters={fields.map((f, index) => ({
                  index,
                  param: f,
                }))}
                handleUpdate={(t, p, v) => {
                  switch (t) {
                    case 'pull':
                      onRemove(v);
                      break;
                    default:
                      onChange(t, p, v);
                  }
                }}
                editorId={editorId}
              />
            );
          }}
        />
      </EntityWidgetGroup>
    );
  }

  let schemaElem;
  if (!_.isEmpty(schemaElem)) {
  }

  return (
    <div className="SwaggerResponse">
      {descriptionElem ? <div className="mb-3">{descriptionElem}</div> : null}

      {headersElem ? <div className="mt-6 mb-6">{headersElem}</div> : null}

      <div className="mt-6">
        <JsonSchemaEditor
          id={`${id}-body`}
          schema={_.get(response, 'schema', '')}
          dereferencedSchema={_.get(dereffedResponse, 'schema', '')}
          definitions={definitions}
          readOnly={readOnly}
          editorStore="oas2EditorStore"
          editorId={editorId}
          onSchemaChange={schema => {
            handleUpdate('set', ['schema'], schema);
          }}
          refBuilderProps={{
            localData: parsed,
            fileFilter: { modeling: [/oas2/] },
            routeDataTargets: { oas2: ['definitions'] },
            targets: 'required',
          }}
        />
      </div>

      <SpecExamples
        id={id}
        schema={_.get(response, 'schema', null)}
        dereferencedSchema={_.get(dereffedResponse, 'schema', '')}
        definitions={definitions}
        examples={_.get(response, 'examples', {})}
        readOnly={readOnly}
        className="mt-4"
        handleUpdate={(t, p, v) => {
          handleUpdate(t, ['examples'].concat(p), v);
        }}
      />
    </div>
  );
};

export default pureCompare()(SwaggerResponse);
