import * as EX from '@core/extension';

import { faServer } from '@fortawesome/pro-solid-svg-icons/faServer';

export const COMMANDS = {
  openInstance: 'open:instance',
};

export const MENUS = {
  external: EX.EDITOR_MENUS.toolbar.secondary.concat(['external']),
};

export class PrismExtension extends EX.Spec implements EX.ISpec {
  public readonly id: string = '.prism';
  public readonly name: string = 'Prism';
  public readonly longName: string = 'Prism';
  public readonly icon: EX.IIcon = faServer;

  public get supportedModes() {
    return [this.editor.supportedModes.design, this.editor.supportedModes.code];
  }

  public registerCommands = () => {
    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.openInstance,
        label: 'Hosted Runner',
      })
    );
  };

  public registerMenus = (_: EX.IModeChangedEvent) => {
    this._registerMenuSecondaryActions();
  };

  private _registerMenuSecondaryActions = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.external, {
        id: COMMANDS.openInstance,
        commandId: COMMANDS.openInstance,
        label: 'Hosted Instance',
      })
    );
  };
}
