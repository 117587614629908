import React from 'react';

import { build as buildObjectRouter } from '@platform/utils/objectRouter';

import EntityEditor from '../EntityEditor';
import EntityEditorWidgets from '../EntityEditor/widgets';

import LintSelector from './LintSelector';

// use content router so we can route to different linting file types in with side bar to differentiate
const BuildContentRouter = () => {
  const propsData = {
    root: {
      component: LintSelector,
      componentProps: {},
    },
  };

  const contentRouterMap = new Map();

  contentRouterMap.set([], propsData.root);
  contentRouterMap.set([/.*/], propsData.root);

  const contentRouter = buildObjectRouter(contentRouterMap);

  return {
    propsData,
    contentRouter,
  };
};

const contentRouterData = BuildContentRouter();

const contentTabs = [
  {
    id: 'design',
    component: EntityEditorWidgets,
  },
];

const LintEditor = props => {
  return (
    <EntityEditor
      editorStoreKey="lintEditorStore"
      contentTabs={contentTabs}
      contentRouterData={contentRouterData}
      {...props}
      disableLocal
    />
  );
};

export default LintEditor;
