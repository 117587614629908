import _ from 'lodash';
import { computed } from 'mobx';

import { GQLService } from '../_gql';

import * as Fragments from './fragments';

export class DocsStore extends GQLService {
  constructor(props) {
    super(props);

    this.info = this.createQuery({
      baseKey: 'doc',
      query: Fragments.docs.info,
      localQueries: {
        doc: {
          typename: 'Doc',
          single: true,
          filterFunc: ({ records, variables }) => {
            return _.find(records, variables);
          },
        },
      },
    });

    this.list = this.createQuery({
      baseKey: 'docs',
      query: Fragments.docs.list,
      localQueries: {
        docs: {
          typename: 'Doc',
        },
      },
    });

    this.updateHandler = this.createMutation({
      baseKey: 'updateDoc',
      mutation: Fragments.docs.update,
    });

    this.createHandler = this.createMutation({
      baseKey: 'createDoc',
      mutation: Fragments.docs.create,
    });

    this.deleteHandler = this.createMutation({
      baseKey: 'deleteDoc',
      mutation: Fragments.docs.delete,
    });

    this.buildInfo = this.createQuery({
      baseKey: 'docBuild',
      query: Fragments.docBuilds.info,
      localQueries: {
        docBuild: {
          typename: 'DocBuild',
          single: true,
          filterFunc: ({ records, variables }) => {
            return _.filter(records, {
              projectId: variables.projectId,
              docId: variables.docId,
            });
          },
        },
      },
    });

    this.buildList = this.createQuery({
      baseKey: 'docBuilds',
      query: Fragments.docBuilds.list,
      localQueries: {
        docBuilds: {
          typename: 'DocBuild',
        },
      },
    });

    this.createBuildHandler = this.createMutation({
      baseKey: 'createDocBuild',
      mutation: Fragments.docBuilds.create,
    });

    this.updateBuildHandler = this.createMutation({
      baseKey: 'updateDocBuild',
      mutation: Fragments.docBuilds.update,
      refetchBaseKeys: ['doc'],
    });
  }

  @computed
  get activeDomain() {
    return _.get(this.rootStore.stores.routerStore, 'location.query.domain');
  }

  activateDomain = domain => {
    this.rootStore.stores.routerStore.setQueryParams({
      domain: domain !== this.activeDomain ? domain : null,
    });
  };
}

export const create = ({ data, env, options = {} }) => {
  return new DocsStore({ rootStore: env.rootStore });
};
