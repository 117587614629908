import { create as createDisposable, Collection as DisposableCollection } from '@core/disposable';
import _ from 'lodash';
import { ApolloLink } from 'apollo-link';
import { action, observable, set, reaction } from 'mobx';

class GqlQuery {
  findOrCreateContainer;

  @observable
  error;

  @observable
  loading = false;

  @observable
  networkStatus;

  @observable.shallow
  data = {};

  key;
  baseKey;
  variables;
  orderBy;

  pollInterval;
  watcher;
  subscription;

  reactions = new DisposableCollection();

  constructor(findOrCreateContainer, watcher, opts = {}) {
    this.findOrCreateContainer = findOrCreateContainer;
    this.watcher = watcher;

    const result = watcher.currentResult();
    this.loading = result.loading;
    this.networkStatus = result.networkStatus;

    this.baseKey = opts.baseKey;
    this.key = opts.key;
    this.variables = opts.variables;
    this.orderBy = opts.orderBy;
    this.localQueries = opts.localQueries;
    this.pollInterval = opts.pollInterval;
  }

  refetch = () => {
    if (
      this.subscription &&
      this.watcher &&
      this.watcher.lastResult &&
      !this.watcher.lastResult.loading
    ) {
      this.watcher.refetch();
    }
  };

  startPolling() {
    if (!this.watcher || !this.pollInterval) return;
    this.watcher.startPolling(this.pollInterval);
  }

  stopPolling() {
    if (!this.watcher) return;
    this.watcher.stopPolling();
  }

  @action
  subscribe() {
    if (!this.watcher) return;
    if (!this.watcher.shouldSubscribe) return;

    this.setupReactions();

    this.subscription = this.watcher.subscribe({
      next: action(value => {
        this.error = undefined;
        this.loading = value.loading;
        this.networkStatus = value.observableQuery;

        // TODO: maybe?
        // this.updateFromResult(value);
      }),

      error: action(error => {
        const e = new Error(error);
        const errorMessage = _.get(e, 'message');
        this.error = errorMessage;
        this.loading = false;
      }),
    });

    this.refetch();
    this.startPolling();
  }

  @action
  unsubscribe() {
    this.stopPolling();
    if (!this.subscription) return;

    this.subscription.unsubscribe();
    this.subscription = undefined;
    this.reactions.dispose();
  }

  setupReactions = () => {
    this.reactions.dispose();

    _.forEach(this.localQueries, (query, name) => {
      const { typename, filterFunc, single } = query;

      const container = this.findOrCreateContainer(typename);
      this.reactions.push(
        createDisposable(
          reaction(
            () => container.lastUpdated,
            () => {
              set(this.data, {
                [name]: this.runLocalQuery({
                  filterFunc,
                  single,
                  records: container.records.slice(),
                }),
              });
            },
            {
              fireImmediately: true,
            }
          )
        )
      );
    });
  };

  runLocalQuery = opts => {
    const { filterFunc, single } = opts;
    const { orderBy, ...variables } = this.variables;

    let filtered = opts.records;

    if (filterFunc) {
      // custom filtering
      filtered = filterFunc({ records: filtered, single, variables });
    } else {
      // filter them down with basic "and" filter on variables
      if (!_.isEmpty(variables)) {
        filtered = _.filter(filtered, variables);
      }
    }

    if (single) {
      return Array.isArray(filtered) ? filtered[0] : filtered;
    }

    if (orderBy) {
      filtered = _.orderBy(filtered, orderBy.field, _.toLower(orderBy.direction));
    }

    return filtered;
  };
}

class MobxLink extends ApolloLink {
  apolloClient;

  cache = new Map();

  findOrCreateContainer = name => {
    let container = this.cache.get(name);

    if (!container) {
      this.cache.set(
        name,
        observable(
          {
            records: [],
            lastUpdated: new Date(),
          },
          {},
          { deep: false }
        )
      );

      container = this.cache.get(name);
    }

    return container;
  };

  pushAllIntoContainer = (name, records) => {
    const container = this.findOrCreateContainer(name);
    container.records = container.records.concat(records);
    container.lastUpdated = new Date();
  };

  @action
  updateRecord = (record, { skipStore } = {}) => {
    if (!record) {
      return;
    }

    const { id, __typename } = record;
    const container = this.findOrCreateContainer(__typename);
    const existingIndex = _.findIndex(container.records, { id });

    if (existingIndex !== -1) {
      container.records[existingIndex] = _.merge({}, container.records[existingIndex], record);
    } else {
      // callee is probably adding new records themselves
      if (skipStore) {
        return {
          __typename,
          record,
        };
      } else {
        container.records.push(record);
      }
    }

    container.lastUpdated = new Date();
  };

  @action
  updateRecords = records => {
    if (!records) {
      return;
    }

    let newRecordsByType = {};
    for (const record of records) {
      // TODO: if can get skipStore: true to work, will save a lot of re-renders since we only trigger
      // one update to push all, instead of one update per push
      const { __typename } = record;
      const newRecord = this.updateRecord(record, { skipStore: false });

      // if we got a response, this is a new record
      // keep track of it so that we can push all new records into the container
      // in one go. this minimizes mobx/react re-renders
      if (newRecord) {
        newRecordsByType[__typename] = newRecordsByType[__typename] || [];
        newRecordsByType[__typename].push(newRecord);
      }
    }

    for (const __typename in newRecordsByType) {
      this.pushAllIntoContainer(__typename, newRecordsByType[__typename]);
    }
  };

  updateFromResult = result => {
    _.forEach(result.data, (value, key) => {
      if (_.isArray(value)) {
        this.updateRecords(value);
      } else if (_.isArray(_.get(value, 'nodes'))) {
        this.updateRecords(_.get(value, 'nodes'));
      } else {
        this.updateRecord(value);
      }
    });
  };

  @action
  deleteRecords = (records, { skipStore } = {}) => {
    // TODO: Implement
  };

  @action
  deleteRecord = (record, { skipStore } = {}) => {
    if (!record) {
      return;
    }

    const { id, __typename } = record;
    const container = this.findOrCreateContainer(__typename);
    const existingIndex = _.findIndex(container.records, { id });

    if (existingIndex > -1) {
      // callee is probably removing records themselves
      if (skipStore) {
        return {
          __typename,
          record,
        };
      } else {
        const recordIndex = _.findIndex(container.records, { id });

        if (recordIndex > -1) {
          container.records.splice(recordIndex, 1);
        }
      }
    }

    container.lastUpdated = new Date();
  };

  deleteFromResult = result => {
    _.forEach(result.data, (value, key) => {
      // TODO: This only handles one record right now

      // if (_.isArray(value)) {
      //   this.deleteRecords(value);
      // } else if (_.isArray(_.get(value, 'nodes'))) {
      //   this.deleteRecords(_.get(value, 'nodes'));
      // } else {
      this.deleteRecord(value);
      // }
    });
  };

  createQuery = config => {
    const { key, baseKey, localQueries, query, variables, orderBy, pollInterval } = config;

    const watcher = this.apolloClient.watchQuery({
      query,
      variables,
    });

    return new GqlQuery(this.findOrCreateContainer, watcher, {
      key,
      baseKey,
      variables,
      orderBy,
      localQueries,
      pollInterval,
    });
  };

  request(operation, forward) {
    const observer = forward(operation);

    return observer.map(result => {
      // TODO: We should have a better convention for this
      if (/^(delete|remove)/gi.test(operation.operationName)) {
        this.deleteFromResult(result);
      } else {
        this.updateFromResult(result);
      }

      return result;
    });
  }
}

export default MobxLink;
