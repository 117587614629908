import { IconLibrary } from '@stoplight/ui-kit/Icon';

import { faBook as farBook } from '@fortawesome/pro-regular-svg-icons/faBook';
import { faBook as fasBook } from '@fortawesome/pro-solid-svg-icons/faBook';

import { faBullseye as farBullseye } from '@fortawesome/pro-regular-svg-icons/faBullseye';
import { faBullseye as fasBullseye } from '@fortawesome/pro-solid-svg-icons/faBullseye';

import { faCube as farCube } from '@fortawesome/pro-regular-svg-icons/faCube';
import { faCube as fasCube } from '@fortawesome/pro-solid-svg-icons/faCube';

import { faCloud as farCloud } from '@fortawesome/pro-regular-svg-icons/faCloud';
import { faCloud as fasCloud } from '@fortawesome/pro-solid-svg-icons/faCloud';

import { faCircleNotch as fasCirleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';

import { faServer as farServer } from '@fortawesome/pro-regular-svg-icons/faServer';
import { faServer as fasServer } from '@fortawesome/pro-solid-svg-icons/faServer';

export const initIcons = () => {
  // @ts-ignore
  IconLibrary.add(fasServer, farServer);
  // @ts-ignore
  IconLibrary.add(farBook, fasBook);
  // @ts-ignore
  IconLibrary.add(farCube, fasCube);
  // @ts-ignore
  IconLibrary.add(farBullseye, fasBullseye);
  // @ts-ignore
  IconLibrary.add(farCloud, fasCloud);
  // @ts-ignore
  IconLibrary.add(fasCirleNotch);
};
