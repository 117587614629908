import * as React from 'react';

const cn: any = require('classnames');

import { faCaretRight } from '@fortawesome/pro-light-svg-icons/faCaretRight';

import { Icon, IIcon } from '@core/ui';

export interface IPanelSection {
  title: string;
  renderChildren: () => any;

  id?: string;
  titleIcon?: IIcon;
  titleColor?: string;
  titleBgColor?: string;
  collapsible?: boolean;
  extended?: boolean;
  renderSearch?: () => any;
  actions?: Array<{
    onClick: any;
    icon: any;
  }>;
}

export class PanelSection extends React.Component<IPanelSection> {
  public render() {
    const {
      title,
      titleIcon,
      titleColor = 'white',
      titleBgColor,
      renderSearch,
      renderChildren,
      collapsible,
      extended = true,
    } = this.props;
    const actions = this.props.actions || [];

    // TODO
    const isOpen = true;

    const caretProps = { icon: faCaretRight, transform: {} };
    if (isOpen) {
      caretProps.transform = { rotate: 45, y: 1 };
      caretProps.icon = faCaretRight;
    }

    const showTitle = title || collapsible || titleIcon || titleIcon || actions.length > 0;

    return (
      <div className={cn('flex flex-col', { 'flex-1': extended })}>
        {showTitle && (
          <div
            style={{ height: 31 }}
            className={cn('px-4 opacity-85 flex items-center leading-none', {
              [`text-${titleColor}`]: titleColor,
              [`bg-${titleBgColor}`]: titleBgColor,
              'bg-lighten-100': !titleBgColor,
            })}
          >
            {collapsible && (
              <div className="w-3">
                <Icon icon={caretProps.icon} transform={caretProps.transform} />
              </div>
            )}

            {titleIcon && (
              <div className="text-xs mr-1">
                <Icon icon={titleIcon} />
              </div>
            )}

            <div className="flex-1 text-sm uppercase font-bold">{title}</div>

            {actions.map((action, i) => {
              return (
                <div className="cursor-pointer text-sm" key={i} onClick={action.onClick}>
                  <Icon icon={action.icon} />
                </div>
              );
            })}
          </div>
        )}

        {renderSearch && <div>{renderSearch()}</div>}
        {renderChildren && <div className="flex-1 relative">{renderChildren()}</div>}
      </div>
    );
  }
}
