import React from 'react';
import AutosizeTextarea from 'react-textarea-autosize';
import cn from 'classnames';

import FormInputLabel from '../FormInputLabel';

const FormTextarea = props => {
  const {
    minRows = 3,
    maxRows = 6,
    value,
    placeholder = '',
    onChange,
    bordered,
    className = '',
    label,
    tip,
    required,
    inputStyle = {},
    ...inputProps
  } = props;

  return (
    <div
      className={cn('FormTextarea', className, {
        'is-bordered': bordered,
      })}
    >
      <FormInputLabel label={label} tip={tip} required={required} />

      <AutosizeTextarea
        value={value || ''}
        placeholder={placeholder}
        minRows={minRows}
        maxRows={maxRows}
        onChange={e => {
          onChange(e, { value: e.target.value });
        }}
        style={inputStyle}
        {...inputProps}
      />
    </div>
  );
};

export default FormTextarea;
