import React from 'react';
import _ from 'lodash';
import { deepCompare } from '@platform/utils/hoc';
import { convertParameterToSchema } from '@platform/utils/specs/http';

import SwaggerParameter from '../SwaggerParameter';
import JsonSchemaEditor from '../JsonSchemaEditor';

class SwaggerParameterGroup extends React.Component {
  handleUpdate = (t, p, v, { parameterIndex } = {}) => {
    this.props.handleUpdate(t, [parameterIndex].concat(p), v);
  };

  handleRemove = parameterIndex => {
    this.props.handleUpdate('pull', [], parameterIndex);
  };

  render = () => {
    const {
      rootParameters = {},
      parsed = {},
      parameters = [],
      readOnly,
      blacklist = [],
      extraTypes = [],
      editorId,
      preview,
      id,
    } = this.props;

    if (preview) {
      const properties = {};

      for (const parameter of parameters) {
        properties[_.get(parameter, 'param.name')] = convertParameterToSchema(parameter.param);
      }

      if (_.isEmpty(properties)) {
        return null;
      }

      return (
        <JsonSchemaEditor
          id={id}
          schema={{ properties }}
          editorStore="oas2EditorStore"
          editorId={editorId}
          readOnly={preview}
        />
      );
    }

    return (
      <div className={`SwaggerParameterGroup ${!_.isEmpty(parameters) ? 'has-params' : ''}`}>
        {parameters.map((parameter, i) => (
          <div key={i} className="SwaggerParameterGroup-item">
            <SwaggerParameter
              parsed={parsed}
              $ref={parameter.$ref}
              blacklist={blacklist}
              rootParameters={rootParameters}
              parameterIndex={parameter.index}
              parameter={parameter.param}
              dereferencedParameter={parameter.dereferencedParam}
              readOnly={readOnly}
              preview={preview}
              extraTypes={extraTypes}
              handleUpdate={this.handleUpdate}
              handleRemove={this.handleRemove}
              editorId={editorId}
            />
          </div>
        ))}
      </div>
    );
  };
}

export default deepCompare()(SwaggerParameterGroup);
