import _ from 'lodash';

import { pathToHash } from '@platform/utils/history';
import { DiagnosticSeverity } from '@stoplight/types';
import { safeStringify } from '@platform/utils/json';

export class SpectralRunner {
  SpectralWorker;

  run({ id, apiHost, projectId, ref, file, target, env, strTarget, config, spec, cb }) {
    if (this.SpectralWorker) {
      const now = new Date().getTime();

      this.SpectralWorker.postMessage(
        safeStringify({
          apiHost,
          projectId,
          ref,
          file,
          target,
          config,
          env,
          spec,
        })
      )
        .then(response => {
          console.info('validation time', id, `${new Date().getTime() - now}ms`, {
            response,
          });

          return cb(this.buildValidations(response, spec));
        })
        .catch(error => {
          console.error('SpectralWorker error:', error);
        });
    } else if (typeof window !== 'undefined') {
      import('./index')
        .then(m => {
          this.SpectralWorker = m.default;
          this.run({
            id,
            apiHost,
            projectId,
            ref,
            file,
            target,
            env,
            strTarget,
            config,
            spec,
            cb,
          });
        })
        .catch(err => {
          console.error('Could not load Spectral', err);
        });
    }
  }

  buildValidations = (validations, spec) => {
    if (!validations) return;

    const v = [];

    for (const validation of validations) {
      // Definitions will always be unused since we dereference, so ignore that error
      const { name, summary, message, path, severity } = validation;

      const location = typeof path === 'string' ? path.split('.') : path;

      const severityTransform =
        severity === 0 ? DiagnosticSeverity.Error : DiagnosticSeverity.Warning;

      v.push({
        spec,
        severity: severityTransform,
        message: summary,
        ruleId: name,
        path: location,
        details: message,
        route: {
          query: {
            edit: pathToHash({ path: location.slice(0, 3) }),
            view: undefined,
          },
        },
      });
    }

    return v;
  };
}
