import React from 'react';

import contentRouterFactory from '@platform/utils/content-routers/oas2';
import HubViewer from '@platform/format-hubs/components/Hub/viewer';

import FormInput from '@platform/components/FormInput';
import FormSelect from '@platform/components/FormSelect';
import SwaggerTags from '@platform/components/SwaggerTags';
import SwaggerRoot from '@platform/components/SwaggerRoot';
import SwaggerInfo from '@platform/components/SwaggerInfo';
import SpecCrudBuilder from '@platform/components/SpecCrudBuilder';
import SwaggerSecurity from '@platform/components/SwaggerSecurity';
import EntityEditorHttp from '@platform/components/EntityEditorHttp';
import SwaggerDefinition from '@platform/components/SwaggerDefinition';
import SwaggerOperation from '@platform/components/SwaggerOperation';
import SwaggerParameters from '@platform/components/SwaggerParameters';
import SwaggerRootResponse from '@platform/components/SwaggerRootResponse';
import SwaggerExternalDocs from '@platform/components/SwaggerExternalDocs';
import SwaggerRootParameter from '@platform/components/SwaggerRootParameter';
import SwaggerSecurityDefinition from '@platform/components/SwaggerSecurityDefinition';

import EntityEditor from '../EntityEditor';
import EntityEditorCode from '../EntityEditor/spec';
import EntityEditorWidgets from '../EntityEditor/widgets';

const contentRouterData = contentRouterFactory({
  FormInput,
  FormSelect,
  SwaggerRoot,
  SwaggerInfo,
  SwaggerTags,
  SwaggerRootParameter,
  SwaggerRootResponse,
  SwaggerParameters,
  SwaggerSecurity,
  SwaggerSecurityDefinition,
  SwaggerOperation,
  SwaggerDefinition,
  SwaggerExternalDocs,
});

const contentTabs = [
  {
    id: 'read',
    component: HubViewer,
    propFactory: ({ editor }) => {
      return { store: editor.hubViewerStore };
    },
  },
  {
    id: 'design',
    component: EntityEditorWidgets,
  },
  {
    id: 'code',
    component: EntityEditorCode,
  },
  {
    id: 'http',
    component: EntityEditorHttp,
  },
];

const Oas2Editor = props => {
  const { entity, ...extraProps } = props;

  return [
    <EntityEditor
      key="1"
      entity={entity}
      editorStoreKey="oas2EditorStore"
      contentRouterData={contentRouterData}
      contentTabs={contentTabs}
      {...extraProps}
    />,
    <SpecCrudBuilder key="2" editorId={entity.id} />,
  ];
};

export default Oas2Editor;
