import React from 'react';
import { inject, observer } from 'mobx-react';
import cn from 'classnames';
import { Icon } from 'semantic-ui-react';

import { Link } from '@platform/utils/router';

const AccountFormTab = ({ isActive, to, icon, name, className, routerStore }) => {
  return (
    <div
      className={cn('AccountFormNav-tab flex-1', className, {
        'is-active': isActive,
      })}
    >
      <Link to={{ ...to, query: routerStore.location.query }}>
        <Icon name={icon} /> {name}
      </Link>
    </div>
  );
};

const mapActiveProps = {
  login: { icon: 'power', to: { pathname: '/login' }, name: 'Login' },
  join: { icon: 'heart', to: { pathname: '/join' }, name: 'Join' },
  'forgot-pass': { icon: 'key', to: { pathname: '/users/password/new' }, name: 'Forgot Password' },
  'reset-pass': { icon: 'key', to: { pathname: '/users/password/new' }, name: 'Reset Password' },
};

const AccountFormNav = ({ active, onlyActive, routerStore }) => {
  if (onlyActive) {
    return <AccountFormTab {...mapActiveProps[active]} isActive to={null} />;
  }

  return (
    <div className="AccountFormNav flex">
      <AccountFormTab
        {...mapActiveProps.login}
        routerStore={routerStore}
        isActive={active === 'login'}
      />

      <AccountFormTab
        {...mapActiveProps.join}
        routerStore={routerStore}
        isActive={active === 'join'}
      />

      <AccountFormTab
        {...mapActiveProps[active === 'reset-pass' ? 'reset-pass' : 'forgot-pass']}
        routerStore={routerStore}
        isActive={active === 'forgot-pass' || active === 'reset-pass'}
      />
    </div>
  );
};

export default inject('routerStore')(observer(AccountFormNav));
