import * as React from 'react';

import { Box, Flex } from '@stoplight/ui-kit';

export interface IPagination {
  currentPage: number;
  totalPages: number;
  onClick: (page: string) => void;
}

export interface IPageItemProps {
  page: number;
  content: any;
  active?: boolean;
  disabled?: boolean;

  onClick: (page: string) => void;
}

export const PageItem = ({ page, content, active, disabled, onClick }: IPageItemProps) => {
  return (
    <Box
      p="6px 10px"
      ml="-1px"
      fontWeight={600}
      disabled={disabled || active}
      cursor={disabled || active ? 'not-allowed' : 'pointer'}
      color={disabled ? 'grey' : active ? 'white' : 'rgb(59, 153, 252)'}
      backgroundColor={active ? 'rgb(59, 153, 252)' : 'transparent'}
      borderLeft={content !== '<' && '1px solid lightgrey'}
      onClick={() => {
        if (!disabled && !active && onClick) {
          onClick(page.toString());
        }
      }}
    >
      {content}
    </Box>
  );
};

export const Pagination: React.FunctionComponent<IPagination> = ({
  currentPage = 1,
  totalPages = 1,
  onClick,
}) => {
  let startPage = currentPage - 2;

  if (startPage <= 0) {
    startPage = 1;
  }

  // previous arrow
  const pageItems: IPageItemProps[] = [
    {
      page: currentPage - 1,
      content: '<',
      disabled: currentPage === 1,
      onClick,
    },
  ];

  // ensures we have at most 5 pages and stops at total pages
  const endPage = startPage + 4;
  while (startPage <= endPage && startPage <= totalPages) {
    if (startPage > 0) {
      pageItems.push({
        page: startPage,
        content: startPage,
        active: startPage === currentPage,
        onClick,
      });
    }

    startPage += 1;
  }

  // next arrow
  pageItems.push({
    page: currentPage + 1,
    content: '>',
    disabled: currentPage === totalPages,
    onClick,
  });

  return (
    <Flex display="inline-flex" border="1px solid lightgrey" borderRadius="5px">
      {pageItems.map((item, index) => (
        <React.Fragment key={index}>
          <PageItem {...item} />
        </React.Fragment>
      ))}
    </Flex>
  );
};
