import React from 'react';
import CodeEditor from '@platform/components/CodeEditor';
import FormCheckbox from '@platform/components/FormCheckbox';

const HtmlBlock = props => {
  const { id, WrapperFactory, data = '', config = {}, onChangeData, onChangeConfig } = props;
  const { plain = false } = config;

  const actions = [];
  actions.push(
    <FormCheckbox
      label="Plain"
      tip="Full Width, No Markdown Styles Applied"
      checked={plain}
      onChange={checked => {
        const t = checked ? 'set' : 'unset';
        onChangeConfig(t, ['plain'], checked);
      }}
      inline
    />
  );

  const contentElem = (
    <CodeEditor
      id={id}
      value={data}
      mode={'html'}
      onChange={value => onChangeData('set', [], value)}
      maxLines={20}
      lint={false}
      autogrow
      noFill
    />
  );

  return (
    <WrapperFactory
      {...props}
      headerProps={{ anchor: true }}
      headerActions={actions}
      contentElem={contentElem}
    />
  );
};

export default HtmlBlock;
