import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Menu } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';

import TableList from '@platform/components/TableList';
import ErrorMessage from '@platform/components/ErrorMessage';

import { canUser } from '@platform/utils/acl';
import { alert } from '@platform/utils/alert';

import { colors, Button, Icon, Layout } from '@core/ui';

import OrgCreate from '../../components/OrgCreate';
import PageContent from '../../components/PageContent';

class Migrate extends React.Component {
  componentWillMount() {
    const { orgService, migrationStore, routerStore, userService } = this.props;

    if (!userService.isLoggedIn) {
      const token = _.get(routerStore, 'location.query.token');

      alert.warning('You must be logged in to migrate your projects.');
      routerStore.push(`/login?r=/migrate?token=${token}`);
      return;
    }

    migrationStore.init();
    orgService.find();
  }

  renderNavigationButtons = () => {
    const { orgService, migrationStore, routerStore } = this.props;
    const { orgId, org, isMigrating } = migrationStore;

    if (!orgId) return null;

    return (
      <div className="flex mt-6">
        <Button
          key="back"
          basic
          outlined
          onClick={() => {
            migrationStore.reset();
          }}
          disabled={isMigrating}
        >
          Back
        </Button>

        <Button
          key="next"
          className="ml-4"
          color={colors.primary}
          disabled={isMigrating || orgService.isLoading}
          onClick={() => {
            routerStore.push(`/${org.path}/people?modal=invite`);
          }}
        >
          Finish
        </Button>
      </div>
    );
  };

  renderOrgs = () => {
    const { orgService, migrationStore } = this.props;
    const { isLoading, error, clearError } = orgService;
    const { orgId, createOrg } = migrationStore;

    // remove orgs where user is not the owner
    const orgs = _.filter(orgService.currentList, org => {
      return canUser({ entity: org, action: 'update' });
    });
    const hasOrgs = orgs.length > 0;

    let contentElem;
    if ((!hasOrgs && !isLoading) || (!orgId && createOrg)) {
      contentElem = (
        <OrgCreate
          className="m-0 w-full max-w-full"
          onSuccess={org => {
            migrationStore.fetchOrg(org.id);
          }}
          redirect={false}
          firstTime={!hasOrgs}
        />
      );
    } else {
      contentElem = [
        <ErrorMessage key="error" error={error} onDismiss={clearError} className="mb-6" />,

        <TableList
          key="org-table"
          noSearch
          scope="Migration-org"
          items={orgs}
          loading={isLoading}
          searchProps={['name']}
          actionButton={{
            content: 'New Organization',
            onClick: () => {
              migrationStore.reset();
              migrationStore.createOrg = true;
            },
          }}
          cellFactories={[
            (org, key) => {
              return (
                <td key={key} className="flex items-center px-3 py-3">
                  <Button
                    className="w-32 mr-6"
                    color={colors.primary}
                    onClick={() => {
                      migrationStore.fetchOrg(org.id);
                    }}
                  >
                    Select
                  </Button>

                  <div className="block">
                    <div className="flex items-center font-bold">
                      <div className="mr-3">{org.name || org.id}</div>
                    </div>

                    {org.description && (
                      <small className="flex text-muted">
                        <div>{_.truncate(org.description, { length: 50 })}</div>
                      </small>
                    )}
                  </div>
                </td>
              );
            },
          ]}
        />,
      ];
    }

    return <div>{contentElem}</div>;
  };

  renderProjects = () => {
    const {
      classicWorkspaces,
      failedProjects,
      pendingProjects,
      finishedProjects,
      migratedProjects,
      filteredProjects,
      isFetchingClassic,
      isLoadingOrg,
      filterWorkspaces,
      migrate,
      filteredWorkspaces,
    } = this.props.migrationStore;

    return (
      <div className="flex">
        <div className="flex-grow">
          <TableList
            noSearch
            scope="Migration-project"
            items={filteredProjects}
            loading={isFetchingClassic || isLoadingOrg}
            cellFactories={[
              (project, key) => {
                const isMigrated =
                  _.findIndex(
                    migratedProjects,
                    migratedProject => _.toLower(migratedProjects.name) === _.toLower(project.name)
                  ) !== -1;
                const isFinished = isMigrated || _.includes(finishedProjects, project._id);
                const isPending = _.includes(pendingProjects, project._id);
                const projectError = _.find(
                  failedProjects,
                  failedProject => failedProject._id === project._id
                );
                const isDisabled = isPending || isFinished || projectError;

                let buttonText = 'Migrate';
                if (isPending) {
                  buttonText = (
                    <span>
                      <Icon icon={faSpinner} pulse /> Migrating
                    </span>
                  );
                } else if (isFinished) {
                  buttonText = 'Done';
                } else if (projectError) {
                  buttonText = 'Failed';
                }

                return (
                  <td
                    key={key}
                    className={cn('flex items-center px-3 py-3', {
                      'bg-positive-light': isFinished,
                      'bg-yellow-light': isPending,
                      'bg-red-light': !!projectError,
                    })}
                  >
                    <Button
                      className="w-32 mr-6"
                      color={isDisabled ? colors.disabled : colors.accent}
                      disabled={isDisabled}
                      onClick={() => {
                        if (isDisabled) return;

                        migrate(project._id);
                      }}
                    >
                      {buttonText}
                    </Button>

                    <div className="font-bold">{project.name || project._id}</div>

                    {_.get(project, 'workspace.name') && (
                      <small className="text-muted ml-3">{project.workspace.name}</small>
                    )}

                    {projectError && (
                      <div className="flex-1 ta-r font-bold text-red-darker">
                        {projectError.message}
                      </div>
                    )}
                  </td>
                );
              },
            ]}
          />
        </div>

        <div className="SidebarFilter ml-6">
          <Menu vertical>
            <Menu.Item>
              <Menu.Header className="flex items-center w-full">
                <div className="flex-1">Workspaces</div>
              </Menu.Header>

              <Menu.Menu>
                <Menu.Item
                  key="all"
                  active={_.isEmpty(filteredWorkspaces)}
                  onClick={() => {
                    filterWorkspaces('workspace');
                  }}
                >
                  <div className="SidebarFilter-item flex items-center h-full">
                    <div className="flex-1">All</div>
                  </div>
                </Menu.Item>

                {_.map(_.sortBy(classicWorkspaces, ['name']), (classicWorkspace, i) => {
                  const isActive = _.includes(filteredWorkspaces, classicWorkspace._id);

                  return (
                    <Menu.Item
                      key={i}
                      active={isActive}
                      onClick={() => {
                        filterWorkspaces('workspace', classicWorkspace._id);
                      }}
                    >
                      <div className="SidebarFilter-item flex items-center h-full">
                        <div className="SidebarFilter-itemBadge mr-2 bg-white border">
                          {isActive && <Icon icon={faCheck} />}
                        </div>

                        <div className="flex-1">
                          {classicWorkspace.name || classicWorkspace._id}
                        </div>
                      </div>
                    </Menu.Item>
                  );
                })}
              </Menu.Menu>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  };

  render() {
    const { orgId, org } = this.props.migrationStore;

    let content;
    let subtitle;

    if (orgId) {
      subtitle = `Migrate projects from Stoplight Classic to ${org.name || 'your org'}.`;
      content = this.renderProjects();
    } else {
      subtitle = 'Choose an Org to migrate your Stoplight Classic projects.';
      content = this.renderOrgs();
    }

    return (
      <PageContent>
        <Layout
          header={{
            title: 'Migration Tool',
            subtitle: (
              <div>
                {subtitle}
                {this.renderNavigationButtons()}
              </div>
            ),
          }}
          content={<div className="mt-8">{content}</div>}
        />
      </PageContent>
    );
  }
}

export default inject(({ orgService, migrationStore, userService, routerStore }) => ({
  userService,
  routerStore,
  orgService,
  migrationStore,
}))(observer(Migrate));
