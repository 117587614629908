import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons/faHtml5';
import { faHeading } from '@fortawesome/pro-solid-svg-icons/faHeading';
import { faArchive } from '@fortawesome/pro-solid-svg-icons/faArchive';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt';
import { faPuzzlePiece } from '@fortawesome/pro-solid-svg-icons/faPuzzlePiece';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';

import { DropdownInput, Icon, Popup } from '@core/ui';

import FormCheckbox from '@platform/components/FormCheckbox';
import { alert } from '@platform/utils/alert';
import { getConfigVar } from '@platform/utils/config';
import { buildExportUrl } from '@platform/utils/url';

class RefSearch extends React.Component {
  render() {
    const { value, store, placeholder, onBlur, refType, onItemSelect } = this.props;

    const { id, fileSearchResults, updateFileSearchQuery, computeFileSearchResults } = store;

    return (
      <DropdownInput
        query={value}
        items={fileSearchResults.map(item => {
          return {
            name: _.get(item, 'record.data.path'),
          };
        })}
        onItemSelect={onItemSelect}
        renderInput={attributes => {
          return (
            <div className="flex-1 rounded">
              <input
                {...attributes}
                className="h-lg w-full font-semibold px-4 text-darken-600 hover:text-white focus:text-white bg-transparent hover:bg-darken-100 focus:bg-darken-200"
                placeholder={placeholder}
                onClick={e => {
                  computeFileSearchResults({ refType });
                }}
                onChange={e => {
                  // remove exporter host and relative path (./)
                  const [projectId, branch, filePath] = _.split(id, ':');
                  const reg = new RegExp(`./|${buildExportUrl({ projectId, branch, filePath })}`);

                  updateFileSearchQuery({ value: _.replace(e.target.value, reg, ''), refType });
                  attributes.onChange(e, attributes);
                }}
                onBlur={e => {
                  onBlur(e);
                  attributes.onBlur(e);
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

const InjectedRefSearch = inject(({ hubEditorStore }) => {
  return {
    store: hubEditorStore.activeEditor,
  };
})(observer(RefSearch));

class PageSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.computeState(props);
  }

  componentWillUpdate(nextProps, nextState) {
    const id = _.get(nextProps, 'store.currentPage.id');

    if (id !== nextState.id) {
      this.setState(this.computeState(nextProps));
    }
  }

  computeState(props) {
    const pathLeaf = _.last(_.get(props, 'store.currentPage.viewPath', []));
    const refType = _.get(props, 'store.currentPage.refType');

    return {
      id: _.get(props, 'store.currentPage.id'),
      route: `/${pathLeaf || ''}`,
      refType,
    };
  }

  refType = () => {
    const { currentPage } = this.props.store;
    const { refType } = this.state;

    return refType || currentPage.refType;
  };

  updatePageType = name => {
    const { store } = this.props;
    const { currentPage, updateFileSearchQuery } = store;

    if (!currentPage) return;

    if (!name && currentPage.ref) {
      const c = confirm(
        `Changing to an embedded page will remove the current reference to '${
          currentPage.ref
        }' (however, you can always change it back). Continue?`
      );

      if (!c) return;

      store.updateParsed('unset', currentPage.jsonPath.concat(['settings']));
      store.updateParsed('unset', currentPage.jsonPath.concat(['config', 'includeDownloadLink']));
      store.updateParsed('set', currentPage.jsonPath.concat(['data']), { blocks: [] });
      store.computeTreeData();
    }

    updateFileSearchQuery({ refType: name });

    this.setState({
      refType: name,
    });
  };

  renderPageType = ({ id, icon, control, name, tip, isActive, className, onClick }) => {
    return (
      <Popup
        posX="left"
        posY="center"
        hideDelay={0}
        offset={{
          right: -5,
        }}
        renderTrigger={attributes => {
          return (
            <div
              {...attributes}
              className={cn('h-lg flex items-center font-semibold rounded', className, {
                'text-white bg-primary cursor-default': isActive,
                'text-darken-600 opacity-60 bg-darken-50 hover:opacity-100 cursor-pointer': !isActive,
              })}
              onClick={() => {
                if (onClick) {
                  onClick();
                } else {
                  if (isActive) return;
                  this.updatePageType(id);
                }
              }}
            >
              <div
                className={cn('h-lg w-lg text-sm flex items-center justify-center bg-darken-50', {
                  'text-white': isActive,
                })}
              >
                {control}
                {icon && <Icon icon={icon} />}
              </div>

              <div className="flex-1 px-4">{name}</div>
            </div>
          );
        }}
        renderContent={attributes => {
          return (
            <div {...attributes} className="bg-white text-muted rounded p-2 shadow">
              {tip}
            </div>
          );
        }}
      />
    );
  };

  renderColumnTitle = ({ title }) => {
    return <div className="font-extrabold mb-3 ml-1 text-darken-500">{title}</div>;
  };

  renderInputRow = props => {
    const {
      value,
      placeholder = '',
      icon,
      tip,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      prefix,
      suffix,
      input,
      className,
    } = props;

    return (
      <div className={cn('flex items-center bg-darken-50', className)}>
        <Popup
          posX="left"
          posY="bottom"
          hideDelay={0}
          offset={{
            top: -8,
          }}
          renderTrigger={attributes => {
            return (
              <div
                {...attributes}
                className="h-lg w-lg text-sm text-darken-500 flex items-center justify-center bg-darken-50"
              >
                <Icon icon={icon} />
              </div>
            );
          }}
          renderContent={attributes => {
            return (
              <div {...attributes} className="bg-white text-muted rounded p-2 shadow">
                {tip || placeholder}
              </div>
            );
          }}
        />

        {prefix}

        {input ? (
          input(props)
        ) : (
          <div className="flex-1 rounded">
            <input
              className="h-lg w-full font-semibold px-4 text-darken-600 hover:text-white focus:text-white bg-transparent hover:bg-darken-100 focus:bg-darken-200"
              value={value || ''}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              disabled={disabled}
            />
          </div>
        )}

        {suffix}
      </div>
    );
  };

  render() {
    const { store } = this.props;
    const { route } = this.state;
    const { currentPage } = store;

    const refType = this.refType();
    if (currentPage) {
      const basePath = currentPage.viewPath.slice(0, -1);
      const leafPath = currentPage.viewPath[currentPage.viewPath.length - 1] || '/';
      const includeDownloadLink = _.get(currentPage.config, 'includeDownloadLink', false);

      return (
        <div className="PageSettings flex flex-1">
          <div className="flex flex-col flex-1">
            {this.renderColumnTitle({ title: 'Page Settings' })}
            <div className="flex">
              <div className="f-1">
                {this.renderInputRow({
                  value: currentPage.title,
                  placeholder: 'Page title',
                  icon: faHeading,
                  tip: 'A short title for your page',
                  className: 'mb-3',
                  onChange: e => {
                    store.updateParsed(
                      'set',
                      currentPage.jsonPath.concat(['title']),
                      e.target.value
                    );
                  },
                  onBlur: e => {
                    store.computeTreeData();
                  },
                })}
              </div>

              <div className="ml-2">
                {this.renderInputRow({
                  value: _.get(currentPage, 'config.sidebar.token') || '',
                  placeholder: 'Page tag',
                  icon: faTag,
                  tip: 'An optional tag to show in the sidebar, when relevant',
                  className: 'mb-3',
                  onChange: e => {
                    store.updateParsed(
                      'set',
                      currentPage.jsonPath.concat(['config', 'sidebar', 'token']),
                      e.target.value
                    );
                  },
                })}
              </div>
            </div>

            {this.renderInputRow({
              value: route,
              placeholder: 'Relative or absolute url path',
              tip:
                leafPath === '/'
                  ? 'The root (/) page path cannot be changed. Add another page instead.'
                  : 'The URL that this page should live at, for example `/introduction`',
              icon: faLink,
              disabled: leafPath === '/',
              className: 'mb-3',
              onChange: e => {
                this.setState({ route: e.target.value.trim() || '/' });
              },
              onKeyPress: e => {
                const keycode = e.key;
                if (keycode === ' ') {
                  e.preventDefault();
                }
              },
              onBlur: e => {
                const value = e.target.value;

                if (value === '/' && _.has(store.parsed, ['pages', '/'])) {
                  alert.error('Pages must have a path, for example `/introduction`');
                  return;
                }

                const slashCount = (value.match(/\//g) || []).length;
                if (slashCount > 1) {
                  alert.error(
                    'Page paths can only have one slash (/) in them. You can nest pages inside of pages to create deep routes.'
                  );
                  return;
                }

                const updated = store.updatePageRoute({
                  page: currentPage,
                  value,
                });

                if (updated && currentPage.type === 'page') {
                  // navigate to new page
                  store.goToPath(['pages', value], { replace: true });
                }
              },
              prefix: basePath.length ? (
                <div className="h-lg px-4 cursor-default font-semibold text-darken-500 flex items-center bg-darken-50">
                  <div className="truncate" style={{ maxWidth: 100 }}>{`/${basePath.join(
                    '/'
                  )}`}</div>
                </div>
              ) : (
                undefined
              ),
            })}

            {refType &&
              this.renderInputRow({
                value: currentPage.ref,
                placeholder: `target ${refType} file to render in this page`,
                icon: faExternalLink,
                input: ({ value, placeholder }) => {
                  const updatePageRef = newRef => {
                    const updated = store.updatePageRef({
                      page: currentPage,
                      value: newRef,
                    });

                    if (updated) {
                      store.computeTreeData();
                    }

                    return updated;
                  };

                  return (
                    <InjectedRefSearch
                      value={value}
                      placeholder={placeholder}
                      refType={refType}
                      onItemSelect={item => {
                        return updatePageRef(item.name);
                      }}
                      onBlur={e => {
                        updatePageRef(e.target.value);
                      }}
                    />
                  );
                },
              })}
          </div>

          <div className="ml-4 flex flex-col">
            {this.renderColumnTitle({ title: 'Page Type' })}

            <div className="flex flex-1">
              <div>
                {this.renderPageType({
                  id: null,
                  icon: faArchive,
                  name: 'Embedded',
                  isActive: !refType,
                  tip: "Author this page's content directly",
                  className: 'mb-3',
                })}

                {this.renderPageType({
                  id: 'modeling',
                  icon: faPuzzlePiece,
                  name: 'OpenAPI',
                  isActive: refType === 'modeling',
                  tip: 'Power this page with an OpenAPI (Swagger) file',
                  className: 'mb-3',
                })}
              </div>

              <div className="ml-3">
                {this.renderPageType({
                  id: 'markdown',
                  icon: faPencilAlt,
                  name: 'Markdown',
                  isActive: refType === 'markdown',
                  tip: 'Power this page with a markdown file',
                  className: 'mb-3',
                })}

                {this.renderPageType({
                  id: 'html',
                  icon: faHtml5,
                  name: 'HTML',
                  isActive: refType === 'html',
                  tip: 'Power this page with an html file',
                  className: 'mb-3',
                })}
              </div>
            </div>

            {refType === 'modeling' &&
              this.renderPageType({
                control: <FormCheckbox checked={includeDownloadLink} inline />,
                onClick: () => {
                  store.updateParsed(
                    includeDownloadLink ? 'unset' : 'set',
                    currentPage.jsonPath.concat(['config', 'includeDownloadLink']),
                    true
                  );
                },
                name: 'Include Download Link?',
                isActive: includeDownloadLink,
                tip:
                  'Add a download link in your published documentation for this OpenAPI (Swagger).',
              })}
          </div>
        </div>
      );
    }

    return (
      <div className="text-center flex-1">
        <div className="text-2xl font-bold">Hub Table of Contents</div>
        <div className="text-darken mt-2 font-semibold text-lg">Select or add a page</div>
      </div>
    );
  }
}

export default inject(({ hubEditorStore }) => {
  return {
    store: hubEditorStore.activeEditor,
  };
})(observer(PageSettings));
