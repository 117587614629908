import React from 'react';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';

import { Icon } from '@core/ui';

import UserInterests from './userInterests';
import UserProfile from './userProfile';
import OrgCreate from './orgCreate';
import OrgDone from './orgDone';

import ProjectCreate from '../ProjectCreate';

const OnboardingStep = ({ isActive, isCompleted, number, name }) => {
  return (
    <div className="flex items-center font-bold">
      <div
        className={cn('flex items-center justify-center rounded-full w-8 h-8 text-lg', {
          'bg-white text-blue': isCompleted || isActive,
          'bg-darken-100 opacity-75': !isCompleted && !isActive,
        })}
      >
        {isCompleted ? <Icon icon={faCheck} /> : number}
      </div>

      <div className="ml-3">{name}</div>
    </div>
  );
};

const OnboardingHeader = ({ activeStep = {} }) => {
  return (
    <div className="bg-blue-light text-white py-10 px-12 rounded-t-lg">
      <div className="text-3xl font-semibold">Welcome to Stoplight</div>

      <div className="mt-6">
        <div className="flex">
          <OnboardingStep
            isActive={activeStep.number === 1}
            isCompleted={activeStep.number > 1}
            number={1}
            name="Complete profile"
          />

          <div className="flex items-center mx-10 text-2xl">
            <Icon icon={faLongArrowRight} />
          </div>

          <OnboardingStep
            isActive={activeStep.number === 2}
            isCompleted={activeStep.number > 2}
            number={2}
            name="Setup your organization"
          />

          <div className="flex items-center mx-10 text-2xl">
            <Icon icon={faLongArrowRight} />
          </div>

          <OnboardingStep
            isActive={activeStep.number === 3}
            isCompleted={activeStep.number > 3}
            number={3}
            name="Create a project"
          />
        </div>
      </div>
    </div>
  );
};

const stepComponents = {
  profile: UserProfile,
  interests: UserInterests,
  'org-create': OrgCreate,
  'org-done': OrgDone,
  'project-create': ProjectCreate,
};

const Onboarding = ({ onboardingStore }) => {
  const ActiveComponent = stepComponents[onboardingStore.activeStep.id] || stepComponents.profile;

  return (
    <div className="w-100 mx-auto my-32 rounded-lg shadow" style={{ maxWidth: 800 }}>
      <ActiveComponent
        header={<OnboardingHeader activeStep={onboardingStore.activeStep} />}
        onSuccess={onboardingStore.goToNextStep}
      />
    </div>
  );
};

export default inject(({ onboardingStore }) => {
  return {
    onboardingStore,
  };
})(observer(Onboarding));
