import _ from 'lodash';
import { routeData } from '../json/routeData';

const oas2RefRouterRules = {
  paths: {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'paths',
    matcher({ parentPath = [], key }) {
      return parentPath[0] === 'paths';
    },
    dataFactory({ key }) {
      return { name: `${key}` };
    },
  },
  operations: {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'paths',
    matcher({ parentPath = [], key }) {
      return parentPath[0] === 'paths' && parentPath.length == 2 && key != 'parameters';
    },
    dataFactory({ parentPath, key, value }) {
      let name = '';

      if (value.summary) {
        name += `${value.summary} - `;
      }

      name += `${_.toUpper(key)} ${_.last(parentPath)}`;

      return { name };
    },
  },
  definitions: {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'definitions',
    matcher({ parentPath = [] }) {
      return parentPath[0] === 'definitions';
    },
    dataFactory({ key, value }) {
      if (_.has(value, 'title')) {
        return { name: value['title'] };
      } else {
        return { name: key };
      }
    },
  },
  responses: {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'responses',
    matcher({ parentPath = [], key }) {
      return parentPath[0] === 'responses';
    },
    dataFactory({ key }) {
      return { name: key };
    },
  },
  parameters: {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'parameters',
    matcher({ parentPath = [], key }) {
      return parentPath[0] === 'parameters';
    },
    dataFactory({ key }) {
      return { name: key };
    },
  },

  headers: {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'parameters',
    matcher({ parentPath = [], key, value }) {
      return parentPath[0] === 'parameters' && key === 'in' && value === 'header';
    },
    dataFactory({ parentPath }) {
      return { name: _.last(parentPath), path: parentPath };
    },
  },

  'query parameters': {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'parameters',
    matcher({ parentPath = [], key, value }) {
      return parentPath[0] === 'parameters' && key === 'in' && value === 'query';
    },
    dataFactory({ parentPath }) {
      return { name: _.last(parentPath), path: parentPath };
    },
  },

  'path parameters': {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'parameters',
    matcher({ parentPath = [], key, value }) {
      return parentPath[0] === 'parameters' && key === 'in' && value === 'path';
    },
    dataFactory({ parentPath }) {
      return { name: _.last(parentPath), path: parentPath };
    },
  },

  'formData parameters': {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'parameters',
    matcher({ parentPath = [], key, value }) {
      return parentPath[0] === 'parameters' && key === 'in' && value === 'formData';
    },
    dataFactory({ parentPath }) {
      return { name: _.last(parentPath), path: parentPath };
    },
  },
};
export const oas2RefRouter = ({ spec = {}, targets = [] } = {}) => {
  const rules = _.isEmpty(targets) ? oas2RefRouterRules : _.pick(oas2RefRouterRules, targets);
  return routeData({ data: spec, rules });
};
