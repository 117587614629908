import gql from 'graphql-tag';

const config = gql`
  fragment config on DocConfig {
    integrations {
      googleAnalytics
      segment
      intercom
      gtm
    }
    auth {
      title
      subtitle
      buttonText
      basic
      saml {
        entryPoint
      }
      auth0 {
        clientSecret
        entryPoint
      }
    }
    redirects {
      to
      from
    }
    filePath
    basePath
    customCSS
    customJS
    whitelabel
    variables
  }
`;

const docBuild = gql`
  fragment docBuild on DocBuild {
    id
    docId
    appVersion
    createdAt
    status {
      code
      message
    }
    config {
      ...config
    }
  }
  ${config}
`;

// Note doc has everything except liveBuildId
const doc = gql`
  fragment doc on Doc {
    id
    projectId
    domain
    createdAt
    updatedAt
    config {
      ...config
    }
  }
  ${config}
`;

const pageInfo = gql`
  fragment pageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;

export const docs = {
  info: gql`
    query DocInfo($id: Int, $domain: String) {
      doc(id: $id, domain: $domain) {
        ...doc
        liveBuildId
      }
    }
    ${doc}
  `,

  list: gql`
    query DocList($projectId: Int!, $after: String, $orderBy: DocsOrder) {
      docs(projectId: $projectId, after: $after, orderBy: $orderBy) {
        nodes {
          ...doc
          liveBuildId
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${doc}
    ${pageInfo}
  `,

  create: gql`
    mutation DocCreate($input: CreateDocInput) {
      createDoc(input: $input) {
        ...doc
      }
    }
    ${doc}
  `,

  delete: gql`
    mutation DeleteDoc($id: Int, $domain: String) {
      deleteDoc(id: $id, domain: $domain) {
        ok
        doc {
          ...doc
        }
      }
    }
    ${doc}
  `,

  update: gql`
    mutation UpdateDoc($id: Int, $domain: String, $input: UpdateDocInput) {
      updateDoc(id: $id, domain: $domain, input: $input) {
        ...doc
        liveBuildId
      }
    }
    ${doc}
  `,
};

// Doc Builds
export const docBuilds = {
  info: gql`
    query DocBuildInfo($id: Int, $domain: String) {
      docBuild(id: $id, domain: $domain) {
        ...docBuild
      }
    }
    ${docBuild}
  `,

  download: gql`
    query DocBuildDownload($id: Int, $domain: String) {
      docBuildDownload(id: $id, domain: $domain) {
        build
      }
    }
  `,

  list: gql`
    query DocBuildList($docId: Int!, $after: String, $orderBy: DocBuildsOrder) {
      docBuilds(docId: $docId, after: $after, orderBy: $orderBy) {
        nodes {
          ...docBuild
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${docBuild}
    ${pageInfo}
  `,

  create: gql`
    mutation DocBuildCreate($docId: Int, $domain: String, $input: CreateDocBuildInput) {
      createDocBuild(docId: $docId, domain: $domain, input: $input) {
        ...docBuild
      }
    }
    ${docBuild}
  `,

  update: gql`
    mutation DocBuildUpdate($id: Int, $domain: String, $input: UpdateDocBuildInput) {
      updateDocBuild(id: $id, domain: $domain, input: $input) {
        ...docBuild
      }
    }
    ${docBuild}
  `,
};
