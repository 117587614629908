import compact = require('lodash/compact');
import get = require('lodash/get');
import isEmpty = require('lodash/isEmpty');
import noop = require('lodash/noop');
import parseInt = require('lodash/parseInt');

import { observer } from 'mobx-react';
import * as React from 'react';
import TimeAgo from 'react-timeago';

import { Box, Flex, IBox, Icon, Input } from '@stoplight/ui-kit';

import MarkdownViewer from '@platform/components/MarkdownViewer';
import { Link } from '@platform/utils/router';

import { useTheme } from '../config/theme';
import { INode, IStore } from '../types';
import { nodeData } from '../utils';

import { Loader } from './Loader';
import { Pagination } from './Pagination';

/**
 * SEARCH
 */
export interface ISearch extends IBox {
  store?: IStore;
}

export const Search: React.FunctionComponent<ISearch> = observer((props: ISearch) => {
  const { store = {} as IStore, ...boxProps } = props;
  const {
    updateSearchQuery = noop,
    isSearching,
    nodes,
    query = {},
    pageInfo = {} as any,
  } = store as IStore;
  const { search = '', page = '' } = query;
  const { totalPages = 1 } = pageInfo;

  return (
    <Box maxWidth={1000} height="100%" {...boxProps}>
      <Input
        placeholder="Search..."
        value={search}
        onChange={e => {
          updateSearchQuery(e.currentTarget.value, 'search');
          updateSearchQuery(undefined, 'page');
        }}
        mb="30px"
        width="100%"
      />

      <Box>
        {isSearching && <Loader />}

        {isEmpty(nodes) && !isSearching && <Box textAlign="center">No Results.</Box>}

        {!isEmpty(nodes) &&
          !isSearching &&
          nodes.map((node: INode, index: number) => (
            <SearchItem key={index} orgId={store.groupId} {...node} />
          ))}

        {totalPages > 1 &&
          !isSearching && (
            <Box mt="20px" textAlign="center">
              <Pagination
                currentPage={parseInt(page) || 1}
                totalPages={totalPages}
                onClick={page => updateSearchQuery(page, 'page')}
              />
            </Box>
          )}
      </Box>
    </Box>
  );
});

/**
 * SEARCH ITEM
 */

export interface ISearchItem extends INode {
  orgId?: number;
}

export const SearchItem: React.FunctionComponent<ISearchItem> = props => {
  const { name, summary, org, project, file, updatedAt, orgId } = props;
  const { label, icon, color, editorUrl } = nodeData(props);

  const meta = compact([
    // don't display org if we are currently inside of this org
    String(orgId) !== String(org.id) ? org.name : null,
    project.name,
    file,
    get(props.meta, 'path'),
  ]);

  const { card, fontSize, fontWeight } = useTheme();

  return (
    <Flex
      as={Link}
      to={{ pathname: editorUrl }}
      p="20px 15px"
      width="100%"
      flexDirection="column"
      justifyContent="center"
      overflow="hidden"
      boxSizing="border-box"
      backgroundColor={card.bg}
      borderBottom={`1px solid ${card.border}`}
      cursor="pointer"
      css={{
        ':hover': {
          backgroundColor: 'rgb(247, 247, 247)',
        },
      }}
    >
      <Flex alignItems="center" mb="3px">
        <Box color={card.title} fontSize={fontSize.lg} fontWeight={fontWeight.semibold} mr="10px">
          {name}
        </Box>

        <Flex
          p="3px 6px"
          alignItems="center"
          justifyContent="center"
          borderRadius="999px"
          color="white"
          backgroundColor={color}
          fontSize={fontSize.sm}
        >
          <Icon icon={icon} color="white" mr="3px" />
          <Box>{label}</Box>
        </Flex>
      </Flex>

      {summary && (
        <Box p="10px" m="15px 0px 5px 0px" color={card.subtitle} borderLeft="3px solid #ddd">
          <MarkdownViewer value={summary} style={{ fontSize: fontSize.base }} />
        </Box>
      )}

      <Flex mt="15px" fontSize={fontSize.sm} color={card.subtitle}>
        <Box flex={1}>{meta.join(' · ')}</Box>
        <Box>
          updated <TimeAgo date={updatedAt} minPeriod={60} />
        </Box>
      </Flex>
    </Flex>
  );
};
