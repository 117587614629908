import { IDisposable } from '@core/disposable';
import { colors } from '@core/ui';
import { IIcon } from '@core/ui';

export const SYMBOLS = {
  MenuRegistry: 'MenuRegistry',
};

export interface IMenuNodeOpts {
  label?: string;
  description?: string;
  color?: colors;
  icon?: IIcon;
  commandId?: string;

  data?: () => IMenuNodeOpts;
}

export interface IMenuNode extends IMenuNodeOpts {
  id: string;
  active?: boolean;
  order?: string;
  children?: ReadonlyArray<IMenuNode>;
}

export interface IMenuAction extends IMenuNode {
  commandId: string;
  loading?: boolean;
  disabled?: boolean;
  toggled?: boolean;
}

export interface IActionMenuNode extends IMenuAction {
  // execute the command associated with this action
  execute(...args: any[]): Promise<any | void>;
  isActive?(...args: any[]): boolean;
}

export interface IMenuRegistry {
  registerMenuAction(menuPath: MenuPath, item: IMenuAction): IDisposable;
  registerSubmenu(menuPath: MenuPath, opts?: IMenuNodeOpts): IDisposable;
  getMenu(menuPath: MenuPath): IMenuNode;
  updateActionsForCommandId(commandId: string, transformer: (node: IActionMenuNode) => void): void;
}

export type MenuPath = string[];
