import { types } from 'mobx-state-tree';
import _ from 'lodash';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/projects/:projectId/members',
      perPage: 100,
    })
    .views(self => {
      return {
        get currentList() {
          const currentProject = _.get(env.rootStore, 'stores.projectService.current');
          if (!currentProject) {
            return;
          }

          const q = {
            query: {
              project_id: String(currentProject.id),
            },
          };

          return self.findLocal(q).get();
        },
      };
    })
    .actions(self => {
      return {};
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('ProjectMemberService');

  return Service.create(data, env);
};
