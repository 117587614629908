import React from 'react';

import { sizes, Icon } from '@core/ui';

const SimpleSection = props => {
  const { className, title, children, icon, actions } = props;

  return (
    <div className={className}>
      <div className="flex items-center ml-1 mb-4">
        {icon && (
          <div className="w-6 h-6 flex items-center justify-center rounded-full bg-grey-dark text-white mr-2">
            <Icon icon={icon} size={sizes.sm} />
          </div>
        )}

        <div className="font-bold text-muted uppercase flex-1">{title}</div>

        {actions}
      </div>

      {children}
    </div>
  );
};

export default SimpleSection;
