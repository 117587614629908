import defaultAxios from 'axios';
import _ from 'lodash';
import { observable, action, computed, flow } from 'mobx';
import { getConfigVar } from '@platform/utils/config';

export default class MigrationStore {
  rootStore;
  request;
  token;

  @observable
  orgId;

  @observable
  createOrg;

  @observable
  org = {};

  @observable
  filteredWorkspaces = [];

  @observable
  classicWorkspaces = [];

  @observable
  classicProjects = [];

  @observable
  orgProjects = [];

  @observable
  nextProjects = [];

  @observable
  finishedProjects = [];

  @observable
  pendingProjects = [];

  @observable
  failedProjects = [];

  @observable
  isFetchingClassic;

  @observable
  isLoadingOrg;

  @observable
  isMigrating;

  @observable
  errorMessage;

  constructor({ rootStore }) {
    this.rootStore = rootStore;

    this.request = defaultAxios.create({
      baseURL: getConfigVar('SL_MIGRATION_HOST'),
      timeout: 120000,
    });
  }

  @action
  init = () => {
    this.token = _.get(this.rootStore.stores.routerStore, 'location.query.token');
    this.reset();
    this.fetchClassic();
  };

  @action
  reset = () => {
    this.orgId = null;
    this.createOrg = null;
    this.org = {};

    this.filteredWorkspaces = [];
    this.orgProjects = [];
    this.nextProjects = [];
    this.finishedProjects = [];
    this.pendingProjects = [];
    this.failedProjects = [];

    this.isFetchingClassic = false;
    this.isLoadingOrg = false;
    this.isMigrating = false;
    this.errorMessage = null;
  };

  @computed
  get migratedProjects() {
    const projects = this.nextProjects || [];

    _.forEach(this.orgProjects, orgProject => {
      const alreadyMigrated = _.find(
        this.classicProjects,
        classicProject => _.toUpper(orgProject.name) === _.toUpper(classicProject.name)
      );

      if (alreadyMigrated) {
        projects.push(orgProject);
      }
    });

    return projects;
  }

  @computed
  get filteredProjects() {
    let projects = [];

    _.forEach(this.classicProjects, project => {
      const workspace = _.find(
        this.classicWorkspaces,
        workspace => workspace._id === project.workspace
      );

      if (
        _.includes(this.filteredWorkspaces, workspace._id) ||
        _.isEmpty(this.filteredWorkspaces)
      ) {
        projects.push({ ...project, workspace });
      }
    });

    return _.sortBy(projects, p => _.toUpper(p.name));
  }

  fetchOrg = flow(function* fetchOrg(orgId) {
    this.createOrg = null;
    this.errorMessage = null;
    this.isLoadingOrg = true;
    this.orgId = orgId;

    const promises = [
      this.rootStore.stores.projectService
        .find({
          namespaceType: 'orgs',
          namespaceId: this.orgId,
        })
        .then(res => {
          this.orgProjects = res;
        }),

      this.rootStore.stores.orgService.get(this.orgId).then(res => {
        this.org = res;
      }),
    ];

    try {
      yield Promise.all(promises);
    } catch (error) {
      this.errorMessage = 'Error loading organization data.';
    }

    this.isLoadingOrg = false;
  }).bind(this);

  fetchClassic = flow(function* fetchClassic() {
    this.isFetchingClassic = true;
    this.errorMessage = null;

    try {
      const { data = {} } = yield this.request.get('/utilities/fetch-classic-projects', {
        params: {
          classicAPIHost: 'http://api.stoplight.io',
          token: this.token,
        },
      });

      this.classicWorkspaces = data.WORKSPACE;
      this.classicProjects = data.PROJECT;
    } catch (error) {
      this.errorMessage = 'Error loading projects.';
    }

    this.isFetchingClassic = false;
  }).bind(this);

  migrate = flow(function* migrate(projectId) {
    if (_.includes(this.pendingProjects, projectId)) return;

    this.isMigrating = true;
    this.failedProjects = _.without(this.failedProjects, projectId);
    this.pendingProjects.push(projectId);

    try {
      const { data } = yield this.request.get('/scenarios/docs', {
        params: {
          project: projectId,
          orgId: this.orgId,
          token: this.token,
        },
        headers: {
          'Session-Cookie': _.get(this.rootStore.stores, 'userService.sessionCookie'),
        },
      });

      if (data.id) {
        this.nextProjects.push(data);
        this.finishedProjects.push(projectId);
      } else if (_.get(data.response, 'body')) {
        this.failedProjects.push({
          _id: projectId,
          message: `Error: ${data.response.body}`,
        });
      }
    } catch (error) {
      this.failedProjects.push({
        _id: projectId,
        message: `Error: ${_.get(error, 'response.data.message') || error.message}`,
      });
    }

    this.pendingProjects = _.without(this.pendingProjects, projectId);
    this.isMigrating = this.pendingProjects.length > 0;
  }).bind(this);

  @action
  filterWorkspaces = (type, id) => {
    if (id) {
      const existing = _.indexOf(this.filteredWorkspaces, id) !== -1;

      if (existing) {
        this.filteredWorkspaces = _.without(this.filteredWorkspaces, id);
        return;
      }

      this.filteredWorkspaces.push(id);
      return;
    }

    this.filteredWorkspaces = [];
  };
}
