import React from 'react';

import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';

import FeatureBadge from '@platform/components/FeatureBadge';

import { hasSubscriptionFeature, getTrial } from '@platform/utils/billing';

import { Icon } from '@core/ui';

const AccordianTitle = props => {
  const { title, checked, feature, namespace = {} } = props;

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        {title} {checked && <Icon className="ml-2" icon={faCheckCircle} />}
      </div>

      {feature && (
        <FeatureBadge
          feature={feature}
          show={!hasSubscriptionFeature(namespace, feature) || getTrial(namespace.subscription) > 0}
          namespace={namespace}
        />
      )}
    </div>
  );
};

export default AccordianTitle;
