import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

import LearnMore from '@platform/components/LearnMore';

import { alert } from '@platform/utils/alert';

import { colors, sizes, Button, TextField } from '@core/ui';

const VersionCreateComponent = props => {
  const { ui, updateUi, routerStore, projectService, versionService } = props;

  let [major = '1', minor = '0'] = _.split(versionService.nextAvailableVersion, '.');

  if (ui.hasOwnProperty('major')) {
    major = ui.major;
  }

  if (ui.hasOwnProperty('minor')) {
    minor = ui.minor;
  }

  const version = `${major}.${minor}`;
  const valid = typeof parseFloat(version) === 'number' && major && minor;

  return (
    <div className="px-3 py-4">
      <div>Create a new version for breaking or significant API/product changes.</div>

      <div className="flex items-end py-4">
        <TextField
          className="w-1/4"
          value={major}
          onChange={(e, { value }) => {
            // only allow up to three places and must contain a number 0 thru 9
            const reg = /^[0-9]{0,3}$/;
            if (!reg.test(value)) return;

            updateUi('set', 'major', value);
          }}
          placeholder="1"
          error={!isFinite(major) || !major}
          disabled={versionService.isCreating}
          outlined
          fluid
        />

        <div className="px-1 font-bold text-lg">.</div>

        <TextField
          className="w-1/4"
          value={minor.toString()}
          onChange={(e, { value }) => {
            // only allow up to three places and must be a number 0 thru 9
            const reg = /^[0-9]{0,3}$/;
            if (!reg.test(value)) return;

            updateUi('set', 'minor', value);
          }}
          placeholder="0"
          error={!isFinite(minor) || !minor}
          disabled={versionService.isCreating}
          outlined
          fluid
        />
      </div>

      <div className="flex items-center">
        <Button
          icon={faPlus}
          loading={versionService.isCreating}
          disabled={versionService.isCreating || !valid}
          onClick={() => {
            if (versionService.isCreating) return;

            if (!valid) {
              alert.error('Invalid version format: must have at least one decimal ex. 1.0');
              return;
            }

            const { id, path_with_namespace } = projectService.current || {};

            const encodedVersion = encodeURIComponent(`version/${version}`);

            versionService
              .create({ branch: version }, { projectId: id })
              .then(res => {
                alert.success(`Version ${version} created.`);
                routerStore.push(`/${path_with_namespace}/${encodedVersion}`);
              })
              .catch(err =>
                alert.error(
                  versionService.error.message || 'Something went wrong while creating the version.'
                )
              );
          }}
          color={valid ? colors.accent : colors.negative}
          size={sizes.sm}
        >
          Version
        </Button>

        <LearnMore className="ml-a pl-2 text-sm" feature="versioning" />
      </div>
    </div>
  );
};

export const VersionCreate = inject(stores => {
  const { appStore, routerStore, versionService, projectService } = stores;

  return {
    versionService,
    projectService,
    routerStore,
    ...appStore.injectUi('VersionPanel'),
  };
})(observer(VersionCreateComponent));
