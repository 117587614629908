import _ from 'lodash';
import produce from 'immer';
import { observable, computed, action, reaction, toJS, flow } from 'mobx';

import { Collection as DisposableCollection } from '@core/disposable';

import { isValidJSON } from '@platform/utils/schemas/helpers';

import { MODES as EDITOR_MODES } from '@core/editor/store';

import GoogleAnalytics from '@platform/utils/googleAnalytics';
import { alert } from '@platform/utils/alert';
import { getConfigVar } from '@platform/utils/config';
import { isExternalLink } from '@platform/utils/url';
import { route } from '@platform/utils/objectRouter';
import { modeObjs } from '@platform/utils/codeMirror';
import { registerLogger } from '@platform/utils/logging';
import { replaceVariables } from '@platform/utils/variables';
import { buildExportUrl } from '@platform/utils/entities';
import { hashToPath, pathToHash } from '@platform/utils/history';
import { renameObjectKey, checkArgs } from '@platform/utils/general';
import { safeStringify, safeParse, arrayIncludes, move } from '@platform/utils/json';
import { DiagnosticSeverity } from '@stoplight/types';

import EditorWorker from './worker';
import HubViewerStore from './hubViewerStore';
import { CoreEditorWrapper } from './coreEditor';

const log = registerLogger('@platform/stores', 'EntityEditorStore');

export class EntityEditor extends CoreEditorWrapper {
  // TODO: customRefResolver
  _extension = null;

  // the entities permanent, ugly id
  _id = '';

  // the entity's pretty orgId (ie "stoplight")
  orgId = '';

  // the original last loaded spec value from the DB or local file
  // helpful to compute isDirty
  @observable
  original = '';

  // spec, collection, instance, or hub
  entityType = '';

  // determine whether we should render readOnly view
  @observable
  readOnly = false;

  @observable
  validJSON = true;

  // the source the last update came from (from spec, parsed, history)
  // spec means the user last made a change directly to the spec, parsed means a UI update, history means undo/redo.
  // prefer using updatingParsed and updatingSpec getters defined below.
  updatingFrom = null;

  // how long certain computations (like debouncedParsed or dereferencedParsed) are delayed
  debounceDelay = 1000;

  // built from content-routers, and passed in by the actual editor component when initing the editor
  contentRouterData = {};

  // the tabs at the bottom of the editor
  contentTabs = [];

  // Preserves the last viewed edit path
  lastEditPath = '';

  // Preserves the last viewed edit path
  lastViewPath = '';

  // Default view path when calling goToViewer
  defaultViewPath = '/';

  // Default edit path when calling goToEditor
  defaultEditPath = '/';

  // the /current/path has value, usually set from the current URL location
  @observable
  editPath = '';

  // the current editor error
  @observable.ref
  error;

  // true when we are in the process of saving a local file
  @observable
  isSavingLocal = false;

  // true when we are in the process of loading a local file
  @observable
  isLoadingLocal = false;

  // spec validation properties
  @observable
  validating = false;

  // the most recent valid spec value (valid JSON), represented as a JSON object
  @observable.ref
  parsed = {};

  // computed and components can subscribe to debouncedParsed observable for performance,
  // if they don't need to use the absolute latest parsed value (like sidebar trees)
  @observable.ref
  debouncedParsed = {};

  // dereferencedParsed is parsed, but with all remote $refs replaced. it functions on a debounce
  @observable.ref
  _dereferencedParsed = {};

  @observable
  isDereferencing = false;

  // prefer using .spec property - getter/setter defined in code below
  @observable
  spec = '';

  // prefer using currentContentTab helpers defined in code below
  @observable
  _currentContentTabId = EDITOR_MODES.design.id;

  // used to filter spec data before file tree is computed
  @observable
  _currentSpecSearchExpression = '';

  hubViewerStore = null;

  _resolveErrors = new DisposableCollection();
  _validations = new DisposableCollection();

  constructor(props) {
    super(props);

    this._disposables.push(this._resolveErrors);
    this._disposables.push(this._validations);

    Object.assign(this, props);

    // this.trackChange({
    //   path: this.currentPath,
    //   value: props.parsed,
    // });
  }

  onDidReset = () => {
    this.load({ doConfirm: false, remote: true, local: false });
  };

  onDidChangeMode = ({ mode }) => {
    if (!mode) return;

    this.setCurrentContentTab(mode.id);
  };

  onActivate = () => {
    // if not embedded and support read mode, start up a viewer store
    if (
      !this.hubViewerStore &&
      !this.embedded &&
      this._extension &&
      _.get(this._extension, 'supportedModes', []).indexOf(EDITOR_MODES.read) >= 0
    ) {
      this.hubViewerStore = new HubViewerStore(this);
    }

    const project = _.get(this.rootStore, 'stores.projectService.current');

    // when parsed changes, update spec after a debounce
    this._disposables.push({
      dispose: reaction(
        () => ({
          newParsed: this.parsed,
        }),
        action(({ newParsed }) => {
          this.debouncedParsed = newParsed;

          if (this.updatingParsed) {
            this.spec = safeStringify(newParsed);
          }

          this.dereferenceParsed();

          // track the changed parsed value for undo/redo
          // this.trackChange({
          //   path: this.currentPath,
          //   value: newParsed,
          // });

          // Reset parse data errors
          // this.rootStore.stores.appStore.removeError('parseEditorData');
        }),
        {
          name: 'parsedChanged',
          delay: this.debounceDelay,
        }
      ),
    });

    this._disposables.push({
      dispose: reaction(
        () => ({
          spec: this.spec,
        }),
        ({ spec }) => {
          if (this.updatingSpec) {
            this.handleSpecUpdate({ spec });
          }
        },
        {
          name: 'specChanged',
          delay: 100,
        }
      ),
    });

    this._disposables.push({
      dispose: reaction(
        () => ({
          target: this.debouncedParsed,
        }),
        action(({ target }) => {
          if (!isValidJSON(this.spec)) {
            this._validations.dispose();

            setTimeout(() => {
              this._validations.push(
                this._editor.addValidation({
                  severity: 0,
                  message: 'INVALID JSON',
                })
              );
            });
          } else {
            if (this.validating) return;

            this.validating = true;

            this.handleValidation({
              target,
              strTarget: this.spec,
              env: this.currentEnv,
              cb: action(data => {
                this.validating = false;
              }),
            });
          }
        }),
        {
          name: 'validateSpec',
          fireImmediately: true,
        }
      ),
    });

    // (CL) TODO: 2s reaction on parsed, calls this.fixSpec(), children can override this func (hub editor for now0)
  };

  // returns the editor's entity from our service store
  @computed
  get entity() {
    return _.get(this.rootStore.stores.projectStore, 'current.currentFile');
  }

  // editors that inherit this one should override this if they want to
  // customize how path routing is handled
  goToPath = (path, options = {}) => {
    if (!_.isEqual(this.currentPath, path)) {
      this.rootStore.stores.routerStore.setQueryParams(
        {
          edit: pathToHash({ path }),
          view: undefined,
        },
        {
          preserve: true,
          replace: true,
          ...options,
        }
      );
    }
  };

  goToEditor = (editPath, extraQuery = {}) => {
    const routerStore = _.get(this.rootStore, 'stores.routerStore');

    this.lastViewPath = this.currentViewPath;

    const currentEditPath = _.get(routerStore, 'location.query.edit');
    const edit = editPath || this.lastEditPath || currentEditPath || this.defaultEditPath;

    routerStore.setQueryParams(
      {
        ...extraQuery,
        view: undefined,
        edit,
      },
      {
        preserve: true,

        // if we're auto-navigating to default, replace so that the user doesn't have to press back twice
        replace: !currentEditPath && edit === this.defaultEditPath ? true : undefined,
      }
    );
  };

  goToViewer(viewPath, extraQuery = {}) {
    const routerStore = _.get(this.rootStore, 'stores.routerStore');

    this.lastEditPath = this.currentEditPath;

    const currentViewPath = _.get(routerStore, 'location.query.view');
    const view = viewPath || this.lastViewPath || currentViewPath || this.defaultViewPath;

    routerStore.setQueryParams(
      {
        ...extraQuery,
        view,
        edit: undefined,
      },
      {
        preserve: true,

        // if we're auto-navigating to default, replace so that the user doesn't have to press back twice
        replace: !currentViewPath && view === this.defaultViewPath ? true : undefined,
      }
    );
  }

  @computed
  get dereferencedParsed() {
    /**
     * Embedded editors are never dereferenced.
     * See the following:
     * https://github.com/stoplightio/platform/blob/87a07dd99241d6097bc184d28cc25875e90d9eaf/platform/src/stores/entityEditorStore.js#L542
     * https://github.com/stoplightio/platform/blob/87a07dd99241d6097bc184d28cc25875e90d9eaf/platform/src/stores/entityEditorStore.js#L163
     */

    return this.embedded ? this.parsed : this._dereferencedParsed;
  }

  @computed
  get jsonPathCache() {
    return this.rootStore.stores.jsonPathCache;
  }

  @computed
  get updatingParsed() {
    return this.updatingFrom === 'parsed';
  }

  @computed
  get updatingSpec() {
    return this.updatingFrom === 'spec';
  }

  @computed
  get currentEditPath() {
    return this.editPath || _.get(this.rootStore.stores.routerStore, 'location.query.edit', null);
  }

  @computed
  get currentViewPath() {
    const path =
      this.viewPath || _.get(this.rootStore.stores.routerStore, 'location.query.view', '/');
    return `/${_.trim(path, '/')}`;
  }

  @computed
  get isEditing() {
    return this.editPath || _.get(this.rootStore.stores.routerStore, 'location.query.edit', null);
  }

  @computed
  get isViewing() {
    const modes = _.get(this._extension, 'supportedModes', []);
    return !this.isEditing && modes.indexOf(EDITOR_MODES.read) >= 0;
  }

  @computed
  get currentEnv() {
    const projectStore = _.get(this.rootStore, 'stores.projectStore.current') || {};
    return projectStore.resolvedEnv || {};
  }

  updateVariables(variables, options = {}) {
    const projectStore = _.get(this.rootStore, 'stores.projectStore.current') || {};
    projectStore.updateActiveEnv(variables, {
      ...options,
      preserveShared: true,
    });
  }

  /**
   * Takes a target path, and returns the location object for that path in the viewer.
   *
   * Uses the view query parameter, and supports #anchor-links.
   */
  buildViewPath = (path = '') => {
    if (isExternalLink(path)) {
      return path;
    }

    // if first char is a #, this is an anchor link
    // use the current path, and set the path to the hash
    if (path && path.charAt(0) === '#') {
      return this.rootStore.stores.routerStore.buildHash(path);
    }

    const location = this.rootStore.stores.routerStore.buildQueryParams(
      { view: path, edit: undefined },
      { preserve: true }
    );

    if (!location) {
      return path;
    }

    return `${location.pathname}${location.search}`;
  };

  buildEditPath = jsonPath => {
    return this.rootStore.stores.routerStore.buildQueryParams(
      { edit: pathToHash({ path: jsonPath }), view: undefined },
      { preserve: true }
    );
  };

  @computed
  get currentPath() {
    return hashToPath({ hash: this.currentEditPath });
  }

  @computed
  get currentContent() {
    return route(this.contentRouter, this.currentPath) || {};
  }

  @computed
  get hasCurrentContent() {
    return _.get(this.currentContent, 'component') ? true : false;
  }

  @computed
  get currentParsed() {
    if (this._currentParsed) {
      return this._currentParsed;
    }

    if (_.isEmpty(this.currentPath)) {
      return this.parsed;
    }

    return _.get(this.parsed, this.currentPath);
  }

  @computed
  get currentDereferencedParsed() {
    if (this._currentDereferencedParsed) {
      return this._currentDereferencedParsed;
    }

    if (_.isEmpty(this.currentPath)) {
      return this.dereferencedParsed;
    }

    return _.get(this.dereferencedParsed, this.currentPath);
  }
  s;

  @computed
  get currentSpecMode() {
    // get mode by file extension
    const extension = _.last(_.split(this.id, '.'));
    // check if supported by codeMirror
    if (modeObjs[extension]) return extension;
    // default json
    return 'json';
  }

  @computed
  get hasSidebar() {
    // hub editor overrides this default
    return true;
  }

  @computed
  get sidebarSections() {
    if (this.isViewing) {
      // the hub viewer store will take care of its own sidebar
      return [];
    }

    // separate computedSidebarTree from sidebarSections so calculation is not wiped when switching modes
    return this.computedSidebarTree;
  }

  @computed
  get computedSidebarTree() {
    if (!this._computeSidebarTree) {
      console.warn('entityEditorStore: computeSidebarTree not implemented.');
      return [];
    }

    if (this.isViewing || !this.hasSidebar) {
      // the hub viewer store will take care of its own sidebar
      return [];
    }

    return this._computeSidebarTree(this.filteredSpec, {
      editor: this,
      currentPath: this.currentPath,
      meta: this.sidebarMeta,
      location: _.get(this.rootStore.stores.routerStore, 'location', {}),
      readOnly: this.readOnly,
      routeFunc: this.createSidebarOptions, // hub editor uses this
    });
  }

  @computed
  get filteredSpec() {
    if (!this._filterSpec || _.isEmpty(this._currentSpecSearchExpression)) {
      return this.debouncedParsed;
    }

    return this._filterSpec(this.debouncedParsed, this._currentSpecSearchExpression);
  }

  @computed
  get isDirty() {
    return _.trim(this.original) !== _.trim(this.spec);
  }

  @computed
  get exportUrl() {
    return buildExportUrl({ entity: this.entity });
  }

  @action
  updateSearchExpression = (e, value) => {
    this._currentSpecSearchExpression = value;
  };

  dereferenceParsed = flow(function* dereferenceParsed() {
    // don't need to dereference embedded stores
    if (this.isDereferencing || this.embedded) {
      return;
    }

    this.isDereferencing = true;

    const projectId = _.get(this, 'rootStore.stores.projectStore.current.id');
    const ref = _.get(this, 'rootStore.stores.projectStore.current.currentRef');
    const file = _.get(this, 'rootStore.stores.projectStore.current.currentFilePath');

    try {
      const response = yield EditorWorker.exec(
        'resolve',
        safeStringify({
          apiHost: getConfigVar('SL_API_HOST'),
          obj: this.parsed,
          id: projectId,
          ref,
          file,
        })
      );

      this._resolveErrors.dispose();
      if (response.errors.length) {
        for (const error of response.errors) {
          // TODO change core/URI to accept _scheme, etc... instead of scheme, or have authority return in scheme not _scheme
          const { authority = {} } = error;

          let message = '';

          if (authority._scheme) {
            message += `${authority._scheme}:`;

            if (authority._authority || authority._scheme === 'file') {
              message += '//';
            }
          }

          if (authority._authority) {
            message += authority._authority;
          }

          if (authority._path) {
            message += authority._path;
          }

          if (authority._query) {
            message += `?${authority._query}`;
          }

          if (authority._fragment) {
            message += `#${authority._fragment}`;
          }

          this._resolveErrors.push(
            this._editor.addValidation({
              severity: DiagnosticSeverity.Error,
              message,
              ruleId: error.code,
              path: error.path,
              details: error.message,
              route: {
                query: {
                  edit: pathToHash({ path: error.path.slice(0, -1) }),
                  view: undefined,
                },
              },
            })
          );
        }
      }

      this._dereferencedParsed = response.resolved;
      this.isDereferencing = false;
    } catch (e) {
      this.isDereferencing = false;

      // TODO: report error to user somehow, in editor logs (separate from validations)?
      console.error('Error dereferencing editor data', e);
    }
    return this.dereferencedParsed;
  }).bind(this);

  // basePath is an array to the root object in parsed
  @action
  renameProp = (basePath, from, to, cb) => {
    if (_.isUndefined(to) || _.isEqual(from, to)) {
      return;
    }

    const fromPath = basePath.concat([from]);
    const toPath = basePath.concat([to]);
    const fromHashPath = pathToHash({ path: fromPath });
    const toHashPath = pathToHash({ path: toPath });

    if (_.isEmpty(_.trim(to))) {
      window.alert('Please set a value. This cannot be empty.');
      return;
    }

    const existing = _.has(this.parsed, toPath);
    if (existing) {
      const r = window.confirm(`${toHashPath} is already defined in this spec, replace it?`);
      if (!r) return;
    }

    let newParsed = _.cloneDeep(this.parsed);

    // update the object at the path (the one with the renamed props)
    const newObj = renameObjectKey(_.get(this.parsed, basePath, {}), from, to);
    _.set(newParsed, basePath, newObj);

    // update any local $refs
    newParsed = safeStringify(newParsed);

    // Keep fragment wrapped in double quotes to ensure we only replace local references
    newParsed = newParsed.replace(new RegExp(`"#${fromHashPath}"`, 'g'), `"#${toHashPath}"`);
    newParsed = safeParse(newParsed);

    // persist the changes
    this.updateParsed('set', [], newParsed);
    this.goToPath(toPath);

    if (cb) {
      cb();
    }
  };

  // moves the value at fromPath, to toPath
  // fromPath and toPath should be arrays
  @action
  moveProp = (fromPath, toPath, cb) => {
    if (_.isUndefined(fromPath) || _.isEqual(fromPath, toPath)) {
      return;
    }

    const fromHashPath = pathToHash({ path: fromPath });
    const toHashPath = pathToHash({ path: toPath });

    let r = true;
    const existing = _.has(this.parsed, toPath);
    if (existing) {
      r = window.confirm(`${toHashPath} is already defined in this spec, replace it?`);
    }

    if (r) {
      this.updateParsed('move', fromPath, toPath);

      // update any local $refs
      let newParsed = safeStringify(this.parsed);
      newParsed = newParsed.replace(new RegExp(`"${fromHashPath}"`, 'g'), `"${toHashPath}"`);
      newParsed = safeParse(newParsed);

      // persist the changes
      this.updateParsed('set', [], newParsed);
      this.goToPath(toPath);

      if (cb) {
        cb();
      }
    }
  };

  @action
  handleSpecUpdate = ({ spec }) => {
    if (_.isUndefined(spec)) {
      return;
    }

    const target = safeStringify(spec);

    if (this.updatingParsed) {
      this.spec = target;
    } else {
      try {
        const newParsed = JSON.parse(this.spec);
        this.parsed = newParsed;
        if (this.onSpecChanged) {
          this.onSpecChanged(newParsed);
        }
      } catch (e) {
        // noop
      }
    }
  };

  @action
  updateSpec = value => {
    this.updatingFrom = 'spec';
    this.updatingHistory = false;
    this.spec = value;
  };

  @action
  updateParsed = (t, p, v, options = {}) => {
    const { immediate, splice = {} } = options;

    this.updatingFrom = 'parsed';
    this.updatingHistory = false;

    // clean path
    // make sure it's an array, and remove empty/null values
    let path = p;
    if (p instanceof Array) {
      path = _.reject(p, e => e === '' || e === null);
    } else if (!p) {
      path = [];
    } else {
      // TODO: handle more complex path selectors like foo.bar[0].fee or [0] or [0].fee
      path = path.split('.');
    }

    let transformation = t;

    // don't allow setting undefined values
    if (_.isUndefined(v)) {
      transformation = 'unset';
    }

    // clean value
    let value;
    this.parsed = produce(this.parsed, newParsed => {
      switch (transformation) {
        case 'push':
          value = _.get(newParsed, path, []);
          if (value instanceof Array) {
            value = value.concat(v instanceof Array ? v : [v]);
          } else {
            log.error(`updateParsed: can't push value because path is not an array:`, {
              path,
              value,
            });
            return newParsed;
          }

          break;
        case 'pull':
          if ((!_.isNumber(v) && !v) || v < 0) {
            log.error(`updateParsed: can't pull out of bounds index`, { path, index: v });
            return newParsed;
          }

          value = _.get(newParsed, path, []);
          if (value instanceof Array) {
            _.pullAt(value, v);
          }

          break;
        case 'splice':
        case 'move':
          // we assume an array in our splice transformation
          value = _.isArray(v) ? v : [v];

          break;
        default:
          value = v;
      }

      // do the transformation
      let tail = _.last(path);
      let parentVal;
      let targetVal;

      switch (transformation) {
        case 'unset':
          parentVal = _.get(newParsed, _.initial(path), {});

          // handle unsetting when the last element is a numeric index (ie unsetting an array value)
          // and handle unsetting where it's a string tail, but really the target is an array
          if (_.isArray(parentVal)) {
            _.pullAt(parentVal, tail);
          } else {
            _.unset(newParsed, path);
          }
          break;
        case 'set':
        case 'push':
        case 'pull':
          if (_.isUndefined(value)) {
            log.error(
              `updateParsed: the ${transformation} transformation requires a value to be passed in`,
              {
                path,
              }
            );
            return newParsed;
          }

          if (_.isEmpty(path)) {
            return v;
          } else {
            _.set(newParsed, path, value);
          }

          break;
        case 'splice':
          if (_.isEmpty(value)) {
            log.error('updateParsed: you must pass a value to splice', {
              splice,
              path,
              value,
            });

            return newParsed;
          }

          if (!_.isNumber(splice.index)) {
            log.error(
              'updateParsed: the splice transformation requires a numeric splice.index option to be passed in',
              {
                splice,
                path,
                value,
              }
            );
            return newParsed;
          }

          targetVal = _.get(newParsed, path, []);
          if (!targetVal || !_.isArray(targetVal)) {
            log.error('updateParsed invalid newParsed, splice can only be used on arrays', {
              targetVal,
              splice,
              path,
              value,
            });

            return newParsed;
          }

          targetVal.splice(splice.index, splice.howMany || 0, ...value);
          _.set(newParsed, path, targetVal);

          break;
        case 'move':
          try {
            move({
              data: newParsed,
              srcPath: path,
              dstPath: value,
            });
          } catch (e) {
            // noop
          }

          break;
        default:
          log.error('updateParsed transformation not supported:', t);
          return newParsed;
      }
    });

    if (this.jsonPathCache) {
      this.jsonPathCache.triggerUpdate(transformation, path, v, { cacheKey: this.id, options });
    }

    this.handleSpecUpdate({ spec: this.parsed }, { immediate });
  };

  @action
  resetPath = (p, defaultVal, { skipConfirm = false } = {}) => {
    const r =
      skipConfirm ||
      window.confirm(
        `Are you sure you want to reset this part of the tree to the last saved value? \n\n ${pathToHash(
          { path: p }
        )}`
      );

    if (!r) {
      return;
    }

    const original = safeParse(this.original);
    const path = safeParse(p, []);

    if (_.isEmpty(path)) {
      this.updateParsed('set', path, original);
    } else {
      const originalProp = _.get(original, path, defaultVal);

      if (!originalProp) {
        window.alert(
          'This path was not set on the original spec (it is new since your last save).'
        );
      } else {
        this.updateParsed('set', path, originalProp);
      }
    }
  };

  resetCurrentPath(defaultVal, opts) {
    this.resetPath(this.currentPath, defaultVal, opts);
  }

  @action
  removePath(path, cb, { force, confirmMessage } = {}) {
    if (_.isEmpty(path)) {
      return;
    }

    let r = true;
    if (!force) {
      r = window.confirm(
        confirmMessage ||
          `Are you sure you want to delete this part of the tree? \n\n ${pathToHash({
            path: path,
          })}`
      );
    }

    if (r) {
      this.updateParsed('unset', path);

      if (_.isFunction(cb)) {
        cb();
      } else if (arrayIncludes(this.currentPath, path)) {
        this.rootStore.stores.routerStore.setQueryParams(
          { edit: '/' },
          { preserve: true, replace: true }
        );
      }
    }
  }

  removeCurrentPath() {
    this.removePath(this.currentPath);
  }

  updateCurrentParsed(t, p, v) {
    let path = p instanceof String ? [p] : p;

    if (!_.isEmpty(this.currentPath)) {
      path = this.currentPath.concat(path);
    }

    this.updateParsed(t, path, v);
  }

  @action
  updateOriginalToCurrent = valueOverride => {
    this.original = safeStringify(valueOverride || this.spec);
  };

  @action
  clearError = () => {
    this.error = null;
  };

  /*
  * UNDO/REDO
  */

  @observable
  history = observable.array([], { deep: false });

  @observable
  historyPosition = 0;

  updatingHistory = false;

  @computed
  get canUndo() {
    return this.history.length - 1 && this.historyPosition < this.history.length - 1;
  }

  @computed
  get canRedo() {
    return this.historyPosition > 0;
  }

  // @action
  // trackChange({ path, value }) {
  //   if (!this.updatingHistory) {
  //     // if we are currently in the history, slice out any
  //     // changes earlier than our current position, and reset ths history
  //     if (this.historyPosition > 0) {
  //       this.history = this.history.slice(this.historyPosition, this.history.length);
  //       this.historyPosition = 0;
  //     }

  //     this.history.unshift({
  //       // new need deep reference, history values can't share refs with
  //       // this.parsed or things will break weirdly (mutations!)
  //       path: _.cloneDeep(path || []),
  //       value: _.cloneDeep(value),
  //     });

  //     // cap history at 25
  //     this.history = this.history.slice(0, 25);
  //   }
  // }

  @action
  undo = () => {
    this.updatingHistory = true;
    const currentTarget = this.history[this.historyPosition];
    const target = this.history[this.historyPosition + 1];

    if (target) {
      this.updatingFrom = 'parsed';
      this.parsed = target.value;
      this.historyPosition += 1;

      GoogleAnalytics.track({
        eventCategory: 'Editors',
        eventAction: 'undo',
      });

      // navigate to this part of the tree
      this.goToPath((currentTarget.path || []).slice());
    }
  };

  @action
  redo = () => {
    this.updatingHistory = true;
    const target = this.history[this.historyPosition - 1];

    if (target) {
      this.updatingFrom = 'parsed';
      this.parsed = target.value;
      this.historyPosition -= 1;

      GoogleAnalytics.track({
        eventCategory: 'Editors',
        eventAction: 'redo',
      });

      // navigate to this part of the tree
      this.goToPath((target.path || []).slice());
    }
  };

  @action
  resetHistory = parsed => {
    this.updatingHistory = false;
    this.historyPosition = 0;
    this.history = observable.array([], { deep: false });
  };

  /*
  * PERSISTANCE
  */

  @computed
  get fileModeActive() {
    return this.rootStore.stores.fileSystemStore.isCurrentActive;
  }

  @computed
  get fileMapping() {
    return this.rootStore.stores.fileSystemStore.currentMapping;
  }

  save = flow(function* save({ commitMessage } = {}) {
    if (this.beforeSave) {
      this.beforeSave();
    }

    // wrap in set timeout to allow any onBlur events
    // in the editor to be processed
    // yield new Promise(resolve => setTimeout(resolve, 0));

    const parts = this.id.split(':');
    const data = this.updatingParsed ? this.parsed : this.spec;

    // save to stoplight
    yield this.rootStore.stores.projectStore.fileService.update(
      _.last(parts),
      {
        branch: parts[1],
        content: data,
        commit_message: commitMessage.summary,
      },
      {
        projectId: _.first(parts),
      }
    );

    this.setDefaultCommitMessage();
    this.reset(data);
  }).bind(this);

  @action
  remove = (options = {}, cb) => {
    const r = window.confirm(
      `Are you sure you want to delete this ${this.entityType} from Stoplight's servers?${
        !_.isEmpty(_.get(this.fileMapping, 'filePath'))
          ? ' Your local file will not be removed.'
          : ''
      }`
    );

    if (!r) {
      return;
    }

    const parts = this.id.split(':');
    this.rootStore.stores.projectStore.current
      .removeFile(_.last(parts))
      .then(() => {
        alert.success('File deleted.');
      })
      .catch(e => {
        alert.warning(_.get(e, 'message', String(e)));
      });
  };

  @action
  load = (options = {}, cb) => {
    const { doConfirm = true, originalOnly } = options;

    let r = true;
    if (doConfirm) {
      let message;
      message = `Are you sure you want to reset this ${
        this.entityType
      } to the last saved value? You will lose any unsaved changes.`;

      r = window.confirm(message);
    }

    if (!r) {
      return;
    }

    const parts = this.id.split(':');
    const current = _.get(this.rootStore, 'stores.projectStore.current');
    if (current) {
      current.getFile(_.last(parts)).then(
        action(data => {
          const content = _.get(data, 'record.data.content');
          // if we are currently working in hosted mode, reset the editor
          this.reset(content, { originalOnly });

          if (cb) {
            cb({
              data,
            });
          }
        })
      );
    }
  };

  @action
  reset = (newData, options = {}) => {
    const { originalOnly, preserveOriginal } = options;

    const data = newData || this.original;
    const stringData = safeStringify(data);
    const parsedData = safeParse(data);

    if (!preserveOriginal) {
      if (newData) {
        this.original = stringData;
      }
    }

    if (!originalOnly) {
      this.updatingFrom = null;
      this.spec = stringData;
      this.parsed = parsedData;
      this.debouncedParsed = parsedData;
      this.dereferenceParsed();
    }
  };

  @action
  toggleFileMode = ({ confirmReload = true, reload = true } = {}) => {
    const isActive = this.fileModeActive;
    this.rootStore.stores.fileSystemStore.toggleActive();

    let originalOnly = confirmReload
      ? !window.confirm(
          `Ok, file mode is being turned ${
            isActive ? 'OFF' : 'ON'
          }. Do you also want to load the latest data that's ${
            isActive
              ? 'saved to Stoplight?'
              : 'on disk? Press cancel to leave the current editor state as is.'
          }`
        )
      : !reload;

    this.load({ remote: isActive, doConfirm: false, originalOnly });
  };

  updateFilePath(path) {
    this.rootStore.stores.fileSystemStore.updateFilePath(path);
    this.load({ doConfirm: false, local: true });
  }

  /*
  * CONNECTIONS
  */

  @computed
  get connectedSpecs() {
    return [];
  }

  @computed
  get connectedEndpoints() {
    // return getEndpointsFromSpecs(this.connectedSpecs);
    return [];
  }

  @computed
  get connectedCollections() {
    return [];
  }

  @computed
  get connectedInstances() {
    return [];
  }

  // will calculate a dropdown that combines all local ref options with all remote
  // ref options, for a given path.
  //
  // for example, if path were ['responses'], this would return an options object suitable to pass
  // to a FormSelect component, that has all @local 'responses', and all 'responses' for connected
  // specs
  refDropdownOptions(path) {
    const data = [];
    const target = _.get(this.parsed, path, {});

    const sortedLocal = _.flow(
      _.toPairs,
      _.sortBy(0),
      _.fromPairs
    )(target);
    _.forEach(sortedLocal, (d, k) => {
      const hash = pathToHash({ path: path.concat(k) });
      data.push({
        text: hash,
        description: '@local',
        value: hash,
      });
    });

    // const specs = this.connectedSpecs;
    // _.forEach(specs, (spec, i) => {
    //   const remoteTarget = _.get(spec.data, path, {});
    //   const sortedRemote = _.flow(_.toPairs, _.sortBy(0), _.fromPairs)(remoteTarget);
    //   _.forEach(sortedRemote, (d, k) => {
    //     const hash = pathToHash({ path: path.concat(k) });
    //     data.push({
    //       text: hash,
    //       description: spec.id,
    //       value: `${buildExportUrl({ entityType: 'specs', entity: spec, deref: 'all' })}${hash}`,
    //     });
    //   });
    // });

    return data;
  }

  // given a ref, will return the data from local deref
  //
  // for example:
  // #/responses/404 will return the @local 404 response object
  // http://export/stoplight/specs/../export.json#/responses/401 will return the 401 object at that connected spec
  resolveRef(ref) {
    return _.get(
      this.dereferencedParsed,
      hashToPath({ hash: `#/${_.last((ref || '').split('#/'))}` })
    );
  }

  get contentRouter() {
    return toJS(this.contentRouterData.contentRouter || {});
  }

  get propsData() {
    return toJS(this.contentRouterData.propsData || {});
  }

  /*
  * METHODS AND GETTERS FOR SUBCLASS TO IMPLEMENT / DEFINE
  */

  // editors that inherit this one should override this if they want to
  // customize the parsed value that is written to disk when in file mode
  get localParsed() {
    return this.parsed;
  }

  // if the data in stoplight should differ at all from what is in the local file, the changes
  // should be made in this function (for example, deleting an "id" property that's stored in the file)
  cleanLocal(data) {
    return data;
  }

  handleValidation({ target, env, strTarget, cb }) {
    if (cb) {
      cb();
    }
  }

  get service() {
    console.warn('entityEditorStore: service not defined.');
    return null;
  }

  // should be map of "treePath" => [{metaData}], for example:
  // {
  //   "scenarios/123": [{
  //     icon: 'check',
  //     name: '200',
  //     className: 'c-positive',
  //   }]
  // }
  @computed
  get sidebarMeta() {
    return {};
  }

  /*
   * CONTENT TABS
   */

  @action
  setCurrentContentTab = id => {
    this._currentContentTabId = id;
  };

  @computed
  get currentContentTabId() {
    // if we don't have any widgets for the current path, and we are supposed to show
    // the ui tab, instead show the code tab
    if (
      (this._currentContentTabId === EDITOR_MODES.design.id && !this.hasCurrentContent) ||
      !this.validJSON
    ) {
      return EDITOR_MODES.code.id;
    }

    return this._currentContentTabId || EDITOR_MODES.code.id;
  }

  @computed
  get currentContentTab() {
    return _.find(this.contentTabs, { id: this.currentContentTabId }) || this.contentTabs[0];
  }

  // children can override this to do more on fetch triggers
  fetchData() {
    this.dereferenceParsed();
  }
}

export default class EntityEditorStore {
  rootStore = null;
  editorClass = EntityEditor;

  @observable
  activeEditor = null;

  @observable
  editors = [];

  constructor({ rootStore } = {}) {
    this.rootStore = rootStore;
  }

  /**
   * Find an editor by query
   * @param  {object} query - Most likely this will be { id } but you could query by anything
   * @return {Editor Instance} - An instance of the EntityEditor
   */
  getEditor(query = {}) {
    return _.find(this.editors, query) || {};
  }

  getEditorIndex(query = {}) {
    return _.findIndex(this.editors, query);
  }

  // query can be an object or function
  getEditors(query) {
    if (!query) return this.editors;

    return _.filter(this.editors, query);
  }

  getEditorsForProject(projectId) {
    if (!projectId) return [];

    return this.getEditors(editor => {
      return _.split(editor.id, ':')[0] === String(projectId);
    });
  }

  initFileMapping(props, options) {
    const { id, spec, entityType } = props;

    if (!this.rootStore.stores.fileSystemStore) {
      return;
    }

    this.rootStore.stores.fileSystemStore.initMapping(
      {
        id,
        type: entityType,
        defaultData: spec,
        onLoad: data => {
          const editor = this.getEditor({ id });
          if (editor && editor.reset) {
            editor.reset(editor.cleanLocal(data));
          }
        },
      },
      options
    );
  }

  @action
  initEditor = (props, { force } = {}) => {
    // editPath is used if you want the editor to show something different than the current URL hash
    // for example, we use this when we show the collection editor embedded in the spec editor
    const { orgId, entityType, editPath, entity = {}, embedded, readOnly, ...extra } = props || {};

    checkArgs('entityEditorStore.initEditor', { entity });

    const { _id, id } = entity;
    const spec = entity.data || '';

    // don't initialize with invalid JSON
    const Editor = this.editorClass;
    let stringSpec = spec;
    let parsed = spec;
    let validJSON = true;

    if (!Editor.rawIsString || (typeof spec === 'string' && spec.charAt(0) === '{')) {
      if (typeof parsed === 'string') {
        try {
          parsed = JSON.parse(_.isEmpty(_.trim(parsed)) ? '{}' : parsed);
          this.rootStore.stores.appStore.removeError('initEditor');
        } catch (e) {
          validJSON = false;
          stringSpec = parsed;

          console.warn('Could not initialize editor, invalid JSON.', e);
          this.rootStore.stores.appStore.addError(
            'Could not initialize editor, invalid JSON. Please fix the issue and save.',
            {
              id: 'initEditor',
            }
          );
        }
      }

      if (this.filterParsed) {
        parsed = this.filterParsed(parsed);
      }

      if (validJSON) {
        stringSpec = safeStringify(parsed);
      }
    }

    const editorData = {
      ...extra,
      _id,
      id,
      orgId,
      entityType,
      original: stringSpec,
      parsed: parsed,
      debouncedParsed: parsed,
      _dereferencedParsed: parsed,
      spec: stringSpec,
      editPath,
      readOnly,
      validJSON,
      rootStore: this.rootStore,
      embedded,
    };

    let newEditor;
    const currentIndex = this.getEditorIndex({ id });
    if (currentIndex >= 0) {
      newEditor = this.editors[currentIndex];

      // replace editor if we are forcing
      // OR
      // if the _id of this entity has changed
      // OR
      // if readOnly has changed
      //
      // for example, a spec called "todo" was deleted and re-created,
      // we want to re-initialize even though the "id" is the same (todo)
      if (
        force ||
        (id && newEditor.id !== id) ||
        newEditor.readOnly !== readOnly ||
        newEditor.validJSON !== validJSON
      ) {
        newEditor = new Editor(editorData);
        this.editors[currentIndex] = newEditor;
      }

      newEditor.fetchData();
    } else {
      newEditor = new Editor(editorData);
      this.editors.push(newEditor);
    }

    // Reset parse data errors
    this.rootStore.stores.appStore.removeError('parseEditorData');

    if (!embedded) {
      this.activateEditor(newEditor);
    }

    return newEditor;
  };

  @action
  activateEditor = newEditor => {
    const oldEditor = this.activeEditor;
    this.activeEditor = newEditor;

    _.invoke(oldEditor, 'deactivate');
    _.invoke(this.activeEditor, 'activate');
  };

  @action
  removeEditor = (query = {}) => {
    _.remove(this.editors, query);
  };
}
