import { computed, action, reaction, observable } from 'mobx';

import get from 'lodash/get';
import set from 'lodash/set';
import trim from 'lodash/trim';
import sortBy from 'lodash/sortBy';
import invert from 'lodash/invert';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';

import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faPenSquare } from '@fortawesome/pro-solid-svg-icons/faPenSquare';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';

import { Spectral } from '@stoplight/spectral';
import { oas2Rules } from '@stoplight/spectral/rulesets/oas2';
import { oas3Rules } from '@stoplight/spectral/rulesets/oas3';
import { DiagnosticSeverity } from '@stoplight/types';

import { colors } from '@core/ui/enums';
import { safeStringify } from '@platform/utils/json';

import { lintExtension } from './coreEditor';
import entityEditorStore, { EntityEditor } from './entityEditorStore';

const defaultRules = {
  oas2: oas2Rules(),
  oas3: oas3Rules(),
};

const getIcons = rule => {
  const { type, severity } = rule;
  const icons = { type: { icon: faQuestion }, severity: { icon: faQuestion } };

  switch (type) {
    case 'style':
      icons.type.icon = faPenSquare;
      icons.type.color = colors.active;
      break;
    case 'validation':
      icons.type.icon = faBadgeCheck;
      icons.type.color = colors.positive;
  }

  switch (severity) {
    case DiagnosticSeverity[DiagnosticSeverity.Information]:
      icons.severity.icon = faInfoCircle;
      icons.severity.color = colors.info;
      break;
    case DiagnosticSeverity[DiagnosticSeverity.Warning]:
      icons.severity.icon = faExclamationTriangle;
      icons.severity.color = colors.warning;
      break;
    case DiagnosticSeverity[DiagnosticSeverity.Error]:
      icons.severity.icon = faExclamationCircle;
      icons.severity.color = colors.negative;
      break;
  }

  return icons;
};

const getSeverity = rule => {
  let { severity, type } = rule;

  // replace severity with friendly labels and defaults
  if (!severity) {
    switch (type) {
      case 'style':
        severity = DiagnosticSeverity.Warning;
        break;
      case 'validation':
        severity = DiagnosticSeverity.Error;
        break;
      default:
        severity = DiagnosticSeverity.Information;
    }
  }

  return invert(DiagnosticSeverity)[severity];
};

export class LintEditor extends EntityEditor {
  _extension = lintExtension;

  @observable
  rulesets = {};

  constructor(props) {
    super(props);
  }

  onDidReset = () => {
    this.load({ doConfirm: false, remote: true, local: false }, this.buildRules);
  };

  activate() {
    super.activate();
    this.buildRules();
    this.goToEditor(this.currentFormat);
  }

  @action
  buildRules = () => {
    const rulesets = {};

    for (const format in defaultRules) {
      if (isEmpty(defaultRules[format])) continue;

      const s = new Spectral();
      s.addRules(defaultRules[format]);
      s.mergeRules(get(this.parsed, ['rules', format]));
      s.applyRuleDeclarations(get(this.parsed, ['rules', format]));

      for (const key in s.rules) {
        if (isEmpty(s.rules[key])) continue;

        const rule = s.rules[key];

        rule.severity = getSeverity(rule);
        rule.icons = getIcons(rule);

        rule.enabled = rule.hasOwnProperty('enabled') ? rule.enabled : true;

        set(rulesets, [format, rule.name || key], rule);
      }
    }

    this.rulesets = rulesets;
  };

  @action
  updateRule = (name, enabled) => {
    const format = this.currentEditPath || 'oas2';
    const rule = get(this.parsed, ['rules', format, name]);

    // custom rules will be objects so we only want to update the enabled field
    switch (typeof rule) {
      case 'object':
        this.updateParsed('set', ['rules', format, name, 'enabled'], enabled);
        break;
      default:
        this.updateParsed('set', ['rules', format, name], enabled);
        break;
    }

    set(this.rulesets, [format, name, 'enabled'], enabled);
  };

  @computed
  get currentFormat() {
    return this.currentEditPath || 'oas2';
  }

  // sorts rules by tag
  @computed
  get sortedRules() {
    const sortedRules = sortBy(this.rulesets[this.currentFormat], [
      ruleEntry => trim(get(ruleEntry.tags, 0)),
      ruleEntry => ruleEntry.name,
    ]);

    const groupedRules = {};
    const ungroupedRules = [];
    sortedRules.forEach(ruleEntry => {
      let tag = get(ruleEntry.tags, 0);
      tag = toUpper(trim(tag));

      if (tag) {
        if (groupedRules[tag]) {
          groupedRules[tag].push(ruleEntry);
        } else {
          groupedRules[tag] = [ruleEntry];
        }
      } else {
        ungroupedRules.push(ruleEntry);
      }
    });

    if (ungroupedRules.length !== 0) {
      groupedRules.OTHER = ungroupedRules;
    }

    return groupedRules;
  }
}

export default class LintEditorStore extends entityEditorStore {
  editorClass = LintEditor;
}
