import React from 'react';
import { Popup, Message, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import pluralize from 'pluralize';
import { simpleSlugify } from '@platform/utils/general';
import { pathToHash } from '@platform/utils/history';

import Modal from '../Modal';
import FormInput from '../FormInput';
import JsonSchemaEditor from '../JsonSchemaEditor';

const SpecCrudBuilder = ({ triggerFactory, routerStore, ui, updateUi, clearUi, editor }) => {
  const { parsed, id, showCrudBuilder } = editor;

  if (!showCrudBuilder) return null;

  return (
    <Modal
      forceOpen
      className="CrudBuilderModal"
      title="CRUD Builder: Create a New Resource"
      size="large"
      onClose={() => {
        editor.toggleCrud();
      }}
      cancelButton={{
        negative: true,
        content: 'Cancel',
      }}
      confirmButton={{
        positive: true,
        content: 'Create',
        onClick(e, elem) {
          editor.addResource(
            {
              path: ui.path,
              name: ui.name,
              schema: ui.schema,
            },
            () => {
              const newPath = ['paths', ui.path, 'get'];

              routerStore.setQueryParams(
                {
                  edit: pathToHash({ path: newPath }),
                },
                {
                  preserve: true,
                }
              );

              elem.handleClose();
              clearUi();
            }
          );
        },
      }}
      contentFactory={() => (
        <div className="pt-3 pb-3">
          <div className="mb-6">
            <Message
              content="Define a model and we'll create all of the relevant CRUD operations and models for you."
              size="tiny"
              info
              compact
            />
          </div>

          <div className="flex mt-3 mb-3">
            <div className="mr-3">
              <div className="mb-2 ml-2 font-bold">Model Name</div>
              <FormInput
                placeholder="User"
                input={{
                  value: ui.name || '',
                  onChange: (e, { value }) => {
                    updateUi('set', 'name', value);
                  },
                  onBlur: () => {
                    if (_.isEmpty(ui.path) && !_.isEmpty(ui.name)) {
                      updateUi('set', 'path', `/${simpleSlugify(pluralize(ui.name))}`);
                    }
                  },
                }}
              />
            </div>

            <div className="flex-1">
              <div className="mb-2 ml-2 font-bold">Base Resource Path</div>
              <FormInput
                placeholder="/users"
                fluid
                input={{
                  value: ui.path || '',
                  onChange: (e, { value }) => {
                    updateUi('set', 'path', value);
                  },
                }}
              />
            </div>
          </div>

          <div className="mt-6">
            <div className="mb-3 ml-2 font-bold">
              Model Schema
              <span className="c-muted ml-2">
                <Popup
                  trigger={<Icon name="question circle" />}
                  size="small"
                  position="top center"
                  hoverable
                  wide
                >
                  All of the properties that make up this model. Mark properties that should only be
                  part of the response body (like id, created_at, etc) as "Read Only"!
                </Popup>
              </span>
            </div>

            <JsonSchemaEditor
              id={'CrudBuilderInput'}
              schema={_.get(ui, 'schema') || '{ "type": "object" }'}
              editorStore="oas2EditorStore"
              editorId={id}
              specFlavor="oas3"
              onSchemaChange={schema => {
                updateUi('set', 'schema', schema);
              }}
              refBuilderProps={{
                localData: parsed,
                fileFilter: { modeling: [/oas2/] },
                routeDataTargets: { oas2: ['definitions'] },
                targets: 'required',
              }}
              example
            />
          </div>
        </div>
      )}
    />
  );
};

export default inject(({ routerStore, appStore, oas2EditorStore }, { editorId }) => ({
  routerStore,
  editor: oas2EditorStore.getEditor({ id: editorId }),
  ...appStore.injectUi(`${editorId}-new-path`),
}))(observer(SpecCrudBuilder));
