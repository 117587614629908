export const calloutTypes = {
  info: {
    icon: 'info circle',
  },
  warning: {
    icon: 'exclamation circle',
  },
  error: {
    icon: 'exclamation triangle',
  },
  success: {
    icon: 'check square',
  },
};
