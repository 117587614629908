import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Form, Icon } from 'semantic-ui-react';

import { ruleTip } from '@platform/utils/instances';
import { httpMethods } from '@platform/utils/http';

import EditorContentSection from '@platform/components/EditorContentSection';

import RuleAccordian from './ruleaccordian.js';

const InstanceRule = props => {
  const { id, onChange, value = {}, parsed, embedded } = props;
  const rule = value;
  const tipData = ruleTip(rule);

  const formElem = (
    <Form>
      <div className="InstanceRule-tip">
        <Icon name="info circle" />
        {_.map(tipData, (t, i) => (
          <span key={i} className={t.className}>
            {`${i === 0 ? '' : ' '}${t.text}`}
          </span>
        ))}
        .
      </div>

      <Form.Group widths="equal">
        <Form.Input
          label="id"
          placeholder="id, 'catch-all'"
          value={rule.id}
          onChange={(e, { value }) => {
            onChange('set', ['id'], value);
          }}
        />

        <Form.Select
          label="apis"
          placeholder="e.g. `api-id`"
          options={_.map(_.get(parsed, 'apis', []), h => ({ text: h.id, value: h.id }))}
          value={_.get(rule, ['apis']) || []}
          onChange={(e, { value }) => {
            if (_.isEmpty(value)) {
              onChange('unset', ['apis']);
            } else {
              onChange('set', ['apis'], value);
            }
          }}
          multiple
          search
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Select
          label="methods"
          placeholder="e.g.`post`"
          options={_.map(_.keys(httpMethods), h => ({ text: h, value: h }))}
          value={_.get(rule, ['methods']) || []}
          onChange={(e, { value }) => {
            if (_.isEmpty(value)) {
              onChange('unset', ['methods']);
            } else {
              onChange('set', ['methods'], value);
            }
          }}
          multiple
          allowAdditions
          search
        />

        <Form.Select
          label="paths"
          placeholder="e.g.`/users`"
          options={_.get(rule, ['paths'], []).map(h => ({
            text: h,
            value: h,
          }))}
          value={_.get(rule, ['paths']) || []}
          onChange={(e, { value }) => {
            if (_.isEmpty(value)) {
              onChange('unset', ['paths']);
            } else {
              onChange('set', ['paths'], value);
            }
          }}
          multiple
          allowAdditions
          search
        />
      </Form.Group>

      <RuleAccordian id={id} rule={rule} onChange={onChange} embedded={embedded} />
    </Form>
  );

  return (
    <div className="InstanceRule">
      {embedded ? formElem : <EditorContentSection>{formElem}</EditorContentSection>}
    </div>
  );
};

export default inject(({ collectionEditorStore, instanceEditorStore }, { editorId }) => {
  return {
    editor: instanceEditorStore.getEditor({ id: editorId }),
  };
})(observer(InstanceRule));
