import React from 'react';
import { Radio } from 'semantic-ui-react';

import FormInputLabel from '../FormInputLabel';

const FormToggle = props => {
  const { checked, onChange, className = '', label, tip, required, ...extraProps } = props;

  return (
    <div className={`FormToggle flex flex-col ${label ? 'FormToggle-labeled ' : ''}${className}`}>
      <FormInputLabel label={label} tip={tip} required={required} />

      <div className="flex-grow flex items-center justify-center">
        <Radio
          checked={checked}
          onChange={(name, e) => {
            if (onChange) onChange(e.checked);
          }}
          {...extraProps}
          toggle
        />
      </div>
    </div>
  );
};

export default FormToggle;
