import React from 'react';
import _ from 'lodash';

import FormDropdown from '../FormDropdown';

const tagTypes = ['lifecycle', 'project', 'other'];

class EntityTagsDropdown extends React.Component {
  options = () => {
    const { orgTags = [] } = this.props;

    const tagOptions = [];

    for (const t of tagTypes) {
      const options = _.filter(orgTags, ot => {
        return ot.type === t;
      });

      if (!_.isEmpty(options)) {
        tagOptions.push({
          key: t || 'Misc',
          text: _.capitalize(t || 'Misc'),
          disabled: true,
        });

        _.forEach(options, tag => {
          tagOptions.push({
            key: tag.key,
            text: tag.text,
            value: tag.value,
            description: _.truncate(tag.description, { length: 40 }),
          });
        });
      }
    }

    return tagOptions;
  };

  render() {
    const {
      label = 'Tags',
      labelStyle,
      orgTags = [],
      projectTags = [],
      readOnly,
      isSaving,
      handleOnChange,
      style = {},
      placeholder,
    } = this.props;

    return (
      <div className="EntityTagsDropdown" style={style}>
        <FormDropdown
          label={label}
          labelStyle={labelStyle}
          fluid
          multiple
          search
          selection
          readOnly={readOnly}
          disabled={isSaving}
          value={projectTags}
          placeholder={placeholder}
          options={this.options()}
          onChange={(e, { value }) => {
            const tags = _.compact(
              _.uniq(
                _.map(value, t => {
                  return _.find(orgTags, { value: t }) ? t : null;
                })
              )
            );

            if (!_.isEqual(projectTags, tags)) {
              handleOnChange(tags);
            }
          }}
        />
      </div>
    );
  }
}

export default EntityTagsDropdown;
