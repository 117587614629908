import get = require('lodash/get');
import trim = require('lodash/trim');
import URL from 'url-parse';

import { getConfigVar } from '@platform/utils/config';
import { IExplorerTheme, useTheme } from '../config/theme';
import { INode } from '../types';

const NodeMap = {
  http_service: { label: 'API', icon: 'cloud' },
  http_operation: {
    label: 'Operation',
    icon: 'bullseye',
    subtypes: {
      get: { label: 'GET' },
      post: { label: 'POST' },
      put: { label: 'PUT' },
      delete: { label: 'DELETE' },
      patch: { label: 'PATCH' },
      copy: { label: 'COPY' },
      head: { label: 'HEAD' },
      options: { label: 'OPTIONS' },
      link: { label: 'LINK' },
      unlink: { label: 'UNLINK' },
      purge: { label: 'PURGE' },
      lock: { label: 'LOCK' },
      unlock: { label: 'UNLOCK' },
    },
  },
  json_schema: { label: 'Model', icon: 'cube' },
  article: { label: 'Article', icon: 'book' },
};

export const nodeData = (node: INode) => {
  const n = { ...node, subtype: nodeSubtype(node) };
  const theme = useTheme();

  return {
    label: nodeLabel(n),
    color: nodeColor(n, theme),
    icon: nodeIcon(n),
    editorUrl: nodeEditorUrl(n),
  };
};

// some node types will need a subtype
export const nodeSubtype = (node: INode) => {
  const subTypePath = {
    http_operation: 'meta.method',
  };

  return get(node, subTypePath[node.type]);
};

export const nodeLabel = (node: INode) => {
  const { type, subtype = '' } = node;

  const label = get(NodeMap, [type, 'label'], '');
  const prefix = get(NodeMap, [type, 'subtypes', subtype, 'label'], '');

  return trim(`${prefix} ${label}`);
};

// color is organized as "{type}_{subtype}"
export const nodeColor = (node: INode, theme: IExplorerTheme) => {
  let path = node.type;
  if (node.subtype) {
    path += `_${node.subtype}`;
  }

  return get(theme, ['colors', path]) || get(theme, 'colors.node');
};

// grab node icon from map
export const nodeIcon = (node: INode) => {
  return get(NodeMap, [node.type, 'icon']);
};

// need to strip off app host for proper routing with link component
export const nodeEditorUrl = (node: INode) => {
  const { origin, pathname, query } = new URL(node.editorUrl);

  if (origin === getConfigVar('SL_APP_HOST')) {
    return `${pathname}${query ? query : ''}`;
  }

  return node.editorUrl;
};
