import { createThemedModule, ICustomTheme } from '@stoplight/ui-kit';

export type themeTypes = IExplorerTheme;

export const { useTheme, ThemeZone, ThemeProvider } = createThemedModule<'', themeTypes>();

export interface IAppConfig {
  fontSize: {
    xs: string;
    sm: string;
    base: string;
    lg: string;
    xl: string;
  };

  fontWeight: {
    light: number;
    normal: number;
    semibold: number;
    bold: number;
  };
}

export const AppConfig: IAppConfig = {
  fontSize: {
    xs: '9px',
    sm: '11px',
    base: '13px',
    lg: '16px',
    xl: '20px',
  },

  fontWeight: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
};

export interface IExplorerTheme extends ICustomTheme, IAppConfig {
  colors: {
    node: string;
    http_service?: string;
    json_schema?: string;
    article?: string;

    http_operation_get?: string;
    http_operation_post?: string;
    http_operation_put?: string;
    http_operation_delete?: string;
    http_operation_patch?: string;
    http_operation_copy?: string;
    http_operation_head?: string;
    http_operation_options?: string;
    http_operation_link?: string;
    http_operation_unlink?: string;
    http_operation_purge?: string;
    http_operation_lock?: string;
    http_operation_unlock?: string;
  };

  app: {
    bg: string;
    fg: string;
  };

  card: {
    title: string;
    subtitle: string;

    bg: string;
    border: string;
  };
}

export const ExplorerTheme: IExplorerTheme = {
  base: 'light',

  ...AppConfig,

  app: {
    fg: 'black',
    bg: 'rgb(255, 255, 255)',
  },

  colors: {
    node: 'grey',
    http_service: 'rgb(187, 39, 221)',
    json_schema: 'rgb(231, 192, 81)',
    article: 'rgb(630, 120, 220)',

    http_operation_get: '#4EB509',
    http_operation_post: '#5E98CD',
    http_operation_put: 'rgb(233, 156, 82)',
    http_operation_delete: 'rgb(209, 68, 57)',
    http_operation_patch: 'rgb(92, 71, 89)',
    http_operation_copy: 'rgb(68, 69, 184)',
    http_operation_head: 'rgb(133, 189, 83)',
    http_operation_options: 'rgb(161, 89, 125)',
    http_operation_link: 'rgb(144,74,185)',
    http_operation_unlink: 'rgb(128, 88, 167)',
    http_operation_purge: 'rgb(158, 188, 84)',
    http_operation_lock: '#AAA',
    http_operation_unlock: '#CCC',
  },

  card: {
    title: 'black',
    subtitle: 'rgba(19, 15, 33, 0.6)',

    bg: '#fff',
    border: '#ccc',
  },
};
