import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { BaseStore } from '../_base';
import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/orgs/:orgId/members',
      invitePath: 'orgs/:orgId/invite',
      perPage: 100,
    })
    .views(self => {
      return {
        get currentList() {
          const currentOrg = _.get(env.rootStore, 'stores.orgService.current');
          if (!currentOrg) return;

          return self
            .findLocal({
              query: {
                $or: [{ orgId: currentOrg.id }, { orgId: currentOrg.path }],
              },
            })
            .get();
        },
      };
    })
    .actions(self => {
      return {};
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('OrgMemberService');

  return Service.create(data, env);
};
