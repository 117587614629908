import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { Icon } from '@core/ui';

import { stringToColor } from '@platform/utils/colors';

const ImageIcon = props => {
  // size is small, tiny, or large
  const {
    imageUrl,
    iconName,
    icon,
    backgroundColor,
    className,
    innerClassName,
    size,
    styles = {},
    isCircle,
  } = props;

  let contentElem;
  if (imageUrl) {
    contentElem = (
      <div className="ImageIcon-inner">
        <div
          className="h-full w-full"
          style={{
            backgroundImage: `url('${imageUrl}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </div>
    );
  } else if (!_.isEmpty(iconName)) {
    contentElem = (
      <div
        className={cn('ImageIcon-inner flex items-center justify-center text-lg', innerClassName)}
        style={{ backgroundColor: backgroundColor || stringToColor(iconName) }}
      >
        {iconName.substring(0, 2)}
      </div>
    );
  } else if (icon) {
    contentElem = (
      <div
        className={cn('ImageIcon-inner flex items-center justify-center', innerClassName)}
        style={{ backgroundColor: backgroundColor }}
      >
        <Icon icon={icon} />
      </div>
    );
  }

  if (!contentElem) {
    return null;
  }

  return (
    <div
      className={cn('ImageIcon flex items-center justify-center', className, {
        [`is-${size}`]: size,
        'is-circle': isCircle,
      })}
      style={styles.root || {}}
    >
      {contentElem}
    </div>
  );
};

export default ImageIcon;
