import _ from 'lodash';
import { build as buildObjectRouter } from '@platform/utils/objectRouter';
import { buildInstanceUrl } from '@platform/utils/instances';

// import { propsData as collectionPropsData } from './collection';

const headerActionExportLinkFactory = ({ editor }) => {
  const conductorUrl = buildInstanceUrl({
    fileHash: _.get(editor.rootStore.stores.projectStore, 'current.currentFileHash'),
    docs: true,
  });

  return {
    type: 'link',
    loc: 'right',
    content: 'Open Prism Instance',
    props: {
      className: 'c-muted',
      href: conductorUrl,
      target: '_blank',
    },
  };
};

export default ({ Overview, Api, Rule } = {}) => {
  const propsData = {
    root: {
      component: Overview,
      sidebarOptions: {
        parseChildren: true,
      },
      headerActions: [
        {
          factoryFunc: headerActionExportLinkFactory,
        },
      ],
    },
    apis: {
      component: Api,
      preserveEmpty: true,
      sidebarOptions: {
        nameFactory(key, data) {
          return data ? data.id : undefined;
        },
      },
      headerActions: [
        {
          factoryFunc: headerActionExportLinkFactory,
        },
      ],
    },
    rules: {
      component: Rule,
      sidebarOptions: {
        nameFactory(key, data) {
          return data ? data.id : undefined;
        },
      },
      headerActions: [
        {
          factoryFunc: headerActionExportLinkFactory,
        },
      ],
    },
  };

  const contentRouterMap = new Map();

  contentRouterMap.set(['apis', /.*/], propsData.apis);
  contentRouterMap.set(['rules', /.*/], propsData.rules);
  // contentRouterMap.set(['scenarios', /.*/], collectionPropsData.scenario);
  // contentRouterMap.set(['scenarios', /.*/, 'steps'], collectionPropsData.steps);
  // contentRouterMap.set(['scenarios', /.*/, 'steps', /.*/], collectionPropsData.step);
  contentRouterMap.set([], propsData.root);

  const contentRouter = buildObjectRouter(contentRouterMap);

  return {
    propsData,
    contentRouter,
  };
};
