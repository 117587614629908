import React from 'react';
import pluralize from 'pluralize';
import { inject, observer } from 'mobx-react';

import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';

import { DiagnosticSeverity } from '@stoplight/types';
import { PanelSections } from '@platform/components/PanelSections';
import Validations from './Validations';

const ValidationPanel = props => {
  const { coreEditorStore = {}, projectStore, projectService } = props;
  const { _editor = {} } = coreEditorStore;
  const { errors = [], warnings = [], info = [], activeSpecExtension = {} } = _editor;
  const activeExt = activeSpecExtension.id;

  const sections = [];
  const actions = [];

  if (
    projectService.canUser({ action: 'push:project' }) &&
    (activeExt === '.oas3' || activeExt === '.oas2')
  ) {
    actions.push({
      onClick: () => {
        projectStore.current.goToFile('lint.yml', {
          query: {
            edit: activeExt.slice(1), // remove the .
          },
        });
      },
      icon: faCog,
    });
  }

  if (errors.length) {
    sections.push({
      title: `${errors.length} ${pluralize('Error', errors.length)}`,
      titleBgColor: 'negative',
      collapsible: false,
      actions,
      renderChildren: () => {
        return <Validations severity={DiagnosticSeverity.Error} />;
      },
    });
  }

  if (warnings.length) {
    sections.push({
      title: `${warnings.length} ${pluralize('Warning', warnings.length)}`,
      titleBgColor: 'warning',
      collapsible: false,
      actions,
      renderChildren: () => {
        return <Validations severity={DiagnosticSeverity.Warning} />;
      },
    });
  }

  if (info.length) {
    sections.push({
      title: `${info.length} Info`,
      titleBgColor: 'info',
      collapsible: false,
      actions,
      renderChildren: () => {
        return <Validations severity={DiagnosticSeverity.Information} />;
      },
    });
  }

  if (sections.length === 0) {
    sections.push({
      title: `Valid File`,
      titleBgColor: 'positive',
      collapsible: false,
      actions,
      renderChildren: () => {
        return (
          <div className="p-4 font-bold text-grey-darker text-sm">
            No errors found in this file.
          </div>
        );
      },
    });
  }

  return <PanelSections sections={sections} />;
};

export default inject('coreEditorStore', 'projectStore', 'projectService')(
  observer(ValidationPanel)
);
