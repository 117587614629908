import { types } from 'mobx-state-tree';
import _ from 'lodash';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/teams/:teamId/projects',
    })
    .views(self => {
      return {
        get current() {
          return _.first(self.currentList);
        },

        get currentList() {
          const currentTeam = _.get(env.rootStore, 'stores.teamService.current');
          if (!currentTeam) {
            return;
          }

          const q = {
            query: {
              teamId: currentTeam.id,
            },
          };

          return self.findLocal(q).get();
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('TeamProjectService');

  return Service.create(data, env);
};
