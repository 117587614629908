import React from 'react';
import { inject, observer } from 'mobx-react';

import Billing from '../../../components/Billing';
import OrgSettingsLayout from './_layout';

const OrgBillingPage = props => {
  const { orgService } = props;

  const org = orgService.current || {};

  return (
    <OrgSettingsLayout activeTab="billing" className="BillingSettings">
      <Billing namespaceType="org" namespace={org} namespaceId={org.id} />
    </OrgSettingsLayout>
  );
};

export default inject('orgService')(observer(OrgBillingPage));
