import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { PanelList } from '@core/ui';

import PageLoading from '@platform/components/PageLoading';
import { PanelSections } from '@platform/components/PanelSections';

import { timeAgo } from '@platform/utils/time';

class _CommitsList extends React.Component {
  componentWillMount() {
    const { commitService, projectStore } = this.props;

    const query = {
      ref_name: _.get(projectStore, 'current.currentRef', 'master'),
    };

    commitService.find(
      { project_id: projectStore.current.id },
      {
        query,
      }
    );
  }

  render() {
    const { routerStore, commitService } = this.props;
    const query = _.get(routerStore, 'location.query');
    const commitHash = query.commit;
    const commits = commitService.currentList;
    const commitIndex = _.findIndex(commits, { id: commitHash });

    if (commitService.isFinding) {
      return <PageLoading size="small" />;
    }

    return (
      <PanelList items={this.items()} onClick={this.handleItemClick} scrollToIndex={commitIndex} />
    );
  }

  handleItemClick = ({ data }) => {
    const { routerStore } = this.props;
    if (!data || !routerStore) return;

    const query = _.get(routerStore, 'location.query', {});
    const commitHash = query.commit;

    routerStore.setQueryParams({
      commit: commitHash !== data.id ? data.id : null,
    });
  };

  items = () => {
    const { routerStore, commitService } = this.props;
    const query = _.get(routerStore, 'location.query');
    const commitHash = query.commit;
    const commits = commitService.currentList || [];

    return commits.map((item, index) => ({
      name: item.title || item.message,
      meta: [item.author_name, item.short_id, timeAgo(item.committed_date, { short: true })],
      data: item,
      isActive: commitHash === item.id,
    }));
  };
}

const CommitsList = inject('projectStore', 'routerStore', 'commitService')(observer(_CommitsList));

class HistoryPanel extends React.Component {
  render() {
    const { headerProps = {} } = this.props;

    return (
      <div className="w-80 h-full z-20">
        <PanelSections
          header={headerProps}
          sections={[
            {
              title: 'History of Changes',
              collapsible: false,
              renderChildren: this.renderCommitsList,
            },
          ]}
        />
      </div>
    );
  }

  renderCommitsList = () => {
    return <CommitsList />;
  };
}

export default HistoryPanel;
