import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import _ from 'lodash';
import FormInput from '@platform/components/FormInput';
import FormDropdown from '@platform/components/FormDropdown';
import FormSearch from '@platform/components/FormSearch';
import FormCodeEditor from '@platform/components/FormCodeEditor';
import ColorPicker from '@platform/components/ColorPicker';

const actionOptions = [
  { text: 'Add Action', value: 'none' },
  { text: 'Button', value: 'button' },
  { text: 'Search', value: 'search' },
  // { text: 'HTML', value: 'html' },
];

const HubBlockAction = ({
  action = {},
  theme = {},
  actionIndex,
  onChangeData,
  onChangeTheme,
  allowed = [],
}) => {
  const { type = 'none', name = '', content = '', route = {} } = action;

  let trigger;
  let options;

  let computedOptions = actionOptions;
  if (!_.isEmpty(allowed)) {
    computedOptions = _.filter(computedOptions, o => {
      return o.value === 'none' || _.includes(allowed, o.value);
    });
  }

  const actionBgStyle = _.get(theme, ['background'], {});
  const actionTextStyle = _.get(theme, ['text'], {});

  switch (type) {
    case 'button':
      trigger = (
        <Button
          primary
          size="large"
          content={name || 'Button Text'}
          style={{ ...actionBgStyle, ...actionTextStyle }}
        />
      );

      options = (
        <div className="mt-3">
          <FormInput
            label="Name"
            value={name}
            onChange={(e, { value }) => onChangeData('set', ['name'], value)}
            required
          />

          <FormSearch
            className="mt-3"
            label={'URL To Link To'}
            value={route.path}
            results={[]}
            onSearchChange={(e, value) => onChangeData('set', ['route', 'path'], value)}
            onResultSelect={(e, { value }) => onChangeData('set', ['route', 'path'], value)}
            input={{ icon: false }}
            filterResults
            required
          />

          {onChangeTheme && (
            <ColorPicker
              inline
              color={actionTextStyle.color}
              label="Base Text Color"
              onChangeComplete={color => {
                onChangeTheme('set', ['text', 'color'], color.hex);
              }}
            />
          )}

          {onChangeTheme && (
            <ColorPicker
              inline
              color={actionBgStyle.backgroundColor}
              label="Base Background Color"
              onChangeComplete={color => {
                onChangeTheme('set', ['background', 'backgroundColor'], color.hex);
              }}
            />
          )}
        </div>
      );
      break;
    case 'search':
      trigger = <FormSearch placeholder="Search..." disabled />;
      break;
    case 'html':
      // TODO need to pass id to FormCodeEditor
      trigger = (
        <FormCodeEditor
          mode="html"
          input={{
            value: content,
            onChange(newValue) {
              onChangeData('set', ['content'], newValue);
            },
          }}
        />
      );
      break;
    default:
      return (
        <FormDropdown
          className="HubBlockAction-add"
          value={type}
          options={computedOptions}
          onChange={(e, { value }) => {
            if (value !== 'none') {
              onChangeData('set', ['type'], value);
            }
          }}
        />
      );
  }

  return (
    <Popup
      trigger={<div className={`HubBlockAction HubBlockAction--${type}`}>{trigger}</div>}
      position="bottom center"
      hoverable
    >
      <div className="flex items-center">
        <FormDropdown
          className="mr-3"
          selection
          value={type}
          options={computedOptions}
          onChange={(e, { value }) => onChangeData('set', ['type'], value)}
        />
        <Button
          icon="trash"
          onClick={() => {
            onChangeData('unset', []);

            if (onChangeTheme) {
              onChangeTheme('unset', []);
            }
          }}
        />
      </div>
      {options}
    </Popup>
  );
};

export default HubBlockAction;
