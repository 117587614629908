import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

const HtmlHead = props => {
  const { description, image, url, meta = [] } = props;

  let title = props.title;
  if (_.isArray(title)) {
    title = _.uniq(_.compact(title)).join(' · ');
  }

  if (!title) {
    title = 'Stoplight';
  } else {
    title += ` · Stoplight`;
  }

  return (
    <Helmet>
      <title>{title || 'Stoplight'}</title>

      {description ? <meta name="description" content={description} /> : null}
      {title ? <meta property="og:title" content={title} /> : null}
      {description ? <meta property="og:description" content={description} /> : null}
      {image ? <meta property="og:image" content={image} /> : null}
      {url ? <meta property="og:url" content={url} /> : null}

      {_.map(meta, (m, i) => (
        <meta key={i} {...m} />
      ))}
    </Helmet>
  );
};

export default HtmlHead;
