import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

import { isFeatureEnabled } from '@platform/utils/acl';

import ExplorerWrapper from '@platform/components/ExplorerWrapper';
import { Search } from '@platform/explorer/components';

const OrgExplorer = props => {
  const { orgService, userService } = props;
  const { current: org = {} } = orgService;
  const { authorizedUser: user = {} } = userService;
  const explorerStore = _.get(props.namespaceStore, 'current.explorerStore');

  const explorerEnabled = isFeatureEnabled({ user, org, feature: 'org-explorer' });

  if (!explorerEnabled) return null;

  return (
    <ExplorerWrapper showFilters={true} showOrgs={false}>
      <Search mx="auto" width="100%" fontSize="14px" store={explorerStore} />
    </ExplorerWrapper>
  );
};

export default inject('userService', 'orgService')(observer(OrgExplorer));
