import * as EX from '@core/extension';

import { faDatabase } from '@fortawesome/pro-solid-svg-icons/faDatabase';
import { faInbox } from '@fortawesome/pro-solid-svg-icons/faInbox';
import { faMagic } from '@fortawesome/pro-solid-svg-icons/faMagic';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane';
import { faPuzzlePiece } from '@fortawesome/pro-solid-svg-icons/faPuzzlePiece';
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag';

export const COMMANDS = {
  addModel: 'add:model',
  addTag: 'add:tag',
  addPath: 'add:operation',
  showCrudBuilder: 'show:crud',
  sendHttp: 'send:http',
  resetHttp: 'reset:http',
  httpToOas2: 'transform:http',
};

export const MENUS = {
  actions: EX.EDITOR_MENUS.toolbar.primary.concat(['actions']),
  http: EX.EDITOR_MENUS.toolbar.primary.concat(['http']),
};

export const MODES: {
  [key: string]: EX.IEditorMode;
} = {
  http: {
    id: 'http',
    name: 'HTTP',
    verb: 'Debugging',
    summary: 'Send requests to your API',
    icon: faPaperPlane,
  },
};

export class Oas2Extension extends EX.Spec implements EX.ISpec {
  public readonly id: string = '.oas2';
  public readonly name: string = 'OAS v2';
  public readonly longName: string = 'OpenAPI v2 Specification';
  public readonly icon: EX.IIcon = faPuzzlePiece;

  public get supportedModes() {
    return [
      this.editor.supportedModes.read,
      this.editor.supportedModes.design,
      this.editor.supportedModes.code,
      MODES.http,
    ];
  }

  public registerCommands = () => {
    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.showCrudBuilder,
        label: 'CRUD Builder',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.addPath,
        label: 'New Path',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.addModel,
        label: 'New Model',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.addTag,
        label: 'New Tag',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.sendHttp,
        label: 'Send HTTP Request',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.resetHttp,
        label: 'Reset HTTP Request',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.httpToOas2,
        label: 'Extend OAS from HTTP',
      })
    );
  };

  public registerMenus = ({ mode }: EX.IModeChangedEvent) => {
    switch (mode) {
      case EX.EDITOR_MODES.read:
        break;
      case EX.EDITOR_MODES.design:
      case EX.EDITOR_MODES.code:
        this._registerMenuActions();
        break;
      case MODES.http:
        this._registerMenuHttp();
        break;
      default:
    }
  };

  private _registerMenuActions = () => {
    if (this.editor.isReadonly) return;

    this.menus.push(this.menuRegistry.registerSubmenu(MENUS.actions));

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.actions, {
        id: COMMANDS.showCrudBuilder,
        commandId: COMMANDS.showCrudBuilder,
        label: 'CRUD',
        icon: faMagic,
      })
    );

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.actions, {
        id: COMMANDS.addPath,
        commandId: COMMANDS.addPath,
        label: 'Path',
        icon: faInbox,
      })
    );

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.actions, {
        id: COMMANDS.addModel,
        commandId: COMMANDS.addModel,
        label: 'Model',
        icon: faDatabase,
      })
    );

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.actions, {
        id: COMMANDS.addTag,
        commandId: COMMANDS.addTag,
        label: 'Tag',
        icon: faTag,
      })
    );
  };

  private _registerMenuHttp = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.http, {
        id: COMMANDS.sendHttp,
        commandId: COMMANDS.sendHttp,
        label: 'Send Request',
        icon: faPaperPlane,
        color: EX.UI.colors.primary,
      })
    );

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.http, {
        id: COMMANDS.resetHttp,
        commandId: COMMANDS.resetHttp,
        label: 'Reset',
      })
    );

    if (!this.editor.isReadonly) {
      this.menus.push(
        this.menuRegistry.registerMenuAction(MENUS.http, {
          id: COMMANDS.httpToOas2,
          commandId: COMMANDS.httpToOas2,
          label: 'Extend OAS from HTTP',
          icon: faMagic,
        })
      );
    }
  };
}
