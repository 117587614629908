import cn from 'classnames';
import React from 'react';

interface IPageContent {
  header?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  bgClassName?: string;
}
const PageContent = ({ header, children, className, bgClassName = 'bg-white' }: IPageContent) => {
  return (
    <div className={cn('flex-grow px-4', bgClassName, className)}>
      {header && <div className="container mx-auto mt-10">{header}</div>}
      <div className="container mx-auto mt-10 mb-64 flex">{children}</div>
    </div>
  );
};

export default PageContent;
