import { observer } from 'mobx-react';
import * as React from 'react';

const cn: any = require('classnames');

import { lazyInject } from '@core/ioc';
import { Icon } from '@core/ui';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';

import { DiagnosticSeverity } from '@stoplight/types';
import { MODES as EDITOR_MODES } from './store';
import { IEditor, SYMBOLS } from './types';

export interface IEditorNotifications {
  className?: string;
}

export class EditorNotifications extends React.Component<IEditorNotifications, {}> {
  @lazyInject(SYMBOLS.Editor)
  // @ts-ignore
  private editor: IEditor;

  public render() {
    const { activeMode, notifications, removeNotification } = this.editor;

    if (activeMode === EDITOR_MODES.read || !notifications.length) return null;

    const notification = notifications[0];

    return (
      <div
        className={cn('flex items-center h-10 text-white pl-4', {
          'bg-primary': notification.severity === DiagnosticSeverity.Information,
          'bg-warning': notification.severity === DiagnosticSeverity.Warning,
          'bg-negative': notification.severity === DiagnosticSeverity.Error,
          'cursor-pointer': notification.onClick ? true : false,
        })}
        onClick={notification.onClick}
      >
        <Icon
          icon={
            notification.severity > DiagnosticSeverity.Information
              ? faExclamationTriangle
              : faInfoCircle
          }
          className="mr-3"
        />

        <div className="flex-1">{notification.message}</div>

        {notification.dismissable && (
          <div
            className="flex items-center h-10 px-4 cursor-pointer hover:bg-darken-50"
            onClick={() => {
              removeNotification(notification);
            }}
          >
            <Icon icon={faTimes} />
          </div>
        )}
      </div>
    );
  }
}

export default observer(EditorNotifications);
