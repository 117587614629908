import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Button, Divider } from 'semantic-ui-react';

import { faHeart } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';

import { getConfigVar } from '@platform/utils/config';
import { emailRegex } from '@platform/utils/general';

import { Icon } from '@core/ui';

import ErrorMessage from '../ErrorMessage';
import FormInput from '../FormInput';
import AccountFormNav from '../AccountFormNav';
import AccountSSO from '../AccountSSO';
import AccountLogin from '../AccountLogin';

const AccountCreate = props => {
  const { ui = {}, updateUi, clearUi, userService, hideNav, message, onSuccess } = props;

  if (getConfigVar('SL_SSO_ENTRYPOINT')) {
    return (
      <AccountSSO entrypoint={() => (document.location.href = getConfigVar('SL_SSO_ENTRYPOINT'))} />
    );
  } else if (getConfigVar('GITHUB_URL')) {
    return <AccountSSO entrypoint={() => userService.openOAuthPopup('github', onSuccess)} />;
  } else if (getConfigVar('SL_LOGIN_PATH')) {
    // don't display the join page if custom login is used (ie, SSO is active)
    return <AccountLogin props />;
  }

  let githubCreate;
  if (getConfigVar('GITHUB_CLIENT_ID')) {
    githubCreate = [
      <div
        key="github"
        className="AccountForm-social"
        onClick={() => userService.openOAuthPopup('github', onSuccess)}
      >
        {'Create Account With GitHub'}
        <Icon className="fl-r font-extrabold text-xl" icon={faGithub} />
      </div>,
      <Divider key="or" className="AccountForm-divider" horizontal inverted>
        OR
      </Divider>,
    ];
  }

  return (
    <div className="AccountForm">
      {!hideNav && <AccountFormNav active="join" />}

      <div className="AccountForm-inner pt-6">
        <ErrorMessage
          className="pb-6"
          error={ui.error}
          onDismiss={() => updateUi('set', 'error', null)}
          bordered
        />

        {githubCreate}

        <form
          onSubmit={async e => {
            e.preventDefault();

            const errors = [];

            if (_.isEmpty(ui.email)) {
              errors.push('Please provide an email.');
            } else if (!emailRegex.test(ui.email)) {
              errors.push('Please provide a valid email.');
            }

            if (_.isEmpty(ui.username)) {
              errors.push('Please provide a username.');
            }

            if (_.isEmpty(ui.password)) {
              errors.push('Please provide a password.');
            }

            if (_.isEmpty(errors)) {
              updateUi('set', 'error', undefined);
            } else {
              updateUi('set', 'error', {
                reason: 'Invalid Form',
                errors,
              });

              return;
            }

            try {
              await userService.create({
                name: ui.username,
                username: ui.username,
                email: ui.email,
                password: ui.password,
              });

              await userService.login({
                email: ui.email,
                password: ui.password,
              });

              if (onSuccess) {
                onSuccess({ email: ui.email, username: ui.username });
              }

              clearUi();
            } catch (error) {
              updateUi('set', 'error', _.get(error, 'response.data', error));
            }
          }}
        >
          <div className="AccountForm-input-group">
            <FormInput
              input={{
                value: ui.username || '',
                onChange(e) {
                  updateUi('set', 'username', _.trim(e.target.value));
                },
              }}
              disabled={userService.loggingIn}
              placeholder="Username"
              fluid
            />
            <FormInput
              input={{
                value: ui.email || '',
                onChange(e) {
                  updateUi('set', 'email', _.trim(e.target.value));
                },
              }}
              disabled={userService.loggingIn}
              placeholder="Work Email Address"
              type="email"
              fluid
            />

            <FormInput
              input={{
                value: ui.password || '',
                type: 'password',
                onChange(e) {
                  updateUi('set', 'password', e.target.value);
                },
              }}
              disabled={userService.loggingIn}
              placeholder="Password"
              fluid
            />
          </div>

          <div className="pt-3">
            <Button
              className="AccountForm-cta"
              disabled={userService.loggingIn || userService.isSaving}
              loading={userService.loggingIn || userService.isSaving}
              type="submit"
              secondary
              fluid
            >
              Join Stoplight <Icon className="fl-r font-extrabold text-lg" icon={faHeart} />
            </Button>
          </div>
        </form>

        {message && <div className="text-white mt-10 text-sm text-center">{message}</div>}
      </div>
    </div>
  );
};

export default inject(({ userService, appStore }) => {
  return {
    ...appStore.injectUi('AccountCreate'),
    userService,
  };
})(observer(AccountCreate));
