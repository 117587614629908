import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import Autoscroll from '../Autoscroll';

class Terminal extends React.Component {
  render() {
    const {
      lines = [],
      token = '$',
      style = {},
      className = '',
      light,
      children,
      onScroll,
    } = this.props;

    const components = _.map(lines, (l, i) => {
      switch (l.type) {
        case 'comment':
          return (
            <div key={i}>
              <i># {l.text}</i>
            </div>
          );
        case 'command':
          return (
            <div key={i} className="Terminal-command">
              <span className="Terminal-token select-none">{token} </span>
              <span className="Terminal-cmd">{l.name} </span>
              <span className="Terminal-text">{l.text}</span>
            </div>
          );
        case 'break':
          return <br key={i} />;
      }

      return <div key={i} />;
    });

    return (
      <div
        className={cn('Terminal', className, { 'is-light': light })}
        style={style}
        onScroll={onScroll}
      >
        {components}

        {children}
      </div>
    );
  }
}

export default Autoscroll(Terminal);
