import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Table, Image, Button, Popup } from 'semantic-ui-react';

import TableList from '@platform/components/TableList';
import InviteMembersModal from '@platform/components/InviteMembersModal';
import DropdownMenu from '@platform/components/DropdownMenu';
import ErrorMessage from '@platform/components/ErrorMessage';

import { alert } from '@platform/utils/alert';
import { identicon } from '@platform/utils/images';
import {
  hasRole,
  accessLevels,
  accessRolesHuman,
  getHighestRole,
  filterByRoles,
} from '@platform/utils/acl';

class MemberList extends React.Component {
  static propTypes = {
    members: PropTypes.array,
    entity: PropTypes.object,
    entityType: PropTypes.string,
    handleCreate: PropTypes.func,
    handleUpdate: PropTypes.func,
    handleRemove: PropTypes.func,
    error: PropTypes.object,
    handleClearError: PropTypes.func,
    hideCollabSwitcher: PropTypes.bool,
    inverted: PropTypes.bool,
    onSearch: PropTypes.func,
    service: PropTypes.object,
    loadMore: PropTypes.func,
  };

  getMembers = () => {
    const { members = [] } = this.props;

    return _.sortBy(members, member => {
      return _.toLower(member.name);
    });
  };

  handleLeaveEntity = () => {
    const { handleRemove, authorizedUser = {}, routerStore } = this.props;

    const r = window.confirm('Are you sure you want to remove yourself?');
    if (!r) return;

    handleRemove(authorizedUser.id).then(() => {
      routerStore.push({ pathname: '/' });
    });
  };

  render() {
    const {
      authorizedUser = {},
      entity = {},
      entityType,
      error,
      handleCreate,
      handleUpdate,
      handleRemove,
      handleClearError,
      inverted,
      noSearch,
      noResultsMessage,
      canManageMembers,
      allowAdditions,
      removeConfirmText,
      memberAccessLevel = accessLevels.MEMBER,
      addMemberPlaceholder,
      onSearch,
      service,
      loadMore,
    } = this.props;

    const members = this.getMembers();
    const owners = filterByRoles({ entities: members, roles: ['OWNER'] });
    const isEntityOwner = hasRole({ entity, role: 'OWNER' });

    let actionButton;

    if (canManageMembers) {
      actionButton = () => (
        <InviteMembersModal
          id={`entity-${entity.id}`}
          name={entity.name}
          entityType={entityType}
          currentMembers={members}
          error={error}
          handleSave={handleCreate}
          inverted={inverted}
          noSearch={noSearch}
          noResultsMessage={noResultsMessage}
          allowAdditions={allowAdditions}
          addMemberPlaceholder={addMemberPlaceholder}
          memberAccessLevel={memberAccessLevel}
          currentAccessLevel={_.get(entity, 'access_level', 0)}
        />
      );
    } else {
      actionButton = () => (
        <Popup
          on="hover"
          trigger={<Button primary basic content="Invite Member" />}
          content={`Only ${entityType} admins can invite new members.`}
        />
      );
    }

    let footer;
    if (loadMore && service.loadMore) {
      footer = (
        <Button
          className="w-1/2"
          onClick={loadMore}
          disabled={service.isFinding}
          loading={service.isFinding}
        >
          Load More
        </Button>
      );
    }

    return (
      <div className="MemberList">
        <ErrorMessage error={error} onDismiss={handleClearError} className="mb-6" />

        <TableList
          loading={!service.isSearching && service.isLoading && _.isEmpty(members)}
          scope={`${entity.id}-people`}
          items={members}
          searchProps={['username', 'name', 'email']}
          noItemsMessage={() => {
            let message = `There are no members for ${entity.name}.`;

            if (canManageMembers) {
              message = `${message} To invite a member, click the invite button to the top right.`;
            }

            return message;
          }}
          actionButton={actionButton}
          cellFactories={[
            (member, key) => (
              <Table.Cell key={key} width="14">
                <div className="flex items-center">
                  <Image src={member.avatar_url || identicon(member.username)} size="mini" inline />

                  {member.state === 'pending' ? (
                    <div className="ml-3">
                      <div>{member.email}</div>
                      <div>Pending</div>
                    </div>
                  ) : (
                    <div className="ml-3">
                      <div>{member.name}</div>
                      <div>{member.username}</div>
                    </div>
                  )}
                </div>
              </Table.Cell>
            ),
            (member, key) => {
              let roleElem;
              const hasOwnerRole = hasRole({ entity: member, role: 'OWNER' });

              if (canManageMembers) {
                let options = [];

                if (isEntityOwner || hasOwnerRole) {
                  options.push({
                    title: _.capitalize(accessRolesHuman[accessLevels.OWNER]),
                    value: accessLevels.OWNER,
                    onClick: value => {
                      const r = window.confirm(
                        `Are you sure you want to make ${member.name || member.username} an owner?`
                      );
                      if (!r) return;

                      handleUpdate(member.id, { access_level: value })
                        .then(() => {
                          alert.success(`${member.name} is now an owner.`);
                        })
                        .catch(e => {
                          alert.error('Error updating member.');
                        });
                    },
                    description: (
                      <div>
                        Owner permissions + can <span className="underline">update/delete </span>
                        this {entityType}
                        's settings.
                      </div>
                    ),
                  });
                }

                options = options.concat([
                  {
                    title: _.capitalize(accessRolesHuman[accessLevels.ADMIN]),
                    value: accessLevels.ADMIN,
                    description: (
                      <span>
                        Contributor permissions + can
                        <span className="underline">
                          {' '}
                          manage members
                          {entityType === 'org' ? ' and teams ' : ' '}
                        </span>
                        in this {entityType}.
                      </span>
                    ),
                  },
                  {
                    title: _.capitalize(accessRolesHuman[memberAccessLevel]),
                    value: memberAccessLevel,
                    description: (
                      <span>
                        Members can view and <span className="underline">create projects</span> in
                        this {entityType}.
                      </span>
                    ),
                  },
                ]);

                if (entityType === 'org') {
                  options.push({
                    title: _.capitalize(accessRolesHuman[accessLevels.GUEST]),
                    value: accessLevels.GUEST,
                    description: <span>Guests can view projects in this {entityType}.</span>,
                  });
                }

                options.push({
                  divider: true,
                });

                let confirmText = 'Are you sure you want to';
                if (member.id === authorizedUser.id) {
                  confirmText = `${confirmText} leave this ${entityType}?`;
                } else {
                  confirmText = `${confirmText} remove ${member.name ||
                    member.username} from this ${entityType}?`;
                }

                if (removeConfirmText) {
                  confirmText = `${confirmText} ${removeConfirmText}`;
                }

                options.push({
                  title: member.id === authorizedUser.id ? `Leave ${entityType}` : 'Remove member',
                  icon: 'remove',
                  onClick: () => {
                    const r = window.confirm(confirmText);
                    if (!r) return;

                    handleRemove(member.id)
                      .then(() => {
                        alert.success('Member removed.');
                      })
                      .catch(e => {
                        alert.error('Error removing member.');
                      });
                  },
                });

                roleElem = (
                  <DropdownMenu
                    className="icon w-full"
                    floatLeft={true}
                    value={member.access_level}
                    onChange={value =>
                      handleUpdate(member.id, { access_level: value })
                        .then(() => {
                          alert.success('Member updated.');
                        })
                        .catch(e => {
                          alert.error('Error updating member.');
                        })
                    }
                    options={options}
                    // cannot change owner if there is only one owner
                    disabled={member.access_level === accessLevels.OWNER && _.size(owners) <= 1}
                    dropdownOptions={{
                      button: true,
                    }}
                  />
                );
              } else {
                roleElem = (
                  <div className="flex items-center">
                    {member.id === authorizedUser.id ? (
                      <Button
                        basic
                        negative
                        title={`Leave ${entityType}`}
                        icon="sign out"
                        onClick={this.handleLeaveEntity}
                      />
                    ) : (
                      <Button basic className="v-h" />
                    )}

                    <small className="text-muted ml-3">
                      {_.capitalize(getHighestRole({ entity: member, human: true }))}
                    </small>
                  </div>
                );
              }

              return (
                <Table.Cell key={key} textAlign="center">
                  {roleElem}
                </Table.Cell>
              );
            },
          ]}
          onSearch={onSearch}
          footer={footer}
        />
      </div>
    );
  }
}

export default inject(({ userService, teamService, orgService, routerStore }, props) => {
  const { entityType } = props;

  let canManageMembers;
  if (entityType === 'org') {
    canManageMembers = orgService.canUser({ action: 'manage:member' });
  } else {
    canManageMembers = teamService.canUser({ action: 'manage:member' });
  }

  return {
    canManageMembers,
    authorizedUser: userService.authorizedUser || {},
    routerStore,
  };
})(observer(MemberList));
