import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import _ from 'lodash';
import cn from 'classnames';

import FormTitleInput from '@platform/components/FormTitleInput';
import FormTextarea from '@platform/components/FormTextarea';
import ColorPicker from '@platform/components/ColorPicker';

import BlockAction from '../BlockAction/editor';
import { computeTheme } from '../../utils';
import { defaultTheme } from './index';

const BarItem = ({
  child = {},
  onChangeData,
  isSolo,
  theme = {},
  onChangeTheme,
  cache,
  updateCache,
  childIndex,
}) => {
  const { title = '', content = '', actions = [] } = child;

  const childBgStyle = _.get(theme, ['styles', 'children', childIndex, 'background'], {});
  const childTextStyle = _.get(theme, ['styles', 'children', childIndex, 'text'], {});

  return (
    <div className="BarListBlock-child flex justify-center">
      <div className="BarListBlock-content flex">
        <div className="BarListBlock-main w-1/3 pr-10">
          <div className="BarListBlock-title">
            <FormTitleInput
              placeholder="Title"
              value={title}
              onChange={(e, { value }) => onChangeData('set', ['title'], value)}
              inputStyle={childTextStyle}
            />
          </div>

          <div className="BarListBlock-actions">
            {!_.isEmpty(actions) ? (
              _.map(actions, (action, actionIndex) => {
                const actionTheme = _.get(
                  theme,
                  ['styles', 'children', childIndex, 'actions', actionIndex],
                  {}
                );

                // TODO
                const oct = null;
                // const oct = (t, p, v) => {
                //   onChangeTheme(t, ['actions', actionIndex].concat(p), v)
                // }

                return (
                  <BlockAction
                    clasName="pt-4"
                    key={actionIndex}
                    allowed={['button']}
                    action={action}
                    onChangeData={(t, p, v) =>
                      onChangeData(t, ['actions', actionIndex].concat(p), v)
                    }
                    onChangeTheme={oct}
                    theme={actionTheme}
                  />
                );
              })
            ) : (
              <BlockAction
                key={actions.length}
                allowed={['button']}
                onChangeData={(t, p, v) => onChangeData(t, ['actions', 0].concat(p), v)}
              />
            )}
          </div>
        </div>

        <div className="BarListBlock-body w-2/3">
          <FormTextarea
            placeholder="Body content..."
            value={content}
            onChange={(e, { value }) => onChangeData('set', ['content'], value)}
            inputStyle={childTextStyle}
          />
        </div>

        {false && (
          <div
            className={cn('BarListBlock-settings', {
              'is-open': _.get(cache, [childIndex, 'isOpen']),
            })}
          >
            <Popup
              trigger={<Icon name="setting" />}
              position="bottom center"
              open={cache.isOpen}
              onOpen={() => updateCache('set', [childIndex, 'isOpen'], true)}
              onClose={() => updateCache('unset', [childIndex, 'isOpen'])}
              hoverable
            >
              <ColorPicker
                inline
                color={childTextStyle.color}
                label="Base Text Color"
                onChangeComplete={color => {
                  onChangeTheme('set', ['text', 'color'], color.hex);
                }}
              />
              <ColorPicker
                inline
                color={childBgStyle.backgroundColor}
                label="Base Background Color"
                onChangeComplete={color => {
                  onChangeTheme('set', ['background', 'backgroundColor'], color.hex);
                }}
              />
            </Popup>
          </div>
        )}

        {!isSolo ? (
          <div className="BarListBlock-remove">
            <Icon
              name="trash"
              onClick={() => {
                onChangeData('unset', []);
                onChangeTheme('unset', []);
                onChangeData('set', 'actions', []);
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const BlockBarList = props => {
  const {
    WrapperFactory,
    data = {},
    onChangeData,
    theme = {},
    onChangeTheme,
    cache,
    updateCache,
  } = props;
  const { children = [] } = data;

  const computedTheme = computeTheme({ theme, defaultTheme });

  const contentElem = _.map(children, (child, childIndex) => {
    const childBgStyle = _.get(theme, ['styles', 'children', childIndex, 'background'], {});

    return (
      <div key={childIndex} className="BarListBlock pos-r" style={childBgStyle}>
        <div className="BarListBlock-insert is-before text-center">
          <Icon
            name="plus circle"
            size="big"
            onClick={() => {
              const themes = _.get(computedTheme, ['styles', 'children'], []);

              onChangeData(
                'set',
                ['children'],
                [...children.slice(0, childIndex), {}, ...children.slice(childIndex)]
              );
              onChangeTheme(
                'set',
                ['styles', 'children'],
                [...themes.slice(0, childIndex), {}, ...themes.slice(childIndex)]
              );
            }}
          />
        </div>

        <BarItem
          child={child}
          childIndex={childIndex}
          isSolo={_.size(children) === 1}
          onChangeData={(t, p, v) => onChangeData(t, ['children', childIndex].concat(p), v)}
          onChangeTheme={(t, p, v) =>
            onChangeTheme(t, ['styles', 'children', childIndex].concat(p), v)
          }
          theme={computedTheme}
          cache={cache}
          updateCache={updateCache}
        />

        <div className="BarListBlock-insert is-after text-center">
          <Icon
            name="plus circle"
            size="big"
            onClick={() => {
              const themes = _.get(computedTheme, ['styles', 'children'], []);
              onChangeData(
                'set',
                ['children'],
                [...children.slice(0, childIndex + 1), {}, ...children.slice(childIndex + 1)]
              );
              onChangeTheme(
                'set',
                ['styles', 'children'],
                [...themes.slice(0, childIndex + 1), {}, ...themes.slice(childIndex + 1)]
              );
            }}
          />
        </div>
      </div>
    );
  });

  return (
    <WrapperFactory
      {...props}
      headerProps={{ anchor: true }}
      contentElem={contentElem}
      className="flex flex-col justify-center"
    />
  );
};

export default BlockBarList;
