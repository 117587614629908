import _ from 'lodash';
import mingo from 'mingo';
import { computed } from 'mobx';
import { types, flow, getParent } from 'mobx-state-tree';

import { Collection as DisposableCollection } from '@core/disposable';

import { hasSubscriptionFeature } from '@platform/utils/billing';
import { buildExportUrl } from '@platform/utils/url';
import { safeParse, safeStringify } from '@platform/utils/json';
import { defaultFileFilter, findFileType } from '@platform/utils/projects';

import { BaseStore } from './_base';
import { BaseManager, BaseInstance } from './_manager';
import { ServiceRecord, removeRecord } from './services/_base';
import { Service as ProjectFileService } from './services/projectFile';

export const EDITOR_STORE_NAMES = [
  'hubEditorStore',
  'collectionEditorStore',
  'oas2EditorStore',
  'oas3EditorStore',
  'instanceEditorStore',
  'rawEditorStore',
  'cssEditorStore',
  'htmlEditorStore',
];

export const create = ({ data = {}, env, options = {} }) => {
  const fileService = ProjectFileService.create(data.fileService, env);

  const ProjectFile = types
    .model({
      id: types.identifier,
      record: types.maybe(types.reference(ServiceRecord)),
      _data: types.frozen(),
      dirtyCommitId: types.maybe(types.string),
    })
    .views(self => {
      return {
        get exportUrl() {
          return buildExportUrl({
            projectId: self.project_id,
            branch: self.ref,
            filePath: self.path,
          });
        },

        // some getters to make accessing common props that are stored on the service record more convenient
        get name() {
          return _.get(self, 'record.data.name', '');
        },

        get path() {
          return _.get(self, 'record.data.path', '');
        },

        get type() {
          return findFileType({ filePath: self.path });
        },

        get ref() {
          return _.get(self, 'record.data.ref');
        },

        get version() {
          return _.split(self.ref, '/')[1];
        },

        get project_id() {
          return _.get(self, 'record.data.project_id');
        },

        get originalData() {
          return _.get(self, 'record.data.content');
        },

        get data() {
          // This value can be an object or string depending
          return self._data || _.get(self, 'record.data.content');
        },

        get hasData() {
          return !_.isUndefined(self.data) && self.data !== null;
        },

        get parsedData() {
          // attempt to parse the data. fallback to the latest saved data
          return safeParse(self.data, self.originalData);
        },

        get editor() {
          const currentProjectStore = _.get(env.rootStore.stores, 'projectStore.current');
          if (!currentProjectStore) return;

          return _.find(currentProjectStore.getEditorsForProject(), { id: self.id });
        },

        get isDirty() {
          const originalData = safeStringify(self.originalData);
          const data = safeStringify(self.data);
          const editor = self.editor;

          return (editor && editor.isDirty) || !_.isEqual(originalData, data);
        },

        get isGetting() {
          return _.get(self, 'record.isGetting');
        },

        get isUpdating() {
          return _.get(self, 'record.isUpdating');
        },

        get isRemoving() {
          return _.get(self, 'record.isRemoving');
        },

        get lastCommitId() {
          return _.get(self, 'record.data.last_commit_id');
        },

        get isStale() {
          return self.dirtyCommitId && self.dirtyCommitId !== self.lastCommitId;
        },
      };
    })
    .actions(self => {
      return {
        updateData(data) {
          self._data = data;
        },

        setDirtyCommitId() {
          // Whenever we make a change, set the last commit as our dirty commit
          self.dirtyCommitId = self.isDirty ? self.dirtyCommitId || self.lastCommitId : undefined;
        },
      };
    })
    .named('ProjectStoreFile');

  const Project = types
    .model({
      fileService: types.reference(ProjectFileService),

      files: types.optional(types.array(ProjectFile), []),
      fileFilter: types.optional(types.frozen()),

      activeEnvKey: 'default',
      environments: types.optional(types.frozen()),

      fileTreeLoaded: false,
      isActive: false,
    })
    .views(self => {
      return {
        get isCreatingFile() {
          return _.get(self.fileService, 'isCreating');
        },

        get activeEnv() {
          return _.get(self.environments, [self.activeEnvKey || 'default'], '{}');
        },

        get themeFile() {
          return _.find(self.currentFiles.get(), ['record.data.path', 'theme.css']);
        },

        get configFile() {
          return _.find(self.currentFiles.get(), ['record.data.path', '.stoplight.yml']);
        },

        get lintFile() {
          return _.find(self.currentFiles.get(), ['record.data.path', 'lint.yml']);
        },

        get configEnvironments() {
          return _.get(self.configFile, 'parsedData.environments', {});
        },

        get activeConfigEnv() {
          return _.get(self.configEnvironments, self.activeEnvKey, '{}');
        },

        get resolvedEnv() {
          return _.merge({}, safeParse(self.activeConfigEnv), safeParse(self.activeEnv));
        },

        // if no minor we have a version branch, else its a release tag
        get currentRef() {
          const ref = _.get(env.rootStore.stores.routerStore, 'location.params.ref');
          if (ref) {
            return decodeURIComponent(ref);
          }
        },

        get currentVersion() {
          const [prefix, versionNumber] = _.split(self.currentRef, '/');

          return versionNumber;
        },

        // returns the loaded files for the current project and ref
        get currentFiles() {
          return computed(() => {
            const cursor = mingo.find(self.files.toJS(), {
              'record.data.ref': self.currentRef,
              'record.data.project_id': self.id,
            });

            return cursor.all();
          });
        },

        branchFiles(branch = self.currentRef) {
          return computed(() => {
            const cursor = mingo.find(self.files.toJS(), {
              'record.data.ref': branch,
              'record.data.project_id': self.id,
            });

            return cursor.all();
          });
        },

        get filteredFiles() {
          return self.fileService.filterFiles({
            files: self.currentFiles.get(),
            fileFilter: self.fileFilter,
          });
        },

        // the file path for the current file
        get currentFilePath() {
          return _.get(env, 'rootStore.stores.routerStore.location.params.filePath');
        },

        // the browser path for the current file
        get currentFilePathname() {
          const pathWithNamespace = _.get(
            env.rootStore.stores.projectService,
            'current.path_with_namespace'
          );

          if (_.isEmpty(pathWithNamespace) || _.isEmpty(self.currentFilePath)) {
            return '';
          }

          return `/${pathWithNamespace}/${encodeURIComponent(self.currentRef)}/${
            self.currentFilePath
          }`;
        },

        // the name for the current file
        get currentFileName() {
          return _.last(_.split(self.currentFilePath, '/'));
        },

        get currentFile() {
          if (self.currentFilePath) {
            return _.find(self.currentFiles.get(), ['record.data.path', self.currentFilePath]);
          }
        },

        /**
         * Files don't really have an ID, so we return projectId:ref:filePath.
         * Note that we'll have to figure out what to do for things that depend on this when filePath is changed.
         */
        get currentFileId() {
          const currentFile = self.currentFile;
          if (currentFile) {
            return self.currentFile.id;
          }
        },

        /**
         * This is used mostly for the prism conductor and multi subdomains.
         */
        get currentFileHash() {
          return self.fileService.computeFileIdHash(self.currentFileId);
        },

        get currentRoom() {
          if (self.id) {
            return _.invoke(env.rootStore.Websocket, 'room', `projects:${self.id}`);
          }
        },

        get currentFileRoom() {
          if (self.currentFileId) {
            return _.invoke(env.rootStore.Websocket, 'room', `projectFile:${self.currentFileId}`);
          }
        },

        get currentFileEditor() {
          if (!self.currentFileId) return;

          return self.currentFile.editor;
        },

        get isReadonly() {
          return !env.rootStore.stores.projectService.canUser({ action: 'push:project' });
        },

        get isDisabled() {
          const project = env.rootStore.stores.projectService.current;
          const namespace = env.rootStore.stores.namespaceService.current;

          return (
            project &&
            project.visibility === 'private' &&
            namespace &&
            // personal projects should never be disabled
            namespace.namespace_type !== 'user' &&
            !hasSubscriptionFeature(env.rootStore.stores.orgService.current, 'private_projects')
          );
        },
      };
    })
    .actions(self => {
      self.persist = [
        {
          key: 'activeEnvKey',
        },
        {
          key: 'environments',
        },
      ];

      const persistScope = `projects:${self.id}`;
      self.persistScope = persistScope;
      self._disposables = new DisposableCollection();

      return {
        afterCreate() {
          // set defaults here if type is complex
          self.fileFilter = _.cloneDeep(defaultFileFilter);
          self.environments = self.environments || {
            default: '{}',
          };

          self.setupPersist();

          if (env.rootStore.isClient) {
            setTimeout(() => {
              env.rootStore.stores.coreEditorStore.activate();

              window.onbeforeunload = e => {
                const msg = self.dirtyConfirmText();
                if (msg) {
                  e.returnValue = msg;
                  return msg;
                }
              };
            }, 0);
          }
        },

        activate() {
          if (self.isActive) return;

          self._disposables.push(
            env.rootStore.stores.routerStore.registerRouteInterceptor(self.interceptor)
          );

          if (env.rootStore.isClient) {
            self.getFileTree();
          }

          self.isActive = true;
        },

        deactivate() {
          self._disposables.dispose();
          self.isActive = false;
        },

        setEnabledPanels(panels) {
          env.rootStore.stores.coreEditorStore.setEnabledPanels(panels);
        },

        dirtyConfirmText() {
          let confirmText;
          const dirtyEditors = self.getDirtyEditorsForProject();
          if (dirtyEditors.length) {
            confirmText = `These files have unsaved changes:`;

            _.forEach(dirtyEditors, editor => {
              const id = _.split(editor.id, ':')[2];

              confirmText = `${confirmText}\n\n* ${id}`;
            });

            confirmText = `${confirmText}\n\nWould you like to discard them?`;
          }

          return confirmText;
        },

        interceptor({ type, location }) {
          const prevPathParts = _.trimStart(window.location.pathname, '/')
            .split('/')
            .slice(0, 3);

          const pathPaths = _.trimStart(location.pathname, '/')
            .split('/')
            .slice(0, 3);

          // We are navigating away from this project
          if (!_.isEqual(pathPaths, prevPathParts)) {
            const dirtyConfirmText = self.dirtyConfirmText();
            if (dirtyConfirmText) {
              const c = window.confirm(dirtyConfirmText);
              if (!c) return;

              _.invokeMap(self.getDirtyEditorsForProject(), 'reset');
            }

            self.deactivate();
          }

          return location;
        },

        getEditorsForProject(projectId) {
          let editorStores = _.pick(env.rootStore.stores, EDITOR_STORE_NAMES);

          return _.flatten(_.invokeMap(editorStores, 'getEditorsForProject', projectId || self.id));
        },

        getDirtyEditorsForProject(projectId) {
          return _.filter(self.getEditorsForProject(projectId || self.id), { isDirty: true });
        },

        removeEditorFromProject(file = {}) {
          let editorStores = _.pick(env.rootStore.stores, EDITOR_STORE_NAMES);

          // some file types have multiple associated store so loop through all and remove based on fileId
          // example prism fies have both collection editor store and instance editorstore
          _.forEach(editorStores, editor => {
            editor.removeEditor({ id: file.id });
          });
        },

        getConfigFile() {
          return self.getFile('.stoplight.yml');
        },

        getLintFile() {
          return self.getFile('lint.yml');
        },

        getFileTree: flow(function*({ force, ref } = {}) {
          // if no ref don't try to fetch files
          if ((!force && self.fileTreeLoaded) || !(self.currentRef || ref)) {
            return;
          }

          self.fileTreeLoaded = false;

          let files = [];
          let loadMore = true;
          let page = 1; // Current page
          const maxPages = 100; // Protect infinite loop

          try {
            // Continue fetching files until we've loaded every page
            while (loadMore && page < maxPages) {
              files = files.concat(
                yield self.fileService.find(
                  { projectId: self.id },
                  {
                    query: {
                      page,
                      recursive: true,
                      ref: ref || self.currentRef,
                    },
                  }
                )
              );

              loadMore = self.fileService.loadMore;
              page++; // Next page
            }
          } catch (e) {
            console.error('Error fetching file tree', e);
          }

          if (files && files.length) {
            files = self.updateLocalFiles(files);
          }

          self.fileTreeLoaded = true;

          return files;
        }),

        /**
         * Gets a specific file data by filePath.
         * Note that the file API returns the data in different format, so we have to normalize below.
         */
        getFile: flow(function*(filePath, { skipError } = {}) {
          if (_.isEmpty(filePath)) {
            return;
          }

          let file;
          try {
            const record = yield self.fileService.get(
              filePath,
              { projectId: self.id },
              {
                query: {
                  ref: self.currentRef,
                },
                skipError,
              }
            );

            file = self.updateLocalFile({
              id: record.id,
              record: record.id,
            });
          } catch (e) {
            // swallow, can't use e here because it lives in the fileService tree
          }

          return file;
        }),

        createFile: flow(function*(data, params = {}, options = {}) {
          const record = yield self.fileService.create(
            data,
            { projectId: self.id },
            {
              query: {
                branch: self.currentRef,
              },
            }
          );

          return self.updateLocalFile({
            id: record.id,
            record: record.id,
          });
        }),

        removeFile: flow(function*(filePath) {
          if (_.isEmpty(filePath)) {
            return;
          }

          yield self.fileService.remove(
            filePath,
            { projectId: self.id },
            {
              query: {
                branch: self.currentRef,
              },
              skipStore: true,
            }
          );

          const fileId = self.fileService.computeFileId({
            project_id: self.id,
            ref: self.currentRef,
            path: filePath,
          });

          self.removeLocalFile(fileId);
          return self.fileService.removeRecord(fileId);
        }),

        updateLocalFile(file) {
          let updatedFile = {
            id: file.id,
            record: file.id,
          };

          const existingIndex = _.findIndex(self.files, { id: file.id });
          if (existingIndex >= 0) {
            // don't do anything, the record reference will be updated already
            updatedFile = self.files[existingIndex];
          } else {
            self.files.push(updatedFile);
          }

          return updatedFile;
        },

        updateLocalFiles(files) {
          if (!_.isEmpty(files)) {
            return _.map(files, self.updateLocalFile);
          }

          return [];
        },

        removeLocalFile(fileId) {
          return removeRecord({ records: self.files, id: fileId });
        },

        replaceFiles(files) {
          self.files.replace([]);
          return self.updateLocalFiles(files);
        },

        setFileFilter(filter) {
          self.fileFilter = filter;
        },

        updateActiveEnv(value, { replace, preserveShared } = {}) {
          let newVal = value;

          if (!replace) {
            const current = _.clone(safeParse(self.activeEnv));
            newVal = safeParse(value);

            // preserveShared makes sure we don't uncessarily define private variables that are already in shared variables
            if (preserveShared) {
              const sharedEnv = safeParse(self.activeConfigEnv || {});
              let pickedVals = {};
              _.forEach(newVal, (v, k) => {
                // If new variable is empty and we have a sharedEnv then we should save the empty value
                // Fixes https://github.com/stoplightio/platform/issues/720 where you can't fully delete a variable
                if ((_.trim(v) === '' && !sharedEnv[k]) || _.isEqual(sharedEnv[k], v)) {
                  _.unset(current, k);
                } else {
                  pickedVals[k] = v;
                }
              });
              newVal = pickedVals;
            }

            newVal = _.merge({}, current, newVal);
          }

          self.environments = {
            ...(self.environments || {}),
            [self.activeEnvKey]: safeStringify(newVal),
          };
        },

        setActiveEnvKey(key) {
          self.activeEnvKey = key;
        },

        /**
         * Navigate to a specific file.
         */
        goToFile(filePath, { ref, query = {}, hash } = {}) {
          const currentProject = _.get(env.rootStore.stores.projectService, 'current');
          if (!currentProject) {
            return;
          }

          const targetRef = ref || self.currentRef;

          env.rootStore.stores.routerStore.push({
            pathname: `/${currentProject.path_with_namespace}/${encodeURIComponent(targetRef)}${
              filePath ? `/${filePath}` : ''
            }`,
            query,
            hash,
          });
        },
      };
    });

  /**
   * Our instance is a combination of several models.
   */
  const ProjectInstance = types
    .compose(
      BaseStore,
      BaseInstance,
      Project
    )
    .named('ProjectStoreInstance');

  const Base = types
    .model({
      instances: types.optional(types.array(ProjectInstance), []),
      fileService: ProjectFileService,

      _isLeftPanelOpen: true,
    })
    .views(self => {
      return {
        /**
         * Returns the instance for the currently active project, by browser location, if any.
         */
        get current() {
          const projectId = _.get(env.rootStore.stores.projectService, 'current.id');
          if (projectId) {
            return _.find(self.instances, { id: projectId });
          }
        },

        get isLeftPanelOpen() {
          return self._isLeftPanelOpen;
        },

        get leftSidebarShow() {
          return _.get(env.rootStore.stores.routerStore, 'location.query.show', 'files');
        },

        get currentCommitHash() {
          return _.get(env.rootStore.stores.routerStore, 'location.query.commit');
        },
      };
    })
    .actions(self => {
      self.instanceModel = ProjectInstance;
      const originalRegister = self.register;
      self.activeInstanceId = null;

      return {
        toggleLeftPanel(state, show) {
          env.rootStore.stores.routerStore.setQueryParams({
            commit: null,
            show,
          });

          self._isLeftPanelOpen = _.isUndefined(state) ? !self._isLeftPanelOpen : state;
        },

        activeInstance() {
          if (!self.activeInstanceId) return;

          return _.find(self.instances, { id: self.activeInstanceId });
        },

        /**
         * Override the default register because we want to do certain things like load the file tree on register.
         */
        register(id, data, options) {
          const instance = originalRegister(
            id,

            // attach the shared fileService
            Object.assign({}, data, { fileService }),

            options
          );

          const oldInstance = self.activeInstance() || {};

          if (oldInstance.id !== id) {
            if (oldInstance.deactivate) {
              oldInstance.deactivate();
            }
          }

          instance.activate();

          self.activeInstanceId = id;

          return instance;
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseManager,
      Base
    )
    .named('ProjectStore');

  return Service.create(Object.assign({}, data, { fileService }), env);
};
