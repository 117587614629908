import React from 'react';

import PageError from '@platform/components/PageError';

const NotFound = props => {
  return (
    <div className="px-4 h-full flex items-center justify-center">
      <PageError
        key="err"
        title="404"
        image
        message="The page you were looking for could not be found."
      />
    </div>
  );
};

export default NotFound;
