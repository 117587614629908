import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Popup, Icon } from 'semantic-ui-react';

import SortableTreeList from '../SortableTreeList';

const SplitSidebarSection = props => {
  const {
    icon,
    name,
    actions = [],
    content,
    treeData,
    htmlId,
    className = '',
    currentPath,
    viewer,
    inverted,
    folderOpenIcon,
    folderClosedIcon,
    noItemsPlaceholder,
    checkIsActive,
    checkIsChildActive,
  } = props;

  return (
    <div className={cn('SplitSidebarSection', className)} id={htmlId}>
      {!_.isEmpty(name) ? (
        <div className="SplitSidebarSection-header mb-2 flex items-center">
          <div className="SplitSidebarSection-header-inner flex">
            {icon ? (
              <div className="SplitSidebarSection-icon">
                <Icon name={icon} />
              </div>
            ) : null}

            <div className="SplitSidebarSection-name">{name}</div>
          </div>

          <div className="SplitSidebarSection-actions f">
            {_.map(actions, (a, i) => {
              if (a.contentFactory) {
                return a.contentFactory();
              }

              const button = (
                <div key={i} className="SplitSidebarSection-action" onClick={a.onClick}>
                  <Icon name={a.icon} />
                </div>
              );

              if (a.tip) {
                return <Popup key={i} trigger={button} size="tiny" offset={11} {...a.tip} />;
              }

              return button;
            })}
          </div>
        </div>
      ) : null}

      {content ? <div className="SplitSidebarSection-content">{content}</div> : null}

      {treeData && !_.isEmpty(treeData) ? (
        <div className="SplitSidebarSection-content">
          <SortableTreeList
            id={name}
            data={treeData}
            currentPath={currentPath}
            viewer={viewer}
            inverted={inverted}
            folderOpenIcon={folderOpenIcon}
            folderClosedIcon={folderClosedIcon}
            checkIsActive={checkIsActive}
            checkIsChildActive={checkIsChildActive}
          />
        </div>
      ) : null}

      {typeof treeData !== 'undefined' && _.isEmpty(treeData) ? (
        <div className="SplitSidebarSection-none">
          {noItemsPlaceholder ? noItemsPlaceholder : 'None.'}
        </div>
      ) : null}
    </div>
  );
};

const SplitSidebar = props => {
  const {
    htmlId,
    className,
    actionElems = [],
    sections = [],
    currentPath,
    viewer,
    inverted,
    folderOpenIcon,
    folderClosedIcon,
    noItemsPlaceholder,
    checkIsActive,
    checkIsChildActive,
  } = props;

  return (
    <div id={htmlId} className={cn('SplitSidebar', className, { 'is-inverted': inverted })}>
      {!_.isEmpty(actionElems) ? (
        <div className="SplitSidebar-actions flex items-center">
          <div>{actionElems}</div>
        </div>
      ) : null}

      {_.map(sections, (s, i) => (
        <SplitSidebarSection
          key={i}
          {...s}
          currentPath={currentPath}
          viewer={viewer}
          inverted={inverted}
          folderOpenIcon={folderOpenIcon}
          folderClosedIcon={folderClosedIcon}
          noItemsPlaceholder={noItemsPlaceholder}
          checkIsActive={checkIsActive}
          checkIsChildActive={checkIsChildActive}
        />
      ))}
    </div>
  );
};

export default SplitSidebar;
