import React from 'react';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import { inject, observer } from 'mobx-react';
import { Segment, Header, Button, Message, Popup, Table, Loader } from 'semantic-ui-react';

import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';

import { Icon } from '@core/ui/Icon';

import FormInput from '@platform/components/FormInput';
import FormInputLabel from '@platform/components/FormInputLabel';
import Modal from '@platform/components/Modal';
import PrivateToken from '@platform/components/PrivateToken';

import { alert } from '@platform/utils/alert';
import { timeAgo } from '@platform/utils/time';

import UserProfileLayout from './_layout';

class UserAccessTokens extends React.Component {
  componentWillMount() {
    const { updateUi, userService, impersonationTokenService } = this.props;
    const user = _.get(userService, 'authorizedUser', {});

    updateUi('set', 'mounting', true);
    updateUi('set', 'token', { user_id: _.get(user, 'id', null), scopes: ['api'] });

    impersonationTokenService.find({ userId: user.id }).then(res => {
      updateUi('set', 'tokenList', res);
      updateUi('unset', 'mounting');
    });
  }

  renderRevokeModal = (data, index) => {
    const { ui, updateUi, impersonationTokenService } = this.props;
    const tokens = _.get(ui, 'tokenList', []);

    return (
      <Modal
        triggerFactory={({ handleOpen }) => {
          return (
            <Button
              content="Revoke"
              color="red"
              size="small"
              onClick={e => {
                e.preventDefault();
                handleOpen(e);
              }}
            />
          );
        }}
        confirmButton={{
          content: ui.revoking ? 'Revoking' : 'Revoke',
          icon: ui.revoking ? 'spinner' : null,
          disabled: ui.revoking,
          color: 'red',
          size: 'small',
          onClick: (e, elem) => {
            updateUi('set', 'revoking', true);
            impersonationTokenService
              .remove(data.id, { userId: ui.token.user_id })
              .then(res => {
                updateUi('pull', 'tokenList', _.findIndex(tokens, { id: data.id }));
                elem.handleClose(e);
                updateUi('set', 'revoking', false);
              })
              .catch(e => alert.error('Error revoking token.'));
          },
        }}
        cancelButton={{
          content: 'Cancel',
          size: 'small',
        }}
        closeOnDimmerClick={false}
        contentFactory={elem => <div>Are you sure you want to revoke the token: {data.name}?</div>}
      />
    );
  };

  renderNewTokenModal = () => {
    const { ui, updateUi, impersonationTokenService } = this.props;
    const token = ui.token || {};
    const userId = token.user_id;

    return (
      <Modal
        title="New Personal Access Token Created"
        triggerFactory={({ handleOpen }) => {
          return (
            <div className="flex mt-6">
              <div className="mr-2">
                <Button
                  content={ui.creating ? 'Creating' : 'Create'}
                  icon={ui.creating ? 'spinner' : null}
                  primary
                  disabled={_.isEmpty(ui.token.scopes) || !ui.token.name}
                  onClick={e => {
                    updateUi('set', 'creating', true);
                    impersonationTokenService
                      .create(token, { userId })
                      .then(res => {
                        updateUi('push', 'tokenList', res);
                        updateUi('unset', ['token', 'name']);
                        updateUi('set', 'creating', false);
                        handleOpen(e);
                      })
                      .catch(e => {
                        updateUi('set', 'creating', false);
                        alert.error('Error creating token.');
                      });
                  }}
                />
              </div>
            </div>
          );
        }}
        cancelButton={{
          content: 'Close',
        }}
        contentFactory={elem => (
          <div>
            <Message
              warning
              content="Save your access tokens in a secure location - you won't be able to view them again!"
            />

            <PrivateToken
              className="mt-4"
              token={_.last(ui.tokenList).jwt_token}
              title="Use this token to authenticate with the Stoplight API and Prism:"
            />

            <PrivateToken
              className="mt-4"
              token={_.last(ui.tokenList).token}
              title="Use this token to git clone projects to your computer:"
            />
          </div>
        )}
      />
    );
  };

  renderTokenList = () => {
    const { ui } = this.props;
    const tokens = _.get(ui, 'tokenList', []);

    return (
      <div className="mb-6">
        <Segment attached="bottom">
          <Header as="h3" className="mb-3" dividing content="Active Tokens" />

          {ui.mounting ? (
            <Loader active />
          ) : _.isEmpty(tokens) ? (
            <Message info content="No Active Tokens" />
          ) : (
            <Table
              className="mt-6"
              basic="very"
              tableData={tokens}
              padded
              sortable
              striped
              headerRow={
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Created</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right" />
                </Table.Row>
              }
              renderBodyRow={(data, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{data.name}</Table.Cell>
                    <Table.Cell>{timeAgo(data.created_at)}</Table.Cell>
                    <Table.Cell textAlign="right" collapsing>
                      {this.renderRevokeModal(data, index)}
                    </Table.Cell>
                  </Table.Row>
                );
              }}
            />
          )}
        </Segment>
      </div>
    );
  };

  renderTokenCreate = () => {
    const { ui, updateUi } = this.props;
    const token = ui.token || {};

    return (
      <div className="mb-6">
        <Segment attached="bottom">
          <Header
            as="h3"
            className="mb-3"
            dividing
            content="Create a Token"
            subheader="Use tokens to access the Stoplight API, authenticate Prism in a continuous integration environment, and git clone projects to your computer."
          />

          <FormInput
            label="Name"
            name="name"
            placeholder="Descriptive token name. For example, `Jenkins CI`, `My Application`, etc."
            value={_.get(token, 'name', '')}
            onChange={(e, { value }) => {
              updateUi('set', 'token.name', value);
            }}
            fluid
          />

          {this.renderNewTokenModal()}
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <UserProfileLayout activeTab="access-tokens">
        {this.renderTokenCreate()}
        {this.renderTokenList()}
      </UserProfileLayout>
    );
  }
}

export default inject((stores, props) => {
  const { userService, impersonationTokenService, appStore } = stores;

  return {
    userService,
    impersonationTokenService,
    ...appStore.injectUi('UserSettings'),
  };
})(observer(UserAccessTokens));
