import React from 'react';
import _ from 'lodash';
import { Button, Menu, Icon, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { httpCodes } from '@platform/utils/http';
import { renameObjectKey } from '@platform/utils/general';
import { hashToPath } from '@platform/utils/history';

import FormInputLabel from '../FormInputLabel';
import FormSelect from '../FormSelect';
import SwaggerResponse from '../SwaggerResponse';
import RefBuilder from '../RefBuilder';
import ErrorMessage from '../ErrorMessage';

const responseTypes = _.uniq(
  [
    {
      text: 'Default',
      value: 'default',
    },
  ].concat(
    _.map(httpCodes, (tip, code) => ({
      text: `${code}: ${tip}`,
      value: String(code),
    }))
  )
);

class SwaggerResponses extends React.Component {
  getActiveCode = () => {
    const { ui, responses } = this.props;
    if (ui.code) {
      return ui.code;
    }

    return responses ? _.first(Object.keys(responses)) : undefined;
  };

  getRef = () => {
    const { responses = {} } = this.props;

    return _.get(responses, [this.getActiveCode(), '$ref']);
  };

  getMenuItems = () => {
    const { responses, readOnly, handleUpdate, updateUi } = this.props;

    const menuItems = [];
    const code = this.getActiveCode();

    if (!readOnly) {
      menuItems.push(
        <Popup
          key="add"
          trigger={
            <Menu.Item
              onClick={() => {
                handleUpdate('set', ['new'], {
                  description: '',
                });
                updateUi('set', 'code', 'new');
              }}
            >
              <div className="AddButton">
                <Icon name="plus square" color="blue" />
              </div>
            </Menu.Item>
          }
          content="Add New Response"
          position="top left"
          wide="very"
          size="small"
          hoverable
        />
      );
    }

    for (const responseCode in responses) {
      if (!_.has(responses, responseCode)) continue;

      menuItems.push(
        <Menu.Item
          key={responseCode}
          name={responseCode}
          active={code === responseCode}
          onClick={() => {
            updateUi('set', 'code', responseCode);
          }}
        />
      );
    }

    return menuItems;
  };

  handleUpdateResponseCode = val => {
    const { handleUpdate, updateUi, responses } = this.props;

    const newResponses = renameObjectKey(responses, this.getActiveCode(), val);
    updateUi('set', 'code', val);
    handleUpdate('set', [], newResponses);
  };

  handleUpdateRef = val => {
    const { handleUpdate } = this.props;
    const code = this.getActiveCode();

    if (!_.isUndefined(val)) {
      handleUpdate('set', [code], {
        $ref: val,
      });
    } else {
      handleUpdate('set', [code], {
        description: '',
      });
    }
  };

  render = () => {
    const {
      id,
      editorId,
      editor,
      responses = {},
      dereffedResponses = {},
      handleUpdate,
      updateUi,
      readOnly,
    } = this.props;

    const $ref = this.getRef();

    const { parsed, dereferencedParsed } = editor;

    const definitions = _.get(dereferencedParsed, 'definitions', {});

    const code = this.getActiveCode();
    const menuItems = this.getMenuItems();

    let response = responses[code] || null;
    if ($ref) {
      // local ref
      if (_.startsWith($ref, '#/')) {
        response = _.get(editor.dereferencedParsed, hashToPath({ hash: $ref }));
      } else {
        response = dereffedResponses[code];
      }
    }

    const responseCodeOptions = responseTypes;

    let responseType = _.find(responseTypes, { value: String(code) });
    if (!responseType && code) {
      responseType = {
        text: String(code),
        value: String(code),
      };

      responseCodeOptions.push(responseType);
    }

    const responseTypeSelection = _.isUndefined($ref) ? 'custom' : 'reference';
    const showRefBuilder = responseTypeSelection === 'reference';

    let responseEditor;
    if (_.has(response, '$ref')) {
      if (response.__error) {
        responseEditor = <ErrorMessage error={response.__error} className="mt-3" />;
      } else {
        responseEditor = (
          <div className="c-muted mt-6">no response data found at the ref target</div>
        );
      }
    } else if (response || readOnly) {
      responseEditor = (
        <SwaggerResponse
          id={`${id}-${code}`}
          parsed={editor.parsed}
          response={response}
          dereffedResponse={dereffedResponses[code]}
          definitions={definitions}
          readOnly={readOnly || $ref ? true : false}
          handleUpdate={(t, p, v) => {
            handleUpdate(t, [this.getActiveCode()].concat(p), v);
          }}
          editorId={editorId}
          preview={readOnly}
        />
      );
    } else {
      responseEditor = (
        <div className="c-muted mt-6">
          {code ? `no response data for ${code} found` : 'select or create a response above'}
        </div>
      );
    }

    return (
      <div className="SwaggerResponses">
        <div className="mb-6">
          <Menu>{menuItems}</Menu>
        </div>

        {code ? (
          <div>
            <div className="flex items-center mb-3">
              {readOnly ? (
                <div>{responseType.text}</div>
              ) : (
                [
                  <FormSelect
                    key="code"
                    className="mr-3"
                    label="Code"
                    placeholder="choose response code"
                    value={code === 'new' ? '' : code}
                    onChange={this.handleUpdateResponseCode}
                    options={responseCodeOptions}
                    search
                    allowAdditions
                  />,

                  <FormSelect
                    key="type"
                    label="Type"
                    className="mr-3"
                    value={responseTypeSelection}
                    onChange={value => {
                      if (value === responseTypeSelection) {
                        return;
                      }

                      if (value === 'reference') {
                        this.handleUpdateRef('');
                      } else {
                        this.handleUpdateRef();
                      }
                    }}
                    options={[
                      {
                        text: 'Custom Schema',
                        value: 'custom',
                      },
                      {
                        text: 'Reference',
                        value: 'reference',
                      },
                    ]}
                  />,
                ]
              )}

              <span className="mt-6">
                {readOnly ? null : (
                  <Button
                    icon="trash"
                    onClick={() => {
                      handleUpdate('unset', [code]);
                      updateUi('set', 'code', null);
                    }}
                  />
                )}
              </span>
            </div>

            {showRefBuilder ? (
              <div className="mt-6">
                <FormInputLabel label="Reference To Response" />
                <RefBuilder
                  id={`${editor.id}:${editor.currentPath.join(':')}:${code}`}
                  className="flex-1"
                  size="medium"
                  initialSource="local"
                  localData={parsed}
                  $ref={$ref || ''}
                  fileFilter={{ modeling: [/oas2/] }}
                  routeDataTargets={{ oas2: ['responses'] }}
                  onComplete={this.handleUpdateRef}
                  targets
                />
              </div>
            ) : null}
          </div>
        ) : null}

        {responseEditor}
      </div>
    );
  };
}

export default inject(({ appStore, oas2EditorStore }, { id, editorId }) => {
  const editor = oas2EditorStore.getEditor({ id: editorId });

  return {
    editor,
    ...appStore.injectUi(`${id}-responses`),
  };
})(observer(SwaggerResponses));
