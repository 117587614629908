import jsf from '@stoplight/json-schema-faker';

import { dereferenceSchema } from './helpers';

import { safeStringify, safeParse } from '../json';

jsf.option({
  failOnInvalidTypes: false,
  failOnInvalidFormat: false,
  alwaysFakeOptionals: true,
  optionalsProbability: 1,
  fixedProbabilities: true,
  ignoreMissingRefs: true,
  useExamplesValue: true,
  useDefaultValue: true,
  maxItems: 2,
  maxLength: 10,
});

export const fakeExampleFromSchema = (schema, { skipDereference } = {}) => {
  // cleanout immutable and other prototype properties that might mess up jsf
  let s = safeParse(
    safeStringify(schema, 0, (key, val) => {
      // remove $refs from the schema... means its a cirular prop and jsf will choke because won't be able to resolve
      if (key === '$ref') return;
      return val;
    }),
    {}
  );

  if (!skipDereference) {
    s = dereferenceSchema(s, { definitions: s.definitions || {} }, true);
  }

  return jsf.resolve(s);
};
