module.exports = {
  ENV: 'development',
  NODE_ENV: 'development',
  RELEASE_STAGE: 'development',
  BUILD_ID: '000',

  SL_APP_HOST: 'http://localhost:3100',
  SL_PREVIEW_HOST: 'http://localhost:6969',
  SL_API_HOST: 'http://localhost:3030',
  SL_GITLAB_HOST: 'http://localhost:8000',
  SL_PRISM_HOST: 'http://localhost:4050',
  SL_PUBS_HOST: 'localhost',
  SL_PUBS_INGRESS: 'ingress.localhost',
  SL_PUBS_SCHEME: '',
  SL_PUBS_PORT: '',
  SL_MIGRATION_HOST: '',
  // If testing SAML integration
  // SL_SSO_ENTRYPOINT: 'http://localhost:3030/sso/global/saml/login',
  // If testing LDAP integration
  // SL_LOGIN_PATH: '/sso/global/ldap/login',
  SL_SSO_ENTRYPOINT: '',
  SL_LOGIN_PATH: '',
  SL_DISABLE_PRISM_SUBDOMAINS: 0,
  SL_EXPERIMENTAL_FEATURES: 'org-explorer, user-explorer, user-projects',
  SL_DISABLE_NEW_ORGS: false,
  SL_DISABLE_NEW_PROJECT_FILES: false,
  SL_NO_PROJECT_VISIBILITY: false,

  AWS_BUCKET: 'user-content-dev.stoplight.io',
  GITHUB_URL: '',
  GITHUB_CLIENT_ID: '2fead212d04013c864b2',
  STRIPE_PK: 'pk_test_kpBgbzToWW21hZDan1nRNnVF',
  ACCOUNT_DOCK_KEY: 'ad_acco_a67qwbr1k0j39lmz',
  GTM_KEY: '',
  GA_KEY: '',
  INTERCOM_KEY: '',
  BUGSNAG_KEY: '',
  HEAP_KEY: '',
  FULLSTORY_KEY: '',
  ZENDESK_KEY: '49da5d81-678b-402a-a26e-7580ffb3349e',
  HUBSPOT_JOIN_FORM_ID: '',
  HUBSPOT_PORTAL_ID: '',
};
