import React from 'react';

import FormTextarea from '../FormTextarea';

const FormTitleInput = ({ className = '', onChange, value = '', ...props }) => {
  return (
    <FormTextarea
      className={`FormTitleInput ${className}`}
      onChange={e => {
        onChange(e, { value: e.target.value });
      }}
      minRows={1}
      value={value}
      {...props}
    />
  );
};

export default FormTitleInput;
