import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { BaseStore } from '../_base';
import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/orgs',
      perPage: 100,
    })
    .views(self => {
      return {
        // returns the org for the current browser location, from local records
        get current() {
          const { namespaceId, orgId } =
            _.get(env.rootStore, 'stores.routerStore.location.params') || {};

          const id = namespaceId || orgId;

          if (!id) {
            return;
          }

          return self.getLocal(id, ['id', 'path']).get();
        },

        /**
         * returns the orgs for the current browser location, from local records
         * for now, this is all of them (currently auth'd user)
         */
        get currentList() {
          return self.findLocal({ sort: { name: 1 } }).get();
        },
      };
    })
    .actions(self => {
      return {
        updateImage(id, { file } = {}) {
          const formData = new FormData();
          formData.append('avatar', file);
          return self.update(id, formData);
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('OrgService');

  return Service.create(data, env);
};
