import _ from 'lodash';
import React from 'react';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { Menu, Label, Icon } from 'semantic-ui-react';
import { Link } from '@platform/utils/router';

import Crumbs from '../Crumbs';
import ImageIcon from '../ImageIcon';
import TrialBadge from '../TrialBadge';

class PageHeader extends React.Component {
  componentWillMount() {
    this.initStore(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { id, activeTab } = this.props;
    const { id: nextId, activeTab: nextTab } = nextProps;

    // TODO think of a better equailty
    // re-init when the tab is the same to preserve query params
    if (id !== nextId || activeTab !== nextTab) {
      const editor = this.getStore();
      this.initStore(nextProps);
    }
  }

  getStore = () => {
    const { namespaceStore, id } = this.props;
    return namespaceStore.getStore({ id }) || {};
  };

  initStore = props => {
    props.namespaceStore.initStore({ id: props.id, activeTab: props.activeTab });
  };

  render() {
    const {
      className = '',
      tabs = [],
      basePath,
      imageUrl,
      iconName,
      subtitle,
      crumbs,
      routerStore,
    } = this.props;

    const { activeTab, updateTab, namespaceType } = this.getStore();

    return (
      <div className={cn('PageHeader px-4 bg-grey-lightest', className)}>
        <div className="container mx-auto flex items-center h-32">
          <ImageIcon imageUrl={imageUrl} iconName={iconName} />

          <div className="flex-grow">
            <div className="flex items-center justify-between">
              <div className="PageHeader-title ml-3">
                <Crumbs
                  className="PageHeader-crumbs"
                  crumbs={crumbs || routerStore.currentRouteCrumbs}
                />
              </div>
              <TrialBadge />
            </div>

            {subtitle && <div className="PageHeader-subtitle">{subtitle}</div>}
          </div>
        </div>

        <div className="container mx-auto">
          {tabs.length ? (
            <Menu tabular borderless size="small" className="PageHeader-tabs">
              {tabs.map((tab, i) => {
                if (_.has(tab, 'visible') && !tab.visible) {
                  return null;
                }

                const { icon, label, name, path } = tab;
                const href = i === 0 ? basePath : `${basePath}/${path || name}`;

                return (
                  <Menu.Item
                    fitted
                    as="div"
                    key={i}
                    name={name}
                    active={name === activeTab}
                    onClick={() => updateTab(name)}
                  >
                    <Link to={{ pathname: href }}>
                      {icon && <Icon name={icon} />}
                      <span>{_.upperFirst(label || name)}</span>
                    </Link>
                  </Menu.Item>
                );
              })}
            </Menu>
          ) : null}
        </div>
      </div>
    );
  }
}

export default inject(({ routerStore, namespaceStore, namespaceService, teamService }) => {
  const namespaceId = _.get(namespaceService.current, 'id');
  const teamId = _.get(teamService.current, 'id');

  return {
    id: `${namespaceId}-${teamId}`,
    routerStore,
    namespaceStore,
  };
})(observer(PageHeader));
