import React from 'react';
import CommitList from '../CommitList';
import CommitViewer from '../CommitViewer';

class ProjectHistory extends React.Component {
  render() {
    return (
      <div className="flex h-full">
        <CommitList {...this.props} />
        <CommitViewer {...this.props} />
      </div>
    );
  }
}

export default ProjectHistory;
