import React from 'react';
import { inject, observer } from 'mobx-react';

import PageContent from '../../components/PageContent';
import MemberList from '../../components/MemberList';

class TeamMembers extends React.Component {
  findItems = ({ search, page } = {}) => {
    const { service, team } = this.props;
    const query = {};
    let skipPagination = false;

    if (search) {
      query.query = search;
      skipPagination = true;
    } else {
      query.page = page || service.getCurrentPage() + 1;
    }

    service.find(
      { teamId: team.id },
      {
        query,
        skipPagination,
      }
    );
  };

  componentWillMount() {
    const { team } = this.props;

    if (team.id) {
      this.findItems({ page: 1 });
    }
  }

  handleCreate = (id, access_level) => {
    const { service, params } = this.props;
    return service.create({ user_id: id, access_level }, params);
  };

  handleUpdate = (id, update) => {
    const { service, params } = this.props;
    return service.update(id, update, params);
  };

  handleRemove = id => {
    const { service, params } = this.props;
    return service.remove(id, params);
  };

  render() {
    const { team, service } = this.props;
    const { currentList, error, clearError } = service;

    return (
      <PageContent>
        <div className="flex-grow">
          <MemberList
            members={currentList}
            entity={team}
            entityType="team"
            handleCreate={this.handleCreate}
            handleUpdate={this.handleUpdate}
            handleRemove={this.handleRemove}
            error={error}
            handleClearError={clearError}
            hideCollabSwitcher
            addMemberPlaceholder="Add existing org members to this team"
            service={service}
            loadMore={this.findItems}
            onSearch={searchQuery => {
              this.findItems({ search: searchQuery });
            }}
          />
        </div>
      </PageContent>
    );
  }
}

export default inject((stores, props) => {
  const { teamService, teamMemberService } = stores;

  const team = teamService.current || {};

  return {
    service: teamMemberService,
    team,
    params: {
      teamId: team.id,
    },
  };
})(observer(TeamMembers));
