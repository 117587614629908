import _ from 'lodash';

import { computeTreeData } from '../entities';
import { safeParse } from '../json';

export const computeSidebarTree = (specData, props = {}) => {
  const { currentPath = [], location, editor, hideSectionActions, hideItemActions, meta } = props;
  const data = safeParse(specData || {});
  const folderProps = {
    data,
    currentPath,
    location,
    editor,
    hideItemActions,
    meta,
  };

  const coverageMeta = [];
  const resultMeta = [];

  if (!editor.collectionRunning && !_.isEmpty(editor.currentCollectionStats)) {
    // Coverage Section
    if (_.get(editor, 'coverageReport.codeCount', 0) > 0) {
      coverageMeta.push({
        id: 'cov',
        className: editor.coveragePercentColor ? `c-${editor.coveragePercentColor}` : '',
        name: `${_.get(editor, 'coverageReport.coveragePercent.basic', 0)}%`,
      });
    }

    // Results Section

    const time = _.find(editor.currentCollectionStats, { id: 'time' });
    const passed = _.find(editor.currentCollectionStats, { id: 'passed' });
    const failed = _.find(editor.currentCollectionStats, { id: 'failed' });
    if (passed || failed) {
      resultMeta.push({
        id: 'pf',
        name: `${_.get(passed, 'value', 0)}/${_.get(passed, 'value', 0) +
          _.get(failed, 'value', 0)}`,
      });
    } else if (time) {
      resultMeta.push({
        id: 'time',
        name: time.value,
      });
    }

    let resClass = 'c-positive';
    if (_.get(failed, 'value')) {
      resClass = 'c-negative';
    }
    resultMeta.push({
      id: 'res',
      className: resClass,
      icon: 'circle',
    });
  } else {
    resultMeta.push({
      id: 'res',
      icon: 'circle outline',
    });
  }

  const basicsTree = [
    {
      name: 'Home',
      isActive: _.isEmpty(currentPath),
      href: {
        pathname: location.pathname,
        query: {
          ...(location.query || {}),
          edit: undefined,
        },
      },
      noToggle: true,
      icon: 'home',
    },
    {
      name: 'Swagger/OAS Coverage',
      isActive: _.isEqual(currentPath, ['settings', 'testing']),
      href: {
        pathname: location.pathname,
        query: {
          ...(location.query || {}),
          edit: '/settings/testing',
        },
      },
      noToggle: true,
      icon: 'legal',
      meta: coverageMeta,
    },
    {
      name: 'Run Result',
      isActive: _.isEqual(currentPath, ['result']),
      href: {
        pathname: location.pathname,
        query: {
          ...(location.query || {}),
          edit: '/result',
        },
      },
      noToggle: true,
      icon: editor.collectionRunning ? 'coffee' : 'inbox',
      meta: resultMeta,
    },
  ];

  const beforeTree = computeTreeData(data.before, {
    ...folderProps,
    treePath: ['before'],
  });

  const scenariosTree = computeTreeData(data.scenarios, {
    ...folderProps,
    treePath: ['scenarios'],
  });

  const afterTree = computeTreeData(data.after, {
    ...folderProps,
    treePath: ['after'],
  });

  const utilitiesTree = computeTreeData(data.utilities, {
    ...folderProps,
    treePath: ['utilities'],
  });

  return [
    {
      treeData: basicsTree,
    },
    {
      name: 'Before',
      treeData: beforeTree,
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add',
                content:
                  'All scenarios in the before stage are run concurrently, before your main collection scenarios.',
              },
              onClick() {
                editor.addScenario(null, { path: ['before'] });
              },
            },
            {
              icon: 'sort',
              tip: {
                content: 'Sort alphabetically.',
              },
              onClick() {
                editor.sortScenarios('before');
              },
            },
          ]
        : [],
    },
    {
      name: 'Scenarios',
      treeData: scenariosTree,
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add',
                content: 'All scenarios are run concurrently when you run your collection.',
              },
              onClick() {
                editor.addScenario(null, { path: ['scenarios'] });
              },
            },
            {
              icon: 'sort',
              tip: {
                content: 'Sort alphabetically.',
              },
              onClick() {
                editor.sortScenarios('scenarios');
              },
            },
          ]
        : [],
    },
    {
      name: 'After',
      treeData: afterTree,
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add',
                content:
                  'All scenarios in the after stage are run concurrently, after your main collection scenarios.',
              },
              onClick() {
                editor.addScenario(null, { path: ['after'] });
              },
            },
            {
              icon: 'sort',
              tip: {
                content: 'Sort alphabetically.',
              },
              onClick() {
                editor.sortScenarios('after');
              },
            },
          ]
        : [],
    },
    {
      name: 'Utilities',
      treeData: utilitiesTree,
      icon: 'wrench',
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add Utility',
                content:
                  'Utility scenarios are NOT run with your collection. Use them to de-duplicate common tasks. Reference them from other scenarios.',
              },
              onClick() {
                editor.addScenario(null, { path: ['utilities'] });
              },
            },
            {
              icon: 'sort',
              tip: {
                content: 'Sort alphabetically.',
              },
              onClick() {
                editor.sortScenarios('utilities');
              },
            },
          ]
        : [],
    },
  ];
};
