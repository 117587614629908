import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import FormInput from '../FormInput';
import EditorContentSection from '../EditorContentSection';

import SwaggerResponse from '../SwaggerResponse';
import { SwaggerExtensions } from '../SwaggerExtensions';

class SwaggerRootResponse extends React.Component {
  handleUpdateParameterType = (e, { value }) => {
    const { onChange } = this.props;

    onChange('set', ['in'], value);
    if (value === 'body') {
      onChange('set', ['schema'], {
        type: 'object',
        properties: {},
      });
    } else {
      onChange('unset', ['schema']);
    }
  };
  render = () => {
    const {
      id,
      editorId,
      parsed,
      dereffedValue = {},
      value = {},
      onChange,
      pathKey,
      onPathKeyChange,
      ui,
      updateUi,
      clearUi,
      currentPath,
    } = this.props;

    const definitions = _.get(parsed, 'definitions');

    return (
      <div>
        <EditorContentSection id="SwaggerRootResponse">
          <div className="mb-6">
            <FormInput
              value={ui.hasOwnProperty('pathKey') ? ui.pathKey : pathKey || ''}
              onChange={e => {
                updateUi('set', ['pathKey'], e.target.value);
              }}
              onBlur={() => {
                onPathKeyChange(pathKey, ui.pathKey, () => {
                  clearUi();
                });
              }}
              size="big"
              label="response key"
              placeholder="example: `errors:500`"
              required
              action
              fluid
            />
          </div>

          <SwaggerResponse
            id={id}
            editorId={editorId}
            parsed={parsed}
            response={value}
            dereffedResponse={dereffedValue}
            definitions={definitions}
            handleUpdate={(t, p, v) => {
              onChange(t, p, v);
            }}
          />
        </EditorContentSection>

        <EditorContentSection id="SwaggerRootResponse:extensions" title="Extensions">
          <SwaggerExtensions
            id={id}
            value={value}
            onChange={(t, p, v) => {
              let value = v;

            // When moving, make sure to only move within the value object since we're just renaming the x-key
              if (t === 'move') {
                value = currentPath.concat(value);
              }

              onChange(t, p, value);
            }}
          />
        </EditorContentSection>
      </div>
    );
  };
}

export default inject(({ appStore }, { id }) => {
  return {
    ...appStore.injectUi(`${id}-rootresponse`),
  };
})(observer(SwaggerRootResponse));
