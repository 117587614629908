import _ from 'lodash';
import { isInTypeGroup, removeValidations, isOfType } from './utils';
import { combinerTypes, complexTypes } from './schemaTypes';

export function changeType(
  props,
  parentSchema,
  currentType,
  nextType,
  path,
  currentSubtype,
  nextSubtype,
  nextRef
) {
  let schema = parentSchema ? parentSchema : _.cloneDeep(props.schema) || {};
  const currentIsCombiner = isInTypeGroup(combinerTypes, currentType);
  const nextIsCombiner = isInTypeGroup(combinerTypes, nextType);
  const currentIsComplex = isInTypeGroup(complexTypes, currentType);
  const nextIsComplex = isInTypeGroup(complexTypes, nextType);
  const isRoot = !parentSchema && path.length === 0;
  const currentIsRef = currentType === '$ref';
  const nextIsRef = nextType === '$ref';

  if (currentType !== nextType) {
    if (nextIsCombiner) {
      if (currentIsCombiner) {
        // if we're switching combiners, just move them over to the new combiner type
        const combinerChildren = _.get(schema, path.concat([currentType]));
        _.set(schema, path.concat([nextType]), combinerChildren);
      } else if (currentIsComplex) {
        // if current is complex, try and move the children over to the combiner
        const currentChildren = _.get(schema, path.concat(['children'])) || [];

        _.set(schema, path.concat([nextType]), [
          {
            children: currentChildren,
            type: currentType,
          },
        ]);
      } else {
        // preserve various existing properties when making the switch
        const newCombiner = _.pick(
          _.get(schema, path, {}),
          'name',
          'description',
          'default',
          'deprecated'
        );
        newCombiner[nextType] = [];

        if (isRoot) {
          schema = newCombiner;
        } else {
          _.set(schema, path, newCombiner);
        }
      }

      _.unset(schema, path.concat('type'));
    } else if (nextIsComplex) {
      if (currentIsCombiner) {
        if (nextType === 'object') {
          const combierFirstChild = _.get(schema, path.concat([currentType, '0', 'children']));
          // if next is an object, try and move the combiner children over
          _.set(schema, path.concat(['children']), combierFirstChild);
        }
      }

      _.set(schema, path.concat(['type']), nextType);
    } else if (!nextIsRef) {
      if (nextType) {
        _.set(schema, path.concat(['type']), nextType);
      } else {
        _.unset(schema, path.concat(['type']));
      }
    }

    // various cleanup below
    if (currentIsRef && !isOfType('$ref', nextType)) {
      // if the current is a ref, we need to clean that up (next can't be ref..)
      _.unset(schema, path.concat(['$ref']));
    }

    if (currentIsCombiner) {
      _.unset(schema, path.concat([currentType]));
    }

    if (currentIsComplex && !nextIsComplex && nextType !== 'object') {
      _.unset(schema, path.concat(['children']));
    }

    // remove validations that arent appropriate for the current types
    removeValidations(schema, path);
  }

  if (nextIsRef && !nextSubtype) {
    _.set(schema, path.concat(['$ref']), nextRef);
    _.unset(schema, path.concat(['type']));
  }

  // Little bit of recursion here to apply all the same stuff to the subtype
  if (nextSubtype) {
    changeType(
      props,
      schema,
      currentSubtype,
      nextSubtype,
      path.concat(['children', 0]),
      null,
      null,
      nextRef
    );
  } else if (nextType !== 'object') {
    _.unset(schema, path.concat(['children']));
  }

  if (parentSchema) {
    return schema;
  }

  return props.onChangeHandler('set', [], schema);
}
