import React from 'react';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

// auto scroll and scroll to index should not be used together
export default class ScrollContainer extends React.Component {
  constructor(props, ...rest) {
    super(props, ...rest);

    this.hasScrolled = true;
  }

  componentDidMount() {
    this.handleAutoScroll();
    this.scrollToIndex();
  }

  componentDidUpdate() {
    this.handleAutoScroll();
  }

  handleAutoScroll = () => {
    const { autoScroll } = this.props;
    if (autoScroll && this.hasScrolled) {
      if (autoScroll === 'top') {
        this.scrollToTop();
      } else {
        this.scrollToBottom();
      }
    }
  };

  onUpdate = values => {
    const { onUpdate, autoScroll, threshold } = this.props;

    if (onUpdate) {
      onUpdate(values);
    }

    const bottom = values.scrollTop + values.clientHeight;

    if (autoScroll === 'top') {
      this.hasScrolled = values.scrollTop - threshold >= 0;
    } else {
      this.hasScrolled = bottom >= values.scrollHeight - threshold;
    }
  };

  scrollToIndex = (index, force) => {
    const { scrollToIndex, offset = 0 } = this.props;

    if (!scrollToIndex && !force) return;

    const elem = this[index || scrollToIndex];

    if (elem) {
      const elemLocation = elem.offsetTop + offset;

      this.scrollTop(elemLocation);
    }
  };

  scrollTop = (top = 0) => {
    return this.scrollbar.scrollTop(top);
  };
  scrollLeft = (left = 0) => {
    return this.scrollbar.scrollLeft(left);
  };
  scrollToTop = () => {
    return this.scrollbar.scrollToTop();
  };
  scrollToBottom = () => {
    return this.scrollbar.scrollToBottom();
  };
  scrollToLeft = () => {
    return this.scrollbar.scrollToLeft();
  };
  scrollToRight = () => {
    return this.scrollbar.scrollToRight();
  };
  getScrollLeft = () => {
    return this.scrollbar.getScrollLeft();
  };
  getScrollTop = () => {
    return this.scrollbar.getScrollTop();
  };
  getScrollWidth = () => {
    return this.scrollbar.getScrollWidth();
  };
  getScrollHeight = () => {
    return this.scrollbar.getScrollHeight();
  };
  getClientWidth = () => {
    return this.scrollbar.getClientWidth();
  };
  getClientHeight = () => {
    return this.scrollbar.getClientHeight();
  };
  getValues = () => {
    return this.scrollbar.getValues();
  };

  render() {
    // pull out autoScroll and scrollToIndex so they are not in scrollbarProps (don't want them spred onto <Scrollbars /> component)
    const { autoScroll, scrollToIndex, children = [], ...scrollbarProps } = this.props;

    let scrollItems;

    if (_.isArray(children)) {
      scrollItems = _.map(children, (child, index) =>
        React.createElement('div', { key: index, ref: elem => (this[index] = elem) }, child)
      );
    } else {
      scrollItems = React.createElement('div', {}, children);
    }

    return (
      <Scrollbars {...scrollbarProps} ref={ref => (this.scrollbar = ref)} onUpdate={this.onUpdate}>
        {scrollItems}
      </Scrollbars>
    );
  }
}
