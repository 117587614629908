import _ from 'lodash';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';

import { Box, Flex } from '@stoplight/ui-kit';
import { Link } from '@platform/utils/router';

const SidebarFilterSettings = props => {
  const { filterName, namespaceStore, onUpdate = _.noop } = props;
  const { current = {} } = namespaceStore;
  const activeItems = _.get(current.activeFilterSettings, filterName);

  const toggleSetting = setting => {
    onUpdate(setting);

    if (!_.has(activeItems, setting)) {
      current.updateActiveFilterSettings('set', [filterName, setting], true);
    } else {
      current.updateActiveFilterSettings('unset', [filterName, setting]);
    }
  };

  const settings = [
    {
      name: 'Display full tags',
      field: 'displayFullTags',
      value: false,
    },
  ];

  return (
    <Box p="14px 12px" mb="20px" border="1px solid rgb(218, 225, 231)" width="200px">
      <Flex pb="8px" mb="5px" alignItems="center" borderBottom="1px solid rgb(218, 225, 231)">
        <Box flex={1} fontSize="12px" fontWeight={600}>
          Filter Settings
        </Box>
      </Flex>

      {_.map(settings, (item, i) => {
        const isActive = _.get(activeItems, item.field, false);

        return (
          <Box
            key={i}
            pt="6px"
            cursor="pointer"
            fontSize=".85em"
            color={'rgba(0,0,0,.5)'}
            css={{
              userSelect: 'none',
              ':hover': { color: 'black' },
            }}
            onClick={() => toggleSetting(item.field)}
          >
            <Flex alignItems="center" height="100%">
              <Box
                borderRadius="50%"
                width="10px"
                height="10px"
                mt="1px"
                position="relative"
                mr="4px"
                border="1px solid  #df22ff"
                backgroundColor={isActive ? '#df22ff' : null}
              >
                {isActive && (
                  <Box
                    as={Icon}
                    name="check"
                    size="small"
                    position="absolute"
                    left="-1px"
                    color="white"
                  />
                )}
              </Box>

              <Box flex={1}>Display full tags</Box>
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

export default inject('namespaceStore')(observer(SidebarFilterSettings));
