import React from 'react';
import _ from 'lodash';
import _move from 'lodash-move';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import SortableList from '../SortableList';
import FormInput from '../FormInput';
import FormTextarea from '../FormTextarea';
import FormInputLabel from '../FormInputLabel';
import FormDropdown from '../FormDropdown';
import FormSearch from '../FormSearch';

class FormInputList extends React.Component {
  static propTypes = {
    handleUpdate: PropTypes.func.isRequired,
  };

  removeField = index => {
    const { fields, handleUpdate, preserveProp } = this.props;
    return () => {
      if (fields.length === 1 && !preserveProp) {
        handleUpdate('unset', []);
      } else {
        handleUpdate('pull', [], index);
      }
    };
  };

  addField = e => {
    if (e) e.preventDefault();

    const { handleUpdate, properties } = this.props;

    const newField = {};

    for (let prop of properties) {
      if (!prop.name) {
        continue;
      }

      newField[prop.name] = prop.default || '';
    }

    handleUpdate('push', [], newField);
  };

  renderAddButton = () => {
    const {
      addButton = {
        content: this.props.addText || 'Add',
        size: 'tiny',
        basic: true,
      },
      disabled,
      allowAdditions = true,
    } = this.props;

    if (!allowAdditions) return null;

    if (typeof addButton === 'function') {
      return addButton();
    }

    return <Button onClick={this.addField} {...addButton} disabled={disabled} />;
  };

  render() {
    const {
      title,
      className,
      fields = [],
      properties = [
        {
          type: 'input',
          name: 'name',
          placeholder: this.props.namePlaceholder || '',
        },
        {
          type: 'input',
          name: 'value',
          placeholder: this.props.valuePlaceholder || '',
        },
      ],
      meta = {},
      disabled,
      label,
      tip,
      required,
      sortable,
      handleUpdate,
    } = this.props;

    let hasLabels;
    let propLabels;

    if (!_.isEmpty(fields)) {
      propLabels = _.map(properties, (prop, propIndex) => {
        const classNames = cn('flex-1 mr-2', prop.className, {
          hidden: prop.hidden,
        });

        if (prop.label) {
          hasLabels = true;
          return (
            <FormInputLabel
              key={propIndex}
              className={classNames}
              label={prop.label}
              required={prop.required}
              tip={prop.tip}
              {...prop.labelProps || {}}
            />
          );
        }

        return <div key={propIndex} className={classNames} {...prop.labelProps || {}} />;
      });
    }

    let labelsElem;
    if (hasLabels) {
      labelsElem = (
        <div key="labels" className="mt-6 flex items-center">
          {propLabels}
          <div className="px-6" />
        </div>
      );
    }

    let itemElems = [];
    const sortableItemElems = [];

    if (hasLabels) {
      itemElems.push(labelsElem);
      sortableItemElems.push({
        id: 'props',
        elem: labelsElem,
        disableDrag: true,
      });
    }

    itemElems = itemElems.concat(
      _.map(fields, (field = {}, index) => {
        let hasError = false;

        const propItems = _.map(properties, (prop, i) => {
          const key = `${index}-${i}`;
          const items = [];
          const fieldValue = field ? field[prop.name] : undefined;
          const fieldError = field ? field.error : undefined;
          if (fieldError) {
            hasError = true;
          }

          const classNames = cn('flex-1 mr-2', prop.className, {
            hidden: prop.hidden,
          });

          switch (prop.type) {
            case 'button':
              items.push(
                <div className="mr-1">
                  <Button key={key} {...prop.buttonProps || {}} />
                </div>
              );
              break;
            case 'dropdown':
              items.push(
                <FormDropdown
                  key={key}
                  className={classNames}
                  placeholder={prop.placeholder}
                  options={prop.options}
                  value={fieldValue || []}
                  onChange={(e, { value }) => {
                    handleUpdate('set', [index, prop.name], value);
                  }}
                  selection
                  fluid
                  {...prop.extraProps}
                  {...prop.dropdownProps}
                />
              );
              break;
            case 'search':
              items.push(
                <FormSearch
                  key={key}
                  icon={false}
                  className={classNames}
                  value={fieldValue || ''}
                  results={prop.options}
                  placeholder={prop.placeholder}
                  onSearchChange={(e, value) => {
                    handleUpdate('set', [index, prop.name], value);
                  }}
                  onResultSelect={(e, result) => {
                    if (prop.onResultSelect) {
                      prop.onResultSelect(index, result, field);
                    } else {
                      handleUpdate('set', [index, prop.name], result.value);
                    }
                  }}
                  showResultsOnClick
                  filterResults
                  required
                />
              );
              break;
            case 'custom':
              items.push(prop.factory(field, index, key));
              break;
            case 'textarea':
              items.push(
                <FormTextarea
                  bordered
                  minRows={1}
                  maxRows={prop.maxRows}
                  inputStyle={{ background: 'white', border: '1px solid rgba(34,36,38,.15)' }}
                  {...prop.extraProps}
                  key={key}
                  className={cn(classNames, 'flex flex-col')}
                  value={fieldValue || ''}
                  onChange={(e, { value }) => {
                    if (prop.onChange) {
                      prop.onChange(index, value);
                    }

                    handleUpdate('set', [index, prop.name], value);
                  }}
                  onBlur={() => {
                    if (prop.onBlur) {
                      prop.onBlur(index, fieldValue);
                    }
                  }}
                  disabled={meta.submitting || disabled}
                  placeholder={prop.placeholder}
                />
              );
              break;
            default:
              items.push(
                <FormInput
                  {...prop.extraProps}
                  error={prop.showError ? fieldError : undefined}
                  key={key}
                  type={prop.type || 'text'}
                  className={classNames}
                  value={fieldValue || ''}
                  onChange={(e, { value }) => {
                    if (prop.onChange) {
                      prop.onChange(index, value);
                    }

                    handleUpdate('set', [index, prop.name], value);
                  }}
                  onBlur={() => {
                    if (prop.onBlur) {
                      prop.onBlur(index, fieldValue);
                    }
                  }}
                  disabled={meta.submitting || disabled}
                  placeholder={prop.placeholder}
                  fluid
                />
              );
              break;
          }

          if (!prop.skipDivider && i !== properties.length - 1) {
            items.push(
              <b className="mr-2 h-10 flex items-center" key={`${key}-divider`}>
                {prop.divider || ':'}
              </b>
            );
          }

          return items;
        });

        const elem = (
          <div
            key={field.id || index}
            className={cn('flex flex-start', { 'mt-3': index > 0, 'mb-3': hasError })}
          >
            {propItems}

            <div className="h-10 flex items-center mt-1/2">
              <Button icon="trash" onClick={this.removeField(index)} disabled={disabled} />
            </div>
          </div>
        );

        if (sortable) {
          sortableItemElems.push({
            id: field.id || index,
            elem,
          });
        }

        return elem;
      })
    );

    let contentElem = itemElems;
    if (sortable) {
      contentElem = (
        <SortableList
          items={sortableItemElems}
          handleReorder={(fromIndex, toIndex) => {
            let start = fromIndex;
            let end = toIndex;

            if (hasLabels) {
              start--;
              end--;
            }

            handleUpdate('set', [], _move(fields, start, end));
          }}
        />
      );
    }

    return (
      <div className={cn('FormInputList', className)}>
        {title && (
          <div className="flex items-center mb-4">
            <div className="text-xl font-bold mr-6 flex-1">{title}</div>
            {this.renderAddButton()}
          </div>
        )}

        <FormInputLabel label={label} tip={tip} required={required} />

        {!title && <div className="mb-4">{this.renderAddButton()}</div>}

        {contentElem}
      </div>
    );
  }
}

export default FormInputList;
