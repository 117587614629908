import React from 'react';
import _ from 'lodash';

export default ({ title, subtitle }) => {
  return (
    <div className="EntityViewer-sectionTitle">
      {title}
      {!_.isEmpty(subtitle) ? <small>{subtitle}</small> : null}
    </div>
  );
};
