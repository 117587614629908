import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { alert } from '@platform/utils/alert';
import { fileFormat } from '@platform/utils/url';
import { getEntityDataForFile } from '@platform/utils/entities';

import PageLoading from '@platform/components/PageLoading';

import MdEditor from '@platform/components/MdEditor';
import CssEditor from '@platform/components/CssEditor';
import HubEditor from '@platform/components/HubEditor';
import Oas2Editor from '@platform/components/Oas2Editor';
import Oas3Editor from '@platform/components/Oas3Editor';
import HtmlEditor from '@platform/components/HtmlEditor';
import LintEditor from '@platform/components/LintEditor';
import RawFileEditor from '@platform/components/RawFileEditor';
import InstanceEditor from '@platform/components/InstanceEditor';
import CollectionEditor from '@platform/components/CollectionEditor';
import EnvironmentEditor from '@platform/components/EnvironmentEditor';
import VersionPanel from '@platform/components/VersionPanel';
import ProjectLanding from '@platform/components/ProjectLanding';
import DiscussionPanel from '@platform/components/DiscussionPanel';
import ValidationPanel from '@platform/components/ValidationPanel';
import EditorContainer from '@platform/components/EditorContainer';

const regexpMap = {
  HubEditor: /(^|.*?\.)hub\.(json|y(a?)ml)$/,
  Oas2Editor: /(^|.*?\.)oas2\.(json|y(a?)ml)$/,
  Oas3Editor: /(^|.*?\.)oas3\.(json|y(a?)ml)$/,
  CollectionEditor: /(^|.*?\.)scenarios\.(json|y(a?)ml)$/,
  InstanceEditor: /(^|.*?\.)prism\.(json|y(a?)ml)$/,
  MdEditor: /.*?\.md$/,
  HtmlEditor: /.*?\.html$/,
  CssEditor: /.*?\.css$/,
  LintEditor: /^lint\.(json|y(a?)ml)$/,
};

const editorMap = {
  HubEditor,
  Oas2Editor,
  Oas3Editor,
  CollectionEditor,
  InstanceEditor,
  MdEditor,
  HtmlEditor,
  CssEditor,
  LintEditor,
};

// Have to put this in a separate component, otherwise children get unmounted and remounted
class ProjectFilePage extends React.Component {
  componentWillMount() {
    this.registerProjectStoreInstance();
    this.getFile();
  }

  componentWillUnmount() {
    const { currentRoom, coreEditorStore } = this.props;

    // If we don't have any unsaved changes, disconnect from this file's websocket room
    if (currentRoom && !_.get(coreEditorStore, 'isDirty')) {
      currentRoom.leave();
    }
  }

  componentDidUpdate(prevProps) {
    const { projectId, currentRef, currentFilePath } = this.props;

    const hasChangedProject = projectId && projectId !== prevProps.projectId;
    const hasChangedRef = currentRef && currentRef !== prevProps.currentRef;
    const hasChangedFile = currentFilePath && currentFilePath !== prevProps.currentFilePath;

    if (hasChangedProject) {
      this.handleChangeProject(prevProps);
    } else if (hasChangedRef) {
      this.handleChangeRef(prevProps);
    } else if (hasChangedFile) {
      this.getFile();
    }
  }

  handleChangeProject = prevProps => {
    if (prevProps.currentRoom && !_.get(prevProps.coreEditorStore, 'isDirty')) {
      prevProps.currentRoom.leave();
    }

    this.registerProjectStoreInstance();
    this.getFile(prevProps);
  };

  handleChangeRef = prevProps => {
    const { projectId, projectStore } = prevProps;

    const currentProjectStore = projectStore.getInstance(projectId);
    if (!currentProjectStore) return;

    currentProjectStore.getFileTree({ force: true });
    this.getFile(prevProps);
  };

  registerProjectStoreInstance = props => {
    const { projectStore, projectId, coreEditorStore, discussionService, orgService = {} } =
      props || this.props;
    if (!projectId) return;

    const currentProjectStore = projectStore.register(projectId);
    if (!currentProjectStore.currentRoom) return;

    currentProjectStore.currentRoom.join();

    const editorPanels = [
      { id: 'versions', computeSections: () => <VersionPanel /> },
      { id: 'validations', computeSections: () => <ValidationPanel /> },
    ];

    // discussions panel is only for orgs
    if (orgService.current) {
      editorPanels.push({ id: 'discussions', computeSections: () => <DiscussionPanel /> });

      currentProjectStore.setEnabledPanels(editorPanels);

      // Should think of a better way of handling things like this
      // open discussions panel if activeId and its closed
      if (
        _.get(coreEditorStore, '_editor._activePanel.id') !== 'discussions' &&
        discussionService.activeId
      ) {
        // set time out to all commands to register before executing
        setTimeout(() => {
          coreEditorStore.executeCommand('toggle:discussions');
        }, 100);
      }
    } else {
      currentProjectStore.setEnabledPanels(editorPanels);
    }
  };

  redirectToLanding = props => {
    const { routerStore } = props || this.props;
    const { namespaceId, projectId, ref } = routerStore.location.params || {};

    alert.error('File not found');
    routerStore.replace(`/${namespaceId}/${projectId}/${ref}`);
  };

  getFile = props => {
    const { projectStore } = props || this.props;

    const currentProjectStore = _.get(projectStore, 'current');
    const currentFilePath = _.get(projectStore, 'current.currentFilePath');

    if (!currentProjectStore || !currentFilePath) return;

    return currentProjectStore
      .getFile(currentFilePath, { skipError: true })
      .then(res => {
        if (!res) {
          this.redirectToLanding(props);
        }
      })
      .catch(() => {
        this.redirectToLanding(props);
      });
  };

  render() {
    const { namespaceId, projectService, projectStore, routerStore } = this.props;

    const canWrite = projectService.canUser({ action: 'push:project' });
    const showTheme = _.has(routerStore.location, 'query.theme');
    const currentProjectStore = projectStore.current || {};
    const { currentFile = {}, currentFilePath } = currentProjectStore;

    let containerProps = {};
    let containerContent;
    if (currentFile.hasData) {
      const editorKey = _.findKey(regexpMap, regexp => regexp.test(currentFile.path));
      const Editor = editorMap[editorKey] || RawFileEditor;

      containerProps = {
        className: 'Editor flex-1 relative z-0 border-l border-lighten-50',
        hideToolbar: (!canWrite && editorKey === 'HubEditor') || showTheme,
        hidePanel: showTheme,
      };

      containerContent = (
        <Editor
          className="ProjectFileEditor"
          orgId={namespaceId}
          entityData={getEntityDataForFile({ file: currentFile })}
          entityService={projectStore.fileService}
          entity={{
            id: currentFile.id,
            data: currentFile.data,
          }}
          showTheme={showTheme}
        />
      );
    } else {
      containerProps = {
        className: 'Landing bg-white flex-1 relative z-0 border-l border-lighten-50',
      };

      if (currentFilePath || projectService.isGetting) {
        containerContent = (
          <div className="pin absolute flex items-center justify-center">
            <PageLoading inverted={false} />
          </div>
        );
      } else {
        containerContent = <ProjectLanding />;
      }
    }

    return (
      <div className="pin absolute flex flex-col bg-white overflow-auto">
        <EditorContainer {...containerProps}>
          <EnvironmentEditor />
          {containerContent}
        </EditorContainer>
      </div>
    );
  }
}

export default inject((stores, props) => {
  const {
    discussionService,
    namespaceService,
    coreEditorStore,
    projectService,
    projectStore,
    routerStore,
    orgService,
  } = stores;
  return {
    discussionService,
    coreEditorStore,
    projectService,
    projectStore,
    routerStore,
    orgService,

    projectId: _.get(projectService, 'current.id'),
    namespaceId: _.get(namespaceService, 'current.id'),
    currentRef: _.get(projectStore, 'current.currentRef'),
    currentRoom: _.get(projectStore, 'current.currentRoom'),
    currentFileRoom: _.get(projectStore, 'current.currentFileRoom'),
    currentFilePath: _.get(projectStore, 'current.currentFilePath'),
  };
})(observer(ProjectFilePage));
