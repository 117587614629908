import React from 'react';
import _ from 'lodash';

import EntityEditorGroup from '../EntityEditorGroup';
import RefBuilderList from '../RefBuilderList';

class ContractTestSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultOpen: _.isEmpty(this.getOas2Specs()),
    };
  }

  getOas2Specs = () => {
    const { editor } = this.props;

    return _.get(editor, 'parsed.settings.testing.oas2', []);
  };

  render() {
    const { editor, className = '' } = this.props;
    const { defaultOpen } = this.state;

    const oas2Specs = this.getOas2Specs();

    return (
      <EntityEditorGroup
        id="tutorial-collection-contract-test-settings"
        className={className}
        name="Contract Test Settings"
        padded
        defaultOpen={defaultOpen}
        active={!_.isEmpty(oas2Specs)}
        contentFactory={() => {
          return (
            <RefBuilderList
              id={editor.id}
              refs={oas2Specs}
              onChange={(t, p, v) => {
                editor.updateParsed(t, ['settings', 'testing', 'oas2'].concat(p), v);
              }}
              fileFilter={{ modeling: [/oas2/] }}
              addButtonProps={{ content: 'Add Spec' }}
              canAdd
              canRemove
            />
          );
        }}
      />
    );
  }
}

export default ContractTestSettings;
