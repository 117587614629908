import * as EX from '@core/extension';

export class LintExtension extends EX.Spec implements EX.ISpec {
  public readonly id: string = '.lint';
  public readonly name: string = 'Lint';
  public readonly longName: string = 'Linting Configuration';

  public get supportedModes() {
    return [this.editor.supportedModes.design];
  }
}
