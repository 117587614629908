import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';

import Crumbs from '@platform/components/Crumbs';

import { Link } from '@platform/utils/router';

import { Icon, Popup } from '@core/ui';

import UserDropdown from '../UserDropdown';

import GlobalNavAction from './action';

const buildActions = actions => {
  return _.compact(
    _.map(actions, (action, i) => {
      const { node, ...actionProps } = action;

      if (node) {
        return node({ key: i });
      }

      return <GlobalNavAction key={action.id} {...actionProps} />;
    })
  );
};

const GlobalNav = props => {
  const {
    className = '',
    routerStore,
    electronStore,
    userService,
    appStore,
    crumbs = [],
    primaryActions = [],
    secondaryActions = [],
    centered = true,
  } = props;

  const authorizedUser = userService.authorizedUser;

  // BUILD HOME BUTTON + CRUMBS

  let homeElem;
  let crumbsElem;
  if (!_.isEmpty(crumbs)) {
    // on editors we show a home badge
    homeElem = (
      <Link
        className="w-16 flex items-center h-full justify-center text-xl hover:bg-lighten-100 text-white"
        href="/"
      >
        <Icon icon={faHome} />
      </Link>
    );

    // only show crumbs in top layout nav on editors, which will have three crumb elems
    crumbsElem = <Crumbs className="GlobalNav-crumbs pl-4 flex items-center" crumbs={crumbs} />;
  } else {
    homeElem = (
      <Link className="flex items-center h-full justify-center text-white font-bold" href="/">
        Stoplight
      </Link>
    );
  }

  // BUILD PRIMARY ACTIONS

  if (electronStore.enabled) {
    if (electronStore.updateDownloaded) {
      primaryActions.push({
        id: 'update-dl',
        className: 'GlobalNav-action--accent mr-3',
        name: 'Install Update',
        icon: 'exclamation circle',
        onClick() {
          appStore.openModal('AppAbout');
        },
      });
    } else if (electronStore.updateAvailable) {
      primaryActions.push({
        id: 'update-avl',
        className: 'GlobalNav-action--accent mr-3',
        name: 'Update Available',
        icon: 'gift',
        onClick() {
          appStore.openModal('AppAbout');
        },
      });
    } else if (electronStore.updateError) {
      primaryActions.push({
        id: 'update-err',
        className: 'GlobalNav-action--error mr-3',
        name: 'Update Error',
        icon: 'remove',
        onClick() {
          appStore.openModal('AppAbout');
        },
      });
    } else if (electronStore.manualUpdateRequired) {
      primaryActions.push({
        id: 'update-manual',
        className: 'GlobalNav-action--warning mr-3',
        name: 'Manual Update Required',
        icon: 'exclamation circle',
        onClick() {
          appStore.openModal('AppAbout');
        },
      });
    }

    primaryActions.unshift({
      id: 'forward',
      className: 'GlobalNav-action--history is-right mr-3',
      icon: 'chevron right',
      onClick: () => {
        routerStore.goForward();
      },
    });

    primaryActions.unshift({
      id: 'back',
      className: 'GlobalNav-action--history is-left mr-2',
      icon: 'chevron left',
      onClick: () => {
        routerStore.goBack();
      },
    });
  }

  // BUILD SECONDARY ACTIONS

  if (!userService.isLoggedIn) {
    secondaryActions.push({
      id: 'login',
      className: 'ml-3',
      name: 'Login',
      icon: 'power',
      href: '/login',
    });

    secondaryActions.push({
      id: 'join',
      className: 'GlobalNav-action--accent ml-3',
      name: 'Join',
      icon: 'heart',
      href: '/join',
    });
  }

  secondaryActions.push({
    id: 'ud',
    node: p => {
      return (
        <Popup
          {...p}
          posX="right"
          posY="bottom"
          offset={{
            left: 10,
          }}
          renderTrigger={(attributes, { isVisible, isOver, showPopup, hidePopup }) => {
            return (
              <div
                className="flex rounded-sm px-3 py-1/2 font-bold select-none transition-all text-sm text-white bg-lighten-100 hover:bg-lighten-200 cursor-pointer ml-3"
                {...attributes}
              >
                <div className="flex-1 mr-2">
                  {authorizedUser ? authorizedUser.username : 'More'}
                </div>

                <div className="flex items-center justify-center">
                  <Icon icon={faCaretDown} />
                </div>
              </div>
            );
          }}
          renderContent={attributes => <UserDropdown {...attributes} />}
        />
      );
    },
  });

  return (
    <div
      className={cn('px-4 bg-darken text-white pin-t z-50', className, {
        'pl-0': !centered,
      })}
    >
      <div
        className={cn('GlobalNav-inner flex mx-auto', {
          container: centered,
        })}
      >
        <div className="GlobalNav-left h-14 flex items-center">
          {homeElem}
          {crumbsElem}
          <div className="ml-6 flex">{buildActions(_.uniqBy(primaryActions, 'id'))}</div>
        </div>

        <div className="GlobalNav-center h-14 flex items-center flex-grow" />

        <div className="GlobalNav-right h-14 flex items-center justify-end">
          {buildActions(_.uniqBy(secondaryActions, 'id'))}
        </div>
      </div>
    </div>
  );
};

export default inject('routerStore', 'electronStore', 'userService', 'appStore')(
  observer(GlobalNav)
);
