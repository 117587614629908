import React from 'react';

import Editor from '@platform/format-hubs/components/Hub/editor';
import Viewer from '@platform/format-hubs/components/Hub/viewer';
import HubTOCModal from '@platform/format-hubs/components/TableOfContents';
import HubSettingsModal from '@platform/format-hubs/components/HubSettings';

import EntityEditor from '../EntityEditor';
import ThemeWrapper from '../ThemeWrapper';
import EntityEditorCode from '../EntityEditor/spec';

const contentTabs = [
  {
    id: 'read',
    component: ThemeWrapper,
    propFactory: ({ editor }) => {
      return {
        prependClass: 'Hub',
        tabs: ['general', 'css'],
        component: Viewer,
        componentProps: { store: editor.hubViewerStore },
      };
    },
  },
  {
    id: 'design',
    component: Editor,
  },
  {
    id: 'code',
    component: EntityEditorCode,
  },
];

const HubEditor = props => {
  const { entity, ...extraProps } = props;

  return [
    <EntityEditor
      key="1"
      className="Hub"
      entity={entity}
      editorStoreKey="hubEditorStore"
      contentTabs={contentTabs}
      contentRouterData={{}}
      {...extraProps}
    />,
    <HubSettingsModal key="2" />,
    <HubTOCModal key="3" />,
  ];
};

export default HubEditor;
