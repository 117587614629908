import _ from 'lodash';
import { computed, action, reaction, observable } from 'mobx';

import {
  computeNewResource,
  computeNewPath,
  getEndpointsFromSpec,
  computeUpdatesFromCollectionResult,
  computePathParamsFromUri,
  createStepFromOperation,
} from '@platform/utils/specs';
import { alert } from '@platform/utils/alert';
import { httpMethods } from '@platform/utils/http';
import { getConfigVar } from '@platform/utils/config';
import { pathToHash } from '@platform/utils/history';
import { uniqueSlugify } from '@platform/utils/general';
import { filterSpec } from '@platform/utils/specs/filter';
import { extractParams, createURL } from '@platform/utils/url';
import { computeSidebarTree } from '@platform/utils/specs/tree';

import { COMMANDS as OAS2_COMMANDS, MODES as OAS_MODES } from '@core/spec-oas2';
import { MODES as EDITOR_MODES } from '@core/editor';

import { oas2Extension } from './coreEditor';
import entityEditorStore, { EntityEditor } from './entityEditorStore';
import { SpectralRunner } from './spectral-worker/spectralRunner';
import HubWorker from './hub-worker/client';

class Oas2Editor extends EntityEditor {
  _extension = oas2Extension;
  SpectralRunner = new SpectralRunner();
  _hubWorker;

  lastCollectionEditorStepPath = [];

  @observable.ref
  _hubViewerSpec = {};

  @observable
  isBuildingHub = false;

  @observable.ref
  collectionEditor;

  @observable
  showCrudBuilder = false;

  activate() {
    super.activate();

    this.registerHubWorker();
    this.registerListeners();
    this.registerCommands();
    this.registerReactions();

    if (_.has(this.rootStore.stores.routerStore.location.query, 'edit')) {
      this.goToEditor();
    } else {
      this.goToViewer();
    }
  }

  @action
  initCollectionEditor = () => {
    const collectionEditor = this.rootStore.stores.collectionEditorStore.initEditor({
      editPath: '#/scenarios/dummy/steps/0',
      entityType: 'collections',
      orgId: this.orgId,
      embedded: true,
      entity: {
        id: this.id,
        path: this.path,
        data: {
          scenarios: {
            dummy: {
              steps: [
                {
                  id: 'dummy',
                  type: 'http',
                  input: {
                    method: 'get',
                    url: _.first(this.hosts),
                  },
                },
              ],
            },
          },
        },
      },
    });

    this.collectionEditor = collectionEditor;
  };

  registerListeners = () => {
    this._disposables.push(
      this._editor.onDidChangeMode(({ mode }) => {
        if (!mode) return;

        switch (mode.id) {
          case EDITOR_MODES.read.id:
            if (!this.isViewing) {
              this.goToViewer();
            }
            break;

          case OAS_MODES.http.id:
            // If we are changing to HTTP mode, init a colleciton editor and update to the current path
            this.initCollectionEditor();
            this.updateCollectionEditorStep({ path: this.currentPath });

            if (!this.isEditing) {
              this.goToEditor();
            }
            break;

          default:
            if (!this.isEditing) {
              this.goToEditor();
            }
            break;
        }
      })
    );
  };

  registerCommands = () => {
    this._disposables.push(
      this._commandRegistry.registerHandler(OAS2_COMMANDS.showCrudBuilder, {
        execute: () => {
          this.toggleCrud();
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(OAS2_COMMANDS.addPath, {
        execute: () => {
          this.addPath({ forceNew: true });
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(OAS2_COMMANDS.addModel, {
        execute: () => {
          this.addModel();
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(OAS2_COMMANDS.addTag, {
        execute: () => {
          this.addTag();
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(OAS2_COMMANDS.sendHttp, {
        execute: () => {
          if (this.collectionEditor.currentRunning) {
            this.collectionEditor.stopStep();
          } else {
            this.collectionEditor.runStep(undefined, { spec: this.parsed });
          }
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(OAS2_COMMANDS.httpToOas2, {
        execute: () => {
          this.applyUpdatesFromCollectionResult(({ operationPath = [] } = {}) => {
            alert.success('Spec extended.');
            if (!_.isEmpty(operationPath)) {
              this.lastCollectionEditorStepPath = operationPath;
              this.goToPath(operationPath);
            }
          });
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(OAS2_COMMANDS.resetHttp, {
        execute: () => {
          this.collectionEditor.resetPath(['scenarios', 'dummy', 'steps', 0], null, {
            skipConfirm: true,
          });
        },
      })
    );
  };

  @action
  registerReactions = () => {
    // when parsed changes, update spec after a debounce
    this._disposables.push({
      dispose: reaction(
        () => ({
          path: this.currentPath,
        }),
        ({ path }) => {
          this.updateCollectionEditorStep({ path });
        },
        {
          name: 'currentPathChanged',
        }
      ),
    });
  };

  @action
  registerHubWorker = () => {
    this._hubWorker = new HubWorker();
    this._disposables.push(this._hubWorker);

    this._hubWorker.on(
      'buildHubFromOAS',
      action(hub => {
        this._hubViewerSpec = hub;
        this.isBuildingHub = false;
      })
    );

    this._disposables.push({
      dispose: reaction(
        () => this.dereferencedParsed,
        action(spec => {
          if (!spec) return;

          const { name, exportUrl } = _.get(
            this.rootStore._stores.projectStore,
            'current.currentFile',
            {}
          );

          this.isBuildingHub = true;
          this._hubWorker.send('buildHubFromOAS', {
            spec,
            name,
            exportUrl,
          });
        }),
        {
          name: 'buildHubFromOAS',
          fireImmediately: true,
        }
      ),
    });
  };

  handleValidation = ({ target, env, strTarget, cb = _.noop }) => {
    const config = _.get(this.rootStore._stores.projectStore, 'current.lintFile.data', {});
    const projectId = _.get(this, 'rootStore.stores.projectStore.current.id');
    const ref = _.get(this, 'rootStore.stores.projectStore.current.currentRef');
    const file = _.get(this, 'rootStore.stores.projectStore.current.currentFilePath');

    const callback = (validations = []) => {
      this._validations.dispose();

      if (this._activated) {
        for (const validation of validations) {
          // Definitions will always be unused since we dereference, so ignore that error
          this._validations.push(this._editor.addValidation(validation));
        }
      }

      cb();
    };

    this.SpectralRunner.run({
      id: this.id,
      apiHost: getConfigVar('SL_API_HOST'),
      projectId,
      ref,
      file,
      target,
      config,
      env,
      spec: 'oas2',
      cb: callback,
    });
  };

  get _computeSidebarTree() {
    return computeSidebarTree;
  }

  get _filterSpec() {
    return filterSpec;
  }

  @computed
  get hosts() {
    let hosts = [];

    // add hosts from this spec
    const host = _.get(this.parsed, 'host');
    const schemes = _.get(this.parsed, 'schemes', ['http']);
    if (host) {
      hosts = _.map(schemes, scheme => {
        const schemeReg = new RegExp(`^${scheme}://`);
        if (schemeReg.test(host)) {
          return host;
        }

        return `${scheme}://${host}`;
      });
    }

    // add hosts from connected prism instances
    const prismHost = getConfigVar('SL_PRISM_HOST').split('://')[0];

    _.forEach(this.connectedInstances, instance => {
      if (!instance.subdomain) return;

      _.forEach(schemes, scheme => {
        hosts.push(`${scheme}://${instance.subdomain}.${prismHost}`);
      });
    });

    return hosts;
  }

  @computed
  get currentPathNames() {
    return Object.keys(_.get(this.parsed, 'paths', {}));
  }

  @computed
  get endpoints() {
    return getEndpointsFromSpec({
      data: this.parsed,
    });
  }

  @computed
  get view() {
    return (
      _.has(this.rootStore.stores.routerStore.location.query, 'view') ||
      !this.rootStore._stores.projectService.canUser({ action: 'push:project' })
    );
  }

  @computed
  get hubViewerSpec() {
    // Don't need to return anything if we're not in view mode
    if (!this.isViewing) return {};

    return this._hubViewerSpec;
  }

  // given two paths, calculate their {params} and update the swagger
  // parameters object if necessary
  @action.bound
  updatePathParameters({ currentPath, newPath }) {
    const currentParams = extractParams(currentPath);
    const newParams = extractParams(newPath);

    if (_.isEqual(currentParams, newParams)) {
      return;
    }

    const newPathParams = [];
    if (newParams) {
      for (const p of newParams) {
        newPathParams.push({
          name: p,
          in: 'path',
          required: true,
          type: 'string',
        });
      }
    }

    if (_.isEmpty(newPathParams)) {
      this.updateParsed('unset', ['paths', newPath, 'parameters']);
    } else {
      this.updateParsed('set', ['paths', newPath, 'parameters'], newPathParams);
    }
  }

  @action.bound
  addPath({ path = '/new-path', methods = ['get'], skipConfirm, forceNew } = {}, cb) {
    let targetPath = path;
    let count = 0;

    if (forceNew) {
      while (_.get(this.parsed, ['paths', targetPath])) {
        count += 1;
        targetPath = `${path}-${count}`;
      }
    }

    const updates = computeNewPath({
      paths: _.get(this.parsed, 'paths', {}),
      path: targetPath,
      methods,
      skipConfirm,
    });

    if (!_.isEmpty(updates)) {
      for (const u of updates) {
        this.updateParsed(u.transformation, u.path, u.value);
      }

      targetPath = ['paths', targetPath];
      if (!_.isEmpty(methods)) {
        targetPath.push(_.first(methods));
      }
      this.rootStore.stores.routerStore.setQueryParams(
        { edit: pathToHash({ path: targetPath }) },
        { preserve: true }
      );

      if (cb) {
        cb();
      }
    }
  }

  // path should a http path, ie /foo
  @action.bound
  addOperation({ path = '/' }, { navigate } = {}) {
    if (_.isEmpty(path)) {
      return;
    }

    const existing = _.get(this.parsed, ['paths', path], {});
    const possibleOperations = _.keys(httpMethods);
    let chosenMethod;
    for (const method of possibleOperations) {
      if (!existing[method]) {
        chosenMethod = method;
        break;
      }
    }

    if (chosenMethod) {
      this.addPath({ path, methods: [chosenMethod], skipConfirm: true }, () => {
        if (navigate) {
          this.goToPath(['paths', path, chosenMethod]);
        }
      });
    }
  }

  @action.bound
  addResource({ path = '', name, schema }, cb) {
    const updates = computeNewResource({
      path,
      paths: _.get(this.parsed, 'paths'),
      name,
      schema,
    });

    if (!_.isEmpty(updates)) {
      for (const u of updates) {
        this.updateParsed(u.transformation, u.path, u.value);
      }

      if (cb) {
        cb();
      }
    }
  }

  @action.bound
  renamePath(from, to, cb) {
    this.renameProp(['paths'], from, to, cb);

    const parameters = computePathParamsFromUri(to, {
      existingParams: _.get(this.parsed, ['paths', to, 'parameters']),
    });
    if (!_.isEmpty(parameters)) {
      this.updateParsed('set', ['paths', to, 'parameters'], parameters);
    } else {
      this.updateParsed('unset', ['paths', to, 'parameters']);
    }
  }

  @action.bound
  applyUpdatesFromCollectionResult(cb) {
    const operationMethod = _.get(this.collectionEditor, 'currentStep.data.input.method');
    const updates = computeUpdatesFromCollectionResult({
      spec: this.parsed,
      result: {
        scenarios: {
          dummy: {
            steps: [
              {
                input: _.get(this.collectionEditor, 'currentStep.data.input'),
                output: _.get(this.collectionEditor, 'currentResult.data.output'),
              },
            ],
          },
        },
      },
    });

    if (!_.isEmpty(updates)) {
      let operationPath = {};

      for (const u of updates) {
        this.updateParsed(u.transformation, u.path, u.value, { immediate: true });
        if (u.path[0] === 'paths') {
          operationPath = u.path[1];
        }
      }

      if (cb) {
        cb({ operationPath: ['paths', operationPath, operationMethod] });
      }
    }
  }

  @action.bound
  toggleCrud() {
    this.showCrudBuilder = !this.showCrudBuilder;
  }

  @action.bound
  addModel() {
    const existing = _.keys(_.get(this.parsed, 'definitions'));
    const newPath = ['definitions', uniqueSlugify({ input: 'new-model', existing })];

    this.updateParsed('set', newPath, {
      type: 'object',
      properties: {},
    });

    this.rootStore.stores.routerStore.setQueryParams(
      { edit: pathToHash({ path: newPath }) },
      { preserve: true }
    );
  }

  @action.bound
  addTag() {
    this.updateParsed(
      'splice',
      'tags',
      { name: '' },
      {
        splice: { index: 0 },
      }
    );

    this.rootStore.stores.routerStore.setQueryParams(
      { edit: pathToHash({ path: ['tags'] }) },
      { preserve: true }
    );
  }

  @action.bound
  addSecurityDefinition() {
    const existing = _.keys(_.get(this.parsed, 'securityDefinitions'));
    const newPath = ['securityDefinitions', uniqueSlugify({ input: 'new-auth', existing })];

    this.updateParsed('set', newPath, {
      type: 'apiKey',
      name: 'api_key',
      in: 'header',
    });

    this.rootStore.stores.routerStore.setQueryParams(
      { edit: pathToHash({ path: newPath }) },
      { preserve: true }
    );
  }

  @action.bound
  addSharedParameter() {
    const existing = _.keys(_.get(this.parsed, 'parameters'));
    const newPath = ['parameters', uniqueSlugify({ input: 'new-param', existing })];

    this.updateParsed('set', newPath, {
      name: 'new',
      in: 'query',
      type: 'string',
    });

    this.rootStore.stores.routerStore.setQueryParams(
      { edit: pathToHash({ path: newPath }) },
      { preserve: true }
    );
  }

  @action.bound
  addSharedResponse() {
    const existing = _.keys(_.get(this.parsed, 'responses'));
    const newPath = ['responses', uniqueSlugify({ input: 'new-response', existing })];

    this.updateParsed('set', newPath, {
      description: '',
      schema: {
        type: 'object',
        properties: {},
      },
    });

    this.rootStore.stores.routerStore.setQueryParams(
      { edit: pathToHash({ path: newPath }) },
      { preserve: true }
    );
  }

  @action
  updateCollectionEditorStep = ({ path = [] }) => {
    // only update if we are looking at a swagger operation, and the http content tab, and the path is different than the last generated
    if (_.size(path) !== 3 || path[0] !== 'paths' || this.currentContentTabId !== 'http') {
      return;
    }

    this.lastCollectionEditorStepPath = path;

    const { origin: host } =
      createURL(_.get(this.collectionEditor.currentStep, 'data.input.url')) || {};

    const { input, name } =
      createStepFromOperation({
        spec: this.dereferencedParsed,
        basePath: _.get(this.dereferencedParsed, 'basePath'),
        host,
        method: path[2],
        path: path[1],
      }) || {};

    if (input) {
      this.collectionEditor.updateCurrentParsed('set', 'input', input);
    }

    if (name) {
      this.collectionEditor.updateCurrentParsed('set', 'name', name);
    }
  };

  goToViewer() {
    const viewPath = _.get(this.rootStore, 'stores.routerStore.location.query.view', '/');

    // Override with the last view path
    super.goToViewer(this.lastViewPath || viewPath);
  }
}

export default class Oas2EditorStore extends entityEditorStore {
  editorClass = Oas2Editor;
}
