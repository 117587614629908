import _ from 'lodash';
import pluralize from 'pluralize';

const { getConfigVar } = require('../config');

export const { PLANS, PRODUCTS } = require('./plans');

export const PAY_GATES = Object.freeze([
  // TODO: are these needed? everyone can publish...
  // or do we not let you publish if you previous set domain settings that are no longer
  // allowed by our plan?
  Object.freeze({
    type: 'gate',
    id: 'basic',
    title: 'Basic Publishing',
    permit: subscription => {
      return true;
    },
  }),
  Object.freeze({
    type: 'gate',
    id: 'standard',
    title: 'Standard Publishing',
    permit: subscription => {
      return true;
    },
  }),
  Object.freeze({
    type: 'gate',
    id: 'pro',
    title: 'Pro Publishing',
    permit: subscription => {
      return true;
    },
  }),
]);

export const PLATFORM_PLANS = Object.freeze({
  business: 40,
  team: 30,
  open_source: 10,
});

export const PLATFORM_FEATURES = Object.freeze({
  private_projects: PLATFORM_PLANS.team,
  sso: PLATFORM_PLANS.business,
});

export const DOCS_PLANS = Object.freeze({
  pro: 40,
  standard: 30,
  essential: 20,
  basic: 10,
});

export const DOCS_FEATURES = Object.freeze({
  publish: DOCS_PLANS.basic,

  theme: DOCS_PLANS.essential,
  custom_domain: DOCS_PLANS.essential,

  auth_basic: DOCS_PLANS.standard,
  auth_auth0: DOCS_PLANS.standard,
  css: DOCS_PLANS.standard,
  js: DOCS_PLANS.standard,
  integrations: DOCS_PLANS.standard,
  whitelabel: DOCS_PLANS.standard,
  redirects: DOCS_PLANS.standard,
  variables: DOCS_PLANS.standard,

  basePath: DOCS_PLANS.pro,
  download: DOCS_PLANS.pro,
  auth_saml: DOCS_PLANS.pro,
  oauth: DOCS_PLANS.pro,
});

export const FEATURE_NAMES = Object.freeze({
  sso: 'Single Sign On',
  publish: 'Publishing',
  theme: 'Theming',
  auth_basic: 'Basic Authorization',
  css: 'CSS',
  js: 'Javascript',
  auth_auth0: 'Auth0',
  integrations: 'Integrations',
  custom_domain: 'Custom Domains',
  download: 'Download',
  auth_saml: 'SAML',
  oath: 'OAuth',
  basePath: 'Base Path',
  redirects: 'Redirects',
  variables: 'Variables',
});

export const getPlatformPlanErrorMessage = ({ plan, count, type }) => {
  const pluralMax = pluralize(type, plan.max);

  return `The ${plan.product.title} plan allows you to have up to ${plan.max} ${pluralMax}.`;
};

export const checkPlanUsageValid = (item = {}, usage = {}) => {
  const plan = findPlan(item.plan);
  if (!plan) {
    throw new Error(`${item.plan} plan not found!`);
  }

  const membersCount = usage.active_member_count + usage.passive_member_count;
  if (plan.max && membersCount > plan.max) {
    throw new Error(
      getPlatformPlanErrorMessage({
        plan,
        count: membersCount,
        type: 'members',
      })
    );
  }
};

/**
 * items = [{
 *   plan: {planId}
 * }]
 *
 * usage = {
 *   active_member_count: number,
 *   passive_member_count: number
 * }
 */
export const checkPlansUsageValid = (items = [], usage = {}) => {
  for (const item of items) {
    checkPlanUsageValid(item, usage);
  }
};

export const computePlanPrice = (item = [], usage = {}) => {
  let price = 0;

  const plan = findPlan(item.plan);
  if (!plan) {
    return price;
  }

  if (plan.price) {
    if (plan.min) {
      price +=
        plan.price * Math.max(plan.min || 0, usage.active_member_count || item.quantity || 0);
    } else {
      price += plan.price;
    }
  }

  return price;
};

export const computeSubscriptionPrice = props => {
  const { items = [], usage = {}, interval } = props || {};
  let price = 0;

  _.forEach(items, item => {
    price += computePlanPrice(item, usage);
  });

  if (interval === 'yearly') {
    price *= 12;
  }

  return price;
};

export const subscriptionIsFree = ({ items, usage }) => {
  return computeSubscriptionPrice({ items, usage }) > 0 ? false : true;
};

export const findPlan = id => {
  return _.find(PLANS, { id });
};

export const findPlans = ({ type, namespaceType, interval }) => {
  return _.filter(PLANS, plan => {
    const isSameType = _.get(plan, 'product.type') === type;
    const planNamespaceType = _.get(plan, 'product.namespaceType');
    const isSameNamespace =
      !planNamespaceType || !namespaceType || planNamespaceType === namespaceType;
    const matchInterval = plan.interval === interval;

    return isSameType && isSameNamespace && matchInterval;
  });
};

/**
 * @param {string} type - type of a current plan. Can be 'platform' or 'docs'.
 */
export const getPlan = ({ subscription, type }) => {
  const items = _.get(subscription, 'items');

  return _.find(items, item => {
    const plan = findPlan(item.plan);
    return _.get(plan, 'product.type') === type ? plan : undefined;
  });
};

export const hasSubscriptionFeature = (namespace = {}, feature) => {
  // personal plan should have access to private projects but nothing else (for legacy users)
  if (namespace.namespace_type === 'user') {
    if (feature === 'private_projects') {
      return true;
    } else {
      return false;
    }
  }

  const subscription = namespace.subscription;

  const trial = getTrial(subscription);

  if (
    (subscription && subscription.provider === 'manual') ||
    (trial && trial > 0) ||
    !getConfigVar('STRIPE_PK')
  ) {
    return true;
  }

  if (PLATFORM_FEATURES[feature]) {
    return _.get(subscription, 'plans.platform') >= PLATFORM_FEATURES[feature];
  }

  if (DOCS_FEATURES[feature]) {
    return _.get(subscription, 'plans.docs') >= DOCS_FEATURES[feature];
  }
};

export const getFeaturePlan = feature => {
  if (PLATFORM_FEATURES[feature]) {
    return _.findKey(PLATFORM_PLANS, plan => plan === PLATFORM_FEATURES[feature]);
  }

  if (DOCS_FEATURES[feature]) {
    return _.findKey(DOCS_PLANS, plan => plan === DOCS_FEATURES[feature]);
  }
};

export const getFeatureType = feature => {
  if (PLATFORM_FEATURES[feature]) {
    return 'platform';
  }

  if (DOCS_FEATURES[feature]) {
    return 'docs';
  }
};

export const getFeatureName = feature => {
  return FEATURE_NAMES[feature] || '';
};

export const getTrial = subscription => {
  const status = _.get(subscription, 'status');
  if (status === 'active') return;

  const expiration = new Date(_.get(subscription, 'trial_until'));

  if (expiration) {
    // trial expiration date - current date to determine days left
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*millisecond
    const current = new Date();

    return Math.round((expiration.getTime() - current.getTime()) / oneDay);
  }
};

export const computeBillingInfo = ({ subscription } = {}) => {
  if (!subscription) return {};

  const plans = _.map(subscription.items, item => {
    return findPlan(item.plan);
  });

  const platformPlan = _.find(plans, ['product.type', 'platform']) || {};
  const docsPlan = _.find(plans, ['product.type', 'docs']) || {};

  const cost = computeSubscriptionPrice(subscription);

  return {
    upgraded_at: subscription.created,
    subscription_status: subscription.status,
    subscription_interval: platformPlan.interval,
    subscription_cost: cost,
    monthly_spend: platformPlan.interval === 'yearly' ? cost / 12 : cost,
    platform_plan: _.get(platformPlan, 'product.title'),
    docs_plan: _.get(docsPlan, 'product.title'),
    trial_until: getTrial(subscription),
  };
};
