import * as _ from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { lazyInject } from '@core/ioc';

import { TreeRow } from './TreeRow';
import { TreeRowContent } from './TreeRowContent';
import { IManager, IStore, ITree, SYMBOLS } from './types';

let SortableTree: any;
let SortableTreeWithoutDndContext: any;

// react-sortable-tree uses window internally
// we need to be "node safe" for server side rendering
if (typeof window !== 'undefined') {
  SortableTree = require('react-sortable-tree').default;
  SortableTreeWithoutDndContext = require('react-sortable-tree').SortableTreeWithoutDndContext;
}

export class Tree extends React.Component<ITree> {
  @lazyInject(SYMBOLS.Manager)
  // @ts-ignore
  private manager: IManager;

  private store: IStore;

  constructor(props: ITree) {
    super(props);

    // @ts-ignore
    this.store = this.manager.getById(props.id);
    this.store.activate(props.data);
  }

  public componentWillUpdate(nextProps: ITree) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      this.store.setFlatData(nextProps.data);
    }
  }

  public render() {
    const { onChange, childOptsFactory, canDrag = true, canDrop = true } = this.props;
    const { scaffoldBlockPxWidth, rowHeight, slideRegionSize, treeData } = this.store;

    if (!SortableTreeWithoutDndContext && !SortableTree) {
      return null;
    }

    const treeProps = {
      theme: {
        nodeContentRenderer: TreeRowContent,
        treeNodeRenderer: TreeRow,
        scaffoldBlockPxWidth,
        rowHeight,
        slideRegionSize,
      },
      treeData: treeData && treeData.slice(),
      getNodeKey: ({ node }: { node: any }) => {
        return node.id || node.path;
      },
      onChange: _.noop,
      onMoveNode: async ({ nextPath, prevPath, treeData: nextTreeData }: any) => {
        if (!onChange || _.isEqual(nextPath, prevPath)) return;

        const oldTreeData = treeData || [];
        this.store.setTreeData(nextTreeData);

        try {
          await onChange(nextPath, prevPath);
        } catch (error) {
          this.store.setTreeData(oldTreeData);
        }
      },
      onVisibilityToggle: ({ treeData: nextTreeData }: any) => {
        this.store.setTreeData(nextTreeData);
      },
      canDrag: canDrag && this.canDrag,
      canDrop: canDrop && this.canDrop,
      generateNodeProps: ({ node }: any) => {
        return childOptsFactory(node);
      },
    };

    if (!canDrag) {
      return <SortableTreeWithoutDndContext {...treeProps} />;
    }

    return <SortableTree {...treeProps} />;
  }

  private canDrag = (opts: { node: any }): boolean => {
    const { canDrag = true } = this.props;

    if (typeof canDrag === 'function') {
      return canDrag(opts);
    }

    return canDrag;
  };

  private canDrop = (opts: { node: any }): boolean => {
    const { canDrop = true } = this.props;

    if (typeof canDrop === 'function') {
      return canDrop(opts);
    }

    return canDrop;
  };
}

export default observer(Tree);
