import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import TimeAgo from 'react-timeago';
import { inject, observer } from 'mobx-react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments';

import PageLoading from '@platform/components/PageLoading';
import ErrorMessage from '@platform/components/ErrorMessage';
import ScrollContainer from '@platform/components/ScrollContainer';

import { Icon, Button, colors } from '@core/ui';

import GraphQL from '@platform/containers/GraphQL';

const DiscussionsWidget = ({
  projectStore,
  routerStore,
  discussionService,
  coreEditorStore,
  projectService,
}) => {
  const { current = {} } = projectStore;
  const projectId = current.id;

  const { activeId } = discussionService;

  const openPanel = () => {
    if (_.get(coreEditorStore, '_editor._activePanel.id') !== 'discussions') {
      coreEditorStore.executeCommand('toggle:discussions');
    }
  };

  let discussionsCreate;

  if (projectService.canUser({ action: 'view' })) {
    discussionsCreate = (
      <div>
        <Button
          icon={faPlus}
          color={colors.accent}
          onClick={() => {
            openPanel();

            // set timeout to allow the panel to render
            setTimeout(() => {
              const textArea = document.getElementById('discussionCreateTextArea');

              if (textArea) {
                textArea.focus();
              }
            }, 100);
          }}
        >
          New
        </Button>
      </div>
    );
  }

  const discussionsList = (
    <GraphQL
      id={`DiscussionWidget:${projectId}`}
      service="discussionService"
      methods={{
        list: {
          variables: {
            projectId,
            state: 'open',
            orderBy: { field: 'lastActivityAt', direction: 'DESC' },
          },
          pollInterval: 10000,
        },
        createHandler: {},
      }}
      renderProp={({ list = {}, createHandler }) => {
        const discussions = _.get(list, 'data.discussions', []);
        if (list.loading) {
          return <PageLoading size="small" inverted={false} />;
        }

        if (list.error) {
          return <ErrorMessage className="m-6" error={list.error} />;
        }

        if (!discussions.length) {
          return <div className="text-darken-300 p-6 font-bold">No new discussions.</div>;
        } else {
          const activeDiscussionIndex = _.findIndex(discussions, d => d.iid === activeId);

          return (
            <ScrollContainer scrollToIndex={activeDiscussionIndex}>
              {_.map(discussions, (discussion = {}, index) => {
                const isActive = discussion.iid === activeId;

                return (
                  <div
                    key={index}
                    className={cn(
                      'px-3 py-2 cursor-pointer',
                      isActive ? 'bg-active-lighter' : 'hover:bg-grey-lightest',
                      index !== discussions.length - 1 && 'border-b'
                    )}
                    onClick={() => {
                      openPanel();

                      routerStore.setQueryParams({
                        discussion: !isActive ? String(discussion.iid) : null,
                      });
                    }}
                  >
                    <div className="flex">
                      <div className="font-bold pr-2">#{discussion.iid}</div>
                      <div className="flex-1 truncate">{discussion.summary}</div>
                    </div>
                    <div className="flex text-darken text-sm">
                      <div>
                        {discussion.commentCount || '0'} <Icon icon={faComment} />
                        {' · '}
                        {_.get(discussion.creator, 'username')}
                        {' · '}
                        {_.get(discussion.file, 'path')}
                      </div>
                      <div className="flex-1 ta-r">
                        <TimeAgo date={discussion.lastActivityAt} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </ScrollContainer>
          );
        }
      }}
    />
  );

  return (
    <div className="flex flex-col w-100 mb-16" style={{ maxWidth: 750 }}>
      <div className="flex items-center mb-6 pl-1">
        <div className="flex-1 text-2xl text-darken-300">
          <Icon icon={faComments} /> <span className="font-semibold mx-3">Recent Discussions</span>
        </div>
        <div className="">{discussionsCreate}</div>
      </div>
      <div className="border" style={{ height: 350 }}>
        {discussionsList}
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'routerStore',
  'discussionService',
  'coreEditorStore',
  'projectService'
)(observer(DiscussionsWidget));
