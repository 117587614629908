import _ from 'lodash';
import EventEmitter from 'eventemitter3';
import socketio from 'socket.io-client';

import { getConfigVar } from '@platform/utils/config';
import { registerLogger } from '@platform/utils/logging';

const log = registerLogger('@platform/stores', 'Websocket');

class Room {
  name = '';
  emitter;
  connection;
  connected;
  usernames = [];

  constructor({ name, connection }) {
    this.name = name;
    this.connection = connection;
    this.emitter = new EventEmitter();

    // Set up some default listeners

    // Whenever someone joins/leaves the room, update our connected usernames list
    this.on('did_update_userMeta', ({ usernames } = {}) => {
      this.usernames = usernames;
    });
  }

  send = (action, data) => {
    if (!this.connection || !this.connected) {
      log.debug('Cannot emit action. Not connected.');
      return;
    }

    this.connection.send({ action, room: this.name, data });
  };

  emit = (action, data) => {
    this.emitter.emit(action, data);
  };

  on = (action, cb) => {
    this.emitter.on(action, cb, this);

    return {
      dispose: () => {
        this.emitter.removeListener(action);
      },
    };
  };

  join = data => {
    if (this.connection) {
      this.connected = true;
      this.pending = false;
      this.send('join', data);
    } else {
      this.pending = true;
    }

    return this;
  };

  leave = data => {
    this.send('leave', data);
    this.connected = false;
    this.pending = false;
    this.emitter.removeAllListeners();

    return this;
  };
}

export default class Websocket {
  id;
  rootStore;
  connected;
  connection;
  rooms = {};
  events = {};
  emitter = new EventEmitter();

  constructor({ rootStore }) {
    this.rootStore = rootStore;
  }

  connect = () => {
    // Only connect on the client
    if (typeof window === 'undefined') {
      return;
    }

    if (this.connection) {
      return this.connection;
    }

    const connection = socketio(getConfigVar('SL_API_HOST'), {
      transports: ['websocket'],
      reconnectionAttempts: 5,

      transportOptions: {
        polling: {
          extraHeaders: {
            'App-Version': this.rootStore.version,
          },
        },
      },
    });
    this.connection = connection;

    this.setupConnectionEvents();

    return connection;
  };

  disconnect = () => {
    if (typeof window === 'undefined') return;

    if (this.connection) {
      // Leave all rooms.
      _.forEach(this.rooms, (room, name) => {
        room.leave();
      });

      this.rooms = {};

      this.connection.disconnect();
    }
  };

  setupConnectionEvents = () => {
    // When the connection opens, set the ID and connect to any pending rooms.
    this.connection.on('connect', () => {
      this.connected = true;

      log.debug('Websocket connection open:', this.connection.id);
      this.emitter.emit('connected', { wsConnId: this.connection.id });

      // Attempt to connect to any pending room
      _.forEach(this.rooms, (room, name) => {
        room.connection = this.connection;

        if (room.pending) {
          room.join();
        }
      });
    });

    // On connection error, log out the error stack.
    this.connection.on('connect_error', err => {
      log.error('Websocket connection error:', err.stack);
    });

    // On connection timeout, log out the error stack.
    this.connection.on('connect_timeout', () => {
      log.error('Websocket connection timeout');
    });

    // Catch all messages and emit them to their correct room
    this.connection.on('message', ({ action, room, data }) => {
      log.debug('ws:', { action, room, data });
      this.room(room).emit(action, data);
    });

    this.connection.on('error', reason => {
      log.debug('Websocket error:', reason);
    });

    // Log when attempting to reconnect
    this.connection.on('reconnect', attempt => {
      log.debug(`Websocket reconnected after ${attempt} attempts`);
      this.connected = false;
      // Don't leave the rooms because we aren't connected so it won't do anything.
      // This also allows us to keep any event listeners connected.
      _.forEach(this.rooms, room => {
        if (room.connected) {
          room.connected = false;
          room.pending = true;
        }
      });
    });

    this.connection.on('reconnect_attempt', attempt => {
      log.debug(`Websocket attempting to reconnect (${attempt})...`);

      // on reconnection, reset the transports option, as the Websocket
      // connection may have failed (caused by proxy, firewall, browser, ...)
      this.connection.io.opts.transports = ['polling', 'websocket'];
    });

    this.connection.on('reconnect_error', err => {
      log.error('Websocket reconnect error:', err.stack);
    });

    this.connection.on('reconnect_failed', () => {
      log.error('Websocket reconnect failed');
    });

    // When a connection ends, move current rooms to pending.
    this.connection.on('disconnect', reason => {
      log.debug(`Websocket ${reason}:`, this.connection.id);
      this.connected = false;

      // Don't leave the rooms because we aren't connected so it won't do anything.
      // This also allows us to keep any event listeners connected.
      _.forEach(this.rooms, room => {
        if (room.connected) {
          room.connected = false;
          room.pending = true;
        }
      });
    });

    // Remove connection when the full object has been destroyed.
    this.connection.on('destroy', () => {
      this.connected = false;
      log.debug('Websocket connection destroyed.');

      _.forEach(this.rooms, (room, name) => {
        room.leave();
      });

      this.rooms = {};
      this.connection = undefined;
    });
  };

  room = name => {
    let connection;
    if (this.connected) {
      connection = this.connection;
    }

    this.rooms[name] = this.rooms[name] || new Room({ name, connection });

    return this.rooms[name];
  };

  join = name => {
    if (typeof window === 'undefined') return;

    return this.room(name).join();
  };

  leave = name => {
    if (typeof window === 'undefined') return;

    return this.room(name).leave();
  };
}
