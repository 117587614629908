import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import FormCheckbox from '@platform/components/FormCheckbox';
import SidebarPopout from '@platform/components/SidebarPopout';
import { alert } from '@platform/utils/alert';
import { getConfigVar } from '@platform/utils/config';
import { isCustomDomain, getBuildLink } from '@platform/utils/publishing';
import { colors, Button } from '@core/ui';

import GraphQL from '@platform/containers/GraphQL';

import {
  Advanced,
  Authorization,
  BuildList,
  DangerZone,
  FileSelect,
  Integrations,
  Summary,
} from './sections';

class PublishViewer extends React.Component {
  componentWillMount() {
    const { doc, publishStore } = this.props;

    if (publishStore && doc) {
      publishStore.activate({ doc });

      if (_.isEmpty(publishStore.files)) {
        publishStore.getLatestFiles();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoading, doc, routerStore, publishStore } = this.props;

    if (!isLoading && !doc) {
      routerStore.setQueryParams({ domain: null });
      return;
    }

    if (_.get(prevProps.doc, 'id') !== _.get(doc, 'id')) {
      _.invoke(prevProps.publishStore, 'deactivate');
      _.invoke(publishStore, 'activate', { doc });
    } else if (!_.isEqual(prevProps.doc, doc)) {
      _.invoke(publishStore, 'activate', { doc });
    }
  }

  handlePublish = async () => {
    const { publishStore, doc, updateHandler, createBuildHandler } = this.props;

    if (publishStore.isPublishing) return;

    if (!publishStore.canPublish) {
      alert.error('Publishing requires Admin access to this project.');
      return;
    }

    let confirmText = `Are you sure you want to ${
      publishStore.setLive ? 'publish' : 'build'
    }?\n\nThe build process will take about 2-5 minutes, but you will be able to cancel ${
      publishStore.setLive ? 'or unpublish ' : ''
    }at any time.`;

    if (publishStore.setLive) {
      confirmText = `${confirmText}\n\nOnce the build process has finished, your built Hub will automatically go live on ${
        doc.domain
      }.`;
    } else {
      confirmText = `${confirmText}\n\nOnce the build process has finished, you will able to view your built Hub before setting it live on ${
        doc.domain
      }.`;
    }

    const c = window.confirm(confirmText);
    if (!c) return;

    try {
      publishStore.setValue('_publishing', true);

      await Promise.all([
        updateHandler.run({
          variables: {
            id: doc.id,
            input: {
              config: publishStore.buildConfig,
            },
          },
        }),
        publishStore.createRelease(),
      ]);

      const response = await createBuildHandler.run({
        variables: {
          docId: doc.id,
          domain: doc.domain,
          input: { setLive: publishStore.setLive },
        },
      });

      const buildId = _.get(response, 'data.createDocBuild.id');

      publishStore.toggleShowLogs(buildId, true);

      setTimeout(() => {
        const elem = document.getElementById(`Build-${buildId}`);
        if (!elem) return;
        elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 1000);
    } catch (e) {
      publishStore.setValue('_publishing', false);

      alert.error(e.message || String(e));
    }
  };

  renderPublishButton = () => {
    const { doc, publishStore } = this.props;

    if (!doc) return null;

    const hasDomain = !_.isEmpty(_.trim(doc.domain));
    const hasFile = !_.isEmpty(publishStore.filePath);
    const isPublishing = publishStore.isPublishing;

    let publishText = 'Build';

    if (!hasDomain) {
      publishText = 'Choose a domain';
    } else if (!hasFile) {
      publishText = 'Choose a Hub file';
    } else if (isPublishing) {
      publishText = publishStore.setLive ? 'Publishing...' : 'Building...';
    } else if (publishStore.setLive) {
      publishText = 'Build & Publish';
    }

    return (
      <Button
        key="publish"
        className={cn('mr-3', {
          'ml-3': !!doc.liveBuildId,
        })}
        color={colors.accent}
        disabled={!publishStore.canPublish || isPublishing || !hasDomain || !hasFile}
        onClick={this.handlePublish}
      >
        {publishText}
      </Button>
    );
  };

  render() {
    const { isLoading, doc, domain, namespace, publishStore, routerStore } = this.props;

    if (isLoading || !doc) {
      return <SidebarPopout loading inverted />;
    }

    const actions = [
      this.renderPublishButton(),
      <FormCheckbox
        inline
        key="set-live"
        label="Set Live"
        labelPosition="right"
        labelClassname="text-muted"
        disabled={publishStore.isPublishing}
        tip={
          <div>
            When checked, your next build will <b>automatically</b> get set as the live Hub on{' '}
            <u>{domain}</u>.
            <div className="mt-2">
              When unchecked, you will be able to view your built Hub <b>before</b> setting it live.{' '}
              <b>(Recommended)</b>
            </div>
          </div>
        }
        checked={publishStore.setLive}
        onChange={checked => publishStore.setValue('setLive', checked)}
      />,
    ];

    if (doc.liveBuildId) {
      actions.unshift(
        <a key="open-hub" href={getBuildLink({ domain, build: doc, isLive: true })} target="_blank">
          <Button color={colors.secondary}>Open Hub</Button>
        </a>
      );
    }

    let subtitle;

    if (!publishStore.canPublish) {
      subtitle = <div className="text-red">Publishing requires Admin access to this project</div>;
    } else if (doc && !doc.liveBuildId && isCustomDomain(domain)) {
      const ingressHost = getConfigVar('SL_PUBS_INGRESS');

      subtitle = (
        <div>
          Please make sure you have a DNS CNAME record pointing to{' '}
          <a href={ingressHost} target="_blank" rel="noopener noreferrer">
            {ingressHost}
          </a>
          .
        </div>
      );
    }

    return (
      <SidebarPopout
        title={domain}
        subtitle={subtitle}
        actions={<div className="flex items-center">{actions}</div>}
        handleClose={() => routerStore.setQueryParams({ show: null, domain: null })}
      >
        <div className="px-6 py-14">
          <FileSelect doc={doc} namespace={namespace} />

          <Integrations doc={doc} namespace={namespace} />

          <Authorization doc={doc} namespace={namespace} />

          <Advanced doc={doc} namespace={namespace} />

          <BuildList doc={doc} namespace={namespace} isPublishing={publishStore.isPublishing} />

          <Summary domain={domain} />

          <DangerZone doc={doc} namespace={namespace} />
        </div>
      </SidebarPopout>
    );
  }
}

const InjectedPublishViewer = inject((stores, props) => {
  const { routerStore, publishStore } = stores;
  const { domain } = props;

  return {
    routerStore,
    publishStore: publishStore.getByDomain(domain),
  };
})(observer(PublishViewer));

export default props => {
  const { domain } = props;

  return (
    <GraphQL
      id="PublishViewer"
      service="docsService"
      methods={{
        info: {
          variables: { domain },
          pollInterval: 30000,
        },
        updateHandler: {},
        createBuildHandler: {},
      }}
      renderProp={({ info, updateHandler, createBuildHandler }) => {
        return (
          <InjectedPublishViewer
            {...props}
            doc={info.data.doc}
            isLoading={info.loading}
            updateHandler={updateHandler}
            createBuildHandler={createBuildHandler}
          />
        );
      }}
    />
  );
};
