import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import PageLoading from '@platform/components/PageLoading';

import { alert } from '@platform/utils/alert';

class ConfirmEmail extends React.Component {
  componentWillMount() {
    const { userService, routerStore } = this.props;

    const confirmation_token = _.get(routerStore, 'location.query.confirmation_token');
    if (_.isEmpty(confirmation_token)) return;

    userService.confirmEmail(
      { confirmation_token },
      () => {
        alert.success('Verified!');

        this.goToLogin(1000);
      },
      error => {
        // TODO: We should just send a new token to that email if the token is expired.
        // Is this a security issue? Probably not.
        // The only way to obtain that token is via the verification email.

        let message = 'Oops, the confirmation token is invalid or expired!';

        if (!_.isEmpty(error.errors)) {
          message = '';
          _.forOwn(error.errors, (reason, key) => {
            message = `${message} ${_.capitalize(key)} ${reason}.`;
          });
        }

        alert.error(message);

        this.goToLogin(1000);
      }
    );
  }

  goToLogin(timeout = 0) {
    if (typeof window === 'undefined') return;

    setTimeout(() => {
      window.location.href = '/login';
    }, timeout);
  }

  render() {
    const { routerStore } = this.props;
    const confirmation_token = _.get(routerStore, 'location.query.confirmation_token');

    if (_.isEmpty(confirmation_token)) {
      this.goToLogin();

      return null;
    }

    return (
      <div className="px-4 h-full flex items-center justify-center" style={{ minHeight: 700 }}>
        <PageLoading>Confirming Email</PageLoading>
      </div>
    );
  }
}

export default inject('userService', 'routerStore')(observer(ConfirmEmail));
