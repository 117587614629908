import { action, computed, flow, observable } from 'mobx';

import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode';
import { faPaintBrush } from '@fortawesome/pro-solid-svg-icons/faPaintBrush';

import { ICommandRegistry, SYMBOLS as COMMAND_SYMBOLS } from '@core/command';
import {
  Collection as DisposableCollection,
  create as createDisposable,
  IDisposable,
  NOOP as DISPOSABLE_NOOP,
} from '@core/disposable';
import { Emitter, Event } from '@core/emitter';
import { inject, provideSingleton } from '@core/ioc';
import { IMenuNode, IMenuRegistry, SYMBOLS as MENU_SYMBOLS } from '@core/menu';
import { IExtension, IStringObject } from '@core/types';

import { colors } from '@core/ui';

// TODO: Editor store is not the right place for routing stuff - router package?

import { DiagnosticSeverity } from '@stoplight/types';
import {
  ICommitMessage,
  IEditor,
  IEditorMode,
  IEditorPanel,
  IIsDirtyChangedEvent,
  IModeChangedEvent,
  INotification,
  IPanelChangedEvent,
  IResetEvent,
  IRouteLocation,
  IRouteOptions,
  ISaveHandler,
  IValidation,
  SYMBOLS,
} from './types';

export const COMMANDS = {
  save: 'save',
  reset: 'reset',
  setCommitMessage: 'set:commitMessage',
  setMode: 'set:mode',
  setPanel: 'set:panel',
  route: 'route',
};

export const MENUS = {
  toolbar: {
    primary: ['editor', 'toolbar', 'primary'],
    secondary: ['editor', 'toolbar', 'secondary'],
    plugin: ['editor', 'toolbar', 'plugin'],
  },
  tabs: {
    secondary: ['editor', 'tabs', 'secondary'],
  },
};

export const MODES: IStringObject<IEditorMode> = {
  read: {
    id: 'read',
    name: 'Read',
    verb: 'Reading',
    summary: 'A read-only presentation view',
    color: colors.green,
    icon: faBook,
  },
  design: {
    id: 'design',
    name: 'Design',
    verb: 'Designing',
    summary: 'Edit with a visual designer',
    color: colors.purple,
    icon: faPaintBrush,
  },
  code: {
    id: 'code',
    name: 'Code',
    verb: 'Coding',
    summary: 'Edit the raw content',
    color: colors.orange,
    icon: faCode,
  },
};

export const DEFAULT_MODES = [MODES.code];

@provideSingleton(SYMBOLS.Editor)
export class EditorStore implements IEditor {
  public readonly supportedModes: IStringObject<IEditorMode>;
  public readonly primaryToolbar: IMenuNode;
  public readonly secondaryToolbar: IMenuNode;
  public readonly pluginToolbar: IMenuNode;
  public readonly secondaryTabs: IMenuNode;

  public isActivated: boolean = true;

  // @ts-ignore
  private _menuRegistry: IMenuRegistry;

  // @ts-ignore
  private _commandRegistry: ICommandRegistry;

  private _commands: DisposableCollection = new DisposableCollection();
  private _activeSpecExtension?: IExtension;

  @observable.shallow
  private _notifications: INotification[] = [];

  @observable.shallow
  private _validations: IValidation[] = [];

  @observable
  private _activeMode?: IEditorMode = MODES.design;

  @observable.ref
  private _enabledModes: IEditorMode[] = DEFAULT_MODES;

  @observable
  private _activePanel?: IEditorPanel;

  @observable
  private _enabledPanels: IEditorPanel[] = [];

  @observable
  private _isDirty: boolean = false;

  @observable
  private _isShowingCommitMessage: boolean = false;

  @observable
  private _activeValidationSeverity?: DiagnosticSeverity;

  @observable
  private _isSaving: boolean = false;

  @observable.ref
  private _saveHandler?: ISaveHandler;

  @observable
  private _commitMessage: ICommitMessage = {
    summary: '',
  };

  /**
   * Emitters
   */

  private _didChangeDirtyEmitter = new Emitter<IIsDirtyChangedEvent>();
  private _didChangeModeEmitter = new Emitter<IModeChangedEvent>();
  private _didChangePanelEmitter = new Emitter<IPanelChangedEvent>();
  private _didResetEmitter = new Emitter<IResetEvent>();

  private constructor(
    @inject(MENU_SYMBOLS.MenuRegistry) menuRegistry: IMenuRegistry,
    @inject(COMMAND_SYMBOLS.CommandRegistry) commandRegistry: ICommandRegistry
  ) {
    this.supportedModes = MODES;
    this._menuRegistry = menuRegistry;
    this._commandRegistry = commandRegistry;

    this.activate();

    this.primaryToolbar = this._menuRegistry.getMenu(MENUS.toolbar.primary);
    this.secondaryToolbar = this._menuRegistry.getMenu(MENUS.toolbar.secondary);
    this.pluginToolbar = this._menuRegistry.getMenu(MENUS.toolbar.plugin);
    this.secondaryTabs = this._menuRegistry.getMenu(MENUS.tabs.secondary);
  }

  public activate() {
    this.isActivated = true;
    this.registerCommands();
  }

  public deactivate() {
    this.isActivated = false;
    this._commands.dispose();
  }

  /**
   * MODE
   */

  @computed
  get activeMode() {
    let am = this._activeMode;
    if (!am) return;

    // @ts-ignore
    const idx = this._enabledModes.findIndex(m => m.id === am.id);

    /**
     * mode is enabled, show the next one
     *
     * (MM) TODO: be smarter about looping. should prob create some helper functions
     * that are also used in the alt+shift+left/right keyboard shortcut functions below
     */
    am = idx < 0 ? this._enabledModes[0] : this._enabledModes[idx];

    return am;
  }

  public setActiveMode = (mode: IEditorMode) => {
    const props: IModeChangedEvent = { mode };
    this._commandRegistry.executeCommand(COMMANDS.setMode, props);
  };

  @action
  public setEnabledModes = (modes: IEditorMode[]) => {
    this._enabledModes = modes;
  };

  @computed
  get enabledModes() {
    return this._enabledModes;
  }

  get onDidChangeMode(): Event<IModeChangedEvent> {
    return this._didChangeModeEmitter.event;
  }

  /**
   * VALIDATIONS
   */

  @computed
  get validations() {
    return this._validations;
  }

  public filterValidations(type: DiagnosticSeverity) {
    return this._validations.filter(m => m.severity === type);
  }

  @computed
  get info() {
    return this.filterValidations(DiagnosticSeverity.Information);
  }

  @computed
  get warnings() {
    return this.filterValidations(DiagnosticSeverity.Warning);
  }

  @computed
  get errors() {
    return this.filterValidations(DiagnosticSeverity.Error);
  }

  /**
   * TODO: remove resetValidations
   *
   * Consumers that addValidations should be responsible for disposing them.
   * Only here because of bad platform editor arch, remove when consolidated.
   */
  @action
  public resetValidations = () => {
    this._validations = [];
  };

  @action
  public addValidations = (validations: IValidation[]) => {
    this._validations = this._validations.concat(validations);

    return createDisposable(() => {
      for (const validation of validations) {
        this._removeValidation(validation);
      }
    });
  };

  @action
  public addValidation = (validation: IValidation) => {
    this._validations = this._validations.concat([validation]);

    return createDisposable(() => {
      this._removeValidation(validation);
    });
  };

  @action
  private _removeValidation = (validation: IValidation) => {
    const idx = this._validations.indexOf(validation);
    if (idx >= 0) {
      this._validations.splice(idx, 1);
    }
  };

  @computed
  get activeValidations() {
    if (this._activeValidationSeverity === DiagnosticSeverity.Error) return this.errors;
    if (this._activeValidationSeverity === DiagnosticSeverity.Warning) return this.warnings;
    if (this._activeValidationSeverity === DiagnosticSeverity.Information) return this.info;

    return [];
  }

  @computed
  get activeValidationSeverity() {
    return this._activeValidationSeverity;
  }

  /**
   * NOTIFICATIONS
   */

  @computed
  get notifications() {
    return this._notifications;
  }

  @action
  public clearNotifications = (id?: string) => {
    if (id) {
      this._notifications = this._notifications.filter(n => n.id !== id);
    } else {
      this._notifications = [];
    }
  };

  @action
  public addNotification = (notification: INotification) => {
    // use id to guarantee only one of this specific notification is shown at a time
    if (notification.id) {
      const idx = this._notifications.findIndex(n => n.id === notification.id);
      if (idx >= 0) this._notifications.splice(idx, 1);
    }

    this._notifications.push(notification);

    return createDisposable(() => {
      this.removeNotification(notification);
    });
  };

  @action
  public removeNotification = (notification: INotification) => {
    const idx = this._notifications.indexOf(notification);
    if (idx >= 0) {
      this._notifications.splice(idx, 1);
    }
  };

  /**
   * PANELS
   */

  @computed
  get activePanel() {
    const am = this._activePanel;
    if (!am) return;

    return this._enabledPanels.find(m => m.id === am.id);
  }

  public setActivePanel = (id: string) => {
    // const panel = this._enabledPanels.find(m => m.id === id);

    // if (!panel) {
    //   console.warn(`setActivePanel: cannot find panel with id ${id}`);
    //   return;
    // }

    this._commandRegistry.executeCommand(COMMANDS.setPanel, id);
  };

  @action
  public setEnabledPanels = (panels: IEditorPanel[]) => {
    this._enabledPanels = panels;
  };

  @computed
  get enabledPanels() {
    return this._enabledPanels;
  }

  get onDidChangePanel(): Event<IPanelChangedEvent> {
    return this._didChangePanelEmitter.event;
  }

  /**
   * PERSISTENCE
   */

  // (MM) TODO: should be tracked in the file model
  @computed
  get isDirty() {
    return this._isDirty;
  }

  // (MM) TODO: should be tracked in the file model
  @action
  public setIsDirty = (isDirty: boolean) => {
    if (isDirty === this.isDirty) return;
    this._isDirty = isDirty;

    if (isDirty) {
      // TODO: test for this
      this.showCommitMessage(false);
    }

    this._didChangeDirtyEmitter.fire({
      isDirty,
    });
  };

  // (MM) TODO: should be tracked in the file model
  get onDidChangeDirty(): Event<IIsDirtyChangedEvent> {
    return this._didChangeDirtyEmitter.event;
  }

  @computed
  get commitMessage() {
    return this._commitMessage;
  }

  @action
  public setCommitMessage = (message: ICommitMessage) => {
    this._commandRegistry.executeCommand(COMMANDS.setCommitMessage, message);
  };

  @computed
  get isShowingCommitMessage() {
    return this._isShowingCommitMessage;
  }

  @action
  public showCommitMessage = (isShowingCommitMessage: boolean) => {
    if (isShowingCommitMessage === this.isShowingCommitMessage) return;
    this._isShowingCommitMessage = isShowingCommitMessage;
  };

  @computed
  get isSaving() {
    return this._isSaving;
  }

  public save = () => {
    this._commandRegistry.executeCommand(COMMANDS.save);
  };

  @action
  public setSaveHandler = (handler: ISaveHandler) => {
    this._saveHandler = handler;
  };

  @computed
  public get isReadonly() {
    return this._saveHandler ? false : true;
  }

  public reset = () => {
    this._commandRegistry.executeCommand(COMMANDS.reset);
  };

  get onDidReset(): Event<IResetEvent> {
    return this._didResetEmitter.event;
  }

  /**
   * Extensions
   */

  get activeSpecExtension() {
    return this._activeSpecExtension;
  }

  @action.bound
  public activateSpecExtension(ex?: IExtension): IDisposable {
    // if already activated, return
    if (ex && this._activeSpecExtension && ex.id === this._activeSpecExtension.id)
      return DISPOSABLE_NOOP;

    // deactivate existing extension
    if (this._activeSpecExtension) this._activeSpecExtension.deactivate();

    // set and activate
    if (ex) {
      this._activeSpecExtension = ex;
      ex.activate();
    } else {
      this._activeSpecExtension = undefined;
    }

    return createDisposable(() => {
      if (ex) {
        if (this._activeSpecExtension === ex) {
          this._activeSpecExtension = undefined;
        }

        ex.dispose();
      }
    });
  }

  /**
   * Routing
   *
   * TODO: doesn't belong here
   */

  public route = (location: IRouteLocation, options?: IRouteOptions) => {
    this._commandRegistry.executeCommand(COMMANDS.route, location, options);
  };

  @action.bound
  private registerCommands() {
    const self = this;

    this._commands.push(
      this._commandRegistry.registerCommand(
        {
          id: COMMANDS.save,
          label: 'Save',
          shortcut: 'mod + s',
        },
        {
          isActive: () => {
            return this.isDirty;
          },

          execute: flow(function*() {
            if (self.isSaving) return;

            if (!self._saveHandler) {
              throw new Error('Cannot save, handler not set.');
            }

            if (!self.isShowingCommitMessage) {
              return self.showCommitMessage(true);
            }

            try {
              self._isSaving = true;
              self.showCommitMessage(false);

              // reset notifications
              self._notifications = [];

              // (MM) TODO: this should happen in core.
              yield self._saveHandler({
                commitMessage: self.commitMessage,
              });
            } catch (e) {
              let message = String(e);

              if (typeof e === 'object') {
                if (e.response && e.response.data) {
                  message = `${e.response.data.name}: ${e.response.data.message}`;
                } else if (e.message) {
                  message = e.message;
                }
              }

              self.addNotification({
                severity: DiagnosticSeverity.Error,
                message,
              });
            }

            self._isSaving = false;
          }),
        }
      )
    );

    this._commands.push(
      this._commandRegistry.registerCommand(
        {
          id: COMMANDS.reset,
          label: 'Reset Editor',
        },
        {
          isActive: () => {
            return this.isDirty;
          },

          execute: () => {
            this._didResetEmitter.fire({});
          },
        }
      )
    );

    this._commands.push(
      this._commandRegistry.registerCommand(
        {
          id: COMMANDS.setMode,
          label: 'Change Mode',
        },
        {
          execute: action((props: IModeChangedEvent) => {
            if (props.mode === this.activeMode) return;

            this._activeMode = props.mode;
            this._didChangeModeEmitter.fire({
              mode: props.mode,
            });
          }),
        }
      )
    );

    this._commands.push(
      this._commandRegistry.registerCommand(
        {
          id: COMMANDS.setCommitMessage,
          label: 'Set Commit Message',
        },
        {
          execute: action((message: ICommitMessage) => {
            this._commitMessage = message;
          }),
        }
      )
    );

    this._commands.push(
      this._commandRegistry.registerCommand(
        {
          id: COMMANDS.setPanel,
          label: 'Set Active Panel',
        },
        {
          execute: action((id?: string) => {
            let panel;

            if (id && (!this._activePanel || id !== this._activePanel.id)) {
              panel = this._enabledPanels.find(m => m.id === id);

              if (!panel) {
                console.warn(`setActivePanel: cannot find panel with id ${id}`);
                return;
              }

              this._activePanel = panel;
            } else {
              this._activePanel = undefined;
            }

            this._didChangePanelEmitter.fire({ panel });
          }),
        }
      )
    );

    this._commands.push(
      this._commandRegistry.registerCommand({
        id: COMMANDS.route,
      })
    );

    // register change mode shortcuts
    this._commands.push(
      this._commandRegistry.registerShortcut('mod + alt + shift + left', {
        execute: () => {
          if (!this.activeMode) {
            return this.setActiveMode(MODES.design);
          }

          const idx = this._enabledModes.indexOf(this.activeMode);
          const target = idx - 1;
          this.setActiveMode(
            target < 0
              ? this._enabledModes[this._enabledModes.length - 1]
              : this._enabledModes[target]
          );
        },
      })
    );
    this._commands.push(
      this._commandRegistry.registerShortcut('mod + alt + shift + right', {
        execute: () => {
          if (!this.activeMode) {
            return this.setActiveMode(MODES.design);
          }

          const idx = this._enabledModes.indexOf(this.activeMode);
          const target = idx + 1;
          this.setActiveMode(
            target >= this._enabledModes.length ? this._enabledModes[0] : this._enabledModes[target]
          );
        },
      })
    );
  }
}
