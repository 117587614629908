import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Popup, Menu, Icon } from 'semantic-ui-react';

import { canUser } from '@platform/utils/acl';
import { alert } from '@platform/utils/alert';
import { Link } from '@platform/utils/router';

import EntityTagsDropdown from '../EntityTagsDropdown';

class ProjectSettingsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isPopupOpen: false };
  }

  componentWillMount() {
    this.updateUiData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateUiData(nextProps);
  }

  updateUiData = ({ ui, projectTags, updateUi }) => {
    if (!ui.projectTags && !_.isEmpty(projectTags)) {
      updateUi('set', 'projectTags', _.clone(projectTags));
    }
  };

  render() {
    const {
      tags,
      namespace = {},
      project = {},
      projectService,
      ui,
      updateUi,
      clearUi,
    } = this.props;

    const canUpdate = projectService.canUser({ action: 'update:project' });

    // display by default and error out if user does something they're not supposed to
    // if (!canUpdate) return null;

    const canDelete = projectService.canUser({
      action: 'remove:project',
    });

    let tagsDropdownPlaceholder;
    switch (namespace.namespace_type) {
      case 'user':
        tagsDropdownPlaceholder = 'Define tags in your profile settings. Assign them here.';
        break;
      case 'org':
        tagsDropdownPlaceholder = 'Define tags in your org settings. Assign them here.';
        break;
      default:
        tagsDropdownPlaceholder = 'Define tags and assign them here.';
    }

    const { isPopupOpen, isRemovingTag } = this.state;

    const original = project.tag_list.toJS();
    const edited = ui.projectTags || original;

    // NOTE: popup needs to be a controlled component because otherwise removing a tag
    // closes the popup which is super annoying

    return (
      <Popup
        hoverable
        flowing
        trigger={<Icon name="setting" className="text-muted is-standalone cursor-pointer" />}
        position="bottom right"
        style={{ padding: 0, width: 400 }}
        offset={11}
        open={isPopupOpen}
        onOpen={() => {
          this.setState({ isPopupOpen: true });
        }}
        onClose={() => {
          if (isRemovingTag) return;

          this.setState({ isPopupOpen: false });

          if (_.isEqual(edited, original)) return;

          projectService
            .update(
              project.id,
              {
                tag_list: edited,
              },
              {
                namespaceType: 'orgs',
                namespaceId: namespace.id,
              }
            )
            .then(() => {
              clearUi();
            })
            .catch(() => {
              alert.error(`You must be a project administrator in order to update a project.`);
            });
        }}
      >
        <Menu vertical size="tiny" fluid style={{ border: 'none' }}>
          <Menu.Item>
            <div className="font-bold mb-3 ml-2">Tags</div>

            <EntityTagsDropdown
              orgTags={_.map(tags, tag => {
                return {
                  key: tag.id,
                  type: tag.type || 'other',
                  text: tag.name,
                  value: tag.name,
                  description: _.truncate(tag.description, { length: 100 }),
                };
              })}
              projectTags={edited}
              isSaving={projectService.isSaving}
              label={null}
              placeholder={tagsDropdownPlaceholder}
              handleOnChange={updatedTags => {
                if (_.size(updatedTags) < _.size(edited)) {
                  this.setState({ isRemovingTag: true });
                }

                updateUi('set', 'projectTags', updatedTags);

                setTimeout(() => {
                  this.setState({ isRemovingTag: false });
                }, 0);
              }}
            />
          </Menu.Item>

          <Menu.Item name="View" as={Link} href={`/${project.path_with_namespace}`} />

          <Menu.Item
            name="More Settings"
            as={Link}
            href={`/${project.path_with_namespace}/settings`}
          />

          {canDelete && (
            <Menu.Item
              name="Delete"
              onClick={() => {
                projectService
                  .remove(project.id, {
                    namespaceType: 'orgs',
                    namespaceId: namespace.id,
                  })
                  .then(() => {
                    alert.success(`${project.name} deleted.`);
                  })
                  .catch(() => {
                    alert.error(
                      `You must be a project administrator in order to remove a project.`
                    );
                  });
              }}
            />
          )}
        </Menu>
      </Popup>
    );
  }
}

export default inject((stores, props) => {
  const { appStore, projectService } = stores;
  const { project } = props;

  return {
    projectService,
    ...appStore.injectUi(`ProjectSettingsPopup-${project.id}`),
  };
})(observer(ProjectSettingsPopup));
