import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faTags } from '@fortawesome/pro-regular-svg-icons/faTags';
import { faSitemap } from '@fortawesome/pro-solid-svg-icons/faSitemap';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';

import FormInput from '@platform/components/FormInput';
import FormTextarea from '@platform/components/FormTextarea';
import SimpleSection from '@platform/components/SimpleSection';
import FormInputList from '@platform/components/FormInputList';
import FormCheckbox from '@platform/components/FormCheckbox';

import { colors, sizes, Button } from '@core/ui';

class HubSettings extends React.Component {
  renderHeaderSection = (navPos, routeOptions) => {
    const { store } = this.props;
    const { parsed, pages, flattenedNav } = store;

    const items = _.get(parsed, ['header', 'nav', navPos]);

    return (
      <SimpleSection
        className="mb-12"
        title={`${_.capitalize(navPos)} Header Links`}
        icon={faSitemap}
        actions={
          <Button
            icon={faPlus}
            color={colors.positive}
            size={sizes.sm}
            disabled={_.find(items, { title: '', path: '' })}
            onClick={() => {
              store.updateParsed('push', ['header', 'nav', navPos], { title: '', path: '' });
            }}
          >
            Add
          </Button>
        }
      >
        <FormInputList
          className="flex flex-col w-full"
          allowAdditions={false} // Use this so we don't render an add button
          fields={items}
          properties={[
            {
              type: 'button',
              labelProps: {
                className: 'px-6',
              },
              buttonProps: {
                icon: 'bars',
              },
              skipDivider: true,
            },
            {
              type: 'text',
              label: 'Title',
              name: 'title',
              placeholder: 'API Reference',
              skipDivider: true,
            },
            {
              type: 'search',
              label: 'Page or Link',
              name: 'path',
              placeholder: '/api-reference',
              skipDivider: true,
              icon: 'caret down',
              options: routeOptions,
              onResultSelect: (index, result = {}, item = {}) => {
                // Force set the title
                store.updateParsed('set', ['header', 'nav', navPos].concat([index]), {
                  ...item,
                  path: result.value,
                });
              },
            },
          ]}
          handleUpdate={(t, p, v) => {
            store.updateParsed(t, ['header', 'nav', navPos].concat(p), v);
          }}
          preserveProp
          sortable
        />
      </SimpleSection>
    );
  };

  render() {
    const { className, store } = this.props;
    const { routes, parsed } = store;
    const { title = '', logo = '', meta = {} } = parsed || {};

    const routeOptions = _.map(routes, value => ({ title: value, value }));

    return (
      <div className={className}>
        <FormInput
          className="mb-3"
          label="Header Title"
          placeholder="Untitled Hub"
          value={title}
          onChange={(e, { value }) => store.updateParsed('set', 'title', value)}
          fluid
        />

        <FormInput
          className="mb-12"
          label="Header Logo"
          placeholder="https://cdn.stoplight.io/assets/images/logos/mark_light_bg_padded.png"
          value={logo}
          onChange={(e, { value }) => store.updateParsed('set', 'logo', value)}
          fluid
        />

        {this.renderHeaderSection('left', routeOptions)}
        {this.renderHeaderSection('right', routeOptions)}

        <SimpleSection className="mb-12" title="Meta Tags" icon={faTags}>
          <FormInput
            className="mb-4"
            label="Favicon"
            placeholder="https://stoplight.io/favicon.ico"
            value={meta.favicon}
            onChange={(e, { value }) => store.updateParsed('set', 'meta.favicon', value)}
            fluid
          />

          <FormInput
            className="mb-4"
            label="Meta Title"
            placeholder={`${title || 'My Hub'} - Technical documentation and API Reference`}
            value={meta.title}
            onChange={(e, { value }) => store.updateParsed('set', 'meta.title', value)}
            fluid
          />

          <FormTextarea
            label="Meta Description"
            value={meta.description || ''}
            placeholder="Stoplight Hubs are the easiest way to generate API documentation, technical guides, tutorials, and more! Powered by Stoplight.io"
            onChange={(e, { value }) => store.updateParsed('set', 'meta.description', value)}
            minRows={5}
            inputStyle={{ minHeight: 119 }} // (CL) TODO: Remove when https://github.com/andreypopp/react-textarea-autosize/issues/173#issuecomment-374604731
            bordered
          />

          <FormCheckbox
            label="Disallow search engines from indexing this content?"
            className="py-4 px-6"
            checked={meta.noindex || false}
            onChange={checked => {
              store.updateParsed('set', 'meta.noindex', checked);
            }}
            inline
          />
        </SimpleSection>
      </div>
    );
  }
}

export default inject(({ hubEditorStore }) => {
  const store = hubEditorStore.activeEditor || {};

  return {
    store,
  };
})(observer(HubSettings));
