import { observer } from 'mobx-react';
import * as React from 'react';

const cn: any = require('classnames');

import { ICommandRegistry, SYMBOLS as COMMAND_SYMBOLS } from '@core/command';
import { lazyInject } from '@core/ioc';
import { ActionMenuNode } from '@core/menu';
import { IMenuNode } from '@core/menu';
import { Button, ButtonGroup } from '@core/ui';
import { Dropdown, IDropdownChild } from '@core/ui';
import { colors, sizes, themes } from '@core/ui';
import { divClickHandler } from '@core/ui';
import { Icon, IIcon } from '@core/ui';
import { TextField } from '@core/ui';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faSave } from '@fortawesome/pro-solid-svg-icons/faSave';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';

import CommandContainer from './CommandContainer';
import { MODES as EDITOR_MODES } from './store';
import { IEditor, SYMBOLS as EDITOR_SYMBOLS } from './types';

export interface IEditorToolbar {
  height?: number;
  className?: string;
}

export class EditorToolbar extends React.Component<IEditorToolbar> {
  private _height = 31;

  @lazyInject(EDITOR_SYMBOLS.Editor)
  // @ts-ignore
  private _editor: IEditor;

  @lazyInject(COMMAND_SYMBOLS.CommandRegistry)
  // @ts-ignore
  private _commandRegistry: ICommandRegistry;

  constructor(props: any) {
    super(props);
    this._height = props.height || this._height;
  }

  public render() {
    const { className } = this.props;
    const { primaryToolbar, secondaryToolbar } = this._editor;

    return (
      <div
        className={cn(className, 'flex items-center relative z-5 bg-grey-lightest')}
        style={{ height: this._height }}
      >
        <div className="EditorModes flex h-full">
          {this._renderModes()}
          {this._renderSave()}
          {this._renderMenuGroup(primaryToolbar, 'left')}
        </div>

        <div className="flex-1 h-full px-2 border-b border-grey-light" />

        <div className="flex h-full justify-end">
          {this._renderMenuGroup(secondaryToolbar, 'right')}
          {this._renderPanelMenus()}
        </div>
      </div>
    );
  }

  private _renderModes = () => {
    const { activeMode, enabledModes, setActiveMode } = this._editor;

    // no need to show mode select if there is only one mode
    if (enabledModes.length <= 1) return;

    // only collapse if we have tons of modes and are not in read mode
    if (activeMode === EDITOR_MODES.read || enabledModes.length < 5) {
      return enabledModes.map((m, index) => {
        return this._renderTab({
          name: m.name,
          active: m === activeMode,
          icon: m.icon,
          color: m.color,
          onClick: () => {
            setActiveMode(m);
          },
          key: index,
          className: 'border-r',
        });
      });
    }

    return (
      <Dropdown
        offset={{
          top: -7,
          left: -10,
        }}
        padding={0}
        posX="left"
        posY="bottom"
        renderTrigger={(attributes: any, props: any) => {
          const idx = enabledModes.findIndex(m => m === activeMode) || 1;
          const name = activeMode ? activeMode.verb || activeMode.name : 'Choose Mode';
          const modeIcon = activeMode ? activeMode.icon : faExclamationCircle;
          const color = activeMode ? activeMode.color || idx + 1 : colors.red;

          return this._renderTab({
            name,
            active: props.isVisible,
            icon: modeIcon,
            color,
            isDropdown: true,
            attributes,
            className: 'border-r',
          });
        }}
        computeSections={() => {
          return [
            {
              children: enabledModes.map(m => ({
                title: m.name,
                subtitle: m.summary,
                color: m.color,
                icon: m.icon,
                active: m === activeMode,
                onClick: () => {
                  setActiveMode(m);
                },
              })),
            },
          ];
        }}
      />
    );
  };

  private _renderTab({
    name,
    active,
    icon,
    color = colors.grey,
    isDropdown,
    attributes,
    onClick,
    key,
    className,
  }: {
    name?: any;
    active?: boolean;
    icon?: IIcon;
    color?: colors;
    isDropdown?: boolean;
    attributes?: any;
    onClick?: divClickHandler;
    key?: any;
    className?: string;
  }) {
    const style: React.CSSProperties = {};
    if (active && !isDropdown) {
      style.borderBottomColor = 'transparent';
    }

    return (
      <div
        key={key}
        className={cn(
          className,
          'flex items-center h-full border-b relative px-4 border-grey-light cursor-pointer text-sm font-bold',
          {
            'text-grey-dark hover:bg-grey-lighter': !active,
            'bg-grey-lighter': active && isDropdown,
            'text-grey-darker bg-white': active && !isDropdown,
          }
        )}
        onClick={onClick}
        style={style}
        {...attributes}
      >
        {icon && (
          <div
            className={cn(`text-${colors[color]}`, {
              'mr-2': name,
            })}
          >
            <Icon icon={icon} />
          </div>
        )}
        {name && <div>{name}</div>}
        {isDropdown && (
          <div className="ml-4">
            <Icon icon={faCaretDown} />
          </div>
        )}
      </div>
    );
  }

  private _renderSave() {
    const {
      activeMode,
      isDirty,
      isShowingCommitMessage,
      isSaving,
      isReadonly,
      save,
      reset,
      showCommitMessage,
      commitMessage,
      setCommitMessage,
    } = this._editor;

    if (activeMode === EDITOR_MODES.read && !isDirty) return;

    let saveColor = colors.grey;

    if (!isReadonly) {
      if (isShowingCommitMessage || isSaving) {
        saveColor = colors.positive;
      } else if (isDirty) {
        saveColor = colors.accent;
      }
    }

    let saveTitle;
    if (isShowingCommitMessage) {
      saveTitle = 'Confirm and save';
    } else if (isDirty) {
      saveTitle = 'No unsaved changes';
    } else if (isReadonly) {
      saveTitle = 'You do not have permission to save.';
    }

    let saveText = 'Save';
    if (isSaving) {
      saveText = 'Saving';
    } else if (isShowingCommitMessage) {
      saveText = 'Confirm';
    }

    return (
      <ButtonGroup className="h-full px-4 relative border-r border-b border-grey-light">
        <Button
          id="editor-toolbar-save"
          color={saveColor}
          disabled={!isDirty || isReadonly}
          size={sizes.sm}
          transparent={!isDirty || isReadonly}
          icon={isShowingCommitMessage ? faCheck : faSave}
          title={saveTitle}
          loading={isSaving}
          onClick={!isReadonly ? save : undefined}
        >
          {saveText}
        </Button>

        {isDirty &&
          !isSaving &&
          !isReadonly && (
            <Button
              id="editor-toolbar-undo"
              color={isShowingCommitMessage ? colors.warning : colors.grey}
              size={sizes.sm}
              icon={faTimes}
              title={isShowingCommitMessage ? 'cancel save' : 'undo changes'}
              onClick={() => {
                if (isShowingCommitMessage) {
                  showCommitMessage(false);
                  return;
                }

                const c = confirm('Are you sure you want to undo your changes?');
                if (c) {
                  reset();
                }
              }}
            />
          )}

        {isShowingCommitMessage &&
          !isReadonly && (
            <div className="absolute left -mt-1 -ml-px bg-grey-darker z-5" style={{ top: '100%' }}>
              <TextField
                value={commitMessage.summary}
                placeholder="a brief summary of changes"
                style={{ width: 500 }}
                theme={themes.dark}
                onChange={(e: any) => {
                  setCommitMessage({ summary: e.currentTarget.value });
                }}
                onEnter={() => {
                  save();
                }}
                onEscape={() => {
                  showCommitMessage(false);
                }}
                outlined={false}
                focus
              />
            </div>
          )}
      </ButtonGroup>
    );
  }

  private _renderMenuGroup(menuGroup: IMenuNode, loc: 'left' | 'right') {
    const menuGroupElems: any[] = [];

    if (!menuGroup.children) {
      return menuGroupElems;
    }

    const children = menuGroup.children.slice();

    for (const i in children) {
      if (!children.hasOwnProperty(i)) continue;

      const group = children[i];
      const itemElems: any = [];

      let borderClass = '';
      if (children.length > 1 && children[parseInt(i) + 1] && loc === 'left') {
        borderClass = 'border-r';
      } else if (children.length > 1 && children[parseInt(i) - 1] && loc === 'right') {
        borderClass = 'border-l';
      }

      if (group.label && group.children) {
        menuGroupElems.push(
          <Dropdown
            key={group.id}
            offset={{
              top: -7,
              left: loc === 'left' ? -10 : 0,
              right: loc === 'right' ? -10 : 0,
            }}
            padding={0}
            posX={loc}
            posY="bottom"
            renderTrigger={(attributes: any, props: any) => {
              return this._renderTab({
                name: group.label || '',
                active: props.isVisible,
                icon: group.icon,
                color: group.color,
                isDropdown: true,
                attributes,
                className: borderClass,
              });
            }}
            computeSections={() => {
              if (!group.children) return [];

              return [
                {
                  children: group.children.map((item, index) => {
                    const aItem = item as ActionMenuNode;

                    // A little complexity here to wrap our dropdown item in a command container
                    // so that it re-renders when the command data changes
                    return (renderItem: (opts: IDropdownChild) => any) => {
                      return (
                        <CommandContainer
                          key={index}
                          commandId={aItem.commandId}
                          renderProp={command => {
                            // toolbar menu buttons don't show if their command is not visible or present
                            if (!command || !command.visible) {
                              return null;
                            }

                            return renderItem({
                              title: aItem.label || '',
                              subtitle: aItem.description,
                              color: aItem.color,
                              icon: aItem.icon,
                              onMouseDown: aItem.execute,
                              disabled: aItem.disabled || !command || !command.enabled,
                              loading: aItem.loading || (command && command.executing),
                            });
                          }}
                        />
                      );
                    };
                  }),
                },
              ];
            }}
          />
        );
      } else {
        const groupChildren = group.children ? group.children : [group];

        for (const item of groupChildren) {
          const aItem = item as ActionMenuNode;

          const aItemCommand = this._commandRegistry.getCommand(aItem.commandId);
          if (!aItemCommand || !aItemCommand.visible) continue;

          const itemElem = (
            <CommandContainer
              key={aItem.id}
              commandId={aItem.commandId}
              renderProp={command => {
                // toolbar menu buttons don't show if their command is not visible or present
                if (!command || !command.visible) {
                  return null;
                }

                const disabled = aItem.disabled || !command || !command.enabled;

                return (
                  <Button
                    id={`editor-toolbar-${aItem.id}`}
                    className={cn('mx-1')}
                    color={aItem.color || colors.grey}
                    size={sizes.sm}
                    transparent
                    icon={aItem.icon}
                    onMouseDown={aItem.execute}
                    loading={aItem.loading || (command && command.executing)}
                    disabled={disabled}
                  >
                    {aItem.label}
                  </Button>
                );
              }}
            />
          );

          if (itemElem) {
            itemElems.push(itemElem);
          }
        }
      }

      if (itemElems.length) {
        menuGroupElems.push(
          <div
            key={group.id}
            className={cn(
              'flex h-full items-center px-3 relative border-b border-grey-light',
              borderClass
            )}
          >
            {itemElems}
          </div>
        );
      }
    }

    return menuGroupElems;
  }

  private _renderPanelMenus() {
    const { activePanel, secondaryTabs } = this._editor;

    if (!secondaryTabs.children) {
      return [];
    }

    const children = secondaryTabs.children.slice();

    return children.map((item, index) => {
      const aItem = item as ActionMenuNode;

      const { id, label, icon, execute, color } = aItem;

      if (!label && !icon) {
        return null;
      }

      const active = activePanel && id === activePanel.id;
      const textColor = color ? `text-${colors[color]}` : 'text-grey-dark hover:text-grey-darker';

      return (
        <div
          key={index}
          id={`editor-toolbar-${id}`}
          className={cn(
            'flex items-center h-full relative px-4 border-b border-l border-grey-light cursor-pointer text-sm font-bold',
            active ? 'text-active' : textColor
          )}
          onClick={execute}
        >
          {icon && (
            <div className={cn(label && 'mr-2')}>
              <Icon icon={icon} />
            </div>
          )}
          {label && <div>{label}</div>}
        </div>
      );
    });
  }
}

export default observer(EditorToolbar);
