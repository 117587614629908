import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import SplitPane from '@platform/components/SplitPane';
import FormSearch from '@platform/components/FormSearch';
import SplitSidebar from '@platform/components/SplitSidebar';
import ErrorBoundary from '@platform/components/ErrorBoundary';

class EntityEditor extends React.Component {
  static propTypes = {
    orgId: PropTypes.number,
    entity: PropTypes.object.isRequired,
    entityData: PropTypes.object.isRequired,
    editorStore: PropTypes.object.isRequired,
    contentRouterData: PropTypes.object.isRequired,
    contentTabs: PropTypes.array,
    widgetContent: PropTypes.node,
    className: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  componentWillMount() {
    this.initEditor(this.props);
  }

  componentWillUnmount() {
    const editor = this.getEditor();
    if (!_.isEmpty(editor)) {
      editor.deactivate();
      editor.updateSearchExpression();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { entity, showTheme } = this.props;
    const { entity: nextEntity, showTheme: nextShowTheme } = nextProps;

    // clears search expression when switching between editors of the same type
    const editor = this.getEditor();
    editor.updateSearchExpression();

    if (_.get(entity, 'id') !== _.get(nextEntity, 'id')) {
      _.invoke(editor, 'deactivate');

      this.initEditor(nextProps);
    } else if (!nextShowTheme && showTheme !== nextShowTheme) {
      this.initEditor(nextProps);
    }
  }

  getEditor = () => {
    const { editorStore, entity = {} } = this.props;

    return editorStore.getEditor({ id: entity.id }) || {};
  };

  initEditor = (props, { reload, force } = {}) => {
    props.editorStore.initEditor(
      {
        orgId: props.orgId,
        entity: props.entity,
        entityType: props.entityData.type,
        error: props.error,
        contentRouterData: props.contentRouterData,
        contentTabs: props.contentTabs || [],
        embedded: props.embedded,
        readOnly: false,
      },
      {
        force,
      }
    );
  };

  render() {
    const { orgId, entity = {}, widgetContent, className = '' } = this.props;

    const editor = this.getEditor();
    let sidebarElem;
    if (editor.hasSidebar && editor.sidebarSections.length) {
      sidebarElem = (
        <div key="s" className="flex flex-col pin absolute overflow-scroll">
          <FormSearch
            key="SpecSearch"
            className="EntityEditor-search"
            icon="search"
            placeholder="Filter contents..."
            size="mini"
            value={editor._currentSpecSearchExpression}
            onSearchChange={editor.updateSearchExpression}
            open={false}
          />

          <SplitSidebar
            key="SplitSidebar"
            htmlId="tutorial-sidebar"
            className="flex-grow"
            sections={editor.sidebarSections}
          />
        </div>
      );
    }

    let contentElem;
    if (typeof widgetContent !== 'undefined') {
      contentElem = widgetContent;
    } else {
      const Component = _.get(editor, 'currentContentTab.component');
      const propFactory = _.get(editor, 'currentContentTab.propFactory');

      let componentProps = { editor };
      if (propFactory) {
        componentProps = propFactory({ editor });
      }

      if (Component) {
        contentElem = (
          <Component id={entity.id} orgId={orgId} entityId={entity.id} {...componentProps} />
        );
      } else {
        contentElem = 'Could not render component.';
      }
    }

    const mainElem = <ErrorBoundary key="m">{contentElem}</ErrorBoundary>;

    let renderedElem = (
      <div className={cn(className, 'flex flex-col overflow-x-scroll relative h-full')}>
        {sidebarElem ? (
          <SplitPane
            id="editor:sidebar:left"
            className="bg-white"
            defaultSize={300}
            renderProp={() => {
              return [sidebarElem, mainElem];
            }}
          />
        ) : (
          mainElem
        )}
      </div>
    );

    return renderedElem;
  }
}

export default inject((stores, props) => {
  const { routerStore, projectStore, rawEditorStore, fileSystemStore } = stores;
  const { editorStoreKey } = props;

  return {
    routerStore,
    projectStore,
    fileSystemStore,
    editorStore: stores[editorStoreKey] || rawEditorStore,
  };
})(observer(EntityEditor));
