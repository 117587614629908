import React from 'react';
import _ from 'lodash';
import { Input } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';

import { colors, sizes, Button, Icon } from '@core/ui';

import EditorContentSection from '../EditorContentSection';
import MarkdownEditor from '../MarkdownEditor';
import SortableList from '../SortableList';

const SwaggerTag = ({ id, tag, index = 0, onRemove, onChange, hideMoveButton }) => {
  if (!tag) return null;

  return (
    <div className="pt-3 pb-6">
      <div className="flex items-center mb-3">
        {!hideMoveButton && <Button className="h-lg pr-1 mr-3 cursor-move" icon={faBars} />}

        <Input
          className="w-1/3"
          value={tag.name || ''}
          label={{ basic: true, content: 'Name' }}
          onChange={e => {
            onChange('set', ['name'], e.target.value);
          }}
        />

        <Button
          className="h-lg pr-1 ml-3"
          icon={faTrash}
          color={colors.negative}
          onClick={onRemove}
        />
      </div>

      <MarkdownEditor
        id={`${id}:tag:${index}`}
        value={tag.description || ''}
        placeholder="Tag description..."
        onChange={val => {
          if (_.isEmpty(val)) {
            onChange('unset', ['description']);
          } else {
            onChange('set', ['description'], val);
          }
        }}
      />
    </div>
  );
};

const SwaggerTags = props => {
  const { value: tags = [], onChange, currentSpecEditor } = props;

  return (
    <div className="SwaggerTags">
      <EditorContentSection>
        <div className="rounded bg-grey-lighter text-muted font-semibold py-3">
          Define tags and add them to your operations. In "read" mode, operations will be grouped by
          their first tag. The ordering of these resource groups will be determined by the ordering
          of the tags below. You can can drag and drop in the list below to reorder them.
        </div>

        <Button
          className="my-3"
          color={colors.blue}
          icon={faTag}
          size={sizes.md}
          onClick={currentSpecEditor.addTag}
          outlined
        >
          Add Tag
        </Button>

        {tags.length > 1 ? (
          <SortableList
            items={_.map(tags, (tag, i) => {
              return {
                id: i,
                elem: (
                  <SwaggerTag
                    key={i}
                    index={i}
                    tag={tag}
                    onRemove={() => {
                      onChange('pull', [], i);
                    }}
                    onChange={(t, p, v) => {
                      onChange(t, [i].concat(p), v);
                    }}
                  />
                ),
              };
            })}
            handleReorder={(fromIndex, toIndex) => {
              onChange('move', [fromIndex], ['tags', toIndex]);
            }}
          />
        ) : (
          <SwaggerTag
            tag={_.first(tags)}
            onRemove={() => {
              onChange('pull', [], 0);
            }}
            onChange={(t, p, v) => {
              onChange(t, [0].concat(p), v);
            }}
            hideMoveButton
          />
        )}
      </EditorContentSection>
    </div>
  );
};

export default inject(({ oas2EditorStore }, { editorId }) => {
  return {
    currentSpecEditor: oas2EditorStore.getEditor({ id: editorId }),
  };
})(observer(SwaggerTags));
