import React from 'react';

import contentRouterFactory from '@platform/utils/content-routers/collection';

import ScenarioStep from '@platform/components/ScenarioEditor/step';
import CollectionOutput from '@platform/components/CollectionOutput';
import CollectionCoverage from '@platform/components/CollectionCoverage';
import ScenarioEditorOverview from '@platform/components/ScenarioEditor/overview';
import CollectionEditorOverview from '@platform/components/CollectionEditorOverview';

import EntityEditor from '../EntityEditor';
import EntityEditorCode from '../EntityEditor/spec';
import EntityEditorWidgets from '../EntityEditor/widgets';

const contentRouterData = contentRouterFactory({
  CollectionEditorOverview,
  CollectionCoverage,
  CollectionOutput,
  ScenarioEditorOverview,
  ScenarioStep,
});

const contentTabs = [
  {
    id: 'design',
    name: 'Visual',
    icon: 'options',
    component: EntityEditorWidgets,
  },
  {
    id: 'code',
    name: 'Code',
    icon: 'code',
    className: 'is-light',
    component: EntityEditorCode,
  },
];

const CollectionEditor = props => {
  return (
    <EntityEditor
      editorStoreKey="collectionEditorStore"
      contentRouterData={contentRouterData}
      contentTabs={contentTabs}
      {...props}
    />
  );
};

export default CollectionEditor;
