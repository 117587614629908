import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button } from 'semantic-ui-react';

import { Box, Flex, Icon } from '@stoplight/ui-kit';

import { ExplorerTheme, ThemeProvider } from '@platform/explorer/config';
import { getConfigVar } from '@platform/utils/config';
import { Link } from '@platform/utils/router';

import PageContent from '../PageContent';
import SidebarFilter from '../SidebarFilter';

class ExplorerWrapper extends React.Component<{
  namespaceStore?: any;
  orgService?: any;
  userService?: any;
  showOrgs?: boolean;
  showFilters?: boolean;
}> {
  public componentWillMount() {
    const { orgService, namespaceStore, showOrgs } = this.props;

    // find orgs
    if (showOrgs) {
      orgService.find();
    }

    const currentNamespace = _.get(namespaceStore, 'current', {});
    const { explorerStore, activeFilters } = currentNamespace;

    // search for nodes in case new were created
    if (explorerStore) {
      explorerStore.updateSearchQuery(activeFilters, undefined, { force: true });
    }
  }

  public renderFilters() {
    const { showFilters = false } = this.props;

    if (!showFilters) {
      return null;
    }

    return (
      <SidebarFilter
        filterName="type"
        displayName="Filters"
        mutliple={false}
        items={[
          {
            name: 'APIs',
            value: 'http_service',
            primary_color: ExplorerTheme.colors.http_service,
          },
          {
            name: 'Operations',
            value: 'http_operation',
            primary_color: ExplorerTheme.colors.http_operation_get,
          },
          {
            name: 'Models',
            value: 'json_schema',
            primary_color: ExplorerTheme.colors.json_schema,
          },
          {
            name: 'Articles',
            value: 'article',
            primary_color: ExplorerTheme.colors.article,
          },
        ]}
      />
    );
  }

  public renderOrgs() {
    const { showOrgs = false, orgService, userService } = this.props;

    const { authorizedUser } = userService;

    if (!showOrgs) {
      return null;
    }

    const orgs = orgService.currentList;

    let contentElem;
    if (orgService.isLoading && _.isEmpty(orgs)) {
      contentElem = (
        <div className="text-center py-2 text-muted">
          <Icon icon="circle-notch" spin={true} />
        </div>
      );
    } else {
      if (_.isEmpty(orgs)) {
        contentElem = <div className="py-2">Use orgs to manage teams and governance.</div>;
      } else {
        contentElem = (
          <div
            className="overflow-auto"
            style={{ maxHeight: 400, margin: '0 -14px', padding: '0 14px 12px' }}
          >
            {_.map(orgs.slice(), (org, index) => (
              <Box
                as={Link}
                key={index}
                href={`/${org.path}`}
                fontSize="11px"
                alignItems="center"
                display="block"
                color="rgba(19, 15, 33, 0.6)"
                pt="6px"
                css={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {org.name}
              </Box>
            ))}

            {orgService.loadMore && (
              <div className="mt-2">
                <Button
                  size="tiny"
                  className="w-full"
                  onClick={() => {
                    orgService.find(undefined, {
                      query: {
                        page: orgService.getCurrentPage() + 1,
                      },
                    });
                  }}
                  disabled={orgService.isFinding}
                  loading={orgService.isFinding}
                >
                  Load More
                </Button>
              </div>
            )}
          </div>
        );
      }
    }

    let newOrgButton;
    if (
      !getConfigVar('SL_DISABLE_NEW_ORGS') ||
      (getConfigVar('SL_DISABLE_NEW_ORGS') && authorizedUser.is_admin)
    ) {
      newOrgButton = (
        <Box
          as={Link}
          href="/?modal=new-org"
          display="box"
          p="2px 10px"
          minHeight="0px"
          fontSize="11px"
          fontWeight={600}
          borderRadius="2px"
          color="white"
          backgroundColor="#e040fb"
          css={{ ':hover': { color: 'white' } }}
        >
          New
        </Box>
      );
    }

    return (
      <Box p="14px 12px" pb={0} border="1px solid rgb(218, 225, 231)" width="200px">
        <Flex pb="8px" mb="5px" alignItems="center" borderBottom="1px solid rgb(218, 225, 231)">
          <Box flex={1} fontSize="12px" fontWeight={600}>
            Organizations
          </Box>

          {newOrgButton}
        </Flex>

        {contentElem}
      </Box>
    );
  }

  public render() {
    const filters = this.renderFilters();
    const orgs = this.renderOrgs();

    return (
      <PageContent>
        <Flex width="100%">
          <Box flex={1}>
            <ThemeProvider theme={ExplorerTheme}>{this.props.children}</ThemeProvider>
          </Box>

          {filters || orgs ? (
            <Box ml="20px" width="250px">
              {filters}
              {orgs}
            </Box>
          ) : null}
        </Flex>
      </PageContent>
    );
  }
}

export default inject('namespaceStore', 'orgService', 'userService')(observer(ExplorerWrapper));
