import React from 'react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

const downloads = [
  {
    icon: 'apple',
    name: 'macOS',
    url: 'https://github.com/stoplightio/desktop/releases/latest',
  },
  {
    icon: 'windows',
    name: 'Windows',
    url: 'https://github.com/stoplightio/desktop/releases/latest',
  },
  {
    icon: 'linux',
    name: 'Linux',
    url: 'https://github.com/stoplightio/desktop/releases/latest',
  },
];

function DesktopDownload(props) {
  const { items, appStore, electronStore, userService } = props;

  if (electronStore.enabled || appStore.desktopDownloadClosed || !userService.loggedIn) {
    return null;
  }

  return (
    <div className="DesktopDownload fixed pin-b pin-l pin-r p-6 mx-auto z-40">
      <div
        className="DesktopDownload-close"
        onClick={() => {
          appStore.hideDesktopDownload();
        }}
      >
        <Icon name="remove" size="small" />
      </div>
      <div className="DesktopDownload-title pb-3">Check Out Our Desktop App</div>
      <div className="text-center c-muted pb-6">
        Work with local files, run tests behind firewalls, and more!
      </div>

      <div className="DesktopDownload-items flex">
        {_.map(items || downloads, (item, i) => (
          <a
            key={i}
            className="DesktopDownload-item"
            href={item.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {item.icon ? (
              <div className="DesktopDownload-item-icon flex items-center">
                <Icon name={item.icon} size="large" />
              </div>
            ) : null}
            <div className="DesktopDownload-item-name">{item.name}</div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default inject('appStore', 'electronStore', 'userService')(observer(DesktopDownload));
