import * as EX from '@core/extension';

import { faFlask } from '@fortawesome/pro-solid-svg-icons/faFlask';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

export const COMMANDS = {
  addScenario: 'add:scenario',
  runCollection: 'run:collection',
  runScenario: 'run:scenario',
  runStep: 'run:step',
  openConductor: 'open:conductor',
};

export const MENUS = {
  actions: EX.EDITOR_MENUS.toolbar.primary.concat(['actions']),
  runActions: EX.EDITOR_MENUS.toolbar.primary.concat(['run-actions']),
  external: EX.EDITOR_MENUS.toolbar.secondary.concat(['external']),
};

export class ScenariosExtension extends EX.Spec implements EX.ISpec {
  public readonly id: string = '.scenarios';
  public readonly name: string = 'Scenarios';
  public readonly longName: string = 'Scenario Collection';
  public readonly icon: EX.IIcon = faFlask;

  public get supportedModes() {
    return [this.editor.supportedModes.design, this.editor.supportedModes.code];
  }

  public registerCommands = () => {
    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.addScenario,
        label: 'Add Scenario',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.runCollection,
        label: 'Run Collection',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.runScenario,
        label: 'Run Scenario',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.runStep,
        label: 'Run Step',
      })
    );

    this.commands.push(
      this.commandRegistry.registerCommand({
        id: COMMANDS.openConductor,
        label: 'Hosted Runner',
      })
    );
  };

  public registerMenus = ({ mode }: EX.IModeChangedEvent) => {
    switch (mode) {
      case EX.EDITOR_MODES.read:
        break;
      case EX.EDITOR_MODES.design:
      case EX.EDITOR_MODES.code:
        this._registerMenuActions();
        this._registerMenuRunActions();
        break;
      default:
    }

    this._registerMenuSecondaryActions();
  };

  private _registerMenuActions = () => {
    this.menus.push(this.menuRegistry.registerSubmenu(MENUS.actions));

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.actions, {
        id: COMMANDS.addScenario,
        commandId: COMMANDS.addScenario,
        label: 'Scenario',
        icon: faPlus,
      })
    );
  };

  private _registerMenuRunActions = () => {
    this.menus.push(
      this.menuRegistry.registerSubmenu(MENUS.runActions, {
        label: 'Run',
        icon: faPlay,
        color: EX.UI.colors.green,
      })
    );

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.runActions, {
        id: COMMANDS.runCollection,
        commandId: COMMANDS.runCollection,
        label: 'Collection',
        description: 'Run all scenarios in this collection',
        icon: faPlay,
      })
    );

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.runActions, {
        id: COMMANDS.runScenario,
        commandId: COMMANDS.runScenario,
        label: 'Scenario',
        description: 'Run this scenario',
        icon: faPlay,
      })
    );

    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.runActions, {
        id: COMMANDS.runStep,
        commandId: COMMANDS.runStep,
        label: 'Step',
        description: 'Run this step',
        icon: faPlay,
      })
    );
  };

  private _registerMenuSecondaryActions = () => {
    this.menus.push(
      this.menuRegistry.registerMenuAction(MENUS.external, {
        id: COMMANDS.openConductor,
        commandId: COMMANDS.openConductor,
        label: 'Hosted Runner',
      })
    );
  };
}
