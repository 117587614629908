import { observer } from 'mobx-react';
import * as React from 'react';

import {
  ICommand,
  ICommandRegistry,
  ICommandState,
  SYMBOLS as COMMAND_SYMBOLS,
} from '@core/command';
import { lazyInject } from '@core/ioc';

export interface ICommandContainer {
  commandId?: string;
  renderProp: (command?: ICommand, state?: ICommandState) => any;
}

/**
 * This is a simple higher order component that will reactively re-render
 * the children if the state of the given commandId changes (enabled -> disabled, etc)
 */
@observer
export default class CommandContainer extends React.Component<ICommandContainer> {
  @lazyInject(COMMAND_SYMBOLS.CommandRegistry)
  // @ts-ignore
  private _commandRegistry: ICommandRegistry;

  public render() {
    const { commandId, renderProp } = this.props;

    let command;
    if (commandId) {
      command = this._commandRegistry.getCommand(commandId);
    }

    // trigger a re-render by accessing computed properties
    let enabled;
    let executing;
    let visible;
    let toggled;
    if (command) {
      enabled = command.enabled;
      executing = command.executing;
      visible = command.visible;
      toggled = command.toggled;
    }

    return renderProp(command, { enabled, executing, visible, toggled });
  }
}
