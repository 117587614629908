import React from 'react';
import { inject, observer } from 'mobx-react';
import { Menu } from 'semantic-ui-react';

import { Link } from '@platform/utils/router';
import { alert } from '@platform/utils/alert';

import PageContent from '../../../components/PageContent';
import { getConfigVar } from '@platform/utils/config';

class UserProfileLayout extends React.Component {
  componentWillMount() {
    const { userService, routerStore } = this.props;

    if (!userService.isLoggedIn) {
      alert.warning('You must be logged in to view your profile settings');
      routerStore.push('/');
    }
  }

  render() {
    const { activeTab, children, className, userService } = this.props;

    return (
      <PageContent className={className}>
        <div>
          <div
            className="sticky"
            style={{
              top: 10,
              zIndex: 20,
              background: '#fff',
            }}
          >
            <Menu vertical>
              <Menu.Item fitted active={activeTab === 'profile'}>
                <Link href={`/profile`} className="p-4 block">
                  Profile
                </Link>
              </Menu.Item>

              {/* <Menu.Item fitted active={activeTab === 'notifications'}>
                <Link href={`/profile/notifications`} className="p-4 block">
                  Notifications
                </Link>
              </Menu.Item> */}

              {userService.showPersonalProjects && (
                <Menu.Item fitted active={activeTab === 'filters'}>
                  <Link href={`/profile/filters`} className="p-4 block">
                    Filters &amp; Tags
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item fitted active={activeTab === 'access-tokens'}>
                <Link href={`/profile/access-tokens`} className="p-4 block">
                  Access Tokens
                </Link>
              </Menu.Item>
            </Menu>
          </div>
        </div>

        <div className="flex-grow ml-6">{children}</div>
      </PageContent>
    );
  }
}

export default inject('userService', 'routerStore')(observer(UserProfileLayout));
