import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';

import ImageIcon from '@platform/components/ImageIcon';

import { colors, Layout } from '@core/ui';

import OrgCreate from '../OrgCreate';

class OnboardingOrgCreate extends React.Component {
  render() {
    const { header, orgService, routerStore, onboardingStore } = this.props;

    const existingOrg = _.get(orgService, 'records[0].data');

    if (!orgService.isFinding && existingOrg) {
      return (
        <Layout
          header={header}
          footer={{
            right: {
              actions: [
                {
                  children: 'Finish',
                  rightIcon: faArrowRight,
                  color: colors.accent,
                  onClick: e => {
                    // finish onboarding, route to org dashboard
                    routerStore.replace({ pathname: `/${existingOrg.path}` });
                  },
                },
              ],
            },
          }}
          content={
            <div className="bg-white">
              <div className="flex p-12">
                <div className="pr-10">
                  <div className="FormFileButton text-center">
                    <ImageIcon
                      imageUrl={existingOrg.avatar_url}
                      iconName={existingOrg.name || ''}
                      size="large"
                      isCircle
                    />
                  </div>
                </div>

                <div className="flex flex-1 flex-col">
                  <div className="text-xl text-darken-600 font-semibold">All Set!</div>

                  <div className="mt-6">
                    Somebody already added you to the{' '}
                    <span className="font-bold">{existingOrg.name}</span> organization, so you’re
                    good to go. You can always create a second organization later.
                  </div>
                </div>
              </div>
            </div>
          }
        />
      );
    }

    return (
      <OrgCreate
        header={header}
        redirect={false}
        onSuccess={result => {
          routerStore.replace({
            pathname: `/${result.path}`,
            search: `?modal=onboard&step=${onboardingStore.nextStep.id}`,
          });
        }}
      />
    );
  }
}

export default inject((stores, props) => {
  const { orgService, routerStore, onboardingStore } = stores;

  return {
    orgService,
    routerStore,
    onboardingStore,
  };
})(observer(OnboardingOrgCreate));
