import _ from 'lodash';
import { getConfigVar } from '../config';
import { buildExportUrl } from '../url';
import { safeStringify, safeParse } from '../json';

export const jsonReplace = (json = {}, original = undefined, replacement = undefined) => {
  let jsonString = safeStringify(json);
  jsonString = _.replace(jsonString, new RegExp(original, 'g'), replacement);
  return safeParse(jsonString);
};

export const buildExport = (filePath = '', projectId = '', branch = 'master') => {
  if (!projectId || !filePath) {
    return getConfigVar('SL_API_HOST');
  }

  return buildExportUrl({ projectId, branch, filePath });
};

export const commitAction = (action = undefined, file_path = undefined, content = {}) => {
  return {
    action,
    file_path,
    content: safeStringify(content),
  };
};

export const commitFile = (contents, replace) => {
  let file = typeof contents === 'function' ? contents() : contents;

  if (file && replace) {
    file = jsonReplace(file, '{related_ref}', replace);
  }

  return file;
};

export const generateInitialCommitActions = opts => {
  const { config, commonOas2, oas2, oas3, scenarios, hub, prism, lint } = opts;

  const files = {};

  if (commonOas2 && !getConfigVar('SL_DISABLE_NEW_PROJECT_FILES')) {
    files['common.oas2.yml'] = commitFile(commonOas2);
  }

  if (oas2 && !getConfigVar('SL_DISABLE_NEW_PROJECT_FILES')) {
    files['main.oas2.yml'] = commitFile(oas2, './common.oas2.yml');
  }

  if (oas3 && !getConfigVar('SL_DISABLE_NEW_PROJECT_FILES')) {
    files['petstore.oas3.yml'] = commitFile(oas3);
  }

  if (hub && !getConfigVar('SL_DISABLE_NEW_PROJECT_FILES')) {
    files['main.hub.yml'] = commitFile(hub, './main.oas2.yml');
    files['theme.css'] = '';
  }

  if (scenarios && !getConfigVar('SL_DISABLE_NEW_PROJECT_FILES')) {
    files['tests.scenarios.yml'] = commitFile(scenarios, './main.oas2.yml');
  }

  if (prism && !getConfigVar('SL_DISABLE_NEW_PROJECT_FILES')) {
    files['mocker.prism.yml'] = commitFile(prism, './main.oas2.yml');
  }

  if (lint) {
    files['lint.yml'] = commitFile(lint);
  }

  const actions = [];
  _.forEach(files, (file, name) => {
    if (!_.isEmpty(file)) {
      actions.push(commitAction('create', name, file));
    }
  });

  const configContents = commitFile(config);
  if (!_.isEmpty(configContents)) {
    actions.push(commitAction('update', '.stoplight.yml', configContents));
  }

  return actions;
};
