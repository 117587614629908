import React from 'react';
import _ from 'lodash';
import { Button, Message, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { alert } from '@platform/utils/alert';
import { isStandardAuthDisabled } from '@platform/utils/config';

import ErrorMessage from '../ErrorMessage';
import FormInput from '../FormInput';
import AccountFormNav from '../AccountFormNav';
import AccountSSO from '../AccountSSO';

function AccountResetPassword(props) {
  const { token, ui = {}, updateUi, clearUi, userService, routerStore, title } = props;

  if (isStandardAuthDisabled()) {
    return <AccountSSO />;
  }

  return (
    <div className="AccountForm">
      <AccountFormNav active="reset-pass" />

      <div className="AccountForm-inner pt-6">
        {ui.error ? (
          <ErrorMessage
            error={ui.error}
            className="mb-3"
            onDismiss={() => {
              updateUi('set', 'error', null);
            }}
          />
        ) : null}

        {!_.isEmpty(ui.successMessage) ? (
          <Message content={ui.successMessage} success />
        ) : (
          <form
            onSubmit={e => {
              e.preventDefault();

              if (_.isEmpty(ui.password)) {
                updateUi('set', 'error', 'Please add a new password.');
                updateUi('set', 'working', false);
                updateUi('set', 'successMessage', null);
                return;
              }

              if (!_.isEqual(ui.password, ui.confirmPassword)) {
                updateUi('set', 'error', 'Passwords do not match.');
                updateUi('set', 'working', false);
                updateUi('set', 'successMessage', null);
                return;
              }

              updateUi('set', 'error', null);
              updateUi('set', 'working', true);

              userService.resetPassword(
                {
                  password: ui.password,
                  password_confirmation: ui.confirmPassword,
                  reset_password_token: token,
                },
                result => {
                  alert.success('Password updated. You can now login!');
                  setTimeout(() => {
                    localStorage.setItem('reset-password', true);
                    routerStore.push({ pathname: '/login' });
                    clearUi();
                  }, 1000);
                },
                error => {
                  updateUi('set', 'successMessage', null);
                  updateUi('set', 'working', false);
                  updateUi('set', 'error', error);
                }
              );
            }}
          >
            <div className="AccountForm-input-group">
              <FormInput
                input={{
                  type: 'password',
                  value: ui.password || '',
                  onChange(e, { value }) {
                    updateUi('set', 'password', value);
                  },
                }}
                disabled={ui.working}
                placeholder="New Password"
                fluid
              />

              <FormInput
                input={{
                  type: 'password',
                  value: ui.confirmPassword || '',
                  onChange(e, { value }) {
                    updateUi('set', 'confirmPassword', value);
                  },
                }}
                disabled={ui.working}
                placeholder="Confirm New Password"
                fluid
              />
            </div>

            <div className="pt-3">
              <Button
                className="AccountForm-cta"
                disabled={ui.working}
                loading={ui.working}
                type="submit"
                secondary
                fluid
              >
                {title || 'Reset Password'} <Icon name="key" />
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default inject(({ appStore, userService, routerStore }) => ({
  userService,
  routerStore,
  ...appStore.injectUi('AccountResetPassword'),
}))(observer(AccountResetPassword));
