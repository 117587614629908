import _ from 'lodash';
import mingo from 'mingo';
import { computed, action, observable, reaction, flow } from 'mobx';
import {
  find as findInTree,
  getNodeAtPath,
  getFlatDataFromTree,
} from '@stoplight/react-sortable-tree';

import { URI } from '@core/uri';
import { MODES } from '@core/editor/store';

import {
  derefNav,
  buildCrumbs,
  searchResults,
  getInferredSubpageType,
  getUrlPathFromJsonPath,
} from '@platform/format-hubs/utils';
import {
  sidebarUtils,
  buildSubpage,
  subpageOptions,
  getSubpageNeighborPaths,
} from '@platform/format-hubs/utils/editor';

import { COMMANDS as HUB_COMMANDS } from '@core/spec-hub';
import { toTree } from '@core/spec-hub/transformers/to-tree';
import { fromTree } from '@core/spec-hub/transformers/from-tree';

import { startsWith } from '@platform/utils/objects';
import { alert as sAlert } from '@platform/utils/alert';
import { filterMap, findFileType } from '@platform/utils/projects';
import { uniqueSlugify, renameObjectKey } from '@platform/utils/general';
import { safeStringify } from '@platform/utils/json';

import { hubExtension } from './coreEditor';
import entityEditorStore, { EntityEditor } from './entityEditorStore';

export class HubEditor extends EntityEditor {
  _extension = hubExtension;

  // This path will be used by goToEditor when a path isn't provided
  defaultEditPath = '/pages/~1';

  @observable
  copiedBlock;

  @observable
  previewMode = false;

  /**
   * holds the computed structure for rendering the tree view
   *
   * [
   *   {
   *     title: '',
   *     subtitle: '',
   *     expanded: false,
   *     children: [],
   *   }
   * ];
   */
  @observable.ref
  treeData = {
    tree: [],
  };

  // no observable here, this is changed in a render function
  focusTreeIndex;

  @observable
  treeSearchQuery = '';

  // TODO: we need a generic, solid way to asyncronously search project files
  // this is not it...
  @observable
  fileSearchQuery = '';

  @observable
  fileSearchRefType = '';

  @observable.ref
  fileSearchResults = [];

  @observable.ref
  treeSearchResult = {
    matches: [],
    searchFoundCount: undefined,
    searchFocusIndex: undefined,
  };

  activate() {
    super.activate();

    if (!this.rootStore.isClient) return;

    this.registerListeners();
    this.registerCommands();
    this.registerReactions();

    if (this.isEditing) {
      return this.goToEditor();
    }

    return this.goToViewer();
  }

  registerListeners = () => {
    this._disposables.push(
      this._editor.onDidChangeMode(({ mode }) => {
        if (!mode) return;

        if (mode.id === MODES.read.id && !this.isViewing) {
          this.goToViewer();
        } else if (mode.id !== MODES.read.id && !this.isEditing) {
          this.goToEditor();
        }
      })
    );
  };

  registerCommands = () => {
    this._disposables.push(
      this._commandRegistry.registerHandler(HUB_COMMANDS.addPage, {
        execute: () => {
          const newPath = this.addPage();
          if (newPath) {
            sAlert.success('Page created.');
          }
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(HUB_COMMANDS.addSubpage, {
        execute: () => {
          const newPath = this.addTreeSubpage({ node: { jsonPath: this.currentPath } });
          if (newPath) {
            sAlert.success('Subpage created.');
          }
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(HUB_COMMANDS.showPageSettings, {
        execute: this.showPageSettings,
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(HUB_COMMANDS.showTheme, {
        execute: this.showTheme,
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(HUB_COMMANDS.showHubSettings, {
        execute: () => {
          this.rootStore.stores.appStore.openModal('hub-settings');
        },
      })
    );
  };

  @action
  registerReactions = () => {
    this._disposables.push({
      dispose: reaction(
        () => ({
          query: this.fileSearchQuery,
          refType: this.fileSearchRefType,
        }),
        this.computeFileSearchResults,
        {
          name: 'fileSearchQueryChanged',
          delay: 100,
        }
      ),
    });

    this._disposables.push({
      dispose: reaction(
        () => ({
          parsed: this.parsed,
        }),
        this.computeTreeData,
        {
          name: 'computeTreeData',
          delay: 500,
        }
      ),
    });
  };

  @computed
  get hubViewerSpec() {
    return this.dereferencedParsed || this.parsed || {};
  }

  showTheme = () => {
    this.goToViewer(null, { theme: 'general' });
  };

  showPageSettings = () => {
    this.rootStore.stores.appStore.openModal('toc');
  };

  redirect = page => {
    const path = this.buildEditPath(page);
    this.rootStore.stores.routerStore.push(path);
  };

  // gets search results based on hub routeData rules
  getResults = searchQuery => {
    const data = this.parsed || {};
    return searchResults({ data, expression: searchQuery });
  };

  @computed
  get hasHeader() {
    return !(
      _.isEmpty(this.nav.left) &&
      _.isEmpty(this.nav.right) &&
      !this.parsed.title &&
      !this.parsed.logo
    );
  }

  @computed
  get config() {
    return _.get(this.parsed, 'config', {});
  }

  @computed
  get nav() {
    return derefNav(this.parsed);
  }

  @computed
  get theme() {
    return _.get(this.parsed, 'theme');
  }

  @computed
  get crumbs() {
    return buildCrumbs({
      parsedPath: this.currentPath,
      hub: this.parsed,
      buildPath: this.buildEditPath,
    });
  }

  @computed
  get viewerJsonPath() {
    const { data = {} } = this.activePage;
    const currentPagePath = _.get(data, 'path', '');
    const currentPageRef = _.get(data, 'data.$ref');

    let childPaths = '';
    let currentSubpagePath = '';

    if (!currentPageRef) {
      childPaths = getUrlPathFromJsonPath(this.parsed, _.dropRight(this.currentPath, 1));
      const subpage = _.get(this.activeSubpage, 'data', {});

      // only add the subpage if it's routable (has blocks or a $ref)
      currentSubpagePath = _.get(subpage, 'route.path', '');
    }

    const path = _.replace(
      _.trimEnd(`${currentPagePath}${childPaths}${currentSubpagePath}`, '/'),
      '//',
      '/'
    );

    return path;
  }

  @computed
  get flattenedNav() {
    let flattenedNav = [];

    const nav = _.get(this.parsed, 'header.nav');
    _.forOwn(nav, (items, key) => {
      flattenedNav = flattenedNav.concat(items);
    });

    return flattenedNav;
  }

  // override the normal implementation
  @computed
  get currentContentTabId() {
    return this._currentContentTabId || 'read';
  }

  @computed
  get pages() {
    return _.get(this.parsed, 'pages', {});
  }

  @computed
  get sortedPages() {
    const pages = this.pages;
    const ordered = _.sortBy(_.keys(pages), _.size);

    return _.reduce(
      ordered,
      (acc, key) => {
        acc[key] = pages[key];
        return acc;
      },
      {}
    );
  }

  @computed
  get activePage() {
    const pagePath = this.currentPath.slice(0, 2);
    let page = _.get(this.parsed, pagePath);
    let data;

    if (page) {
      data = {
        path: pagePath[1],
        ...page,
      };
    }

    return {
      data,
      parsedPath: pagePath,
    };
  }

  @computed
  get activeSubpage() {
    const page = _.get(this.activePage, 'data', {});
    const path = this.currentPath;
    const subpagePath = path.slice(2);

    const data = _.get(page, subpagePath);

    return {
      data,
      parsedPath: subpagePath,
      inferredType: getInferredSubpageType(data),
    };
  }

  @computed
  get currentUrlPrefix() {
    const basePath = _.get(this.activePage, 'data.path', '');
    const parsedPath = this.currentPath;

    let prefix = basePath + getUrlPathFromJsonPath(this.parsed, _.dropRight(parsedPath, 3));
    if (prefix === '/') {
      prefix = '';
    }

    return prefix.replace('//', '/');
  }

  /*
    current computed url prefix + current page path
    useful for things like creating appropriate links in markdown
  */
  @computed
  get currentRelativePath() {
    const subpage = this.activeSubpage.data;
    return (this.currentUrlPrefix + _.get(subpage, 'route.path', '')).replace('//', '/');
  }

  @action
  addPage(data = {}, path = ['pages'], callback, { noRedirect = false } = {}) {
    const page = {
      title: data.title || '',
      data: {},
    };

    let input = page.title;
    const existing = _.map(this.rootPaths, p => _.trimStart(p, '/'));
    const pagePath = data.pagePath || `/new-page-${uniqueSlugify({ input, existing })}`;
    const parsedPath = path.concat(pagePath);

    this.updateParsed('set', parsedPath, page, { immediate: true });

    if (callback) {
      callback(page, pagePath);
    } else if (!noRedirect) {
      this.goToPath(parsedPath);
    }

    return parsedPath;
  }

  @action
  addSubpage(data = {}, path, callback, { noRedirect = false } = {}) {
    let updatePath = path;
    if (_.isEmpty(path)) {
      updatePath = this.activePage.parsedPath.concat(['data', 'children']);
    } else if (_.last(path) !== 'children') {
      updatePath = path.concat(['data', 'children']);
    }

    const parentRefPath = path.concat(['data', '$ref']);
    const parentRef = _.get(this.parsed, parentRefPath);
    let c = true;

    if (parentRef) {
      c = confirm(
        'This page is currently referencing another file. Adding a subpage will remove this reference. Continue?'
      );
    }

    if (!c) {
      return;
    }

    const existingPaths = _.compact(
      _.map(_.get(this.parsed, updatePath), p => {
        return _.trimStart(_.get(p, 'route.path'), '/');
      })
    );

    const subpage = buildSubpage(data, existingPaths);

    const index = 0;
    const newPath = updatePath.concat([index]);
    this.updateParsed('splice', updatePath, subpage, { splice: { index } });

    // remove any old $ref
    this.updateParsed('unset', parentRefPath);

    if (callback) {
      callback(subpage, index);
    } else if (!noRedirect) {
      this.goToPath(newPath);
    }

    return newPath;
  }

  @action
  removeHeader(path) {
    _.forEach(this.nav, (items, loc) => {
      _.forEach(items, (item, index) => {
        if (item.path === _.join(_.tail(path))) {
          this.updateParsed('pull', ['header', 'nav', loc], index);
        }
      });
    });
  }

  // slight customization for hubs, to stay on the same page when possible
  @action
  removePath(path, opts = {}) {
    super.removePath(
      path,
      () => {
        // if we just removed the path we are looking at
        // remove header nav item for this path if existing
        this.removeHeader(path);
        if (startsWith(this.currentPath, path)) {
          // if we are on a page, route to the page root, else route to the hub root
          if (this.currentPath[0] === 'pages' && this.currentPath[1]) {
            this.goToPath(this.currentPath.slice(0, 2));
          } else {
            this.goToPath(null);
          }
        }
      },
      opts
    );
  }

  @observable.ref
  copiedBlock;

  @action
  setCopiedBlock(block, blockPath) {
    this.copiedBlock = {
      block,
      blockPath,
    };
  }

  @action
  cancelCopiedBlock() {
    const { block, blockPath } = this.copiedBlock || {};

    if (blockPath) {
      const blockIndex = _.last(blockPath);
      this.updateParsed('splice', _.dropRight(blockPath, 1), block, {
        splice: { index: blockIndex },
      });
    }

    this.setCopiedBlock();
  }

  @computed
  get hasSidebar() {
    // (CL) TODO: Return false to prevent rendering both EntityEditor and HubEditor sidebars
    // return _.get(this.activePage, 'data.data.children', []).length > 0;
    return false;
  }

  @computed
  get hasPageSidebar() {
    // (CL) TODO: This is the same as above but only used in the HubEditor
    return _.get(this.activePage, 'data.data.children', []).length > 0;
  }

  @computed
  get computedSidebarTree() {
    // the hub viewer store will take care of its own sidebar
    if (this.isViewing || !this.hasPageSidebar) return [];

    return sidebarUtils.computeTree(this.debouncedParsed, {
      editor: this,
      location,
      currentPath: this.currentPath,
      meta: this.sidebarMeta,
      routeFunc: this.createSidebarOptions,
    });
  }

  // TODO: remove this if possible
  @action
  getUniqueRootPath(input = '', ignorePaths = []) {
    const rootPaths = _.difference(this.rootPaths, [].concat(ignorePaths));
    const existingPaths = _.map(rootPaths, rootPath => _.trimStart(rootPath, '/'));

    const newPath = `/${uniqueSlugify({
      input: _.trimStart(input, '/'),
      existing: existingPaths,
    })}`;

    return newPath;
  }

  @computed
  get activePagePath() {
    return _.get(this.activePage, 'data.path', '/');
  }

  @action
  updateActivePagePath(value) {
    const { data, parsedPath } = this.activePage;
    if (!data) return;

    const { path } = data;

    const updatePath = `/${_.trimStart(value, '/')}`;

    if (updatePath === path) return;

    this.updateParsed('move', parsedPath, ['pages', updatePath]);

    const nav = _.get(this.parsed, 'header.nav');
    _.forOwn(nav, (navItems, key) => {
      _.forEach(navItems, (navItem, index) => {
        if (navItem && navItem.path === path) {
          this.updateParsed('set', ['header', 'nav', key, index, 'path'], updatePath);
        }
      });
    });

    this.goToPath(['pages', updatePath]);
  }

  @action
  getUniqueSubpageRoute(input) {
    const existingPaths = _.map(
      getSubpageNeighborPaths({ data: this.parsed, parsedPath: this.currentPath }),
      path => _.trimStart(path, '/')
    );

    const newPath = `/${uniqueSlugify({
      input: _.trimStart(input, '/'),
      existing: existingPaths,
    })}`;

    return newPath;
  }

  @action
  updateActiveSubpageRoute(newPath) {
    const { data } = this.activeSubpage;

    if (newPath === _.get(data, 'route.path')) return;

    this.updateCurrentParsed('set', ['route', 'path'], newPath);
  }

  @action
  removePage({ pageJsonPath = [] }, { force } = {}) {
    let c = true;

    if (pageJsonPath[1] === '/') {
      alert('The root `/` page cannot be removed');
      return;
    }

    if (!force) {
      c = window.confirm('Are you sure? Continuing will remove this page AND all of its subpages.');
    }

    if (!c) return;

    let newNav = {};
    const nav = _.get(this.parsed, ['header', 'nav'], {});
    _.forOwn(nav, (navItems, key) => {
      newNav[key] = _.reject(navItems, { path: _.last(pageJsonPath) });
    });

    this.updateParsed('unset', pageJsonPath);
    this.updateParsed('set', ['header', 'nav'], newNav);
    this.goToPath([]);
  }

  @action
  removeSubpage({ subpageJsonPath = [] }) {
    let c = window.confirm(
      'Are you sure? Continuing will remove this subpage AND all if its subpages.'
    );
    if (c) {
      this.updateParsed('unset', subpageJsonPath);
      this.goToPath(subpageJsonPath.slice(0, 2));
    }
  }

  @action
  clearPageRef({ pageJsonPath = [] }) {
    this.updateParsed('unset', pageJsonPath.concat(['data', '$ref']));
  }

  createSidebarOptions = () => {
    return {
      linkable: true,
      parseChildrenBasePath: ['data'],
      parseChildren: ['children'],
      parseChildrenOnly: true,
      tokenFactory: (key, data = {}) => {
        return {
          name: _.get(data, ['config', 'sidebar', 'token']),
          icon: _.get(data, ['config', 'sidebar', 'icon']),
        };
      },
      nameFactory: (key, data) => {
        return (data && _.trim(data.title)) || `NEW Subpage`;
      },
      metaFactory: (key, data = {}) => {
        const meta = [];

        if (_.get(data, ['data', '$ref'])) {
          meta.push({
            id: 't',
            icon: 'plug',
          });
        } else {
          const subpageType = getInferredSubpageType(data);
          const subpageOpts = subpageOptions[subpageType] || {};
          if (subpageOpts.icon) {
            meta.push({
              id: 't',
              icon: subpageOpts.icon,
            });
          }
        }

        return meta;
      },
      actionsFactory: (key, data = {}, opts) => {
        const actions = [];

        const subpageType = getInferredSubpageType(data);
        const subpageOpts = subpageOptions[subpageType] || {};

        // Cannot have children: dividers, external links, refs
        if (!subpageOpts.noChildren && !_.get(data, ['data', '$ref'])) {
          const path = [...opts.treePath, key, 'data', 'children'];
          actions.push({
            id: 'add',
            icon: 'plus',
            type: 'action',
            tip: _.get(data, 'data.children')
              ? 'Add a subpage to this group.'
              : 'Turn this page into a group, and add a subpage.',
            onClick: () => {
              opts.editor.addSubpage({ type: 'page' }, path);
            },
          });
        }

        return actions;
      },
    };
  };

  @computed
  get rootPaths() {
    return _.keys(this.sortedPages);
  }

  /**
   * Page helpers. NOTE that page is expected to be of the form:
   *
   * id: string
   * jsonPath: string[]
   * viewPath: string[]
   * type: string
   * title: string
   * data: object
   * ref?: string
   */

  // this looks at activePage and activeSubpage and returns the appropriate structure
  // we prob don't need activePage and activeSubpage in the future
  @computed
  get currentPage() {
    let type = 'page';
    let activePage = this.activePage || {};
    if (this.activeSubpage && this.activeSubpage.data) {
      type = 'subpage';
      activePage = this.activeSubpage;
    }

    const page = {
      type,
      id: _.join(this.currentPath, '.'),
      jsonPath: this.currentPath,
      viewPath: _.trim(this.viewerJsonPath, '/').split('/'),
      title: _.get(activePage, 'data.title'),
      data: _.get(activePage, 'data.data'),
      config: _.get(activePage, 'data.config'),
      ref: _.get(activePage, 'data.data.$ref'),
    };

    if (page.ref) {
      page.refType = findFileType({ filePath: page.ref }) || 'modeling';
    }

    return page;
  }

  updatePageRef = ({ pageJsonPath, page, value }) => {
    if (_.isEmpty(value)) return value;

    if (pageJsonPath) {
      page = _.get(this.parsed, pageJsonPath);
    } else {
      pageJsonPath = page && page.jsonPath;
    }

    if (!page) return value;

    const uri = URI.parse(value);

    // TODO: this is temporary until we support relative file refs
    if (!uri.isAbsolute() && !_.startsWith(value, './')) {
      value = `./${value}`;
    }

    let confirmation = true;
    const data = page.data || {};

    if (!_.isEmpty(data.blocks)) {
      confirmation = confirm(
        'Changing this page to a ref will remove its current content. Continue?'
      );
    } else if (!_.isEmpty(data.children)) {
      confirmation = confirm(
        'Changing this page to a ref will remove its current subpages. Continue?'
      );
    }

    if (!confirmation) return false;

    this.updateParsed('set', pageJsonPath.concat(['data']), { $ref: value || '' });

    return value;
  };

  updatePageRoute = ({ page, value }) => {
    value = `/${_.trim(_.trim(value), '/')}`;

    if (!page) return;

    // TODO: call updatePageRoute/updateSubpageRoute functions (or maybe just one?) that also takes care of stuff like re-wiring header links
    // length 2 means page ie ['pages', '/foo']
    if (page.jsonPath.length === 2) {
      if (page.jsonPath[1] === value) {
        // no change
        return;
      }

      if (page.jsonPath[1] === '/' || page.jsonPath[1] === '') {
        alert('The root `/` page cannot be changed or removed.');
        return;
      }

      const existing = _.get(this.parsed, ['pages', value]);
      if (existing) {
        alert(
          `A page with the '${value}' route already exists. You must change the other page's route before you can set this one.`
        );

        return;
      }

      // preserve the page ordering
      const pages = renameObjectKey(this.parsed.pages, page.jsonPath[1], value);
      this.updateParsed('set', ['pages'], pages);
    } else {
      // subpage
      this.updateParsed('set', page.jsonPath.concat(['route', 'path']), value);
    }

    this.computeTreeData();

    return true;
  };

  /**
   * Tree Data Helpers
   */

  treeGetNodeKey = ({ treeIndex }) => treeIndex;

  @action
  computeTreeData = () => {
    const data = toTree(this.parsed, { prev: this.treeData });
    this.treeData = data;
  };

  @action
  updateTreeData = data => {
    this.treeData = {
      tree: data,
    };
  };

  moveTreeDataNode = opts => {
    const { node = {}, nextPath = [], treeData } = opts;

    try {
      const hub = fromTree({
        tree: treeData,
      });
      this.updateParsed('set', ['pages'], hub.pages);
      this.computeTreeData();

      const result = getNodeAtPath({
        treeData: this.treeData.tree,
        path: nextPath,
        getNodeKey: this.treeGetNodeKey,
      });

      if (result && result.node) {
        if (startsWith(this.currentPath, node.jsonPath)) {
          this.goToPath(result.node.jsonPath, { replace: true });
        }
      }
    } catch (e) {
      console.error('Error moving tree node', e);
      alert(
        'There was an issue performing the tree move! More info in the developer console. Please reach out to us if this persists.'
      );
    }
  };

  buildTreeSearcher = ({ findOne, searchText } = {}) => {
    let found = false;

    return opts => {
      // only need to find one node, don't do search logic on all of them...
      if (findOne && found) return false;

      let { searchQuery } = opts;

      found = false;
      if (searchQuery) {
        searchQuery = searchQuery.toLowerCase();

        found = opts.node.jsonPath.join('.') === searchQuery;

        if (!found && opts.node.title) {
          found = _.includes(opts.node.title.toLowerCase(), searchQuery);
        }

        if (!found) {
          const ref = _.get(opts.node, 'data.$ref');
          found = ref && _.includes(ref.toLowerCase(), searchQuery);
        }

        if (!found && opts.node.viewPath) {
          found = _.includes(opts.node.viewPath.join('/').toLowerCase(), searchQuery);
        }
      }

      return found;
    };
  };

  searchTree = ({ path, findOne }) => {
    const pathKey = path.join('.');
    const result = findInTree({
      getNodeKey: this.treeGetNodeKey,
      treeData: this.treeData.tree,
      expandAllMatchPaths: true,
      searchQuery: pathKey,
      searchMethod: this.buildTreeSearcher({ findOne }),
    });

    this.updateTreeSearchResult({
      matches: result.matches,
      searchFoundCount: result.matches.length,
      searchFocusIndex:
        result.matches.length > 0
          ? this.treeSearchResult.searchFocusIndex || 0 % result.matches.length
          : 0,
    });

    this.updateTreeData(result.treeData);
  };

  @action
  expandTreePath = ({ path, focus }) => {
    const pathKey = path.join('.');
    const result = findInTree({
      getNodeKey: this.treeGetNodeKey,
      treeData: this.treeData.tree,
      expandAllMatchPaths: true,
      searchQuery: pathKey,
      searchMethod: this.buildTreeSearcher({ findOne: true }),
    });

    if (focus) {
      this.focusTreeIndex = result.matches.length > 0 ? result.matches[0].treeIndex : undefined;
    }

    this.updateTreeData(result.treeData);
  };

  addTreePage = () => {
    const newPath = this.addPage();

    if (newPath) {
      this.computeTreeData();
      this.expandTreePath({ path: newPath, focus: true });
    }

    return newPath;
  };

  addTreeSubpage = ({ node }) => {
    const newPath = this.addSubpage(undefined, node.jsonPath);

    if (newPath) {
      this.computeTreeData();
      this.expandTreePath({ path: newPath, focus: true });
    }

    return newPath;
  };

  removeTreePage = ({ node }) => {
    this.removePath(node.jsonPath, {
      confirmMessage: `Are you sure you want to remove the page at '/${node.viewPath.join(
        '/'
      )}' and all of its subpages? `,
    });
    this.computeTreeData();
  };

  @action
  updateTreeSearchResult = result => {
    this.treeSearchResult = result;
  };

  @action
  updateTreeSearchQuery = value => {
    this.treeSearchQuery = value;
  };

  @action
  updateFileSearchQuery = ({ value, refType }) => {
    if (typeof value !== 'undefined') {
      this.fileSearchQuery = value;
    }

    if (typeof refType !== 'undefined') {
      this.fileSearchRefType = refType;
    }
  };

  @action
  computeFileSearchResults = ({ query, refType }) => {
    const currentProject = _.get(this.rootStore, 'stores.projectStore.current') || [];

    const fileType = filterMap[refType];

    let results = currentProject.files;
    if (fileType) {
      results = currentProject.filteredFiles[refType];
    }

    if (results && query) {
      const cursor = mingo.find(results, {
        'record.data.path': { $regex: `.*${query}.*`, $options: 'i' },
      });

      results = cursor.all();
    }

    this.fileSearchResults = results;

    return this.fileSearchResults;
  };

  /**
   * File searching
   */
}

export default class HubEditorStore extends entityEditorStore {
  editorClass = HubEditor;
}
