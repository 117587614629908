import * as _ from 'lodash';
import { action, computed, observable } from 'mobx';

import { provideSingleton } from '@core/ioc';

import { IFlatTreeChild, IManager, IStore, ITreeChild, SYMBOLS } from './types';
import { getFlatDataFromTree, getTreeFromFlatData } from './utils';

class UITreeStore implements IStore {
  public readonly id: string;
  public readonly scaffoldBlockPxWidth = 10;
  public readonly rowHeight = 30;
  public readonly slideRegionSize = 50;
  public readonly rootKey = '';

  @observable
  private _flatData?: IFlatTreeChild[];

  @observable
  private _treeData?: ITreeChild[];

  constructor(id: string) {
    this.id = id;
  }

  @action.bound
  public activate(data: any) {
    this.setFlatData(data);
    this.updateTreeData(data);
  }

  @computed
  get flatData() {
    return this._flatData;
  }

  @computed
  get treeData() {
    return this._treeData;
  }

  @action
  public setFlatData = (flatData: IFlatTreeChild[]) => {
    this._flatData = flatData;

    this.updateTreeData(this._flatData);
  };

  @action
  public setTreeData = (treeData: any) => {
    this._treeData = treeData;
  };

  @action
  public updateTreeData = (flatData: IFlatTreeChild[]) => {
    this._treeData = getTreeFromFlatData({
      flatData,
      getKey: (node: { id: string; path: string }) => {
        return node.id || node.path;
      },
      getParentKey: ({ id, path }: { id: string; path: string }) => {
        const parentKey = _.dropRight(_.split(path, '/')).join('/');

        if (id) {
          const parent = _.find(flatData, { path: parentKey });
          if (parent) {
            return parent.id;
          }
        }

        return parentKey;
      },
      rootKey: this.rootKey,
    });
  };

  public getFlatDataFromTree = (treeData: any) => {
    return getFlatDataFromTree({
      treeData,
      ignoreCollapsed: false,
      getNodeKey: ({ node }: { node: any }) => {
        return node.id || node.path;
      },
    }).map(({ node }: { node: any }) => {
      return { id: node.id, path: node.path };
    });
  };
}

@provideSingleton(SYMBOLS.Manager)
export class UITreeStoreManager implements IManager {
  @observable
  public stores: any = {};

  @action.bound
  public getById(id: string) {
    let store = this.stores[id];

    if (!store) {
      store = new UITreeStore(id);
      this.stores[id] = store;
    }

    return store;
  }
}
