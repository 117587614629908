import React from 'react';
import { Button, Segment, Header } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import ColorPicker from '../ColorPicker';
import FormInputList from '../FormInputList';

class Filters extends React.Component {
  componentWillReceiveProps(nextProps) {
    this.updateUiTags(nextProps);
  }

  tags(props) {
    const { tags, tagType } = props || this.props;
    return _.filter(tags, t => {
      if (tagType) {
        return t.type === tagType;
      }

      return !t.type;
    });
  }

  updateUiTags = props => {
    const tags = this.tags(props);
    const { ui, updateUi } = props;

    if (!ui.tags && !_.isEmpty(tags)) {
      updateUi('set', 'tags', _.cloneDeep(tags));
    }
  };

  handleSave = e => {
    e.preventDefault();

    const { ui, updateUi, store, namespaceId, tags, tagType } = this.props;

    const uiTags = _.cloneDeep(ui.tags || []);
    const original = this.tags();
    const tagsChanged = !_.isEqual(uiTags, original);

    if (!tagsChanged) {
      return;
    }

    // start with just tags that are not the type we are working with
    let newTags = _.filter(_.cloneDeep(tags), t => {
      return (!tagType && t.type) || (tagType && t.type !== tagType);
    });

    // append the new tags (of this type) to the end
    for (const tag of uiTags) {
      if (tag.isNew) {
        delete tag.id;
        delete tag.isNew;
      }

      newTags.push(tag);
    }

    store
      .updateBulk(namespaceId, {
        tags: newTags,
      })
      .then(result => {
        updateUi(
          'set',
          'tags',
          _.cloneDeep(
            this.tags({
              tags: result,
              tagType,
            })
          )
        );
      });
  };

  render() {
    const { ui, updateUi, store, headerName, headerDescription, tagType } = this.props;

    const tags = ui.tags || [];
    const original = this.tags();
    const tagsChanged = !_.isEqual(tags, original);

    return (
      <div className="Filters mb-6">
        <Segment attached="bottom" loading={store.isSaving || store.isLoading}>
          <Header
            as="h3"
            className="mb-2"
            content={headerName}
            subheader={headerDescription}
            dividing
          />

          <FormInputList
            fields={tags}
            preserveProp
            properties={[
              {
                type: 'custom',
                name: 'primaryColor',
                skipDivider: true,
                factory: (field, index, key) => {
                  const primaryColor = field.primary_color || '#999';

                  return (
                    <ColorPicker
                      key={key}
                      className="mr-2"
                      color={primaryColor}
                      onChangeComplete={color => {
                        updateUi('set', ['tags', index, 'primary_color'], color.hex);
                      }}
                    />
                  );
                },
              },
              {
                type: 'input',
                name: 'name',
                placeholder: 'name',
                skipDivider: true,
              },
              {
                type: 'input',
                name: 'description',
                placeholder: 'brief, optional description',
                skipDivider: true,
              },
            ]}
            handleUpdate={(t, p, v) => {
              if (t === 'push') {
                v.id = new Date().getTime(); // assign a tmp id
                v.type = tagType || '';
                v.isNew = true;
              }

              updateUi(t, ['tags'].concat(p), v);
            }}
          />

          <div className="mt-6">
            <Button
              content="Save"
              disabled={store.isSaving || !tagsChanged}
              primary={tagsChanged}
              onClick={this.handleSave}
            />

            <Button
              basic
              content="Reset"
              disabled={!tagsChanged}
              onClick={e => {
                e.preventDefault();
                updateUi('set', 'tags', _.cloneDeep(original));
              }}
            />
          </div>
        </Segment>
      </div>
    );
  }
}

export default inject(({ routerStore, appStore }, { namespaceId, tagType }) => ({
  routerStore,
  ...appStore.injectUi(`Tags-${namespaceId}-${tagType}`),
}))(observer(Filters));
