import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

const Component = props => {
  const { name, icon, onChange, className = '', buttonProps = {} } = props;

  return (
    <Button as="span" className={`FormFileButton ${className}`} {...buttonProps}>
      {icon ? <Icon name={icon} /> : null}

      {name || 'Choose File Location'}

      <input
        type="file"
        onChange={onChange}
        onClick={event => {
          event.target.value = null;
        }}
      />
    </Button>
  );
};

export default Component;
