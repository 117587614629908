import * as Hub from '../types';

export interface IAccumulators {
  viewPath: string[];
  jsonPath: Array<string | number>;
}

export interface IToTreeOpts {
  prev?: Hub.ITree;
}

export interface ITransformOpts {
  prev?: Hub.ITreeChild;
}

const cleanPath = (path: string) => {
  path = (path || '').trim();

  if (path[0] === '/') {
    path = path.slice(1);
  }

  if (path[path.length - 1] === '/') {
    path = path.slice(0, -1);
  }

  return path;
};

const transformPage = (
  page: Hub.IPage | Hub.ISubpage,
  opts: ITransformOpts,
  acc: IAccumulators
): Hub.ITreeChild => {
  const { title, data = {}, config } = page;
  const child: Hub.ITreeChild = {
    title,
    viewPath: acc.viewPath,
    jsonPath: acc.jsonPath,
    data,
    config,
    children:
      data && data.children
        ? transformSubpages(data.children, opts, {
            viewPath: acc.viewPath,
            jsonPath: acc.jsonPath.concat(['data', 'children']),
          })
        : [],
  };

  if (opts.prev) {
    child.expanded = opts.prev.expanded;
  }

  return child;
};

const transformSubpages = (
  subpages: Hub.ISubpage[],
  opts: ITransformOpts,
  acc: IAccumulators
): Hub.ITreeChild[] => {
  const processed: Hub.ITreeChild[] = [];

  for (const i in subpages) {
    if (!subpages[i]) continue;

    const subpage = subpages[i];

    processed.push(
      transformPage(
        subpages[i],
        {
          prev: opts.prev && opts.prev.children ? opts.prev.children[parseInt(i)] : undefined,
        },
        {
          viewPath:
            subpage.route && subpage.route
              ? acc.viewPath.concat([cleanPath(subpage.route.path)])
              : acc.viewPath,
          jsonPath: acc.jsonPath.concat([parseInt(i)]),
        }
      )
    );
  }

  return processed;
};

export const toTree = (root: Hub.IRoot, opts: IToTreeOpts = {}): Hub.ITree => {
  const pages = root.pages;

  let index = 0;

  const processed: Hub.ITreeChild[] = [];
  for (const i in pages) {
    if (!pages[i]) continue;

    processed.push(
      transformPage(
        pages[i],
        {
          prev: opts.prev ? opts.prev.tree[index] : undefined,
        },
        {
          viewPath: [cleanPath(i)],
          jsonPath: ['pages', i],
        }
      )
    );

    index++;
  }

  return {
    tree: processed,
  };
};
