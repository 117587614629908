import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

const LEFT_BUTTON_CODE = 0;

class TreeNode extends React.Component {
  renderChildren = ({ depth, isDragging, isNodeDragging, isParentDragging }) => {
    const {
      index = {},
      tree,
      draggingId,
      isDragNode,
      toggleCollapse,
      onDragStart,
      isCollapsed,
    } = this.props;

    const { id, children } = index;

    if (isCollapsed(id) || _.isEmpty(children)) return null;

    return (
      <div className={`SortableTree-children depth--${depth}`}>
        {children.map(child => {
          const childIndex = tree.getIndex(child);

          return (
            <TreeNode
              key={childIndex.id}
              tree={tree}
              index={childIndex}
              isDragNode={isDragNode}
              draggingId={draggingId}
              toggleCollapse={toggleCollapse}
              isCollapsed={isCollapsed}
              onDragStart={onDragStart}
              depth={depth + 1}
              isParentDragging={isNodeDragging || isParentDragging}
            />
          );
        })}
      </div>
    );
  };

  renderNode = ({ depth, isDragging, isNodeDragging, isParentDragging }) => {
    const { hidden, tree, index = {}, isCollapsed } = this.props;
    const { id, node, children } = index;

    if (hidden) return null;

    return (
      <div ref="inner" className="SortableTree-nodeInner" onMouseDown={this.handleMouseDown}>
        {tree.renderNode({
          node,
          depth,
          children,
          isDragging,
          isCollapsed: isCollapsed(id),
          toggleCollapse: this.toggleCollapse,
        })}
      </div>
    );
  };

  render() {
    const { index = {}, isRoot, depth = 0, isParentDragging, draggingId, isDragNode } = this.props;
    const { id, children } = index;
    const hasChildren = !_.isEmpty(children);

    const isDragging = draggingId || isDragNode;
    const isNodeDragging = draggingId === id;

    return (
      <div
        className={cn('SortableTree-node', {
          'is-placeholder': isNodeDragging,
          'is-child': !isRoot && !hasChildren,
          'is-folder': !isRoot && hasChildren,
          'is-root': isRoot,
        })}
      >
        {this.renderNode({ depth, isDragging, isNodeDragging, isParentDragging })}
        {this.renderChildren({
          depth,
          isDragging,
          isNodeDragging,
          isParentDragging,
        })}
      </div>
    );
  }

  toggleCollapse = (e, options) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { index = {}, toggleCollapse } = this.props;
    const { id, node } = index;

    if (toggleCollapse && node.children) {
      toggleCollapse(id, options);
    }
  };

  handleMouseDown = e => {
    if (e.button !== LEFT_BUTTON_CODE) return;

    const { index = {}, onDragStart } = this.props;
    if (onDragStart) {
      onDragStart(index.id, this.refs.inner, e);
    }
  };
}

export default TreeNode;
