import _ from 'lodash';
import { routeData } from '../json/routeData';

const scenariosRefRouterRules = {
  scenarios: {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'scenarios',
    matcher({ parentPath = [], key }) {
      return parentPath[0] === 'scenarios' && parentPath.length == 1;
    },
    dataFactory({ key, value }) {
      const name = _.get(value, 'name');

      if (name) {
        return { name: `${name} - ${key}` };
      }
      return { name: `${key}` };
    },
  },
  utilities: {
    recursive: ({ currentPath = [] }) => currentPath[0] === 'utilities',
    matcher({ parentPath = [], key }) {
      return parentPath[0] === 'utilities' && parentPath.length == 1;
    },
    dataFactory({ key, value }) {
      const name = _.get(value, 'name');

      if (name) {
        return { name: `${name} - ${key}` };
      }
      return { name: `${key}` };
    },
  },
};
export const scenariosRefRouter = ({ spec = {}, targets = [] } = {}) => {
  const rules = _.isEmpty(targets)
    ? scenariosRefRouterRules
    : _.pick(scenariosRefRouterRules, targets);
  return routeData({ data: spec, rules });
};
