import _ from 'lodash';
import { types, getEnv, flow } from 'mobx-state-tree';

import * as Bugsnag from '@platform/utils/bugsnag';
import FullStory from '@platform/utils/fullstory';
import Intercom from '@platform/utils/intercom';
import Heap from '@platform/utils/heap';
import { openAuthPopup } from '@platform/utils/popups';
import { getConfigVar } from '@platform/utils/config';
import { computeBillingInfo } from '@platform/utils/billing';
import { registerLogger } from '@platform/utils/logging';
import { createURL, getAllParams } from '@platform/utils/url';
import { isFeatureEnabled } from '@platform/utils/acl';

import { BaseStore } from '../_base';

import { BaseService, ServiceRecord } from './_base';

const log = registerLogger('@platform/stores', 'UserService');

// refetch the user up to every 60 seconds
const AUTHED_USER_FETCH_INTERVAL = 1000 * 60;

// Hide personal projects for users created past this date [SL-641]
const HIDE_PERSONAL_PROJECTS_DATE = 1543471199999; // Wed Nov 28 2018 23:59:59 GMT-0600

export const create = ({ data, env, options = {} }) => {
  const routerStore = _.get(env.rootStore, 'stores.routerStore');

  const Base = types
    .model({
      basePath: '/users',

      // keep a reference to the current user record
      _authorizedUser: types.maybe(types.reference(ServiceRecord)),

      loginError: types.optional(types.frozen()),
      loggingIn: false,
      loggingOut: false,
      tokenUpdated: types.maybe(types.number),

      // this tracks wether the user has logged in during this page session (without reloading)
      hasLoggedIn: false,

      // this is set by the server if the user has a session cookie set before the app is loaded
      sessionCookie: types.maybe(types.string),
    })
    .views(self => {
      return {
        get isLoggedIn() {
          return self.sessionCookie || self.hasLoggedIn ? true : false;
        },

        // fetch the data portion of the authorized user record, for easier use in the UI
        get authorizedUser() {
          return _.get(self, '_authorizedUser.data');
        },

        get showPersonalProjects() {
          return self.authorizedUser
            ? isFeatureEnabled({ feature: 'user-projects', user: self.authorizedUser }) ||
                new Date(self.authorizedUser.created_at).getTime() <= HIDE_PERSONAL_PROJECTS_DATE
            : false;
        },

        // DEPRECATED - need to remove all references to .user
        get user() {
          console.warn('DEPRECATED: use userService.authorizedUser.');
          return self.authorizedUser;
        },

        // returns the user for the current browser location, from local records
        get current() {
          const namespaceId = _.get(routerStore, 'location.params.namespaceId', null);
          if (!namespaceId) {
            return;
          }

          return self.getLocal(namespaceId, ['username']).get();
        },
      };
    })
    .actions(self => {
      // simple tracker so that we don't fetch the user a bazillion times as they
      // navigate through the app
      let lastFetched = 0;

      return {
        // always dehydrate the user store
        skipDehydrate() {
          return false;
        },

        afterCreate() {
          self.updateAnalytics();
          self.connectWebsocket();
        },

        beforeDestroy() {
          // Clean-up any state that shouldn't persist
          self.disconnectWebsocket();
        },

        updateSessionCookie(sessionCookie) {
          self.sessionCookie = sessionCookie;

          if (env.rootStore.isElectron) {
            window.Electron.ipc.send('session.create', {
              value: sessionCookie,
            });
          }
        },

        // Fetch data for the authorizedUser
        getAuthorizedUser: flow(function*({ token, force } = {}) {
          // only fetch once every so often
          const shouldFetch =
            lastFetched === 0 || new Date().getTime() - lastFetched < AUTHED_USER_FETCH_INTERVAL;

          if (self.authorizedUser && shouldFetch && !force) {
            // if lastFetched is 0 and we have a user, means we're attempting the first fetch
            // after initial page load in the client - let's setup the initial time.
            // can't do this on the server because the server time will be much different than
            // the client's time
            if (lastFetched === 0) {
              lastFetched = new Date().getTime();
            }

            return self.authorizedUser;
          }

          self.isLoading = true;

          let data;
          try {
            const headers = {};
            if (token) {
              headers.authorization = token;
            }

            const res = yield self.send({ method: 'get', path: '/user', headers });
            data = res.data;
            self.updateAuthorizedUser(data);

            self.afterLogin();
          } catch (e) {
            try {
              yield self.logout();
            } catch (e2) {
              console.error('Could not logout.', e2);
            }

            self.sessionCookie = undefined;
            self.hasLoggedIn = false;
          }

          self.isLoading = false;

          return data;
        }),

        authorize: flow(function*({ strategy, ...credentials }) {
          const { sessionService } = getEnv(self).rootStore._stores;

          self.loggingIn = true;

          try {
            const data = yield sessionService.create({
              username: credentials.email,
              email: credentials.email,
              password: credentials.password,
            });

            self.updateAuthorizedUser(data);
            self.afterLogin();
          } catch (err) {
            if (_.get(err, 'code')) {
              self.afterLogin({
                code: 401,
                name: 'Invalid credentials',
                message: _.includes(err.message, '401') ? 'Please try again' : err.message,
                errors: [
                  'Make sure you are using the correct email/username and password.',
                  'If you need to recover your password, click "Forgot Password".',
                  'If you do not have a Stoplight account, click "Join".',
                ],
              });
            } else {
              Bugsnag.notify(err, {
                severity: 'error',
                user: {
                  username: credentials.email,
                },
              });

              log.error(err);
              self.afterLogin({
                code: 500,
                name: String(err),
                message: 'Please contact support@stoplight.io if this persists.',
              });
            }
          }
        }),

        login({ email, password }) {
          return self.authorize({ strategy: 'local', email, password });
        },

        updateAuthorizedUser(userRecord) {
          if (_.isEmpty(userRecord)) {
            self._authorizedUser = undefined;
            self.sessionCookie = undefined;
            return;
          }

          self.updateRecord(userRecord, { replace: true });

          // setup the authorizedUser reference
          self._authorizedUser = userRecord.id;

          lastFetched = new Date().getTime();

          self.updateAnalytics();
        },

        updateAnalytics(extraInfo = {}, options = {}) {
          if (!env.rootStore.isClient || !self.authorizedUser) return;

          const { interests, ...otherInfo } = extraInfo || {};
          const { id, name, email, username, subscription } = self.authorizedUser;

          // build up user data to send to analytics
          const info = {
            ...(_.get(window, '__SL.userInfo') || {}),
            ...otherInfo,
            ...computeBillingInfo({ subscription }),
            id,
            user_id: id,
            name,
            email,
            username,
          };

          if (interests) {
            for (const interest of interests) {
              info[`interest_${interest}`] = true;
            }
          }

          if (env.rootStore.isElectron) {
            info.desktop_version = env.rootStore.version;
          } else {
            info.web_version = env.rootStore.version;
          }

          const filteredInfo = _.pickBy(info);
          _.set(window, '__SL.userInfo', filteredInfo);

          const targetFuncs = {
            intercom: Intercom.update,
            fullstory: FullStory.init,
            bugsnag: Bugsnag.init,
            heap: Heap.init,
          };

          const targets = options.targets || Object.keys(targetFuncs);
          _.forEach(targets, target => {
            if (!targetFuncs[target]) return;

            if (target === 'intercom') {
              window.intercomSettings = filteredInfo;
            }

            targetFuncs[target](filteredInfo);
          });
        },

        connectWebsocket() {
          if (env.rootStore.isClient && self.isLoggedIn) {
            _.invoke(env.rootStore.Websocket, 'connect');
          }
        },

        disconnectWebsocket() {
          if (env.rootStore.isClient) {
            _.invoke(env.rootStore.Websocket, 'disconnect');
          }
        },

        afterLogin(err) {
          if (err) {
            self.loginError = _.get(err, 'response.data', err);
            self.hasLoggedIn = false;
          } else {
            self.hasLoggedIn = true;
            self.connectWebsocket();
          }

          self.loggingIn = false;
        },

        logout: flow(function*() {
          if (env.rootStore.isElectron) {
            // eat dat cookie. nom nom nom.
            window.Electron.ipc.send('session.remove');
          }

          self.loggingOut = true;

          let err;
          try {
            yield self.send({
              method: 'delete',
              path: '/session',
            });
            self.updateAuthorizedUser();
            self.hasLoggedIn = false;
          } catch (e) {
            err = e;
          }

          self.loggingOut = false;
          self.disconnectWebsocket();

          if (err) {
            throw err;
          }
        }),

        updateImage(id, { file } = {}) {
          const formData = new FormData();
          formData.append('avatar', file);
          return self.update(id, formData);
        },

        openOAuthPopup: flow(function*(provider, onSuccess) {
          const { electronStore } = getEnv(self).rootStore.stores;
          const authUrl = `${getConfigVar('SL_API_HOST')}/oauth/${provider}`;
          let promise;

          if (env.rootStore.isClient && window.Electron) {
            promise = new Promise((resolve, reject) => {
              electronStore.Electron.ipc.on('close.oauth.window', (e, url) => {
                if (url) {
                  const loc = createURL(url);
                  const creds = getAllParams(loc);

                  resolve(creds);
                } else {
                  reject(new Error('Authentication was cancelled.'));
                }
              });

              electronStore.Electron.ipc.send('open.oauth.window', {
                provider: _.capitalize(provider),
                url: authUrl,
                param: 'token',
              });
            });
          } else {
            const popup = openAuthPopup(authUrl);
            promise = self.listenForCredentials(popup, provider);
          }

          try {
            const res = yield promise;

            // send a ping to grab a new cookie
            yield self.send({
              method: 'put',
              path: '/session',
              headers: { authorization: `Bearer ${res.access_token}` }, // API requires Bearer with authorization header
            });

            // get the authorized user
            const user = yield self.getAuthorizedUser({ force: true });

            self.afterLogin();
            if (onSuccess) {
              onSuccess(user);
            }
          } catch (err) {
            self.afterLogin(err);
          }
        }),

        listenForCredentials(popup, provider, resolve, reject) {
          if (!resolve) {
            return new Promise((res, rej) => {
              self.listenForCredentials(popup, provider, res, rej);
            });
          }

          let creds;

          try {
            creds = getAllParams(popup.location);
          } catch (err) {
            // err
          }

          if (!_.isEmpty(creds)) {
            popup.close();

            if (creds.access_token) {
              return resolve(creds);
            }

            if (creds.reason) {
              return reject(new Error(creds.reason));
            }
          }

          if (popup.closed) {
            return reject(new Error(`${_.capitalize(provider)} authentication was cancelled.`));
          }

          setTimeout(() => {
            self.listenForCredentials(popup, provider, resolve, reject);
          }, 10);
        },

        forgotPassword: flow(function*({ email }, onSuccess, onError) {
          if (_.isEmpty(email) || typeof window === 'undefined') return;

          self.isLoading = true;
          self.loginError = null;

          try {
            yield self.send({
              method: 'post',
              path: '/user/password_reset',
              data: { email },
            });

            onSuccess();
          } catch (error) {
            onError(_.get(error, 'response.data', error));
          }

          self.isLoading = false;
        }),

        resetPassword: flow(function*(
          { password, password_confirmation, reset_password_token },
          onSuccess,
          onError
        ) {
          if (_.isEmpty(reset_password_token) || typeof window === 'undefined') {
            onError(new Error('Reset password token is invalid. Please try again.'));
            return;
          }

          self.isLoading = true;
          self.loginError = null;

          try {
            yield self.send({
              method: 'post',
              path: '/user/password_confirm',
              data: { password, password_confirmation, reset_password_token },
            });
            onSuccess();
          } catch (error) {
            onError(_.get(error, 'response.data', error));
          }

          self.isLoading = false;
        }),

        confirmEmail: flow(function*({ confirmation_token }, onSuccess, onError) {
          if (_.isEmpty(confirmation_token) || typeof window === 'undefined') return;

          self.isLoading = true;
          self.loginError = null;

          try {
            yield self.send({
              method: 'put',
              path: '/user/email_confirm',
              data: { confirmation_token },
            });

            onSuccess();
          } catch (error) {
            onError(_.get(error, 'response.data', error));
          }

          self.isLoading = false;
        }),
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('UserService');

  return Service.create(data, env);
};
