import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import AutosizeTextarea from 'react-textarea-autosize';

import { alert } from '@platform/utils/alert';
import { timeAgo } from '@platform/utils/time';
import { colors, sizes, Button, Icon } from '@core/ui';
import PageLoading from '@platform/components/PageLoading';
import ErrorMessage from '@platform/components/ErrorMessage';
import ScrollContainer from '@platform/components/ScrollContainer';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';

class DiscussionList extends React.Component {
  render() {
    const { createHandler, ui, userService } = this.props;

    const canCreate = userService.authorizedUser;

    return (
      <div className="flex flex-col flex-1 text-sm">
        {this.renderError()}

        <div className="flex p-3">{this.renderDiscussionCreate()}</div>
        <div className="flex items-center px-3 pb-3 border-b">
          {this.renderFilters()}
          <div className="flex justify-end flex-1">
            {canCreate && (
              <Button
                size={sizes.sm}
                color={colors.accent}
                loading={createHandler.running}
                disabled={createHandler.running || !ui.discussionBody}
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
        {this.renderDiscussionList()}
      </div>
    );
  }

  handleItemClick = iid => {
    const { activeId, routerStore } = this.props;

    if (!iid || !routerStore) return;

    routerStore.setQueryParams({
      discussion: activeId !== iid ? _.toString(iid) : null,
    });
  };

  renderDiscussionCreate = () => {
    const { ui, updateUi, filePath, userService } = this.props;
    const { discussionBody = '', creating } = ui;

    const canCreate = userService.authorizedUser;

    let placeholder;

    if (canCreate) {
      placeholder = filePath
        ? `Start discussion for ${filePath}...`
        : 'Start discussion for this project...';
    } else {
      placeholder = 'Login to create a discussion';
    }

    return (
      <AutosizeTextarea
        id="discussionCreateTextArea"
        style={{
          background: '#f1f5f8',
          padding: '6px 13px',
          borderRadius: '3px',
          lineHeight: '16px',
          maxHeight: '110px',
          resize: 'none',
          flexGrow: 1,
          cursor: (!canCreate || creating) && 'not-allowed',
        }}
        minRows={1}
        disabled={creating || !canCreate}
        value={discussionBody}
        placeholder={placeholder}
        onChange={e => {
          updateUi('set', 'discussionBody', e.target.value);
        }}
      />
    );
  };

  renderDiscussionItem = (item, key) => {
    const { activeId } = this.props;

    const isActive = item.iid === activeId;

    return (
      <div
        key={key}
        style={{ maxHeight: 50, minHeight: 30 }}
        className={cn(
          'flex text-sm px-4 py-3 opacity-85 cursor-pointer',
          isActive ? 'bg-active-lighter' : 'hover:bg-darken-50'
        )}
        onClick={() => this.handleItemClick(item.iid)}
      >
        <div className="flex flex-1 overflow-hidden">
          <div className={cn('pr-2 font-extrabold')}>#{item.iid}</div>
          <div className="flex flex-col overflow-hidden flex-1">
            <div>{_.truncate(item.summary, { length: 50 })}</div>
          </div>
          <div className="pl-2">
            <div className="flex text-darken">
              <div className="pr-2">
                {item.commentCount || 0} <Icon icon={faComment} />
              </div>
              <div className="w-8 ta-r">{timeAgo(item.lastActivityAt, { short: true })}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDiscussionList = () => {
    const { list, activeId, filePath } = this.props;

    const discussions = _.get(list, 'data.discussions', []);

    if (list.loading) {
      return <PageLoading text="loading discussions..." size="small" inverted={false} />;
    }

    if (discussions.length === 0) {
      return (
        <div className="ta-c py-4 text-darken">
          No discussions {filePath ? `for ${filePath}` : 'in this project'}.
        </div>
      );
    }

    return (
      <div className="flex-1">
        <ScrollContainer
          ref={this.setScrollerRef}
          scrollToIndex={_.findIndex(discussions, item => item.iid === activeId)}
        >
          {_.map(discussions, (item, index) => this.renderDiscussionItem(item, index))}
        </ScrollContainer>
      </div>
    );
  };

  renderError = () => {
    const { ui, updateUi } = this.props;
    const { discussionsError } = ui;

    if (!discussionsError) return null;

    return (
      <ErrorMessage
        className="px-3 pt-3"
        error={discussionsError}
        onDismiss={() => updateUi('unset', 'discussionsError')}
        messageProps={{
          size: 'mini',
        }}
      />
    );
  };

  renderFilters = () => {
    const { ui, updateUi } = this.props;
    const { state = 'open' } = ui;

    const filters = ['open', 'resolved'];

    return _.map(filters, (filter, i) => {
      const isActive = (state === 'closed' && filter === 'resolved') || state === filter;

      return (
        <div
          key={i}
          onClick={() => updateUi('set', 'state', filter === 'resolved' ? 'closed' : 'open')}
          className={cn(
            'px-1 py-1/2 mr-1 font-bold rounded-lg cursor-pointer hover:bg-grey-lighter',
            isActive && 'text-active bg-grey-lighter'
          )}
        >
          {_.capitalize(filter)}
        </div>
      );
    });
  };

  setScrollerRef = element => {
    this.scroller = element;
  };

  handleSubmit = async () => {
    const { ui, updateUi, projectId, filePath, branch, createHandler } = this.props;
    const { discussionBody } = ui;

    updateUi('set', 'creating', true);

    const input = {
      projectId,
      branch,
      body: discussionBody,
    };

    if (filePath) {
      input.filePath = filePath; // TODO update with fileId
    }

    try {
      const result = await createHandler.run({ variables: { input } });

      const iid = _.get(result, 'data.createDiscussion.iid');

      if (iid) {
        updateUi('unset', 'discussionBody');
        this.handleItemClick(iid);
      }

      // no scroller when no discussions
      if (this.scroller) {
        this.scroller.scrollToTop();
      }
    } catch (e) {
      alert.error('There was an error creating the discussion.');
      console.error(e);
    }

    updateUi('unset', 'creating');
  };
}

export default inject('routerStore', 'userService')(observer(DiscussionList));
