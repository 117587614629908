import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import PageLoading from '@platform/components/PageLoading';

import Intercom from '@platform/utils/intercom';
import { alert } from '@platform/utils/alert';

class LogoutPage extends React.Component {
  componentWillMount() {
    if (typeof window === 'undefined') return;

    const { userService, routerStore } = this.props;

    userService
      .logout()
      .then(() => {
        Intercom.shutdown();

        alert.success('See ya next time!');

        setTimeout(() => {
          window.location.href = _.get(routerStore, 'location.query.r') || '/login';
        }, 2500);
      })
      .catch(e => {
        console.error('Error logging out', e);
        alert.warning('Something went wrong, you might not be logged out.');
      });
  }

  render() {
    return (
      <div className="px-4 h-full flex items-center justify-center" style={{ minHeight: 700 }}>
        <PageLoading>Logging Out...</PageLoading>
      </div>
    );
  }
}

export default inject('userService', 'routerStore')(observer(LogoutPage));
