import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import { inject, observer } from 'mobx-react';

import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';

import { alert } from '@platform/utils/alert';
import { getConfigVar } from '@platform/utils/config';
import { accessLevels, accessRolesHuman } from '@platform/utils/acl';

import { colors, sizes, Button, Icon } from '@core/ui';

class MagicInvite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isGenerating: false,
      showUrl: false,
    };
  }

  generateToken = async () => {
    const { currentOrg = {}, orgService, orgInviteTokenService, role, onGenerate } = this.props;
    this.setState({ isGenerating: true });

    try {
      const res = await orgInviteTokenService.update(
        role,
        {},
        {
          orgId: currentOrg.id,
        },
        { skipStore: true }
      );

      if (onGenerate) {
        onGenerate(res);
      }

      await orgService.get(currentOrg.id);

      alert.success('New link generated! Click it to copy to clipboard.');
    } catch (error) {
      // swallow
    }

    this.setState({ isGenerating: false });
  };

  renewToken = () => {
    let c = true;

    const { role, currentOrg } = this.props;
    const invite_token = _.get(currentOrg.invite_tokens, `invite_token_${role}`);

    if (invite_token) {
      c = confirm('Renewing will generate a new link and invalidate the current one. Proceed?');
    }

    if (c) {
      this.generateToken();
    }
  };

  render() {
    const { className, role, currentOrg } = this.props;
    const { isGenerating, showUrl } = this.state;

    const appURL = getConfigVar('SL_APP_HOST');

    const invite_token = _.get(currentOrg.invite_tokens, `invite_token_${role}`);
    const orgPath = `${appURL}/${currentOrg.path}`;
    const inviteUrl = `${orgPath}/invite?token=${invite_token}`;
    const scrubbedInviteUrl = `${orgPath}/invite?token=●●●●●●●●●●`;

    const humanRole = accessRolesHuman[accessLevels[_.toUpper(role)]];

    let buttonText = 'Generate';

    if (invite_token) {
      buttonText = 'Renew';
      if (isGenerating) {
        buttonText = 'Renewing...';
      }
    } else if (isGenerating) {
      buttonText = 'Generating...';
    }

    const buttonElem = (
      <Button
        color={invite_token ? colors.grey : colors.primary}
        disabled={isGenerating}
        size={invite_token ? sizes.sm : sizes.md}
        onClick={this.renewToken}
      >
        {buttonText}
      </Button>
    );

    return (
      <div className={cn('MagicInvite', className)}>
        <div className="flex items-center">
          <div className="flex-1 text-muted uppercase">
            Or invite anyone as a <span className="underline">{humanRole}</span> by sharing this
            link
          </div>
        </div>

        {!invite_token ? (
          <div className="pt-3">{buttonElem}</div>
        ) : (
          <div className="flex items-center border border-grey-light font-bold mt-1 rounded cursor-pointer text-muted">
            <div
              className="flex-1 px-4 py-2"
              onClick={() => {
                copy(inviteUrl);
                alert.success('Link copied to clipboard.');
              }}
            >
              {showUrl ? inviteUrl : scrubbedInviteUrl}
            </div>

            <div
              title={showUrl ? 'Hide token' : 'Show token'}
              className="px-4 py-2 border-l"
              onClick={() => {
                this.setState({ showUrl: !showUrl });
              }}
            >
              <Icon icon={showUrl ? faEye : faEyeSlash} />
            </div>

            <div
              title="Copy link to clipboard"
              className="px-4 py-2 border-l"
              onClick={() => {
                copy(inviteUrl);
                alert.success('Link copied to clipboard.');
              }}
            >
              <Icon icon={faCopy} />
            </div>

            <div title="Renew token" className="px-4 py-2 border-l" onClick={this.renewToken}>
              <Icon icon={faSync} pulse={isGenerating} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default inject((stores, props) => {
  const { orgService, orgInviteTokenService } = stores;
  const { org } = props;

  return {
    orgService,
    orgInviteTokenService,
    currentOrg: org || orgService.current || {},
  };
})(observer(MagicInvite));
