import React from 'react';
import _ from 'lodash';
import TimeAgo from 'react-timeago';
import AutosizeTextarea from 'react-textarea-autosize';
import { Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { Button, colors, sizes } from '@core/ui';

import ImageIcon from '@platform/components/ImageIcon';
import PageLoading from '@platform/components/PageLoading';
import MarkdownViewer from '@platform/components/MarkdownViewer';
import ScrollContainer from '@platform/components/ScrollContainer';
import GraphQL from '@platform/containers/GraphQL';

// TODO Change to const if lifecycle methods wont be needed
class DiscussionInfo extends React.Component {
  render() {
    const { info, activeId } = this.props;

    const discussion = _.get(info, 'data.discussion', {});

    let elem;
    if (info.loading) {
      elem = (
        <PageLoading
          key="l"
          className="text-sm"
          text="loading discussion..."
          size="small"
          inverted={false}
        />
      );
    } else if (_.isEmpty(discussion)) {
      elem = (
        <div className="ta-c py-4 text-sm text-darken">
          No discussion <b>#{activeId}</b>.
        </div>
      );
    } else {
      elem = this.renderCommentList();
    }

    return <div className="flex flex-col h-full">{elem}</div>;
  }

  renderResolveButton = () => {
    const {
      updateUi,
      info,
      setStateHandler,
      activeId,
      projectId,
      userService,
      projectService,
    } = this.props;

    const discussion = _.get(info, 'data.discussion', {});
    const { state } = discussion;

    const canResolve =
      _.get(discussion.creator, 'id') === _.get(userService.authorizedUser, 'id') ||
      projectService.canUser({ action: 'push:project' });

    const buttonText = state === 'open' ? 'Resolve' : 'Reopen';

    let resolveElem = (
      <Button
        size={sizes.sm}
        color={state === 'open' ? colors.secondary : colors.default}
        loading={setStateHandler.running}
        disabled={setStateHandler.running || !canResolve}
        onClick={async () => {
          let result = await setStateHandler.run({
            variables: {
              projectId,
              discussionIId: activeId,
              input: {
                state: state === 'open' ? 'closed' : 'open',
              },
            },
          });

          result = _.get(result, 'data.setDiscussionState');

          if (result) {
            updateUi('set', 'activeFilter', result.state === 'closed' ? 'resolved' : result.state);
          }
        }}
      >
        {buttonText}
      </Button>
    );

    if (!canResolve) {
      return (
        <Popup
          hoverable
          on="hover"
          size="small"
          position="top right"
          content={`Only project writers or discussion creator can "${buttonText}"`}
          trigger={resolveElem}
        />
      );
    }

    return resolveElem;
  };

  renderDiscussionInfo = () => {
    const { info } = this.props;

    const discussion = _.get(info, 'data.discussion', {});

    return (
      <div className="mt-6" key="di">
        {this.renderCommentItem({
          body: discussion.body,
          updatedAt: discussion.createdAt,
          creator: discussion.creator,
          meta: this.renderResolveButton(),
        })}
      </div>
    );
  };

  renderCommentList = () => {
    const { activeId, ui, info, updateUi, userService } = this.props;

    const discussion = _.get(info, 'data.discussion', {});
    const commentBody = _.get(ui, [activeId, 'commentBody'], '');
    const commentError = _.get(ui, [activeId, 'commentError'], '');

    const canReply = userService.authorizedUser;

    return (
      <GraphQL
        id={`DiscussionsPanelComments`}
        service="commentService"
        methods={{
          list: {
            variables: {
              parentId: discussion.id,
              parentType: 'post',
              orderBy: { field: 'createdAt', direction: 'DESC' },
            },
            pollInterval: 10000,
          },
          createHandler: {},
        }}
        renderProp={({ list, createHandler }) => {
          let comments = _.get(list, 'data.comments', []);

          if (list.loading) {
            comments = [
              <PageLoading
                key="l"
                className="text-sm"
                text="loading comments..."
                size="small"
                inverted={false}
              />,
            ];
          } else {
            comments = _.sortBy(comments, c => new Date(c.createdAt));
            comments = _.map(comments, (comment, index) =>
              this.renderCommentItem({
                body: comment.body,
                updatedAt: comment.createdAt,
                creator: comment.creator,
                key: index,
              })
            );
          }

          comments.unshift(this.renderDiscussionInfo());

          return (
            <div className="flex flex-col flex-1">
              <div className="flex-1">
                <ScrollContainer autoScroll="bottom" threshold={50}>
                  {comments}
                </ScrollContainer>
              </div>
              {commentError && (
                <div className="text-red text-sm px-4 pb-1">
                  Something went wrong, see the developer console for more details.
                </div>
              )}
              <div className="flex mx-4 mb-3">
                <AutosizeTextarea
                  style={{
                    background: '#f1f5f8',
                    padding: '6px 13px',
                    borderRadius: '3px',
                    lineHeight: '16px',
                    maxHeight: '110px',
                    resize: 'none',
                    cursor: !canReply && 'not-allowed',
                    flexGrow: 1,
                  }}
                  placeholder={canReply ? 'Add reply...' : 'Login to reply'}
                  value={commentBody}
                  disabled={!canReply}
                  onChange={e => {
                    updateUi('set', [activeId, 'commentBody'], e.target.value);
                  }}
                  minRows={1}
                />
              </div>
              {canReply && (
                <div className="flex mx-4 mb-3">
                  <Button
                    className="px-1"
                    size={sizes.sm}
                    color={colors.accent}
                    loading={createHandler.running}
                    disabled={!commentBody || createHandler.running || !canReply}
                    onClick={async () => {
                      try {
                        await createHandler.run({
                          variables: {
                            input: {
                              parentType: 'post',
                              parentId: discussion.id,
                              body: commentBody,
                            },
                          },
                        });

                        updateUi('unset', [activeId, 'commentBody']);
                        updateUi('unset', [activeId, 'commentError']);
                      } catch (e) {
                        updateUi('set', [activeId, 'commentError'], e);
                        console.warn(e);
                      }
                    }}
                  >
                    Reply
                  </Button>
                  <div className="flex-1" />
                  {this.renderResolveButton()}
                </div>
              )}
            </div>
          );
        }}
      />
    );
  };

  renderCommentItem = ({ key, body, updatedAt, creator = {}, meta }) => {
    if (!body || _.isEmpty(body)) return null;

    return (
      <div className="px-4 pb-8" key={key}>
        <div className="flex items-center pb-3">
          <ImageIcon className="mr-2" imageUrl={creator.avatar} size="small" />
          <div className="flex-1">
            <div className="leading-none">{creator.username}</div>
            <div className="text-darken text-sm pt-1/2">
              <TimeAgo date={updatedAt} />
            </div>
          </div>
          {meta && <div>{meta}</div>}
        </div>
        <MarkdownViewer innerClassName="text-base" value={body} />
      </div>
    );
  };
}

export default inject('routerStore', 'userService', 'projectService')(observer(DiscussionInfo));
