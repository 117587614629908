import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { computeHubThemeStyles } from '@platform/utils/hubs';
import { isExternalLink } from '@platform/utils/url';

import HubHeader from '../HubHeader/viewer';
import Page from '../Page/editor';

class HubEditor extends React.Component {
  static propTypes = {
    // should be a hub editor store
    editor: PropTypes.object.isRequired,
  };

  render() {
    const { id, editor, sidebarProps = {} } = this.props;
    const { nav, activePagePath, theme, parsed = {}, hasHeader, hasSidebar } = editor;

    const hubElems = [];

    const computedTheme = computeHubThemeStyles(theme);
    if (computedTheme) {
      hubElems.push(<style key="th">{computedTheme}</style>);
    }

    hubElems.push(
      <HubHeader
        key="1"
        id={id}
        nav={nav}
        theme={theme}
        logo={parsed.logo}
        title={parsed.title}
        hasHeader={hasHeader}
        hasSidebar={hasSidebar}
        activePagePath={activePagePath}
        buildPath={path => {
          if (isExternalLink(path)) return path;

          return editor.buildEditPath(['pages', path]);
        }}
        includeSearch="Search is only enabled in published Hubs."
      />
    );

    hubElems.push(<Page key="2" id={id} store={editor} {...sidebarProps} />);

    hubElems.push(
      <div key="3" className="pin absolute flex z--1" role="presentation">
        <div className="h-full flex-1 bg-grey-lightest" />
        <div className="h-full flex-1 bg-white" />
      </div>
    );

    return hubElems;
  }
}

export default observer(HubEditor);
