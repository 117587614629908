/**
 * Temporary workaround until consolidate new editor architecture.
 */

import _ from 'lodash';
import { computed, action } from 'mobx';

const steps = [
  {
    id: 'profile',
    number: 1,
  },
  { id: 'interests', number: 1 },
  { id: 'org-create', number: 2 },
  { id: 'org-done', number: 2 },
  { id: 'project-create', number: 3 },
];

export default class OnboardingStore {
  rootStore = null;

  constructor({ rootStore }) {
    this.rootStore = rootStore;
  }

  @computed
  get activeStepIndex() {
    const id = _.get(this.rootStore, 'stores.routerStore.location.query.step', steps[0]);
    const index = _.findIndex(steps, { id });
    return index >= 0 ? index : 0;
  }

  @computed
  get activeStep() {
    return steps[this.activeStepIndex];
  }

  @computed
  get nextStep() {
    return steps[this.activeStepIndex + 1];
  }

  start(namespaceId, namespaceType) {
    this.rootStore.stores.routerStore.setQueryParams({ modal: 'onboard' }, { replace: true });
  }

  @action.bound
  goToNextStep() {
    if (!this.nextStep) return;

    this.rootStore.stores.routerStore.setQueryParams({ step: this.nextStep.id }, { replace: true });
  }
}
