import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';

import { getFeaturePlan, getFeatureName, getTrial } from '@platform/utils/billing';
import { getConfigVar } from '@platform/utils/config';

const FeatureBadge = props => {
  const { routerStore, className, feature, namespace = {}, show = true, inverted } = props;

  const featurePlan = getFeaturePlan(feature);
  if (!featurePlan || !getConfigVar('STRIPE_PK')) {
    return null;
  }

  const namespaceType = namespace.namespace_type;

  const trial = namespaceType === 'org' && getTrial(namespace.subscription) > 0;
  if (!show && !trial) {
    return null;
  }

  let planText = `${_.upperFirst(featurePlan)} Plan`;
  const path = `/${namespace.path}/settings/billing`;

  return (
    <div className={cn('FeatureBadge', className)}>
      <Popup
        on="hover"
        trigger={
          <div
            className={cn(
              'text-xs px-2 py-1/2 text-white cur-p text-rounded hover:opacity-100 opacity-75',
              inverted ? 'bg-lighten-100' : 'bg-green'
            )}
            onClick={() => {
              if (namespaceType === 'org') {
                routerStore.replace(path);
              } else {
                routerStore.setQueryParams({ modal: 'new-org' }, { preserve: true });
              }
            }}
          >
            {trial ? 'Plan Required' : planText}
          </div>
        }
        content={
          namespaceType === 'org' ? (
            <div>
              To use <strong>{getFeatureName(feature)}</strong>
              {trial ? ' after your trial,' : ''} upgrade your organization account to the{' '}
              <strong>{planText}</strong>
            </div>
          ) : (
            <div>
              To use <strong>{getFeatureName(feature)}</strong> create an organization with the{' '}
              <strong>{planText}</strong>
            </div>
          )
        }
        hideOnScroll
      />
    </div>
  );
};

export default inject('routerStore')(observer(FeatureBadge));
