import React from 'react';
import { observer } from 'mobx-react';
import JsonPathCacheParentContainer from '@platform/components/JsonPathCacheParentContainer';

import BlockList from '../BlockList/editor';

const INVALID_BLOCK_TYPES = [];

class ContainerBlock extends React.Component {
  render() {
    const { WrapperFactory, editor, cache, updateCache, blockPath = [], data } = this.props;
    const contentElem = [];

    contentElem.push(
      <div key="tabContent" className="HubBlock-tab-content">
        <BlockList
          store={editor}
          blocks={data.blocks || []}
          parentPath={blockPath.concat('data')}
          relativePath={editor.currentRelativePath}
          cache={cache}
          updateCache={updateCache}
          invalidBlockTypes={INVALID_BLOCK_TYPES}
          blockProps={{ isNested: true }}
          isNested
        />
      </div>
    );

    return <WrapperFactory {...this.props} contentElem={contentElem} />;
  }
}

ContainerBlock.displayName = 'HubContainerBlockEditor';

export default JsonPathCacheParentContainer({
  cacheKeyProp: 'cacheKey',
  basePathProp: 'blockPath',
  path: ['data', 'children'],
  childrenProp: 'data.children',
})(observer(ContainerBlock));
