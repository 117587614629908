import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import EntityEditorGroup from '../EntityEditorGroup';
import EditorContentSection from '../EditorContentSection';

import SwaggerInfo from '../SwaggerInfo';
import SwaggerSecurity from '../SwaggerSecurity';
import { SwaggerExtensions } from '../SwaggerExtensions';

const SwaggerRoot = props => {
  const { id, value, parsed, onChange, propsData, readOnly, currentPath } = props;

  let hostAndPath;
  let globalGroups;

  if (!readOnly) {
    hostAndPath = (
      <div className="flex mt-6">
        <FormInput
          className="flex-1 pr-2"
          onChange={e => {
            onChange('set', ['host'], e.target.value);
          }}
          {..._.get(propsData, 'host.componentProps', {})}
          input={{
            ..._.get(propsData, 'host.componentProps.input', {}),
            value: _.get(value, 'host'),
            size: 'medium',
          }}
        />

        <FormInput
          className="flex-1 pl-2"
          onChange={e => {
            onChange('set', ['basePath'], e.target.value);
          }}
          {..._.get(propsData, 'basePath.componentProps', {})}
          input={{
            ..._.get(propsData, 'basePath.componentProps.input', {}),
            value: _.get(value, 'basePath'),
            size: 'medium',
          }}
        />
      </div>
    );

    globalGroups = (
      <div className="mt-6">
        <EntityEditorGroup
          id="SwaggerRoot-settings"
          name="Global Settings"
          active={
            !_.isEmpty(_.get(value, 'schemes')) ||
            !_.isEmpty(_.get(value, 'consumes')) ||
            !_.isEmpty(_.get(value, 'produces'))
          }
          padded
          defaultOpen
          contentFactory={() => (
            <div className="flex">
              <FormSelect
                className="flex-1 pr-2"
                onChange={v => {
                  onChange('set', ['schemes'], v);
                }}
                {..._.get(propsData, 'schemes.componentProps', {})}
                size="medium"
                value={_.get(value, 'schemes')}
              />

              <FormSelect
                className="flex-1 pr-2 pl-2"
                onChange={v => {
                  onChange('set', ['consumes'], v);
                }}
                {..._.get(propsData, 'consumes.componentProps', {})}
                size="medium"
                value={_.get(value, 'consumes')}
              />

              <FormSelect
                className="flex-1 pl-2"
                onChange={v => {
                  onChange('set', ['produces'], v);
                }}
                {..._.get(propsData, 'produces.componentProps', {})}
                size="medium"
                value={_.get(value, 'produces')}
              />
            </div>
          )}
        />

        <EntityEditorGroup
          id="SwaggerRoot-security"
          className="mt-6"
          name="Global Security"
          active={!_.isUndefined(_.get(value, 'security'))}
          padded
          contentFactory={() => (
            <SwaggerSecurity
              security={_.get(value, 'security')}
              securityDefinitions={_.get(parsed, 'securityDefinitions')}
              handleUpdate={(t, p, v) => {
                onChange(t, ['security'].concat(p), v);
              }}
            />
          )}
        />
      </div>
    );
  }

  return (
    <div>
      <EditorContentSection>
        <SwaggerInfo
          value={_.get(value, 'info')}
          onChange={(t, p, v) => {
            onChange(t, ['info'].concat(p), v);
          }}
          afterTitle={hostAndPath}
          beforeGroups={globalGroups}
          readOnly={readOnly}
          preview={readOnly}
        />
      </EditorContentSection>

      {!readOnly && (
        <EditorContentSection id="SwaggerRoot:extensions" title="Extensions">
          <SwaggerExtensions
            id={id}
            value={value}
            onChange={(t, p, v) => {
              let value = v;

              // When moving, make sure to only move within the value object since we're just renaming the x-key
              if (t === 'move') {
                value = currentPath.concat(value);
              }

              onChange(t, p, value);
            }}
          />
        </EditorContentSection>
      )}
    </div>
  );
};

export default inject(({ oas2EditorStore }, { editorId }) => {
  const editor = oas2EditorStore.getEditor({ id: editorId });

  return {
    editor,
  };
})(observer(SwaggerRoot));
