import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Image, Icon, Divider } from 'semantic-ui-react';

import Accordion from '@platform/components/Accordion';
import PageLoading from '@platform/components/PageLoading';
import { PanelHeader } from '@platform/components/PanelHeader';
import UserSearchDropdown from '@platform/components/UserSearchDropdown';
import TeamSearchDropdown from '@platform/components/TeamSearchDropdown';

import { alert } from '@platform/utils/alert';
import { accessLevels, accessRoles } from '@platform/utils/acl';

import { themes } from '@core/ui';

const RoleElem = ({ onClick, title, description, isActive }) => {
  return (
    <div className="ProjectPageCollaboration-role-elem" onClick={onClick}>
      <div className="flex items-center p-3">
        <div className="mr-3">
          <Icon name={`${isActive ? 'check ' : ''}circle outline`} />
        </div>
        <div>
          <div>{title}</div>
          {description}
        </div>
      </div>
    </div>
  );
};

class ProjectPageCollaboration extends React.Component {
  componentWillMount() {
    this.fetchMember(this.props);
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.type !== nextProps.type) {
      this.fetchMember(nextProps);
    }
  }

  fetchMember = props => {
    const { service, project } = props;

    service.find({
      projectId: project.id,
    });
  };

  renderMember = (member, section) => {
    const { currentUser, canManageCollaboration } = this.props;

    return {
      id: member.id,
      title: () => (
        <div
          className="ProjectPageCollaboration-member flex items-center justify-between"
          key={member.id}
        >
          {member.avatar_url ? (
            <Image inline size="mini" bordered spaced="right" src={member.avatar_url} />
          ) : null}

          <div className={`flex-1${member.avatar_url ? ' ml-2' : ''}`}>
            <div>{member.name}</div>
          </div>
        </div>
      ),
      content: section.noControls
        ? null
        : () => {
            let roleElem;

            if (canManageCollaboration && member.id !== currentUser.id) {
              roleElem = [];

              roleElem.push(
                <RoleElem
                  key={accessLevels['ADMIN']}
                  isActive={member.access_level === accessLevels['ADMIN']}
                  onClick={() => {
                    this.handleUpdate(member.id, accessLevels['ADMIN']);
                  }}
                  title="Admin"
                  description={
                    <small className="c-muted">
                      Can <span className="underline">update/remove</span> the project and its
                      members.
                    </small>
                  }
                />
              );

              roleElem.push(
                <RoleElem
                  key={accessLevels['MEMBER']}
                  isActive={member.access_level === accessLevels['MEMBER']}
                  onClick={() => {
                    this.handleUpdate(member.id, accessLevels['MEMBER']);
                  }}
                  title="Write"
                  description={
                    <small className="c-muted">
                      Can <span className="underline">update files</span> in the project.
                    </small>
                  }
                />
              );

              roleElem.push(<Divider key="divider" fitted />);

              roleElem.push(
                <div
                  key="remove"
                  className="cur-p"
                  onClick={() => {
                    this.handleRemove(member.id);
                  }}
                >
                  <div className="flex items-center p-3">
                    <div className="mr-3">
                      <Icon name="sign out" />
                    </div>
                    <div>Remove from project</div>
                  </div>
                </div>
              );
            } else if (member.id === currentUser.id) {
              roleElem = (
                <div
                  className="cur-p"
                  onClick={() => {
                    this.handleRemove(member.id);
                  }}
                >
                  <div className="flex items-center p-3">
                    <div className="mr-3">
                      <Icon name="sign out" />
                    </div>
                    <div>Leave project</div>
                  </div>
                </div>
              );
            }

            return roleElem;
          },
    };
  };

  renderSection = (section, key) => {
    let listElem;

    if (_.isEmpty(section.members) || section.message) {
      listElem = <div className="ProjectPageCollaboration-none">{section.message || 'None.'}</div>;
    } else {
      const sortedCollaborators = _.sortBy(section.members, member => {
        return [_.toLower(member.name), _.toLower(member.username)];
      });

      const panels = _.map(sortedCollaborators, member => {
        return this.renderMember(member, section);
      });

      listElem = <Accordion panels={panels} exclusive />;
    }

    let dividerElem;
    if (section.divider) {
      dividerElem = <div className={`ProjectPageCollaboration-divider is-${section.divider}`} />;
    }

    return (
      <div className="ProjectPageCollaboration-section" key={key}>
        {section.divider === 'top' ? dividerElem : null}

        <div className="ProjectPageCollaboration-section-title">
          <span>{_.capitalize(section.name)}</span>
        </div>

        <div className="ProjectPageCollaboration-list">{listElem}</div>

        {section.divider === 'bottom' ? dividerElem : null}
      </div>
    );
  };

  renderSections = () => {
    const { members, project, type } = this.props;

    const roleObject = {};

    roleObject.ADMIN = {
      name: 'admin access',
      members: [],
    };

    roleObject.MEMBER = {
      name: 'write access',
      members: [],
    };

    if (type === 'members') {
      roleObject.REPORTER = {
        name: 'read access',
        message: `All members of the ${_.get(
          project,
          'namespace.name'
        )} organization have read access.`,
        members: [],
      };
    }

    _.forEach(members, member => {
      _.get(roleObject, [accessRoles[member.access_level], 'members'], []).push(member);
    });

    const sections = _.map(roleObject, this.renderSection);

    return <div key="list">{sections}</div>;
  };

  handleError = e => {
    alert.error(e.message || String(e));
  };

  handleCreate = id => {
    const { service, project, type } = this.props;

    const identifier = type === 'members' ? 'user_id' : 'group_id';

    return service
      .create({ [identifier]: id }, { projectId: project.id })
      .then(() => {
        alert.success('Successfully added!');
      })
      .catch(this.handleError);
  };

  handleUpdate = (id, accessLevel) => {
    const { service, project, type } = this.props;

    let c = true;

    if (accessLevel === accessLevels['OWNER']) {
      c = window.confirm(`Are you sure you want to make this member an owner?`);
    }

    if (!c) return;

    const update = {};

    if (type === 'members') {
      update.access_level = accessLevel;
    } else {
      update.group_access = accessLevel;
    }

    return service
      .update(id, update, {
        projectId: project.id,
      })
      .then(() => {
        alert.success('Successfully updated.');
      })
      .catch(this.handleError);
  };

  handleRemove = id => {
    const { service, project } = this.props;

    const c = window.confirm('Are you sure you want to remove this team from the project?');

    if (!c) return;

    return service
      .remove(id, { projectId: project.id })
      .then(() => {
        alert.warning('Successfully removed.');
      })
      .catch(this.handleError);
  };

  render() {
    const {
      project = {},
      service,
      type,
      canManageCollaboration,
      members,
      orgMemberService,
    } = this.props;

    let contentElem;
    let searchElem;

    if (service.isFinding) {
      contentElem = <PageLoading size="small" />;
    } else {
      if (canManageCollaboration) {
        if (type === 'members') {
          searchElem = (
            <UserSearchDropdown
              key="u-search"
              id={`project:${project.id}`}
              className="ml-3 mr-3 transparent"
              placeholder="add org member by username"
              excludedMembers={members}
              handleChange={(e, { value }) => {
                if (!(e instanceof KeyboardEvent) || _.toLower(e.key) === 'enter') {
                  this.handleCreate(value);
                }
              }}
              handleSearch={({ searchQuery } = {}) => {
                return orgMemberService.find(
                  { orgId: _.get(project, 'namespace.id') },
                  { query: { query: searchQuery } }
                );
              }}
              loading={orgMemberService.isFinding}
              fluid
              allowAdditions={false}
              size="tiny"
              selectOnBlur={false}
              value={null}
              noResultsMessage="Add members to this organization before adding them to a project."
            />
          );
        } else {
          searchElem = (
            <TeamSearchDropdown
              key="t-search"
              id={`project:${project.id}`}
              className="ml-3 mr-3 transparent"
              orgId={_.get(project, 'namespace.id')}
              placeholder="add org team by name"
              handleChange={(e, { value }) => {
                if (!(e instanceof KeyboardEvent) || _.toLower(e.key) === 'enter') {
                  this.handleCreate(value);
                }
              }}
              size="tiny"
              selectOnBlur={false}
              allowAdditions={false}
              value={null}
              noResultsMessage="Add teams to this organization before adding them to a project."
            />
          );
        }
      }

      contentElem = this.renderSections();
    }

    return (
      <div className="w-80 text-white flex flex-col h-full">
        <PanelHeader theme={themes.dark} path={[{ name: type }]} />
        <div className="mt-3">{searchElem}</div>
        <div className="flex-1 overflow-y-scroll">{contentElem}</div>
      </div>
    );
  }
}

export default inject((stores, props) => {
  const {
    projectService,
    projectStore,
    projectTeamService,
    projectMemberService,
    userService,
    orgMemberService,
  } = stores;

  const { type = 'members' } = props;

  const project = projectService.current;
  const service = type === 'members' ? projectMemberService : projectTeamService;

  const canManageCollaboration = projectService.canUser({
    action: 'manage:collaboration',
  });

  return {
    type,
    project,
    service,
    members: service.currentList,
    store: projectStore.current,
    currentUser: userService.authorizedUser,
    canManageCollaboration,
    orgMemberService,
  };
})(observer(ProjectPageCollaboration));
