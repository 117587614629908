import React from 'react';
import _ from 'lodash';

import FormSelect from '../FormSelect';

class SwaggerSecurityScope extends React.Component {
  handleUpdateScopes = val => {
    const { id, index, handleUpdate } = this.props;

    handleUpdate('set', [index, id], val);
  };

  render = () => {
    const { id, availableScopes, usedScopes } = this.props;

    return (
      <div key={id} className="mt-2">
        <FormSelect
          multiple
          fluid
          options={availableScopes}
          placeholder={`${id} applicable scopes`}
          value={usedScopes}
          onChange={this.handleUpdateScopes}
          allowAdditions
          search
        />
      </div>
    );
  };
}

class SwaggerSecurity extends React.Component {
  usedSecurity = () => {
    const { security = [] } = this.props;
    return security ? security.map(s => _.first(_.keys(s))) : [];
  };

  handleUpdateSecurity = val => {
    const { security, handleUpdate } = this.props;
    const usedSecurity = this.usedSecurity();

    if (val.includes('no security') && !_.isEqual(security, [])) {
      handleUpdate('set', [], []);
      return;
    } else {
      val = val.filter(v => v !== 'no security');
    }

    if (_.isEmpty(val)) {
      handleUpdate('unset', []);
      return;
    }

    // handle removals
    const newSecurity = _.reject(security, s => !_.includes(val, _.first(_.keys(s))));

    // handle additions
    for (const v of val) {
      const existing = _.find(security, s => _.first(_.keys(s)) === v);
      if (!existing) {
        newSecurity.push({ [v]: [] });
      }
    }

    handleUpdate('set', [], newSecurity);
  };

  render = () => {
    const { security, securityDefinitions = {}, handleUpdate, readOnly } = this.props;
    const usedSecurity = this.usedSecurity();

    const usedOauths = [];
    _.forEach(securityDefinitions, (s, k) => {
      if (s.type === 'oauth2' && _.includes(usedSecurity, k)) {
        usedOauths.push(k);
      }
    });

    const oauthScopeElems = [];
    if (!_.isEmpty(usedOauths)) {
      for (const oauth of usedOauths) {
        const availableScopes = _.keys(_.get(securityDefinitions, [oauth, 'scopes'], {}));
        const securityIndex = _.findIndex(security, s => _.first(_.keys(s)) === oauth);
        const usedScopes = _.get(security, [securityIndex, oauth], []);
        if (!_.isEmpty(availableScopes) || !_.isEmpty(usedScopes)) {
          oauthScopeElems.push(
            <SwaggerSecurityScope
              key={oauth}
              id={oauth}
              index={securityIndex}
              availableScopes={_.map(availableScopes, s => ({ text: s, value: s }))}
              placeholder={`${oauth} applicable scopes`}
              usedScopes={usedScopes}
              handleUpdate={handleUpdate}
              readOnly={readOnly}
            />
          );
        }
      }
    }

    return (
      <div className="SwaggerSecurity">
        {readOnly ? null : (
          <FormSelect
            multiple
            fluid
            options={_.keys(securityDefinitions)
              .concat('no security')
              .map(k => ({ text: k, value: k }))}
            placeholder="Choose security definitions to apply..."
            value={_.isEqual(security, []) ? 'no security' : usedSecurity}
            onChange={this.handleUpdateSecurity}
            search
          />
        )}

        {oauthScopeElems}
      </div>
    );
  };
}

export default SwaggerSecurity;
