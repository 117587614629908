import _ from 'lodash';
import { getConfigVar } from '../config';
import { cleanSlashes } from '../url';

export const getProtocol = () =>
  getConfigVar('SL_PUBS_SCHEME') ||
  (getConfigVar('RELEASE_STAGE') === 'development' ? 'http' : 'https');

export const getPort = () =>
  getConfigVar('SL_PUBS_PORT') ? `:${getConfigVar('SL_PUBS_PORT')}` : '';

export const isCustomDomain = domain => {
  const isSubdomain = new RegExp(`.${getConfigVar('SL_PUBS_HOST')}$`);
  return !isSubdomain.test(domain);
};

export const getBuildLink = ({ domain, build, isLive }) => {
  if (!isLive && (!build || _.get(build.status, 'code') !== 'success')) return '';

  const basePath = _.get(build.config, 'basePath') || '';
  const query = isLive ? '' : `?__build_id=${build.id}`;
  const buildLink = cleanSlashes(`${domain}${getPort()}/${basePath}${query}`);

  return `${getProtocol()}://${buildLink}`;
};
