import _ from 'lodash';

import { computeTreeData } from '../entities';
import { safeParse } from '../json';

const treeMapping = {
  apis: {},
  rules: {},
  scenarios: {},
};

export const propOrder = Object.keys(treeMapping);

export const computeSidebarTree = (specData, props = {}) => {
  const { currentPath = [], location, editor, hideSectionActions, hideItemActions, meta } = props;
  const data = safeParse(specData || {});
  const folderProps = {
    data,
    currentPath,
    location,
    editor,
    hideItemActions,
    meta,
  };

  const basicsTree = [
    {
      name: 'home',
      isActive: _.isEmpty(currentPath),
      href: {
        pathname: location.pathname,
        query: {
          ...(location.query || {}),
          edit: undefined,
        },
      },
      noToggle: true,
      icon: 'home',
    },
  ];

  const apisTree = computeTreeData(data.apis, {
    ...folderProps,
    treePath: ['apis'],
  });

  const rulesTree = computeTreeData(data.rules, {
    ...folderProps,
    treePath: ['rules'],
  });

  return [
    {
      treeData: basicsTree,
    },
    {
      name: 'Apis',
      treeData: apisTree,
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add API',
                content:
                  'APIs let you match incoming requests, and forward them to upstream servers. They can also act as a group for rules.',
              },
              onClick() {
                editor.addApi();
              },
            },
          ]
        : [],
    },
    {
      name: 'Rules',
      treeData: rulesTree,
      actions: !hideSectionActions
        ? [
            {
              icon: 'plus',
              tip: {
                header: 'Add Rule',
                content: 'Rules let you match incoming requests, and apply scenarios to them.',
              },
              onClick() {
                editor.addRule();
              },
            },
          ]
        : [],
    },
  ];
};
