import React from 'react';

import Highlight from '@platform/components/Highlight';
import CodeEditor from '@platform/components/CodeEditor';
import FormSelect from '@platform/components/FormSelect';
import FormCheckbox from '@platform/components/FormCheckbox';
import FormInputList from '@platform/components/FormInputList';
import FormInputLabel from '@platform/components/FormInputLabel';

import { modeOptions } from '@platform/utils/codeMirror';

const CodeBlock = props => {
  const { id, WrapperFactory, data = '', config = {}, onChangeData, onChangeConfig, isRef } = props;
  const { mode, lineNumbers = true, highlightRange = [] } = config;

  const actions = [];
  actions.push(
    <FormSelect
      label="Mode"
      value={mode}
      options={modeOptions}
      onChange={(e, { value }) => onChangeConfig('set', ['mode'], value)}
      size="tiny"
      loc="right"
      inline
      search
    />
  );

  actions.push(
    <FormCheckbox
      label="Line Numbers"
      checked={lineNumbers}
      onChange={checked => {
        const t = checked ? 'unset' : 'set';
        onChangeConfig(t, ['lineNumbers'], checked);
      }}
      inline
    />
  );

  actions.push(
    <div className="flex flex-col w-full">
      <FormInputLabel
        label="Extra Highlighting"
        tip="Add a range of line numbers for extra highlighting. This is useful for bringing focus to sections of code."
      />

      <FormInputList
        className="w-full flex flex-col mt-3"
        addText="Add highlight range"
        fields={highlightRange}
        properties={[
          {
            type: 'number',
            name: 'from',
            placeholder: 1,
            divider: 'to',
            extraProps: {
              min: 1,
            },
          },
          {
            type: 'number',
            name: 'to',
            placeholder: 5,
            divider: 'to',
            extraProps: {
              min: 1,
            },
          },
        ]}
        handleUpdate={(t, p, v) => {
          onChangeConfig(t, ['highlightRange'].concat(p), v);
        }}
      />
    </div>
  );

  let contentElem;
  if (isRef) {
    contentElem = (
      <Highlight
        id={id}
        content={data}
        mode={mode}
        lineNumbers={lineNumbers}
        highlightRange={highlightRange}
        immediate
      />
    );
  } else {
    contentElem = (
      <CodeEditor
        id={id}
        value={data}
        mode={mode}
        highlightRange={highlightRange}
        onChange={value => onChangeData('set', [], value)}
        maxLines={20}
        lint={false}
        // autogrow
        noFill
      />
    );
  }

  return <WrapperFactory {...props} headerActions={actions} contentElem={contentElem} />;
};

export default CodeBlock;
