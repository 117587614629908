import _ from 'lodash';
import { computed, action, reaction, observable } from 'mobx';

import { MODES as EDITOR_MODES } from '@core/editor';
import { safeStringify } from '@platform/utils/json';
import { alert } from '@platform/utils/alert';

import { oas3Extension } from './coreEditor';
import { SpectralRunner } from './spectral-worker/spectralRunner';
import entityEditorStore, { EntityEditor } from './entityEditorStore';
import HubWorker from './hub-worker/client';

class Oas3Editor extends EntityEditor {
  _extension = oas3Extension;
  SpectralRunner = new SpectralRunner();
  _hubWorker;

  @observable.ref
  _hubViewerSpec = {};

  @observable
  isBuildingHub = false;

  activate() {
    super.activate();

    this.registerHubWorker();

    if (_.has(this.rootStore.stores.routerStore.location.query, 'edit')) {
      this._editor.setActiveMode(EDITOR_MODES.code);
    }
  }

  @action
  registerHubWorker = () => {
    this._hubWorker = new HubWorker();
    this._disposables.push(this._hubWorker);

    this._hubWorker.on(
      'buildHubFromOAS',
      action(hub => {
        this._hubViewerSpec = hub;
        this.isBuildingHub = false;
      })
    );

    this._disposables.push({
      dispose: reaction(
        () => this.dereferencedParsed,
        action(spec => {
          if (!spec) return;

          const { name, exportUrl } = _.get(
            this.rootStore._stores.projectStore,
            'current.currentFile',
            {}
          );

          this.isBuildingHub = true;
          this._hubWorker.send('buildHubFromOAS', {
            spec,
            name,
            exportUrl,
          });
        }),
        {
          name: 'buildHubFromOAS',
          fireImmediately: true,
        }
      ),
    });
  };

  handleValidation = ({ target, env, strTarget, cb = _.noop }) => {
    const config = _.get(this.rootStore._stores.projectStore, 'current.lintFile.data', {});

    const callback = (validations = []) => {
      this._validations.dispose();

      if (this._activated) {
        for (const validation of validations) {
          // Definitions will always be unused since we dereference, so ignore that error
          this._validations.push(this._editor.addValidation(validation));
        }
      }

      cb();
    };

    this.SpectralRunner.run({
      id: this.id,
      target,
      config,
      env,
      spec: 'oas3',
      cb: callback,
    });
  };

  get currentPath() {
    return [];
  }

  @computed
  get hubViewerSpec() {
    // Don't need to return anything if we're not in view mode
    if (!this.isViewing) return {};

    return this._hubViewerSpec;
  }
}

export default class Oas3EditorStore extends entityEditorStore {
  editorClass = Oas3Editor;
}
