import _ from 'lodash';
import axios from 'axios';
import produce from 'immer';
import { observable, computed, action, reaction, flow } from 'mobx';

import shortid from '@platform/utils/shortid';
import GoogleAnalytics from '@platform/utils/googleAnalytics';
import { extractParams, replacePathParams, buildQueryString, urlSource } from '@platform/utils/url';
import {
  newScenario,
  newStep,
  cleanCollection,
  scenarioPathLocations,
  computeAllScenarios,
  flattenRefOutput,
  calculateCoverageReport,
  getConductorUrl,
  buildConductorUrl,
  createScenarioStepRequest,
} from '@platform/utils/collections';
import { filterSpec } from '@platform/utils/collections/filter';
import { computeSidebarTree } from '@platform/utils/collections/tree';
import { alert } from '@platform/utils/alert';
import { safeParse } from '@platform/utils/json';
import { fetchRef } from '@platform/utils/refProxy';
import { getConfigVar } from '@platform/utils/config';
import { buildExport } from '@platform/utils/commit';
import { extractVariables } from '@platform/utils/variables';
import { getEndpointsFromSpecs, createStepFromOperation } from '@platform/utils/specs/swagger';

import { COMMANDS as SCENARIOS_COMMANDS } from '@core/spec-scenarios';

import EditorWorker from './worker';
import { scenariosExtension } from './coreEditor';
import entityEditorStore, { EntityEditor } from './entityEditorStore';

class CollectionResult {
  id = '';
  type = 'collection';
  createdAt = null;

  @observable.ref
  data = {};
}

class ScenarioResult {
  id = '';
  type = 'scenario';
  createdAt = null;

  @observable.ref
  data = {};
}

class StepResult {
  id = '';
  type = 'http';
  createdAt = null;

  @observable.ref
  data = {};
}

export class CollectionEditor extends EntityEditor {
  _extension = scenariosExtension;

  @observable
  isFetchingConnectedSpecs = false;

  @observable
  collectionRunning = false;

  @observable
  scenariosRunning = observable.map({});

  @observable
  stepsRunning = observable.map({});

  @observable.ref
  collectionResult = {};

  @observable
  scenarioResults = [];

  @observable
  stepResults = [];

  @observable
  scenarioCtx = observable.map({});

  @observable
  stepPathParams = observable.map({});

  @observable
  scenarioTabs = {};

  @observable
  searchInput = '';

  @observable
  debouncedSearchInput = '';

  @observable
  allCodes = false;

  @observable
  bodyCoverage = false;

  @observable.shallow
  newScenario = [];

  @observable
  migrationNotes;

  @observable.ref
  connectedSpecs = [];

  isExecutingFactory = type => {
    return () => {
      const running = this.currentRunning;
      return running && running.type === type ? true : false;
    };
  };

  activate() {
    super.activate();

    if (this.rootStore.isClient) {
      this.registerCommands();
      this.registerReactions();
    }
  }

  registerCommands = () => {
    const canWrite = this.rootStore.stores.projectService.canUser({ action: 'push:project' });

    this._disposables.push(
      this._commandRegistry.registerHandler(SCENARIOS_COMMANDS.addScenario, {
        isVisible: () => {
          return canWrite;
        },
        execute: () => {
          this.addScenario();
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(SCENARIOS_COMMANDS.runCollection, {
        isActive: () => {
          return !this.currentRunning;
        },
        isExecuting: this.isExecutingFactory('collection'),
        execute: () => {
          this.runCollection();
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(SCENARIOS_COMMANDS.runScenario, {
        isActive: () => {
          return !this.currentRunning ? true : false;
        },
        isVisible: () => {
          return this.currentScenario.id ? true : false;
        },
        isExecuting: this.isExecutingFactory('scenario'),
        execute: () => {
          this.runScenario();
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(SCENARIOS_COMMANDS.runStep, {
        isActive: () => {
          return !this.currentRunning ? true : false;
        },
        isVisible: () => {
          return this.currentStep.id ? true : false;
        },
        isExecuting: this.isExecutingFactory('step'),
        execute: () => {
          this.runStep();
        },
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(SCENARIOS_COMMANDS.openConductor, {
        execute: () => {
          const conductorUrl = buildConductorUrl({
            fileId: _.get(this.rootStore.stores.projectStore, 'current.currentFileId'),
            docs: true,
            variables: this.currentPopulatedEnvVariables,
          });

          window.open(conductorUrl, '_blank');
        },
      })
    );
  };

  refreshCommands = () => {
    this._commandRegistry.refreshCommandState(SCENARIOS_COMMANDS.runCollection);
    this._commandRegistry.refreshCommandState(SCENARIOS_COMMANDS.runScenario);
    this._commandRegistry.refreshCommandState(SCENARIOS_COMMANDS.runStep);
  };

  @action
  registerReactions = () => {
    this._disposables.push({
      dispose: reaction(
        () => ({
          searchInput: this.searchInput,
        }),
        action(({ searchInput }) => {
          this.debouncedSearchInput = searchInput;
        }),
        {
          name: 'debouncedSearchInput',
          delay: 600,
        }
      ),
    });

    this._disposables.push({
      dispose: reaction(
        () => ({
          oas2Refs: this.oas2Refs,
        }),
        () => {
          this.fetchConnectedSpecs();
        },
        {
          name: 'oas2Refs',
          delay: 1000,
          fireImmediately: true,
        }
      ),
    });

    // enable/disable commands based on current route
    this._disposables.push({
      dispose: reaction(
        () => ({
          scenario: this.currentScenario,
          step: this.currentStep,
        }),
        this.refreshCommands,
        {
          fireImmediately: true,
        }
      ),
    });

    // enable/disable commands based on run state
    this._disposables.push({
      dispose: reaction(
        () => ({
          currentRunning: this.currentRunning,
        }),
        this.refreshCommands
      ),
    });
  };

  fetchConnectedSpecs = flow(function* fetchConnectedSpecs() {
    if (this.isFetchingConnectedSpecs) return;
    this.isFetchingConnectedSpecs = true;

    const projectId = _.get(this, 'rootStore.stores.projectStore.current.id');
    const ref = _.get(this, 'rootStore.stores.projectStore.current.currentRef');
    const file = _.get(this, 'rootStore.stores.projectStore.current.currentFilePath');

    const connectedSpecPromises = [];

    _.forEach(this.oas2Refs, url => {
      if (!url) return;

      let u = url;

      const source = urlSource({ u }) || {};
      if (source.source === 'internal' && u[0] === '.') {
        u = buildExport(
          _.trimStart(u, '.'),
          this.rootStore.stores.projectStore.current.id,
          this.rootStore.stores.projectStore.current.currentRef
        );
      }

      connectedSpecPromises.push(
        new Promise(async (resolve, reject) => {
          try {
            const res = await fetchRef({
              url: u,
            });

            const id = res.request.responseURL;
            const data = safeParse(res.data);

            const result = await EditorWorker.exec('resolve', {
              apiHost: getConfigVar('SL_API_HOST'),
              obj: data,
              id: projectId,
              ref,
              file,
            });

            resolve({
              id,
              name: _.get(result.resolved, 'info.title', id),
              data: result.resolved,
            });
          } catch (error) {
            console.error('error fetching connected spec', url, error);
          }
        })
      );
    });

    this.connectedSpecs = yield Promise.all(connectedSpecPromises);
    this.isFetchingConnectedSpecs = false;
  }).bind(this);

  @computed
  get connectedEndpoints() {
    return getEndpointsFromSpecs(this.connectedSpecs);
  }

  @computed
  get allCurrentVariables() {
    let vars = {};

    _.merge(
      vars,
      this.currentPopulatedEnvVariables,
      this.currentPopulatedCtxVariables,
      this.currentStepPathParams
    );

    return vars;
  }

  get localParsed() {
    const collection = cleanCollection({
      collection: this.parsed,
    });

    return {
      id: this.id,
      name: _.get(this.entity, 'name'),
      ...collection,
    };
  }

  cleanLocal(data) {
    return cleanCollection({ collection: safeParse(data) });
  }

  handleValidation({ target, env, strTarget, cb }) {
    // return validateCollection({
    //   collection: parsed,
    // }).then(data => {
    //   cb(data);
    // });
  }

  get _computeSidebarTree() {
    return computeSidebarTree;
  }

  get _filterSpec() {
    return filterSpec;
  }

  @computed
  get oas2Refs() {
    return _.uniq(_.get(this.parsed, 'settings.testing.oas2', []));
  }

  @computed
  get sidebarMeta() {
    const data = {};

    for (const r of this.scenarioResults) {
      const dp = r.path.join('/');

      const time = _.get(r, 'data.output.body.time');
      const failCount = _.get(r, 'data.output.body.failCount', 0);
      const passCount = _.get(r, 'data.output.body.passCount', 0);

      let icon = 'circle';
      let className = '';
      if (failCount) {
        className = 'c-negative';
      } else if (passCount || time) {
        className = 'c-positive';
      }

      data[dp] = [];

      if (time) {
        data[dp].push({
          id: 'time',
          name: `${time}ms`,
        });
      }

      if (passCount || failCount) {
        data[dp].push({
          id: 'pf',
          name: `${passCount}/${passCount + failCount}`,
        });
      }

      data[dp].push({
        id: 'r',
        icon,
        className,
      });
    }

    for (const r of this.stepResults) {
      const name = _.get(r, 'data.output.status') || 'n/a';

      let className = '';
      if (_.get(r, 'data.failCount')) {
        className = 'c-negative';
      } else if (_.get(r, 'data.passCount')) {
        className = 'c-positive';
      }

      data[r.path.join('/')] = [
        {
          id: 1,
          name,
          className,
        },
      ];
    }

    return data;
  }

  @computed
  get endpoints() {
    return _.sortBy(this.connectedEndpoints, 'path') || [];
  }

  @computed
  get allScenarios() {
    return computeAllScenarios({ collection: this.parsed });
  }

  @computed
  get stepResultsMap() {
    const results = {};
    for (const r of this.stepResults) {
      results[r.id] = r;
    }
    return results;
  }

  @computed
  get currentScenarioPath() {
    if (_.size(this.currentPath) < 2) {
      return null;
    }

    return this.currentPath.slice(0, 2);
  }

  @computed
  get currentScenarioId() {
    // dont want a scenarioID on the coverage page
    if (_.last(this.currentScenarioPath) === 'testing') {
      return;
    }
    return _.last(this.currentScenarioPath);
  }

  _currentScenario(parsed) {
    const currentScenarioPath = this.currentScenarioPath;
    if (!currentScenarioPath) {
      return {};
    }

    const scenario = _.get(parsed, currentScenarioPath, {});

    return {
      stage: currentScenarioPath[0],
      id: currentScenarioPath[1],
      key: currentScenarioPath[1],
      path: currentScenarioPath,
      data: {
        ...scenario,
        id: currentScenarioPath[1],
      },
    };
  }

  @computed
  get currentScenario() {
    return this._currentScenario(this.parsed);
  }

  @computed
  get currentDereferencedScenario() {
    return this._currentScenario(this.dereferencedParsed);
  }

  @computed
  get currentStepPath() {
    if (_.size(this.currentPath) < 4) {
      return null;
    }

    return this.currentPath.slice(0, 4);
  }

  @computed
  get currentStepId() {
    return _.get(this.currentStep, 'id');
  }

  _currentStep(parsed) {
    const currentStepPath = this.currentStepPath;
    if (!currentStepPath) {
      return {};
    }

    const step = _.get(parsed, currentStepPath, {});

    return {
      index: _.last(currentStepPath),
      id: _.last(currentStepPath),
      data: step,
      path: currentStepPath,
    };
  }

  @computed
  get currentStep() {
    return this._currentStep(this.parsed);
  }

  @computed
  get currentDereferencedStep() {
    return this._currentStep(this.dereferencedParsed);
  }

  /*
   * STATS
   */

  @computed
  get currentCollectionStats() {
    const items = [];

    const data = _.get(this.collectionResult, 'data.output.body');
    if (_.isEmpty(data)) {
      return items;
    }

    items.push({
      id: 'assertions',
      label: 'assertions',
      value: data.passCount + data.failCount,
    });

    items.push({
      id: 'passed',
      label: 'passed',
      value: data.passCount,
      color: data.passCount > 0 ? 'green' : null,
    });

    items.push({
      id: 'failed',
      label: 'failed',
      value: data.failCount,
      color: data.failCount > 0 ? 'red' : null,
    });

    items.push({
      id: 'time',
      label: 'time taken',
      value: `${_.round(data.time / 1000, 2)}s`,
    });

    let slowest = null;

    const scenarios = data.scenarios;
    _.forEach(scenarios, (scenario, scenarioId) => {
      if (scenario && (!slowest || scenario.time > slowest.time)) {
        slowest = { scenarioId, name: scenario.name, time: scenario.time };
      }
    });

    const slowestItem = {
      id: 'slowest',
      label: 'slowest scenario',
      value: slowest ? `${_.round(slowest.time / 1000, 2)}s` : 'n/a',
    };

    if (slowest) {
      slowestItem.title = slowest.name;
      slowestItem.className = 'is-link';
      slowestItem.onClick = () => {
        this.goToPath(['scenarios', slowest.scenarioId]);
      };
    }

    items.push(slowestItem);

    return items;
  }

  @computed
  get currentScenarioStats() {
    const items = [];

    // only calc stats if looking at a scenario
    if (!this.currentScenarioId || this.currentStepId) {
      return items;
    }

    const data = _.get(this.currentResult, 'data.output.body');
    if (_.isEmpty(data)) {
      return items;
    }

    items.push({
      label: 'assertions',
      value: data.passCount + data.failCount,
    });

    items.push({
      label: 'passed',
      value: data.passCount,
      color: data.passCount > 0 ? 'green' : null,
    });

    items.push({
      label: 'failed',
      value: data.failCount,
      color: data.failCount > 0 ? 'red' : null,
    });

    items.push({
      label: 'time taken',
      value: `${_.round(data.time / 1000, 2)}s`,
    });

    let slowest = null;

    const steps = data.steps || [];
    _.forEach(steps, (step, stepId) => {
      if (!slowest || step.time > slowest.time) {
        slowest = { stepId, name: step.name, time: step.time };
      }
    });

    const slowestItem = {
      label: 'slowest step',
      value: slowest ? `${_.round(slowest.time / 1000, 2)}s` : 'n/a',
    };

    if (slowest) {
      slowestItem.title = slowest.name;
      slowestItem.className = 'is-link';
      slowestItem.onClick = () => {
        this.goToPath(['scenarios', this.currentScenarioId, 'steps', slowest.stepId]);
      };
    }

    items.push(slowestItem);

    return items;
  }

  /*
   * ENV
   */

  @computed
  get currentEnvVariables() {
    const target =
      _.get(this.currentStep, 'data') || _.get(this.currentScenario, 'data') || this.parsed || {};

    // return unique $$.env values, stripped of the $$.env
    return _.compact(
      _.uniq(
        _.map(extractVariables(target), v => {
          if (!_.includes(v, '$$.env')) {
            return null;
          }

          return v.replace(/\$\$\.env[.]*|{|}/g, '');
        })
      )
    );
  }

  @computed
  get currentPopulatedEnvVariables() {
    const variables = this.currentEnvVariables || {};
    const populatedVariables = {};

    _.forEach(variables, v => {
      populatedVariables[v] = _.get(this.currentEnv, v);
    });

    return populatedVariables;
  }

  @action
  addScenario(s, { path = ['scenarios'] } = {}) {
    const scenario = s || newScenario();
    const newId = shortid({ length: 5, lowercase: true });

    const newPath = path.concat([newId]);

    // update spec immediately, so that it's written to file (if in filemode) before we route
    // to the editor
    this.updateParsed('set', newPath, scenario, { immediate: true });
    this.goToPath(newPath);

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'add',
      eventLabel: `Add Scenario - ${window.Electron ? 'Desktop' : 'Web'}`,
    });

    return scenario;
  }

  // path should be a scenario path
  @action
  addStep({ path, afterIndex } = {}, { navigate } = {}) {
    const step = newStep();
    const targetPath = path || this.currentScenarioPath;
    let newSteps = _.get(this.parsed, [...targetPath, 'steps'], []);

    if (!_.isUndefined(afterIndex)) {
      this.updateParsed('splice', [...targetPath, 'steps'], step, {
        splice: { index: afterIndex + 1 },
      });
    } else {
      this.updateParsed('push', [...targetPath, 'steps'], step, { immediate: true });
    }

    newSteps = _.get(this.parsed, [...targetPath, 'steps'], []);

    if (navigate) {
      const newIndex = _.isUndefined(afterIndex) ? _.size(newSteps) - 1 : afterIndex + 1;
      this.goToPath([...targetPath, 'steps', newIndex]);
    }

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'add',
      eventLabel: `Add Step - ${window.Electron ? 'Desktop' : 'Web'}`,
    });

    return step;
  }

  // path should be to an existing step
  @action
  cloneStep({ path = [] } = {}, { navigate } = {}) {
    if (_.size(path) !== 4) {
      return;
    }

    const step = _.get(this.parsed, path);
    if (!step) {
      return;
    }

    const copiedStep = _.cloneDeep(step);
    if (!_.isEmpty(copiedStep.name)) {
      copiedStep.name = `${copiedStep.name} COPY`;
    }

    const stepsPath = path.slice(0, 3);

    this.updateParsed('push', stepsPath, copiedStep, {
      immediate: true,
    });

    if (navigate) {
      const newIndex = _.size(_.get(this.parsed, stepsPath)) - 1;
      this.goToPath([...stepsPath, newIndex]);
    }

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'add',
      eventLabel: `Clone Step - ${window.Electron ? 'Desktop' : 'Web'}`,
    });

    return copiedStep;
  }

  // path should be to an existing scenario
  @action
  cloneScenario({ path } = {}, { navigate } = {}) {
    const targetPath = path || this.currentScenarioPath;
    const scenario = _.get(this.parsed, targetPath);

    if (!scenario || _.isEmpty(scenario)) {
      return;
    }

    const copiedScenario = _.cloneDeep(scenario);
    const newId = shortid({ length: 5, lowercase: true });
    if (!_.isEmpty(copiedScenario.name)) {
      copiedScenario.name = `${copiedScenario.name} COPY`;
    }

    const newPath = targetPath;
    newPath[newPath.length - 1] = newId;
    this.updateParsed('set', newPath, copiedScenario, { immediate: true });

    if (navigate) {
      this.goToPath(newPath);
    }

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'add',
      eventLabel: `Copy Scenario - ${window.Electron ? 'Desktop' : 'Web'}`,
    });

    return copiedScenario;
  }

  @action
  removeStep(index) {
    const stepIndex = typeof index === 'undefined' ? this.currentStep.index : index;
    this.updateParsed('pull', [...this.currentScenarioPath, 'steps'], stepIndex, {
      immediate: true,
    });

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'remove',
      eventLabel: `Remove Step - ${window.Electron ? 'Desktop' : 'Web'}`,
    });
  }

  @action
  removeScenario(path) {
    this.updateParsed('unset', path || this.currentScenarioPath, null, { immediate: true });

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'remove',
      eventLabel: `Remove Scenario - ${window.Electron ? 'Desktop' : 'Web'}`,
    });
  }

  // stage is optional - if not provided, all scenarios will be sorted
  @action
  sortScenarios(stage) {
    let stages = scenarioPathLocations;
    if (stage) {
      stages = [stage];
    }

    for (const k of stages) {
      const targetScenarios = _.map(_.get(this.parsed, k, {}), (s, k) => ({
        id: k,
        ...s,
      }));

      const scenarios = _.sortBy(targetScenarios, [s => _.toLower(s.name), s => s.id]);
      const sortedScenarios = {};
      _.forEach(scenarios, s => {
        sortedScenarios[s.id] = s;
        delete sortedScenarios[s.id].id;
      });

      this.updateParsed('set', k, sortedScenarios, { immediate: true });
    }

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'sort',
      eventLabel: `Sort Scenarios - ${window.Electron ? 'Desktop' : 'Web'}`,
    });
  }

  reorderSteps(fromIndex, toIndex) {
    const scenario = this.currentScenario;

    if (!scenario.data) {
      return;
    }

    this.updateParsed(
      'move',
      [...this.currentScenarioPath, 'steps', fromIndex],
      [...this.currentScenarioPath, 'steps', toIndex]
    );
  }

  @computed
  get currentResult() {
    const currentStep = this.currentStep;
    if (currentStep.data) {
      return _.find(this.stepResults, {
        id: `${this.currentScenarioId}-${this.currentStepId}`,
      });
    }

    const currentScenario = this.currentScenario;
    if (currentScenario.data) {
      return _.find(this.scenarioResults, { id: currentScenario.data.id });
    }

    return this.collectionResult;
  }

  @computed
  get flattenedStepResults() {
    let steps = [];

    const scenariosObj = _.get(this.collectionResult, 'data.output.body.scenarios', {});
    _.forEach(scenariosObj, scenario => {
      steps = steps.concat(flattenRefOutput(_.get(scenario, 'steps', [])));
    });

    return steps;
  }

  @computed
  get currentRunning() {
    if (this.collectionRunning) {
      return {
        type: 'collection',
      };
    }

    if (this.currentScenarioId) {
      const runningScenario = this.scenariosRunning.get(this.currentScenarioId);
      if (runningScenario) {
        return {
          type: 'scenario',
        };
      }
    }

    if (this.currentStepId) {
      const runningStep = this.stepsRunning.get(`${this.currentScenarioId}-${this.currentStepId}`);
      if (runningStep) {
        return {
          type: 'step',
        };
      }
    }

    return null;
  }

  @action
  resetStepResults() {
    this.stepResults = [];
  }

  @action
  addCollectionResult(collection, result) {
    const newResult = new CollectionResult();
    newResult.id = collection.id;
    newResult.createdAt = new Date();
    newResult.data = {
      output: {
        status: 200,
        body: result,
      },
    };
    this.collectionResult = newResult;

    for (const path of scenarioPathLocations) {
      const target = _.get(collection, path, {});

      _.forEach(target, (scenario, id) => {
        const scenarioResult = _.get(result, [...path.split('.'), id]);
        if (scenarioResult) {
          this.addScenarioResult(
            {
              stage: path,
              id,
              key: id,
              path: [path, id],
              data: {
                id,
                ...scenario,
              },
            },
            scenarioResult
          );
        }
      });
    }
  }

  @action
  addScenarioResult(scenario, result) {
    const newResult = new ScenarioResult();
    newResult.id = scenario.id;
    newResult.createdAt = new Date();
    newResult.path = scenario.path;
    newResult.data = {
      output: {
        ..._.get(result, 'response', {}),
        body: result,
      },
    };

    const existingIndex = _.findIndex(this.scenarioResults, {
      id: scenario.id,
    });
    if (existingIndex >= 0) {
      this.scenarioResults[existingIndex] = newResult;
    } else {
      this.scenarioResults.push(newResult);
    }

    const steps = _.get(scenario, 'data.steps', []);
    _.forEach(steps, (s, stepIndex) => {
      const step = {
        index: stepIndex,
        id: stepIndex,
        data: steps[stepIndex],
        path: scenario.path.concat(['steps', stepIndex]),
      };

      const stepResult = _.get(result, ['steps', stepIndex]);
      if (stepResult) {
        this.addStepResult(scenario, step, stepResult);
      }
    });
  }

  @action
  addStepResult(scenario, step, result) {
    const resultId = `${scenario.id}-${step.id}`;

    const newResult = new StepResult();
    newResult.id = resultId;
    newResult.createdAt = new Date();
    newResult.type = step.data.type;
    newResult.data = result;
    newResult.path = step.path;

    const existingIndex = _.findIndex(this.stepResults, { id: resultId });
    if (existingIndex >= 0) {
      this.stepResults[existingIndex] = newResult;
    } else {
      this.stepResults.push(newResult);
    }
  }

  @action
  handleRunError(err) {
    if (axios.isCancel(err)) {
      alert.warning(err.message);
    } else {
      const data = _.get(err, 'response.data');
      if (data) {
        const collection = this.replaceCollectionPathParams(this.parsed);
        this.addCollectionResult(collection, {
          ...data,
        });
        alert.error('An error occurred while running. Check the collection result tab.');
      } else {
        if (err.response) {
          this.error = err.response;
        } else {
          alert.error(
            'The API did not return a response. Is it running and accessible? If you are sending this request from a web browser, does the API support cors?'
          );
        }
      }
    }
  }

  @action
  runCollection() {
    const collection = this.replaceCollectionPathParams(this.dereferencedParsed);
    const env = this.currentEnv;

    this.clearError();

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'run',
      eventLabel: `Run Collection - ${window.Electron ? 'Desktop' : 'Web'}`,
      eventValue: _.size(collection.scenarios),
    });

    const now = new Date().getTime();
    const cancelToken = axios.CancelToken;
    const canceler = cancelToken.source();

    axios
      .request({
        method: 'post',
        url: `${getConductorUrl()}/run`,
        headers: {
          'Session-Cookie': _.get(this.rootStore.stores, 'userService.sessionCookie'),
        },
        data: {
          env,
          apiSecret: _.get(this.rootStore.stores, 'userService.token'),
          collection,
          specs: _.map(this.connectedSpecs, 'data'),
        },
        cancelToken: canceler.token,
        withCredentials: this.rootStore.isClient,
      })
      .then(
        action(res => {
          this.collectionRunning = false;

          // add time since we don't track in prism yet
          const timeTaken = new Date().getTime() - now;
          this.addCollectionResult(collection, {
            time: timeTaken,
            ...res.data,
          });

          // update env
          this.updateVariables(_.get(res, ['data', 'env'], {}));

          // update ctx
          const scenariosPath = ['data', 'scenarios'];
          const scenarios = _.get(res, scenariosPath, {});

          for (const scenarioKey in scenarios) {
            if (scenarios.hasOwnProperty(scenarioKey)) {
              const scenarioPath = scenariosPath.concat([scenarioKey]);
              const stepsPath = scenarioPath.concat(['steps']);
              const scenario = _.get(res, scenarioPath, {});

              _.forEach(_.get(res, stepsPath, []), (step, index) => {
                this.updateScenarioCtx(
                  scenario.id,
                  _.get(res, stepsPath.concat([index, 'ctx']), {})
                );
              });
            }
          }
        })
      )
      .catch(
        action(err => {
          this.collectionRunning = false;
          this.handleRunError(err);
        })
      );

    this.collectionRunning = canceler;
  }

  @action
  stopCollection() {
    const req = this.collectionRunning;
    if (!req) {
      alert.error('Collection is not currently running.');
      return;
    }

    req.cancel('Collection canceled by user.');
  }

  @action
  runScenario(path, { spec } = {}) {
    const scenarioPath = path || this.currentScenarioPath;
    let scenario = _.get(this.dereferencedParsed, scenarioPath);

    if (!scenario) {
      console.warn('runScenario: scenario not found, and there is no active scenario.');
      return;
    }

    const collection = produce(this.dereferencedParsed, draft => {
      _.set(draft, scenarioPath, this.replaceScenarioPathParams(scenario, scenarioPath[1]));
    });

    scenario = {
      stage: scenarioPath[0],
      id: scenarioPath[1],
      key: scenarioPath[1],
      path: scenarioPath,
      data: {
        id: scenarioPath[1],
        ...scenario,
      },
    };

    const env = this.currentEnv;

    this.clearError();

    // open up the results tab, close the settings tab
    this.toggleCurrentScenarioTab('results');

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'run',
      eventLabel: `Run Scenario - ${window.Electron ? 'Desktop' : 'Web'}`,
      eventValue: _.size(scenario.data.steps),
    });

    const runMap = {
      [scenarioPath[0]]: {
        [scenarioPath[1]]: true,
      },
    };

    const cancelToken = axios.CancelToken;
    const canceler = cancelToken.source();
    axios
      .request({
        method: 'post',
        url: `${getConductorUrl()}/run`,
        headers: {
          'Session-Cookie': _.get(this.rootStore.stores, 'userService.sessionCookie'),
        },
        data: {
          env,
          apiSecret: _.get(this.rootStore.stores, 'userService.token'),
          collection,
          specs: spec ? [spec] : _.map(this.connectedSpecs, 'data'),
          runMap,
        },
        cancelToken: canceler.token,
        withCredentials: this.rootStore.isClient,
      })
      .then(
        action(res => {
          this.scenariosRunning.set(scenario.id, false);

          this.addScenarioResult(scenario, _.get(res.data, scenarioPath, {}));

          // update env
          this.updateVariables(_.get(res, ['data', 'env'], {}));

          // update ctx
          const stepsPath = ['data', ...scenarioPath, 'steps'];

          _.forEach(_.get(res, stepsPath, []), (step, index) => {
            this.updateScenarioCtx(scenario.id, _.get(res, stepsPath.concat([index, 'ctx']), {}));
          });
        })
      )
      .catch(
        action(err => {
          this.scenariosRunning.set(scenario.id, false);
          this.handleRunError(err);
        })
      );

    this.scenariosRunning.set(scenario.id, canceler);
  }

  @action
  stopScenario() {
    const req = this.scenariosRunning.get(this.currentScenarioId);
    if (!req) {
      alert.error('Scenario is not currently running.');
      return;
    }

    req.cancel('Scenario canceled by user.');
  }

  @action
  runStep = flow(function* runStep(index, { spec, headers } = {}) {
    const scenario = _.get(this.currentDereferencedScenario, 'data');
    if (!scenario) {
      return;
    }

    const scenarioPath = _.get(this.currentDereferencedScenario, 'path');

    let step;
    if (typeof index !== 'undefined') {
      step = {
        index,
        id: index,
        data: _.get(this.currentDereferencedScenario, ['data', 'steps', index]),
        path: [...scenarioPath, 'steps', index],
      };
    } else {
      step = this.currentDereferencedStep;
    }

    if (!step) {
      return;
    }

    let collection = produce(this.dereferencedParsed, draft => {
      _.set(
        draft,
        step.path,
        this.setQueryString(this.replaceStepPathParams(_.cloneDeep(step), scenario.id))
      );
    });

    // step will always be the first in the result because we're only running one
    let stepResultPath = scenarioPath.concat(['steps', 0]);

    // allow callee to add arbitrary, hidden headers (useful for embedded try it out like on instance editor)
    // instance editor needs to be able to add the auth'd users cookie as a step header
    if (headers) {
      collection = produce(collection, draft => {
        _.set(
          draft,
          [...step.path, 'input', 'headers'],
          Object.assign({}, headers, _.get(step, 'data.input.headers', {}))
        );
      });
    }

    const stepId = `${scenario.id}-${step.id}`;

    const ctx = this.scenarioCtx.get(scenario.id) || {};
    const env = this.currentEnv || {};

    // Don't allow skipping prism in the Scenario Editor
    const skipPrism = this.embedded && env.skipPrism;

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'run',
      eventLabel: `Run Step - ${window.Electron ? 'Desktop' : 'Web'}`,
    });

    const runMap = {
      [scenarioPath[0]]: {
        [scenarioPath[1]]: {
          [step.id]: true,
        },
      },
    };

    const stepPath = [scenarioPath[0], scenarioPath[1], 'steps', step.id];

    this.clearError();

    const cancelToken = axios.CancelToken;
    const canceler = cancelToken.source();
    const request = createScenarioStepRequest({
      env,
      ctx,
      runMap,
      stepPath,
      skipPrism,
      collection,
      cancelToken: canceler.token,
      withCredentials: this.rootStore.isClient,
      specs: spec ? [spec] : _.map(this.connectedSpecs, 'data'),
      sessionCookie: _.get(this.rootStore.stores, 'userService.sessionCookie'),
    });
    this.stepsRunning.set(stepId, canceler);

    let res;
    let err;
    try {
      res = yield axios.request(request);
    } catch (error) {
      err = error;
    }

    this.stepsRunning.set(stepId, false);

    if (err) {
      if (skipPrism) {
        if (axios.isCancel(err)) {
          alert.warning(err.message);
          return;
        }

        if (err.response) {
          res = err.response;
        } else {
          this.handleRunError(err);
          return;
        }
      } else {
        this.handleRunError(err);
        return;
      }
    }

    if (!res) return;

    let stepResult = _.get(res.data, stepResultPath) || {};

    if (skipPrism) {
      stepResult = {
        ...stepResult,
        output: {
          ...stepResult.output,
          status: res.status,
          headers: res.headers,
        },
      };
    }

    this.addStepResult(scenario, step, stepResult);

    // update env
    this.updateVariables(_.get(res, ['data', 'env'], {}));

    // update ctx
    this.updateScenarioCtx(scenario.id, _.get(res.data, [...stepResultPath, 'ctx'], {}), {
      overwrite: true,
    });
  });

  @action
  stopStep() {
    const scenarioId = _.get(this.currentScenario, 'id');
    if (!scenarioId) {
      return;
    }

    const stepId = this.currentStep.id;
    const key = `${scenarioId}-${stepId}`;
    const req = this.stepsRunning.get(key);
    if (!req) {
      alert.error('Step is not currently running.');
      return;
    }

    req.cancel('Step canceled by user.');
  }

  /*
   * CTX
   */

  @action
  updateScenarioCtx(scenarioId, ctx = {}, { overwrite } = {}) {
    if (overwrite) {
      this.scenarioCtx.set(scenarioId, ctx);
      return;
    }

    if (_.isEmpty(ctx)) {
      return;
    }

    let existing = this.scenarioCtx.get(scenarioId) || {};

    _.merge(existing, ctx);

    this.scenarioCtx.set(scenarioId, existing);
  }

  ctxVariables(step) {
    const target = _.merge({}, _.get(step, 'input', {}), _.get(step, 'after.assertions', {}));

    // return unique $.ctx values, stripped of the $.ctx
    return _.compact(
      _.uniq(
        _.map(extractVariables(target), v => {
          if (!_.includes(v, '$.ctx')) {
            return null;
          }

          return v.replace(/\$\.ctx[.]*|{|}/g, '');
        })
      )
    );
  }

  populatedCtxVariables(scenarioId, variables) {
    if (!scenarioId) {
      return {};
    }

    const currentVariables = this.scenarioCtx.get(scenarioId) || {};
    const populatedVariables = {};

    _.forEach(variables, v => {
      _.set(populatedVariables, v, _.get(currentVariables, v));
    });

    return populatedVariables;
  }

  @computed
  get currentCtxVariables() {
    return this.ctxVariables(_.get(this.currentStep, 'data', {}));
  }

  @computed
  get currentPopulatedCtxVariables() {
    return this.populatedCtxVariables(
      _.get(this.currentScenario, 'data.id'),
      this.currentCtxVariables
    );
  }

  @action
  updateCurrentCtxVariables(newVars) {
    const scenarioId = _.get(this.currentScenario, 'data.id');
    if (scenarioId) {
      this.updateScenarioCtx(scenarioId, safeParse(newVars));
    }
  }

  // Path params
  getStepPathParams(step, scenarioId) {
    const key = `${scenarioId}-${step.id}`;
    const existing = this.stepPathParams.get(key);

    const currentPathParams = {};

    const pathParams = extractParams(_.get(step, 'data.input.url', ''));
    for (let param of pathParams) {
      if (!/^\$\.ctx\..*/gi.test(param) && !/^\$\$\.env\..*/gi.test(param)) {
        currentPathParams[param] = _.get(existing, param);
      }
    }

    return currentPathParams;
  }

  @computed
  get currentStepPathParams() {
    return this.getStepPathParams(this.currentStep, this.currentScenario.id);
  }

  @action
  updateCurrentStepPathParams = newParams => {
    const scenarioId = _.get(this.currentScenario, 'id');
    const stepId = _.get(this.currentStep, 'id');
    const key = `${scenarioId}-${stepId}`;

    let existing = this.stepPathParams.get(key);

    if (existing) {
      this.stepPathParams.delete(key);
    } else {
      existing = {};
    }
    _.merge(existing, newParams);

    this.stepPathParams.set(key, existing);
  };

  replaceCollectionPathParams(collection) {
    return produce(collection, draft => {
      for (let sType of ['before', 'scenarios', 'after']) {
        _.keys(draft[sType]).forEach(scenarioKey => {
          _.set(
            draft,
            [sType, scenarioKey],
            this.replaceScenarioPathParams(draft[sType][scenarioKey], scenarioKey)
          );
        });
      }
    });
  }

  replaceScenarioPathParams(scenario, scenarioId) {
    if (!scenario) {
      return;
    }

    return produce(scenario, draft => {
      _.keys(scenario.steps).forEach(stepIndex => {
        const newStep = this.replaceStepPathParams(
          {
            index: stepIndex,
            id: stepIndex,
            data: draft.steps[stepIndex],
          },
          scenarioId
        );

        _.set(draft, ['steps', stepIndex], newStep);
      });
    });
  }

  replaceStepPathParams(step, scenarioId) {
    const pathParams = this.getStepPathParams(step, scenarioId);

    if (!_.isEmpty(pathParams)) {
      step.data = produce(step.data, draft => {
        _.set(
          draft,
          ['input', 'url'],
          replacePathParams(_.get(step, 'data.input.url'), pathParams)
        );
      });
    }

    return step.data;
  }

  /*
   * QUERY PARAMS
   */

  setQueryString(stepData) {
    let url = _.get(stepData, 'input.url');
    const params = _.get(stepData, 'input.query');

    const v = buildQueryString({ url, params });
    const newUrl = v.url;
    if (newUrl) {
      stepData = produce(stepData, draft => {
        _.set(draft, ['input', 'url'], newUrl);
      });
    }

    return stepData;
  }

  /*
   * ACCORDIONS
   */

  @computed
  get currentScenarioTab() {
    return this.scenarioTabs[this.currentScenarioId] || 'settings';
  }

  @action
  toggleScenarioTab(scenarioId, tab) {
    this.scenarioTabs = {
      ...this.scenarioTabs,
      [scenarioId]: tab,
    };
  }

  @action
  toggleCurrentScenarioTab(tab) {
    this.toggleScenarioTab(this.currentScenarioId, tab);
  }

  /*
   * COVERAGE REPORT
   */

  @computed
  get hasSearchInput() {
    return !_.isEmpty(this.debouncedSearchInput);
  }

  @computed
  get filteredEndpoints() {
    const endpoints = _.clone(this.endpoints);

    _.remove(endpoints, endpoint => {
      return _.split(endpoint.method, '-')[0].toUpperCase() === 'X';
    });

    if (this.hasSearchInput) {
      return _.filter(endpoints, endpoint => {
        const regex = new RegExp(_.escapeRegExp(this.debouncedSearchInput), 'gi');
        const summary = endpoint.summary || '';
        const method = endpoint.method || '';
        const path = endpoint.path || '';
        const specName = endpoint.specName || '';
        const url = `${method} ${path}`;

        return (
          regex.test(summary) ||
          regex.test(method) ||
          regex.test(path) ||
          regex.test(url) ||
          regex.test(specName)
        );
      });
    }

    return endpoints;
  }

  @computed
  get testCount() {
    if (this.allCodes) {
      return this.coverageReport.assertionsCount;
    }

    return this.coverageReport.basicAssertionsCount;
  }

  @computed
  get codes() {
    const codes = this.coverageReport.responseCodes || [];

    if (this.allCodes) {
      return codes;
    }

    return _.filter(codes, code => {
      return String(code).charAt(0) === '2';
    });
  }

  @computed
  get coveragePercent() {
    const path = ['coveragePercent'];

    if (this.bodyCoverage) {
      path.push('body');
    }

    if (this.allCodes) {
      path.push('all');
    } else {
      path.push('basic');
    }

    return _.get(this.coverageReport, path, 0);
  }

  @computed
  get coveragePercentColor() {
    if (this.coveragePercent < 50) {
      return 'red';
    } else if (this.coveragePercent < 75) {
      return 'yellow';
    }

    return 'green';
  }

  @computed
  get coverageReport() {
    return calculateCoverageReport({
      endpoints: this.filteredEndpoints,
      specs: this.connectedSpecs,
      results: this.flattenedStepResults,
      env: this.currentEnv,
    });
  }

  @action
  updateSearchInput(value) {
    this.searchInput = value;
  }

  @action
  toggleAllCodesCheckbox(checked) {
    this.allCodes = checked;
  }

  @action
  toggleBodyCoverageCheckbox(checked) {
    this.bodyCoverage = checked;
  }

  @action
  clearScenario() {
    this.newScenario = [];
  }

  @action
  createScenario(specList) {
    const steps = [];

    for (const operation of this.newScenario) {
      const spec = _.find(this.connectedSpecs, { id: operation.specId }) || {};
      spec.path = _.get(_.find(specList, { id: operation.specId }), 'path');

      const step = createStepFromOperation({
        ...operation,
        spec,
        pathParamsToCtx: true,
        paramsToCtx: true,
      });

      if (step) {
        steps.push(step);
      }
    }

    const scenario = newScenario({ steps });

    this.addScenario(scenario);

    GoogleAnalytics.track({
      eventCategory: 'Scenarios',
      eventAction: 'buildFromCoverage',
      eventLabel: `Build From Coverage - ${window.Electron ? 'Desktop' : 'Web'}`,
      eventValue: _.size(scenario.steps),
    });

    return scenario;
  }

  @action
  addEndpointToScenario({ endpoint, code }) {
    this.newScenario.push({ ...endpoint, code });
  }

  @action
  removeFromScenario(index) {
    this.newScenario.splice(index, 1);
  }

  @computed
  get scenarioVersion() {
    return _.get(this.parsed, 'scenarioVersion');
  }

  @action
  migrateCollection() {
    const collection = this.parsed;

    axios
      .request({
        method: 'post',
        url: `${getConfigVar('SL_API_HOST')}/orgs/${this.orgId}/collections/${
          this.id
        }/migrate/latest`,
        data: collection,
        withCredentials: this.rootStore.isClient,
      })
      .then(
        action(res => {
          const migrationResults = safeParse(res.body);

          if (migrationResults.collection) {
            this.updateParsed('set', [], migrationResults.collection, { immediate: true });
          }

          if (migrationResults.notes) {
            this.migrationNotes = migrationResults.notes;
          }
        })
      )
      .catch(err => {
        console.error('Error migrating collection', err);
      });
  }

  @action
  removeMigrationNotes() {
    this.migrationNotes = null;
  }

  fetchData() {
    super.fetchData();
    this.fetchConnectedSpecs();
  }
}

export default class CollectionEditorStore extends entityEditorStore {
  editorClass = CollectionEditor;

  filterParsed(parsed) {
    return cleanCollection({ collection: parsed });
  }

  @action
  updateCurrent(editorId, { scenarioId, stepId }) {
    const editorIndex = this.getEditorIndex({ id: editorId });
    if (editorIndex >= 0) {
      this.editors[editorIndex].currentScenarioId = scenarioId;
      this.editors[editorIndex].currentStepId = stepId;
      return this.editors[editorIndex];
    }

    return {};
  }
}
