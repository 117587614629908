import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import HtmlHead from '@platform/components/HtmlHead';

const ProjectHead = props => {
  const { projectService, projectStore } = props;

  const currentServiceObj = _.get(projectService, 'current', {});
  const currentStoreObj = _.get(projectStore, 'current', {});

  return (
    <HtmlHead
      title={[currentStoreObj.currentFileName, currentServiceObj.name]}
      description={currentServiceObj.description}
      image={currentServiceObj.avatar_url}
    />
  );
};

export default inject('projectService', 'projectStore')(observer(ProjectHead));
