import { observable, computed, action, reaction } from 'mobx';
import _ from 'lodash';

export class TableListEditor {
  scope = '';
  searchProps = ['name', 'email', 'username'];

  @observable
  searchInput = '';

  @observable
  debouncedSearchInput = '';

  @observable
  selected = [];

  @observable
  items = [];

  @action
  setupReactions() {
    reaction(
      () => ({
        searchInput: this.searchInput,
      }),
      action(({ searchInput }) => {
        if (searchInput.length < 3) {
          this.debouncedSearchInput = '';
          return;
        }

        this.debouncedSearchInput = searchInput;

        if (this.onSearch) {
          this.onSearch(searchInput);
        }
      }),
      {
        name: 'debouncedSearchInput',
        delay: 600,
      }
    );
  }

  constructor(props) {
    Object.assign(this, props);
    this.setupReactions();
  }

  @computed
  get hasSearchInput() {
    return !_.isEmpty(this.debouncedSearchInput);
  }

  @computed
  get filteredItems() {
    if (this.hasSearchInput) {
      const regex = new RegExp(_.escapeRegExp(this.debouncedSearchInput), 'i');
      const searchProps = this.searchProps;

      return _.filter(_.slice(this.items), item =>
        _.find(searchProps, prop => regex.test(_.get(item, prop)))
      );
    }

    return this.items;
  }

  @action
  updateSearchInput(value) {
    this.searchInput = value;
  }

  @action
  addSelected(value) {
    const newVal = value instanceof Array ? value : [value];
    this.selected = _.uniq(this.selected.concat(newVal));
  }

  @action
  removeSelected(value) {
    this.selected = _.without(this.selected, value);
  }

  @action
  resetSelected(value) {
    this.selected = [];
  }

  @action
  updateItems(items) {
    this.items = items;
  }
}

export default class TableListStore {
  tableListClass = TableListEditor;

  @observable
  tableLists = [];

  @observable
  currentTableListId;

  getTableList(query = {}) {
    return _.find(this.tableLists, query) || {};
  }

  getTableListIndex(query = {}) {
    return _.findIndex(this.tableLists, query);
  }

  @action
  initTableList(props = {}, { force } = {}) {
    const { scope } = props;

    this.currentTableListId = scope;

    const TableList = this.tableListClass;

    const currentIndex = this.getTableListIndex({ scope });
    if (currentIndex >= 0) {
      if (force) {
        this.tableLists[currentIndex] = new TableList(props);
      }

      return;
    }

    this.tableLists.push(new TableList(props));
  }
}
