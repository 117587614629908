export default () => ({
  stoplight: '1.0',
  environments: {
    default: {
      host: 'http://todos.stoplight.io',
      todosApiKey: 123,
    },
    staging: {},
    production: {},
  },
});
