import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faGavel } from '@fortawesome/pro-solid-svg-icons/faGavel';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faFlask } from '@fortawesome/pro-solid-svg-icons/faFlask';
import { faServer } from '@fortawesome/pro-solid-svg-icons/faServer';
import { faHeartbeat } from '@fortawesome/pro-solid-svg-icons/faHeartbeat';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faPuzzlePiece } from '@fortawesome/pro-solid-svg-icons/faPuzzlePiece';

import ErrorMessage from '@platform/components/ErrorMessage';

import { alert } from '@platform/utils/alert';
import { colors, Icon, Layout } from '@core/ui';

const interests = [
  {
    id: 'docs_external',
    name: 'External Docs',
    icon: faBook,
  },
  {
    id: 'modeling',
    name: 'Modeling',
    icon: faPuzzlePiece,
    subtitle: '(Swagger)',
  },
  {
    id: 'mocking',
    name: 'Mocking',
    icon: faServer,
  },
  {
    id: 'testing',
    name: 'Testing',
    icon: faFlask,
  },
  {
    id: 'docs_internal',
    name: 'Internal Docs',
    icon: faBook,
  },
  {
    id: 'monitoring',
    name: 'Monitoring',
    icon: faHeartbeat,
  },
  {
    id: 'collaboration',
    name: 'Collaboration',
    icon: faUsers,
  },
  {
    id: 'governance',
    name: 'Governance',
    icon: faGavel,
  },
];

const BigCheckbox = ({ checked, onClick, icon, name, className }) => {
  return (
    <div
      className={cn(
        className,
        'border-2 rounded-lg relative flex flex-col items-center justify-center cursor-pointer w-48 h-32',
        {
          'border-active': checked,
          'border-grey-lighter hover:border-grey': !checked,
        }
      )}
      onClick={onClick}
    >
      {icon && (
        <div className="mb-2 text-xl text-darken">
          <Icon icon={icon} />
        </div>
      )}

      <div className="text-darken-500 font-semibold">{name}</div>

      {checked && (
        <div
          className="rounded-full border-4 border-white bg-active text-white flex items-center justify-center absolute h-10 w-10"
          style={{ top: -13, right: -13 }}
        >
          <Icon icon={faCheck} />
        </div>
      )}
    </div>
  );
};

class UserInterests extends React.Component {
  componentWillMount() {
    this.updateUiUser(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateUiUser(nextProps);
  }

  updateUiUser = props => {
    const { ui, updateUi, original } = props;

    if (!ui.user && !_.isEmpty(original)) {
      updateUi('set', 'user', _.clone(original));
    }
  };

  render() {
    const { header, ui, updateUi, original = {}, userService, onSuccess } = this.props;

    const isSaving = ui.isSaving;
    const user = ui.user || {};
    const currentInterests = user.interests || [];
    const nextEnabled = !isSaving && user.interests && user.interests.length;

    return (
      <Layout
        header={header}
        footer={{
          right: {
            actions: [
              {
                children: isSaving ? 'Saving...' : 'Next',
                rightIcon: !isSaving && faArrowRight,
                color: nextEnabled ? colors.accent : colors.grey,
                disabled: !nextEnabled,
                onClick: e => {
                  e.preventDefault();
                  updateUi('set', 'error', null);
                  updateUi('set', 'isSaving', true);

                  const interests = _.compact(user.interests);
                  userService.updateAnalytics({ interests });

                  userService
                    .update(original.id, { interests: interests })
                    .then(() => {
                      return userService.getAuthorizedUser();
                    })
                    .then(res => {
                      updateUi('set', 'isSaving', false);

                      if (onSuccess) {
                        onSuccess();
                      }
                    })
                    .catch(err => {
                      alert.error('Error updating interests.');
                      updateUi('set', 'error', err);
                      updateUi('set', 'isSaving', false);
                    });
                },
              },
            ],
          },
        }}
        content={
          <div className="bg-white">
            <ErrorMessage error={ui.error} />

            <div className="p-12">
              <div className="font-semibold mb-3 text-darken-500">
                WHAT ARE YOUR PRIMARY 1-4 GOALS?
              </div>

              <div className="flex flex-wrap">
                {interests.map(interest => {
                  const index = currentInterests.indexOf(interest.id);
                  const checked = index >= 0;

                  return (
                    <BigCheckbox
                      key={interest.id}
                      checked={checked}
                      icon={interest.icon}
                      name={interest.name}
                      className="mr-6 mt-6"
                      onClick={() => {
                        if (checked) {
                          updateUi('pull', 'user.interests', index);
                        } else {
                          updateUi('push', 'user.interests', interest.id);
                        }
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

export default inject((stores, props) => {
  const { userService, appStore } = stores;

  return {
    userService,
    original: userService.authorizedUser,
    ...appStore.injectUi('UserInterests'),
  };
})(observer(UserInterests));
