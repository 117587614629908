import React from 'react';
import { Segment, Header, Form, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import { alert } from '@platform/utils/alert';

import FormFileButton from '@platform/components/FormFileButton';
import ErrorMessage from '@platform/components/ErrorMessage';
import { isStandardAuthDisabled } from '@platform/utils/config';

import UserProfileLayout from './_layout';

class UserSettings extends React.Component {
  componentWillMount() {
    this.updateUiUser(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateUiUser(nextProps);
  }

  user(props) {
    const { userService } = props || this.props;
    return _.get(userService, 'authorizedUser');
  }

  updateUiUser = props => {
    const { ui, updateUi } = props;
    const user = this.user(props);

    if (!ui.user && !_.isEmpty(user)) {
      updateUi('set', 'user', _.clone(user));
    }
  };

  hasChanged = (newObj, oldObj, props = []) => {
    return !_.isEqual(_.pick(oldObj, props), _.pick(newObj, props));
  };

  reset = () => {
    const { updateUi } = this.props;
    const user = this.user();

    updateUi('set', 'user', _.clone(user));
    updateUi('set', 'basicInfoError', null);
  };

  render() {
    const { ui, updateUi, userService, routerStore } = this.props;

    const user = ui.user || {};
    const original = this.user();

    let changePassword;
    if (!isStandardAuthDisabled()) {
      changePassword = (
        <Segment attached="bottom" loading={userService.isSaving}>
          <Header as="h3" className="mb-3" dividing>
            Change Password
          </Header>

          <ErrorMessage
            className="mb-6"
            error={ui.passwordError}
            onDismiss={() => updateUi('set', 'passwordError', null)}
          />

          <Form>
            <Form.Input
              label="New Password"
              name="password"
              type="password"
              value={ui.password || ''}
              onChange={(e, { value }) => {
                updateUi('set', 'password', value);
              }}
            />
            <Form.Input
              label="Confirm New Password"
              name="confirmPassword"
              type="password"
              value={ui.confirmPassword || ''}
              onChange={(e, { value }) => {
                updateUi('set', 'confirmPassword', value);
              }}
            />

            <div className="mt-6">
              <Button
                primary
                content="Change Password"
                disabled={_.isEmpty(ui.password) || _.isEmpty(ui.confirmPassword)}
                onClick={e => {
                  e.preventDefault();
                  updateUi('set', 'passwordError', null);

                  if (ui.password !== ui.confirmPassword) {
                    updateUi('set', 'passwordError', 'Passwords do not match.');
                    return;
                  }

                  userService
                    .update(original.id, { password: ui.password })
                    .then(() => userService.getAuthorizedUser())
                    .then(() => {
                      updateUi('set', 'password', '');
                      updateUi('set', 'confirmPassword', '');
                      alert.success('Password updated.');
                    })
                    .catch(err => {
                      updateUi('set', 'passwordError', err);
                    });
                }}
              />
            </div>
          </Form>
        </Segment>
      );
    }

    return (
      <UserProfileLayout activeTab="profile">
        <div className="mb-6">
          <Segment attached="bottom" loading={userService.isSaving}>
            <Header as="h3" className="mb-3" dividing>
              Basic Info
            </Header>

            <ErrorMessage
              className="mb-6"
              error={ui.basicInfoError}
              onDismiss={() => updateUi('set', 'basicInfoError', null)}
            />

            <Form>
              <Form.Input
                label="Name"
                name="name"
                placeholder="Name"
                value={_.get(user, 'name', '')}
                onChange={(e, { value }) => {
                  updateUi('set', 'user.name', value);
                }}
              />
              <Form.Input
                label="Email"
                name="email"
                placeholder="Email"
                value={_.get(user, 'email', '')}
                onChange={(e, { value }) => {
                  updateUi('set', 'user.email', _.trim(value));
                }}
              />
              <Form.Input
                label="Username"
                name="username"
                placeholder="Username"
                value={user.username || ''}
                onChange={(e, { value }) => {
                  updateUi('set', 'user.username', _.trim(value));
                }}
              />

              <div className="flex mt-6">
                <div className="mr-2">
                  <Button
                    content="Save"
                    primary
                    disabled={!this.hasChanged(user, original, ['username', 'email', 'name'])}
                    onClick={e => {
                      e.preventDefault();
                      updateUi('set', 'basicInfoError', null);

                      const oldObj = _.pick(original, ['username', 'email', 'name']);
                      const newObj = _.pick(user, ['username', 'email', 'name']);

                      userService
                        .update(original.id, {
                          ...oldObj,
                          ...newObj,
                        })
                        .then(() => {
                          return userService.getAuthorizedUser();
                        })
                        .then(res => {
                          if (!_.isEqual(oldObj.email, newObj.email)) {
                            alert.success(
                              'A confirmation email has been sent. Your new email will show on this page once it has been confirmed.'
                            );
                          } else {
                            alert.success('Profile updated.');
                          }

                          this.reset();
                        })
                        .catch(err => {
                          alert.error('Error updating profile.');
                          updateUi('set', 'basicInfoError', err);
                        });
                    }}
                  />
                </div>

                <div className="mr-2">
                  <Button
                    content="Reset"
                    disabled={!this.hasChanged(user, original, ['username', 'email', 'name'])}
                    onClick={e => {
                      e.preventDefault();
                      this.reset();
                    }}
                  />
                </div>
              </div>
            </Form>
          </Segment>
        </div>

        <div className="mb-6">
          <Segment attached="bottom" loading={userService.isSaving}>
            <Header as="h3" dividing>
              Profile Image
            </Header>

            <ErrorMessage
              className="mb-6"
              error={ui.imageError}
              onDismiss={() => updateUi('set', 'imageError', null)}
            />

            <div className="mt-4">
              <FormFileButton
                icon="file outline"
                name="Choose File"
                buttonProps={{
                  primary: true,
                  basic: true,
                }}
                onChange={e => {
                  e.preventDefault();
                  updateUi('set', 'imageError', null);

                  const file = _.get(e, 'target.files[0]');
                  userService
                    .updateImage(original.id, {
                      file,
                    })
                    .then(() => userService.getAuthorizedUser())
                    .then(res => {
                      alert.success('Profile image updated.');
                    })
                    .catch(err => {
                      alert.error('Error updating image.');
                      updateUi('set', 'imageError', err);
                    });
                }}
              />
            </div>
          </Segment>
        </div>
        {changePassword}
        <Segment attached="bottom" loading={userService.isSaving}>
          <Header as="h3" className="mb-3" dividing>
            Remove Account
          </Header>
          <p className="px-3">
            This will delete account, all its projects and organization if the user is the owner.
            Make sure to move ownership to another user to make it persist.
          </p>
          <Form>
            <div className="mt-6">
              <Button
                primary
                content="Remove Account"
                onClick={e => {
                  e.preventDefault();
                  const confirmed = confirm('Are you sure you want to remove this account?');
                  if (!confirmed) {
                    return;
                  }

                  userService.updateAuthorizedUser({});
                  userService
                    .remove(original.id)
                    .then(() => {
                      routerStore.push({ pathname: '/logout' });
                      alert.success('Account removed.');
                    })
                    .catch(err => {
                      alert.error('Error removing account.');
                    });
                }}
              />
            </div>
          </Form>
        </Segment>
      </UserProfileLayout>
    );
  }
}

export default inject((stores, props) => {
  const { userService, appStore, routerStore } = stores;

  return {
    userService,
    routerStore,
    ...appStore.injectUi('UserSettings'),
  };
})(observer(UserSettings));
