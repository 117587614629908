import React from 'react';
import { Modal, Message, Icon, Button, Checkbox } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { getConfigVar } from '@platform/utils/config';

const AppAbout = ({ appStore, electronStore }) => {
  let title;
  if (getConfigVar('ENTERPRISE')) {
    title = 'stoplight enterprise';
  } else {
    title = 'stoplight';
  }

  let desktopVersion;
  if (electronStore.enabled) {
    desktopVersion = (
      <div className="AppAbout-version pb-3">
        Desktop v
        {electronStore.enabled && typeof window !== 'undefined'
          ? _.get(electronStore, 'Electron.env.APP_VERSION')
          : '0'}
        <span className="pl-3">
          {electronStore.enabled && typeof window !== 'undefined'
            ? _.get(electronStore, 'Electron.env.ARCH', 'x')
            : '...'}
        </span>
        <a
          className="ml-6"
          href="https://github.com/stoplightio/desktop/releases/latest"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="code" /> Releases
        </a>
      </div>
    );
  }

  let versionBadge;
  if (getConfigVar('ENTERPRISE')) {
    versionBadge = (
      <div>
        <div className="AppAbout-version">
          v{getConfigVar('ENTERPRISE_VERSION')}
          <span className="pl-3">{getConfigVar('ENTERPRISE_RELEASE_CHANNEL')}</span>
        </div>

        <div className="AppAbout-version pt-3">{getConfigVar('ENTERPRISE_ORG_NAME')}</div>
      </div>
    );
  } else if (!desktopVersion && getConfigVar('APP_VERSION')) {
    versionBadge = (
      <div>
        <div className="AppAbout-version">Core v{getConfigVar('APP_VERSION')}</div>
      </div>
    );
  }

  let versionElem;
  if (electronStore.enabled) {
    if (electronStore.updateDownloaded) {
      versionElem = (
        <div>
          <Icon name="gift" color="green" />v{electronStore.updateInfo.version} downloaded! Restart
          Stoplight to install.
        </div>
      );
    } else if (electronStore.checkingForUpdate) {
      versionElem = (
        <div>
          <Icon loading name="spinner" />
          Checking for updates...
        </div>
      );
    } else if (electronStore.manualUpdateRequired) {
      versionElem = (
        <div className="pr-6">
          <Icon name="exclamation circle" color="orange" />A manual update is required. Please
          re-install the{' '}
          <a
            href="https://github.com/stoplightio/desktop/releases/latest"
            target="_blank"
            rel="noopener noreferrer"
          >
            latest version
          </a>
          .<br />
          <br />
          This occurs when we make a change that cannot be distributed by our auto update system.
          This is usually a one time thing :).
        </div>
      );
    } else if (_.get(electronStore, 'Electron.env.RUN_CONTEXT') === 'linux') {
      versionElem = (
        <div>
          Linux does not support auto updates. Please click "Releases" above to check for a new
          release.
        </div>
      );
    } else if (electronStore.updateError) {
      versionElem = (
        <div className="pr-6">
          <Icon name="remove" color="red" />
          There was an error. If this persists, you can manually download the{' '}
          <a
            href="https://github.com/stoplightio/desktop/releases/latest"
            target="_blank"
            rel="noopener noreferrer"
          >
            latest version
          </a>
          .<br />
          <br />
          <pre>
            <code>{JSON.stringify(electronStore.updateError, null, 4)}</code>
          </pre>
        </div>
      );
    } else if (electronStore.updateInfo) {
      versionElem = (
        <div>
          <Icon name="gift" color="purple" /> {electronStore.updateInfo.releaseName} is available!
        </div>
      );
    } else {
      versionElem = (
        <div>
          <Icon name="check" color="green" />
          Stoplight is up to date!
        </div>
      );
    }
  } else {
    versionElem = (
      <div>
        <Icon name="desktop" color="green" />
        Check out our desktop app!{' '}
        <a
          href="https://github.com/stoplightio/desktop/releases/latest"
          target="_blank"
          rel="noopener noreferrer"
        >
          download it here
        </a>
      </div>
    );
  }

  let downloadBox;
  if (electronStore.enabled) {
    let updateInfoElem = [];
    if (electronStore.updateInfo && electronStore.updateInfo.releaseNotes) {
      for (const release of electronStore.updateInfo.releaseNotes) {
        updateInfoElem.push(
          <div key={release.version} className="mt-4 pt-4 border-t border-grey-light">
            <div>
              <b>v{release.version}</b>
            </div>
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: release.note.replace('<a ', '<a target="_blank" '),
              }}
            />
          </div>
        );
      }
    }

    let downloadText = 'Check Now';
    if (electronStore.updateDownloadProgress) {
      downloadText = `Downloading... ${Math.round(electronStore.updateDownloadProgress.percent)}%`;
    } else if (electronStore.updateDownloaded) {
      downloadText = 'Install + Restart';
    } else if (electronStore.updateAvailable) {
      downloadText = 'Download Now';
    }

    downloadBox = (
      <div className="AppAbout-updateStatus">
        <Message>
          <div className="flex items-center">
            <div className="flex-1">{versionElem}</div>

            {!electronStore.manualUpdateRequired &&
            _.get(electronStore, 'Electron.env.RUN_CONTEXT') !== 'linux' ? (
              <div className="pl-6">
                <Button
                  primary={electronStore.updateDownloaded || electronStore.updateAvailable}
                  content={downloadText}
                  disabled={electronStore.checkingForUpdate || electronStore.updateDownloadProgress}
                  onClick={() => {
                    if (electronStore.updateDownloaded) {
                      electronStore.installUpdate();
                    } else if (electronStore.updateAvailable) {
                      electronStore.downloadUpdate();
                    } else {
                      electronStore.checkForUpdates();
                    }
                  }}
                  basic
                />
              </div>
            ) : null}
          </div>

          <div className="AppAbout-updateInfo">{updateInfoElem}</div>
        </Message>
      </div>
    );
  }

  return (
    <Modal
      size="small"
      closeIcon="close"
      open={appStore.activeModal === 'AppAbout'}
      onClose={() => {
        appStore.closeModal();
      }}
    >
      <div className="AppAbout">
        <div className="AppAbout-header flex flex-col justify-center">
          <div>
            <img src="/images/mark-light-bg.png" />
          </div>
          <div>{title}</div>
        </div>

        {desktopVersion}
        {versionBadge}
        {downloadBox}

        <div className="AppAbout-downloadUpdates d-n">
          <Checkbox label="Automatically download updates" />
        </div>
      </div>
    </Modal>
  );
};

export default inject('appStore', 'electronStore')(observer(AppAbout));
