import React from 'react';
import { inject, observer } from 'mobx-react';

import LearnMore from '@platform/components/LearnMore';
import MarkdownViewer from '@platform/components/MarkdownViewer';

const ReleaseNotes = props => {
  const { projectStore, versionService } = props;
  const { currentRef, currentVersion } = projectStore.current || {};
  const isReleased = versionService.isReleased(currentRef);

  return (
    <div className="px-3 py-3">
      Release Notes for <strong>v{currentVersion}</strong>:
      <MarkdownViewer className="py-4 text-sm" value={isReleased.message || 'No release notes'} />
      <div className="flex items-center">
        <LearnMore className="ml-a pl-2 text-sm" feature="releasing" />
      </div>
    </div>
  );
};

export default inject('projectStore', 'versionService')(observer(ReleaseNotes));
