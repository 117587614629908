import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';

import FormDropdown from '@platform/components/FormDropdown';

import { checkIsWhite } from '@platform/utils/colors';
import { isExternalLink } from '@platform/utils/url';
import { versionSortDesc } from '@platform/utils/versions';

import { Icon } from '@core/ui';

import HubHeaderItem from '../HubHeaderItem';
import HubSearch from '../HubSearch';

const HeaderSection = props => {
  const { items, className, activeItemPath, buildPath, isDark, children } = props;

  return (
    <div className={cn('HubHeader-section flex items-center', className)}>
      {_.map(items, (item, i) => {
        return (
          <HubHeaderItem
            key={i}
            item={item}
            to={buildPath(item.path)}
            isActive={item.path === activeItemPath}
            isDark={isDark}
          />
        );
      })}

      {children}
    </div>
  );
};

const VersionSelector = props => {
  const { versions, currentVersion, isDark, buildPath } = props;

  if (!currentVersion || !versions || versions.length < 2) return null;

  return (
    <FormDropdown
      className={cn('HubHeader-version-selector text-center text-sm font-bold', {
        'text-white': isDark,
      })}
      options={_.map(_.sortBy(versions, versionSortDesc), version => {
        return {
          text: `v${version.name}`,
          value: version.name,
        };
      })}
      value={currentVersion}
      onChange={(e, { value }) => {
        if (currentVersion === value) return;

        const version = _.find(versions, { name: value }) || {};
        if (version) {
          if (version.path) {
            const axios = _.get(window, '__SL.rootStore.axios');
            if (axios) {
              // strip version from current path
              const checkPath = window.location.pathname.replace(
                _.find(versions, { name: currentVersion }).path,
                ''
              );

              axios
                .get(buildPath(`${version.path}/${checkPath}`))
                .then(() => {
                  // try to retain path, if it exists
                  window.location.pathname = buildPath(`${version.path}/${checkPath}`);
                })
                .catch(() => {
                  window.location.pathname = buildPath(version.path || '/');
                });
            } else {
              window.location.pathname = buildPath(version.path || '/');
            }
          } else if (version.onClick) {
            version.onClick();
          }
        }
      }}
      selectOnBlur={false}
      selection
    />
  );
};

const DesktopHubHeader = props => {
  const {
    activeItemPath,
    currentVersion,
    includeSearch,
    buildPath,
    location,
    versions,
    isDark,
    nav,
  } = props;

  return (
    <div className="HubHeader-inner sm:hidden flex items-center h-24">
      <HeaderSection
        activeItemPath={activeItemPath}
        className="flex-grow is-left"
        buildPath={buildPath}
        location={location}
        items={nav.left}
        isDark={isDark}
      >
        <VersionSelector
          currentVersion={currentVersion}
          buildPath={buildPath}
          versions={versions}
          isDark={isDark}
        />
      </HeaderSection>

      <HeaderSection
        className="justify-end is-right flex-grow relative"
        activeItemPath={activeItemPath}
        buildPath={buildPath}
        location={location}
        items={nav.right}
        isDark={isDark}
      >
        {includeSearch && (
          <div className="HubHeader-search h-24 flex items-center">
            <HubSearch
              id="header"
              noResultsMessage={typeof includeSearch === 'string' ? includeSearch : undefined}
            />
          </div>
        )}
      </HeaderSection>
    </div>
  );
};

const MobileHubIcon = props => {
  const { isLarge, isDark, icon, onClick } = props;

  return (
    <div
      className={cn(
        'HubHeaderMobile-sidebar-toggle h-16 w-16 flex items-center justify-center',
        isLarge ? 'text-2xl' : 'text-lg',
        isDark ? 'is-dark text-white' : 'is-light'
      )}
      onClick={onClick}
    >
      {icon && <Icon icon={icon} />}
    </div>
  );
};

let MobileHubHeader = props => {
  const {
    activeItemPath,
    includeSearch,
    hasSidebar,
    buildPath,
    updateUi,
    isDark,
    nav = {},
    ui,
  } = props;

  const mobileDropdown = _.map(_.compact(_.concat(nav.left, nav.right)), (item, index) => {
    return {
      key: index,
      value: item.path,
      text: item.title || (item.path === '/' ? 'Home' : item.path),
      content: <HubHeaderItem key={index} item={item} to={buildPath(item.path)} />,
    };
  });

  return (
    <div className="HubHeader-inner relative hidden sm:flex items-center h-16 p-0">
      <MobileHubIcon
        onClick={() => {
          if (!hasSidebar) return;

          updateUi('unset', 'showSearch');
          updateUi('set', 'showSidebar', !ui.showSidebar);
        }}
        icon={!!hasSidebar && faBars}
        isDark={isDark}
        isLarge
      />

      {mobileDropdown.length > 1 ? (
        <FormDropdown
          className={cn('HubHeaderMobile-title flex-grow text-center font-bold text-xl', {
            'text-white': isDark,
          })}
          options={mobileDropdown}
          value={activeItemPath}
          onChange={(e, { value }) => {
            if (value === activeItemPath) return;

            let path = value;
            if (!isExternalLink(path)) {
              path = buildPath(value);
            }

            window.location.href = path;
          }}
          selectOnBlur={false}
          floating
        />
      ) : (
        _.get(mobileDropdown[0], 'content')
      )}

      <MobileHubIcon
        onClick={() => {
          updateUi('unset', 'showSidebar');
          updateUi('set', 'showSearch', true);
        }}
        icon={includeSearch && faSearch}
        isDark={isDark}
      />

      {includeSearch &&
        ui.showSearch && (
          <HubSearch
            id="header"
            autoFocus
            onBlur={() => {
              setTimeout(() => updateUi('unset', 'showSearch'), 500);
            }}
            noResultsMessage={typeof includeSearch === 'string' ? includeSearch : undefined}
          />
        )}
    </div>
  );
};

MobileHubHeader = inject((stores, props) => {
  const { appStore } = stores;

  return {
    ...appStore.injectUi('Hub'),
  };
})(observer(MobileHubHeader));

const HubHeader = props => {
  const {
    currentVersion,
    activePagePath,
    includeSearch,
    routerStore,
    hasSidebar,
    className,
    buildPath,
    hasHeader,
    versions,
    theme = {},
    nav = {},
  } = props;

  if (!hasHeader) return null;

  const isDark = !checkIsWhite(theme.background);

  let activeItemPath;
  _.forEach(_.compact(_.concat(nav.left, nav.right)), (navItem, index) => {
    if (activeItemPath) return false; // returning false exits the loop;

    if (navItem.path === '/') {
      if (!activePagePath || activePagePath === navItem.path) {
        activeItemPath = navItem.path;
      }
    } else if (activePagePath === navItem.path || _.startsWith(navItem.path, activePagePath)) {
      activeItemPath = navItem.path;
    }
  });

  return (
    <div
      className={cn('HubHeader print:hidden bg-white', className, {
        'border-b border-grey-light': !isDark,
        'is-dark': isDark,
      })}
    >
      <MobileHubHeader
        activeItemPath={activeItemPath}
        includeSearch={includeSearch}
        hasSidebar={hasSidebar}
        buildPath={buildPath}
        isDark={isDark}
        nav={nav}
      />

      <DesktopHubHeader
        location={routerStore.location}
        activeItemPath={activeItemPath}
        currentVersion={currentVersion}
        includeSearch={includeSearch}
        buildPath={buildPath}
        versions={versions}
        isDark={isDark}
        nav={nav}
      />
    </div>
  );
};

export default inject('routerStore')(observer(HubHeader));
