import React from 'react';
import cn from 'classnames';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';

import { Icon } from '@core/ui';

const Banner = props => {
  const { className, children } = props;

  return (
    <div className={cn('flex items-center h-10 text-white bg-warning pl-4', className)}>
      <Icon icon={faExclamationTriangle} className="mr-3" />

      <div className="flex-1">{children}</div>
    </div>
  );
};

export default Banner;
