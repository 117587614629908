import React from 'react';
import { enableUniqueIds } from 'react-html-id';
import FormCheckbox from '@platform/components/FormCheckbox';
import JsonSchemaEditor from '@platform/components/JsonSchemaEditor';
import _ from 'lodash';

class JsonSchemaBlock extends React.Component {
  constructor() {
    super();
    enableUniqueIds(this);
  }

  componentWillMount() {
    const { data = {}, updateCache } = this.props;

    if (data.$ref) {
      updateCache('set', ['activeType'], 'connected');
    }
  }

  onSchemaChange = schema => {
    const { onChangeData } = this.props;
    if (onChangeData) {
      onChangeData('set', [], schema);
    }
  };

  onRefChange = ref => {
    const { onChangeData } = this.props;

    if (onChangeData) {
      onChangeData('set', ['$ref'], ref);
    }
  };

  handleChangeType = (e, { value }) => {
    const { cache = {}, updateCache, onChangeData } = this.props;

    const activeType = cache.activeType || 'edit';

    if (value === activeType) return;

    if (onChangeData) {
      onChangeData('set', [], {});
    }

    updateCache('set', ['activeType'], value);
  };

  getSchema = () => {
    const { data = {} } = this.props;
    let schema = data;

    return schema;
  };

  buildHeaderActions = () => {
    const { config = {}, onChangeConfig } = this.props;

    const actions = [];
    actions.push(
      <FormCheckbox
        name="hideRoot"
        label="Hide Root"
        tip="When checked, the root object will be hidden in the viewer."
        checked={config.hideRoot}
        onChange={checked => {
          if (checked) {
            onChangeConfig('set', ['hideRoot'], checked);
          } else {
            onChangeConfig('unset', ['hideRoot']);
          }
        }}
        inline
      />
    );

    return actions;
  };

  render = () => {
    const { WrapperFactory, dereferencedBlock, data } = this.props;
    let contentElem;

    contentElem = (
      <JsonSchemaEditor
        id={`${this.nextUniqueId()}:JSE`}
        schema={data}
        dereferencedSchema={_.get(dereferencedBlock, 'data.schema')}
        onSchemaChange={this.onSchemaChange}
        editOnly
        refBuilderProps={{
          fileFilter: { modeling: [/oas2/] },
          routeDataTargets: { oas2: ['definitions'] },
          targets: 'optional',
          initialSource: 'currentProject',
        }}
      />
    );

    return (
      <WrapperFactory
        {...this.props}
        headerActions={this.buildHeaderActions()}
        contentElem={contentElem}
      />
    );
  };
}

export default JsonSchemaBlock;
