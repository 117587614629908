// import React from 'react';
// import dynamic from 'next/dynamic';

// import PageLoading from '../PageLoading';

// const ColorPicker = dynamic(import('./colorPicker'), {
//   ssr: false,
//   loading: () => <PageLoading />,
// });

// export default ColorPicker;

import ColorPicker from './colorPicker';

export default ColorPicker;
