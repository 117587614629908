import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

import { IEditor, IValidation, SYMBOLS } from '@core/editor/types';
import { lazyInject } from '@core/ioc';
import { themes } from '@core/ui/enums';
import { PanelList } from '@core/ui/PanelList';
import { IPanelListItem } from '@core/ui/PanelListItem';

import MarkdownViewer from '../MarkdownViewer';
import { DiagnosticSeverity } from '@stoplight/types';

export interface IValidations {
  severity?: DiagnosticSeverity;
  projectStore?: any;
  projectService?: any;
}

export class Validations extends React.Component<IValidations, {}> {
  @lazyInject(SYMBOLS.Editor)
  // @ts-ignore
  private _editor: IEditor;

  public render() {
    return (
      <PanelList
        items={this.validations}
        theme={themes.light}
        popup={{
          posX: 'left',
          posY: 'center',
        }}
        onClick={({ data }: { data?: any }) => {
          const { route } = this._editor;
          if (route && data && data.route) {
            route(data.route);
          }
        }}
      />
    );
  }

  private get validations(): IPanelListItem[] {
    const { severity, projectStore, projectService } = this.props;

    let validations: IValidation[] = [];
    if (severity === undefined) {
      validations = this._editor.activeValidations;
    } else if (severity === DiagnosticSeverity.Error) {
      validations = this._editor.errors;
    } else if (severity === DiagnosticSeverity.Warning) {
      validations = this._editor.warnings;
    } else if (severity === DiagnosticSeverity.Information) {
      validations = this._editor.info;
    }

    return validations.map((validation, i) => {
      const meta: any = [];
      if (validation.message) {
        meta.push(validation.message);
      } else if (validation.path) {
        if (_.isArray(validation.path)) {
          meta.push(`loc: ${validation.path.join('.')}`);
        } else {
          meta.push(`loc: ${validation.path}`);
        }
      }

      let path: any = validation.path;
      if (_.isArray(path)) {
        path = JSON.stringify(path);
        path = _.replace(path, /"/g, '');
        path = _.replace(path, /,/g, '`, `');
        path = _.replace(path, '[', '[ `');
        path = _.replace(path, ']', '` ]');
      }

      let details = validation.details || '';
      const isAssertionError = /^expected /.test(details);
      if (isAssertionError) {
        details = `\`\`\`\n${details}\n\`\`\``;
      }

      const detailsElem = (
        <div className="py-1">
          {(validation.ruleId || validation.message) && (
            <div className="mb-4">
              <div className="flex">
                <div className="font-bold mb-1 flex-1">
                  Rule: <span className="font-normal">{validation.ruleId}</span>
                </div>
                {projectService.canUser({ action: 'push:project' }) &&
                  validation.spec && (
                    <div
                      className="cursor-pointer hover:underline text-active pl-4"
                      onClick={() =>
                        projectStore.current.goToFile('lint.yml', {
                          query: { edit: validation.spec },
                          hash: validation.ruleId,
                        })
                      }
                    >
                      Edit Rule
                    </div>
                  )}
              </div>

              {validation.message && (
                <MarkdownViewer innerClassName="text-sm" value={validation.message} />
              )}
            </div>
          )}

          {details && (
            <div className="mb-4">
              <div className="font-bold mb-1">Details:</div>
              <MarkdownViewer innerClassName="text-sm" value={details} />
            </div>
          )}

          {path && (
            <div>
              <span className="font-bold mb-1">Location in Document: </span>
              <MarkdownViewer innerClassName="text-sm" value={path} />
            </div>
          )}
        </div>
      );

      return validation.message === 'INVALID JSON'
        ? {
            name: 'Invalid JSON: SyntaxError',
          }
        : {
            name: validation.ruleId || 'error',
            meta,
            badge: `#${i + 1}`,
            data: validation,
            details: detailsElem,
          };
    });
  }
}

export default inject('projectStore', 'projectService')(observer(Validations));
