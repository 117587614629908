import React from 'react';
import { inject, observer } from 'mobx-react';
import EventsFeed from '@platform/components/EventsFeed';
import { isFeatureEnabled } from '@platform/utils/acl';

import PageContent from '../../components/PageContent';
import ExplorerWrapper from '@platform/components/ExplorerWrapper';
import { Changelog } from '@platform/explorer/components';

const OrgActivity = props => {
  const { orgService, userService, namespaceStore } = props;
  const { current: org = {} } = orgService;
  const { authorizedUser: user = {} } = userService;

  const explorerEnabled = isFeatureEnabled({ user, org, feature: 'org-explorer' });
  const explorerStore = _.get(namespaceStore, 'current.explorerStore');

  return (
    <ExplorerWrapper showFilters={explorerEnabled} showOrgs={true}>
      {explorerEnabled ? (
        <Changelog store={explorerStore} />
      ) : (
        <EventsFeed type="org" id={org.id} />
      )}
    </ExplorerWrapper>
  );
};

export default inject('userService', 'orgService', 'namespaceStore')(observer(OrgActivity));
