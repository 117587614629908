import _ from 'lodash';
import { filter } from '../json/compose';

// dynamic rules based on the search query expression
const rules = () => {
  return {
    Apis: {
      recursive({ currentPath = [] }) {
        return currentPath[0] === 'apis' && _.size(currentPath) <= 3;
      },
      matcher({ parentPath = [], key, value }) {
        return parentPath[0] === 'apis' && _.size(parentPath) === 1;
      },
      searchFactory({ currentPath, value }) {
        return {
          currentPath,
          ..._.pick(value, ['id', 'upstream_url']),
        };
      },
    },
    Rules: {
      recursive({ currentPath = [] }) {
        return currentPath[0] === 'rules' && _.size(currentPath) <= 3;
      },
      matcher({ parentPath = [], key, value }) {
        return parentPath[0] === 'rules' && _.size(parentPath) === 1;
      },
      searchFactory({ currentPath, value }) {
        return {
          currentPath,
          ..._.pick(value, ['id']),
        };
      },
    },
  };
};

export const filterSpec = (spec, expression) => {
  return filter(
    { spec, rules: rules() },
    {
      search: {
        query: expression,
      },
    }
  );
};
