import _ from 'lodash';
import React from 'react';
import { inject, observer } from 'mobx-react';

import PageHeader from '@platform/components/PageHeader';
import { isFeatureEnabled } from '@platform/utils/acl';

const OrgPageHeader = props => {
  const { activeTab, orgService, userService } = props;
  const { current: org = {} } = orgService;
  const { authorizedUser: user = {} } = userService;

  const { name, path, description, avatar_url } = org;

  const crumbs = [{ children: name || '...', href: { pathname: `/${path}` } }];

  const canView = orgService.canUser({ action: 'view' });
  const canUpdate = orgService.canUser({ action: 'update' });

  let tabs = [
    {
      name: 'projects',
      icon: 'cube',
    },
    {
      name: 'explorer',
      icon: 'search',
      visible: isFeatureEnabled({ user, org, feature: 'org-explorer' }),
    },
    {
      name: 'activity',
      icon: 'rss',
      visible: canView,
    },
    {
      name: 'people',
      icon: 'user',
      visible: canView,
    },
    {
      name: 'teams',
      icon: 'users',
      visible: canView,
    },
    {
      name: 'settings',
      icon: 'setting',
      visible: canUpdate,
    },
  ];

  return (
    <PageHeader
      tabs={tabs}
      activeTab={activeTab}
      crumbs={crumbs}
      iconName={name}
      basePath={`/${path}`}
      imageUrl={avatar_url}
      subtitle={description}
    />
  );
};

export default inject('orgService', 'userService')(observer(OrgPageHeader));
