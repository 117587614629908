import React from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';

import { faFileArchive } from '@fortawesome/pro-solid-svg-icons/faFileArchive';

import FeatureBadge from '@platform/components/FeatureBadge';
import SimpleSection from '@platform/components/SimpleSection';
import GraphQL from '@platform/containers/GraphQL';
import { hasSubscriptionFeature } from '@platform/utils/billing';

import BuildRow from './buildRow';

const BuildList = props => {
  const { doc, namespace, builds, updateBuildHandler } = props;

  if (_.isEmpty(builds)) return null;

  const downloadEnabled = hasSubscriptionFeature(namespace, 'download');

  return (
    <SimpleSection
      className="PublishViewer-builds mt-12"
      title="Builds"
      icon={faFileArchive}
      actions={<FeatureBadge feature="download" show={!downloadEnabled} namespace={namespace} />}
    >
      <Table singleLine style={{ marginTop: 0 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Built At</Table.HeaderCell>
            <Table.HeaderCell>File</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(builds, (build, index) => (
            <BuildRow
              key={index}
              build={build}
              index={builds.length - index}
              downloadEnabled={downloadEnabled}
              isLive={build.id === doc.liveBuildId}
              updateBuildHandler={updateBuildHandler}
            />
          ))}
        </Table.Body>
      </Table>
    </SimpleSection>
  );
};

export default props => {
  const { doc } = props;

  return (
    <GraphQL
      id="BuildList"
      service="docsService"
      methods={{
        buildList: {
          variables: {
            docId: doc.id,
            orderBy: { field: 'id', direction: 'DESC' },
          },
          pollInterval: 30000,
        },
        updateBuildHandler: {},
      }}
      renderProp={({ buildList, updateBuildHandler }) => {
        return (
          <BuildList
            {...props}
            builds={buildList.data.docBuilds}
            isLoading={buildList.loading}
            error={buildList.error}
            updateBuildHandler={updateBuildHandler}
          />
        );
      }}
    />
  );
};
