import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

class EntityEditorWidgets extends React.Component {
  onChange = (t, p, v, { editor, currentContent, currentPath = [] } = {}) => {
    let transformation = t;
    let path = p;
    let value = v;

    // if t is not a string, this is likely coming from a simple component like a form input
    // that passes the value to update directly as the first argument
    if (typeof t !== 'string' || !p) {
      value = t;

      // this handles when an event object is passed (like from form input)
      if (typeof t === 'object' && t.target) {
        value = t.target.value;
      }

      transformation = 'set';
      path = [];
    }

    if (
      _.includes(['object', 'string'], typeof value) &&
      _.isEmpty(value) &&
      _.size(path) <= 1 &&
      !currentContent.preserveEmpty
    ) {
      transformation = 'unset';
    }

    editor.updateParsed(transformation, currentPath.concat(path), value);
  };

  onPathKeyChange = (fromProp, toProp, cb) => {
    const fromPath = this.props.editor.currentPath;
    const basePath = _.clone(fromPath);
    basePath.pop();
    this.props.editor.renameProp(basePath, fromProp, toProp, cb);
  };

  render() {
    const { orgId, entityId, editor = {}, exportUrl } = this.props;
    const {
      id,
      currentContent = {},
      currentPath,
      dereferencedParsed,
      currentParsed,
      currentDereferencedParsed,
      propsData,
    } = editor;

    const { component: Component, componentProps = {} } = currentContent;

    if (!Component) return null;

    return (
      <div className="EntityEditor-widgets bg-grey-lightest">
        <Component
          id={`${id}-${editor.currentEditPath}`}
          editorId={id}
          orgId={orgId}
          exportUrl={exportUrl}
          entityId={entityId}
          pathKey={_.last(currentPath)}
          currentPath={currentPath}
          onPathKeyChange={this.onPathKeyChange}
          readOnly={false}
          parsed={dereferencedParsed}
          value={currentParsed}
          dereffedValue={currentDereferencedParsed}
          propsData={propsData}
          updateParsed={editor.updateParsed}
          onChange={(t, p, v) => {
            this.onChange(t, p, v, { editor, currentContent, currentPath });
          }}
          {...componentProps}
        />
      </div>
    );
  }
}

export default observer(EntityEditorWidgets);
