import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import SidebarFilter from '@platform/components/SidebarFilter';
import SidebarFilterSettings from '@platform/components/SidebarFilterSettings';

import { alert } from '@platform/utils/alert';

import PageContent from '../../components/PageContent';
import ProjectCreate from '../../components/ProjectCreate';
import ProjectList from '../../components/ProjectList';

class UserProjects extends React.Component {
  componentWillMount() {
    const { userService, routerStore, store } = this.props;
    const { current = {}, authorizedUser = {} } = userService;

    // redirect current user to root projects if they are looking at their own page
    if ((!current || !current.id) && (!authorizedUser || !authorizedUser.id)) {
      routerStore.replace('/');
      alert.success('You must be logged in to view this page.');
    } else if (current && current.id === authorizedUser.id) {
      routerStore.replace('/projects');
      this.findItems({ page: 1 });
    } else {
      this.findItems({ page: 1 });
    }

    if (store) {
      store.find({ namespaceId: _.get(authorizedUser, 'id') });
    }
  }

  findItems = ({ search, page } = {}) => {
    const { userService, projectService } = this.props;
    const { authorizedUser = {} } = userService;
    const query = { owned: true, order_by: 'name', sort: 'asc' };

    let skipPagination = false;

    if (search) {
      query.search = search;
      skipPagination = true;
    } else {
      query.page = page || projectService.getCurrentPage() + 1;
    }

    projectService
      .find(
        { userId: _.get(authorizedUser, 'id') },
        {
          basePath: '/users/:userId/projects',
          query,
          skipPagination,
        }
      )
      .catch(e => {
        console.warn('Error fetching projects', e);
      });
  };

  render() {
    const { userService, projectService, namespaceStore, store } = this.props;

    const user = userService.authorizedUser || {};
    const projects = projectService.currentList;

    const tags = _.get(store, 'tags');
    const activeTags = _.get(namespaceStore.current, 'activeFilters.tags', []);
    const activeFilterSettings = _.get(namespaceStore.current, 'activeFilterSettings.tags', {});

    const canWrite = true;
    const canCreate = true;

    let contentElem;
    if (projectService.isFinding || !_.isEmpty(projects) || !canCreate || !_.isEmpty(activeTags)) {
      contentElem = [
        <div key="1" className="flex-auto">
          <ProjectList
            namespace={{ ...user, namespace_type: 'user' }}
            projects={projects}
            tags={tags}
            loadMore={this.findItems}
            onSearch={searchQuery => {
              this.findItems({ search: searchQuery });
            }}
            displayFullTags={activeFilterSettings.displayFullTags}
          />
        </div>,

        <div key="2" className="ml-6">
          <SidebarFilterSettings filterName="tags" />

          <SidebarFilter
            filterName="tags"
            displayName="Lifecycle Tags"
            items={_.filter(tags, t => t.type === 'lifecycle')}
            settingsPathname={`/profile/filters`}
            canCreate={canWrite}
            hideIfEmpty
          />

          <SidebarFilter
            filterName="tags"
            displayName="Other Tags"
            items={_.filter(tags, t => !t.type)}
            settingsPathname={`/profile/filters`}
            canCreate={canWrite}
            hideIfEmpty
          />
        </div>,
      ];
    } else {
      contentElem = (
        <div className="w-100 mx-auto my-12 rounded-lg shadow" style={{ maxWidth: 800 }}>
          <ProjectCreate />
        </div>
      );
    }

    return <PageContent>{contentElem}</PageContent>;
  }
}

export default inject(
  ({ userService, projectService, namespaceStore, routerStore, tagStore }, props) => {
    const user = userService.authorizedUser;

    let store;
    if (user) {
      store = tagStore.register(`UserProjects-${user.id}`);
    }

    return {
      userService,
      projectService,
      namespaceStore,
      routerStore,
      store,
    };
  }
)(observer(UserProjects));
