import React from 'react';
import { Popup } from 'semantic-ui-react';
import _ from 'lodash';

import { Icon } from '@core/ui';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

// Specialized cancel to make sure that we don't close the popup unless we click outside
// The regular popup does not handle this very well
export default class SLPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleOpen = e => {
    if (this.props.onOpen) {
      this.props.onOpen();
    }

    this.setState({
      isOpen: true,
    });
  };

  handleClose = (e, data) => {
    let cancel;
    const path = _.get(e, 'path', []);
    _.forOwn(path, (val, k) => {
      if (_.includes(_.get(val, 'className'), 'popup')) {
        cancel = true;

        return false;
      }
    });

    if (cancel) {
      return;
    }

    if (this.props.onClose) {
      this.props.onClose();
    }

    this.setState({
      isOpen: false,
    });
  };

  render() {
    const {
      content,
      contentFactory,
      forceOpen,
      triggerFactory,
      on,
      hideOnScroll,
      ...popupProps
    } = this.props;

    const open = this.state.isOpen || forceOpen;
    const triggerElem = triggerFactory({ handleOpen: this.handleOpen });

    return (
      <Popup
        trigger={triggerElem}
        on={on}
        open={!!open}
        hideOnScroll={hideOnScroll}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        {...popupProps}
      >
        {open && (
          <React.Fragment>
            {(on === 'click' || !!hideOnScroll) && (
              <div className="absolute pin-x text-right px-4 z-10">
                <Icon
                  className="cursor-pointer opacity-75 hover:opacity-100"
                  size="lg"
                  icon={faTimes}
                  onClick={this.handleClose}
                />
              </div>
            )}

            {content ? content : contentFactory(this)}
          </React.Fragment>
        )}
      </Popup>
    );
  }
}
