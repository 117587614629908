import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { inject } from 'mobx-react';
import { Table, Button, Popup } from 'semantic-ui-react';

import TableList from '@platform/components/TableList';
import TeamCreate from '../TeamCreate';

import { Link } from '@platform/utils/router';
import { getHighestRole } from '@platform/utils/acl';

class TeamList extends React.Component {
  static propTypes = {
    teams: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired,
    entityType: PropTypes.string.isRequired,
    handleRemove: PropTypes.func.isRequired,
    loadMore: PropTypes.func,
    onSearch: PropTypes.func,
  };

  sortTeams = () => {
    const { teams = [] } = this.props;

    return _.sortBy(_.sortBy(teams, 'name'), team => {
      switch (getHighestRole({ entity: team })) {
        case 'owner':
          return 1;
        case 'admin':
          return 2;
        case 'member':
          return 3;
        default:
          return 4;
      }
    });
  };

  render() {
    const {
      entity,
      entityType,
      handleRemove,
      routerStore,
      orgService,
      service,
      loadMore,
      onSearch,
    } = this.props;

    const teams = this.sortTeams();

    const canCreateTeam = orgService.canUser({ action: 'create:team' });

    let actionButton;

    if (canCreateTeam) {
      actionButton = {
        content: 'New Team',
        onClick: () => {
          routerStore.push({ pathname: `${routerStore.location.pathname}/new` });
        },
      };
    } else {
      actionButton = () => (
        <Popup
          on="hover"
          trigger={<Button primary basic content="New Team" />}
          content="Only org admins can create new teams."
        />
      );
    }

    if (!service.isLoading && _.isEmpty(teams) && canCreateTeam) {
      return (
        <div className="TeamsPage">
          <TeamCreate firstTime />
        </div>
      );
    }

    return (
      <div className="TeamsPage">
        <TableList
          loading={!service.isSearching && service.isLoading && _.isEmpty(teams)}
          scope={`${entity.id}-teams`}
          items={teams}
          noItemsMessage={() => {
            let message = `There are no teams for ${entity.name}.`;

            if (canCreateTeam) {
              message = `${message} To invite a teams, click the invite button to the top right.`;
            }

            return message;
          }}
          actionButton={actionButton}
          cellFactories={[
            (team, key) => (
              <Table.Cell key={key} width="14">
                <Link to={`/${entity.path}/teams/${team.path}`} className="block font-bold">
                  <div className="flex">
                    <div className="mr-3">{team.name}</div>
                  </div>
                  <small className="text-muted">{`${entity.path}/teams/${team.path}`}</small>
                </Link>
              </Table.Cell>
            ),
            (team, key) => {
              let roleElem;

              const canManageTeam = orgService.canUser({ action: 'manage:team' });

              if (canManageTeam) {
                roleElem = (
                  <Button
                    className="icon"
                    icon="remove"
                    negative
                    basic
                    onClick={() => {
                      const r = window.confirm(
                        `Are you sure you want to remove ${team.name} from this ${entityType}?`
                      );
                      if (r) {
                        handleRemove(team.id);
                      }
                    }}
                  />
                );
              }

              return (
                <Table.Cell key={key} textAlign="right">
                  {roleElem}
                </Table.Cell>
              );
            },
          ]}
          footer={
            loadMore &&
            service.loadMore && (
              <Button
                className="w-1/2"
                onClick={loadMore}
                disabled={service.isFinding}
                loading={service.isFinding}
              >
                Load More
              </Button>
            )
          }
          onSearch={onSearch}
        />
      </div>
    );
  }
}

export default inject('routerStore', 'orgService')(TeamList);
