import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import CollectionTerminal from '../CollectionTerminal';
import CollectionTriggerByUrl from '../CollectionTriggerByUrl';
import FormTitleInput from '../FormTitleInput';
import MarkdownEditor from '../MarkdownEditor';
import UsedEnvironmentVariables from '../UsedEnvironmentVariables';
import EntityEditorGroup from '../EntityEditorGroup';
import EditorContentSection from '../EditorContentSection';

function CollectionEditorOverview(props) {
  const { orgId, userService, editor } = props;

  return (
    <div className="CollectionOverview">
      <EditorContentSection>
        <FormTitleInput
          className="mb-3 mt-3"
          value={_.get(editor, 'parsed.name', '')}
          placeholder="Collection Name..."
          onChange={(e, { value }) => {
            editor.updateParsed('set', ['name'], value);
          }}
          inputStyle={{
            padding: 5,
            fontWeight: 'bold',
          }}
        />

        <MarkdownEditor
          id={editor.id}
          className="CollectionEditor-notes mt-3"
          placeholder="Optional collection description..."
          value={_.get(editor, 'parsed.description', '')}
          onChange={markdownVal => {
            editor.updateParsed('set', ['description'], markdownVal);
          }}
        />

        <UsedEnvironmentVariables editorId={editor.id} className="mt-6" />

        <EntityEditorGroup
          id="tutorial-collection-triggers"
          className="mt-6"
          name="Trigger This Collection"
          padded
          contentFactory={() => (
            <div>
              <CollectionTerminal
                orgId={orgId}
                editorId={editor.id}
                message={userService.isLoggedIn ? null : 'Login or register to run from terminal.'}
              />
              <br />
              <CollectionTriggerByUrl editorId={editor.id} />
            </div>
          )}
        />
      </EditorContentSection>
    </div>
  );
}

export default inject(({ userService, collectionEditorStore, appStore }, { editorId }) => ({
  userService,
  editor: collectionEditorStore.getEditor({ id: editorId }),
}))(observer(CollectionEditorOverview));
