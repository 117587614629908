import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import FormDropdown from '../FormDropdown';

class TeamSearchDropdown extends React.Component {
  createOptions(foundedTeams) {
    const { updateUi, excludedTeams = [] } = this.props;

    if (_.isEmpty(foundedTeams)) {
      updateUi('set', 'options', []);
    }

    const availableTeams = _.differenceBy(foundedTeams, excludedTeams, 'id');
    const options = _.uniqBy(
      _.map(availableTeams, t => ({
        text: t.name,
        value: t.id,
        key: t.id,
      })),
      'value'
    );

    updateUi('set', 'options', options);
  }

  render() {
    const { orgId, teamService, ui, handleChange, ...extraProps } = this.props;

    return (
      <FormDropdown
        fluid
        search
        selection
        disabled={ui.loading}
        options={ui.options || []}
        loading={teamService.isFinding}
        onFocus={() => {
          teamService.find({ orgId }).then(results => {
            this.createOptions(results);
          });
        }}
        onSearchChange={(e, { searchQuery }) => {
          teamService
            .find(
              { orgId },
              {
                query: {
                  search: searchQuery,
                },
              }
            )
            .then(results => {
              this.createOptions(results);
            });
        }}
        onChange={_.isFunction(handleChange) ? handleChange : _.noop}
        {..._.omit(extraProps, ['updateUi', 'clearUi'])}
      />
    );
  }
}

TeamSearchDropdown.propTypes = {
  handleChange: PropTypes.func,
  excludedTeams: PropTypes.array,
  label: PropTypes.string,
};

export default inject(({ appStore, teamService }, { id }) => ({
  ...appStore.injectUi(`${id}:TeamSearchDropdown`),
  teamService,
}))(observer(TeamSearchDropdown));
