import _ from 'lodash';
import produce from 'immer';
import { action, computed, observable, reaction, toJS, isObservableArray } from 'mobx';
import { Collection as DisposableCollection } from '@core/disposable';

import ExplorerStore from '@platform/explorer/models';
import { getConfigVar } from '@platform/utils/config';
import { isFeatureEnabled } from '@platform/utils/acl';

class NamespaceStore {
  id = null;
  rootStore = null;
  _disposables = new DisposableCollection();

  explorerStore = null;
  _isExplorerEnabled = false;

  @observable
  _activeTab;

  @observable
  filters = {};

  @observable
  filterSettings = {};

  constructor(opts = {}) {
    const user = _.get(opts.rootStore.stores, 'userService.authorizedUser', {});
    const org = _.get(opts.rootStore.stores, 'orgService.current', {});
    const feature = _.isEmpty(org) ? 'user-explorer' : 'org-explorer';

    this.id = opts.id;
    this.rootStore = opts.rootStore;
    this._isExplorerEnabled = isFeatureEnabled({ user, org, feature });
    this.namespaceType = _.get(opts.rootStore.stores, 'namespaceService.current.namespace_type');

    this._activeTab = opts.activeTab;
    this.filters[this.activeTab] = this.cleanFilters(opts.filters);
    this.filterSettings[this.activeTab] = {};

    // id has format namespaceId-teamId
    if (this._isExplorerEnabled) {
      const groupId = this.namespaceType === 'org' ? _.split(this.id, '-')[0] : undefined;
      this.explorerStore = new ExplorerStore({
        apiHost: getConfigVar('SL_API_HOST'),
        query: this.activeTab === 'explorer' ? this.filters.explorer : {},
        groupId: groupId ? Number(groupId) : undefined,
        url: this.activeTab === 'activity' ? '/nodes.changelog' : '/nodes.list',
      });
    }
  }

  @action
  activate = () => {
    this._disposables.dispose();
    this.registerReactions();
  };

  @action
  deactivate() {
    this._disposables.dispose();
  }

  @action
  registerReactions = () => {
    // need to update the namespace filters when explorer is updated
    this._disposables.push({
      dispose: reaction(
        () => _.get(this.explorerStore, 'debouncedQuery'),
        query => this.updateActiveFilter('set', [], toJS(query))
      ),
    });
  };

  // FILTERS
  @computed
  get activeFilters() {
    return toJS(this.filters[this.activeTab]);
  }

  @action
  updateActiveFilter = (t, p, v) => {
    this.filters = produce(this.filters, newFilters => {
      let path = [this.activeTab, ...(_.isArray(p) ? p : [p])];

      switch (t) {
        case 'set':
          _.set(newFilters, path, v);
          break;

        // REMOVING FROM ARRAYS NEEDS TO BE HANDLED SPECIALLY
        case 'unset':
          const head = _.initial(path);
          const tail = _.last(path);
          const parent = _.get(newFilters, head, {});

          if (_.isArray(parent) || isObservableArray(parent)) {
            if (typeof tail === 'number') {
              _.pullAt(parent, tail);
            } else {
              _.pull(parent, tail);
            }
            _.set(newFilters, [...head, tail], parent);
          } else {
            _.unset(newFilters, path);
          }
          break;
      }

      // clean the filters so we only ever store relevant ones
      const cleaned = this.cleanFilters(newFilters[this.activeTab]);
      _.set(newFilters, this.activeTab, cleaned);

      return newFilters;
    });

    // update url query with new filters
    this.setQuery();
  };

  cleanFilters(filters = {}) {
    return _.pick(filters, [
      // project tab
      'tags',
      // explorer/activity tab
      'search',
      'type',
      'page',
    ]);
  }

  // FILTER SETTINGS
  @computed
  get activeFilterSettings() {
    return toJS(this.filterSettings[this.activeTab]);
  }

  @action
  updateActiveFilterSettings = (t, p, v) => {
    this.filterSettings = produce(this.filterSettings, newSettings => {
      let path = [this.activeTab, ...(_.isArray(p) ? p : [p])];

      switch (t) {
        case 'set':
          _.set(newSettings, path, v);
          break;

        case 'unset':
          _.unset(newSettings, path);
          break;
      }

      return newSettings;
    });
  };

  // TABS
  @computed
  get activeTab() {
    if (this._activeTab) return this._activeTab;
    // for orgs default to projects
    if (this.namespaceType === 'org') return 'projects';
    // for users default to explorer if enabled
    if (this._isExplorerEnabled) return 'explorer';
    // default to activity else
    return 'activity';
  }

  @action
  updateTab = tab => {
    this._activeTab = tab;

    // switch the url for the appropriate tabs

    if (this.explorerStore) {
      switch (tab) {
        case 'explorer':
          this.explorerStore.updateUrl('/nodes.list');
          break;
        case 'activity':
          this.explorerStore.updateUrl('/nodes.changelog');
          break;
      }
    }

    // set url query with filters for this tab
    this.setQuery();
  };

  // ROUTING
  setQuery = () => {
    this.rootStore.stores.routerStore.setQueryParams(this.activeFilters, {
      preserve: true,
      replace: false,
      arrayFormat: 'indices',
    });

    // update explorer wih the url changes
    if (
      this._isExplorerEnabled &&
      (this.activeTab === 'explorer' || this.activeTab === 'activity')
    ) {
      const explorerFilters = toJS(_.get(this.explorerStore, 'query'));

      if (!_.isEqual(explorerFilters, this.activeFilters)) {
        this.explorerStore.updateSearchQuery(this.activeFilters);
      }
    }
  };
}

export default class NamespaceStoreManager {
  rootStore = null;

  @observable
  activeStore = null;

  @observable
  stores = [];

  constructor({ rootStore } = {}) {
    this.rootStore = rootStore;
  }

  getStore({ id }) {
    return _.find(this.stores, { id }) || {};
  }

  getStoreIndex(id) {
    return _.findIndex(this.stores, { id });
  }

  @action
  initStore = props => {
    const { id, activeTab, namespaceType } = props || {};

    let newStore = this.getStore({ id });
    if (_.isEmpty(newStore)) {
      newStore = new NamespaceStore({
        id,
        activeTab,
        namespaceType,
        filters: _.get(this.rootStore.stores.routerStore, 'location.query'),
        rootStore: this.rootStore,
      });
      this.stores.push(newStore);
    } else {
      if (newStore.activeTab !== activeTab) {
        newStore.updateTab(activeTab);
      }
    }

    this.activateStore(newStore);
    return newStore;
  };

  @action
  activateStore = newStore => {
    const oldStore = this.activeStore;
    _.invoke(oldStore, 'deactivate');

    this.activeStore = newStore;
    _.invoke(this.activeStore, 'activate');
  };

  @computed
  get current() {
    const namespaceId = _.get(this.rootStore.stores, 'namespaceService.current.id');
    const teamId = _.get(this.rootStore.stores, 'teamService.current.id');

    return this.getStore({ id: `${namespaceId}-${teamId}` });
  }
}
