import React from 'react';
import _ from 'lodash';
import { Button, Image, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

const PageError = props => {
  const { routerStore, homeUrl, title, icon, iconProps = {}, image, message } = props;

  let messageText;

  if (message) {
    if (_.isFunction(message)) {
      messageText = message();
    } else {
      messageText = message;
    }
  }

  return (
    <div className="bg-grey-lightest shadow-lg rounded text-center shadow w-128 p-6">
      {title ? <div className="pt-6 pb-4 text-5xl font-extrabold">{title}</div> : null}

      {icon ? (
        <div className="mb-3">
          <Icon name={icon} size="huge" {...iconProps} />
        </div>
      ) : null}

      {messageText ? <div className="text-2xl py-4 leading-normal">{messageText}</div> : null}

      {image ? (
        <div className="p-3">
          <Image centered src="//thecatapi.com/api/images/get?format=src&type=gif" />
        </div>
      ) : null}

      <div className="flex justify-center items-center pt-6">
        <Button
          content="Take Me Home"
          icon="home"
          primary
          onClick={() => {
            if (homeUrl) {
              window.location.href = homeUrl;
            } else {
              routerStore.replace({ pathname: '/' });
            }
          }}
        />
      </div>
    </div>
  );
};

export default inject(({ routerStore }) => {
  return {
    routerStore,
  };
})(observer(PageError));
