import React from 'react';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import pluralize from 'pluralize';
import { inject, observer } from 'mobx-react';
import { buildParameters } from '@platform/utils/specs';

import SwaggerParameterGroup from '../SwaggerParameterGroup';
import MarkdownEditor from '../MarkdownEditor';
import FormSelect from '../FormSelect';
import JsonSchemaEditor from '../JsonSchemaEditor';
import SpecExamples from '../SpecExamples';
import EntityEditorGroup from '../EntityEditorGroup';
import EditorContentSection from '../EditorContentSection';

const emptyObject = {};
const emptyArray = [];
const extraTypes = [
  {
    text: 'file',
    value: 'file',
  },
];

class SwaggerParameters extends React.Component {
  handleUpdateRequestBodyType = val => {
    const { value = emptyObject } = this.props;

    // filter out all formData and body params
    const newParams = _.compact(
      _.map(value, p => {
        if (!p || p.in === 'formData' || p.in === 'body') {
          return null;
        }

        return p;
      })
    );

    if (val === 'body') {
      newParams.push({
        in: 'body',
        name: 'body',
        schema: {
          type: 'object',
          properties: {},
        },
      });
    } else if (val === 'formData') {
      newParams.push({
        in: 'formData',
        name: '',
        type: 'string',
      });
    }

    this.handleUpdate('set', [], newParams);
  };

  // pull this out so that children that we pass this function to can more effectively
  // use shouldComponentUpdate
  handleUpdate = (...args) => {
    this.props.onChange(...args);
  };

  render = () => {
    const {
      id,
      editorId,
      editor,
      value = emptyArray,
      dereferencedValue = emptyArray,
      dereffedValue,
      parsed = emptyObject,
      preview,
    } = this.props;

    const parameters = value;
    const dereferencedParameters = dereferencedValue.length > 0 ? dereferencedValue : dereffedValue;
    const rootParameters = _.get(parsed, 'parameters');
    const definitions = _.get(parsed, 'definitions');
    const paramData = buildParameters({
      rootParameters,
      parameters,
      dereferencedParameters,
      editor,
    });

    return (
      <EditorContentSection className="SwaggerParameters">
        {preview && _.isEmpty(paramData.path) ? null : (
          <EntityEditorGroup
            id={`${id}-path`}
            name={`${_.size(paramData.path)} Path ${pluralize(
              'Parameter',
              _.size(paramData.path)
            )}`}
            tip="Used together with Path Templating, where the parameter value is actually part of the operation's URL.
          This does not include the host or base path of the API. For example, in /items/{itemId}, the path parameter is itemId."
            padded
            active={!_.isEmpty(paramData.path)}
            contentFactory={() => (
              <div>
                {preview ? null : (
                  <Button
                    icon="plus"
                    content="Add"
                    onClick={() => {
                      this.handleUpdate('push', [], {
                        in: 'path',
                        name: '',
                        type: 'string',
                        required: true,
                      });
                    }}
                    size="tiny"
                    secondary
                    compact
                  />
                )}

                <SwaggerParameterGroup
                  parsed={parsed}
                  id={`${id}-path`}
                  rootParameters={rootParameters}
                  parameters={paramData.path}
                  handleUpdate={this.handleUpdate}
                  editorId={editorId}
                  preview={preview}
                />
              </div>
            )}
          />
        )}

        {preview && _.isEmpty(paramData.header) ? null : (
          <EntityEditorGroup
            id={`${id}-headers`}
            className="mt-6"
            name={`${_.size(paramData.header)} ${pluralize('Header', _.size(paramData.header))}`}
            tip="Custom headers that are expected as part of the request."
            active={!_.isEmpty(paramData.header)}
            padded
          >
            <div>
              {preview ? null : (
                <Button
                  icon="plus"
                  content="Add"
                  onClick={() => {
                    this.handleUpdate('push', [], {
                      in: 'header',
                      name: '',
                      type: 'string',
                    });
                  }}
                  size="tiny"
                  secondary
                  compact
                />
              )}

              <SwaggerParameterGroup
                parsed={parsed}
                id={`${id}-headers`}
                rootParameters={rootParameters}
                parameters={paramData.header}
                handleUpdate={this.handleUpdate}
                editorId={editorId}
                preview={preview}
              />
            </div>
          </EntityEditorGroup>
        )}

        {preview && _.isEmpty(paramData.query) ? null : (
          <EntityEditorGroup
            id={`${id}-query`}
            className="mt-6"
            name={`${_.size(paramData.query)} Query ${pluralize(
              'Parameter',
              _.size(paramData.query)
            )}`}
            tip="Parameters that are appended to the URL. For example, in /items?id=###, the query parameter is id."
            active={!_.isEmpty(paramData.query)}
            padded
            contentFactory={() => (
              <div>
                {preview ? null : (
                  <Button
                    icon="plus"
                    content="Add"
                    onClick={() => {
                      this.handleUpdate('push', [], {
                        in: 'query',
                        name: '',
                        type: 'string',
                      });
                    }}
                    size="tiny"
                    secondary
                    compact
                  />
                )}

                <SwaggerParameterGroup
                  parsed={parsed}
                  id={`${id}-query`}
                  rootParameters={rootParameters}
                  parameters={paramData.query}
                  handleUpdate={this.handleUpdate}
                  editorId={editorId}
                  preview={preview}
                />
              </div>
            )}
          />
        )}

        {preview && (_.isEmpty(paramData.formData) && _.isEmpty(paramData.body)) ? null : (
          <EntityEditorGroup
            id={`${id}-requestbody`}
            className="mt-6"
            name="Request Body"
            tip="Use 'body' to define a JSON schema.
          Use 'formData' for 'multipart/form-data' or 'application/x-www-form-urlencoded' request bodies."
            active={!_.isEmpty(paramData.formData) || _.get(paramData, 'body.index')}
            padded
            contentFactory={() => {
              let requestBodyType = '';
              let requestBodyElem = null;
              if (!_.isEmpty(paramData.formData)) {
                requestBodyType = 'formData';
                requestBodyElem = (
                  <SwaggerParameterGroup
                    parsed={parsed}
                    rootParameters={rootParameters}
                    parameters={paramData.formData}
                    handleUpdate={this.handleUpdate}
                    extraTypes={extraTypes}
                    editorId={editorId}
                    preview={preview}
                  />
                );
              } else if (_.get(paramData, 'body.index')) {
                requestBodyType = 'body';
                requestBodyElem = (
                  <div className="mt-6">
                    {preview ? (
                      <p>{_.get(paramData, 'body.param.description')}</p>
                    ) : (
                      <MarkdownEditor
                        id={`${id}:req`}
                        className="mb-6"
                        placeholder="Request body description..."
                        value={_.get(paramData, 'body.param.description', '')}
                        onChange={v => {
                          if (!v) {
                            this.handleUpdate('unset', [paramData.body.index, 'description']);
                          } else {
                            this.handleUpdate('set', [paramData.body.index, 'description'], v);
                          }
                        }}
                      />
                    )}

                    <div className="mb-6">
                      <JsonSchemaEditor
                        id={`${id}-body`}
                        schema={_.get(paramData, 'body.param.schema', '')}
                        dereferencedSchema={_.get(paramData, 'body.dereferencedParam.schema', '')}
                        definitions={definitions}
                        editorStore="oas2EditorStore"
                        editorId={editorId}
                        onSchemaChange={schema => {
                          this.handleUpdate('set', [paramData.body.index, 'schema'], schema);
                        }}
                        readOnly={preview}
                        refBuilderProps={{
                          localData: parsed,
                          fileFilter: { modeling: [/oas2/] },
                          routeDataTargets: { oas2: ['definitions'] },
                          targets: 'required',
                        }}
                      />
                    </div>

                    <div>
                      <SpecExamples
                        id="param-body"
                        schema={_.get(paramData, 'body.param.schema', '')}
                        dereferencedSchema={_.get(paramData, 'body.dereferencedParam.schema', '')}
                        definitions={definitions}
                        examples={_.get(paramData, 'body.param.x-examples', {})}
                        handleUpdate={(t, p, v) => {
                          this.handleUpdate(t, [paramData.body.index, 'x-examples'].concat(p), v);
                        }}
                        readOnly={preview}
                      />
                    </div>
                  </div>
                );
              }

              return (
                <div>
                  <div className="flex items-center">
                    <div>
                      {preview ? null : (
                        <FormSelect
                          placeholder="choose type"
                          value={requestBodyType}
                          onChange={val => {
                            if (val === requestBodyType) {
                              return;
                            }

                            this.handleUpdateRequestBodyType(val);
                          }}
                          options={[
                            {
                              text: 'none',
                              value: '',
                            },
                            {
                              text: 'body (JSON, XML, etc)',
                              value: 'body',
                            },
                            {
                              text: 'formData',
                              value: 'formData',
                            },
                          ]}
                        />
                      )}
                    </div>

                    {requestBodyType === 'formData' && !preview ? (
                      <div className="ml-3">
                        <Button
                          icon="plus"
                          content="Add"
                          onClick={() => {
                            this.handleUpdate('push', [], {
                              in: 'formData',
                              name: '',
                              type: 'string',
                            });
                          }}
                          size="tiny"
                          secondary
                          compact
                        />
                      </div>
                    ) : null}
                  </div>

                  {requestBodyElem}
                </div>
              );
            }}
          />
        )}
      </EditorContentSection>
    );
  };
}

export default inject(({ oas2EditorStore }, { editorId }) => {
  const editor = oas2EditorStore.getEditor({ id: editorId });

  return {
    editor,
  };
})(observer(SwaggerParameters));
