import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import AutosizeInput from 'react-input-autosize';

import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faLongArrowRight } from '@fortawesome/pro-solid-svg-icons/faLongArrowRight';

import { sizes, colors, Button, Icon, Modal } from '@core/ui';

import { alert } from '@platform/utils/alert';

import SortableTree from '@stoplight/react-sortable-tree';

import '@stoplight/react-sortable-tree/style.css';
// import './style.styl';

import PageSettings from '../PageSettings';

class TOC extends React.Component {
  componentWillMount() {
    this.props.store.computeTreeData();

    this.props.store.updateTreeSearchResult({ matches: [] });
    this.props.store.updateTreeSearchQuery('');
    this.props.store.expandTreePath({
      path: this.props.store.currentPath,
      focus: true,
    });
  }

  render() {
    const { store } = this.props;
    const { treeData, treeSearchQuery, updateTreeSearchQuery, addTreePage } = store;

    let treeElem;
    if (treeData.tree && treeData.tree.length) {
      treeElem = this.renderTree();
    } else {
      treeElem = (
        <div className="text-muted text-xl font-bold my-40 text-center">
          click the add page button to start building your hub
        </div>
      );
    }

    return (
      <div className="flex flex-col flex-1 h-full">
        <div className="flex bg-grey-lighter border-b border-grey-light items-center px-6">
          <div className="opacity-50">
            <Icon icon={faSearch} />
          </div>

          <div className="flex-1">
            <AutosizeInput
              inputClassName="appearance-none h-12 pl-4 pr-6 bg-transparent"
              value={treeSearchQuery || ''}
              placeholder="Search..."
              onChange={e => {
                updateTreeSearchQuery(e.target.value);
              }}
              placeholderIsMinWidth
            />
          </div>

          <div className="mr-8">
            <Button size={sizes.sm} color={colors.accent} onClick={() => addTreePage()}>
              Add Page
            </Button>
          </div>
        </div>

        <div className="flex-1">{treeElem}</div>

        <div
          className="bg-grey-lighter border-t border-grey-light py-4 px-6 rounded-b-lg flex items-center"
          style={{ height: 175 }}
        >
          <PageSettings />
        </div>
      </div>
    );
  }

  renderTree = () => {
    const { store } = this.props;
    const {
      focusTreeIndex,
      goToPath,
      treeGetNodeKey,
      treeData,
      treeSearchQuery,
      updateTreeData,
      buildTreeSearcher,
      treeSearchResult,
      updateTreeSearchResult,
      moveTreeDataNode,
      addTreeSubpage,
      currentPath,
      removeTreePage,
    } = store;

    const currentPathKey = currentPath.join('.');

    const focusOnIndex = focusTreeIndex;
    store.focusTreeIndex = undefined;

    // min 3 characters, helps to focus
    const searchString =
      treeSearchQuery && treeSearchQuery.length > 2 ? treeSearchQuery : undefined;

    return (
      <SortableTree
        rowHeight={55}
        scaffoldBlockPxWidth={34}
        innerStyle={{
          padding: '30px 20px 30px 15px',
        }}
        treeData={treeData.tree}
        onChange={updateTreeData}
        onMoveNode={moveTreeDataNode}
        getNodeKey={treeGetNodeKey}
        reactVirtualizedListProps={{
          scrollToIndex: focusOnIndex || undefined,
        }}
        searchMethod={searchString ? buildTreeSearcher({ searchText: true }) : undefined}
        searchQuery={searchString ? searchString : undefined}
        searchFocusOffset={searchString ? treeSearchResult.searchFocusIndex : undefined}
        searchFinishCallback={
          searchString
            ? matches => {
                updateTreeSearchResult({
                  matches: matches,
                  searchFocusIndex:
                    matches.length > 0
                      ? treeSearchResult.searchFocusIndex || 0 % matches.length
                      : 0,
                });
              }
            : undefined
        }
        canDrop={opts => {
          // cannot add children to $ref pages
          const ref = _.get(opts, 'nextParent.data.$ref');
          if (ref) {
            return false;
          }

          return true;
        }}
        generateNodeProps={opts => {
          const { node, path } = opts;

          let relativePath = _.last(node.viewPath) || '';
          if (relativePath.charAt(0) !== '/') {
            relativePath = `/${relativePath}`;
          }

          const classNames = [];
          if (node.jsonPath.join('.') === currentPathKey) {
            classNames.push('is-active');
          }

          let refElem;
          if (node.data && node.data.$ref) {
            refElem = <div className="mx-2 truncate">{node.data.$ref}</div>;
          }

          return {
            className: classNames.join(' '),

            title: (
              <div className="font-bold opacity-75 mx-2 -mt-1">{node.title || 'No Title'}</div>
            ),

            subtitle: (
              <div className="flex items-center text-muted h-4">
                <div className="mx-2">{relativePath}</div>

                {refElem && (
                  <div className="opacity-50 mx-2">
                    <Icon icon={faLongArrowRight} />
                  </div>
                )}

                {refElem}
              </div>
            ),

            onClick: e => {
              const clickedItemClassName = e.target.className;
              if (
                clickedItemClassName !== 'rst__collapseButton' &&
                clickedItemClassName !== 'rst__expandButton' &&
                clickedItemClassName !== 'rst__moveHandle'
              ) {
                goToPath(node.jsonPath);
              }
            },

            buttons: [
              <Button
                key="1"
                className="mr-2"
                size={sizes.sm}
                color={colors.accent}
                onClick={e => {
                  e.stopPropagation();

                  addTreeSubpage({
                    node: node,
                    treeJSONPath: path,
                  });
                }}
              >
                Add Subpage
              </Button>,
              <Button
                key="2"
                className="mr-3"
                outlined
                size={sizes.sm}
                icon={faTrash}
                onClick={e => {
                  e.stopPropagation();

                  if (relativePath === '/') {
                    alert.error('Deleting the root path (/) is not allowed.');
                  } else {
                    removeTreePage({
                      node: node,
                      treeJSONPath: path,
                    });
                  }
                }}
              />,
            ],
          };
        }}
      />
    );
  };
}

const InjectedTOC = inject(({ hubEditorStore }) => {
  return {
    store: hubEditorStore.activeEditor,
  };
})(observer(TOC));

const TOCModal = props => {
  const { appStore, routerStore } = props;

  const closeModal = () => {
    routerStore.setQueryParams({ tab: null });
    appStore.closeModal();
  };

  return (
    <Modal
      show={appStore.activeModal === 'toc'}
      closeIcon={faTimes}
      onDidHide={closeModal}
      pinY
      renderContent={() => <InjectedTOC handleClose={closeModal} />}
    />
  );
};

export default inject('appStore', 'routerStore')(observer(TOCModal));
