import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Popup, Icon } from 'semantic-ui-react';

import Terminal from '@platform/components/Terminal';

import { buildInstanceUrl } from '@platform/utils/instances';

class InstanceUrl extends React.Component {
  render() {
    const { projectStore } = this.props;

    const instanceUrlDocs = buildInstanceUrl({
      fileHash: _.get(projectStore, 'current.currentFileHash'),
      docs: true,
    });

    const instanceUrl = buildInstanceUrl({
      fileHash: _.get(projectStore, 'current.currentFileHash'),
    });

    return (
      <div className="InstanceEditor-terminal" id="tutorial-instance-terminal">
        <div className="SplitEditor-parsedTitle">
          Automatic Hosted Instance
          <span className="SpecEditor-widgetGroupTip ml-2">
            <Popup
              trigger={<Icon name="question circle" />}
              size="small"
              position="top center"
              hoverable
              wide
            >
              <div>
                <div>Visit this URL to open your Prism instance.</div>

                <div className="mt-3">
                  This base URL will open documentation to better describe the usage of this
                  instance, like making requests via path names of connected specs
                </div>

                <div className="mt-3">
                  <b>example:</b> {instanceUrl}
                  /todos
                </div>

                <div className="mt-3">
                  <b>NOTE:</b> This reads from the Stoplight database, so make sure you have saved
                  your instance back to Stoplight.
                </div>
              </div>
            </Popup>
          </span>
        </div>

        <Terminal className="mt-2" light>
          <a href={instanceUrlDocs} target="_blank">
            {instanceUrlDocs}
          </a>
        </Terminal>
      </div>
    );
  }
}

export default inject(({ projectStore }) => ({
  projectStore,
}))(observer(InstanceUrl));
