import React from 'react';
import _ from 'lodash';
import { Feed, Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { Link } from '@platform/utils/router';
import { timeAgo } from '@platform/utils/time';

import PageLoading from '../PageLoading';

class EventsFeed extends React.Component {
  componentWillMount() {
    const { eventService, type, id } = this.props;
    eventService.findEvents({ type, id });
  }

  render() {
    const { eventService } = this.props;

    if (eventService.isLoading) {
      return <PageLoading inverted={false} />;
    }

    const events = eventService.findLocal({ sort: { created_at: -1 } }).get();

    if (_.isEmpty(events)) {
      return <Message content="There are no events to show here." info />;
    }

    const eventElems = _.map(events, (event, index) => {
      let actionName = _.get(event, 'push_data.action') || event.action_name;
      const path = _.get(event, 'project.path_with_namespace');
      const subtitle = event.target_title || _.get(event, 'push_data.commit_title');

      let eventTarget = _.toLower(event.target_type || _.get(event, 'push_data.ref') || 'project');
      if (event.target_type === 'Issue' && event.target_iid) {
        eventTarget = (
          <Link to={`/${path}?issue=${event.target_iid}`}>issue #{event.target_iid}</Link>
        );
      }

      let eventTargetType = _.get(event, 'push_data.ref_type');

      const icons = {
        tag: 'tag',
        branch: 'fork',
        joined: 'user add',
        created: 'plus circle',
        opened: 'exclamation triangle',
        pushed: 'code',
      };

      let icon = icons[eventTargetType] || icons[actionName];
      if (actionName === 'pushed') {
        icon = icons.pushed;
      }

      // TEMPORARY until we better return data from the events endpoint
      if (eventTargetType === 'tag') {
        eventTarget = eventTarget.replace('release/', 'version ');
        eventTargetType = undefined;
        icon = 'upload';

        if (actionName === 'created') {
          actionName = 'released';
        } else if (actionName === 'removed') {
          actionName = 'unreleased';
        }
      }

      if (eventTargetType === 'branch') {
        eventTarget = eventTarget.replace('/', ' ');
        eventTargetType = undefined;
      }

      return (
        <Feed.Event key={index} className="EventFeed-item">
          <Feed.Label icon={icon} />

          <Feed.Content>
            <Feed.Date>{timeAgo(event.created_at)}</Feed.Date>

            <Feed.Summary>
              {_.get(event, 'author.username')} {actionName} {eventTarget} {eventTargetType} @{' '}
              <Link to={`/${path}`}>{path}</Link>
            </Feed.Summary>

            {subtitle ? (
              <Feed.Meta>
                <div className="flex">
                  <div className="EventFeed-image  mr-3">
                    <div
                      className="h-full w-full"
                      style={{
                        backgroundImage: `url('${_.get(event, 'author.avatar_url')}')`,
                        backgroundSize: 'cover',
                      }}
                    />
                  </div>
                  <div>{subtitle}</div>
                </div>
              </Feed.Meta>
            ) : null}
          </Feed.Content>
        </Feed.Event>
      );
    });

    return <Feed>{eventElems}</Feed>;
  }
}

export default inject('eventService')(observer(EventsFeed));
