import React from 'react';

import PageContent from '../../components/PageContent';
import OrgCreate from '../../components/OrgCreate';

class UserNewOrg extends React.Component {
  render() {
    return (
      <PageContent>
        <OrgCreate />
      </PageContent>
    );
  }
}

export default UserNewOrg;
