import React from 'react';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import ScenarioStep from '@platform/components/ScenarioEditor/step';

import { initCollectionEditor, getCollectionEditor, stopStep, runStep } from './common';

class HttpBlock extends React.Component {
  componentWillMount() {
    if (!getCollectionEditor(this.props).id) {
      initCollectionEditor(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!getCollectionEditor(nextProps).id || nextProps.id === this.props.id) {
      initCollectionEditor(nextProps);
    }
  }

  render = () => {
    const { WrapperFactory, id, editor, config = {}, onChangeData, data } = this.props;
    const currentRunning = !_.isEmpty(getCollectionEditor(this.props).currentRunning);

    const contentElem = (
      <ScenarioStep
        editorId={id}
        handleUpdate={(t, p, v) => {
          onChangeData(t, _.without(p, 'input'), v);
        }}
        inputProps={{
          type: 'http',
          standalone: true,
          config: config,
          tabWhitelist: _.compact([
            'settings',
            'headers',
            'query',
            _.includes(['post', 'patch', 'put'], data.method) ? 'body' : null,
            'auth',
            'code-generation',
          ]),
          endpoints: editor.connectedEndpoints,
          connectedSpecs: editor.connectedSpecs,
          headerElems: [
            {
              render: () => {
                let buttonProps = {};

                if (currentRunning) {
                  buttonProps = {
                    icon: 'stop',
                    content: 'Stop',
                    onClick: () => {
                      stopStep(this.props);
                    },
                    negative: true,
                  };
                } else {
                  buttonProps = {
                    icon: 'play',
                    content: 'Send',
                    onClick: () => {
                      runStep(this.props);
                    },
                    secondary: true,
                  };
                }

                return <Button {...buttonProps} />;
              },
            },
          ],
        }}
        outputProps={{
          tabWhitelist: ['body', 'headers', 'request'],
        }}
      />
    );

    const actions = [];

    // TODO
    // actions.push(
    //   <FormCheckbox
    //     label="Code Generation"
    //     checked={codegen}
    //     onChange={checked => {
    //       const t = checked ? 'set' : 'unset';
    //       onChangeConfig(t, ['codegen'], checked);
    //     }}
    //     inline
    //   />
    // );

    return <WrapperFactory {...this.props} headerActions={actions} contentElem={contentElem} />;
  };
}

export default inject(({ collectionEditorStore }) => {
  return {
    collectionEditorStore,
  };
})(observer(HttpBlock));
