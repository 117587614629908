import React from 'react';
import _ from 'lodash';

import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import MarkdownEditor from '../MarkdownEditor';
import MarkdownViewer from '../MarkdownViewer';
import EntityEditorGroup from '../EntityEditorGroup';
import { PageTitle } from '../EntityViewer';

const SwaggerInfo = props => {
  const { id, value = {}, onChange, afterTitle, beforeGroups, readOnly } = props;

  const {
    contact = {},
    license = {},
    title,
    description = '',
    version,
    termsOfService,
    summary,
  } = value;

  return (
    <div className="SwaggerInfo">
      {readOnly ? (
        <PageTitle title={title} subtitle={version ? `v${version}` : ''} description={summary} />
      ) : (
        <div className="flex">
          <FormInput
            className="flex-1"
            onChange={e => {
              onChange('set', ['title'], e.target.value);
            }}
            input={{
              value: title,
              fluid: true,
              size: 'big',
            }}
            label="title"
            tip="The title of the application."
          />
        </div>
      )}

      <div className="mt-6">
        {readOnly ? (
          <MarkdownViewer className="EntityViewer-padded" value={description} />
        ) : (
          <MarkdownEditor
            id={`${id}:info`}
            value={description}
            placeholder="Write your description here..."
            onChange={val => {
              onChange('set', ['description'], val);
            }}
            tip="A short description of the application. Markdown syntax can be used for rich text representation."
          />
        )}
      </div>

      {afterTitle}

      <div className="flex mt-6">
        {readOnly ? null : (
          <FormInput
            className="flex-1 pr-2"
            onChange={e => {
              onChange('set', ['version'], e.target.value);
            }}
            input={{
              value: version,
              fluid: true,
            }}
            label="version"
            tip="Provides the version of the application API (not to be confused with the specification version)."
          />
        )}

        {readOnly ? (
          <div>{termsOfService}</div>
        ) : (
          <FormInput
            className="flex-1 pl-2"
            onChange={e => {
              onChange('set', ['termsOfService'], e.target.value);
            }}
            input={{
              value: termsOfService,
              fluid: true,
              placeholder: 'http://swagger.io/terms',
            }}
            label="Terms of Service URL"
            tip="The Terms of Service for the API."
          />
        )}
      </div>

      {beforeGroups}

      {readOnly && _.isEmpty(contact) ? null : (
        <EntityEditorGroup
          id="swaggerinfo-contact"
          className="mt-6"
          name="Contact"
          active={!_.isEmpty(contact.name) || !_.isEmpty(contact.url) || !_.isEmpty(contact.email)}
          padded
          contentFactory={() => (
            <div className="flex">
              {readOnly ? (
                <div>{contact.name}</div>
              ) : (
                <FormInput
                  className="flex-1 pr-2"
                  onChange={e => {
                    onChange('set', ['contact', 'name'], e.target.value);
                  }}
                  input={{
                    value: contact.name,
                    fluid: true,
                  }}
                  label="name"
                  tip="The identifying name of the contact person/organization."
                />
              )}

              {readOnly ? (
                <div>{contact.url}</div>
              ) : (
                <FormInput
                  className="flex-1 pl-2 pr-2"
                  onChange={e => {
                    onChange('set', ['contact', 'url'], e.target.value);
                  }}
                  input={{
                    value: contact.url,
                    fluid: true,
                  }}
                  label="url"
                  tip="The URL pointing to the contact information. MUST be in the format of a URL."
                />
              )}

              {readOnly ? (
                <div>{contact.email}</div>
              ) : (
                <FormInput
                  className="flex-1 pl-2"
                  onChange={e => {
                    onChange('set', ['contact', 'email'], e.target.value);
                  }}
                  input={{
                    value: contact.email,
                    fluid: true,
                  }}
                  label="email"
                  tip="The email address of the contact person/organization. MUST be in the format of an email address."
                />
              )}
            </div>
          )}
        />
      )}

      {readOnly && _.isEmpty(license) ? null : (
        <EntityEditorGroup
          id="swaggerinfo-license"
          className="mt-6"
          name="License"
          active={!_.isEmpty(license.name) || !_.isEmpty(license.url)}
          padded
          contentFactory={() => (
            <div className="flex">
              {readOnly ? (
                <div>{license.name}</div>
              ) : (
                <FormSelect
                  className="flex-1 pr-2"
                  label="name"
                  tip="The license name used for the API."
                  value={license.name}
                  onChange={v => {
                    if (v === 'None') {
                      onChange('set', ['license', 'name'], '');
                    } else {
                      onChange('set', ['license', 'name'], v);
                    }
                  }}
                  fluid
                  allowAdditions
                  search
                  selectOnBlur={false}
                  options={_.uniqBy(
                    _.concat(
                      [
                        {
                          text: 'None',
                          value: 'None',
                        },
                      ],
                      license.name
                        ? [
                            {
                              text: license.name,
                              value: license.name,
                            },
                          ]
                        : [],
                      [
                        {
                          text: 'Apache 2.0',
                          value: 'Apache 2.0',
                        },
                        {
                          text: 'BSD-3-Clause',
                          value: 'BSD-3-Clause',
                        },
                        {
                          text: 'BSD-2-Clause',
                          value: 'BSD-2-Clause',
                        },
                        {
                          text: 'GPL-3.0',
                          value: 'GPL-3.0',
                        },
                        {
                          text: 'MIT',
                          value: 'MIT',
                        },
                        {
                          text: 'Mozilla Public License 2.0',
                          value: 'Mpl-6.0',
                        },
                        {
                          text: 'Common Development and Distribution License',
                          value: 'CDDL-1.0',
                        },
                      ]
                    ),
                    'value'
                  )}
                />
              )}

              {readOnly ? (
                <div>{license.url}</div>
              ) : (
                <FormInput
                  className="flex-1 pl-2"
                  onChange={e => {
                    onChange('set', ['license', 'url'], e.target.value);
                  }}
                  input={{
                    value: license.url,
                    fluid: true,
                  }}
                  label="url"
                  tip="A URL to the license used for the API. MUST be in the format of a URL."
                />
              )}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default SwaggerInfo;
