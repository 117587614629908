import defaultAxios from 'axios';
import _ from 'lodash';
import { computed } from 'mobx';

import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BatchHttpLink } from 'apollo-link-batch-http';

import { UITreeStoreManager } from '@core/ui-tree';
import { COMMANDS as EDITOR_COMMANDS } from '@core/editor';

import ApolloGQL from './services/_apollo-link';

import {
  safeStringify,
  safeParse,
  escapeScriptTags,
  unescapeScriptsTags,
} from '@platform/utils/json';
import { getConfigVar } from '@platform/utils/config';
import { stringifyQuery } from '@platform/utils/query';

import { initIcons as initExplorerIcons } from '@platform/explorer/config';

import AppStore from './appStore';
import HomeStore from './homeStore';
import BillingStore from './billingStore';
import PublishStore from './publishStore';
import NamespaceStore from './namespaceStore';
import JsonPathCache from './jsonPathCache';
import ElectronStore from './electronStore';
import HubEditorStore from './hubEditorStore';
import TableListStore from './tableListStore';
import RawEditorStore from './rawEditorStore';
import CssEditorStore from './cssEditorStore';
import FileSystemStore from './fileSystemStore';
import Oas2EditorStore from './oas2EditorStore';
import Oas3EditorStore from './oas3EditorStore';
import JsonSchemaStore from './jsonSchemaStore';
import HtmlEditorStore from './htmlEditorStore';
import OnboardingStore from './onboardingStore';
import LintEditorStore from './lintEditorStore';
import MigrationStore from './migrationStore.js';
import InstanceEditorStore from './instanceEditorStore';
import CollectionEditorStore from './collectionEditorStore';

import { CommandRegistry, CoreEditorManager } from './coreEditor';

import { create as createTagStore } from './tag';
import { create as createErrorStore } from './error';
import { create as createProjectStore } from './project';
import { create as createOauthTokenStore } from './oauth';
import { create as createRefBuilderStore } from './refBuilder';

import Websocket from './websocket';
import { RouterStore } from './router';
import { services, createService } from './services';

class RootStore {
  version;
  releaseStage;

  constructor(opts = {}) {
    const {
      axios,
      api,
      createRoutes,
      location = {}, // initialize with a specific http location
      defaults = {}, // initialize with a user token
      isElectron,
      fetch, // can supply fetch implementation (useful for ssr)
      ...options
    } = opts;

    this.version = getConfigVar('APP_VERSION') || '0.0.0';
    this.releaseStage = getConfigVar('RELEASE_STAGE') || 'development';

    // so we don't have to do this check everywhere, can just check isClient :)
    this.isClient = typeof window !== 'undefined' && window.localStorage ? true : false;
    this.isElectron = isElectron;

    // build the routes
    if (createRoutes) {
      this.routes = createRoutes({ rootStore: this });
    } else if (process.env.NODE_ENV !== 'test') {
      console.warn(
        'RootStore instantiated without a createRoutes function! Outside of testing, you normally need to provide this function.'
      );
    }

    const apiHost = getConfigVar('SL_API_HOST');

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.log('GraphQL errors: ', graphQLErrors);

        _.map(graphQLErrors, ({ code, message, locations, path }) => {
          console.log(
            `[GraphQL error]: Code: ${code}, Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    });

    const apolloLink = new ApolloGQL();
    this.apolloLink = apolloLink;

    const authLink = setContext((x, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          'Session-Cookie': _.get(this, 'stores.userService.sessionCookie') || undefined,
        },
      };
    });

    const httpLink = new BatchHttpLink({
      uri: `${apiHost}/graphql`,
      credentials: 'include',
      fetch,
      batchInterval: 50,
    });

    // setup the global graphql client
    this.apollo = new ApolloClient({
      ssrMode: !this.isClient,
      link: ApolloLink.from([errorLink, apolloLink, authLink, httpLink]),
      cache: new InMemoryCache().restore(this.isClient ? window.__APOLLO_STATE__ || {} : {}),
      ssrForceFetchDelay: 1000,
    });

    apolloLink.apolloClient = this.apollo;

    // setup the global api requester
    // allow overriding defaults, useful for testing
    this.axios = axios || defaultAxios;
    this.api =
      api ||
      axios ||
      defaultAxios.create({
        baseURL: getConfigVar('SL_API_HOST'),
        paramsSerializer(params) {
          return stringifyQuery(params);
        },
      });

    if (this.isClient) {
      this.Websocket = new Websocket({ rootStore: this });
    }

    /**
     * TODO: can't use until this is fixed (there are other todos related to this in the code)
     * https://github.com/axios/axios/issues/385#issuecomment-339199333
     */
    // if we already have a token, let's use it!
    // on the server, this is passed in when creating the root store, if it is present in a cookie
    // if (defaults.token) {
    //   this.api.defaults.headers.common['private-token'] = defaults.token;
    // }
    // this.api.defaults.headers.common['App-Version'] = this.version;

    // create the state models
    this._stores = {
      // services are available here, AND at {name}Service.
      // not duplicated, but just point to same reference
      // useful for dehyrate function, to know what is a service and what not
      services: {},
    };

    // build up all the api domain models first
    _.keys(services).forEach(name => {
      const service = createService({
        rootStore: this,
        name,
        data: defaults,
        options,
      });

      this._stores.services[name] = this._stores[`${name}Service`] = service;
    });

    this._stores.routerStore = new RouterStore({ rootStore: this, routes: this.routes, location });
    this._stores.coreEditorStore = new CoreEditorManager({ rootStore: this });

    /**
     * Create the custom stores second, so that they can use the services if necessary.
     */
    Object.assign(this._stores, {
      electronStore: new ElectronStore({ rootStore: this }),
      appStore: new AppStore({ rootStore: this }),
      homeStore: new HomeStore({ rootStore: this }),
      tableListStore: new TableListStore({ rootStore: this }),

      fileSystemStore: new FileSystemStore({ rootStore: this }),
      oas2EditorStore: new Oas2EditorStore({ rootStore: this }),
      oas3EditorStore: new Oas3EditorStore({ rootStore: this }),
      collectionEditorStore: new CollectionEditorStore({ rootStore: this }),
      instanceEditorStore: new InstanceEditorStore({ rootStore: this }),
      hubEditorStore: new HubEditorStore({ rootStore: this }),
      jsonSchemaStore: new JsonSchemaStore({ rootStore: this }),
      rawEditorStore: new RawEditorStore({ rootStore: this }),
      htmlEditorStore: new HtmlEditorStore({ rootStore: this }),
      cssEditorStore: new CssEditorStore({ rootStore: this }),
      lintEditorStore: new LintEditorStore({ rootStore: this }),

      jsonPathCache: new JsonPathCache({ rootStore: this }),

      errorStore: createErrorStore({ env: { rootStore: this } }),
      refBuilderStore: createRefBuilderStore({ env: { rootStore: this } }),
      tagStore: createTagStore({ env: { rootStore: this } }),
      projectStore: createProjectStore({ env: { rootStore: this } }),
      oauthTokenStore: createOauthTokenStore({ env: { rootStore: this } }),
      billingStore: new BillingStore({ rootStore: this }),
      onboardingStore: new OnboardingStore({ rootStore: this }),
      migrationStore: new MigrationStore({ rootStore: this }),
      uiTreeStore: new UITreeStoreManager({ rootStore: this }),
      publishStore: new PublishStore({ rootStore: this }),

      namespaceStore: new NamespaceStore({ rootStore: this }),
    });

    if (this.isClient) {
      CommandRegistry.registerHandler(EDITOR_COMMANDS.route, {
        execute: (location, { replace } = {}) => {
          if (replace) {
            if (location.query && !location.pathname) {
              this._stores.routerStore.setQueryParams(location.query, {
                preserve: false,
              });
            } else {
              this._stores.routerStore.replace(location);
            }
          } else {
            if (location.query && !location.pathname) {
              this._stores.routerStore.setQueryParams(location.query, {
                preserve: true,
              });
            } else {
              this._stores.routerStore.push(location);
            }
          }
        },
      });
    }

    /**
     * TEMPORARY
     * REMOVE WHEN EXPLORER IS EXTRACTED
     */
    initExplorerIcons();
  }

  get stores() {
    return this._stores;
  }

  /*
   * server -> client data passing.
   * The server sends down:
   *
   * 1. The projectStore data. This is useful so that we have the project .stoplight.yml config file on load.
   * 2. The routerStore data. This includes stuff like computed props, loadDataError, etc.
   * 3. All services data.
   */

  // DEPRECATED
  // Used on the server to prepare appropriate data to send down to client.
  dehydrate() {
    const services = {};

    _.forOwn(this.stores.services, (s, k) => {
      // allow services to opt out of dehydrate by defining a skipDehydrate function
      if (!s.skipDehydrate || !s.skipDehydrate()) {
        services[k] = s;
      }
    });

    const data = safeStringify(
      {
        stores: {
          routerStore: this.stores.routerStore,
          projectStore: this.stores.projectStore,
          services,
        },
      },
      (k, v) => {
        // don't recurse through the nested rootStore reference that stores have
        if (_.includes(['rootStore'], k)) {
          return undefined;
        }

        return v;
      }
    );

    return escapeScriptTags(data);
  }

  // DEPRECATED
  // Used on the client to populate the stores with data the server sent down.
  rehydrate({ data = {}, options = {} }) {
    if (_.isEmpty(data)) {
      return;
    }

    data = safeParse(unescapeScriptsTags(safeStringify(data)));

    if (!_.isEmpty(data.stores)) {
      this.stores.routerStore = new RouterStore({
        rootStore: this,
        routes: this.routes,
        ...data.stores.routerStore,
      });

      this.stores.projectStore = createProjectStore({
        data: data.stores.projectStore,
        env: { rootStore: this },
      });

      _.forEach(_.get(data, 'stores.services'), (data, name) => {
        if (this.stores.services[name]) {
          const service = createService({
            rootStore: this,
            name,
            data,
            options,
          });

          this._stores.services[name] = this._stores[`${name}Service`] = service;
        }
      });
    }
  }

  /**
   * Anything that calls the loadRouteData function should catch its error and assign to
   * rootStore when appropriate. Components can watch this to display the appropriate screen.
   */
  @computed
  get loadRouteDataErr() {
    const e = _.get(this.stores, 'routerStore.loadDataError');

    if (!e || _.isEmpty(e)) {
      return null;
    }

    if (e.response) {
      return e.response;
    }

    if (e.message) {
      return e;
    }

    return String(e);
  }

  clearServiceErrors() {
    _.forEach(this.stores.services, service => {
      service.setError();
    });
  }
}

export const createRootStore = opts => {
  return new RootStore(opts);
};
