module.exports = {
  NODE_ENV: 'production',
  RELEASE_STAGE: 'staging',
  BUILD_ID: '000',

  // SL_APP_HOST: 'https://staging.stoplight-dev.com',
  // SL_API_HOST: 'https://api.staging.stoplight-dev.com',
  // SL_GITLAB_HOST: 'https://gitlab.staging.stoplight-dev.com',
  // SL_PRISM_HOST: 'https://prism.stoplight-dev.com',
  // SL_PUBS_HOST: 'pubs.staging.stoplight-dev.com',
  // SL_PUBS_INGRESS: 'ingress.pubs.staging.stoplight-dev.com',
  // SL_MIGRATION_HOST:
  //   'https://gi2tgotnmfzxizlshj2gk43uomxhgy3fnzqxe2lpomxhs3lm.prism-staging.stoplight-dev.com',

  // AWS_BUCKET: 'user-content-staging.stoplight.io',
  // GITHUB_CLIENT_ID: 'fa40e53e1eb70a976deb',
  // GTM_KEY: 'GTM-NXFDDH9',
  // GA_KEY: 'UA-73790375-7',
  // INTERCOM_KEY: 'hujuq0zi',
  // STRIPE_PK: 'pk_test_kpBgbzToWW21hZDan1nRNnVF',
  // ACCOUNT_DOCK_KEY: 'ad_acco_a67qwbr1k0j39lmz',
  // BUGSNAG_KEY: '45837fb90fa3085732214a960e9545ea',
  // FULLSTORY_KEY: '7CQBR',
  // HEAP_KEY: '904679970',
};
