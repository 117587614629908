import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import Filters from '@platform/components/Filters';

import UserProfileLayout from './_layout';

class UserFiltersPage extends React.Component {
  componentWillMount() {
    const { store, user } = this.props;
    store.find({ namespaceId: user.username });
  }

  render() {
    const { store, user } = this.props;
    const tags = store.tags.toJS();

    return (
      <UserProfileLayout activeTab="filters">
        <Filters
          namespaceId={user.username}
          store={store}
          tags={tags}
          tagType="lifecycle"
          headerName="Lifecycle"
          headerDescription="Use lifecycle tags to organize the various stages of your development process."
        />

        <Filters
          namespaceId={user.username}
          store={store}
          tags={tags}
          headerName="Tags"
          headerDescription="Use tags for arbitrary organization."
        />
      </UserProfileLayout>
    );
  }
}

export default inject(({ tagStore, userService }) => {
  const user = _.get(userService, 'authorizedUser', {});
  let store = tagStore.register(`UserProfileFilter-${user.username}`);

  return {
    store,
    user,
  };
})(observer(UserFiltersPage));
