import { action, observable } from 'mobx';

import { MODES } from '@core/editor';
import { COMMANDS } from '@core/spec-html';
import { HTMLReader } from '@core/lang-html';

import rawEditorStore, { RawEditor } from './rawEditorStore';
import { htmlExtension } from './coreEditor';

class HtmlEditor extends RawEditor {
  _extension = htmlExtension;
  htmlReader = new HTMLReader();

  @observable
  preview = false;

  activate() {
    super.activate();

    if (!this.rootStore.isClient) return;

    this.registerListeners();
    this.registerCommands();

    if (this.isEditing) {
      return this.goToEditor();
    }

    return this.goToViewer();
  }

  registerListeners = () => {
    this._disposables.push(
      this._editor.onDidChangeMode(({ mode }) => {
        if (!mode) return;

        if (mode.id === MODES.read.id && !this.isViewing) {
          this.goToViewer();
        } else if (mode.id !== MODES.read.id && !this.isEditing) {
          this.goToEditor();
        }
      })
    );
  };

  registerCommands = () => {
    this._disposables.push(
      this._commandRegistry.registerHandler(COMMANDS.format, {
        execute: this.format,
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(COMMANDS.showTheme, {
        execute: this.showTheme,
      })
    );

    this._disposables.push(
      this._commandRegistry.registerHandler(COMMANDS.togglePreview, {
        execute: this.togglePreview,
      })
    );
  };

  onDidChangeMode = ({ mode }) => {
    if (!mode) return;

    let modeId = mode.id;

    if (modeId === MODES.design.id) {
      modeId = MODES.code.id;
    }

    this.setCurrentContentTab(modeId);
  };

  dereferenceParsed() {
    // Don't need to dereference
  }

  @action
  format = () => {
    this.spec = this.htmlReader.format(this.spec);
  };

  showTheme = () => {
    this.goToViewer(null, { theme: 'css' });
  };

  @action
  togglePreview = () => {
    this.preview = !this.preview;
  };
}

export default class HtmlEditorStore extends rawEditorStore {
  editorClass = HtmlEditor;
}
