import { types, flow } from 'mobx-state-tree';
import _ from 'lodash';

import { BaseStore } from './_base';
import { BaseManager, BaseInstance } from './_manager';
import { BaseService } from './services/_base';

export const create = ({ data, env, options = {} }) => {
  const Tag = types
    .model({
      basePath: '/namespaces/:namespaceId/tags',
      tags: types.optional(types.array(types.frozen()), []),
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      const originalFind = self.find;

      return {
        find: flow(function*(params) {
          self.isLoading = true;
          const authorizedUser = _.get(env.rootStore, 'stores.userService.authorizedUser', {});
          const namespaceId = params.namespaceId || params.userId || authorizedUser.id;

          if (!namespaceId) {
            return;
          }

          const tags = yield originalFind({ namespaceId });
          self.isLoading = false;
          self.tags = tags;

          return tags;
        }),

        updateBulk: flow(function*(id, data = {}) {
          self.error = undefined;
          self.isSaving = true;
          self.isUpdating = true;

          let records = [];

          const res = yield self.send(
            {
              method: 'put',
              path: `${self.basePath}`,
              data,
            },
            { params: { namespaceId: id } }
          );

          records = self.updateRecords(_.get(res, 'data.data', []));
          records = _.map(records, 'data');

          records.sort(function(a, b) {
            return a.id - b.id;
          });

          self.isSaving = false;
          self.isUpdating = false;
          self.tags = records;

          return records;
        }),
      };
    });

  const TagInstance = types
    .compose(
      BaseStore,
      BaseInstance,
      BaseService,
      Tag
    )
    .named('TagInstance');

  const Base = types
    .model({
      instances: types.optional(types.array(TagInstance), []),
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      self.instanceModel = TagInstance;

      return {};
    });

  const Service = types
    .compose(
      BaseStore,
      BaseManager,
      Base
    )
    .named('TagStore');

  return Service.create(data, env);
};
