export * from './spec';

export { IDisposable, Collection as DisposableCollection } from '@core/disposable';
export { MODES as EDITOR_MODES, MENUS as EDITOR_MENUS } from '@core/editor';
export { IEditor, IEditorMode, IModeChangedEvent } from '@core/editor';
export { IIcon } from '@core/ui';

/**
 * UI
 */

import { colors, sizes } from '@core/ui';

const UI = {
  colors,
  sizes,
};

export { UI };
