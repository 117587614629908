import React from 'react';
import _ from 'lodash';
import TimeAgo from 'react-timeago';
import { Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';

import Terminal from '@platform/components/Terminal';
import PageLoading from '@platform/components/PageLoading';

import { alert } from '@platform/utils/alert';
import { getConfigVar } from '@platform/utils/config';
import { getBuildLink } from '@platform/utils/publishing';

import { sizes, colors, Button } from '@core/ui';

const BuildLogs = props => {
  const { logs = [] } = props;

  const orderedLogs = _.sortBy(logs, log => Number(log.timestamp));
  const firstLog = _.first(orderedLogs);

  return (
    <Terminal
      autoscroll
      className="rounded-none overflow-y-scroll"
      lines={_.map(orderedLogs, log => {
        // Find the seconds from the first log
        const timeDiff = _.round((Number(log.timestamp) - Number(firstLog.timestamp)) / 1000, 2);

        return {
          type: 'command',
          name: `[+${_.padEnd(timeDiff, 5, '0')}s]`,
          text: `${log.log}\n`,
        };
      })}
      style={{
        height: '400px',
      }}
    >
      {!logs.length ? (
        <PageLoading size="small" inverted>
          Preparing logs...
        </PageLoading>
      ) : null}
    </Terminal>
  );
};

const BuildRow = props => {
  const {
    index,
    build,
    isLive,
    downloadEnabled,
    publishStore,
    projectStore,
    updateBuildHandler,
  } = props;

  let action;
  let statusColor;
  let downloadLink;

  const filePath = _.get(build, 'config.filePath');
  const buildLink = getBuildLink({ domain: publishStore.domain, build, isLive });
  const showLogs = publishStore.showLogs.get(build.id);
  const buildLogs = publishStore.buildLogs.get(build.id);
  const buildStatus = _.get(build, 'status.code');

  switch (buildStatus) {
    case 'success':
      statusColor = 'green';
      downloadLink = (
        <a
          key="download"
          href={
            downloadEnabled
              ? `${getConfigVar('SL_API_HOST')}/docs.builds.download?id=${build.id}`
              : undefined
          }
          target="_blank"
          title={downloadEnabled ? 'Download' : 'Upgraded docs plan required'}
        >
          <Button outlined color={colors.grey} size={sizes.sm} icon={faDownload} />
        </a>
      );

      action = (
        <Button
          outlined
          fluid
          color={colors.grey}
          size={sizes.sm}
          disabled={publishStore.isPublishing}
          onClick={() => {
            if (publishStore.isPublishing) return;

            const c = window.confirm(
              `Are you sure you want to set this build as the live Hub for ${
                publishStore.domain
              }?\n\nYou can view this build before setting it live by going to ${buildLink}`
            );
            if (!c) return;

            // Set build live on domain
            updateBuildHandler
              .run({
                variables: {
                  id: build.id,
                  input: { setLive: true },
                },
              })
              .then(() => {
                alert.success(`Build ${build.id} is now live at ${publishStore.domain}`);
              })
              .catch(e => {
                console.error('Error updating build:', e);
                alert.error('Error updating build. Please try again.', e.message);
              });
          }}
        >
          Set Live
        </Button>
      );
      break;
    case 'pending':
    case 'building':
      statusColor = 'orange';

      action = (
        <Button
          fluid
          key="action"
          color={colors.red}
          size={sizes.sm}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            const c = window.confirm(`Are you sure you want to cancel this build?`);
            if (!c) return;

            updateBuildHandler
              .run({
                variables: {
                  id: build.id,
                  input: { status: { code: 'canceled' } },
                },
              })
              .then(() => {
                publishStore.setValue('_publishing', false);
                publishStore.toggleShowLogs(build.id, false);
                alert.warning('Build canceled.');
              })
              .catch(e => {
                console.error('Error canceling build:', e);
                alert.error('Error canceling build. Please try again.', e.message);
              });
          }}
        >
          Cancel
        </Button>
      );
      break;
    case 'canceled':
    case 'error':
      statusColor = 'red';
      break;
    default:
      break;
  }

  if (isLive) {
    action = (
      <Button fluid color={colors.green} size={sizes.sm}>
        <a
          href={buildLink}
          className="text-white hover:text-white"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Live
        </a>
      </Button>
    );
  }

  const rows = [
    <Table.Row
      key={build.id}
      id={`Build-${build.id}`}
      positive={isLive}
      onClick={() => {
        if (showLogs || (buildLogs && buildLogs.length)) publishStore.toggleShowLogs(build.id);
      }}
    >
      <Table.Cell className="font-bold">
        {buildLink ? (
          <a className="underline" href={buildLink} target="_blank" rel="noopener noreferrer">
            {index}
          </a>
        ) : (
          index
        )}
      </Table.Cell>
      <Table.Cell>
        {build.createdAt ? (
          <TimeAgo date={build.createdAt} minPeriod={60} />
        ) : (
          <div className="italic">In progress</div>
        )}
      </Table.Cell>
      <Table.Cell>
        {filePath && (
          <a className="cursor-pointer" onClick={() => projectStore.goToFile(filePath)}>
            {filePath}
          </a>
        )}
      </Table.Cell>
      <Table.Cell className={`text-${statusColor}`}>{buildStatus}</Table.Cell>
      <Table.Cell>{publishStore.canPublish && action}</Table.Cell>
      <Table.Cell textAlign="center">{downloadLink}</Table.Cell>
    </Table.Row>,
  ];

  if (showLogs) {
    rows.push(
      <Table.Row key={`${build.id}-logs`}>
        <Table.Cell colSpan="7" style={{ padding: 0, maxWidth: '400px' }}>
          <BuildLogs logs={buildLogs} />
        </Table.Cell>
      </Table.Row>
    );
  }

  return rows;
};

export default inject(stores => {
  const { projectStore, publishStore } = stores;

  return {
    publishStore: publishStore.current,
    projectStore: projectStore.current,
  };
})(observer(BuildRow));
