import React from 'react';
import _ from 'lodash';
import { Form, Button, Popup, Segment } from 'semantic-ui-react';

import { alert } from '@platform/utils/alert';

import DesktopSettingsLayout from './_layout';

class DesktopSettingsNetworking extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getConfig();
  }

  getConfig = () => {
    if (typeof window !== 'undefined') {
      this.config = _.get(window, 'Electron.config');
      return _.pick(this.config.get('networking'), 'apiHost', 'prismHost');
    }

    return {};
  };

  handleUpdate = (t, p, v) => {
    const state = this.state;
    _.set(state, p, v);
    this.setState(state);
  };

  handleSave = () => {
    for (const prop in this.state) {
      this.config.set(`networking.${prop}`, this.state[prop]);
    }

    this.handleReset();
    alert.success('Settings Updated. They will take effect after you restart Stoplight.');
  };

  handleReset = () => {
    this.setState(this.getConfig());
  };

  render() {
    if (!this.config) {
      return <Segment>This is only supported in the desktop app.</Segment>;
    }

    const state = this.state;
    const isDirty = !_.isEqual(state, this.getConfig());

    return (
      <DesktopSettingsLayout activeTab="enterprise">
        <Segment>
          <Form>
            <Popup
              position="bottom right"
              size="tiny"
              wide
              trigger={
                <Form.Field
                  label="Stoplight API Host"
                  name="apiHost"
                  placeholder={'for example, `https://api.stoplight.io`'}
                  value={_.get(this.state, ['apiHost'], '')}
                  control="input"
                  onChange={e => this.handleUpdate('set', 'apiHost', e.target.value)}
                  size="tiny"
                />
              }
              content="The URL for your on premise Stoplight API instance."
            />

            <Popup
              position="bottom right"
              size="tiny"
              wide
              trigger={
                <Form.Field
                  label="Stoplight Prism Host"
                  name="prismHost"
                  placeholder={'for example, `https://prism.stoplight.io`'}
                  value={_.get(this.state, ['prismHost'], '')}
                  control="input"
                  onChange={e => this.handleUpdate('set', 'prismHost', e.target.value)}
                  size="tiny"
                />
              }
              content="The URL for your on premise Stoplight Prism instance."
            />

            <div className="pt-1">
              <Button
                primary={isDirty}
                disabled={!isDirty}
                content="Save Settings"
                onClick={this.handleSave}
              />

              <Button
                icon="remove"
                content="Reset"
                floated="right"
                basic
                disabled={!isDirty}
                onClick={this.handleReset}
              />
            </div>
          </Form>
        </Segment>
      </DesktopSettingsLayout>
    );
  }
}

export default DesktopSettingsNetworking;
