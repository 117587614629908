import { types, flow } from 'mobx-state-tree';
import _ from 'lodash';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({})
    .views(self => {
      return {};
    })
    .actions(self => {
      return {
        getRecordId(record) {
          return `${record.id}`;
        },

        findEvents: ({ type, id }) => {
          let path = '/events';

          if (type && id) {
            path = `/${type}s/${id}/events`;
          }

          return flow(function*() {
            self.isLoading = true;

            let events;
            try {
              const res = yield self.send({
                method: 'get',
                path,
                query: { per_page: self.perPage },
              });
              events = res.data;
              self.replaceRecords(_.get(events, 'data', []));
            } catch (e) {
              self.error = _.get(e, 'response.data', String(e));
            }

            self.isLoading = false;

            return events;
          })();
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('EventService');

  return Service.create(data, env);
};
