let TreeUtils: any = {};

if (typeof window !== 'undefined') {
  TreeUtils = require('react-sortable-tree');
}

export const getFlatDataFromTree = TreeUtils.getFlatDataFromTree;
export const getTreeFromFlatData = TreeUtils.getTreeFromFlatData;
export const isDescendant = TreeUtils.isDescendant;

// import { ILock, ITree, IVirtualFile } from './types';

// export const fromTreeToLock = (tree: ITree): ILock => {
//   const lock: ILock = { files: [] };

//   if (!tree || !tree.children) return lock;

//   return lock;
// };

// export const fromLockToTree = (lock: ILock): ITree => {
//   const tree: ITree = { children: [] };

//   if (!lock) return tree;

//   return tree;
// };

// export const fromFilesToLock = (files: IVirtualFile[]): ILock => {
//   const lock: ILock = { files: [] };

//   if (!files || !files.length) return lock;

//   return lock;
// };
