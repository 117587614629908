import _ from 'lodash';

import GlobalNav from './components/GlobalNav';

import Start from './pages/start';
import Join from './pages/join';
import Login from './pages/login';
import Logout from './pages/logout';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import ConfirmEmail from './pages/confirm-email';
import NotFound from './pages/not-found';
import Oauth from './pages/oauth';

import DesktopSettingsNetworking from './pages/desktop/settings/networking';
import DesktopSettingsEnterprise from './pages/desktop/settings/enterprise';
import DesktopSettingsPrism from './pages/desktop/settings/prism';
import DesktopSettingsHelp from './pages/desktop/settings/help';

import UserPageHeader from './components/UserPageHeader';
import UserProfile from './pages/users/profile';
import UserNotifications from './pages/users/profile/notifications';
import UserAccessTokens from './pages/users/profile/access-tokens';
import UserFilters from './pages/users/profile/filters';
import UserProjects from './pages/users/projects';
import UserNewProject from './pages/users/newProject';
import UserNewOrg from './pages/users/newOrg';
import UserActivity from './pages/users/activity';
import UserExplorer from './pages/users/explorer';

import OrgPageHeader from './components/OrgPageHeader';
import OrgMembers from './pages/orgs/members';
import OrgTeams from './pages/orgs/teams';
import OrgNewTeam from './pages/orgs/newTeam';
import OrgSettings from './pages/orgs/settings';
import OrgSettingsFilters from './pages/orgs/settings/filters';
import OrgSettingsSSO from './pages/orgs/settings/sso';
import OrgSettingsBilling from './pages/orgs/settings/billing';
import OrgSettingsAdmin from './pages/orgs/settings/admin';
import OrgProjects from './pages/orgs/projects';
import OrgExplorer from './pages/orgs/explorer';
import OrgNewProject from './pages/orgs/newProject';
import OrgActivity from './pages/orgs/activity';
import OrgMigrate from './pages/orgs/migrate';
import OrgInvite from './pages/orgs/invite';

import TeamPageHeader from './components/TeamPageHeader';
import TeamActivity from './pages/teams/activity';
import TeamProjects from './pages/teams/projects';
import TeamMembers from './pages/teams/members';
import TeamSettings from './pages/teams/settings';

import ProjectHead from './components/ProjectHead';
import ProjectFile from './pages/projects/file';
import ProjectSettings from './pages/projects/settings';
import ProjectGutter from './components/ProjectGutter';
import ProjectSidebar from './components/ProjectSidebar';
import PageLoading from './components/PageLoading';

import { getConfigVar } from '@platform/utils/config';
import { alert } from '@platform/utils/alert';
import lintFile from '@platform/utils/commit/templates/lint';

export const createRoutes = ({ rootStore } = {}) => {
  const isOnPrem = !getConfigVar('STRIPE_PK');

  return [
    // always attempt to load the authorized user
    {
      path: '/',

      // Try to fetch the authorized user no matter the route
      loadData: async () => ({
        authorizedUser: await rootStore.stores.userService.getAuthorizedUser(),
      }),

      layout: () => ({
        globalHeader: {
          id: rootStore.stores.userService.isLoggedIn ? 'logged-in' : 'logged-out',
          component: GlobalNav,
        },
      }),

      routes: _.compact([
        // Misc routes

        {
          path: '/',
          props: () => ({
            title: rootStore.stores.userService.isLoggedIn ? 'My Activity' : 'Join',
            isLoggedIn: rootStore.stores.userService.isLoggedIn,
          }),

          layout: ({ context }) => {
            const { isLoggedIn } = context.props || {};

            return {
              globalHeader: { component: GlobalNav },
              bodyHeader: {
                component: isLoggedIn ? UserPageHeader : undefined,
              },
              bodyContent: { component: isLoggedIn ? Start : Join },
            };
          },
        },

        {
          path: '/desktop/settings',

          props: () => ({
            title: 'Desktop Settings',
          }),

          layout: () => ({
            bodyContent: {
              component: DesktopSettingsNetworking,
            },
          }),

          routes: [
            {
              path: '/networking',
              props: () => ({
                title: 'Desktop Network Settings',
              }),
            },

            {
              path: '/prism',
              props: () => ({
                title: 'Desktop Prism Settings',
              }),
              layout: () => ({
                bodyContent: {
                  component: DesktopSettingsPrism,
                },
              }),
            },

            {
              path: '/enterprise',
              props: () => ({
                title: 'Desktop Enterprise Settings',
              }),
              layout: () => ({
                bodyContent: {
                  component: DesktopSettingsEnterprise,
                },
              }),
            },

            {
              path: '/help',
              props: () => ({
                title: 'Desktop Settings Help',
              }),
              layout: () => ({
                bodyContent: {
                  component: DesktopSettingsHelp,
                },
              }),
            },
          ],
        },

        {
          path: '/join',

          props: () => ({
            title: 'Join',
          }),

          layout: () => ({
            bodyContent: {
              component: Join,
            },
          }),
        },

        {
          path: '/login',

          props: () => ({
            title: 'Login',
          }),

          layout: () => ({
            bodyContent: {
              component: Login,
            },
          }),
        },

        {
          path: '/logout',

          props: () => ({
            title: 'Logout',
          }),

          layout: () => ({
            bodyContent: {
              component: Logout,
            },
          }),
        },

        {
          path: '/forgot-password',

          props: () => ({
            title: 'Forgot Password',
          }),

          layout: () => ({
            bodyContent: {
              component: ForgotPassword,
            },
          }),
        },

        {
          path: '/reset-password',

          props: () => ({
            title: 'Reset Password',
          }),

          layout: () => ({
            bodyContent: {
              component: ResetPassword,
            },
          }),
        },

        {
          path: '/confirm-email',

          props: () => ({
            title: 'Confirm Email',
          }),

          layout: () => ({
            bodyContent: {
              component: ConfirmEmail,
            },
          }),
        },

        // User Routes
        {
          path: '/projects',
          props: () => {
            const { showPersonalProjects } = rootStore.stores.userService;

            return {
              title: showPersonalProjects ? 'Projects' : 'Not Found',
            };
          },
          layout: () => {
            const { showPersonalProjects } = rootStore.stores.userService;

            if (!showPersonalProjects) {
              return {
                bodyContent: {
                  component: NotFound,
                },
              };
            }

            return {
              bodyHeader: {
                component: UserPageHeader,
                props: {
                  activeTab: 'projects',
                },
              },
              bodyContent: {
                component: UserProjects,
              },
            };
          },
        },

        {
          path: '/activity',
          props: () => ({
            title: 'Projects',
          }),
          layout: () => ({
            bodyHeader: {
              component: UserPageHeader,
              props: {
                activeTab: 'activity',
              },
            },
            bodyContent: {
              component: UserActivity,
            },
          }),
        },

        {
          path: '/profile',

          props: () => ({
            title: 'Profile',
          }),

          layout: () => ({
            bodyHeader: {
              component: UserPageHeader,
              props: {
                activeTab: 'settings',
              },
            },
            bodyContent: {
              component: UserProfile,
            },
          }),

          routes: _.compact([
            {
              path: '/notifications',
              props: () => ({
                title: 'Notifications',
              }),
              layout: () => ({
                bodyContent: {
                  component: UserNotifications,
                },
              }),
            },

            {
              path: '/access-tokens',
              props: () => ({
                title: 'Access Tokens',
              }),
              layout: () => ({
                bodyContent: {
                  component: UserAccessTokens,
                },
              }),
            },

            {
              path: '/filters',
              props: () => ({
                title: 'Filters',
              }),
              layout: () => ({
                bodyContent: {
                  component: UserFilters,
                },
              }),
            },
          ]),
        },

        // Helpful user redirects

        {
          path: '/help',
          redirect: 'https://docs.stoplight.io',
        },

        {
          path: '/users',
          routes: [
            {
              path: '/sign_in',
              redirect: ({ match }) => ({ ...match.location, pathname: '/login' }),
            },
            {
              path: '/sign_out',
              redirect: '/logout',
            },
            {
              path: '/password/new',
              redirect: ({ match }) => ({ ...match.location, pathname: '/forgot-password' }),
            },
            {
              path: '/password/edit',
              redirect: ({ match }) => ({ ...match.location, pathname: '/reset-password' }),
            },
            {
              path: '/confirmation/new',
              redirect: ({ match }) => ({ ...match.location, pathname: '/confirm-email' }),
            },
            {
              path: '/confirmation',
              redirect: ({ match }) => ({ ...match.location, pathname: '/confirm-email' }),
            },
          ],
        },

        // Migrations
        {
          path: '/migrate',

          props: () => ({
            title: 'Migrate',
          }),

          layout: () => ({
            bodyContent: {
              component: OrgMigrate,
            },
          }),
        },

        // DEPRECATED (in modal now)
        {
          path: '/projects/new',
          props: () => {
            const { showPersonalProjects } = rootStore.stores.userService;

            return {
              title: showPersonalProjects ? 'New Project' : 'Not Found',
            };
          },
          layout: () => {
            const { showPersonalProjects } = rootStore.stores.userService;

            if (!showPersonalProjects) {
              return {
                bodyContent: {
                  component: NotFound,
                },
              };
            }

            return {
              bodyHeader: {
                component: UserPageHeader,
              },
              bodyContent: {
                component: UserNewProject,
              },
            };
          },
        },

        // Handle incoming oauth redirects

        {
          path: '/oauth/:provider/:status?',
          layout: () => ({
            bodyContent: {
              component: Oauth,
            },
          }),
        },

        // Namespaces

        {
          path: '/:namespaceId',
          loadData: async ({ match }) => ({
            namespace: await rootStore.stores.namespaceService.get(match.params.namespaceId),
          }),
          props: ({ context = {} }) => {
            return {
              namespaceType:
                _.get(context, 'props.namespaceType') ||
                _.get(rootStore, 'stores.namespaceService.current.namespace_type'),
            };
          },
          layout: ({ context }) => ({
            bodyHeader: {
              component: context.namespaceType === 'user' ? UserPageHeader : OrgPageHeader,
              props: {
                activeTab: context.props.activeTab,
              },
            },
          }),

          routes: [
            {
              path: '/activity',
              props: () => ({
                title: 'Activity',
                activeTab: 'activity',
              }),
              layout: ({ context }) => ({
                bodyContent: {
                  component: context.props.namespaceType === 'user' ? UserActivity : OrgActivity,
                },
              }),
            },

            {
              path: '/explorer',
              props: () => ({
                title: 'Explorer',
                activeTab: 'explorer',
              }),
              layout: ({ context }) => ({
                bodyContent: {
                  component: context.props.namespaceType === 'user' ? UserExplorer : OrgExplorer,
                },
              }),
            },

            // DEPRECATED (in modal now)
            {
              path: '/projects/new',
              props: () => ({
                title: 'New Project',
              }),
              layout: ({ context }) => ({
                bodyContent: {
                  component: OrgNewProject,
                },
              }),
            },

            {
              path: '/projects',
              redirect: ({ match }) => `/${match.params.namespaceId}`,
            },

            {
              path: '/',
              props: () => ({
                title: 'Projects',
                activeTab: 'projects',
              }),
              layout: ({ context }) => {
                return {
                  bodyContent: {
                    component: OrgProjects,
                  },
                };
              },
            },
          ],
        },

        // Organizations

        {
          path: '/orgs/new',
          props: () => ({
            title: 'Projects',
            activeTab: 'projects',
          }),
          layout: ({ context }) => ({
            bodyHeader: {
              component: UserPageHeader,
            },
            bodyContent: {
              component: UserNewOrg,
            },
          }),
        },

        {
          path: '/:orgId',

          loadData: async ({ match }) => ({
            org: await rootStore.stores.orgService.get(match.params.orgId),
          }),

          layout: ({ context }) => ({
            bodyHeader: {
              component: OrgPageHeader,
              props: {
                activeTab: context.props.activeTab,
              },
            },
          }),

          routes: [
            {
              path: '/people',
              props: () => ({
                title: 'People',
                activeTab: 'people',
              }),
              layout: ({ context }) => ({
                bodyContent: {
                  component: OrgMembers,
                },
              }),
            },

            {
              path: '/invite',
              props: () => ({ title: 'Invite' }),
              layout: ({ context }) => ({
                bodyHeader: null,
                bodyContent: {
                  component: OrgInvite,
                },
              }),
            },

            // Org Settings

            {
              path: '/settings',
              props: () => ({
                activeTab: 'settings',
              }),

              routes: _.compact([
                {
                  path: '/',
                  props: () => ({
                    title: 'Settings',
                  }),
                  layout: ({ context }) => ({
                    bodyContent: {
                      component: OrgSettings,
                    },
                  }),
                },
                {
                  path: '/filters',
                  props: () => ({
                    title: 'Filters',
                  }),
                  layout: ({ context }) => ({
                    bodyContent: {
                      component: OrgSettingsFilters,
                    },
                  }),
                },

                !isOnPrem && {
                  path: '/billing',
                  props: () => ({
                    title: 'Billing',
                  }),
                  layout: ({ context }) => ({
                    bodyContent: {
                      component: OrgSettingsBilling,
                    },
                  }),
                },
                {
                  path: '/sso',
                  props: () => ({
                    title: 'Single Sign-on',
                  }),
                  layout: ({ context }) => ({
                    bodyContent: {
                      component: OrgSettingsSSO,
                    },
                  }),
                },
                {
                  path: '/admin',
                  props: () => ({
                    title: 'Admin',
                  }),
                  layout: ({ context }) => ({
                    bodyContent: {
                      component: OrgSettingsAdmin,
                    },
                  }),
                },
              ]),
            },

            // Org Teams

            {
              path: '/teams',

              routes: [
                {
                  path: '/',

                  props: () => ({
                    title: 'Teams',
                    activeTab: 'teams',
                  }),

                  layout: ({ context }) => ({
                    bodyContent: {
                      component: OrgTeams,
                    },
                  }),
                },

                {
                  path: '/new',

                  props: () => ({
                    title: 'New Teams',
                  }),

                  layout: ({ context }) => ({
                    bodyContent: {
                      component: OrgNewTeam,
                    },
                  }),
                },

                {
                  path: '/:teamId',

                  loadData: async ({ match }) => ({
                    team: await rootStore.stores.teamService.get(
                      `${match.params.orgId}/${match.params.teamId}`
                    ),
                  }),

                  layout: ({ context }) => ({
                    bodyHeader: {
                      component: TeamPageHeader,
                      props: {
                        activeTab: context.props.activeTab,
                      },
                    },
                    bodyContent: {
                      component: null,
                    },
                  }),

                  routes: [
                    {
                      path: '/',

                      props: () => ({
                        title: 'Team Members',
                        activeTab: 'people',
                      }),

                      layout: () => ({
                        bodyContent: {
                          component: TeamMembers,
                        },
                      }),
                    },

                    {
                      path: '/activity',

                      props: () => ({
                        title: 'Team Activity',
                        activeTab: 'activity',
                      }),

                      layout: () => ({
                        bodyContent: {
                          component: TeamActivity,
                        },
                      }),
                    },

                    {
                      path: '/projects',

                      props: () => ({
                        title: 'Team Projects',
                        activeTab: 'projects',
                      }),

                      layout: () => ({
                        bodyContent: {
                          component: TeamProjects,
                        },
                      }),
                    },

                    {
                      path: '/settings',

                      props: () => ({
                        title: 'Team Settings',
                        activeTab: 'settings',
                      }),

                      layout: () => ({
                        bodyContent: {
                          component: TeamSettings,
                        },
                      }),
                    },
                  ],
                },
              ],
            },
          ],
        },

        // Projects

        {
          path: '/:namespaceId/:projectId',

          loadData: async ({ match }) => {
            const { namespaceId, projectId } = match.params || {};
            let project = {};

            await Promise.all([
              rootStore.stores.namespaceService.get(namespaceId, undefined, {
                onlyNew: true,
              }),

              rootStore.stores.projectService
                .get(`${namespaceId}/${projectId}`, undefined, { onlyNew: true })
                .then(res => {
                  project = res || {};
                }),
            ]);

            if (!project.id) return;

            rootStore.stores.projectStore.register(project.id);
            rootStore.stores.versionService.find({ projectId: project.id });
            rootStore.stores.releaseService.find({ projectId: project.id });
          },

          layout: () => ({
            htmlHead: {
              id: '1',
              component: ProjectHead,
            },
            globalHeader: {
              id: '1',
              component: undefined,
            },
            leftGutter: {
              id: '1',
              component: ProjectGutter,
            },
            leftSidebar: {
              id: '1',
              component: ProjectSidebar,
            },
          }),

          routes: [
            {
              path: '/settings',

              props: () => ({
                title: 'Project Settings',
              }),

              layout: () => ({
                bodyContent: {
                  component: ProjectSettings,
                },
              }),
            },

            {
              path: '/:ref/:filePath*',

              loadData: async ({ match }) => {
                const { namespaceId, projectId, ref, filePath } = match.params || {};

                const { id, default_branch } =
                  _.get(rootStore.stores.projectService, 'current') || {};

                const currentProjectStore = rootStore.stores.projectStore.getInstance(id);
                if (!currentProjectStore) return;

                const handleRedirect = () => {
                  let redirectPath = `/${namespaceId}/${projectId}/${encodeURIComponent(
                    default_branch
                  )}`;

                  if (filePath) {
                    redirectPath = `${redirectPath}/${filePath}`;
                  }

                  rootStore.stores.routerStore.replace(redirectPath);
                };

                await Promise.all([
                  rootStore.stores.projectBranchService
                    .get(decodeURIComponent(ref), { projectId: id })
                    .then(version => {
                      if (!version) {
                        this.handleRedirect();
                      }
                    })
                    .catch(handleRedirect),
                  currentProjectStore.getConfigFile().catch(_.noop),
                  currentProjectStore
                    .getLintFile()
                    .then(file => {
                      // if no lint create it if user can edit

                      if (
                        !file &&
                        rootStore.stores.projectService.canUser({ action: 'push:project' })
                      ) {
                        currentProjectStore
                          .createFile({
                            file_path: 'lint.yml',
                            commit_message: `add lint.yml`,
                            content: lintFile(),
                          })
                          .catch(e => {
                            alert.error('Error creating lint file.');
                          });
                      }
                    })
                    .catch(_.noop),
                ]);
              },

              layout: () => ({
                bodyContent: {
                  component: ProjectFile,
                },
              }),
            },

            {
              path: '/',
              loadData: async ({ match }) => {
                const { namespaceId, projectId } = match.params;

                // if version not specified re-route to default branch
                const project = _.get(rootStore.stores.projectService, 'current') || {};
                if (!project.id) return;

                rootStore.stores.routerStore.replace(
                  `/${namespaceId}/${projectId}/${encodeURIComponent(project.default_branch)}`
                );
              },

              layout: () => ({
                bodyContent: {
                  component: PageLoading,
                  props: { className: 'flex flex-1 items-center' },
                },
              }),
            },
          ],
        },

        {
          path: '/*',

          props: () => ({
            title: 'Not Found',
          }),

          layout: () => ({
            bodyContent: {
              component: NotFound,
            },
          }),
        },
      ]),
    },
  ];
};
