import React from 'react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import AppAbout from '@platform/components/AppAbout';
import Alert from '@platform/components/Alert';
import DesktopDownload from '@platform/components/DesktopDownload';
import { registerLogger } from '@platform/utils/logging';

import Page from './page';

if (process.env.NODE_ENV !== 'test') {
  require('@core/ui/styles/main.css');
  require('@platform/styles/index.styl');
}

// When Needed
// let DevTools;
// if (process.env.NODE_ENV === 'development') {
//   DevTools = require('mobx-react-devtools').default;
// }

const log = registerLogger('components', 'App');

let Errors = ({ appStore }) => {
  const { errors, handleClose } = appStore;
  if (_.isEmpty(errors)) {
    return null;
  }

  const uniqErrors = _.uniq(
    _.compact(_.map(errors.slice(), error => String(_.get(error, 'error', null))))
  );

  if (_.isEmpty(uniqErrors)) {
    return null;
  }

  const errorElems = _.compact(
    _.map(uniqErrors, (err, k) => {
      if (_.isEmpty(err)) {
        return null;
      }

      return (
        <div key={k} className="AppError">
          <div className="flex">
            <div className="flex-1">{typeof err === 'object' ? err.error : err}</div>

            {handleClose ? (
              <div
                className="ml-6 cur-p"
                onClick={() => {
                  handleClose(k);
                }}
              >
                <Icon name="remove" fitted />
              </div>
            ) : null}
          </div>
        </div>
      );
    })
  );

  return <div className="AppErrors flex flex-col">{errorElems}</div>;
};
Errors = inject('appStore')(observer(Errors));

export const App = () => {
  log.debug('Render');

  // Add all the elements in a children array, to avoid the extra <div> :tada:
  const children = [];

  children.push(<Page key="pa" />);

  children.push(<Alert key="al" />);

  children.push(<AppAbout key="ab" />);

  children.push(<Errors key="er" />);

  children.push(<DesktopDownload key="dd" />);

  // When Needed
  // const { rootStore } = this.props;
  // if (DevTools && rootStore.isClient) {
  //   children.push(
  //     <DevTools key="dt" position={{ bottom: 0, left: 0 }} />
  //   );
  // }

  return children;
};
