import * as React from 'react';
import { inject, observer } from 'mobx-react';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';

// ew
import { Popup } from 'semantic-ui-react';

import FormDropdown from '@platform/components/FormDropdown';
import FormCheckbox from '@platform/components/FormCheckbox';
import FormInputLabel from '@platform/components/FormInputLabel';
import MarkdownViewer from '@platform/components/MarkdownViewer';

import { filterMap } from '@platform/utils/projects';

import { Icon } from '@core/ui/Icon';

class LintSelector extends React.Component {
  componentDidMount() {
    const { routerStore } = this.props;
    routerStore.scrollToHash();
  }

  render() {
    const { editor = {} } = this.props;
    const { sortedRules = [], currentFormat } = editor;

    return (
      <div className="px-32 py-24 max-w-4xl">
        <div className="pb-4 border-b flex items-end">
          <div className="flex-1">
            <div className="text-3xl font-bold mb-4">OpenAPI Specification (OAS) Style Guide</div>
            <div>
              Enable or disable rules used to enforce certain stylistic or validation properties on
              OAS files in this project.
            </div>
          </div>

          <div>
            <FormInputLabel label="SPECIFICATION" className="ta-r" />
            <FormDropdown
              className="w-40"
              placeholder="Specification"
              value={currentFormat}
              options={[
                { value: 'oas2', text: 'OpenAPI v2' },
                { value: 'oas3', text: 'OpenAPI v3' },
              ]}
              onChange={(e, { value }) => {
                editor.goToEditor(value);
              }}
              selection
              fluid
            />
          </div>
        </div>
        {!isEmpty(sortedRules) ? (
          map(sortedRules, (rules, tag) => this._renderRuleSection(rules, tag))
        ) : (
          <div className="mt-12 text-xl text-grey-dark font-bold">No Rules</div>
        )}
      </div>
    );
  }

  _renderRuleSection(rules, tag) {
    return (
      <div key={tag} className="mt-12">
        <div className="flex items-end mb-4 text-grey-dark font-bold">
          <div className="text-xl flex-1">{tag}</div>
          <div className="ml-6 w-20 ta-c text-sm">Enabled?</div>
        </div>

        {map(rules, (rule, index) => this._renderRule(rule, index))}
      </div>
    );
  }

  _renderRule(ruleEntry, key) {
    const { editor, projectService } = this.props;
    const { updateRule, currentFormat } = editor;

    const canPush = projectService.canUser({ action: 'push:project' });

    const { enabled, type, summary, name, severity, icons } = ruleEntry;
    const { type: typeIcon, severity: severityIcon } = icons;

    const { icon: tIcon, color: tColor } = typeIcon;
    const { icon: sIcon, color: sColor } = severityIcon;

    return (
      <div id={name} key={key} className="flex mb-4">
        <div className="flex-1">
          <div className="flex items-center">
            <div className="font-semibold">{name}</div>
            <Popup
              content={`${capitalize(type || 'unknown')} Rule`}
              trigger={<Icon className="mx-2" icon={tIcon} color={tColor} />}
              inverted={true}
              size="mini"
            />

            <Popup
              content={`${capitalize(severity || 'unknown')} Severity`}
              trigger={<Icon icon={sIcon} color={sColor} />}
              inverted={true}
              size="mini"
            />
          </div>
          <div className="flex items-center">
            {<MarkdownViewer value={summary ? summary : '*No Summary*'} />}
          </div>
          <div className="border-b p-1" />
        </div>

        <FormCheckbox
          className="w-20 ml-6 items-center justify-center"
          checked={enabled}
          disabled={!canPush}
          onChange={checked => updateRule(name, checked)}
          checkboxProps={{ toggle: true }}
        />
      </div>
    );
  }
}

export default inject(({ lintEditorStore, routerStore, projectService }, { editorId }) => {
  const editor = lintEditorStore.getEditor({ id: editorId });

  return {
    editor,
    routerStore,
    projectService,
  };
})(observer(LintSelector));
