import entityEditorStore, { EntityEditor } from './entityEditorStore';

export class RawEditor extends EntityEditor {
  static rawIsString = true;

  activate() {
    super.activate();
  }

  get currentPath() {
    return [];
  }
}

export default class RawEditorStore extends entityEditorStore {
  editorClass = RawEditor;
}
