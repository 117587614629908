import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';

import { alert } from '@platform/utils/alert';

import { colors, Layout } from '@core/ui';

import FormInput from '@platform/components/FormInput';
import FormDropdown from '@platform/components/FormDropdown';
import ErrorMessage from '@platform/components/ErrorMessage';
import ImageIcon from '@platform/components/ImageIcon';

import { freeEmailRegexp } from './freeEmailRegexp';

const roles = [
  {
    key: 1,
    text: 'Architect',
    value: 'architect',
  },
  {
    key: 2,
    text: 'Frontend Developer',
    value: 'frontend_dev',
  },
  {
    key: 3,
    text: 'Backend Developer',
    value: 'backend_dev',
  },
  {
    key: 4,
    text: 'Quality Assurance',
    value: 'qa',
  },
  {
    key: 5,
    text: 'Technical Writer',
    value: 'writer',
  },
  {
    key: 6,
    text: 'Product Manager',
    value: 'product_manager',
  },
  {
    key: 7,
    text: 'Founder / C-Level / VP',
    value: 'vp',
  },
  {
    key: 8,
    text: 'Other',
    value: 'other',
  },
];

class UserSettings extends React.Component {
  componentWillMount() {
    this.updateUiUser(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateUiUser(nextProps);
  }

  updateUiUser = props => {
    const { ui, updateUi, original } = props;

    if (!ui.user && !_.isEmpty(original)) {
      const user = _.clone(original);

      if (user.name === user.username) {
        user.name = '';
      }

      // Check to see if this is a work email or not
      if (!freeEmailRegexp.test(user.email)) {
        user.public_email = user.email;
      }

      updateUi('set', 'user', user);
    }
  };

  render() {
    const { header, ui, updateUi, original = {}, userService, onSuccess } = this.props;

    const isSaving = ui.isSaving;
    const user = ui.user || {};
    const nextEnabled =
      !isSaving &&
      user.name &&
      user.name.length &&
      user.role &&
      user.role.length &&
      user.public_email &&
      user.public_email.length &&
      !ui.emailError;

    return (
      <Layout
        header={header}
        footer={{
          right: {
            actions: [
              {
                children: isSaving ? 'Saving...' : 'Next',
                rightIcon: !isSaving && faArrowRight,
                color: nextEnabled ? colors.accent : colors.grey,
                disabled: !nextEnabled,
                onClick: e => {
                  e.preventDefault();
                  updateUi('set', 'error', null);
                  updateUi('set', 'isSaving', true);

                  userService.updateAnalytics({
                    role: user.role,
                    name: user.name,
                    work_email: user.public_email,
                  });

                  userService
                    .update(
                      original.id,
                      _.omitBy(_.pick(user, ['name', 'role', 'public_email']), _.isEmpty)
                    )
                    .then(() => {
                      return userService.getAuthorizedUser();
                    })
                    .then(res => {
                      updateUi('set', 'isSaving', false);

                      if (onSuccess) {
                        onSuccess();
                      }
                    })
                    .catch(err => {
                      alert.error('Error updating profile.');
                      updateUi('set', 'error', err);
                      updateUi('set', 'isSaving', false);
                    });
                },
              },
            ],
          },
        }}
        content={
          <div className="bg-white">
            <ErrorMessage error={ui.error} />

            <div className="flex p-12">
              <div className="pr-10">
                <div className="FormFileButton text-center">
                  <ImageIcon imageUrl={original.avatar_url} size="large" isCircle />
                  <div className="mt-2 text-blue text-sm">change avatar</div>

                  <input
                    type="file"
                    onChange={e => {
                      e.preventDefault();
                      updateUi('set', 'error', null);
                      updateUi('set', 'isSaving', true);

                      const file = _.get(e, 'target.files[0]');
                      userService
                        .updateImage(original.id, {
                          file,
                        })
                        .then(() => {
                          userService.getAuthorizedUser();
                        })
                        .then(res => {
                          alert.success('Profile image updated.');
                          updateUi('set', 'isSaving', false);
                        })
                        .catch(err => {
                          alert.error('Error updating image.');
                          updateUi('set', 'isSaving', false);
                          updateUi('set', 'error', err);
                        });
                    }}
                    onClick={event => {
                      event.target.value = null;
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-1 flex-col">
                <FormInput
                  label="FULL NAME"
                  name="name"
                  placeholder="Please write your name..."
                  value={_.get(user, 'name', '')}
                  onChange={(e, { value }) => {
                    updateUi('set', 'user.name', value);
                  }}
                  fluid
                />

                <FormInput
                  className="mt-10"
                  label="WORK EMAIL ADDRESS"
                  name="email"
                  placeholder="me@company.com"
                  value={_.get(user, 'public_email', '')}
                  error={ui.emailError}
                  onChange={(e, { value }) => {
                    updateUi('set', 'user.public_email', _.trim(value));

                    if (ui.emailError) {
                      updateUi('unset', 'emailError');
                    }
                  }}
                  onBlur={() => {
                    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                      _.get(user, 'public_email', '')
                    );

                    if (isValid) {
                      updateUi('unset', 'emailError');
                    } else {
                      updateUi('set', 'emailError', 'Please enter a valid email address.');
                    }
                  }}
                  fluid
                />

                <FormDropdown
                  className="mt-10"
                  label="WHICH OF THE FOLLOWING BEST DESCRIBES YOUR ROLE?"
                  fluid
                  value={user.role}
                  placeholder="Please choose one..."
                  options={roles}
                  onChange={(e, { value }) => {
                    updateUi('set', 'user.role', value);
                  }}
                  selection
                />
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

export default inject((stores, props) => {
  const { userService, appStore } = stores;

  return {
    userService,
    original: userService.authorizedUser,
    ...appStore.injectUi('UserSettings'),
  };
})(observer(UserSettings));
