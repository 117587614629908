import * as React from 'react';

const cn: any = require('classnames');

import Notifications from './Notifications';
import Panel from './Panel';
import Toolbar from './Toolbar';

export interface IEditorContainerProps {
  children: any;
  className?: string;
  hidePanel?: boolean;
  hideToolbar?: boolean;
}

export class EditorContainer extends React.Component<IEditorContainerProps> {
  public render() {
    const { children, className, hidePanel, hideToolbar } = this.props;

    return (
      <div className={cn('flex flex-col h-full', className)}>
        {!hideToolbar && <Toolbar className="z-5 print:hidden" />}

        <div className="flex flex-1 z-1">
          <div className="flex flex-1 flex-col z-1">
            {!hideToolbar && <Notifications className="z-5 print:hidden" />}
            <div className="flex-1 flex flex-col z-1 relative">{children}</div>
          </div>

          {!hidePanel && <Panel />}
        </div>
      </div>
    );
  }
}
