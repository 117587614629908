import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { alert } from '@platform/utils/alert';
import { accessLevels } from '@platform/utils/acl';

import PageContent from '../../components/PageContent';
import MemberList from '../../components/MemberList';

class OrgMembers extends React.Component {
  findItems = ({ search, page } = {}) => {
    const { service, org } = this.props;
    const query = {};
    let skipPagination = false;

    if (search) {
      query.query = search;
      skipPagination = true;
    } else {
      query.page = page || service.getCurrentPage() + 1;
    }

    service.find(
      { orgId: org.id },
      {
        query,
        skipPagination,
      }
    );
  };

  componentWillMount() {
    const { org } = this.props;

    if (org.id) {
      this.findItems({ page: 1 });
    }
  }

  handleCreate = (id, access_level) => {
    const { service, params } = this.props;
    return service.create({ user_id: id, access_level }, params);
  };

  handleUpdate = (id, update) => {
    const { service, params } = this.props;
    return service.update(id, update, params);
  };

  handleRemove = id => {
    const { service, params, userService, routerStore } = this.props;
    return service
      .remove(id, params)
      .then(() => {
        if (id === _.get(userService, 'authorizedUser.id')) {
          alert.success('You have left the organization.');
          routerStore.push('/');
        }
      })
      .catch(e => {
        alert.error('There was an error while removing org member.');
        console.error('There was an error while removing org member.', e);
      });
  };

  render() {
    const { org, service } = this.props;
    const { currentList, error, clearError } = service;

    return (
      <PageContent>
        <div className="flex-grow">
          <MemberList
            members={currentList}
            entity={org}
            entityType="org"
            handleCreate={this.handleCreate}
            handleUpdate={this.handleUpdate}
            handleRemove={this.handleRemove}
            error={error}
            handleClearError={clearError}
            hideCollabSwitcher
            removeConfirmText="They will be removed from all teams in this org. Additionally, any projects they own in this org will be transferred to the org owner."
            memberAccessLevel={accessLevels.REPORTER}
            service={service}
            loadMore={this.findItems}
            onSearch={searchQuery => {
              this.findItems({ search: searchQuery });
            }}
          />
        </div>
      </PageContent>
    );
  }
}

export default inject((stores, props) => {
  const { orgService, orgMemberService, routerStore, userService } = stores;

  const { orgId } = _.get(routerStore, 'location.params', {});

  return {
    userService,
    service: orgMemberService,
    orgService,
    org: orgService.current || {},
    routerStore,
    params: {
      orgId,
    },
  };
})(observer(OrgMembers));
