import _ from 'lodash';
import { httpMethodRegex, commonMimeTypes } from '@platform/utils/http';
import { build as buildObjectRouter } from '@platform/utils/objectRouter';

export default (props = {}) => {
  const {
    FormInput,
    FormSelect,
    SwaggerRoot,
    SwaggerInfo,
    SwaggerTags,
    SwaggerRootParameter,
    SwaggerRootResponse,
    SwaggerParameters,
    SwaggerSecurity,
    SwaggerSecurityDefinition,
    SwaggerSecurityDefinitions,
    SwaggerOperation,
    SwaggerDefinition,
    SwaggerExternalDocs,
  } = props;

  const propsData = {
    root: {
      component: SwaggerRoot,
      preserveEmpty: true,
      componentProps: {},
      sidebarOptions: {
        parseChildren: true,
      },
    },
    swagger: {
      component: FormSelect,
      componentProps: {
        label: 'swagger/oas version',
        tip: `Specifies the Swagger Specification version being used. It
        can be used by the Swagger UI and other clients to interpret
        the API listing. The value MUST be "2.0".`,
        required: true,
        size: 'big',
        options: [
          {
            text: 'Swagger (OAS) 2',
            value: '2.0',
          },
        ],
        fluid: true,
      },
      editor: {
        mode: 'text',
      },
    },
    info: {
      component: SwaggerInfo,
    },
    host: {
      component: FormInput,
      componentProps: {
        label: 'api host',
        tip: `The host (name or ip) serving the API. This MUST be the host only and does not include the scheme nor sub-paths.
        It MAY include a port. If the host is not included, the host serving the documentation is to be used
        (including the port). The host does not support path templating.`,
        required: true,
        size: 'big',
        placeholder: 'example: `api.acme.com`',
        fluid: true,
      },
      editor: {
        mode: 'text',
      },
    },
    tags: {
      component: SwaggerTags,
      headerActions: [
        {
          type: 'button',
          props: {
            icon: 'tag',
            content: 'New Tag',
            onClick: ({ value, handleUpdate }) => {
              const newTags = _.cloneDeep(value || []);

              newTags.unshift({
                name: '',
              });
              handleUpdate('set', [], newTags);
            },
            secondary: true,
          },
        },
      ],
    },
    basePath: {
      component: FormInput,
      componentProps: {
        label: 'api base path',
        tip: `The base path on which the API is served, which is relative to the host. If it is not included,
        the API is served directly under the host. The value MUST start with a leading slash (/).
        The basePath does not support path templating.`,
        size: 'big',
        placeholder: 'example: `/v1`',
        fluid: true,
      },
      editor: {
        mode: 'text',
      },
    },
    schemes: {
      component: FormSelect,
      componentProps: {
        label: 'supported schemes',
        tip: `The transfer protocol of the API. Values MUST be from the list: "http", "https", "ws", "wss".
  If the schemes is not included, the default scheme to be used is the one used to access the Swagger definition itself.`,
        required: true,
        size: 'big',
        options: [
          { text: 'HTTP', value: 'http' },
          { text: 'HTTPS', value: 'https' },
          { text: 'WS', value: 'ws' },
          { text: 'WSS', value: 'wss' },
        ],
        placeholder: 'Protocol(s)',
        fluid: true,
        multiple: true,
      },
    },
    consumes: {
      component: FormSelect,
      componentProps: {
        label: 'request mime types',
        tip: `A list of MIME types the APIs can consume. This is global to all APIs but can be overridden on specific API calls.`,
        size: 'big',
        options: commonMimeTypes.map(c => ({ text: c, value: c })),
        fluid: true,
        multiple: true,
      },
    },
    produces: {
      component: FormSelect,
      componentProps: {
        label: 'response mime types',
        tip: `A list of MIME types the APIs can produce. This is global to all APIs but can be overridden on specific API calls.`,
        size: 'big',
        options: commonMimeTypes.map(c => ({ text: c, value: c })),
        fluid: true,
        multiple: true,
      },
    },
    security: {
      component: SwaggerSecurity,
      componentProps: {},
    },
    securityDefinition: {
      component: SwaggerSecurityDefinition,
      componentProps: {},
    },
    securityDefinitions: {
      component: SwaggerSecurityDefinitions,
      componentProps: {},
    },
    path: {
      sidebarOptions: {
        parseChildren: true,
        actionsFactory: (key, data, opts) => {
          return [
            {
              id: 'add',
              icon: 'plus',
              type: 'action',
              tip: 'Add a new operation.',
              onClick: () => {
                opts.editor.addOperation({ path: key }, { navigate: true });
              },
            },
          ];
        },
      },
    },
    operation: {
      component: SwaggerOperation,
      preserveEmpty: true,
      componentProps: {},
      sidebarOptions: {
        tokenFactory: (key, data) => {
          return {
            name: key,
            className: `sl-${key}-color`,
          };
        },
        metaFactory: (key, data = {}) => {
          const meta = [];

          if (_.get(data, ['deprecated'])) {
            meta.push({
              icon: 'exclamation circle',
              className: 'text-red',
              title: 'deprecated',
            });
          }

          if (_.get(data, ['x-private'])) {
            meta.push({
              icon: 'eye slash',
              title: 'private',
            });
          }

          return meta;
        },
        nameFactory: (method, data, opts = {}) => {
          if (_.isEmpty(data)) {
            return '';
          }

          const { summary, operationId } = data || {};

          return _.truncate(summary || operationId || method, {
            length: 40,
          });
        },
      },
    },
    definition: {
      component: SwaggerDefinition,
      sidebarOptions: {
        nameFactory: (key, data) => {
          return (data && data.title) || key;
        },
      },
    },
    parameters: {
      component: SwaggerParameters,
    },
    rootParameters: {
      sidebarOptions: {
        parseChildren: true,
      },
    },
    rootParameter: {
      component: SwaggerRootParameter,
      sidebarOptions: {
        tokenFactory: (key, data) => {
          return {
            name: data.in,
            className: `sl-${key}-color`,
          };
        },
      },
    },
    rootResponses: {
      sidebarOptions: {
        parseChildren: true,
      },
    },
    rootResponse: {
      component: SwaggerRootResponse,
    },
    externalDocs: {
      component: SwaggerExternalDocs,
    },
  };

  const contentRouterMap = new Map();

  contentRouterMap.set(['swagger'], propsData.swagger);
  contentRouterMap.set(['info'], propsData.info);
  contentRouterMap.set(['tags'], propsData.tags);
  contentRouterMap.set(['host'], propsData.host);
  contentRouterMap.set(['basePath'], propsData.basePath);
  contentRouterMap.set(['schemes'], propsData.schemes);
  contentRouterMap.set(['consumes'], propsData.consumes);
  contentRouterMap.set(['produces'], propsData.produces);
  contentRouterMap.set(['securityDefinitions', /.*/], propsData.securityDefinition);
  contentRouterMap.set(['paths', /.*/], propsData.path);
  contentRouterMap.set(['paths', /.*/, 'parameters'], propsData.parameters);
  contentRouterMap.set(['paths', /.*/, httpMethodRegex], propsData.operation);
  contentRouterMap.set(['definitions', /.*/], propsData.definition);
  contentRouterMap.set(['parameters'], propsData.rootParameters);
  contentRouterMap.set(['parameters', /.*/], propsData.rootParameter);
  contentRouterMap.set(['responses'], propsData.rootResponses);
  contentRouterMap.set(['responses', /.*/], propsData.rootResponse);
  contentRouterMap.set(['externalDocs'], propsData.externalDocs);
  contentRouterMap.set([], propsData.root);

  const contentRouter = buildObjectRouter(contentRouterMap);

  return {
    propsData,
    contentRouter,
  };
};
