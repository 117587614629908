import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { Link } from '@platform/utils/router';

const Crumbs = props => {
  const { crumbs = [], className = '' } = props;

  const crumbElems = [];
  _.forOwn(crumbs, (crumb, i) => {
    const isLast = !crumbs[parseInt(i) + 1];
    const { elem, children, href, to, ...linkProps } = crumb;

    if (to || href) {
      crumbElems.push(
        <Link key={i} className="Crumb" to={to || href} {...linkProps}>
          {children}
        </Link>
      );
    } else if (elem) {
      crumbElems.push(elem);
    } else {
      crumbElems.push(
        <div key={i} className="Crumb">
          {children}
        </div>
      );
    }

    if (!isLast) {
      crumbElems.push(
        <div key={`${i}-d`} className="Crumb-divider">
          /
        </div>
      );
    }
  });

  return <div className={cn('Crumbs', className)}>{crumbElems}</div>;
};

export default Crumbs;
