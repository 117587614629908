import React from 'react';
import _ from 'lodash';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Menu, Segment, Icon, Popup, Dropdown } from 'semantic-ui-react';

import { commonMimeTypes } from '@platform/utils/http';
import { renameObjectKey } from '@platform/utils/general';
import { fakeExampleFromSchema } from '@platform/utils/schemas/json-schema-faker';
import { alert } from '@platform/utils/alert';

import FormSelect from '../FormSelect';
import CodeEditor from '../CodeEditor';

const mimeTypes = _.map(commonMimeTypes, t => ({
  text: t,
  value: t,
}));

export class SpecExamples extends React.Component {
  state = { isLoading: false };

  handleUpdateExampleKey = val => {
    const { examples = {}, handleUpdate, ui, updateUi } = this.props;

    const activeTab = ui.activeTab || _.first(Object.keys(examples));

    if (val === activeTab) {
      return;
    }

    if (examples[val]) {
      alert.warning('This example key is already being used!');
      return;
    }

    const newExamples = renameObjectKey(examples, activeTab, val);
    updateUi('set', 'activeTab', val);
    handleUpdate('set', [], newExamples);
  };

  getActiveTab = () => {
    const { ui, examples = {} } = this.props;

    return ui.activeTab || _.first(Object.keys(examples)) || '';
  };

  getMode = () => {
    const { ui } = this.props;

    const activeTab = this.getActiveTab();

    let mode = _.get(ui, [activeTab, 'mode']);

    if (!mode) {
      if (activeTab.match(/new|json|javascript/g)) {
        mode = 'json';
      } else if (activeTab.match(/xml/g)) {
        mode = 'xml';
      } else {
        mode = 'text';
      }
    }

    return mode;
  };

  onBlur = value => {
    const { handleUpdate } = this.props;

    if (!handleUpdate) return;

    let parsedValue = value;

    const mode = this.getMode();

    if (mode === 'json') {
      try {
        parsedValue = JSON.parse(value);
      } catch (e) {
        // TODO: tell the user it's invalid?
      }
    }

    const activeTab = this.getActiveTab();

    handleUpdate('set', [activeTab], parsedValue);
  };

  createExampleFromSchema = () => {
    const { dereferencedSchema, handleUpdate } = this.props;
    const activeTab = this.getActiveTab();
    fakeExampleFromSchema(dereferencedSchema, { skipDereference: true })
      .then(example => {
        handleUpdate('set', [activeTab], example);
      })
      .catch(e => {
        alert.error(
          'An error occurred while generating the example. Do you have a schema set? More information can be found in the developer console.'
        );

        console.error('Error generating schema.', e);
      });
  };

  render = () => {
    const {
      id,
      readOnly,
      schema, // deprecated
      dereferencedSchema,
      definitions,
      examples = {},
      handleUpdate,
      updateUi,
      className = '',
      editor,
    } = this.props;

    const { isLoading } = this.state;

    const activeTab = this.getActiveTab();

    const menuItems = [];

    if (readOnly && _.isEmpty(examples)) {
      return null;
    }

    if (!readOnly) {
      menuItems.push(
        <Popup
          key="add"
          trigger={
            <Menu.Item
              onClick={() => {
                let exampleKey = 'new';
                if (!examples['application/json']) {
                  exampleKey = 'application/json';
                }

                handleUpdate('set', [exampleKey], {});
                updateUi('set', 'activeTab', exampleKey);
              }}
            >
              <div className="AddButton">
                <Icon name="plus square" color="blue" />
              </div>
            </Menu.Item>
          }
          content="Add Example"
          position="top left"
          wide="very"
          size="small"
          hoverable
        />
      );
    }

    for (const k in examples) {
      menuItems.push(
        <Menu.Item
          key={k}
          active={activeTab === k}
          onClick={() => {
            updateUi('set', 'activeTab', k);
          }}
        >
          {k}
        </Menu.Item>
      );
    }

    let contentElem;
    const example = examples.hasOwnProperty(activeTab) ? examples[activeTab] : null;

    if (example !== null) {
      const mode = this.getMode();

      contentElem = (
        <div>
          {!readOnly ? (
            <div className="mb-6 flex items-center">
              <FormSelect
                placeholder="choose example key"
                value={activeTab || ''}
                onChange={this.handleUpdateExampleKey}
                options={
                  _.find(mimeTypes, { value: activeTab })
                    ? mimeTypes
                    : [{ text: activeTab, value: activeTab }].concat(mimeTypes)
                }
                allowAdditions
                search
              />

              <span className="ml-3">
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  icon="magic"
                  onClick={async () => {
                    if (_.isEmpty(dereferencedSchema)) {
                      alert.warning('You have not set a schema! Cannot generate.');
                      return;
                    }
                    this.setState({ isLoading: true });
                    if (editor.isDereferencing) {
                      when(
                        () => !this.props.editor.isDereferencing,
                        () => {
                          this.createExampleFromSchema();
                          this.setState({ isLoading: false });
                        }
                      );
                    } else {
                      await editor.dereferenceParsed();
                      this.createExampleFromSchema();
                      this.setState({ isLoading: false });
                    }
                  }}
                  size="mini"
                  content="Generate From Schema"
                />
              </span>

              <span className="ml-3">
                <Button
                  icon="trash"
                  onClick={() => {
                    handleUpdate('unset', [activeTab]);
                    updateUi('set', 'activeTab', null);
                  }}
                  size="mini"
                />
              </span>

              <span className="ml-3">
                <Dropdown text={`Editor Mode (${mode})`}>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text="json"
                      onClick={() => {
                        updateUi('set', [activeTab, 'mode'], 'json');
                      }}
                    />
                    <Dropdown.Item
                      text="xml"
                      onClick={() => {
                        updateUi('set', [activeTab, 'mode'], 'xml');
                      }}
                    />
                    <Dropdown.Item
                      text="plain"
                      onClick={() => {
                        updateUi('set', [activeTab, 'mode'], 'text');
                      }}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </div>
          ) : null}

          <CodeEditor
            name={`${id}-${activeTab}-${mode}`}
            value={example || (mode === 'json' ? '{}' : '')}
            mode={mode}
            autogrow
            maxLines={20}
            readOnly={readOnly}
            noFill
            onBlur={this.onBlur}
            wordWrap
            noDebounce
          />
        </div>
      );
    } else if (!readOnly) {
      contentElem = <div className="c-muted">select or create an example above</div>;
    }

    return (
      <div className={`SpecExamples ${className}`}>
        <div className="FormInputLabel">Examples</div>

        <Menu attached="top" size="tiny">
          {menuItems}
        </Menu>

        <Segment attached="bottom">{contentElem}</Segment>
      </div>
    );
  };
}

export default inject(({ appStore, oas2EditorStore }, { id }) => ({
  ...appStore.injectUi(`${id}-examples`),
  editor: {
    isDereferencing: oas2EditorStore.activeEditor.isDereferencing,
    dereferenceParsed: oas2EditorStore.activeEditor.dereferenceParsed,
  },
}))(observer(SpecExamples));
