import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Button, Divider } from 'semantic-ui-react';

import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff';

import { getConfigVar } from '@platform/utils/config';

import { Icon } from '@core/ui';

import ErrorMessage from '../ErrorMessage';
import FormInput from '../FormInput';
import AccountFormNav from '../AccountFormNav';
import AccountSSO from '../AccountSSO';

const AccountLogin = props => {
  const { ui = {}, updateUi, userService, hideNav, message } = props;
  const { email = '', password = '' } = ui;

  if (getConfigVar('SL_SSO_ENTRYPOINT')) {
    return (
      <AccountSSO entrypoint={() => (document.location.href = getConfigVar('SL_SSO_ENTRYPOINT'))} />
    );
  } else if (getConfigVar('GITHUB_URL')) {
    return <AccountSSO entrypoint={() => userService.openOAuthPopup('github')} />;
  }

  let githubLogin;
  if (getConfigVar('GITHUB_CLIENT_ID')) {
    githubLogin = [
      <div
        key="github"
        className="AccountForm-social"
        onClick={() => userService.openOAuthPopup('github')}
      >
        {'Log In With GitHub'}
        <Icon className="fl-r font-extrabold text-xl" icon={faGithub} />
      </div>,

      <Divider key="or" className="AccountForm-divider" horizontal inverted>
        OR
      </Divider>,
    ];
  }

  return (
    <div className="AccountForm">
      {!getConfigVar('SL_LOGIN_PATH') && <AccountFormNav active="login" onlyActive={hideNav} />}

      <div className="AccountForm-inner pt-6">
        <ErrorMessage error={userService.loginError} className="pb-6" />

        {githubLogin}

        <form
          onSubmit={e => {
            e.preventDefault();

            userService.login({
              email: email,
              password: password,
            });
          }}
        >
          <div className="AccountForm-input-group">
            <FormInput
              id="login-user"
              value={email}
              onChange={e => {
                updateUi('set', 'email', _.trim(e.target.value));
              }}
              disabled={userService.loggingIn}
              placeholder="Email or Username"
              fluid
            />

            <FormInput
              id="login-pass"
              type="password"
              value={password}
              onChange={e => {
                updateUi('set', 'password', e.target.value);
              }}
              disabled={userService.loggingIn}
              placeholder="Password"
              fluid
            />
          </div>

          <div className="pt-3">
            <Button
              className="AccountForm-cta"
              disabled={userService.loggingIn}
              type="submit"
              secondary
              fluid
            >
              Log In To Stoplight
              <Icon className="fl-r font-extrabold text-lg" icon={faPowerOff} />
            </Button>
          </div>
        </form>

        {message && <div className="text-white mt-10 text-sm text-center">{message}</div>}
      </div>
    </div>
  );
};

export default inject(({ appStore, userService }) => ({
  userService,
  ...appStore.injectUi('AccountLogin'),
}))(observer(AccountLogin));
