import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';

import { themes } from '@core/ui';

import ProjectHistory from '../ProjectHistory';
import ProjectPublish from '../ProjectPublish';
import ProjectFileTree from '../ProjectFileTree';
import ProjectCollaboration from '../ProjectCollaboration';

class ProjectSidebar extends React.Component {
  componentWillMount() {
    const { routerStore, projectService, userService, projectStore } = this.props;

    const loggedIn = _.get(userService, 'isLoggedIn');
    const showToggled = _.get(routerStore, 'location.query.show');
    const canWrite = projectService.canUser({ action: 'push:project' });
    const pathname = _.get(routerStore, 'location.pathname');

    // don't should panel on the settings page
    if (_.last(_.split(pathname, '/')) === 'settings') {
      projectStore.toggleLeftPanel(false);
    } else if (loggedIn && (showToggled || canWrite)) {
      projectStore.toggleLeftPanel(true, showToggled);
    }
  }

  render() {
    const { projectService, projectStore, hideLeftPanel } = this.props;
    const { leftSidebarShow, isLeftPanelOpen } = projectStore;

    const headerProps = {
      theme: themes.dark,
      noUpper: true,
      isLarge: true,
      path: [],
      closeIcon: faChevronLeft,
      handleClose: () => {
        projectStore.toggleLeftPanel(false);
      },
    };

    const namespace = _.get(projectService, 'current.namespace');
    if (namespace) {
      headerProps.path.push({
        name: namespace.name,
        uri: {
          path: namespace.kind === 'user' ? '/' : `/${namespace.path}`,
        },
        className: _.size(namespace.path) >= 15 ? 'max-w-xxs truncate' : null,
      });
    }

    const project = _.get(projectService, 'current');
    if (project) {
      headerProps.path.push({
        name: project.name,
        uri: {
          path: `/${project.path_with_namespace}`,
        },
        className: 'truncate',
      });
    }

    let primaryPanel;
    if (isLeftPanelOpen && !hideLeftPanel) {
      switch (leftSidebarShow) {
        case 'members':
        case 'teams':
          primaryPanel = <ProjectCollaboration headerProps={headerProps} type={leftSidebarShow} />;
          break;
        case 'history':
          primaryPanel = <ProjectHistory headerProps={headerProps} />;
          break;
        case 'publish':
          primaryPanel = <ProjectPublish headerProps={headerProps} />;
          break;
        default:
          // depends on localStorage, so can't render on server or end up with mismatch on client
          primaryPanel = <ProjectFileTree headerProps={headerProps} />;
          break;
      }
    }

    return <div className="flex flex-col">{primaryPanel}</div>;
  }
}

export default inject('routerStore', 'projectStore', 'userService', 'projectService')(
  observer(ProjectSidebar)
);
