import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import FormSelect from '@platform/components/FormSelect';

import Block from '../Block/viewer';

const RefBlock = props => {
  const {
    WrapperFactory,
    data = {},
    block = {},
    dereferencedBlock = {},
    editor,
    parsed,
    onChangeData,
  } = props;

  const options = _.map(editor.connectedBlocks, b => ({ text: b.name, value: `#/blocks/${b.id}` }));
  const blockId = _.replace(data.$ref, '#/blocks/', '');
  const refBlock = _.find(editor.connectedBlocks, { id: blockId }) || {};
  const refBlockDeref = _.cloneDeep(_.get(parsed, ['blocks', blockId], {}));

  const optionsElem = (
    <FormSelect
      value={data.$ref}
      options={options}
      onChange={(e, { value }) => onChangeData('set', ['$ref'], value)}
    />
  );

  refBlock.input = _.unionBy(block.input, refBlock.input, 'name');
  refBlockDeref.input = _.unionBy(dereferencedBlock.input, refBlockDeref.input, 'name');

  const contentElem = <Block {...props} block={refBlock} dereferencedBlock={refBlockDeref} />;

  return <WrapperFactory optionsElem={optionsElem} contentElem={contentElem} />;
};

export default observer(RefBlock);
