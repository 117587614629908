import React from 'react';

import contentRouterFactory from '@platform/utils/content-routers/instance';

import EntityEditor from '../EntityEditor';
import EntityEditorCode from '../EntityEditor/spec';
import EntityEditorWidgets from '../EntityEditor/widgets';

import Api from '../InstanceEditor/api';
import Rule from '../InstanceEditor/rule';
import Overview from '../InstanceEditor/overview';

const contentRouterData = contentRouterFactory({
  Overview,
  Api,
  Rule,
});

const contentTabs = [
  {
    id: 'design',
    name: 'Visual',
    icon: 'options',
    component: EntityEditorWidgets,
  },
  {
    id: 'code',
    name: 'Code',
    icon: 'code',
    className: 'is-light',
    component: EntityEditorCode,
  },
];

const InstanceEditor = props => {
  return (
    <EntityEditor
      editorStoreKey="instanceEditorStore"
      contentRouterData={contentRouterData}
      contentTabs={contentTabs}
      {...props}
    />
  );
};

export default InstanceEditor;
