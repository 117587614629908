import _ from 'lodash';
import { types, flow } from 'mobx-state-tree';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/projects/:projectId/releases',
    })
    .views(self => {
      return {
        get current() {
          const project = _.get(env.rootStore.stores.projectStore, 'current') || {};

          if (!project.id || !project.currentRef) return;

          return _.first(
            self
              .findLocal({
                query: {
                  branch: project.currentRef,
                  project_id: project.id,
                },
                limit: 1,
              })
              .get()
          );
        },

        get currentList() {
          const project_id = _.get(env.rootStore, 'stores.projectService.current.id');
          if (!project_id) return;

          return self
            .findLocal({
              query: {
                project_id,
              },
              sort: {
                name: -1,
              },
            })
            .get();
        },

        get latest() {
          return _.first(self.currentList);
        },
      };
    })
    .actions(self => {
      const originalUpdateRecord = self.updateRecord;
      const originalRemove = self.remove;

      return {
        /**
         * Overload updateRecord to transform the record to the format the client expects.
         * API doesn't include project_id, for example.
         */
        updateRecord(record, { params = {} } = {}) {
          if (!record) return;

          const project_id =
            record.project_id ||
            _.get(params, 'projectId') ||
            _.get(env.rootStore.stores.routerStore, 'location.params.projectId');

          const extendedRecord = {
            ...record,
            project_id,
          };

          // releases/tags don't come with ids :(
          extendedRecord.id = `${project_id}:${record.name}`;

          return originalUpdateRecord(extendedRecord);
        },

        remove: flow(function*(name, params, opts = {}) {
          const branch = yield originalRemove(name, params, {
            ...opts,
            skipStore: true,
          });

          const project_id =
            branch.project_id ||
            _.get(params, 'projectId') ||
            _.get(env.rootStore.stores.routerStore, 'location.params.projectId');

          return self.removeRecord(`${project_id}:${name}`);
        }),
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('ReleaseService');

  return Service.create(data, env);
};
