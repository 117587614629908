import React from 'react';
import { inject, observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';

import { colors, Button } from '@core/ui';
import SimpleSection from '@platform/components/SimpleSection';
import GraphQL from '@platform/containers/GraphQL';
import { alert } from '@platform/utils/alert';

const DangerZone = props => {
  const { doc, publishStore, routerStore, updateHandler, deleteHandler } = props;

  if (!publishStore.canPublish) return null;

  return (
    <SimpleSection
      className="PublishViewer-danger mt-12"
      title="Danger Zone"
      icon={faExclamationTriangle}
    >
      <div className="flex align-center">
        {doc.liveBuildId && (
          <Popup
            trigger={
              <div className="mr-6">
                <Button
                  color={colors.red}
                  disabled={publishStore.isUpdating}
                  loading={publishStore.isUpdating}
                  onClick={() => {
                    if (publishStore.isUpdating) return;

                    const r = window.confirm(
                      `Are you sure you want unpublish your Hub from ${publishStore.domain}?`
                    );
                    if (!r) return;

                    updateHandler
                      .run({
                        variables: {
                          id: doc.id,
                          input: { liveBuildId: -1 },
                        },
                      })
                      .then(() => {
                        alert.warning('Successfully unpublished.');
                      })
                      .catch(() => {
                        alert.error('Error unpublishing. Please try again.');
                      });
                  }}
                >
                  Unpublish
                </Button>
              </div>
            }
          >
            <div>
              Unpublishes the current build but allows you to publish to this domain in the future.
            </div>
          </Popup>
        )}

        <Popup
          trigger={
            <div>
              <Button
                transparent
                outlined
                color={colors.red}
                disabled={publishStore.isRemoving}
                loading={publishStore.isRemoving}
                onClick={() => {
                  if (publishStore.isRemoving) return;

                  const domain = publishStore.domain;

                  const r = window.confirm(`Are you sure you want to remove ${domain}?`);
                  if (!r) return;

                  deleteHandler
                    .run({
                      variables: {
                        id: doc.id,
                      },
                    })
                    .then(() => {
                      routerStore.setQueryParams({
                        domain: null,
                      });

                      alert.warning(`${domain} has been removed.`);
                    })
                    .catch(err => {
                      console.error('Error removing domain:', err);
                      alert.error('Error removing domain. Please try again.');
                    });
                }}
              >
                Remove Domain
              </Button>
            </div>
          }
        >
          <div>Deletes all builds and allows another project to publish to this domain.</div>
        </Popup>
      </div>
    </SimpleSection>
  );
};

const InjectedDangerZone = inject((stores, props) => {
  const { publishStore, routerStore } = stores;

  return {
    routerStore,
    publishStore: publishStore.current,
  };
})(observer(DangerZone));

export default props => {
  return (
    <GraphQL
      id="DangerZone"
      service="docsService"
      methods={{
        updateHandler: {},
        deleteHandler: {},
      }}
      renderProp={({ updateHandler, deleteHandler }) => {
        return (
          <InjectedDangerZone
            {...props}
            updateHandler={updateHandler}
            deleteHandler={deleteHandler}
          />
        );
      }}
    />
  );
};
