import * as React from 'react';

export class TreeRow extends React.Component<any> {
  public render() {
    const {
      children,
      listIndex,
      swapFrom,
      swapLength,
      swapDepth,
      scaffoldBlockPxWidth,
      lowerSiblingCounts,
      connectDropTarget,
      isOver,
      draggedNode,
      canDrop,
      treeIndex,
      rowDirection, // Delete from otherProps
      treeId, // Delete from otherProps
      getPrevRow, // Delete from otherProps
      node, // Delete from otherProps
      path, // Delete from otherProps
      ...otherProps
    } = this.props;

    return connectDropTarget(
      <div {...otherProps}>
        {React.Children.map(children, child => {
          // @ts-ignore
          return React.cloneElement(child, {
            isOver,
            canDrop,
            draggedNode,
            lowerSiblingCounts,
            listIndex,
            swapFrom,
            swapLength,
            swapDepth,
          });
        })}
      </div>
    );
  }
}
