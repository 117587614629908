import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';

import { Icon } from '@core/ui';

class BillingPlanSelector extends React.Component {
  render() {
    const { plans, selectedPlan, onPlanClick = _.noop } = this.props;

    return (
      <div className="flex select-none">
        {_.map(plans, (plan, index) => {
          const isSelected = selectedPlan === plan.id;

          const isFree = plan.price ? false : true;
          const subtitleParts = [];

          if (isFree) {
            subtitleParts.push('FREE');
          } else {
            subtitleParts.push(`$${plan.price}`);
            if (plan.product.namespaceType !== 'user' && plan.product.unit) {
              subtitleParts.push(plan.product.unit);
            }
          }

          let subtitle = subtitleParts.join(' / ');

          if (plan.min) {
            subtitle += ` (min ${plan.min})`;
          }

          if (plan.product.subtitle) {
            subtitle += ` (${plan.product.subtitle})`;
          }

          return (
            <div
              key={index}
              className={cn('rounded hover:opacity-100', {
                'text-black': !isSelected,
                'bg-darken-50': !isSelected,
                'cursor-pointer': !isSelected,
                'opacity-75': !isSelected,
                'text-white': isSelected,
                'bg-blue': isSelected,
                'mr-4': plans[index + 1],
                'flex-grow': !plans[index + 1],
                shadow: isSelected,
              })}
              onClick={() => onPlanClick(plan.id)}
            >
              <div
                className={cn('px-4 py-2', {
                  'bg-lighten-50': isSelected,
                  'bg-darken-50': !isSelected,
                })}
              >
                <div className="text-lg font-bold">{plan.product.title}</div>
                {subtitle && <div className="opacity-75 text-sm truncate">{subtitle.trim()}</div>}
              </div>

              <div className="py-3 px-4 opacity-75 text-sm">
                {_.get(plan, 'product.features', []).map((f, i) => {
                  return (
                    <div key={i} className="flex py-1">
                      <div className="mr-3">
                        <Icon icon={faCheck} />
                      </div>
                      <div>{f}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

BillingPlanSelector.propTypes = {
  namespaceType: PropTypes.oneOf(['user', 'org']),
  className: PropTypes.string,
  onPlanClick: PropTypes.func,
  selectedPlan: PropTypes.string,
};

export default BillingPlanSelector;
