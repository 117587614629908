import { types } from 'mobx-state-tree';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/orgs/:orgId/invite_tokens',
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      return {};
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('OrgInviteTokenService');

  return Service.create(data, env);
};
