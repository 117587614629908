import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Popup, Icon, Button } from 'semantic-ui-react';
import { Link } from '@platform/utils/router';
import FormInput from '@platform/components/FormInput';
import FormTitleInput from '@platform/components/FormTitleInput';
import FormTextarea from '@platform/components/FormTextarea';
import ColorPicker from '@platform/components/ColorPicker';

import { computeTheme } from '../../utils';

import { defaultTheme } from './index';

const CardChild = props => {
  const {
    child = {},
    childIndex,
    cache = {},
    onChangeData,
    updateCache,
    removeChild,
    isSolo,
    theme = {},
    onChangeTheme,
  } = props;

  const computedTheme = computeTheme({ theme, defaultTheme });
  const cardBgStyle = _.get(computedTheme, ['styles', 'card', childIndex, 'background'], {});
  const cardTextStyle = _.get(computedTheme, ['styles', 'card', childIndex, 'text'], {});

  const {
    image = '//react.semantic-ui.com/assets/images/wireframe/image.png',
    title = '',
    content = '',
    route = {},
  } = child;

  return (
    <Link
      className="CardListBlock-child flex items-center"
      onClick={e => e.preventDefault()}
      style={cardBgStyle}
    >
      {image ? (
        <div className="CardListBlock-image">
          <img src={image} />
        </div>
      ) : null}

      <div className="CardListBlock-main flex-1">
        <div className="CardListBlock-title font-bold">
          <FormTitleInput
            placeholder="Title"
            value={title}
            onChange={(e, { value }) => onChangeData('set', ['title'], value)}
            inputStyle={cardTextStyle}
          />
        </div>

        <div className="CardListBlock-body">
          <FormTextarea
            placeholder="Body content..."
            value={content}
            minRows={1}
            maxRows={3}
            onChange={(e, { value }) => onChangeData('set', ['content'], value)}
            inputStyle={cardTextStyle}
          />
        </div>
      </div>

      <div className={cn('CardListBlock-settings', { 'is-open': cache.isOpen })}>
        <Popup
          trigger={<Icon name="setting" />}
          position="bottom center"
          open={cache.isOpen}
          onOpen={() => updateCache('set', 'isOpen', true)}
          onClose={() => updateCache('unset', 'isOpen')}
          hoverable
        >
          <FormInput
            label="Link"
            value={route.path}
            onChange={(e, { value }) => onChangeData('set', ['route', 'path'], value)}
          />
          <FormInput
            className="mt-3"
            label="Image Source URL"
            value={image}
            onChange={(e, { value }) => onChangeData('set', ['image'], value)}
          />
          <ColorPicker
            inline
            color={cardTextStyle.color}
            label="Base Text Color"
            onChangeComplete={color => {
              if (
                color.hex === _.get(defaultTheme, ['styles', 'card', childIndex, 'text', 'color'])
              ) {
                onChangeTheme('unset', ['styles', 'card', childIndex, 'text', 'color']);
              } else {
                onChangeTheme('set', ['styles', 'card', childIndex, 'text', 'color'], color.hex);
              }
            }}
          />
          <ColorPicker
            inline
            color={cardBgStyle.backgroundColor}
            label="Base Background Color"
            onChangeComplete={color => {
              if (
                color.hex ===
                _.get(defaultTheme, ['styles', 'card', childIndex, 'background', 'backgroundColor'])
              ) {
                onChangeTheme('unset', [
                  'styles',
                  'card',
                  childIndex,
                  'background',
                  'backgroundColor',
                ]);
              } else {
                onChangeTheme(
                  'set',
                  ['styles', 'card', childIndex, 'background', 'backgroundColor'],
                  color.hex
                );
              }
            }}
          />
          {!isSolo ? (
            <div className="mt-3">
              <Button icon="trash" content="Remove" onClick={removeChild} fluid />
            </div>
          ) : null}
        </Popup>
      </div>
    </Link>
  );
};

const BlockCardList = props => {
  const {
    WrapperFactory,
    data = {},
    onChangeData,
    theme = {},
    onChangeTheme,
    header = {},
    onChangeHeader,
    cache,
    updateCache,
  } = props;

  const { children = [] } = data;
  const { title = '', subtitle = '' } = header;

  const computedTheme = computeTheme({ theme, defaultTheme });
  const baseBgStyle = _.get(computedTheme, 'styles.base.background', {});
  const baseTextStyle = _.get(computedTheme, 'styles.base.text', {});

  const contentElem = [
    <div key="h" className="CardListBlock-header">
      <FormTitleInput
        className="HubBlock-content-title"
        placeholder="Title"
        value={title}
        onChange={(e, { value }) => onChangeHeader('set', ['title'], value)}
        inputStyle={baseTextStyle}
      />

      <FormTextarea
        className="HubBlock-content-body"
        placeholder="Optional subtitle..."
        value={subtitle}
        minRows={1}
        onChange={(e, { value }) => onChangeHeader('set', ['subtitle'], value)}
        inputStyle={baseTextStyle}
      />
    </div>,

    <div key="c" className="CardListBlock-cards flex items-center justify-center flex-wrap">
      {_.map(children, (child, childIndex) => {
        return (
          <div key={childIndex} className="CardListBlock-wrapper flex items-center">
            <div className="CardListBlock-insert is-before">
              <Icon
                name="plus circle"
                size="big"
                onClick={() => {
                  onChangeData(
                    'splice',
                    ['children'],
                    {},
                    {
                      splice: {
                        index: 0,
                      },
                    }
                  );
                }}
              />
            </div>

            <CardChild
              child={child}
              childIndex={childIndex}
              isSolo={_.size(children) === 1}
              cache={_.get(cache, ['children', childIndex])}
              updateCache={(t, p, v) => updateCache(t, ['children', childIndex].concat(p), v)}
              onChangeData={(t, p, v) => onChangeData(t, ['children', childIndex].concat(p), v)}
              removeChild={() => {
                onChangeTheme('unset', ['styles', 'card', childIndex]);
                onChangeData('pull', ['children'], childIndex);
              }}
              theme={theme}
              onChangeTheme={onChangeTheme}
            />

            <div className="CardListBlock-insert is-after">
              <Icon
                className="CardListBlock-insert"
                name="plus circle"
                size="big"
                onClick={() => {
                  onChangeData(
                    'splice',
                    ['children'],
                    {},
                    {
                      splice: {
                        index: childIndex + 1,
                      },
                    }
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </div>,
  ];

  const headerActions = [];
  headerActions.push(
    <ColorPicker
      inline
      color={baseTextStyle.color}
      label="Base Text Color"
      onChangeComplete={color => {
        if (color.hex === _.get(defaultTheme, ['styles', 'base', 'text', 'color'])) {
          onChangeTheme('unset', ['styles', 'base', 'text', 'color']);
        } else {
          onChangeTheme('set', ['styles', 'base', 'text', 'color'], color.hex);
        }
      }}
    />
  );
  headerActions.push(
    <ColorPicker
      inline
      color={baseBgStyle.backgroundColor}
      label="Base Background Color"
      onChangeComplete={color => {
        if (
          color.hex === _.get(defaultTheme, ['styles', 'base', 'background', 'backgroundColor'])
        ) {
          onChangeTheme('unset', ['styles', 'base', 'background', 'backgroundColor']);
        } else {
          onChangeTheme('set', ['styles', 'base', 'background', 'backgroundColor'], color.hex);
        }
      }}
    />
  );

  return (
    <WrapperFactory
      {...props}
      headerProps={{ anchor: true }}
      headerActions={headerActions}
      contentProps={{ style: baseBgStyle }}
      contentElem={contentElem}
      className="flex flex-col justify-center"
    />
  );
};

export default BlockCardList;
