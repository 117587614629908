import React from 'react';
import { Menu } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';

import { Link } from '@platform/utils/router';
import { getConfigVar } from '@platform/utils/config';

import { Icon } from '@core/ui';

import PageContent from '../../../components/PageContent';

const OrgSettingsLayout = props => {
  const { activeTab = 'options', orgService, children, className, userService } = props;

  const { current = {} } = orgService;
  const { authorizedUser = {} } = userService;

  const canUpdate = orgService.canUser({ action: 'update' });

  return (
    <PageContent className={className}>
      <div>
        <div
          className="sticky"
          style={{
            top: 10,
            zIndex: 20,
            background: '#fff',
          }}
        >
          <Menu vertical>
            <Menu.Item fitted active={activeTab === 'options'}>
              <Link href={`/${current.path}/settings`} className="p-4 block">
                Options
              </Link>
            </Menu.Item>

            <Menu.Item fitted active={activeTab === 'filters'}>
              <Link href={`/${current.path}/settings/filters`} className="p-4 block">
                Filters & Tags
              </Link>
            </Menu.Item>

            {getConfigVar('STRIPE_PK') && (
              <Menu.Item fitted active={activeTab === 'billing'}>
                <Link href={`/${current.path}/settings/billing`} className="p-4 block">
                  Billing
                </Link>
              </Menu.Item>
            )}

            {getConfigVar('NODE_ENV') !== 'production' && (
              <Menu.Item fitted active={activeTab === 'sso'}>
                <Link href={`/${current.path}/settings/sso`} className="p-4 block">
                  Single Sign-on
                </Link>
              </Menu.Item>
            )}

            {authorizedUser.is_admin && (
              <Menu.Item fitted active={activeTab === 'admin'}>
                <Link href={`/${current.path}/settings/admin`} className="p-4 block">
                  Admin
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </div>
      </div>

      <div className="flex-grow ml-6">
        {canUpdate ? (
          children
        ) : (
          <div className="flex items-center h-10 text-white pl-4 bg-negative">
            <Icon icon={faExclamationTriangle} className="mr-3" />

            <div className="flex-1">
              Your account does not have the proper permissions to access this page.
            </div>
          </div>
        )}
      </div>
    </PageContent>
  );
};

export default inject('orgService', 'userService')(observer(OrgSettingsLayout));
