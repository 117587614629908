import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import copy from 'copy-to-clipboard';

import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';

import { alert } from '@platform/utils/alert';

import { Icon } from '@core/ui';

class PrivateToken extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    const { className, title, token } = this.props;
    const { show } = this.state;

    return (
      <div className={cn('PrivateToken', className)}>
        {title && <div className="text-muted mb-2">{title}</div>}
        <div className="flex items-center border border-grey-light font-bold mt-1 rounded cursor-pointer text-muted">
          <div
            className="flex-1 px-4 py-2 truncate"
            onClick={() => {
              copy(token);
              alert.success('Copied to clipboard.');
            }}
          >
            {show ? token : '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●'}
          </div>

          <div
            title={show ? 'Hide token' : 'Show token'}
            className="px-4 py-2 border-l"
            onClick={() => {
              this.setState({ show: !show });
            }}
          >
            <Icon icon={show ? faEye : faEyeSlash} />
          </div>

          <div
            title="Copy to clipboard"
            className="px-4 py-2 border-l"
            onClick={() => {
              copy(token);
              alert.success('Link copied to clipboard.');
            }}
          >
            <Icon icon={faCopy} />
          </div>
        </div>
      </div>
    );
  }
}

export default PrivateToken;
