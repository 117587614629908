import _ from 'lodash';
import { observable, flow, action } from 'mobx';

class GQLService {
  rootStore;

  queries = {};
  mutations = {};

  constructor({ rootStore }) {
    this.rootStore = rootStore;
  }

  refetchQueries = baseKeys => {
    _.forEach(baseKeys, key => {
      _.forEach(this.queries, ({ baseKey, refetch }) => {
        if (key === baseKey) {
          refetch();
        }
      });
    });
  };

  createQuery = ({ baseKey, query, localQueries }) => {
    return ({ id, variables, orderBy, pollInterval, onError }) => {
      // TODO: create a hash from variables object, do not rely on fields order!!!
      const key = `${baseKey}/${id}/${JSON.stringify(variables)}`;

      if (!this.queries[key]) {
        this.queries[key] = this.rootStore.apolloLink.createQuery({
          key: `${baseKey}/${id}`,
          baseKey,
          query,
          localQueries,
          variables,
          orderBy,
          pollInterval,
          onError,
        });
      }

      return this.queries[key];
    };
  };

  createMutation = ({ baseKey, mutation, refetchBaseKeys }) => {
    const self = this;

    return ({ id, onError }) => {
      const key = `${baseKey}/${id}`;

      if (!self.mutations[key]) {
        const observableMutation = observable.object(
          {
            id: key,
            ref: undefined,
            result: undefined,
            running: false,
            error: undefined,
            clearError: action(() => {
              observableMutation.error = undefined;
            }),
            run: flow(function*({ variables }) {
              observableMutation.running = true;

              // console.info(`mutation:${baseKey}:run`, { id, key, variables });

              observableMutation.ref = self.rootStore.apollo.mutate({
                mutation,
                variables,
              });

              try {
                observableMutation.result = yield observableMutation.ref;
                self.refetchQueries(refetchBaseKeys);
              } catch (error) {
                console.error(`mutation:${baseKey}:error`, { id, key, error });
                observableMutation.error =
                  _.get(error, 'message') || _.get(new Error(error), 'message');
              }

              observableMutation.running = false;

              if (observableMutation.error) {
                throw observableMutation.error;
              }

              return observableMutation.result;
            }),
          },
          {},
          { deep: false }
        );

        self.mutations[key] = observableMutation;
      }

      return self.mutations[key];
    };
  };
}

export { GQLService };
