import * as React from 'react';

const cn: any = require('classnames');

import { inject } from 'mobx-react';

// @ts-ignore
import { NavLink } from 'react-router-dom';

import { IUriComponents, URI } from '@core/uri';

export interface ILink {
  uri: URI | IUriComponents;
  children: any;
  className?: string;
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  routerStore?: any;
}

export class LinkComponent extends React.Component<ILink> {
  public state = {
    canRenderFragment: false,
  };

  constructor(props: any) {
    super(props);
  }

  /**
   * Special handling for SSR
   * the node server does not get the browser hash fragment, so it will not render links
   * with a hash included. This causes a mismatch no the client. So, we render initially on
   * the client without the hash, and then do a quick re-render (one time only on first mount)
   * to include the hash
   */
  public componentDidMount() {
    this.setState({ canRenderFragment: true });
  }

  public render() {
    const { uri, children, className, id, onClick, routerStore } = this.props;

    let target: URI;
    if (uri instanceof URI) {
      target = uri;
    } else {
      target = URI.from(uri);
    }

    // remove the fragment if we can't render it
    if (!this.state.canRenderFragment) {
      target = target.with({ fragment: '' });
    }

    const classNames = cn(className, 'cursor-pointer');

    // render a regular link if we are dealing with an absolute URL
    if (target.isAbsolute()) {
      let targetProp: string | undefined;

      // Open in new tab if URL is to a different domain
      if (target.scheme !== 'mailto' && target.authority !== routerStore.location.host) {
        targetProp = '_blank';
      }

      return (
        <a className={classNames} href={target.toURL()} id={id} target={targetProp}>
          {children}
        </a>
      );
    }

    return (
      <NavLink
        id={id}
        className={classNames}
        to={target.toURL()}
        onClick={(e: any) => {
          if (onClick) onClick(e);

          // Route interceptor will return undefined if navigation should be stopped
          // OR it will return a new location to navigate
          const location = routerStore._triggerRouteInterceptors({
            type: 'click',
            location: target.location,
          });

          if (!location) {
            e.preventDefault();
            return;
          }

          // scroll logic for hashes since not natively supported but NavLink
          if (location.hash) {
            routerStore.scrollToHash(location.hash);
          }
        }}
      >
        {children}
      </NavLink>
    );
  }
}

export const Link = inject('routerStore')(LinkComponent);
