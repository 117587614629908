import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { observer } from 'mobx-react';

import JsonPathCacheContainer from '@platform/components/JsonPathCacheContainer';

import BlockList from '../BlockList/editor';
import PageSettings from '../PageSettings';
import PageSidebar from '../PageSidebar/editor';

class HubPageEditor extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  render() {
    const { store, cache, updateCache } = this.props;
    const {
      currentPage,
      currentPath,
      currentRelativePath,
      currentParsed,
      currentDereferencedParsed,
      hasPageSidebar,
    } = store;

    const headElem = (
      <div>
        <div className="bg-grey-lighter py-3 px-4 rounded-md flex items-center border border-grey-light">
          <PageSettings />
        </div>
      </div>
    );

    let contentElem;
    if (!currentPage.ref && !_.isEmpty(currentPath)) {
      contentElem = (
        <BlockList
          store={store}
          blocks={_.get(currentParsed, 'data.blocks')}
          blocksDereferenced={_.get(currentDereferencedParsed, 'data.blocks')}
          parentPath={currentPath.concat(['data'])}
          relativePath={currentRelativePath}
          cache={cache}
          updateCache={updateCache}
        />
      );
    }

    return (
      <div
        className={cn('HubPage flex flex-1 relative', {
          'has-sidebar': hasPageSidebar,
        })}
      >
        {hasPageSidebar && <PageSidebar store={store} />}

        <div
          className="HubPageBody flex-1 bg-white w-full overflow-y-auto h-full"
          ref={elem => {
            this.wrapper = elem;
          }}
        >
          <div className="HubPageBody-inner">
            {headElem && (
              <div
                className="mx-auto mt-16"
                style={{ paddingLeft: 50, paddingRight: 50, maxWidth: 1400 }}
              >
                {headElem}
              </div>
            )}

            {contentElem}
          </div>
        </div>
      </div>
    );
  }
}

export default JsonPathCacheContainer({
  cacheKeyProp: 'store.id',
  pathProp: 'store.currentParsedPath',
  pure: false,
})(observer(HubPageEditor));
