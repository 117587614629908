import React from 'react';

import PageContent from '../../components/PageContent';
import TeamCreate from '../../components/TeamCreate';

const NewTeamPage = props => {
  return (
    <PageContent>
      <TeamCreate {...props} />
    </PageContent>
  );
};

export default NewTeamPage;
