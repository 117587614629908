import React from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { DragDropContext } from 'react-dnd';

import PageLoading from '@platform/components/PageLoading';
import PageError from '@platform/components/PageError';
import MainLayout from '@platform/components/MainLayout';
import ErrorMessage from '@platform/components/ErrorMessage';
import HtmlHead from '@platform/components/HtmlHead';
import { registerLogger } from '@platform/utils/logging';

import Modals from './modals';

const log = registerLogger('components', 'Page');

let Head = props => {
  const { routerStore, title, ...extra } = props;

  return <HtmlHead title={title || routerStore.props.title} {...extra} />;
};
Head = inject('routerStore')(observer(Head));

class Page extends React.Component {
  render() {
    const { routerStore, ...headProps } = this.props;

    log.debug('Render');

    let title;
    let error = this.props.error || routerStore.loadDataError;
    let pageError = error;

    // TODO. Should protect pages like /:org/settings from users without write access to org.
    // if (routerStore && routerStore.notAllowed && !error) {
    //   error = {
    //     status: userService.isLoggedIn ? 403 : 401,
    //   };
    // }

    let content;

    if (error) {
      // axios embeds the error we want in response
      error = error.response || error;

      const code = error.status || error.code;
      if (code === 404) {
        pageError = null;
        title = 'Not Found';
        content = (
          <PageError
            key="c"
            title="404"
            image
            message="The page you were looking for could not be found."
          />
        );
      } else if (code === 401 || code === 403) {
        pageError = null;
        title = 'Not Authorized';
        content = (
          <PageError
            key="c"
            icon="lock"
            iconProps={{ color: 'orange' }}
            message="You are not authorized to view this. If you believe this is a mistake, talk to your team
              administrator."
          />
        );
      } else if (code === 'ECONNREFUSED') {
        pageError = null;
        title = 'Could Not Connect';
        content = (
          <PageError
            key="c"
            icon="plug"
            iconProps={{ color: 'red' }}
            message="Could not connect to the Stoplight API. Are you behind a firewall? Please contact support@stoplight.io if this issue persists."
          />
        );
      } else {
        // TODO: send this to bugsnag (not sure if here is the best place)
        log.error(error);
        pageError = null;
        title = 'Unknown Error';
        content = (
          <PageError
            key="c"
            icon="bomb"
            iconProps={{ color: 'red' }}
            message="Hmm, something weird happened. If this persists, please contact support@stoplight.io!"
          />
        );
      }

      content = (
        <div key="c" className="h-full flex items-center justify-center">
          {content}
        </div>
      );
    } else {
      const { show } = _.get(routerStore, 'location.query', {});
      let overlayBg;
      let overlayClick;

      if (show) {
        overlayBg = 'darken';
        overlayClick = () => routerStore.setQueryParams({ show: null });
      }

      if (!routerStore.isReady) {
        content = (
          <div key="c" className="pin fixed flex items-center justify-center">
            <PageLoading className="bg-black" text="Initializing Stoplight..." />
          </div>
        );
      } else {
        content = (
          <MainLayout
            fullscreen
            key="c"
            overlay={
              overlayBg && (
                <div
                  className={`absolute pin bg-${overlayBg} z-20 transition-all opacity-${
                    overlayBg ? '1' : '0'
                  }`}
                  onClick={overlayClick}
                />
              )
            }
          />
        );
      }
    }

    return [
      <Head key="h" title={title} {...headProps} />,
      <ErrorMessage key="e" error={pageError ? pageError : null} />,
      content,
      <Modals key="m" />,
    ];
  }
}

export default DragDropContext(HTML5Backend)(
  inject((stores, props) => {
    const { routerStore, userService } = stores;

    return {
      routerStore,
      userService,
    };
  })(observer(Page))
);
