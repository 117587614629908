import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';

import FormInput from '@platform/components/FormInput';
import ErrorMessage from '@platform/components/ErrorMessage';

import { alert } from '@platform/utils/alert';
import { getConfigVar } from '@platform/utils/config';

import { colors, Icon, Layout } from '@core/ui';

class OrgCreate extends React.Component {
  handleSave = () => {
    const { routerStore, orgService, ui, clearUi, onSuccess, redirect = true } = this.props;

    orgService
      .create({
        name: ui.name,
      })
      .then(result => {
        alert.success('Organization created.');
        if (onSuccess) onSuccess(result);
        if (redirect) routerStore.push({ pathname: `/${result.path}` });
        clearUi();
      })
      .catch(e => {
        alert.error('There was an error creating the organization.');
        console.error('There was an error creating the organization.', e);
      });
  };

  render() {
    const { orgService, ui, updateUi, header } = this.props;

    const nextEnabled = !orgService.isCreating && _.size(ui.name) > 1;
    const subtitle = 'Organizations group together people, projects, and billing.';

    return (
      <Layout
        header={
          header || {
            title: 'New Organization',
            subtitle,
            bgClassName: 'bg-blue-light',
          }
        }
        footer={{
          right: {
            actions: [
              {
                children: orgService.isCreating ? 'Saving...' : 'Next',
                rightIcon: !orgService.isCreating && faArrowRight,
                color: nextEnabled ? colors.accent : colors.grey,
                disabled: !nextEnabled,
                onClick: this.handleSave,
              },
            ],
          },
        }}
        content={
          <div className="bg-white flex flex-col p-12">
            <ErrorMessage
              className="mb-8"
              error={orgService.error}
              onDismiss={orgService.clearError}
            />

            <div className="flex flex-1 flex-col">
              <FormInput
                label="ORGANIZATION NAME"
                name="name"
                placeholder="The name of your company or personal workspace"
                value={ui.name}
                onChange={(e, { value }) => {
                  updateUi('set', 'name', value);
                }}
                fluid
              />

              {getConfigVar('STRIPE_PK') && (
                <div className="mt-8 rounded-md overflow-hidden opacity-85 flex items-center">
                  <div className="px-4 bg-green text-white h-16 flex items-center text-lg">
                    <Icon icon={faGift} />
                  </div>
                  <div className="flex-1 px-4 h-16 text-darken-500 bg-grey-lightest flex items-center">
                    Enjoy a full featured trial for 2 weeks, after which you can choose one of our
                    flexible paid plans or stick with the free plan for public projects and basic
                    docs.
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      />
    );
  }
}

export default inject(({ routerStore, appStore, orgService }) => ({
  ...appStore.injectUi('OrgCreate'),
  routerStore,
  orgService,
}))(observer(OrgCreate));
