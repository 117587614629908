import React from 'react';

import { Link } from '@platform/utils/router';

import { Icon, Button, colors, sizes } from '@core/ui';

class BillingSection extends React.Component {
  render() {
    const { title, icon, className = '', children, link } = this.props;

    return (
      <div className={className}>
        <div className="opacity-85 text-3xl flex items-center mb-6">
          <Icon icon={icon} className="mr-4" />
          <div className="font-extrabold">{title}</div>

          {link && (
            <Link to={link.href} className="flex items-center">
              <Button className="ml-6" size={sizes.sm} color={colors.secondary} transparent>
                {link.content}
              </Button>
            </Link>
          )}
        </div>

        {children}
      </div>
    );
  }
}

export default BillingSection;
