import React from 'react';
import _ from 'lodash';

import FormInput from '../FormInput';
import MarkdownEditor from '../MarkdownEditor';

const SwaggerExternalDocs = props => {
  const { id, value, onChange } = props;

  return (
    <div className="SwaggerExternalDocs">
      <div className="flex">
        <FormInput
          className="flex-1 pr-2"
          onChange={e => {
            onChange('set', ['url'], e.target.value);
          }}
          input={{
            value: _.get(value, 'url'),
            fluid: true,
          }}
          label="documentation url"
          tip="The URL for the target documentation. Value MUST be in the format of a URL."
        />
      </div>

      <div className="mt-6">
        <MarkdownEditor
          id={`${id}:edocs`}
          value={_.get(value, 'description')}
          placeholder="Write your description here..."
          onChange={val => {
            onChange('set', ['description'], val);
          }}
          tip="A short description of the target documentation. Markdown syntax can be used for rich text representation."
        />
      </div>
    </div>
  );
};

export default SwaggerExternalDocs;
