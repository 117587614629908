import React from 'react';
import cn from 'classnames';
import { Image } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { faBug } from '@fortawesome/pro-solid-svg-icons/faBug';
import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faHeart } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faDesktop } from '@fortawesome/pro-solid-svg-icons/faDesktop';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff';
import { faCommentAlt } from '@fortawesome/pro-solid-svg-icons/faCommentAlt';

import Intercom from '@platform/utils/intercom';
import Zendesk from '@platform/utils/zendesk';
import { Link } from '@platform/utils/router';

import { Icon } from '@core/ui';

const UserLink = ({ href, icon, content, onClick }) => {
  const elem = (
    <div
      className="flex items-center justify-center px-4 py-2 text-lighten-700 hover:text-lighten-700 hover:bg-darken-300 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center justify-center mr-4 w-10">
        <Icon icon={icon} />
      </div>
      <div className="flex-1">{content}</div>
    </div>
  );

  if (href) {
    return <Link href={href}>{elem}</Link>;
  }

  return elem;
};

const UserDropdown = props => {
  const { userService, appStore, electronStore, className } = props;

  const authorizedUser = userService.authorizedUser;

  return (
    <div className={cn(className, 'bg-grey-darkest pb-2 shadow', { 'pt-2': !authorizedUser })}>
      {authorizedUser && (
        <Link href="/profile">
          <div className="flex items-center justify-center p-4 text-lighten-700 hover:text-lighten-700 hover:bg-darken-300">
            <Image src={authorizedUser.avatar_url} className="mr-4 w-10 h-10" />

            <div className="flex-1 flex flex-col">
              <div className="truncate">{authorizedUser.name}</div>
              <div className="truncate">{authorizedUser.username}</div>
            </div>
          </div>
        </Link>
      )}

      {authorizedUser && <div className="border border-lighten-50 mx-2 mb-2" />}

      <UserLink href="https://docs.stoplight.io" icon={faBook} content="Docs" />

      {Zendesk.sdk() && (
        <UserLink
          icon={faCommentAlt}
          content="Chat"
          onClick={() => {
            Zendesk.toggle();
          }}
        />
      )}

      {Intercom.sdk() && (
        <UserLink
          icon={faCommentAlt}
          content="Chat"
          onClick={() => {
            Intercom.show();
          }}
        />
      )}

      <div className="border border-lighten-50 m-2" />

      {!electronStore.enabled && (
        <UserLink
          href="https://github.com/stoplightio/desktop/releases/latest"
          icon={faDesktop}
          content="Desktop App"
        />
      )}

      <UserLink
        icon={faHeart}
        content="About"
        onClick={() => {
          appStore.openModal('AppAbout');
        }}
      />

      {authorizedUser && <div className="border border-lighten-50 m-2" />}

      {authorizedUser && <UserLink href="/logout" icon={faPowerOff} content="Log Out" />}
    </div>
  );
};

export default inject('appStore', 'userService', 'electronStore')(observer(UserDropdown));
