import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { Icon } from 'semantic-ui-react';

import AccountLogin from '@platform/components/AccountLogin';
import PageLoading from '@platform/components/PageLoading';
import { isOnPrem } from '@platform/utils/config';

import { alert } from '@platform/utils/alert';

class LoginPage extends React.Component {
  componentWillMount() {
    this.checkRedirect(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.checkRedirect(nextProps);
  }

  checkRedirect = props => {
    const { authorizedUser, routerStore, loggedIn } = props;

    if (authorizedUser && routerStore && typeof window !== 'undefined' && loggedIn) {
      const { r, nonce, sso } = _.get(routerStore, 'location.query') || {};

      let redirect = r || '/';
      if (sso === 'discourse') {
        const { id, email } = authorizedUser;
        redirect = `${redirect}?nonce=${nonce}&id=${id}&email=${email}`;
      } else {
        alert.success(`Welcome back ${authorizedUser.username}!`);
        redirect = r || undefined;
      }

      // if we just reset our password and haven't done onboarding
      if (localStorage.getItem('reset-password')) {
        // always remove from local storage
        localStorage.removeItem('reset-password');

        // only send to onboarding if we aren't redirecting and don't have a role set
        if (redirect === '/' && !authorizedUser.role && !isOnPrem()) {
          redirect = '/?modal=onboard';
        }
      }

      if (redirect) {
        setTimeout(() => {
          window.location.href = redirect || '/';
        }, 1000);
      } else {
        routerStore.replace('/');
      }
    }
  };

  render() {
    const { loggingIn, loggedIn, routerStore } = this.props;

    const sso = _.get(routerStore, 'location.query.sso');

    let contentElem;
    if (loggingIn) {
      contentElem = <PageLoading>Logging In...</PageLoading>;
    } else if (loggedIn) {
      contentElem = <PageLoading>Redirecting...</PageLoading>;
    } else {
      contentElem = (
        <AccountLogin
          github
          message={sso && `You will be redirected to ${sso} after logging in.`}
        />
      );
    }

    return (
      <div
        className="px-4 h-full flex flex-col items-center justify-center"
        style={{ minHeight: 700 }}
      >
        {!sso &&
          !isOnPrem() && (
            <div
              className="bg-lighten-200 text-white py-3 px-3 rounded mb-10"
              style={{
                backgroundColor: 'rgba(78, 55, 232, 0.8)',
                borderRadius: '4px',
                fontSize: '14px',
              }}
            >
              <Icon name="rocket" />
              Announcing{' '}
              <a
                style={{ color: 'white', fontWeight: 700 }}
                href="https://stoplight.io/studio"
                target="_blank"
              >
                Stoplight Studio
              </a>
              , Stoplight's next-generation API designer, with support for Git, OpenAPIv3, and more.
              Get started{' '}
              <a
                style={{ color: 'white', fontWeight: 700, textDecoration: 'underline' }}
                href="https://stoplight.io/studio"
                target="_blank"
              >
                here
              </a>
              .
            </div>
          )}

        {contentElem}
      </div>
    );
  }
}

export default inject(({ userService, routerStore }) => {
  return {
    routerStore,
    userService,
    authorizedUser: userService.authorizedUser,
    loggingIn: userService.loggingIn,
    loggedIn: userService.isLoggedIn,
  };
})(observer(LoginPage));
