import _ from 'lodash';
import axios from 'axios';

import { getConfigVar } from '../config';
import { hashToPath } from '../history';
import { createURL, getQueryParams, removeQueryString } from '../url';
import { stringifyQuery } from '../query';

export const isValidInternalRef = u => {
  if (_.startsWith(u, getConfigVar('SL_API_HOST'))) {
    // internal must have at least 3 path parts to be valid
    // when split, have empty first part, so must check for at least 4
    const u2 = createURL(u);
    return _.size((u2.pathname || '').split('/')) >= 4;
  }
};

export const fetchRef = props => {
  // deref can be "all", "remote", "local", or undefined
  const { method = 'get', url, headers = {}, cachios, deref, timeout } = props;

  let query = getQueryParams(url);
  let { url: requestUrl } = removeQueryString(url);
  const hash = _.split(requestUrl, '#/')[1];
  if (hash) {
    query.jsonPath = hashToPath({ hash });
  }

  // not going to the exporter then send it through the ref-proxy
  const apiHost = getConfigVar('SL_API_HOST');
  if (apiHost) {
    if (!_.startsWith(requestUrl, apiHost)) {
      requestUrl = `${apiHost || ''}/ref-proxy`;
      query = {
        target: url,
      };
    }

    if (deref) {
      query.deref = deref;
    }
  }

  /**
   * Add the query string directly to the path, instead of via axios.params,
   * to make testing (with moxios) possible.
   */
  if (!_.isEmpty(query)) {
    const queryString = stringifyQuery(query);
    if (!_.isEmpty(queryString)) {
      requestUrl = `${requestUrl}?${queryString}`;
    }
  }

  const requester = cachios ? cachios : axios;

  // TODO: should we treat ref-proxy as a proxy instead of doing this ?target thing?
  // https://github.com/axios/axios#request-config
  return requester.request({
    method,
    url: requestUrl,
    headers,
    timeout: timeout || 10000,
    withCredentials: true,
  });
};
