import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';

import { Icon } from '@core/ui';

import { Link } from '@platform/utils/router';

const BreadCrumbs = props => {
  const { className, crumbs } = props;

  const sections = [];

  _.forEach(crumbs, (crumb, index) => {
    const isLast = index === crumbs.length - 1;
    const crumbProps = {};

    let content = crumb.content;

    if (!isLast) {
      if (crumb.to) {
        content = <Link to={crumb.to}>{content}</Link>;
      } else if (crumb.onClick) {
        crumbProps.onClick = crumb.onClick;
      }
    }

    sections.push(
      <div
        key={index}
        className={cn('HubPageCrumb', {
          'text-black hover:text-primary': !isLast,
          'opacity-50 cursor-default': isLast,
        })}
        {...crumbProps}
      >
        {content}
      </div>
    );

    if (!isLast) {
      sections.push(
        <div key={`divider-${index}`} className="px-3 text-sm opacity-40">
          <Icon icon={faCaretRight} />
        </div>
      );
    }
  });

  return <div className={cn(className, 'HubPageCrumbs flex items-center')}>{sections}</div>;
};

export default BreadCrumbs;
