import { types, flow } from 'mobx-state-tree';
import { reaction } from 'mobx';
import _ from 'lodash';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/projects/:project_id/commits',
    })
    .views(self => {
      return {
        get currentCommitHash() {
          const { commit } = _.get(env.rootStore, 'stores.routerStore.location.query', {});

          return commit;
        },

        get current() {
          const commit = self.currentCommitHash;

          if (!commit) {
            return;
          }

          return _.first(
            self
              .findLocal({
                query: {
                  id: commit,
                },
                limit: 1,
              })
              .get()
          );
        },

        get currentList() {
          const project = _.get(env.rootStore, 'stores.projectStore.current');

          if (!project) {
            return;
          }

          const { id, currentRef } = project;

          return self
            .findLocal({
              query: {
                project_id: id,
                ref_name: currentRef,
              },
              sort: {
                created_at: -1,
              },
            })
            .get();
        },
      };
    })
    .actions(self => {
      const originalUpdateRecord = self.updateRecord;

      return {
        afterCreate: () => {
          setTimeout(() => {
            // Load current commit's diff after page load
            self.loadDiff(self.currentCommitHash);
            reaction(() => self.currentCommitHash, self.loadDiff);
          }, 0);
        },

        loadDiff: flow(function*(id) {
          if (!id) {
            return;
          }

          const project = _.get(env.rootStore, 'stores.projectService.current');
          if (!project) {
            return;
          }

          self.isLoading = true;

          try {
            const res = yield self.send(
              {
                method: 'get',
                path: `${self.basePath}/${id}/diff`,
              },
              {
                params: {
                  project_id: project.id,
                },
              }
            );

            self.updateRecord({
              id,
              changes: _.get(res, 'data.data'),
            });
          } catch (e) {
            self.error = _.get(e, 'response.data', String(e));
          }

          self.isLoading = false;
        }),

        /**
         * Overload updateRecord to transform the record to the format the client expects.
         * API doesn't include project_id, for example.
         */
        updateRecord(record, { params = {}, opts = {} } = {}) {
          if (!record) {
            return;
          }

          // add ref to commits so we can grab the correct list
          const extendedRecord = {
            ...record,
            ref_name: _.get(opts.query, 'ref_name'),
          };

          return originalUpdateRecord(extendedRecord, { params, opts });
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('CommitService');

  return Service.create(data, env);
};
