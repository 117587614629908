import React from 'react';
import { Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import UserProfileLayout from './_layout';

class UserNotifications extends React.Component {
  render() {
    return (
      <UserProfileLayout activeTab="notifications">
        <Message info>Coming Soon!</Message>
      </UserProfileLayout>
    );
  }
}

export default inject((stores, props) => {
  const { userService } = stores;

  return {
    userService,
  };
})(observer(UserNotifications));
