import React from 'react';
import { inject, observer } from 'mobx-react';

import Filters from '@platform/components/Filters';

import OrgSettingsLayout from './_layout';

class OrgFiltersPage extends React.Component {
  componentWillMount() {
    const { store, org } = this.props;
    store.find({ namespaceId: org.id });
  }

  render() {
    const { store, org } = this.props;
    const tags = store.tags.toJS();

    return (
      <OrgSettingsLayout activeTab="filters">
        <Filters
          namespaceId={org.id}
          store={store}
          tags={tags}
          tagType="lifecycle"
          headerName="Lifecycle"
          headerDescription="Use lifecycle tags to organize the various stages of your development process."
        />

        <Filters
          namespaceId={org.id}
          store={store}
          tags={tags}
          headerName="Tags"
          headerDescription="Use tags for arbitrary organization."
        />
      </OrgSettingsLayout>
    );
  }
}

export default inject(({ tagStore, orgService }, props) => {
  const org = orgService.current || {};
  let store = tagStore.register(`UserProfileFilter-${org.id}`);

  return {
    store,
    org,
  };
})(observer(OrgFiltersPage));
