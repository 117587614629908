import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { CardElement } from 'react-stripe-elements';

import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard';

import { Icon } from '@core/ui';

class BillingCardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: props.customer,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      _.get(nextProps.customer, 'id') !== _.get(this.state.customer, 'id') &&
      this.state.customer !== null
    ) {
      this.setState({ customer: nextProps.customer });
    }
  }

  render() {
    const { className, billingStore } = this.props;

    const {
      email,
      coupon,
      hideCoupon,
      namespaceType,
      showCardForm,
      previousCard,
      isSubscribing,
    } = billingStore;

    let cardElem;

    if (!showCardForm) {
      const { last4, exp_month, exp_year } = previousCard;

      cardElem = (
        <div className="flex items-center py-3 px-3 border border-grey-light rounded text-grey-darker">
          <div className="mr-4">
            <Icon icon={faCreditCard} />
          </div>

          <div className="flex-1 mr-4">
            xxxx-xxxx-xxxx-
            {last4}
          </div>

          <div className="mr-4">
            {exp_month}/{exp_year}
          </div>

          <div
            className="fflex justify-center cursor-pointer"
            onClick={() => (billingStore.showCardForm = true)}
          >
            <Icon icon={faTimes} />
          </div>
        </div>
      );
    }

    return (
      <div className={cn('flex h-lg', className)}>
        <div
          className={cn('flex-1 py-2 px-3 border border-grey-light rounded', {
            hidden: !showCardForm,
          })}
        >
          <CardElement onChange={data => (billingStore.card = data)} />
        </div>

        {cardElem}

        {namespaceType === 'org' && (
          <input
            className={cn(
              'appearance-none w-40  block text-grey-darker border border-grey-light py-3 px-3 rounded ml-3',
              { 'flex-1': showCardForm }
            )}
            placeholder="billing-email@yourco.com"
            value={email || ''}
            onChange={e => (billingStore.email = e.target.value)}
            readOnly={isSubscribing}
          />
        )}

        {!hideCoupon ? (
          <input
            className="appearance-none w-32 block text-grey-darker border border-grey-light py-3 px-3 rounded ml-3"
            placeholder="coupon"
            value={coupon || ''}
            onChange={e => (billingStore.coupon = e.target.value)}
            readOnly={isSubscribing}
          />
        ) : null}
      </div>
    );
  }
}

BillingCardForm.propTypes = {
  email: PropTypes.string,
  coupon: PropTypes.string,
  hideCoupon: PropTypes.bool,
  className: PropTypes.string,
  onFieldChange: PropTypes.func,
};

export default observer(BillingCardForm);
