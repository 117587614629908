import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import FormDropdown from '../FormDropdown';

class UserSearchDropdown extends React.Component {
  createOptions(foundMembers, email) {
    if (_.isEmpty(foundMembers)) return;

    const { updateUi, excludedMembers = [] } = this.props;

    if (_.isEmpty(foundMembers)) {
      updateUi('set', 'options', []);
    }

    const availableMembers = _.differenceBy(foundMembers, excludedMembers, 'id');
    const options = _.uniqBy(
      _.map(availableMembers, u => ({
        email, // to enable search by email in option list
        text: u.username,
        value: u.id,
        key: u.id,
      })),
      'value'
    );

    updateUi('set', 'options', options);
  }

  render() {
    const {
      handleSearch,
      searchOnChange = true,
      ui,
      updateUi,
      handleChange,
      allowAdditions = true,
      noResultsMessage,
      loading,
      debounceDelay = 500,
      ...extraProps
    } = this.props;

    let onFocus;
    let onSearchChange;
    if (handleSearch && searchOnChange) {
      onFocus = () => {
        if (handleSearch) {
          handleSearch().then(results => this.createOptions(results));
        }
      };

      onSearchChange = _.debounce((e, { searchQuery } = {}) => {
        handleSearch({ searchQuery }).then(results => this.createOptions(results));
      }, debounceDelay);
    }

    let onAddItem;
    if (allowAdditions) {
      onAddItem = (e, { value }) => {
        if (!value) return;

        updateUi(
          'set',
          'options',
          (ui.options || []).concat([
            {
              value,
              email: value,
              text: value,
              key: value,
            },
          ])
        );
      };
    }

    const search = (data, searchQuery) => {
      const re = new RegExp(_.escapeRegExp(searchQuery), 'i');
      return _.filter(data, opt => re.test(_.deburr(opt.text)) || re.test(_.deburr(opt.email)));
    };

    return (
      <FormDropdown
        additionLabel={'Hit "Enter" to add '}
        onAddItem={onAddItem}
        search={search}
        options={(!loading && ui.options) || []}
        onFocus={onFocus}
        onSearchChange={onSearchChange}
        onChange={handleChange}
        allowAdditions={allowAdditions}
        noResultsMessage={loading ? 'Loading...' : noResultsMessage}
        clearSearchOnSelect
        selection
        fluid
        {..._.omit(extraProps, ['updateUi', 'clearUi', 'excludedMembers'])}
      />
    );
  }
}

UserSearchDropdown.propTypes = {
  handleChange: PropTypes.func,
  excludedMembers: PropTypes.array,
};

export default inject(({ appStore, userService }, { id, excludedMembers = [], includeSelf }) => {
  return {
    ...appStore.injectUi(`${id}:UserSearchDropdown`),
    excludedMembers: includeSelf
      ? excludedMembers
      : _.compact(excludedMembers.concat([userService.authorizedUser])),
  };
})(observer(UserSearchDropdown));
