import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Form, Icon } from 'semantic-ui-react';

import { apiTip } from '@platform/utils/instances';
import { httpMethods } from '@platform/utils/http';

import RefBuilderList from '@platform/components/RefBuilderList';
import EntityEditorGroup from '@platform/components/EntityEditorGroup';
import EditorContentSection from '@platform/components/EditorContentSection';

const InstanceApi = props => {
  const { id, value, onChange, embedded } = props;
  const api = value;
  const tipData = apiTip(api);

  // make sure we're including any extras that might not be currently connected
  const currentSpecs = _.get(api, ['specs'], []);
  const formElem = (
    <Form>
      <div className="InstanceApi-tip">
        <Icon name="info circle" />
        {tipData.map((t, i) => (
          <span key={i} className={t.className}>
            {`${i === 0 ? '' : ' '}${t.text}`}
          </span>
        ))}
        .
      </div>

      <Form.Group widths="equal">
        <Form.Input
          label="ID"
          placeholder="e.g. 'my-api-name'"
          value={_.get(api, ['id'], '')}
          onChange={e => {
            onChange('set', ['id'], e.target.value);
          }}
        />

        <Form.Input
          label="Upstream URL"
          placeholder="if set, incoming requests will be forwarded to this url"
          value={_.get(api, ['upstream_url'], '')}
          onChange={e => {
            onChange('set', ['upstream_url'], e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Select
          label="Methods"
          placeholder="e.g. `post`"
          options={_.map(_.keys(httpMethods), h => ({ text: h, value: h }))}
          value={_.get(api, ['methods']) || []}
          onChange={(e, { value }) => {
            if (_.isEmpty(value)) {
              onChange('unset', ['methods']);
            } else {
              onChange('set', ['methods'], value);
            }
          }}
          multiple
          allowAdditions
          search
        />

        <Form.Select
          label="Hosts"
          placeholder="e.g. `api.example.com`"
          options={_.map(_.get(api, ['hosts'], []), h => ({
            text: h,
            value: h,
          }))}
          value={_.get(api, ['hosts']) || []}
          onChange={(e, { value }) => {
            if (_.isEmpty(value)) {
              onChange('unset', ['hosts']);
            } else {
              onChange('set', ['hosts'], value);
            }
          }}
          multiple
          allowAdditions
          search
        />

        <Form.Select
          label="Paths"
          placeholder="e.g. `/users`"
          options={_.map(_.get(api, ['paths'], []), h => ({
            text: h,
            value: h,
          }))}
          value={_.get(api, ['paths']) || []}
          onChange={(e, { value }) => {
            if (_.isEmpty(value)) {
              onChange('unset', ['paths']);
            } else {
              onChange('set', ['paths'], value);
            }
          }}
          multiple
          allowAdditions
          search
        />
      </Form.Group>

      <EntityEditorGroup
        id="tutorial-collection-contract-test-settings"
        className="mt-6"
        name="Connected Specs"
        defaultOpen={!embedded}
        active={!_.isEmpty(currentSpecs)}
        padded
        contentFactory={() => {
          return (
            <RefBuilderList
              id={id}
              refs={currentSpecs}
              onChange={(t, p, v) => {
                onChange(t, ['specs'].concat(p), v);
              }}
              fileFilter={{ modeling: [/oas2/] }}
              canAdd
              canRemove
            />
          );
        }}
      />
    </Form>
  );

  return (
    <div className="InstanceApi">
      {embedded ? formElem : <EditorContentSection>{formElem}</EditorContentSection>}
    </div>
  );
};

export default inject(({ instanceEditorStore }, { editorId }) => {
  return { editor: instanceEditorStore.getEditor({ id: editorId }) };
})(observer(InstanceApi));
