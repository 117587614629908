import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import Accordion from '@platform/components/Accordion';

class BillingUsageBreakdown extends React.Component {
  render() {
    const { usage } = this.props;

    return (
      <div>
        {_.map(usage.lineItems, (row, index) => {
          const rowElem = [
            <div key="1" className="flex-grow">
              {row.description}
              {row.details && <span className="text-grey-dark"> ({row.details})</span>}
            </div>,

            <div
              key="2"
              className={cn('px-1/2', {
                'bg-green-lightest': index === usage.lineItems.length - 1,
              })}
            >
              {_.isNumber(row.price)
                ? `${numeral(row.price).format('$0,0.00')} / ${row.interval}`
                : row.price}
            </div>,
          ];

          return (
            <div
              key={index}
              className={cn('flex items-center', {
                'py-2': !row.members && index !== usage.lineItems.length - 1,
                'py-3': !row.members && index === usage.lineItems.length - 1,
                'font-bold border-t border-grey-light': index === usage.lineItems.length - 1,
              })}
            >
              {row.members ? (
                <Accordion
                  id={`Billing-${index}`}
                  className="BillingLineItem"
                  exclusive
                  panels={[
                    {
                      key: 0,
                      title: <div className="flex">{rowElem}</div>,
                      content: row.members.map(member => {
                        return (
                          <div key={member.id} className="pl-6">
                            {member.username}
                          </div>
                        );
                      }),
                    },
                  ]}
                  fluid
                />
              ) : (
                rowElem
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

BillingUsageBreakdown.propTypes = {
  usage: PropTypes.object.isRequired,
};

export default BillingUsageBreakdown;
