import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Icon, Popup } from 'semantic-ui-react';

import FormAutosizeInput from '@platform/components/FormAutosizeInput';
import JsonPathCacheContainer from '@platform/components/JsonPathCacheContainer';

// Render the viewer header in preview mode
import { HubBlockHeader } from './viewer';

// Editors
import barListEditor from '../BlockBarList/editor';
import calloutEditor from '../BlockCallout/editor';
import cardListEditor from '../BlockCardList/editor';
import codeEditor from '../BlockCode/editor';
import heroEditor from '../BlockHero/editor';
import htmlEditor from '../BlockHtml/editor';
import imageEditor from '../BlockImage/editor';
import jsonSchemaEditor from '../BlockJsonSchema/editor';
import refEditor from '../BlockRef/editor';
import tabsEditor from '../BlockTabs/editor';
import textEditor from '../BlockText/editor';
import httpEditor from '../BlockHttp/editor';
import containerEditor from '../BlockContainer/editor';
import accordionEditor from '../BlockAccordion/editor';

// Viewers
import barListViewer from '../BlockBarList/viewer';
import calloutViewer from '../BlockCallout/viewer';
import cardListViewer from '../BlockCardList/viewer';
import codeViewer from '../BlockCode/viewer';
import heroViewer from '../BlockHero/viewer';
import htmlViewer from '../BlockHtml/viewer';
import imageViewer from '../BlockImage/viewer';
import jsonSchemaViewer from '../BlockJsonSchema/viewer';
import refViewer from '../BlockRef/viewer';
import tabsViewer from '../BlockTabs/viewer';
import containerViewer from '../BlockContainer/viewer';
import textViewer from '../BlockText/viewer';
import httpViewer from '../BlockHttp/viewer';
import accordionViewer from '../BlockAccordion/viewer';

import { buildBlockClassNames } from '../../utils';
import { blockConfig } from '../../utils/editor';
import { blockConfig as viewerBlockConfig } from '../../utils/viewer';

import { registerLogger } from '@platform/utils/logging';
const log = registerLogger('components', 'HubBlock');

const BlockMap = {
  barList: {
    editor: barListEditor,
    viewer: barListViewer,
  },
  callout: {
    editor: calloutEditor,
    viewer: calloutViewer,
  },
  cardList: {
    editor: cardListEditor,
    viewer: cardListViewer,
  },
  code: {
    editor: codeEditor,
    viewer: codeViewer,
  },
  hero: {
    editor: heroEditor,
    viewer: heroViewer,
  },
  html: {
    editor: htmlEditor,
    viewer: htmlViewer,
  },
  image: {
    editor: imageEditor,
    viewer: imageViewer,
  },
  jsonSchema: {
    editor: jsonSchemaEditor,
    viewer: jsonSchemaViewer,
  },
  ref: {
    editor: refEditor,
    viewer: refViewer,
  },
  tabs: {
    editor: tabsEditor,
    viewer: tabsViewer,
  },
  text: {
    editor: textEditor,
    viewer: textViewer,
  },
  http: {
    editor: httpEditor,
    viewer: httpViewer,
  },
  container: {
    editor: containerEditor,
    viewer: containerViewer,
  },
  accordion: {
    editor: accordionEditor,
    viewer: accordionViewer,
  },
};

const HubBlockHeaderEditor = props => {
  const { type, headerProps, header = {}, actions = [], onChange } = props;
  const { token = '', title = '', subtitle = '', anchor = '' } = header;

  if (!onChange) {
    console.warn(
      `onChange() not passed through to HubBlockHeaderEditor for ${type} block. Skipping header render.`
    );
    return null;
  }

  return (
    <div className="flex items-center">
      <div className="flex items-center flex-1">
        {!headerProps || headerProps.token ? (
          <div>
            <FormAutosizeInput
              onChange={e => {
                const val = e.target.value;
                if (!_.isEmpty(val)) {
                  onChange('set', ['header', 'token'], val);
                } else {
                  onChange('unset', ['header', 'token']);
                }
              }}
              value={token}
              placeholder="token"
            />
          </div>
        ) : null}

        {!headerProps || headerProps.title ? (
          <div>
            <FormAutosizeInput
              onChange={e => {
                const val = e.target.value;
                if (!_.isEmpty(val)) {
                  onChange('set', ['header', 'title'], val);
                } else {
                  onChange('unset', ['header', 'title']);
                }
              }}
              value={title}
              placeholder="title"
            />
          </div>
        ) : null}

        {!headerProps || headerProps.subtitle ? (
          <div>
            <FormAutosizeInput
              onChange={e => {
                const val = e.target.value;
                if (!_.isEmpty(val)) {
                  onChange('set', ['header', 'subtitle'], val);
                } else {
                  onChange('unset', ['header', 'subtitle']);
                }
              }}
              value={subtitle}
              placeholder="subtitle"
            />
          </div>
        ) : null}

        {!headerProps || headerProps.anchor ? (
          <div className="ml-a">
            <FormAutosizeInput
              onChange={e => {
                const val = e.target.value;
                if (!_.isEmpty(val)) {
                  onChange('set', ['header', 'anchor'], val);
                } else {
                  onChange('unset', ['header', 'anchor']);
                }
              }}
              value={anchor}
              placeholder="anchor"
            />
          </div>
        ) : null}
      </div>

      {!_.isEmpty(actions) ? (
        <Popup
          on="click"
          position="bottom right"
          className="HubBlock-edit-headerOptions"
          trigger={
            <div className="HubBlock-edit-headerOptionsTrigger flex items-center">
              <Icon name="setting" />
            </div>
          }
        >
          {_.map(actions, (action, i) => {
            return (
              <div key={i} className="HubBlock-edit-headerAction flex items-center">
                {action}
              </div>
            );
          })}
        </Popup>
      ) : null}
    </div>
  );
};

const HubBlockContent = props => {
  const {
    cache,
    className,
    block = {},
    headerProps,
    headerActions,
    headerElem,
    contentClassName = '',
    contentProps = {},
    contentElem,
    onChange,
    previewMode,
  } = props;

  let header;

  // allow for blocks to override the header
  // can pass null to show no header at all (block prob has it's own special header)
  if (previewMode) {
    if (headerElem) {
      header = headerElem;
    } else if (!_.isEmpty(block.header) && _.isUndefined(headerElem)) {
      header = <HubBlockHeader header={block.header} />;
    }
  } else if (headerElem !== null) {
    header = (
      <div className="HubBlock-edit-header">
        {headerElem ? (
          headerElem
        ) : (
          <HubBlockHeaderEditor
            cache={cache}
            type={block.type}
            headerProps={headerProps}
            header={block.header}
            actions={headerActions}
            onChange={onChange}
          />
        )}
      </div>
    );
  }

  return (
    <div className={cn('HubBlock-inner flex-1 w-full', className)}>
      {header}

      {contentElem ? (
        <div className={cn('HubBlock-content', contentClassName)} {...contentProps}>
          {contentElem}
        </div>
      ) : null}
    </div>
  );
};

class HubBlockEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'editor',
    };
  }

  updateBlock = (t, p, v, options) => {
    const { editor, blockPath } = this.props;
    editor.updateParsed(t, blockPath.concat(p), v, options);
  };

  updateBlockData = (t, p, v, options) => {
    this.updateBlock(t, ['data', ...p], v, options);
  };

  updateBlockInput = (t, p, v, options) => {
    this.updateBlock(t, ['input', ...p], v, options);
  };

  updateBlockConfig = (t, p, v, options) => {
    this.updateBlock(t, ['config', ...p], v, options);
  };

  updateBlockTheme = (t, p, v, options) => {
    this.updateBlock(t, ['theme', ...p], v, options);
  };

  updateBlockHeader = (t, p, v, options) => {
    this.updateBlock(t, ['header', ...p], v, options);
  };

  removeBlock = skipConfirm => {
    if (!skipConfirm) {
      const r = window.confirm('Are you sure you want to turn this block into tabs?');
      if (!r) return;
    }

    const { editor, blockPath, blockIndex } = this.props;

    editor.updateParsed('pull', _.dropRight(blockPath), blockIndex);
  };

  copyBlock = () => {
    const { editor, block = {}, updateCache } = this.props;

    editor.setCopiedBlock(block);
    updateCache('refresh');
  };

  cutBlock = () => {
    const { editor, block = {}, blockPath } = this.props;

    editor.setCopiedBlock(block, blockPath);
    this.removeBlock(true);
  };

  refBlock = () => {
    const { block } = this.props;
    if (_.has(block, ['data', '$ref'])) {
      switch (block.type) {
        case 'text':
          this.updateBlockData('set', [], '');
          break;
        case 'jsonSchema':
          this.updateBlockData('set', [], { type: 'object', properties: {} });
      }
    } else {
      this.updateBlockData('set', [], {});
      this.updateBlockData('set', ['$ref'], '');
    }
  };

  updateActiveTab = ({ tab }) => {
    this.setState({ activeTab: tab });
  };

  showPreviewTab = () => {
    this.updateActiveTab({ tab: 'preview' });
  };

  showEditorTab = () => {
    this.updateActiveTab({ tab: 'editor' });
  };

  showVariablesTab = () => {
    this.updateActiveTab({ tab: 'variables' });
  };

  renderActions = actions => {
    return _.map(actions, action => {
      if (action.hide) return null;

      return (
        <div
          key={action.icon}
          className={cn(
            'HubBlockToolbar-action flex items-center justify-center',
            action.className,
            {
              'is-active': action.active,
            }
          )}
          onClick={action.onClick}
        >
          {action.title ? (
            <Popup
              trigger={<Icon name={action.icon} title={action.title} />}
              content={action.title}
              position="left center"
              size="tiny"
            />
          ) : (
            <Icon name={action.icon} title={action.title} />
          )}
        </div>
      );
    });
  };

  renderBlockTabs = () => {
    const { hideActions, block = {} } = this.props;

    if (hideActions) {
      return;
    }

    const actions = [
      {
        icon: 'unhide',
        title: this.state.activeTab === 'preview' ? null : 'Show Preview',
        className: this.state.activeTab === 'preview' ? 'is-active' : '',
        onClick: this.state.activeTab === 'preview' ? this.showEditorTab : this.showPreviewTab,
      },
      {
        icon: 'magic',
        title: 'Input Variables',
        className: this.state.activeTab === 'variables' ? 'is-active' : '',
        onClick: this.showVariablesTab,
        hide: block.type !== 'ref',
      },
    ];

    return this.renderActions(actions);
  };

  renderBlockActions = () => {
    const { hideActions, block } = this.props;
    const blockType = _.get(block, 'type');

    if (hideActions) {
      return;
    }

    const actions = [
      {
        icon: 'cut',
        title: 'Cut',
        onClick: this.cutBlock,
      },
      {
        icon: 'copy',
        title: 'Copy',
        onClick: this.copyBlock,
      },
    ];

    if (blockType === 'text') {
      actions.push({
        icon: 'linkify',
        title: 'Reference External Source',
        onClick: this.refBlock,
        active: _.has(block, 'data.$ref'),
      });
    }

    actions.push({
      icon: 'remove',
      title: 'Remove Block',
      onClick: this.removeBlock,
    });

    return this.renderActions(actions);
  };

  render() {
    const {
      id,
      blockPath,
      block = {},
      blockDereferenced = {},
      blockIndex,
      className,
      isNested,
      isSolo,
      isLast,
      basePath,
      relativePath,
      cache,
      updateCache,
      editor,
    } = this.props;

    log.debug('Render', {
      id,
      blockIndex,
      blockPath,
      basePath,
      relativePath,
      block,
    });

    const { activeTab } = this.state;
    const previewMode = activeTab === 'preview';

    let toolbarElem;
    let contentElem;

    if (activeTab === 'variables') {
      // contentElem = this.renderInput();
    } else {
      const RenderedBlock = _.get(BlockMap, [block.type, previewMode ? 'viewer' : 'editor']);

      if (RenderedBlock) {
        let blockData = block.data;
        const isRef = _.get(blockData, '$ref');
        if (previewMode) {
          blockData = _.get(blockDereferenced, 'data');
        }

        let config = _.cloneDeep(_.get(editor.config, block.type, {}));

        const elemConfig = _.get(block, 'config', {});

        _.merge(config, elemConfig);

        contentElem = (
          <RenderedBlock
            {...this.props}
            isRef={isRef}
            block={block}
            onChange={this.updateBlock}
            header={block.header}
            onChangeHeader={this.updateBlockHeader}
            config={config}
            onChangeConfig={this.updateBlockConfig}
            theme={block.theme}
            onChangeTheme={this.updateBlockTheme}
            data={blockData}
            onChangeData={this.updateBlockData}
            previewMode={previewMode}
            basePath={basePath}
            relativePath={relativePath}
            cache={cache}
            updateCache={updateCache}
            WrapperFactory={HubBlockContent}
            dereferencedBlock={blockDereferenced}
          />
        );
      } else {
        contentElem = (
          <div>
            {block.type} {activeTab} has not been implemented.
          </div>
        );
      }
    }

    const blockTabs = this.renderBlockTabs();
    const blockActions = previewMode ? null : this.renderBlockActions();

    if (blockTabs || blockActions) {
      toolbarElem = (
        <div className="HubBlockToolbar">
          <div className="HubBlockToolbar-actions">
            {blockTabs}
            {blockActions}
          </div>
        </div>
      );
    }

    const classNames = buildBlockClassNames({
      block,
      config: previewMode ? viewerBlockConfig[block.type] : blockConfig[block.type],
      classNames: [`HubBlock HubBlock--${block.type} flex`, className],
      isNested,
      isSolo,
      isLast,
    });

    if (previewMode) {
      classNames.push('is-preview');
    } else {
      classNames.push('is-outlined is-editing');
    }

    // if it's nested, we render the toolbar on the right
    if (isNested) {
      return (
        <div className={classNames.join(' ')} id={`/blocks/${blockIndex}`}>
          {contentElem}
          {toolbarElem}
        </div>
      );
    }

    return (
      <div className={classNames.join(' ')} id={`/blocks/${blockIndex}`}>
        {toolbarElem}
        {contentElem}
      </div>
    );
  }
}

export default JsonPathCacheContainer({
  cacheKeyProp: 'editor.id',
  pathProp: 'blockPath',
})(HubBlockEditor);
