import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { Table, Checkbox, Statistic, Button, Icon, Input } from 'semantic-ui-react';

import ContractTestSettings from '../ContractTestSettings';
import PageLoading from '../PageLoading';
import EditorContentSection from '../EditorContentSection';

const SearchInput = observer(({ editor }) => (
  <Input
    icon="search"
    placeholder="Search..."
    value={editor.searchInput}
    onChange={(e, { value }) => {
      if (editor) {
        editor.updateSearchInput(value);
      }
    }}
  />
));

class CollectionCoverage extends React.Component {
  createFrom = (endpoints, coverageReport, newScenario, codes) => {
    return _.map(endpoints, (endpoint, i) => {
      const endpointCoverage = _.get(coverageReport, ['endpoints', endpoint.operationId], {});

      const methodPath = `${_.toUpper(endpoint.method)} ${endpoint.path}`;
      let title = endpoint.summary;
      let subtitle = endpoint.specName;

      if (_.isEmpty(endpoint.summary)) {
        title = methodPath;
      } else {
        subtitle = `${subtitle} - ${methodPath}`;
      }

      return (
        <Table.Row key={i}>
          <Table.Cell>
            <div className="font-bold">{title}</div>
            <div className="c-muted">{subtitle}</div>
          </Table.Cell>

          {codes.map((code, key) => {
            const codeCoverage = endpointCoverage[code] || {};
            const codeAsserted =
              codeCoverage.count && codeCoverage.failed === 0 && codeCoverage.passed > 0;

            const inScenario = _.find(newScenario, step => {
              return (
                step.operationId === endpoint.operationId &&
                step.code === code &&
                step.specId === endpoint.specId
              );
            });

            let colorClass;
            let coverageIcon = 'minus';
            let iconSize = 'small';

            if (inScenario) {
              colorClass = 'info';
              coverageIcon = codeAsserted ? 'checkmark' : 'remove';
              iconSize = 'large';
            } else if (!_.isEmpty(codeCoverage)) {
              colorClass = codeCoverage.color;
              coverageIcon = codeCoverage.icon;
              iconSize = codeCoverage.size;
            }

            return (
              <Table.Cell
                className={cn('ScenarioCoveragePage-Endpoint-Coverage-Cell', colorClass)}
                key={key}
                textAlign="center"
                onClick={() => {
                  this.addEndpointToScenario({ endpoint, code });
                }}
              >
                <Icon
                  className="ScenarioCoveragePage-Endpoint-Coverage-Icon"
                  name={coverageIcon}
                  size={iconSize}
                />

                <Icon
                  className="ScenarioCoveragePage-Endpoint-Coverage-Add"
                  name="add circle"
                  color="black"
                  size="large"
                />
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
    })
  }

  toggleAllCodesCheckbox = checked => {
    const { editor } = this.props;

    editor.toggleAllCodesCheckbox(checked);
  };

  toggleBodyCoverageCheckbox = checked => {
    const { editor } = this.props;

    editor.toggleBodyCoverageCheckbox(checked);
  };

  clearScenario = () => {
    const { editor } = this.props;

    editor.clearScenario();
  };

  createScenario = () => {
    const { connectedSpecs, editor } = this.props;

    editor.createScenario(connectedSpecs);
    this.clearScenario();
  };

  addEndpointToScenario = ({ endpoint, code }) => {
    this.props.editor.addEndpointToScenario({ endpoint, code });
  };

  removeFromScenario = index => {
    this.props.editor.removeFromScenario(index);
  };

  renderContractSettings = () => {
    const { editor } = this.props;

    return <ContractTestSettings editor={editor} />;
  };

  renderStats = () => {
    const { editor } = this.props;

    const {
      coverageReport,
      testCount,
      coveragePercent,
      coveragePercentColor,
      stepResults,
    } = editor;

    if (_.isEmpty(stepResults)) {
      return;
    }

    return (
      <Statistic.Group widths="3">
        <Statistic size="tiny" label="Assertions" value={testCount ? testCount : '-'} />
        <Statistic label="Coverage" value={`${coveragePercent}%`} color={coveragePercentColor} />
        <Statistic
          size="tiny"
          label="Endpoints"
          value={coverageReport.endpointCount ? coverageReport.endpointCount : '-'}
        />
      </Statistic.Group>
    );
  };

  renderTable = () => {
    const { editor } = this.props;

    const {
      debouncedSearchInput = '',
      newScenario,
      codes = [],
      filteredEndpoints = [],
      allCodes,
      hasSearchInput,
      coverageReport,
      isFetchingConnectedSpecs,
      oas2Refs,
    } = editor;

    let bodyElem;
    if (isFetchingConnectedSpecs) {
      bodyElem = (
        <Table.Row>
          <Table.Cell>
            <PageLoading inverted={false} />
          </Table.Cell>
        </Table.Row>
      );
    } else if (_.isEmpty(oas2Refs)) {
      bodyElem = (
        <Table.Row>
          <Table.Cell>
            <div className="p-3 font-bold c-warn">
              Connect a valid OAS2/Swagger spec to use coverage (settings pane above).
            </div>
          </Table.Cell>
        </Table.Row>
      );
    } else if (_.isEmpty(filteredEndpoints) && hasSearchInput) {
      bodyElem = (
        <Table.Row>
          <Table.Cell>
            <div className="p-3 font-bold c-warn">{debouncedSearchInput} not found.</div>
          </Table.Cell>
        </Table.Row>
      );
    } else if (filteredEndpoints.length) {
      bodyElem = this.createFrom(filteredEndpoints, coverageReport, newScenario, codes);
    } else {
      bodyElem = (
        <Table.Row>
          <Table.Cell>
            <div style={{"text-align": "center"}} className="font-bold">Invalid JSON : Please see the connected spec.</div>
          </Table.Cell>
        </Table.Row>
      );
    }

    return (
      <Table celled compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={codes.length + 1}>
              <div className="flex items-center p-3">
                <div className="flex-1 flex flex-col">
                  <div className="flex-1 mb-6">
                    <Checkbox className="pr-3" label="2xx Code Asserted" checked disabled />
                    <Checkbox
                      className="pl-3 pr-3"
                      label="All Codes"
                      checked={allCodes}
                      onChange={(e, { checked }) => this.toggleAllCodesCheckbox(checked)}
                    />
                  </div>

                  {!_.isEmpty(newScenario) ? (
                    <div>
                      {_.map(newScenario.slice(), (step, i) => (
                        <div
                          className="ScenarioCoveragePage-newScenario-step"
                          key={i}
                          onClick={() => this.removeFromScenario(i)}
                        >
                          <Icon name="remove" />{' '}
                          <span className="ScenarioCoveragePage-newScenario-step-info">
                            {i + 1}. {_.toUpper(step.method)} {step.path}{' '}
                            <span className="c-muted">[{step.code}]</span>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="c-muted">
                      click endpoint responses to create a scenario from them
                    </div>
                  )}
                </div>

                <div className="flex flex-col items-end">
                  <Button
                    disabled={_.isEmpty(newScenario)}
                    primary
                    onClick={() => this.createScenario()}
                  >
                    Create Scenario
                  </Button>

                  {!_.isEmpty(newScenario) ? (
                    <div className="mt-3">
                      <Button onClick={() => this.clearScenario()}>Clear Chosen Steps</Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>
              <SearchInput editor={editor} />
            </Table.HeaderCell>
            {codes.map((code, i) => (
              <Table.HeaderCell textAlign="center" key={i}>
                {code}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>{bodyElem}</Table.Body>
      </Table>
    );
  };

  render() {
    return (
      <div className="ScenarioCoveragePage">
        <EditorContentSection>
          {this.renderStats()}

          {this.renderContractSettings()}

          {this.renderTable()}
        </EditorContentSection>
      </div>
    );
  }
}

export default inject(({ routerStore, collectionEditorStore }, { editorId }) => {
  return {
    routerStore,
    editor: collectionEditorStore.getEditor({ id: editorId }),
  };
})(observer(CollectionCoverage));
