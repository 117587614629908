import gql from 'graphql-tag';

const comment = gql`
  fragment comment on Comment {
    id
    creator {
      avatar
      username
    }
    parentId
    parentType
    body
    createdAt
    updatedAt
  }
`;

export const comments = {
  list: gql`
    query CommentList($parentId: Int!, $parentType: CommentParentType!) {
      comments(parentId: $parentId, parentType: $parentType) {
        nodes {
          ...comment
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
    ${comment}
  `,

  create: gql`
    mutation CommentCreate($input: CreateCommentInput!) {
      createComment(input: $input) {
        ...comment
      }
    }
    ${comment}
  `,
};
