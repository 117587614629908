// @ts-ignore
import { Container, interfaces } from 'inversify';

import { makeFluentProvideDecorator } from 'inversify-binding-decorators';
import getDecorators from 'inversify-inject-decorators';

export const container = new Container();

const provideDecorator = makeFluentProvideDecorator(container);

// @provideSingleton decorator
export const provideSingleton = (identifier: any) => {
  return provideDecorator(identifier)
    .inSingletonScope()
    .done();
};

// @provide decorator
export const provide = (identifier: any) => {
  return provideDecorator(identifier).done();
};

// @inject decorator
export const lazyInject = getDecorators(container).lazyInject;

export { inject, injectable } from 'inversify';
