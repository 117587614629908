import React from 'react';

import SplitPane from '@platform/components/SplitPane';
import HtmlViewer from '@platform/components/HtmlViewer';

import EntityEditorCode from '../EntityEditor/spec';

const HtmlEditorCode = props => {
  const { editor = {}, preview } = props;

  const content = <EntityEditorCode key="editor" {...props} />;

  if (preview) {
    return (
      <SplitPane
        className="bg-white"
        defaultSize="50%"
        noPersist
        renderProp={() => [
          content,
          <HtmlViewer key="viewer" value={editor.spec} className="fill-space overflow-y-auto" />,
        ]}
      />
    );
  }

  return content;
};

export default HtmlEditorCode;
