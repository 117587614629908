import React from 'react';

import HubViewer from '@platform/format-hubs/components/Hub/viewer';

import EntityEditor from '../EntityEditor';
import EntityEditorCode from '../EntityEditor/spec';

const contentTabs = [
  {
    id: 'code',
    htmlId: 'tutorial-entity-code',
    name: 'Code',
    icon: 'code',
    className: 'is-light',
    component: EntityEditorCode,
  },
  {
    id: 'read',
    component: HubViewer,
    propFactory: ({ editor }) => {
      return { store: editor.hubViewerStore };
    },
  },
];

const Oas3Editor = props => {
  return (
    <EntityEditor
      editorStoreKey="oas3EditorStore"
      contentTabs={contentTabs}
      contentRouterData={{}}
      {...props}
      disableLocal
    />
  );
};

export default Oas3Editor;
