import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Segment } from 'semantic-ui-react';

import Accordion from '@platform/components/Accordion';

import InstanceApi from './api';

class InstanceApis extends React.Component {
  render() {
    const { editor = {} } = this.props;

    const apis = _.compact([_.get(editor, 'currentConfigBlocks.api', {})]);
    const panels = _.map(apis, (api, index) => {
      const apiId = api.id;

      return {
        key: apiId,
        title: apiId,
        defaultOpen: index === 0,
        content: () => (
          <InstanceApi
            id={`${editor.id}:${apiId}`}
            editorId={editor.id}
            value={api}
            embedded
            onChange={(t, p, v) => {
              editor.updateParsed(
                t,
                ['apis', _.findIndex(editor.parsed.apis, { id: apiId })].concat(p),
                v
              );
            }}
          />
        ),
      };
    });

    let contentElem;
    if (_.isEmpty(panels)) {
      contentElem = <Segment secondary>No API blocks match this request.</Segment>;
    } else {
      contentElem = (
        <Accordion
          id={`${editor.id}:apis`}
          panels={panels}
          exclusive={false}
          defaultActiveIndex={0}
          styled
          fluid
        />
      );
    }

    return <div className="InstanceApis">{contentElem}</div>;
  }
}

export default inject(({ instanceEditorStore }, { editorId }) => {
  return { editor: instanceEditorStore.getEditor({ id: editorId }) };
})(observer(InstanceApis));
