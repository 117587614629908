import React from 'react';
import { observer } from 'mobx-react';

import ScenarioStep from '../ScenarioEditor/step';
import EditorContentSection from '../EditorContentSection';

const EntityEditorHttp = props => {
  const { editor } = props;

  let mockElem;

  return (
    <div className="EntityEditor-widgets bg-grey-lightest">
      <EditorContentSection>
        {mockElem}

        <ScenarioStep
          editorId={editor.id}
          inputProps={{
            modeling: true,
            standalone: true,
            hosts: editor.hosts,
            tabWhitelist: [
              'settings',
              'headers',
              'basics',
              'auth',
              'body',
              'query',
              'code-generation',
            ],
            endpoints: editor.endpoints,
            updateHostOnSearch: false,
            connectedSpecs: [
              {
                id: editor.id,
                data: editor.dereferencedParsed,
              },
            ],
          }}
          outputProps={{
            tabWhitelist: ['body', 'headers', 'request'],
          }}
        />
      </EditorContentSection>
    </div>
  );
};

export default observer(EntityEditorHttp);
