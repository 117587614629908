import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

import CodeEditor from '@platform/components/CodeEditor';
import Highlight from '@platform/components/Highlight';
import FormCheckbox from '@platform/components/FormCheckbox';

import { colors, Button, Modal, Layout } from '@core/ui';

const EnvironmentEditor = props => {
  const { ui = {}, updateUi, appStore, projectStore, projectService } = props;

  if (!projectStore.current) return null;

  const modalId = 'environments';
  const closeModal = () => appStore.closeModal();
  const canPush = projectService.canUser({ action: 'push:project' });

  const {
    activeEnvKey,
    setActiveEnvKey,
    activeEnv,
    configEnvironments,
    resolvedEnv,
    updateActiveEnv,
  } = projectStore.current;

  const sidebarChildren = _.map(configEnvironments, (environment, environmentName) => {
    const isActive = environmentName === activeEnvKey;

    return {
      active: isActive,
      title: _.capitalize(environmentName),
      onClick: () => {
        if (isActive) return;
        setActiveEnvKey(environmentName);
      },
    };
  });

  if (canPush) {
    sidebarChildren.unshift(() => {
      return (
        <Button
          key="action"
          className="mx-4 my-6"
          icon={faCog}
          color={colors.primary}
          onClick={() => {
            closeModal();

            projectStore.current.goToFile('.stoplight.yml', {
              query: {
                edit: '/environments',
              },
            });
          }}
        >
          Shared
        </Button>
      );
    });
  }

  return (
    <Modal
      show={appStore.activeModal === modalId}
      closeIcon={faTimes}
      onDidHide={closeModal}
      renderContent={() => {
        return (
          <Layout
            header={{
              title: 'Environments',
              subtitle:
                'Environments group together values and variables, and allow you to quickly switch contexts during testing, define dynamic documentation values, and more.',
            }}
            sidebar={{
              sections: [
                {
                  children: sidebarChildren,
                },
              ],
            }}
            content={
              <div className="flex flex-col">
                <div className="flex py-4 px-6 border-b border-grey-lighter">
                  <div className="flex-1">
                    {ui.showResolved ? (
                      <div className="font-bold text-xl">
                        Resolved Variables <small className="text-muted">(Read Only)</small>
                      </div>
                    ) : (
                      <div className="font-bold text-xl">Private Variables</div>
                    )}

                    <div className="text-muted text-sm">
                      {ui.showResolved
                        ? 'Private variables merged over your shared variables.'
                        : 'Stored on your computer. Good for sensitive data like API keys.'}
                    </div>
                  </div>

                  <FormCheckbox
                    label="Resolved"
                    tip="Combination of your Private Variables and your Shared Variables."
                    className="py-4 px-6"
                    checked={ui.showResolved}
                    onChange={checked => {
                      updateUi('set', 'showResolved', checked);
                    }}
                    inline
                    toggle
                  />
                </div>

                <div className="relative overflow-auto" style={{ height: 350 }}>
                  {ui.showResolved ? (
                    <div className="py-4 px-6">
                      <Highlight mode="json" content={resolvedEnv} lineNumbers={false} noFill />
                    </div>
                  ) : (
                    <CodeEditor
                      id={`${activeEnvKey}-env-var-editor`}
                      mode="json"
                      value={activeEnv}
                      onChange={value => updateActiveEnv(value, { replace: true })}
                      fill
                      autoRefresh={{
                        delay: 50,
                      }}
                    />
                  )}
                </div>
              </div>
            }
          />
        );
      }}
    />
  );
};

export default inject((stores, props) => {
  const { appStore, routerStore, projectStore, projectService } = stores;

  const projectId = _.get(projectService, 'current.id');

  return {
    ...appStore.injectUi(`${projectId}-EnvironmentEditor`),
    appStore,
    routerStore,
    projectStore,
    projectService,
  };
})(observer(EnvironmentEditor));
