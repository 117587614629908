import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';

import Accordion from '@platform/components/Accordion';
import FormCheckbox from '@platform/components/FormCheckbox';
import FormInput from '@platform/components/FormInput';
import FormInputList from '@platform/components/FormInputList';
import LearnMore from '@platform/components/LearnMore';
import SimpleSection from '@platform/components/SimpleSection';
import CodeEditor from '@platform/components/CodeEditor';

import { hasSubscriptionFeature } from '@platform/utils/billing';
import { cleanSlashes } from '@platform/utils/url';
import { getProtocol } from '@platform/utils/publishing';

import AccordionTitle from './accordionTitle';

const customCSSPanel = ({ customCSS, whitelabel, namespace, onChange }) => {
  const cssEnabled = hasSubscriptionFeature(namespace, 'css');
  const whitelabelEnabled = hasSubscriptionFeature(namespace, 'whitelabel');

  return {
    title: (
      <AccordionTitle
        title="CSS"
        checked={customCSS || whitelabel}
        namespace={namespace}
        feature="css"
      />
    ),
    content: (
      <div>
        <div className="mb-4">
          Include any custom CSS that you've applied using the Hub Theming.{' '}
          <LearnMore feature="custom-css" />
        </div>
        <FormCheckbox
          checked={customCSS}
          disabled={!cssEnabled && !customCSS}
          onChange={checked => {
            onChange('customCSS', checked);
          }}
          label="Include CSS"
          labelPosition="left"
          tip={
            <div>
              CSS from your
              <strong>
                <code> theme.css </code>
              </strong>
              file will be applied to your published Hub.
            </div>
          }
          inline
        />
        <FormCheckbox
          className="mt-4"
          label="White Label"
          labelPosition="left"
          checked={whitelabel}
          disabled={!whitelabelEnabled && !whitelabel}
          onChange={checked => {
            onChange('whitelabel', checked);
          }}
          tip="White labeling will remove all stoplight branding from the published Hub."
          inline
        />
      </div>
    ),
  };
};

const customJSPanel = ({ docId, customJS, namespace, onChange }) => {
  const jsEnabled = hasSubscriptionFeature(namespace, 'js');

  return {
    title: (
      <AccordionTitle title="JavaScript" checked={customJS} namespace={namespace} feature="js" />
    ),
    content: (
      <div>
        <div className="mb-4">
          Add custom JavaScript that will be placed in a {`<script>`} in the {`<head>`} of every
          page. <LearnMore feature="custom-js" />
        </div>

        <CodeEditor
          readOnly={!jsEnabled && !customJS}
          id={`${docId}-customJS`}
          value={customJS}
          mode="javascript"
          onChange={value => onChange('customJS', value)}
          maxLines={20}
          lint={false}
          autogrow
          noFill
        />
      </div>
    ),
  };
};

const basePathPanel = ({ domain, basePath, namespace, onChange }) => {
  const basePathEnabled = hasSubscriptionFeature(namespace, 'basePath');

  return {
    title: (
      <AccordionTitle
        title="Base Path"
        checked={basePath}
        namespace={namespace}
        feature="basePath"
      />
    ),
    content: (
      <div>
        <div className="mb-4">
          Publish your Hub to a specific base path on your domain. This is useful if you have a
          website at{' '}
          <a href={`${getProtocol()}://${domain}`} target="_blank" rel="noopener noreferrer">
            {getProtocol()}
            ://
            {domain}
          </a>{' '}
          and want your Hub to be at{' '}
          <a href={`${getProtocol()}://${domain}/docs`} target="_blank" rel="noopener noreferrer">
            {getProtocol()}
            ://
            {domain}
            /docs
          </a>
          .
        </div>

        <div className="mb-4">
          For more information, check out our docs on{' '}
          <LearnMore feature="embedding">embedding a Hub into an existing website</LearnMore>.
        </div>

        <FormInput
          className="mb-4"
          label="Base Path"
          placeholder="/docs"
          value={basePath}
          disabled={!basePathEnabled && !basePath}
          onChange={(e, { value }) => {
            onChange('basePath', value);
          }}
          onBlur={() => {
            if (basePath) {
              onChange('basePath', cleanSlashes(_.trim(basePath)));
            }
          }}
          fluid
        />
      </div>
    ),
  };
};

const redirectsPanel = ({ redirects = [], namespace, updateArray }) => {
  const redirectEnabled = hasSubscriptionFeature(namespace, 'redirects');

  return {
    title: (
      <AccordionTitle
        title="Redirects"
        checked={!_.isEmpty(redirects)}
        namespace={namespace}
        feature="redirects"
      />
    ),
    content: (
      <div>
        <div className="mb-4">
          Add 301 redirects to a page in your Hub or an external website.{' '}
          <LearnMore feature="redirects" />
        </div>

        <FormInputList
          className="flex flex-col w-full"
          fields={redirects.slice()}
          disabled={!redirectEnabled && _.isEmpty(redirects.slice())}
          properties={[
            {
              type: 'input',
              label: 'From',
              name: 'from',
              placeholder: '/some/path',
            },
            {
              type: 'input',
              label: 'To',
              name: 'to',
              placeholder: '/some/other/path or https://another-site.com',
            },
          ]}
          handleUpdate={(t, p, v) => {
            updateArray('redirects', t, p, v);
          }}
          preserveProp
        />
      </div>
    ),
  };
};

const variablesPanel = ({ docId, variables, namespace, onChange }) => {
  const variabledEnabled = hasSubscriptionFeature(namespace, 'variables');

  return {
    title: (
      <AccordionTitle
        title="Env Variables"
        checked={variables}
        namespace={namespace}
        feature="variables"
      />
    ),
    content: (
      <div>
        <div className="mb-4">
          Provide default values for environment variables used in your published Hub. Values
          defined here will replace text and fill inputs matching the {`{{$$.env}}`} syntax.{' '}
          <LearnMore feature="/documentation/variables-hubs" />
        </div>

        <CodeEditor
          readOnly={!variabledEnabled && _.isEmpty(variables)}
          id={`${docId}-variables`}
          mode="json"
          value={variables || '{}'}
          onChange={value => onChange('variables', value)}
          maxLines={20}
          autogrow
          noFill
          lint
        />
      </div>
    ),
  };
};

const Advanced = props => {
  const { namespace, publishStore } = props;
  if (!publishStore.canPublish) return null;

  return (
    <SimpleSection className="PublishViewer-providers mt-12" title="Advanced" icon={faCog}>
      <Accordion
        id="Advanced"
        styled
        fluid
        exclusive={false}
        panels={[
          redirectsPanel({
            namespace,
            redirects: publishStore.redirects,
            updateArray: publishStore.updateArray,
          }),
          customCSSPanel({
            namespace,
            customCSS: publishStore.customCSS,
            whitelabel: publishStore.whitelabel,
            onChange: publishStore.setValue,
          }),
          customJSPanel({
            namespace,
            docId: publishStore.doc.id,
            customJS: publishStore.customJS,
            onChange: publishStore.setValue,
          }),
          variablesPanel({
            namespace,
            variables: publishStore.variables,
            docId: publishStore.doc.id,
            onChange: publishStore.setValue,
          }),
          basePathPanel({
            namespace,
            domain: publishStore.domain,
            basePath: publishStore.basePath,
            onChange: publishStore.setValue,
          }),
        ]}
      />
    </SimpleSection>
  );
};

export default inject((stores, props) => {
  const { publishStore } = stores;

  return {
    publishStore: publishStore.current,
  };
})(observer(Advanced));
