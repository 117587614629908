import React from 'react';
import _ from 'lodash';
import TimeAgo from 'react-timeago';
import { inject, observer } from 'mobx-react';

import { Button } from '@core/ui';

import FormSelect from '@platform/components/FormSelect';
import PageLoading from '@platform/components/PageLoading';
import CodeEditor from '@platform/components/CodeEditor';
import MarkdownViewer from '@platform/components/MarkdownViewer';
import SidebarPopout from '@platform/components/SidebarPopout';

import { Link } from '@platform/utils/router';
import { buildExportUrl } from '@platform/utils/url';

let CommitViewer = props => {
  const { commit, commitService, ui, updateUi, routerStore } = props;

  const activeChange = ui.activeChange || '0';

  const changes = _.get(commit, 'changes', []);
  const change = changes[activeChange] || {};

  let diffContent;
  if (commitService.isLoading) {
    diffContent = <PageLoading size="small" inverted={false} />;
  } else if (change) {
    if (change.diff) {
      diffContent = (
        <CodeEditor className="h-full" mode="diff" readOnly value={change.diff} noFill />
      );
    }
  } else {
    diffContent = <div className="text-muted">No diff to show.</div>;
  }

  return (
    <SidebarPopout
      title={commit.title}
      actions={
        <Link href="/TODO" className="d-n">
          <Button>Browse @ Commit</Button>
        </Link>
      }
      subtitle={
        <div>
          <span className="font-bold">{commit.author_name}</span> committed{' '}
          <TimeAgo date={commit.created_at} minPeriod={60} />
        </div>
      }
      handleClose={() => routerStore.setQueryParams({ show: null })}
      header={
        <div className="text-muted text-sm">
          <MarkdownViewer value={`**${commit.id}**: ${commit.message}`} />

          {changes.length ? (
            <div className="mt-3 flex items-center">
              <FormSelect
                value={activeChange}
                placeholder="choose a file"
                onChange={(e, { value }) => {
                  updateUi('set', ['activeChange'], value);
                }}
                options={changes.map((chg, i) => ({
                  text: chg.new_path || chg.old_path,
                  value: String(i),
                }))}
              />

              <div className="ml-4">
                <Link
                  href={buildExportUrl({
                    projectId: commit.project_id,
                    branch: change.deleted_file ? _.get(commit, ['parent_ids', 0]) : commit.id,
                    filePath: change.new_path || change.old_path,
                  })}
                  target="_blank"
                >
                  View Raw @ Commit
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      }
    >
      {diffContent}
    </SidebarPopout>
  );
};

CommitViewer = inject(({ commitService, appStore, routerStore }, { commit }) => ({
  commitService,
  routerStore,
  ...appStore.injectUi(`${commit.id}:CommitViewer`),
}))(observer(CommitViewer));

const CommitViewerContainer = props => {
  const { commitService, routerStore } = props;
  const commit = commitService.current;

  if (!commit) {
    const firstCommit = _.first(commitService.currentList);
    if (firstCommit) {
      routerStore.setQueryParams({ commit: firstCommit.id });
    }

    return null;
  }

  return <CommitViewer commit={commit} />;
};

export default inject(({ commitService, routerStore }, { editorId }) => ({
  commitService,
  routerStore,
}))(observer(CommitViewerContainer));
