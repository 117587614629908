import React from 'react';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import { inject, observer } from 'mobx-react';

import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';

import ImageIcon from '@platform/components/ImageIcon';
import ErrorMessage from '@platform/components/ErrorMessage';
import FormInputLabel from '@platform/components/FormInputLabel';

import { alert } from '@platform/utils/alert';
import { getConfigVar } from '@platform/utils/config';

import { colors, Icon, Layout } from '@core/ui';

class OnboardingOrgDone extends React.Component {
  render() {
    const { header, orgService, onSuccess, ui, updateUi, clearUi } = this.props;

    const existingOrg = _.get(orgService, 'current') || {};
    const isSaving = orgService.isSaving;
    const nextEnabled = !isSaving;

    const inviteUrl = `${getConfigVar('SL_APP_HOST')}/${existingOrg.path}/invite?token=${_.get(
      existingOrg.invite_tokens,
      'invite_token_reporter',
      '●●●●●●●●●●'
    )}`;

    return (
      <Layout
        header={header}
        footer={{
          right: {
            actions: [
              {
                children: isSaving ? 'Saving...' : 'Next',
                rightIcon: !isSaving && faArrowRight,
                color: nextEnabled ? colors.accent : colors.grey,
                disabled: !nextEnabled,
                onClick: onSuccess,
              },
            ],
          },
        }}
        content={
          <div className="bg-white p-12">
            <ErrorMessage className="mb-8" error={ui.error} onDismiss={clearUi} />

            <div className="flex">
              <div className="pr-10">
                <div className="FormFileButton text-center">
                  <ImageIcon
                    imageUrl={existingOrg.avatar_url}
                    iconName={_.get(existingOrg, 'name', '')}
                    size="large"
                    isCircle
                  />

                  <div className="mt-2 text-blue text-sm">change logo</div>

                  <input
                    type="file"
                    onChange={e => {
                      e.preventDefault();
                      updateUi('unset', 'error');

                      orgService
                        .updateImage(existingOrg.id, {
                          file: _.get(e, 'target.files[0]'),
                        })
                        .then(result => {
                          alert.success('Org image updated.');
                        })
                        .catch(err => {
                          alert.error(`Error updating image.`);
                          updateUi('set', 'error', err);
                        });
                    }}
                    onClick={e => {
                      e.target.value = null;
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-1 flex-col">
                <div className="text-xl text-darken-600 font-semibold">Congrats!</div>

                <div className="mt-6">
                  Your new organization is ready. Invite team members by sharing the link below.
                  Click next to create your first project.
                </div>

                <FormInputLabel className="mt-8" label="PRIVATE INVITE URL" />

                <div className="flex items-center border border-grey-light font-bold mt-1 rounded cursor-pointer text-muted">
                  <div
                    className="flex-1 px-4 py-2"
                    onClick={() => {
                      copy(inviteUrl);
                      alert.success('Link copied to clipboard.');
                    }}
                  >
                    {inviteUrl}
                  </div>

                  <div
                    title="Copy link to clipboard"
                    className="px-4 py-2 border-l"
                    onClick={() => {
                      copy(inviteUrl);
                      alert.success('Link copied to clipboard.');
                    }}
                  >
                    <Icon icon={faCopy} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

export default inject((stores, props) => {
  const { appStore, orgService, routerStore, onboardingStore } = stores;

  return {
    ...appStore.injectUi('OnboardingOrgDone'),
    orgService,
    routerStore,
    onboardingStore,
  };
})(observer(OnboardingOrgDone));
