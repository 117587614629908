import { types } from 'mobx-state-tree';
import _ from 'lodash';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/projects/:projectId/branches',
    })
    .views(self => {
      return {};
    })
    .actions(self => {
      const originalUpdateRecord = self.updateRecord;

      return {
        /**
         * Overload updateRecord to transform the record to the format the client expects.
         * API doesn't include project_id, for example.
         */
        updateRecord(record, { params = {} } = {}) {
          if (!record) {
            return;
          }

          const project_id =
            record.project_id ||
            _.get(
              params,
              'projectId',
              _.get(env.rootStore.stores.routerStore, 'location.params.projectId')
            );

          const extendedRecord = {
            project_id,
            ...record,
          };

          // branches don't come with ids :(
          extendedRecord.id = `${project_id}:${record.name}`;

          return originalUpdateRecord(extendedRecord);
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('ProjectBranchService');

  return Service.create(data, env);
};
