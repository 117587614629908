import _ from 'lodash';
import { action, reaction, observable, flow } from 'mobx';
import { DiagnosticSeverity } from '@stoplight/types';

import { COMMANDS } from '@core/spec-css';
import { MENUS } from '@core/editor/store';

import { safeStringify } from '@platform/utils/json';

import CSSWorker from './css-worker';
import rawEditorStore, { RawEditor } from './rawEditorStore';
import { cssExtension } from './coreEditor';

class CSSEditor extends RawEditor {
  _extension = cssExtension;
  hideCloseButton = false;
  prependClass = 'HtmlViewer';
  replaceSelectors = ['*', 'html', 'body'];

  @observable
  cleanCss = '';

  constructor(props) {
    super(props);

    if (props.prependClass) {
      this.prependClass = props.prependClass;
    }

    if (props.replaceSelectors) {
      this.replaceSelectors = props.replaceSelectors;
    }

    if (props.embedded) {
      this.cleanSpec(props.spec);
    }

    this.hideCloseButton = props.hideCloseButton;
  }

  activate() {
    super.activate();

    if (!this.rootStore.isClient) return;

    this.registerListeners();
    this.registerCommands();
    this.registerReactions();
    this.registerMenuActions();
  }

  registerListeners = () => {
    this._disposables.push(
      this.rootStore.stores.routerStore.registerRouteInterceptor(({ type, location }) => {
        const currentLocation = this.rootStore.stores.routerStore.location;

        if (_.get(currentLocation, 'query.theme') && !_.get(location, 'query.theme')) {
          this.deactivate();
        }

        return location;
      })
    );
  };

  registerCommands = () => {
    this._disposables.push(
      this._commandRegistry.registerHandler(COMMANDS.format, {
        execute: this.formatCss,
      })
    );

    if (!this.hideCloseButton) {
      this._disposables.push(
        this._commandRegistry.registerHandler('close:theme', {
          execute: () => {
            this.rootStore.stores.routerStore.setQueryParams({ theme: null }, { preserve: true });
          },
        })
      );
    }
  };

  registerMenuActions = () => {
    if (!this.hideCloseButton) {
      this._disposables.push(
        this._menuRegistry.registerMenuAction(MENUS.toolbar.primary, {
          id: 'close:theme',
          commandId: 'close:theme',
          label: 'Close Theme',
        })
      );
    }
  };

  @action
  registerReactions = () => {
    this._disposables.push({
      dispose: reaction(
        () => ({
          spec: this.spec,
        }),
        this.cleanSpec,
        {
          name: 'Clean CSS',
          fireImmediately: true,
          delay: 500,
        }
      ),
    });
  };

  dereferenceParsed() {
    // Don't need to dereference
  }

  beforeSave = () => {
    this.formatCss();
  };

  formatCss = async () => {
    const formattedCss = await CSSWorker.exec('format:css', safeStringify({ css: this.spec }));

    this.updateSpec(formattedCss);
  };

  cleanSpec = flow(function* cleanSpec({ spec }) {
    try {
      this.cleanCss = yield CSSWorker.exec(
        'clean:css',
        safeStringify({
          css: this.spec,
          prependClass: this.prependClass,
          replaceSelectors: this.replaceSelectors,
        })
      );
      this._validations.dispose();
    } catch (error) {
      this._validations.dispose();

      this._validations.push(
        this._editor.addValidation({
          ruleId: 'Parsing Error',
          severity: DiagnosticSeverity.Error,
          message: error.message || '',
        })
      );
    }
  }).bind(this);
}

export default class CssEditorStore extends rawEditorStore {
  editorClass = CSSEditor;
}
