import React from 'react';
import { inject, observer } from 'mobx-react';
import EventsFeed from '@platform/components/EventsFeed';

import PageContent from '../../components/PageContent';

const Activity = props => {
  const { teamService } = props;
  const { current = {} } = teamService;

  return (
    <PageContent>
      <div className="flex-grow">
        <EventsFeed type="team" id={current.id} />
      </div>
    </PageContent>
  );
};

export default inject('teamService')(observer(Activity));
