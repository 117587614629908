import React from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from '@platform/utils/router';

import PageContent from '../../../components/PageContent';

const DesktopSettingsLayout = props => {
  const { activeTab = 'networking', children, className } = props;

  const header = <h1 className="text-white mb-8">Desktop Preferences</h1>;

  return (
    <PageContent header={header} className={className} bgClassName="">
      <div>
        <Menu vertical>
          <Menu.Item active={activeTab === 'networking'}>
            <Link href={`/desktop/settings/networking`} className="block">
              Networking
            </Link>
          </Menu.Item>

          <Menu.Item active={activeTab === 'prism'}>
            <Link href={`/desktop/settings/prism`} className="block">
              Prism
            </Link>
          </Menu.Item>

          <Menu.Item active={activeTab === 'enterprise'}>
            <Link href={`/desktop/settings/enterprise`} className="block">
              Enterprise
            </Link>
          </Menu.Item>

          <Menu.Item active={activeTab === 'help'}>
            <Link href={`/desktop/settings/help`} className="block">
              Help
            </Link>
          </Menu.Item>
        </Menu>
      </div>

      <div className="flex-grow ml-6">{children}</div>
    </PageContent>
  );
};

export default DesktopSettingsLayout;
