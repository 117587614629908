import { GQLService } from '../_gql';

import * as Fragments from './fragments';

export class CommentStore extends GQLService {
  constructor(props) {
    super(props);

    this.list = this.createQuery({
      baseKey: 'comments',
      query: Fragments.comments.list,
      localQueries: {
        comments: {
          typename: 'Comment',
        },
      },
    });

    this.createHandler = this.createMutation({
      baseKey: 'createComment',
      mutation: Fragments.comments.create,
    });
  }
}

export const create = ({ data, env, options = {} }) => {
  return new CommentStore({ rootStore: env.rootStore });
};
