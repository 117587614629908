import { types } from 'mobx-state-tree';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/teams/:teamId/members',
      perPage: 100,
    })
    .views(self => {
      return {
        get currentList() {
          const currentTeam = _.get(env.rootStore, 'stores.teamService.current');
          if (!currentTeam) return;

          return self
            .findLocal({
              query: {
                teamId: currentTeam.id,
              },
            })
            .get();
        },
      };
    })
    .actions(self => {
      const originalUpdateRecord = self.updateRecord;

      return {
        /**
         * Overload updateRecord to add teamId.
         */
        updateRecord(record, { params = {} } = {}) {
          if (!record) return;

          return originalUpdateRecord({
            ...record,
            teamId: _.get(env.rootStore, 'stores.teamService.current.id'),
          });
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('TeamMemberService');

  return Service.create(data, env);
};
