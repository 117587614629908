import React from 'react';
import _ from 'lodash';

import shortid from '@platform/utils/shortid';

import Accordion from '@platform/components/Accordion';
import RefBuilderList from '@platform/components/RefBuilderList';

class RuleAccordian extends React.Component {
  getRefs = rule => {
    const refs = {};

    _.forOwn(rule, (val, key) => {
      refs[key] = val.$ref;
    });

    return refs;
  };

  renderPanels = () => {
    const { id, rule, onChange, embedded } = this.props;
    const rulesKeys = ['before', 'after', 'done'];

    return _.map(rulesKeys, ruleKey => {
      const ruleId = `${id}:${ruleKey}`;
      const refs = this.getRefs(rule[ruleKey]);

      return {
        id: ruleId,
        title: ruleKey,
        icon: !_.isEmpty(refs)
          ? {
              name: 'check',
              color: 'green',
            }
          : null,
        defaultOpen: !embedded,
        content: () => {
          return (
            <RefBuilderList
              id={ruleId}
              refs={refs}
              onChange={(t, p, v) => {
                let transform = 'set';
                let path = p;

                if (t === 'pull') {
                  transform = 'unset';
                  path = [v];
                } else if (t === 'push') {
                  const id = shortid();
                  path = [id];
                }

                onChange(transform, [ruleKey].concat(path), { $ref: v });
              }}
              fileFilter={{ scenario: [/scenario/] }}
              routeDataTargets={{ scenario: ['scenarios', 'utilities'] }}
              targets
              canAdd
              canRemove
              context
            />
          );
        },
      };
    });
  };

  render() {
    const { id, embedded } = this.props;

    return <Accordion id={id} panels={this.renderPanels()} exclusive={embedded} styled fluid />;
  }
}

export default RuleAccordian;
