import React from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';

const ItemTypes = {
  ITEM: 'item',
};

const itemSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
  endDrag(props, monitor, component) {
    if (!component) {
      return;
    }

    const endIndex = props.index;

    // Time to actually perform the action
    props.endDrag(endIndex);
  },
};

const itemTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const foundDOMNode = findDOMNode(component);
    if (!foundDOMNode) {
      return;
    }

    const hoverBoundingRect = foundDOMNode.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveItem(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const dropConnector = connect => ({
  connectDropTarget: connect.dropTarget(),
});

const dragCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

class Item extends React.Component {
  // static propTypes {
  //   connectDragSource: PropTypes.func.isRequired,
  //   connectDropTarget: PropTypes.func.isRequired,
  //   index: PropTypes.number.isRequired,
  //   isDragging: PropTypes.bool.isRequired,
  //   id: PropTypes.any.isRequired,
  //   text: PropTypes.string.isRequired,
  //   moveItem: PropTypes.func.isRequired
  //   dropItem: PropTypes.func.isRequired
  // };

  render() {
    const { children, isDragging, connectDragSource, connectDropTarget } = this.props;
    const opacity = isDragging ? 0 : 1;

    return connectDragSource(connectDropTarget(<div style={{ opacity }}>{children}</div>));
  }
}

const ItemWrapper = DragSource(ItemTypes.ITEM, itemSource, dragCollector)(Item);

export default DropTarget(ItemTypes.ITEM, itemTarget, dropConnector)(ItemWrapper);
