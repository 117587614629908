import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Icon, Popup } from 'semantic-ui-react';

import { stringToColor } from '@platform/utils/colors';
import { calculateFromToJsonPath, safeStringify } from '@platform/utils/json';
import { Link } from '@platform/utils/router';
import { isExternalLink } from '@platform/utils/url';

import Modal from '../Modal';

import Tree from './tree';

const annotateLeafs = (items, isLeafFunc) => {
  _.forOwn(items, item => {
    if (item.children) {
      annotateLeafs(item.children, isLeafFunc);
    } else {
      item.leaf = isLeafFunc(item);
    }
  });
};

/**
 * Example:
    [{
      id: 1,
      className: 'c-positive',
      icon: 'circle',
      name: '200',
    }]
 */
const buildMetaElems = meta => {
  const metaElems = [];

  if (meta) {
    for (const m of meta) {
      if (m.icon) {
        metaElems.push(
          <div key={m.id} className={`SortableTree-metaItem  ${m.className || 'c-muted'}`}>
            <Icon name={m.icon} />
          </div>
        );
      } else {
        metaElems.push(
          <div key={m.id} className={`SortableTree-metaItem  ${m.className || 'c-muted'}`}>
            {m.name}
          </div>
        );
      }
    }
  }

  return metaElems;
};

const buildActionElems = actions => {
  const actionElems = [];

  if (actions) {
    for (const action of actions) {
      let elem;
      if (action.type === 'modal') {
        elem = (
          <Modal
            {...action.modalProps}
            key={action.id}
            triggerFactory={({ handleOpen }) => {
              return (
                <div
                  className={`TreeList-action flex items-center ${action.className ||
                    'c-muted'} is-action`}
                  onClick={e => {
                    if (action.onClick) {
                      action.onClick(e, handleOpen);
                    } else {
                      handleOpen();
                    }
                  }}
                >
                  <div>
                    <Icon name={action.icon} className="TreeList-actionIcon" />
                  </div>
                </div>
              );
            }}
          />
        );
      } else if (action.hasOwnProperty('href')) {
        elem = (
          <Link
            key={action.id}
            className={cn('TreeList-action flex items-center', action.className || 'c-muted', {
              [`is-${action.type}`]: action.type,
            })}
            to={action.href}
          >
            <div>
              <Icon name={action.icon} className="TreeList-actionIcon" />
            </div>
          </Link>
        );
      } else {
        elem = (
          <div
            key={action.id}
            className={cn('TreeList-action flex items-center', action.className || 'c-muted', {
              [`is-${action.type}`]: action.type,
            })}
            onClick={action.onClick}
          >
            <div>
              <Icon name={action.icon} className="TreeList-actionIcon" />
            </div>
          </div>
        );
      }

      if (action.tip) {
        elem = <Popup key={action.id} trigger={elem} content={action.tip} size="tiny" />;
      }

      actionElems.push(elem);
    }
  }

  return actionElems;
};

class SortableTree extends React.Component {
  state = {
    active: null,
    internal: false,
    externalTree: '{}',
    tree: {},
  };

  componentWillMount = () => {
    const { items } = this.props;
    this.setState({ tree: this.buildTree(items) });
  };

  componentWillUpdate = (nextProps, nextState) => {
    if (nextState.internal) {
      nextState.internal = false;
    } else {
      const { items } = nextProps;
      const externalEqual = safeStringify(items, 0) === nextState.externalTree;
      if (!externalEqual) {
        const externalTreeHash = this.externalTreeHash({ tree: items });
        nextState.tree = this.buildTree(items);
        nextState.externalTree = externalTreeHash;
      }
    }
  };

  buildTree = items => {
    const treeItems = _.cloneDeep(items);

    annotateLeafs(treeItems, item => {
      return isExternalLink(_.get(item, 'route.path'));
    });

    return {
      name: 'Subpages',
      children: treeItems,
    };
  };

  externalTreeHash = ({ tree = {} }) => {
    return safeStringify(tree, 0, (key, val) => {
      if (_.includes(['collapsed', 'leaf'], key)) return;
      return val;
    });
  };

  handleChange = ({ tree = {}, fromPath }) => {
    const { onChange } = this.props;

    const externalTreeHash = JSON.parse(this.externalTreeHash({ tree }));

    this.setState({
      tree,
      externalTree: safeStringify(externalTreeHash.children),
      internal: true,
    });

    if (!fromPath) return;

    const toPath = calculateFromToJsonPath({
      fromPath,
      tree: externalTreeHash,
    });

    onChange(fromPath, toPath);
  };

  renderNode = ({ id, node = {}, depth, children, isDragging, isCollapsed, toggleCollapse }) => {
    const { isActive, isMuted, isDivider, token } = node;

    let className = cn('SortableTree-item flex items-center', {
      'is-active': isActive,
      'is-muted': isMuted,
      'is-divider': isDivider,
    });

    const metaElems = buildMetaElems(node.meta);
    const actionElems = buildActionElems(node.actions);

    let toggleElem;
    if (!_.isEmpty(children)) {
      let iconName = 'folder open';
      if (isCollapsed) {
        iconName = 'folder';
      }

      toggleElem = (
        <div
          className="SortableTree-itemToggle "
          onMouseDown={e => e.stopPropagation()}
          onClick={toggleCollapse}
        >
          <Icon name={iconName} />
        </div>
      );
    }

    const icon = node.icon || _.get(node, 'token.icon');
    const contentElem = (
      <div className="SortableTree-itemInner flex-1 flex items-center" title={node.name}>
        {icon ? (
          <div className="SortableTree-itemIcon">
            <Icon name={icon} />
          </div>
        ) : null}

        <div className="SortableTree-itemName flex-1">{node.name}</div>

        {!_.isEmpty(metaElems) ? <div className="SortableTree-meta flex">{metaElems}</div> : null}

        {_.get(token, 'name') ? (
          <div className="flex items-center justify-center SortableTree-itemToken">
            <div
              style={{ color: stringToColor(token.name.toLowerCase()) }}
              className={`SortableTree-itemToken-inner flex items-center ${token.className ||
                `token--${token.name}`}`}
            >
              {token.name}
            </div>
          </div>
        ) : null}

        <div className="SortableTree-actions  flex">{actionElems}</div>
      </div>
    );

    if (node.hasOwnProperty('href') && !isDragging) {
      const href = typeof node.href === 'string' ? node.href : _.get(node.href, 'pathname');
      if (!_.startsWith(href, '/')) {
        className = `${className} is-link`;
      }

      return (
        <Link
          className={className}
          to={node.href}
          onClick={e => {
            // if we're clicking on an action, don't navigate
            const className =
              _.get(e, 'target.className', '') + _.get(e, 'currentTarget.className', '');

            if (className.includes('action')) {
              toggleCollapse(e, { collapsed: false });
            } else if (isActive) {
              toggleCollapse(null);
            } else if (!_.includes(className, 'icon')) {
              toggleCollapse(null, { collapsed: false });
            }
          }}
        >
          {toggleElem}
          {contentElem}
        </Link>
      );
    }

    return (
      <div className={className}>
        {toggleElem}
        {contentElem}
      </div>
    );
  };

  render() {
    const { className, currentPath } = this.props;
    const { tree } = this.state;

    return (
      <div className={cn('SortableTree', className)}>
        <Tree
          tree={tree}
          currentPath={currentPath}
          onChange={this.handleChange}
          renderNode={this.renderNode}
          hideRoot
        />
      </div>
    );
  }
}

export default SortableTree;
