import { observer } from 'mobx-react';
import * as React from 'react';

const cn: any = require('classnames');

import { lazyInject } from '@core/ioc';
import { IStringObject } from '@core/types';
import { themes } from '@core/ui';

import { IEditor, SYMBOLS } from './types';

export interface IPanel {
  theme?: themes;
  className?: string;
  style?: IStringObject<any>;
}

export class Panel extends React.Component<IPanel, {}> {
  @lazyInject(SYMBOLS.Editor)
  // @ts-ignore
  private _editor: IEditor;

  public render() {
    const { theme = themes.light, className, style } = this.props;
    const { activePanel, isActivated } = this._editor;

    if (!isActivated || !activePanel) return null;

    const sections = activePanel.computeSections(this._editor);

    return (
      <div
        style={style}
        className={cn(className, 'flex flex-col w-80', {
          'border-l border-grey-light bg-white': theme === themes.light,
        })}
      >
        {sections}
      </div>
    );
  }
}

export default observer(Panel);
