import _ from 'lodash';
import { computed } from 'mobx';
import { types, flow } from 'mobx-state-tree';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  // needs to be evaluated as a function, because when this service is created, env.rootStore
  // might not have all the other stores that we need loaded yet
  const namespaceStores = () => ({
    org: env.rootStore.stores.orgService,
    user: env.rootStore.stores.userService,
    team: env.rootStore.stores.teamService,
  });

  const getStoreForData = data => {
    if (!data) {
      return;
    }

    const stores = namespaceStores();
    for (const name in stores) {
      if (_.get(data, 'namespace_type') === name) {
        return stores[name];
      }
    }
  };

  const Base = types
    .model({
      basePath: '/namespaces',
    })
    .views(self => {
      const originalGetLocal = self.getLocal;

      return {
        // overload the BaseService get method, because we need to fetch the result from another
        // store (whatever type this namespace is, orgs, users, teams, etc)
        getLocal(id, searchProps) {
          let result = originalGetLocal(id, searchProps).get();

          if (result) {
            const namespaceService = getStoreForData(result);
            if (namespaceService) {
              return namespaceService.getLocal(id, searchProps);
            }
          } else {
            // try each of the namespace stores
            const stores = namespaceStores();

            return computed(() => {
              for (const name in stores) {
                result = stores[name].getLocal(id, searchProps).get();
                if (result) {
                  return {
                    ...result,
                    namespace_type: name,
                  };
                }
              }
            });
          }
        },

        // returns the namespace entity for the current browser location, from local records
        get current() {
          const { userService } = _.get(env.rootStore, 'stores', {});

          const { namespaceId, orgId } =
            _.get(env.rootStore, 'stores.routerStore.location.params') || {};

          const { current, authorizedUser } = userService || {};

          const id =
            namespaceId || orgId || _.get(current, 'username') || _.get(authorizedUser, 'username');

          return self.getLocal(id).get();
        },
      };
    })
    .actions(self => {
      const originalGet = self.get;

      return {
        // overload the BaseService get method, because we need to store the result in another
        // store (whatever type this namespace is, orgs, users, teams, etc)
        get: flow(function*(id) {
          let data;

          try {
            data = yield originalGet(id, {}, { skipStore: true });

            /**
             * we only want to store a couple of properties in this
             * namespaceService - everything else is stored in the actual services for each
             * namespace type
             *
             * When skipStore is used, raw response is returned (not nested in data)
             */
            self.updateRecord({
              id: data.id,
              namespace_type: data.namespace_type || 'user',
            });

            const namespaceService = getStoreForData(data);
            if (namespaceService) {
              namespaceService.updateRecord(data);
            }
          } catch (error) {
            self.error = _.get(error, 'response.data', String(error));
            throw error;
          }

          return data;
        }),
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('NamespaceService');

  return Service.create(data, env);
};
