import React from 'react';
import update from 'immutability-helper';
import _ from 'lodash';

import ItemWrapper from './item';

class SortableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startIndex: null,
      items: [...(props.items || [])],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      items: [...(nextProps.items || [])],
    });
  }

  // only moves temporarily, in state
  moveItem = (dragIndex, hoverIndex) => {
    const { items, startIndex } = this.state;
    const dragItem = items[dragIndex];
    this.setState(
      update(this.state, {
        startIndex: {
          $set: startIndex === null ? dragIndex : startIndex,
        },
        items: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragItem]],
        },
      })
    );
  };

  // calls out to store the move permanently
  endDrag = endIndex => {
    const { startIndex } = this.state;
    const { handleUpdate, handleReorder } = this.props;

    if (startIndex !== null && startIndex !== endIndex) {
      if (handleReorder) {
        handleReorder(startIndex, endIndex);
      } else {
        const { items } = this.state;
        const dragItem = items[endIndex];
        handleUpdate(
          update(items, {
            $splice: [[endIndex, 1], [endIndex, 0, dragItem]],
          })
        );
      }
    }

    this.setState({ startIndex: null });
  };

  render() {
    const { items } = this.state;

    const component = _.map(items, (item, i) => {
      if (!item) {
        return;
      }

      if (item.disableDrag) {
        return <div key={item.id}>{item.elem}</div>;
      }

      return (
        <ItemWrapper
          key={item.id}
          index={i}
          id={item.id}
          moveItem={this.moveItem}
          endDrag={this.endDrag}
        >
          {item.elem}
        </ItemWrapper>
      );
    });

    return <div>{component}</div>;
  }
}

// This component expects the react tree it's used in to already be wrapped!
// export default DragDropContext(HTML5Backend)(SortableList);
export default SortableList;
