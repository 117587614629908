import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faPlug } from '@fortawesome/pro-solid-svg-icons/faPlug';

import Accordion from '@platform/components/Accordion';
import FormInput from '@platform/components/FormInput';
import SimpleSection from '@platform/components/SimpleSection';
import FeatureBadge from '@platform/components/FeatureBadge';

import { hasSubscriptionFeature } from '@platform/utils/billing';

import AccordionTitle from './accordionTitle';

const Integrations = props => {
  const { publishStore, namespace } = props;

  if (!publishStore.canPublish) return null;

  const { segment, intercom, googleAnalytics, gtm } = publishStore.integrations || {};

  const enabled = hasSubscriptionFeature(namespace, 'integrations');

  return (
    <SimpleSection
      className="mt-12"
      title="Integrations"
      icon={faPlug}
      actions={<FeatureBadge feature="integrations" show={!enabled} namespace={namespace} />}
    >
      <Accordion
        id="Integrations"
        styled
        fluid
        exclusive={false}
        panels={[
          {
            title: <AccordionTitle title="Segment" checked={segment} />,
            content: (
              <FormInput
                placeholder="Write key"
                value={segment}
                onChange={(e, { value }) => {
                  publishStore.setValue('integrations.segment', _.trim(value));
                }}
                disabled={!enabled && !segment}
                fluid
              />
            ),
          },
          {
            title: <AccordionTitle title="Intercom" checked={intercom} />,
            content: (
              <FormInput
                placeholder="App ID"
                value={intercom}
                onChange={(e, { value }) => {
                  publishStore.setValue('integrations.intercom', _.trim(value));
                }}
                disabled={!enabled && !intercom}
                fluid
              />
            ),
          },
          {
            title: <AccordionTitle title="Google Analytics" checked={googleAnalytics} />,
            content: (
              <FormInput
                placeholder="UA-12345678-1"
                value={googleAnalytics}
                onChange={(e, { value }) => {
                  publishStore.setValue('integrations.googleAnalytics', _.trim(value));
                }}
                disabled={!enabled && !googleAnalytics}
                fluid
              />
            ),
          },
          {
            title: <AccordionTitle title="Google Tag Manager" checked={gtm} />,
            content: (
              <FormInput
                placeholder="GTM-ABCDEFG"
                value={gtm}
                onChange={(e, { value }) => {
                  publishStore.setValue('integrations.gtm', _.trim(value));
                }}
                disabled={!enabled && !gtm}
                fluid
              />
            ),
          },
        ]}
      />
    </SimpleSection>
  );
};

export default inject((stores, props) => {
  const { publishStore } = stores;

  return {
    publishStore: publishStore.current,
  };
})(observer(Integrations));
