import React from 'react';
import { Segment } from 'semantic-ui-react';

import DesktopSettingsLayout from './_layout';

class DesktopSettingsHelp extends React.Component {
  render() {
    return (
      <DesktopSettingsLayout activeTab="help">
        <Segment>
          If you need help, or have questions, please email support@stoplight.io with a description
          of the issue.
        </Segment>
      </DesktopSettingsLayout>
    );
  }
}

export default DesktopSettingsHelp;
