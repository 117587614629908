import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Icon } from 'semantic-ui-react';

import FormTextarea from '@platform/components/FormTextarea';

import { calloutTypes } from './index';

const BlockCallout = props => {
  const { WrapperFactory, data = {}, onChangeData } = props;

  const { type = 'info', title = '', body = '' } = data;

  const contentElem = [
    <div key="ty" className="CalloutBlock-types flex mt-3 mb-3">
      {_.map(calloutTypes, (calloutType, k) => {
        const isActive = type === k;

        return (
          <div
            key={k}
            className={cn('CalloutBlock-type mr-2', { 'is-active': isActive })}
            onClick={() => {
              onChangeData('set', ['type'], k);
            }}
          >
            <Icon name={calloutType.icon} />
          </div>
        );
      })}
    </div>,

    <FormTextarea
      key="t"
      className="HubBlock-content-title"
      placeholder="Callout Title"
      value={title}
      minRows={1}
      onChange={e => onChangeData('set', ['title'], e.target.value)}
    />,

    <FormTextarea
      key="b"
      className="HubBlock-content-body"
      placeholder="Optional body..."
      value={body}
      minRows={1}
      onChange={e => onChangeData('set', ['body'], e.target.value)}
    />,
  ];

  return (
    <WrapperFactory
      {...props}
      headerProps={{ anchor: true }}
      contentClassName={`CalloutBlock--${type}`}
      contentElem={contentElem}
    />
  );
};

export default BlockCallout;
