import React from 'react';
import { inject, observer } from 'mobx-react';

import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

import { colors, Button, Layout, Modal } from '@core/ui';

import HubSettings from './editor';

const HubSettingsModal = props => {
  const { appStore, routerStore, canPublish } = props;

  const closeModal = () => {
    routerStore.setQueryParams({ tab: null });
    appStore.closeModal();
  };

  let publishButton = null;
  if (canPublish) {
    publishButton = (
      <Button
        key="action"
        className="mb-6"
        icon={faUpload}
        color={colors.primary}
        onClick={() => {
          closeModal();

          routerStore.setQueryParams({ show: 'publish' });
        }}
      >
        Publish
      </Button>
    );
  }
  return (
    <Modal
      show={appStore.activeModal === 'hub-settings'}
      closeIcon={faTimes}
      onDidHide={closeModal}
      renderContent={() => (
        <Layout
          header={{
            title: 'Hub Settings',
            subtitle: 'Add meta tags, top level navigation, and theming.',
          }}
          content={
            <div className="pl-12 pr-12 mt-6">
              {publishButton}
              <HubSettings handleClose={closeModal} />
            </div>
          }
        />
      )}
    />
  );
};

export default inject(({ appStore, routerStore, projectService }) => {
  return {
    canPublish: projectService.canUser({ action: 'publish:project' }),
    appStore,
    routerStore,
  };
})(observer(HubSettingsModal));
