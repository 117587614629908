import React from 'react';
import _ from 'lodash';

import MarkdownViewer from '../MarkdownViewer';

export default ({ title, subtitle, description, afterElem }) => {
  return (
    <div className="EntityViewer-pageTitle">
      <div className="EntityViewer-pageTitle-name">
        {title}
        {!_.isEmpty(subtitle) ? <small>{subtitle}</small> : null}
      </div>

      <MarkdownViewer value={description} className="mt-6 c-muted" />

      {afterElem}
    </div>
  );
};
