import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Button, Input, Table, Message, Checkbox, Dropdown, Loader, Menu } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

let SearchBar = ({ currentTableList, ...inputOptions }) => {
  return (
    <Input
      icon="search"
      placeholder="Search... (min 3 characters)"
      value={currentTableList.searchInput}
      onChange={(e, { value }) => {
        currentTableList.updateSearchInput(value);
      }}
      {...inputOptions}
    />
  );
};

SearchBar = observer(SearchBar);

class Container extends React.Component {
  componentWillMount() {
    this.initTableList(this.props);
    this.updateItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.scope, nextProps.scope)) {
      this.initTableList(nextProps);
    }

    if (!_.isEqual(this.props.items, nextProps.items)) {
      this.updateItems(nextProps);
    }
  }

  initTableList = (props, { force } = {}) => {
    const initProps = {
      scope: props.scope,
      items: props.items,
      onSearch: props.onSearch,
    };

    if (props.searchProps) {
      initProps.searchProps = props.searchProps;
    }

    props.tableListStore.initTableList(initProps, {
      force,
    });
  };

  currentTableList = props => {
    const p = props || this.props;
    return p.tableListStore.getTableList({ scope: p.scope });
  };

  updateItems = props => {
    const tb = this.currentTableList(props);
    if (tb && tb.updateItems) {
      tb.updateItems(props.items);
    }
  };

  addSelected = value => {
    this.currentTableList().addSelected(value);
  };

  removeSelected = value => {
    this.currentTableList().removeSelected(value);
  };

  resetSelected = () => {
    this.currentTableList().resetSelected();
  };

  render() {
    const {
      loading,
      items,
      cellFactories,
      actionButton,
      topSegment,
      dropdownItems,
      noItemsFactory,
      noItemsMessage,
      canAdd,
      noSearch,
      tabs = [],
      routerStore,
      children,
      footer,
      className,
    } = this.props;

    const currentTableList = this.currentTableList();

    const { filteredItems, debouncedSearchInput, selected, hasSearchInput } = currentTableList;

    let actionItem = null;
    if (actionButton) {
      if (typeof actionButton === 'function') {
        actionItem = actionButton();
      } else {
        actionItem = <Button primary basic {...actionButton} />;
      }
    }

    let topBar;
    if (_.isEmpty(tabs)) {
      if (actionItem || !noSearch) {
        topBar = (
          <div className="TableList-topBar flex">
            {actionItem && <div className="mr-3">{actionItem}</div>}

            {!noSearch && (
              <div className="flex-1 items-end ta-r">
                <SearchBar currentTableList={currentTableList} />
              </div>
            )}
          </div>
        );
      }
    } else {
      topBar = (
        <Menu>
          {actionItem && <Menu.Item>{actionItem}</Menu.Item>}

          {_.map(tabs, (tab, index) => (
            <Menu.Item
              key={index}
              active={tab.active}
              content={tab.name}
              onClick={() => {
                routerStore.push({
                  pathname: tab.href,
                });
              }}
            />
          ))}

          {!noSearch && (
            <Menu.Menu position="right">
              <Menu.Item>
                <SearchBar iconPosition="left" transparent currentTableList={currentTableList} />
              </Menu.Item>
            </Menu.Menu>
          )}
        </Menu>
      );
    }

    const classNames = cn('TableList', className, {
      'no-topBar': !topBar,
    });

    if (loading) {
      return (
        <div className={classNames}>
          {topBar}

          {topSegment}

          <Loader inline="centered" style={{ marginTop: 50, width: '100%' }} active />
        </div>
      );
    }

    if (_.isEmpty(items)) {
      return (
        <div className={classNames}>
          {topBar}

          {topSegment}

          {noItemsFactory ? (
            noItemsFactory()
          ) : (
            <Message info>
              {noItemsMessage
                ? noItemsMessage()
                : `No items found.${canAdd ? ' Add one using the button to the top right.' : ''}`}
            </Message>
          )}
        </div>
      );
    }

    return (
      <div className={classNames}>
        {topBar}

        {topSegment}

        {children}

        {_.isEmpty(filteredItems) ? (
          <Message warning>
            <Message.Header>
              {hasSearchInput ? `'${debouncedSearchInput}' not found.` : 'Not found.'}
            </Message.Header>
          </Message>
        ) : (
          <Table striped>
            {dropdownItems && (
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Checkbox
                      checked={items.length === selected.length}
                      onChange={(e, { checked }) => {
                        if (checked) {
                          return this.addSelected(_.map(items, 'id'));
                        }

                        return this.resetSelected();
                      }}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan={cellFactories.length}>
                    {selected.length ? (
                      <Dropdown
                        floating
                        button
                        labeled
                        text={`${selected.length} selected`}
                        className="icon"
                      >
                        <Dropdown.Menu>
                          {_.map(dropdownItems, ({ onClick, ...extraProps }, i) => (
                            <Dropdown.Item
                              {...extraProps}
                              key={i}
                              onClick={() => {
                                if (onClick) onClick(selected.slice());

                                this.resetSelected();
                              }}
                            />
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      'Select All'
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            )}
            <Table.Body>
              {_.map(filteredItems, (item, i) => (
                <Table.Row key={i}>
                  {dropdownItems && (
                    <Table.Cell collapsing>
                      <Checkbox
                        checked={_.includes(selected, item.id)}
                        onChange={(e, { checked }) => {
                          if (checked) {
                            return this.addSelected(item.id);
                          }

                          this.removeSelected(item.id);
                        }}
                      />
                    </Table.Cell>
                  )}
                  {cellFactories &&
                    _.map(cellFactories, (cellFactory, key) => cellFactory(item, `${i}-${key}`))}
                </Table.Row>
              ))}
            </Table.Body>

            {footer && (
              <Table.Footer>
                <Table.Row textAlign="center">
                  <Table.HeaderCell colSpan={cellFactories.length + 1}>{footer}</Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        )}
      </div>
    );
  }
}

export default inject(({ tableListStore, routerStore }, { scope }) => ({
  tableListStore,
  routerStore,
}))(observer(Container));
