import * as React from 'react';

import { Box, IBox, Icon, IIcon } from '@stoplight/ui-kit';

export interface ILoader extends IBox {
  icon?: IIcon;
  text?: string;
}

export const Loader: React.FunctionComponent<ILoader> = ({ icon, text = 'Searching...' }) => {
  return (
    <Box textAlign="center">
      <Icon icon="circle-notch" spin={true} {...icon} /> {text}
    </Box>
  );
};
