import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe';
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons/faPowerOff';

import PageGutter from '@platform/components/PageGutter';

import UserDropdown from '../UserDropdown';

const ProjectGutter = props => {
  const { routerStore, userService, projectService, projectStore } = props;

  const authorizedUser = userService.authorizedUser;
  const project = projectService.current || {};
  const currentLocation = _.get(routerStore, 'location');
  const query = _.get(routerStore, 'location.query');
  const entityPath = `/${project.path_with_namespace}`;
  const settingsPath = `${entityPath}/settings`;
  const settingsActive = currentLocation.pathname === settingsPath;
  const tabPath = settingsActive ? entityPath : currentLocation.pathname;
  const role = projectService.getHighestRole();
  const canUpdate = projectService.canUser({ action: 'push:project' });

  const sections = [];
  const tabs = [];

  tabs.push({
    name: 'Home',
    icon: faHome,
    href: {
      pathname: '/',
    },
  });

  tabs.push({
    name: 'Files',
    icon: faFileAlt,
    isActive: projectStore.isLeftPanelOpen && !query.show && !settingsActive,
    href: {
      pathname: tabPath,
      query: {
        ...query,
        show: undefined,
      },
    },
    onClick: e => {
      if (!settingsActive && !query.show) {
        e.preventDefault();
        projectStore.toggleLeftPanel();
        return false;
      } else {
        projectStore.toggleLeftPanel(true, 'files');
      }
    },
  });

  tabs.push({
    name: 'Change History',
    icon: faHistory,
    isActive: projectStore.isLeftPanelOpen && query.show === 'history',
    onClick: () => {
      if (query.show === 'history') {
        projectStore.toggleLeftPanel();
      } else {
        projectStore.toggleLeftPanel(true, 'history');
      }
    },
  });

  if (canUpdate) {
    tabs.push({
      name: 'Publish',
      icon: faGlobe,
      isActive: projectStore.isLeftPanelOpen && query.show === 'publish',
      onClick: () => {
        if (query.show === 'publish') {
          projectStore.toggleLeftPanel();
        } else {
          projectStore.toggleLeftPanel(true, 'publish');
        }
      },
    });
  }

  if (_.get(project, 'namespace.kind') === 'group' && role) {
    tabs.push({
      name: 'Members',
      icon: faUser,
      isActive: projectStore.isLeftPanelOpen && query.show === 'members',
      onClick: () => {
        if (query.show === 'members') {
          projectStore.toggleLeftPanel();
        } else {
          projectStore.toggleLeftPanel(true, 'members');
        }
      },
    });

    tabs.push({
      name: 'Teams',
      icon: faUsers,
      isActive: projectStore.isLeftPanelOpen && query.show === 'teams',
      onClick: () => {
        if (query.show === 'teams') {
          projectStore.toggleLeftPanel();
        } else {
          projectStore.toggleLeftPanel(true, 'teams');
        }
      },
    });
  }

  if (canUpdate) {
    tabs.push({
      name: 'Settings',
      icon: faCog,
      isActive: settingsActive,
      href: {
        pathname: settingsPath,
        query: {
          ...query,
          show: undefined,
        },
      },
      onClick: () => {
        projectStore.toggleLeftPanel(false, undefined);
      },
    });
  }

  sections.push({ className: 'flex-1', tabs });

  if (authorizedUser) {
    sections.push({
      tabs: [
        {
          name: authorizedUser.username,
          image: authorizedUser.avatar_url,
          popup: {
            posX: 'right',
            posY: 'center',
            offset: {
              left: -10,
            },
            renderContent: (attributes, props) => <UserDropdown {...attributes} />,
          },
        },
      ],
    });
  } else {
    sections.push({
      tabs: [
        {
          name: 'More',
          icon: faQuestion,
          popup: {
            width: 200,
            posX: 'right',
            posY: 'center',
            offset: {
              left: -10,
            },
            renderContent: (attributes, props) => <UserDropdown {...attributes} />,
          },
        },
        {
          name: 'Login',
          icon: faPowerOff,
          href: {
            pathname: '/login',
          },
        },
      ],
    });
  }

  return <PageGutter sections={sections} />;
};

export default inject('routerStore', 'userService', 'projectService', 'projectStore')(
  observer(ProjectGutter)
);
