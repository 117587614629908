import compact = require('lodash/compact');
import get = require('lodash/get');
import isEmpty = require('lodash/isEmpty');
import noop = require('lodash/noop');
import parseInt = require('lodash/parseInt');

import { observer } from 'mobx-react';
import * as React from 'react';
import TimeAgo from 'react-timeago';

import { Box, Flex, IBox } from '@stoplight/ui-kit';

import MarkdownViewer from '@platform/components/MarkdownViewer';
import { Link } from '@platform/utils/router';

import { useTheme } from '../config/theme';
import { IChangelogNode, IStore } from '../types';
import { nodeData } from '../utils';

import { Loader } from './Loader';
import { Pagination } from './Pagination';

/**
 * CHANGELOG
 */
export interface IChangelog extends IBox {
  store?: IStore;
}

export const Changelog: React.FunctionComponent<IChangelog> = observer(
  ({ store = {}, ...boxProps }) => {
    const {
      updateSearchQuery = noop,
      isSearching,
      changelogs,
      query = {},
      pageInfo = {} as any,
    } = store as IStore;
    const { page = '' } = query;
    const { totalPages = 1 } = pageInfo;

    return (
      <Box maxWidth={1000} height="100%" {...boxProps}>
        {isSearching && <Loader text="Loading..." />}

        {isEmpty(changelogs) && !isSearching && <Box textAlign="center">No activity detected.</Box>}

        {!isEmpty(changelogs) &&
          !isSearching &&
          changelogs.map((log: IChangelogNode, index: number) => <LogItem key={index} {...log} />)}

        {totalPages > 1 &&
          !isSearching && (
            <Box mt="20px" textAlign="center">
              <Pagination
                currentPage={parseInt(page) || 1}
                totalPages={totalPages}
                onClick={page => updateSearchQuery(page, 'page')}
              />
            </Box>
          )}
      </Box>
    );
  }
);

/**
 * LOG ITEM
 */
const LogItem: React.FunctionComponent<IChangelogNode> = log => {
  const { message, createdAt, node } = log;
  const { org, project, file } = node;
  const { editorUrl } = nodeData(node);
  const meta = compact([org.name, project.name, file, get(node.meta, 'path')]);

  const { card, fontSize, fontWeight } = useTheme();

  return (
    <Flex
      as={Link}
      to={{ pathname: editorUrl }}
      p="20px 15px"
      width="100%"
      flexDirection="column"
      justifyContent="space-between"
      overflow="hidden"
      boxSizing="border-box"
      backgroundColor={card.bg}
      borderBottom={`1px solid ${card.border}`}
      cursor="pointer"
      css={{
        ':hover': {
          backgroundColor: 'rgb(247, 247, 247)',
        },
      }}
    >
      <Flex alignItems="center">
        <Box fontSize={fontSize.sm} color={card.subtitle} mr="10px">
          <TimeAgo date={createdAt} minPeriod={60} />
        </Box>
      </Flex>

      <Box color={card.title} fontSize={fontSize.base} fontWeight={fontWeight.semibold} mr="10px">
        <MarkdownViewer value={message} style={{ fontSize: fontSize.base }} />
      </Box>

      <Flex fontSize={fontSize.sm} color={card.subtitle}>
        <Box flex={1}>{meta.join(' · ')}</Box>
      </Flex>
    </Flex>
  );
};
