import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Image, Loader } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { upload } from '@platform/utils/aws';
import FormCheckbox from '@platform/components/FormCheckbox';
import FormInput from '@platform/components/FormInput';
import ErrorMessage from '@platform/components/ErrorMessage';

const headerProps = { title: true, subtitle: true, anchor: true };

class ImageBlock extends React.Component {
  handleImageChange = files => {
    let file = _.first(files);
    if (!file) return;

    const { onChangeData, sessionCookie, updateCache } = this.props;

    const name = new Date().getTime();

    updateCache('set', 'isLoading', true);

    upload({ name, file, sessionCookie })
      .then(res => {
        onChangeData('set', [], res);
        updateCache('set', 'isLoading', null);
        this.clearCacheError();
      })
      .catch(err => {
        updateCache('set', 'isLoading', null);
        updateCache('set', 'error', err);
      });
  };

  clearCacheError = () => {
    this.props.updateCache('set', 'error', null);
  };

  buildHeaderActions = () => {
    const { config = {}, onChangeConfig } = this.props;

    const actions = [];

    actions.push(
      <FormCheckbox
        name="centered"
        label="Centered"
        checked={config.centered}
        onChange={checked => {
          if (checked) {
            onChangeConfig('set', ['centered'], checked);
          } else {
            onChangeConfig('unset', ['centered']);
          }
        }}
        inline
      />
    );

    actions.push(
      <FormCheckbox
        name="fluid"
        label="Fluid"
        checked={config.fluid}
        onChange={checked => {
          if (checked) {
            onChangeConfig('set', ['fluid'], checked);
          } else {
            onChangeConfig('unset', ['fluid']);
          }
        }}
        inline
      />
    );

    actions.push(
      <FormCheckbox
        name="bordered"
        label="Bordered"
        checked={config.bordered}
        onChange={checked => {
          if (checked) {
            onChangeConfig('set', ['bordered'], checked);
          } else {
            onChangeConfig('unset', ['bordered']);
          }
        }}
        inline
      />
    );

    actions.push(
      <FormInput
        name="w"
        tip="Max Width"
        size="tiny"
        input={{
          value: config.maxWidth,
          placeholder: 'max width',
          size: 'mini',
          onChange(e) {
            const val = e.target.value;
            if (val) {
              onChangeConfig('set', ['maxWidth'], val);
            } else {
              onChangeConfig('unset', ['maxWidth']);
            }
          },
        }}
      />
    );

    actions.push(
      <FormInput
        name="h"
        tip="Max Height"
        input={{
          value: config.maxHeight,
          placeholder: 'max height',
          size: 'mini',
          onChange(e) {
            const val = e.target.value;
            if (val) {
              onChangeConfig('set', ['maxHeight'], val);
            } else {
              onChangeConfig('unset', ['maxHeight']);
            }
          },
        }}
      />
    );

    return actions;
  };

  render = () => {
    const { WrapperFactory, data = '', cache = {} } = this.props;

    let innerElem;

    if (cache.isLoading) {
      innerElem = <Loader inline="centered" style={{ marginTop: 50, width: '100%' }} active />;
    } else if (cache.error) {
      innerElem = <ErrorMessage error={cache.error} onDismiss={this.clearCacheError} />;
    } else if (!_.isEmpty(data)) {
      innerElem = <Image src={data} centered className="mb-3" />;
    }

    const contentElem = (
      <div className="ImageBlock p-3 text-center">
        <Dropzone
          accept="image/*"
          className="cur-p"
          onDrop={this.handleImageChange}
          multiple={false}
        >
          <div className="font-bold c-muted p-3">
            Drag and drop an image here, or click to select an image to upload.
          </div>

          {innerElem}
        </Dropzone>
      </div>
    );

    return (
      <WrapperFactory
        {...this.props}
        headerProps={headerProps}
        headerActions={this.buildHeaderActions()}
        contentElem={contentElem}
      />
    );
  };
}

export default inject(({ userService }) => {
  return {
    sessionCookie: userService.sessionCookie,
  };
})(observer(ImageBlock));
