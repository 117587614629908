import _ from 'lodash';
import { action, computed, observable, reaction } from 'mobx';

import { hashToPath } from '@platform/utils/history';

import { derefNav, searchResults, buildCrumbs } from '@platform/format-hubs/utils';
import {
  childToRoute,
  sidebarUtils,
  findPageByPath,
  findSubpageByPath,
  replaceBlockVariables,
} from '@platform/format-hubs/utils/viewer';

export default class HubViewer {
  entityStore = null;
  // This path will be used by goToEditor when a path isn't provided
  defaultEditPath = '/pages/~1';

  @observable
  basePath = '';

  @observable.ref
  _currentEnv = {};

  constructor(entityStore) {
    this.entityStore = entityStore;

    this.setupReactions();
  }

  setupReactions() {
    reaction(
      () => this.entityStore && this.entityStore.currentEnv,
      action(currentEnv => {
        this._currentEnv = currentEnv || {};
      }),
      {
        name: 'debouncedCurrentEnv',
        delay: 1000,
        fireImmediately: true,
      }
    );
  }

  @computed
  get isLoading() {
    return _.isEmpty(this.parsed) && this.entityStore.isBuildingHub;
  }

  @computed
  get parsed() {
    return (this.entityStore && this.entityStore.hubViewerSpec) || {};
  }

  @computed
  get currentEnv() {
    return this._currentEnv;
  }

  @computed
  get currentPath() {
    return (this.entityStore && hashToPath({ hash: this.entityStore.currentViewPath })) || [];
  }

  @computed
  get rootStore() {
    return (this.entityStore && this.entityStore.rootStore) || {};
  }

  @computed
  get namespace() {
    return this.rootStore.stores.namespaceService.current;
  }

  @computed
  get config() {
    return _.get(this.parsed, 'config', {});
  }

  @computed
  get nav() {
    return derefNav(this.parsed, { autoNav: true });
  }

  @computed
  get hasHeader() {
    return !(
      _.isEmpty(this.nav.left) &&
      _.isEmpty(this.nav.right) &&
      !this.parsed.title &&
      !this.parsed.logo
    );
  }

  @computed
  get theme() {
    return _.get(this.parsed, 'theme', {});
  }

  @computed
  get activePagePath() {
    return _.get(this.activePage.data, 'path', '/');
  }

  @computed
  get hasSidebar() {
    return _.get(this.activePage.data, 'data.children', []).length > 0;
  }

  @computed
  get sidebarTree() {
    if (!this.hasSidebar) return;

    const { data = {}, path } = this.activePage.data || {};

    // Hub Viewer Sidebar Props
    const sidebarProps = {
      children: data.children,
      buildPath: this.buildViewPath,
      basePath: _.trimEnd(`${this.basePath}${path}`, '/'),
      activePath: this.entityStore.currentViewPath,
    };

    let tree;
    try {
      tree = sidebarUtils.computeTree(undefined, sidebarProps);
    } catch (e) {
      console.error('error computing hub sidebar tree', e);
    }
    return tree;
  }

  buildViewPath = (...args) => {
    return this.entityStore ? this.entityStore.buildViewPath(...args) : '';
  };

  buildEditPath = (...args) => {
    return this.entityStore ? this.entityStore.buildEditPath(...args) : '';
  };

  checkSidebarItemIsActive = item => {
    if (!this.rootStore.isClient || !item) return;

    return this.entityStore.currentViewPath === item.path;
  };

  checkSidebarChildIsActive = folder => {
    if (!this.rootStore.isClient || !folder) return;

    // Check to see if our current path starts with the folder's path
    let reg = new RegExp(`^${folder.path}`);

    return reg.test(this.entityStore.currentViewPath);
  };

  redirect = page => {
    const route = childToRoute({
      jsonPath: page,
      spec: this.parsed,
    });

    this.rootStore.stores.routerStore.push(this.buildViewPath(route));
  };

  getResults = searchQuery => {
    return searchResults({
      data: this.parsed,
      expression: searchQuery,
    });
  };

  @computed
  get crumbs() {
    return buildCrumbs({
      parsedPath: this.currentParsedPath,
      hub: this.parsed,
      buildPath: jsonPath => {
        return this.buildViewPath(
          childToRoute({
            jsonPath,
            spec: this.parsed,
          })
        );
      },
    });
  }

  @computed
  get currentParsedPath() {
    return _.compact(_.concat(this.activePage.parsedPath, this.activeSubpage.parsedPath));
  }

  @computed
  get pages() {
    return _.get(this.parsed, 'pages', {});
  }

  // sorts by path size, descending - useful for routing
  // adds the original key as a property on the page itself, since this returns an array instead of an object
  sortPages = pages => {
    const ordered = _.orderBy(_.keys(pages), [path => _.size(path)], ['desc']);

    return _.reduce(
      ordered,
      (acc, key) => {
        acc[key] = pages[key];
        return acc;
      },
      {}
    );
  };

  @computed
  get sortedPages() {
    const pages = this.pages;
    return this.sortPages(pages);
  }

  goToNestedPath = path => {
    if (path !== this.rootStore.stores.routerStore.location.pathname) {
      this.rootStore.stores.routerStore.replace(this.buildViewPath(path));
    }
  };

  // computes the path to the page in parsed + gets the page data
  @computed
  get activePage() {
    return findPageByPath({
      pages: this.sortedPages,
      path: this.entityStore.currentViewPath,
    });
  }

  // computes the path to the subpage in parsed (relative to parent page) + gets the subpage data
  @computed
  get activeSubpage() {
    const activeSubpage = {
      data: undefined,
      treePath: [],
      parsedPath: [],
    };

    const { data = {} } = this.activePage || {};
    if (_.isEmpty(data)) {
      return activeSubpage;
    }

    const path = _.trim(_.replace(this.entityStore.currentViewPath, data.path, ''), '/');

    return findSubpageByPath({
      subpages: _.get(data.data, 'children'),
      pathParts: path ? path.split('/') : [],
    });
  }

  @computed
  get activeBlocks() {
    const page = this.activeSubpage.data || this.activePage.data || {};
    const { blocks } = page.data ? _.cloneDeep(page.data) : {};

    return _.map(blocks, block => replaceBlockVariables({ block, env: this.currentEnv }));
  }

  /*
    current location path - file basePath
    useful for things like creating appropriate links in markdown
  */
  @computed
  get currentRelativePath() {
    return _.replace(
      _.get(this.rootStore, 'stores.routerStore.location.pathname'),
      this.basePath || '',
      ''
    );
  }
}
