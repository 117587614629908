import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';

const EntityWidgetGroup = ({ name, required, tip, children, action }) => {
  return (
    <div className="EntityEditor-widgetGroupWrapper">
      <div className="EntityEditor-widgetGroup">
        <div className="EntityEditor-widgetGroupName">
          {action}

          {required ? '*' : ''}
          {name}

          {tip ? (
            <span className="EntityEditor-widgetGroupTip">
              <Popup
                trigger={<Icon name="question circle" />}
                content={tip}
                size="small"
                wide="very"
              />
            </span>
          ) : null}
        </div>

        <div className="EntityEditor-widgetGroupContent">{children}</div>
      </div>
    </div>
  );
};

export default EntityWidgetGroup;
