import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { BaseStore } from '../_base';
import { BaseService } from './_base';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/projects/:projectId/teams',
      perPage: 100,
    })
    .views(self => {
      return {
        get currentList() {
          const currentProject = _.get(env.rootStore, 'stores.projectService.current');
          if (!currentProject) return;

          return self
            .findLocal({
              query: {
                project_id: String(currentProject.id),
              },
            })
            .get();
        },
      };
    })
    .actions(self => {
      return {};
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('ProjectTeamService');

  return Service.create(data, env);
};
