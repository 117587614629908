import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';

import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';

import { alert } from '@platform/utils/alert';
import FormInput from '@platform/components/FormInput';
import FeatureBadge from '@platform/components/FeatureBadge';
import FormCheckbox from '@platform/components/FormCheckbox';
import SidebarPopout from '@platform/components/SidebarPopout';

import { getConfigVar } from '@platform/utils/config';
import { hasSubscriptionFeature } from '@platform/utils/billing';

import { sizes, colors, Button, Icon } from '@core/ui';

import GraphQL from '@platform/containers/GraphQL';

class PublishDomain extends React.Component {
  cleanDomain = domain => {
    const { ui, updateUi } = this.props;

    // Don't need to clean an empty domain
    if (_.isEmpty(domain)) return '';

    // Remove trailing slashes, protocol, and whitespace
    // Force lowercase
    let cleanDomain = _.last(_.split(_.trim(_.toLower(_.trimEnd(domain, '/'))), '://'));

    const isSubdomain = this.isSubdomain(cleanDomain) || !ui.customDomain;

    if (isSubdomain) {
      // Remove our domain
      cleanDomain = _.replace(
        cleanDomain,
        new RegExp(`.${getConfigVar('SL_PUBS_HOST')}$`, 'i'),
        ''
      );

      // Remove multi-level subdomains
      cleanDomain = _.replace(cleanDomain, /\./g, '-');

      // Switch to the subdomain UI
      if (ui.customDomain) {
        updateUi('unset', 'customDomain');
      }
    } else {
      // Remove base path
      cleanDomain = _.replace(cleanDomain, /\/.+$/, '');
    }

    // Convert anything thats NOT a-z, A-Z, 0-9, -, and . to a hyphen
    cleanDomain = _.replace(cleanDomain, /([^\w-.]|_)+/g, '-');

    // Trim . and - from the beginning and end of the domain
    cleanDomain = _.trim(cleanDomain, /\.|-/);

    return cleanDomain;
  };

  checkAndUpdateDomain = async () => {
    const { ui = {}, updateUi, createHandler, routerStore, canPublish, projectId } = this.props;

    if (!canPublish) return;

    alert.closeAll();

    let domain = this.cleanDomain(ui.domain);
    if (_.isEmpty(domain)) {
      alert.error('Please choose a valid domain.');
      return;
    }

    updateUi('set', 'isCheckingDomain', true);

    if (ui.customDomain) {
      const c = window.confirm(
        `Publishing to a custom domain requires a DNS CNAME record.\n\nPlease create a CNAME pointing from "${
          ui.domain
        }" to "${getConfigVar('SL_PUBS_INGRESS')}" before your first publish.\n\n`
      );
      if (!c) {
        updateUi('unset', 'isCheckingDomain');
        return;
      }
    } else {
      domain = `${domain}.${getConfigVar('SL_PUBS_HOST')}`;
    }

    if (domain.length > 64) {
      alert.error('Domain must not be longer than 64 characters.');
      return;
    }

    try {
      await createHandler.run({
        variables: {
          input: {
            projectId,
            domain,
            config: {
              filePath: '',
            },
          },
        },
      });

      updateUi('unset', 'isCheckingDomain');
      createHandler.clearError();

      routerStore.setQueryParams({ domain });
    } catch (err) {
      updateUi('unset', 'isCheckingDomain');
    }
  };

  isSubdomain = domain => {
    const isSubdomain = new RegExp(`.${getConfigVar('SL_PUBS_HOST')}$`);

    return isSubdomain.test(domain);
  };

  render() {
    const { ui = {}, updateUi, routerStore, canPublish, namespace, createHandler } = this.props;

    if (_.get(routerStore, 'location.query.domain')) return null;

    const isDisabled =
      !canPublish || ui.isCheckingDomain || createHandler.error || _.isEmpty(_.trim(ui.domain));

    const customDomainEnabled = hasSubscriptionFeature(namespace, 'custom_domain');

    return (
      <SidebarPopout
        className="flex items-center justify-center"
        inverted
        handleClose={() => routerStore.setQueryParams({ show: null })}
      >
        <div className="flex flex-col w-3/4 items-center justify-center text-center">
          <div className="rounded-full h-24 w-24 flex items-center justify-center bg-white opacity-50 m-8 text-5xl">
            <Icon icon={faBook} />
          </div>

          <div className="text-white opacity-85 text-4xl font-extrabold tracking-wide">
            Publish Beautiful Docs
          </div>

          <div className="text-white opacity-50 text-lg m-4 tracking-wide">
            Turn any Hub file into static documentation, hosted at the domain of your choice.
          </div>

          <div className="flex align-center justify-between w-full mt-14 mb-3 tracking-wide">
            <div className="text-white opacity-75 text-lg font-bold">First, Choose a Domain</div>
            <div className="text-white opacity-75 flex items-center">
              <FormCheckbox
                label="Custom?"
                labelClassname="text-lg"
                checked={ui.customDomain}
                onChange={checked => {
                  updateUi('set', 'customDomain', checked);
                }}
                disabled={!customDomainEnabled}
                inline
                inverted
              />
              <FeatureBadge
                className="pl-4"
                feature="custom_domain"
                show={!customDomainEnabled}
                namespace={namespace}
                inverted
              />
            </div>
          </div>

          <div className="w-full mb-10">
            <FormInput
              placeholder={ui.customDomain ? 'your-domain.com' : 'your-subdomain-here'}
              className="w-full"
              prefix={ui.customDomain && 'https://'}
              suffix={!ui.customDomain && `.${getConfigVar('SL_PUBS_HOST')}`}
              disabled={ui.isCheckingDomain}
              loading={ui.isCheckingDomain}
              value={ui.domain}
              onChange={(e, { value }) => {
                if (ui.isCheckingDomain) return;

                updateUi('set', 'domain', value);

                if (createHandler.error) {
                  createHandler.clearError();
                }
              }}
              onBlur={e => {
                // Use the cleaned domain
                updateUi('set', 'domain', this.cleanDomain(e.target.value));
              }}
              transparent
              fluid
            />

            {createHandler.error && (
              <div className="text-center text-red mt-1">{createHandler.error}</div>
            )}
            {!canPublish && (
              <div className="text-center text-red mt-1">
                Domain creation requires Admin access to this project.
              </div>
            )}
          </div>

          <Button
            className="mb-32"
            color={isDisabled ? colors.grey : colors.accent}
            size={sizes.lg}
            disabled={isDisabled}
            loading={ui.isCheckingDomain}
            icon={faArrowRight}
            iconPos={2}
            onClick={() => {
              this.checkAndUpdateDomain();
            }}
          >
            {ui.isCheckingDomain ? 'Checking domain...' : 'Next'}
          </Button>
        </div>
      </SidebarPopout>
    );
  }
}

const InjectedPublishDomain = inject((stores, props) => {
  const { appStore, routerStore } = stores;

  return {
    ...appStore.injectUi('PublishDomain'),
    routerStore,
  };
})(observer(PublishDomain));

export default props => {
  return (
    <GraphQL
      id="PublishDomain"
      service="docsService"
      methods={{
        createHandler: {},
      }}
      renderProp={({ createHandler }) => {
        return <InjectedPublishDomain {...props} createHandler={createHandler} />;
      }}
    />
  );
};
