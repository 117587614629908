import TimeAgo from 'javascript-time-ago';

// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';

// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en);

// Create relative date/time formatter.
const timeInstance = new TimeAgo('en-US');

export const timeAgo = (time, { short } = {}) => {
  return timeInstance.format(new Date(time), { flavour: short ? 'tiny' : 'long' });
};
