import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';

import JsonPathCacheParentContainer from '@platform/components/JsonPathCacheParentContainer';

import { registerLogger } from '@platform/utils/logging';

import Block from '../Block/editor';
import BlockListInsert from '../BlockListInsert';

const log = registerLogger('components', 'HubBlockList');

class HubBlockListEditor extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    blocks: PropTypes.array,
    blockDereferenced: PropTypes.array,
    parentPath: PropTypes.array, // json path to parent in tree
    basePath: PropTypes.string, // base url path
    relativePath: PropTypes.string, // relative url path (everything between basePath and the page we are on)
    cache: PropTypes.object,
    updateCache: PropTypes.func,
    blockProps: PropTypes.object, // props to pass onto underlying blocks
    invalidBlockTypes: PropTypes.array,
  };

  blockPath = index => {
    return this.props.parentPath.concat(['blocks', index]);
  };

  insertBlock = ({ blockIndex, type, data, header, config } = {}) => {
    const { store, parentPath = [] } = this.props;

    store.updateParsed(
      'splice',
      parentPath.concat(['blocks']),
      { type, data, header, config },
      {
        splice: {
          index: blockIndex,
        },
      }
    );
  };

  renderBlock = ({ id, block, index }) => {
    const {
      store,
      blocks = [],
      blocksDereferenced = [],
      basePath,
      relativePath,
      blockProps = {},
    } = this.props;

    const blockLength = _.size(blocks);
    const isLast = parseInt(index) >= blockLength - 1;

    return (
      <Block
        {...blockProps}
        key={id}
        id={id}
        editor={store} // DEPRECATED
        store={store}
        block={block}
        blockDereferenced={blocksDereferenced[index]}
        blockIndex={index}
        blockPath={this.blockPath(index)}
        isLast={isLast}
        isSolo={blockLength <= 1}
        basePath={basePath}
        relativePath={relativePath}
      />
    );
  };

  renderBlockInsert = ({ id, index, isLast, isStandalone }) => {
    const { store, cache, updateCache, invalidBlockTypes, isNested } = this.props;

    return (
      <BlockListInsert
        key={`insert-${id}`}
        id={id}
        handleInsert={this.insertBlock}
        blockIndex={index}
        blockPath={this.blockPath(index)}
        cache={cache}
        updateCache={updateCache}
        store={store}
        isLast={isLast}
        isStandalone={isStandalone}
        isNested={isNested}
        blockTypeBlacklist={invalidBlockTypes}
      />
    );
  };

  render() {
    const { className, blocks = [], childCacheIds = [], readOnly } = this.props;

    log.debug('Render', { blocks });

    let contentElems = [];

    _.forEach(blocks, (block, index) => {
      const id = childCacheIds[index];

      if (!readOnly) {
        contentElems.push(this.renderBlockInsert({ id, block, index }));
      }

      contentElems.push(this.renderBlock({ id, block, index }));
    });

    contentElems.push(
      this.renderBlockInsert({
        id: 'last',
        index: blocks.length,
        isLast: true,
        isStandalone: _.isEmpty(blocks),
      })
    );

    return <div className={cn('HubBlockList', className)}>{contentElems}</div>;
  }
}

export default JsonPathCacheParentContainer({
  cacheKeyProp: 'store.id',
  basePathProp: 'parentPath',
  path: ['blocks'],
  childrenProp: 'blocks',
})(HubBlockListEditor);
