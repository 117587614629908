import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Segment } from 'semantic-ui-react';

import Accordion from '@platform/components/Accordion';

import InstanceRule from './rule';

class InstanceRules extends React.Component {
  render() {
    const { editor = {} } = this.props;

    const rules = _.get(editor, 'currentConfigBlocks.rules', []);

    const panels = _.map(rules, (rule, index) => {
      const ruleId = rule.id;

      return {
        id: ruleId,
        title: ruleId,
        defaultOpen: index === 0,
        content: (
          <InstanceRule
            id={`${editor.id}:${ruleId}`}
            editorId={editor.id}
            value={rule}
            parsed={editor.parsed}
            embedded
            onChange={(t, p, v) => {
              editor.updateParsed(
                t,
                ['rules', _.findIndex(editor.parsed.rules, { id: ruleId })].concat(p),
                v
              );
            }}
          />
        ),
      };
    });

    let contentElem;
    if (_.isEmpty(panels)) {
      contentElem = <Segment secondary>No rule blocks match this request.</Segment>;
    } else {
      contentElem = (
        <Accordion id={`${editor.id}:rules`} panels={panels} exclusive={false} styled fluid />
      );
    }

    return <div className="InstanceRules">{contentElem}</div>;
  }
}

export default inject(({ instanceEditorStore }, { editorId }) => {
  return {
    editor: instanceEditorStore.getEditor({ id: editorId }),
  };
})(observer(InstanceRules));
