import React from 'react';

import EntityEditor from '../EntityEditor';
import EntityEditorCode from '../EntityEditor/spec';

const contentTabs = [
  {
    id: 'code',
    htmlId: 'tutorial-entity-code',
    name: 'Code',
    icon: 'code',
    className: 'is-light',
    component: EntityEditorCode,
  },
];

const RawEditor = props => {
  return (
    <EntityEditor
      editorStoreKey="rawEditorStore"
      contentTabs={contentTabs}
      contentRouterData={{}}
      {...props}
      disableLocal
    />
  );
};

export default RawEditor;
