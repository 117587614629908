import { types, flow } from 'mobx-state-tree';
import _ from 'lodash';
import { toJS } from 'mobx';

import { BaseStore } from '../_base';

import { BaseService } from './_base';

import hub from '@platform/utils/commit/templates/hub';
import oas2 from '@platform/utils/commit/templates/oas2';
import oas3 from '@platform/utils/commit/templates/oas3';
import prism from '@platform/utils/commit/templates/prism';
import config from '@platform/utils/commit/templates/config';
import commonOas2 from '@platform/utils/commit/templates/commonOas';
import scenarios from '@platform/utils/commit/templates/scenarios';
import { generateInitialCommitActions } from '@platform/utils/commit';

export const create = ({ data, env, options = {} }) => {
  const Base = types
    .model({
      basePath: '/:namespaceType/:namespaceId/projects',
      singleBasePath: '/projects',
    })
    .views(self => {
      return {
        // returns the project for the current browser location, from local records
        // we need to check both the project and namespace identifiers
        get current() {
          const params = _.get(env.rootStore, 'stores.routerStore.location.params', {});
          const { projectId, namespaceId } = params;

          if (!projectId || !namespaceId) return;

          return _.first(
            self
              .findLocal({
                query: { path_with_namespace: `${namespaceId}/${projectId}` },
                limit: 1,
              })
              .get()
          );
        },

        /**
         * returns the projects for the current browser location, from local records
         *
         * Supports filters: tags
         */
        get currentList() {
          const params = _.get(env.rootStore, 'stores.routerStore.location.params', {});
          const authorizedUser = _.get(env.rootStore, 'stores.userService.authorizedUser', {});
          const namespaceId = params.namespaceId || params.userId || authorizedUser.username;

          if (!namespaceId) {
            return;
          }

          const q = {
            query: {
              $or: [
                // check just the first part of the path_with_namespace prop
                // some endpoints return namespace.id/path, which we could use to get rid of regex, but not all endpoints!
                // all endpoints return path_with_namespace
                { path_with_namespace: new RegExp(`${namespaceId}/`) },
              ],
            },
          };

          const tags = _.get(env.rootStore, 'stores.routerStore.location.query.tags', []);
          if (!_.isEmpty(tags)) {
            q.query.tag_list = { $all: toJS(tags) };
          }

          return self.findLocal(q).get();
        },
      };
    })
    .actions(self => {
      const originalGet = self.get;
      const originalCreate = self.create;
      const originalRemove = self.remove;

      return {
        /**
         * Overload the BaseService get method, because we need to lookup directly on /projects/{namespace+project}
         * This is because we don't always know the namespaceType (org or user) before looking up the project
         */
        get: flow(function*(id) {
          let data;

          try {
            data = yield originalGet(id, {}, { basePath: '/projects' });
          } catch (error) {
            self.error = _.get(error, 'response.data', String(error));
            throw error;
          }

          return data;
        }),

        /**
         * Overload the BaseService create method, because we need to do a lot more than just create a project here.
         *
         * 1. Create project.
         * 2. Create initial commit. TODO: create with generated data based on template options indicated by user, instead of hardcode two files.
         * 3. Unprotect the default protection on the master branch. NOTE: this protection is only added AFTER the first commit (step 2).
         *
         * IF error at any point, attempt to roll back by removing the project.
         */
        create: flow(function*(data, params, options) {
          // TODO
          // const { templateOptions = {} } = options;

          const newProject = yield originalCreate(data, params, options);

          // override... we are still creating
          self.isCreating = true;

          try {
            yield env.rootStore.stores.commitService.create(
              {
                branch: 'version/1.0',
                commit_message: 'Initial commit.',
                actions: generateInitialCommitActions({
                  commonOas2,
                  oas2,
                  oas3,
                  scenarios,
                  hub,
                  prism,
                  config,
                }),
              },
              { project_id: newProject.id }
            );
          } catch (e) {
            if (newProject) {
              try {
                yield self.remove(newProject.id);
              } catch (e) {
                console.error('remove:error', e);
              }
            }

            self.error = _.get(e, 'response.data', String(e));
            self.isCreating = false;

            throw e;
          }

          self.isCreating = false;

          return newProject;
        }),

        /**
         * Overload the remove function
         * on project delete we need to check for existing domains and remove on approval
         */
        remove: (id, params, opts = {}) => {
          const r = window.confirm(
            `Are you sure you want to delete this project?\n\nThis action cannot be undone and will permanently delete all associated files and published documentation.`
          );
          if (!r) return;

          return originalRemove(id, params, opts);
        },
      };
    });

  const Service = types
    .compose(
      BaseStore,
      BaseService,
      Base
    )
    .named('ProjectService');

  return Service.create(data, env);
};
