import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus';
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync';

import FormInput from '@platform/components/FormInput';
import FormDropdown from '@platform/components/FormDropdown';
import LandingPageWidget from '@platform/components/LandingPageWidget';

import { alert } from '@platform/utils/alert';
import { encodeFileId } from '@platform/utils/instances';
import {
  getDefaultFileContents,
  filterMap,
  findFileType,
  findFileSubType,
} from '@platform/utils/projects';

import { colors, Button } from '@core/ui';

const FileCreateWidget = ({
  defaultFileType = 'oas',
  creating,
  ui,
  updateUi,
  clearUi,
  projectStore,
  onCreate,
  onError,
  userService,
}) => {
  const { name, type, subType } = ui;

  const fileType = type || defaultFileType;

  let supportedFile = _.find(filterMap, { type: fileType }) || {};
  const supportedSubType =
    _.find(supportedFile.subTypes, { type: subType }) || _.get(supportedFile, ['subTypes', 0], {});

  // merge over supportedFile for extension/names
  supportedFile = Object.assign({}, supportedFile, supportedSubType);

  const extension = supportedFile.extension;

  const fileTypes = [];
  _.forOwn(filterMap, (value, key) => {
    if (!value.noAdd) {
      fileTypes.push({
        key,
        text: value.name || _.capitalize(key),
        value: value.type,
        icon: value.iconName,
      });
    }
  });

  const create = ({ isImporting } = {}) => {
    if (creating) return;

    // force naming of files even if not required in api
    if (_.isEmpty(name)) {
      alert.error('Name is required, please add one.');
      return;
    }

    let filePath = _.trim(name);
    if (extension) {
      filePath += `.${extension}`;
    }

    projectStore.current
      .createFile({
        file_path: filePath,
        commit_message: `add ${filePath}`,
        content: isImporting
          ? ui.fileData.result
          : getDefaultFileContents({
              name,
              type: supportedFile.type,
              path: filePath,
            }),
      })
      .then(() => {
        if (onCreate) {
          onCreate();
        }

        if (isImporting) {
          userService.updateAnalytics(
            {
              [`imported_${supportedFile.type}`]: true,
              imported_file: true,
            },
            { targets: ['intercom'] }
          );
        }

        projectStore.current.goToFile(filePath, {
          query: {
            edit: supportedFile.type === 'hub' ? '/pages/~1' : '/',
          },
        });

        clearUi();

        alert.success('File created!');
      })
      .catch(err => {
        alert.error(_.get(err, 'message', String(err)));

        if (onError) {
          onError();
        }
      });
  };

  let nameError;
  if (ui.type === 'prism') {
    const encodedFileName = encodeFileId(`${name}.prism.yml`);

    if (encodedFileName.length > 63) {
      nameError = `The '${name}' file name is too long, please give it a shorter name.`;
    }
  }

  const fileFields = (
    <div className="flex mt-2">
      <FormDropdown
        label="TYPE"
        value={fileType}
        placeholder="Please choose one..."
        options={fileTypes}
        onChange={(e, { value }) => {
          updateUi('set', 'type', value);
          updateUi('unset', 'subType');
        }}
        selection
        fluid
      />

      {supportedFile.subTypes && (
        <FormDropdown
          className="ml-6"
          label="SPECIFICATION"
          value={subType || supportedSubType.type}
          placeholder="Please choose one..."
          options={supportedFile.subTypes.map(sub => ({ text: sub.name, value: sub.type }))}
          onChange={(e, { value }) => {
            updateUi('set', 'subType', value);
          }}
          selection
          fluid
        />
      )}

      <div className="flex-1 ml-6">
        <FormInput
          error={nameError}
          label={supportedFile.label || 'NAME'}
          placeholder={supportedFile.placeholder || 'Something short and memorable...'}
          value={name}
          onChange={(e, { value }) => {
            updateUi('set', 'name', value);
          }}
          fluid
        />

        {nameError ? <div className="c-error mt-2">{nameError}</div> : null}
      </div>
    </div>
  );

  const panes = [
    {
      title: 'From Scratch',
      content: (
        <div>
          {fileFields}

          <div className="flex mt-6">
            <Button
              icon={faPlus}
              onClick={() => create()}
              loading={creating}
              disabled={creating || nameError}
              color={colors.accent}
            >
              Add {_.trim(ui.name)}
              {extension && `.${extension}`}
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: 'Import Existing',
      content: (
        <div>
          {ui.fileData && fileFields}

          <div
            className={cn('flex', {
              'mt-6': ui.fileData,
            })}
          >
            {ui.fileData && (
              <Button
                icon={faPlus}
                onClick={() => create({ isImporting: true })}
                loading={creating}
                disabled={creating}
                color={colors.accent}
              >
                Add {_.trim(ui.name)}
                {extension && `.${extension}`}
              </Button>
            )}

            <Button
              className={cn('relative', { 'ml-4': ui.fileData })}
              icon={ui.fileData ? faSync : faUpload}
              loading={creating}
              disabled={creating}
              color={ui.fileData ? colors.grey : colors.accent}
              transparent={ui.fileData ? true : false}
            >
              {ui.fileData ? 'Change File' : 'Upload OpenAPI (Swagger), Markdown or HTML File'}

              <input
                type="file"
                className="absolute pin z-10 w-100 opacity-0 cursor-pointer"
                onChange={e => {
                  e.preventDefault();

                  const file = _.get(e, 'target.files[0]');
                  updateUi('set', 'name', _.split(file.name, '.')[0]);
                  updateUi('set', 'fileData', file);

                  const reader = new FileReader();
                  reader.onload = readEvent => {
                    let contents = readEvent.target.result;

                    const fileType = findFileType({
                      filePath: file.name,
                      contentType: file.type,
                      contents,
                    });

                    if (fileType && filterMap[fileType]) {
                      updateUi('set', 'type', filterMap[fileType].type);

                      if (filterMap[fileType].subTypes) {
                        const subType = findFileSubType({
                          filePath: file.name,
                          contentType: file.type,
                          contents,
                        });

                        updateUi('set', 'subType', subType || filterMap[fileType].subTypes[0].type);
                      }
                    }

                    updateUi('set', 'fileData.result', contents);
                  };

                  reader.readAsText(file);
                }}
                onClick={event => {
                  event.target.value = null;
                }}
              />
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <LandingPageWidget
      id="new-file"
      title="NEW FILE"
      icon={faFilePlus}
      error={projectStore.fileService.error}
      onDismissError={projectStore.fileService.clearError}
      panes={panes}
    />
  );
};

export default inject(({ appStore, routerStore, projectStore, userService }) => {
  const defaultFileType = _.get(routerStore.location, 'query.type');
  const creating = _.get(projectStore.current, 'isCreatingFile');

  return {
    ...appStore.injectUi(`ProjectCreate`),
    projectStore,
    defaultFileType,
    creating,
    userService,
  };
})(observer(FileCreateWidget));
