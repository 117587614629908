import React from 'react';
import cn from 'classnames';
import { Popup } from 'semantic-ui-react';
import { ChromePicker, CompactPicker } from 'react-color';

import FormInputLabel from '../FormInputLabel';

const ColorPicker = props => {
  const {
    label,
    labelPosition = 'top',
    tip,
    required,
    color = '#fff',
    onChangeComplete,
    className,
    width = 20,
    height = 34,
    bordered,
    inline,
    inverted,
    triggerOn = 'click',
    trigger,
  } = props;

  const labelComponent = (
    <FormInputLabel
      className={cn('ColorPicker-label', labelPosition === 'top' ? 'mb-2' : 'mt-2')}
      label={label}
      tip={tip}
      required={required}
    />
  );

  if (inline) {
    return (
      <div className={cn('ColorPicker is-inline', className)}>
        {labelPosition === 'top' && labelComponent}

        <CompactPicker
          className="ColorPicker-component shadow-none"
          color={color}
          onChangeComplete={onChangeComplete}
        />

        {labelPosition === 'bottom' && labelComponent}
      </div>
    );
  }

  return (
    <Popup
      className={cn('ColorPicker', className)}
      size="small"
      on={triggerOn}
      trigger={
        <div className={cn('ColorPicker-trigger', className)}>
          {labelPosition === 'top' && labelComponent}

          {trigger ? (
            trigger
          ) : (
            <div
              className={cn('ColorPicker-swatch', { 'is-bordered': bordered })}
              style={{
                backgroundColor: color,
                width,
                height,
              }}
            />
          )}

          {labelPosition === 'bottom' && labelComponent}
        </div>
      }
      inverted={inverted}
      hoverable
      wide
    >
      <ChromePicker color={color} onChangeComplete={onChangeComplete} />
    </Popup>
  );
};

export default ColorPicker;
