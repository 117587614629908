import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { commonMimeTypes } from '@platform/utils/http';

import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import FormToggle from '../FormToggle';
import MarkdownEditor from '../MarkdownEditor';
import EntityEditorGroup from '../EntityEditorGroup';
import EditorContentSection from '../EditorContentSection';
import FormInputLabel from '../FormInputLabel';

import SwaggerMethodPath from '../SwaggerMethodPath';
import SwaggerSecurity from '../SwaggerSecurity';
import SwaggerResponses from '../SwaggerResponses';
import SwaggerParameters from '../SwaggerParameters';
import { SwaggerExtensions } from '../SwaggerExtensions';

const SwaggerOperation = props => {
  const { id, value, dereffedValue, parsed, onChange, editorId, updateParsed, currentPath } = props;

  const globalConsumes = _.get(parsed, 'consumes', []);
  const globalProduces = _.get(parsed, 'produces', []);

  return (
    <div className="SwaggerOperation">
      <EditorContentSection>
        <SwaggerMethodPath id={id} editorId={editorId} />
      </EditorContentSection>

      <EditorContentSection id="SwaggerOperation:basics" title="Basics">
        <div className="flex mb-4">
          <FormInput
            className="w-1/2"
            label="summary"
            tip="The name that will be displayed in the sidebar."
            value={_.get(value, 'summary')}
            onChange={e => {
              onChange('set', ['summary'], e.target.value);
            }}
            fluid
            placeholder="A short summary of what the operation does."
          />

          <FormToggle
            className="pl-4"
            checked={_.get(value, 'deprecated', false)}
            label="deprecated"
            tip="Declares this operation as deprecated and a notice will be displayed in the published documentation."
            onChange={v => {
              if (v) {
                onChange('set', ['deprecated'], true);
              } else {
                onChange('unset', ['deprecated']);
              }
            }}
          />

          <FormToggle
            className="pl-4"
            checked={_.get(value, 'x-private', false)}
            label="private"
            tip="Private operations won't be displayed in the published documentation."
            onChange={v => {
              if (v) {
                onChange('set', ['x-private'], true);
              } else {
                onChange('unset', ['x-private']);
              }
            }}
          />
        </div>

        <div>
          <FormInputLabel label="description" />

          <MarkdownEditor
            id={id}
            value={_.get(value, 'description')}
            placeholder="Optional description of operation..."
            onChange={val => {
              onChange('set', ['description'], val);
            }}
            tip="A verbose explanation of the operation behavior. Markdown syntax can be used for rich text representation."
            size="medium"
          />
        </div>

        <div className="flex mt-4">
          <FormInput
            className="flex-1 pr-2"
            value={_.get(value, 'operationId')}
            onChange={e => {
              onChange('set', ['operationId'], e.target.value);
            }}
            fluid
            required
            label="operation ID"
            tip="Unique string used to identify the operation. The id MUST
              be unique among all operations described in the API. Tools and
              libraries MAY use the operationId to uniquely identify an operation,
              therefore, it is recommended to follow common programming naming conventions."
          />

          <FormSelect
            className="flex-1 pl-2"
            value={_.get(value, 'tags', [])}
            onChange={operationTags => {
              // make sure all of these tags are in the global tags

              const swaggerTags = _.cloneDeep(_.get(parsed, 'tags', []));
              let hasAdded;

              _.forEach(operationTags, operationTag => {
                const existing = _.find(
                  swaggerTags,
                  swaggerTag => _.toLower(swaggerTag.name) === _.toLower(operationTag)
                );

                if (!existing) {
                  swaggerTags.push({ name: operationTag });
                  hasAdded = true;
                }
              });

              onChange('set', ['tags'], operationTags);

              if (hasAdded) {
                updateParsed('set', ['tags'], swaggerTags);
              }
            }}
            fluid
            label="tags"
            tip="A list of tags for API documentation control. Tags can be used for
              logical grouping of operations by resources or any other qualifier."
            options={_.uniqBy(
              _.map(_.get(parsed, 'tags', []), t => ({
                text: t.name,
                value: t.name,
              })).concat(_.map(_.get(value, 'tags', []), t => ({ text: t, value: t }))),
              t => t.text
            )}
            allowAdditions
            multiple
            search
          />
        </div>

        <div className="flex mt-4">
          <FormSelect
            className="flex-1 pr-2"
            value={_.get(value, 'consumes', [])}
            onChange={v => {
              onChange('set', ['consumes'], v);
            }}
            fluid
            label="consumes"
            tip="A list of MIME types the operation can consume. This overrides the global consumes.
              An empty value MAY be used to clear the global definition. To set an empty value, use the code editor pane."
            options={commonMimeTypes.map(c => ({ text: c, value: c }))}
            placeholder={
              _.isEmpty(globalConsumes)
                ? 'optional'
                : `inheriting global ${globalConsumes.join(', ')}`
            }
            allowAdditions
            multiple
            search
          />

          <FormSelect
            className="flex-1 pl-2"
            value={_.get(value, 'produces', [])}
            onChange={v => {
              onChange('set', ['produces'], v);
            }}
            fluid
            label="produces"
            tip="A list of MIME types the operation can produce. This overrides the global produces.
              An empty value MAY be used to clear the global definition. To set an empty value, use the code editor pane."
            options={commonMimeTypes.map(c => ({ text: c, value: c }))}
            placeholder={
              _.isEmpty(globalProduces)
                ? 'optional'
                : `inheriting global ${globalProduces.join(', ')}`
            }
            allowAdditions
            multiple
            search
          />
        </div>
      </EditorContentSection>

      <EditorContentSection id="SwaggerOperation:request" title="Request">
        <EntityEditorGroup
          id="swaggeroperation-security"
          name="Security"
          active={!_.isUndefined(_.get(value, 'security'))}
          padded
          contentFactory={() => (
            <SwaggerSecurity
              security={_.get(value, 'security')}
              securityDefinitions={_.get(parsed, 'securityDefinitions')}
              handleUpdate={(t, p, v) => {
                onChange(t, ['security'].concat(p), v);
              }}
            />
          )}
        />

        <div className="mt-6">
          <SwaggerParameters
            id={id}
            editorId={editorId}
            onChange={(t, p, v) => {
              onChange(t, ['parameters'].concat(p), v);
            }}
            value={_.get(value, 'parameters')}
            dereferencedValue={_.get(dereffedValue, 'parameters')}
            parsed={parsed}
          />
        </div>
      </EditorContentSection>

      <EditorContentSection id="SwaggerOperation:responses" title="Responses">
        <SwaggerResponses
          id={id}
          editorId={editorId}
          responses={_.get(value, 'responses')}
          dereffedResponses={_.get(dereffedValue, 'responses')}
          handleUpdate={(t, p, v) => {
            onChange(t, ['responses'].concat(p), v);
          }}
        />
      </EditorContentSection>

      <EditorContentSection id="SwaggerOperation:extensions" title="Extensions">
        <SwaggerExtensions
          id={id}
          value={value}
          onChange={(t, p, v) => {
            let value = v;

            // When moving, make sure to only move within the operation object since we're just renaming the x-key
            if (t === 'move') {
              value = currentPath.concat(value);
            }

            onChange(t, p, value);
          }}
        />
      </EditorContentSection>
    </div>
  );
};

export default inject(({ appStore }, { id }) => {
  return {
    ...appStore.injectUi(id),
  };
})(observer(SwaggerOperation));
