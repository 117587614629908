import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import shortid from '@platform/utils/shortid';

import RefBuilder from '../RefBuilder';

class RefBuilderList extends React.Component {
  static propTypes = {
    refs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    labelFactory: PropTypes.func,
    fileFilter: PropTypes.object,
    canAdd: PropTypes.bool,
    canRemove: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    props.updateUi('set', [props.id, 'ids'], this.buildCache(this.props));
  }

  componentWillReceiveProps = nextProps => {
    const { id, updateUi } = nextProps;
    if (this.props.id !== id) {
      updateUi('set', [id, 'ids'], this.buildCache(nextProps));
    }
  };

  buildCache = props => {
    const { id, refs = [], ui = {} } = props;
    const ids = _.get(ui, [id, 'ids'], []);

    for (const r in refs) {
      if (!ids[r]) {
        ids.push(shortid());
      }
    }

    return ids;
  };

  handleAdd = () => {
    const { id, onChange, ui = {}, updateUi } = this.props;
    const ids = _.get(ui, [id, 'ids'], []);
    ids.push(shortid());
    updateUi('set', [id, 'ids'], ids);
    onChange('push', [], '');
  };

  handleRemove = index => {
    const { id, onChange, ui = {}, updateUi } = this.props;
    const ids = _.get(ui, [id, 'ids'], []);
    _.pullAt(ids, index);
    updateUi('set', [id, 'ids'], ids);
    onChange('pull', [], index);
  };

  render() {
    const {
      id,
      className,
      refs = [],
      label,
      fileFilter,
      targets,
      onChange,
      canAdd,
      canRemove,
      size,
      bordered,
      ui = {},
      addButtonProps = {},
      context = false,
    } = this.props;

    let count = -1;
    const ids = _.get(ui, [id, 'ids'], []);

    return (
      <div className={cn('RefBuilderList', className, { 'is-bordered': bordered })}>
        {canAdd || label ? (
          <div className="flex">
            {canAdd ? (
              <Button
                className="RefBuilderList-add mr-2"
                content="Add"
                icon="plus"
                onClick={this.handleAdd}
                primary
                basic
                {...addButtonProps}
              />
            ) : null}

            {label ? label : null}
          </div>
        ) : null}

        {_.map(refs, ($ref, index) => {
          // can't trust index to be numeric, since sometimes refs can be an key[val] object
          count += 1;

          return (
            <div key={index} className="RefBuilderList-row flex mt-3">
              <RefBuilder
                id={`${id}:${ids[count]}`}
                className="flex-1"
                $ref={$ref}
                size={size}
                fileFilter={fileFilter}
                targets={targets}
                onComplete={value => onChange('set', index, value)}
                onRemove={canRemove ? () => this.handleRemove(index) : null}
                context={context}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default inject(({ appStore }, { id }) => {
  return {
    ...appStore.injectUi(`${id}:RefBuilderList`),
  };
})(observer(RefBuilderList));
