import React from 'react';
import { inject, observer } from 'mobx-react';

import PageContent from '../../components/PageContent';
import TeamList from '../../components/TeamList';

class OrgTeams extends React.Component {
  findItems = ({ search, page } = {}) => {
    const { routerStore, teamService, org = {} } = this.props;
    const query = {};

    let skipPagination = false;

    if (search) {
      query.search = search;
      skipPagination = true;
    } else {
      query.page = page || teamService.getCurrentPage() + 1;
    }

    teamService.find(
      { orgId: org.id },
      {
        query,
        skipPagination,
      }
    );
  };

  componentWillMount() {
    this.findItems({ page: 1 });
  }

  handleRemove = id => {
    const { teamService, query = {} } = this.props;

    return teamService.remove(id, query);
  };

  render() {
    const { org = {}, teamService } = this.props;

    return (
      <PageContent>
        <div className="flex-grow">
          <TeamList
            entity={org}
            entityType="org"
            teams={teamService.currentList}
            handleRemove={this.handleRemove}
            loadMore={this.findItems}
            service={teamService}
            loading={teamService.isLoading}
            onSearch={searchQuery => {
              this.findItems({ search: searchQuery });
            }}
          />
        </div>
      </PageContent>
    );
  }
}

export default inject((stores, props) => {
  const { orgService, teamService } = stores;

  return {
    teamService,
    org: orgService.current,
  };
})(observer(OrgTeams));
