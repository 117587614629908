import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Message } from 'semantic-ui-react';

import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';

import FormSearch from '@platform/components/FormSearch';
import FormInput from '@platform/components/FormInput';
import SimpleSection from '@platform/components/SimpleSection';

import Banner from './banner';

const FileSelect = props => {
  const { publishStore, currentProjectStore, releases } = props;

  const currentFilePath = publishStore.filePath;
  let component;
  let isDirtyMessage;
  let releasesMessage;

  if (publishStore.canPublish) {
    // Check if selected file is dirty
    const file = _.find(currentProjectStore.files, { path: currentFilePath }) || {};

    if (file.isDirty) {
      isDirtyMessage = (
        <Banner className="mt-2">{currentFilePath} currently has unsaved changes.</Banner>
      );
    }

    if (releases.length === 0) {
      releasesMessage = `You do not have any released versions. Publishing will automatically set v${
        publishStore.latestRelease.name
      } to "released".`;
    } else {
      releasesMessage = (
        <div>
          <div>The following released versions will be published:</div>
          <ul>
            {releases.map((release, index) => (
              <li key={index}>{release.branch.replace('/', ' ')}</li>
            ))}
          </ul>
        </div>
      );
    }

    const hubFiles = _.map(publishStore.files.documentation, file => {
      return {
        key: file.path,
        title: file.name,
        value: file.path,
      };
    });

    const oasFiles = _.map(publishStore.files.modeling, file => {
      return {
        key: file.path,
        title: file.name,
        value: file.path,
      };
    });

    const resultsObj = {};

    if (hubFiles.length) {
      resultsObj.documentation = {
        name: 'Hub Files',
        results: hubFiles,
      };
    }

    if (oasFiles.length) {
      resultsObj.modeling = {
        name: 'OAS Files',
        results: oasFiles,
      };
    }

    component = (
      <FormSearch
        className="flex-1"
        placeholder="Select a file to publish"
        icon="caret down"
        value={currentFilePath}
        results={resultsObj}
        onSearchChange={(e, value) => {
          publishStore.setValue('filePath', value);
        }}
        onResultSelect={(e, { value }) => {
          publishStore.setValue('filePath', value);
        }}
        showResultsOnClick
        filterResults
        required
        fluid
      />
    );
  } else {
    component = <FormInput className="flex-1" readOnly fluid value={currentFilePath} />;
  }

  return (
    <SimpleSection className="PublishViewer-file" title="Hub File" icon={faBook}>
      <div className="flex items-center">{component}</div>

      {isDirtyMessage}

      {releasesMessage && (
        <Message className="mt-2" size="mini" info style={{ maxHeight: '150px', overflow: 'auto' }}>
          {releasesMessage}
        </Message>
      )}
    </SimpleSection>
  );
};

export default inject((stores, props) => {
  const { projectStore, publishStore, releaseService } = stores;

  return {
    publishStore: publishStore.current,
    releases: releaseService.currentList || [],
    currentProjectStore: projectStore.current || {},
  };
})(observer(FileSelect));
