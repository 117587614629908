import React from 'react';

import { faBug } from '@fortawesome/pro-solid-svg-icons/faBug';

import SimpleSection from '@platform/components/SimpleSection';
import { getConfigVar } from '@platform/utils/config';
import { getProtocol, isCustomDomain } from '@platform/utils/publishing';

const Summary = props => {
  const { domain } = props;

  if (!isCustomDomain(domain)) return null;

  const ingressHost = getConfigVar('SL_PUBS_INGRESS');

  return (
    <SimpleSection
      className="PublishViewer-issues mt-12"
      title="Having issues loading your docs?"
      icon={faBug}
    >
      <div className="text-sm">
        Make sure there is a DNS CNAME record pointing from{' '}
        <a href={`${getProtocol()}://${domain}`} target="_blank" rel="noopener noreferrer">
          {domain}
        </a>{' '}
        to{' '}
        <a href={ingressHost} target="_blank" rel="noopener noreferrer">
          {ingressHost}
        </a>
        .
      </div>
    </SimpleSection>
  );
};

export default Summary;
