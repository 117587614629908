import produce from 'immer';

import * as Hub from '../types';

// ensure a single leading slash
const cleanPath = (path: string) => {
  path = (path || '').trim();

  if (path === '/') {
    return path;
  }

  if (path[0] !== '/') {
    path = `/${path}`;
  }

  return path;
};

const producer = (child: Hub.ITreeChild) => {
  const data = child.data || {};

  return produce(data, draft => {
    if (child.children && child.children.length) {
      draft.children = transformChildren(child.children);
    } else {
      delete draft.children;
    }
  }) as Hub.IData;
};

const transformChild = (child: Hub.ITreeChild): Hub.ISubpage => {
  const subpage: Hub.ISubpage = {
    title: child.title,
    config: child.config,
    route: {
      path: child.viewPath ? cleanPath(child.viewPath[child.viewPath.length - 1]) : '/',
    },
  };

  subpage.data = producer(child);

  return subpage;
};

const transformChildren = (children: Hub.ITreeChild[]): Hub.ISubpage[] => {
  const subpages: Hub.ISubpage[] = [];

  for (const i in children) {
    if (!children[i]) continue;

    const child = children[i];
    subpages.push(transformChild(child));
  }

  return subpages;
};

export const fromTree = (root: Hub.ITree): Hub.IRoot => {
  const children = root.tree;

  // TODO: should we just go back and forth between the entire hub spec?
  const hub: Hub.IRoot = {
    pages: {},
  };

  for (const i in children) {
    if (!children[i]) continue;

    const child = children[i];

    const page: Hub.IPage = {
      title: child.title,
      config: child.config,
    };

    page.data = producer(child);

    const path = cleanPath(child.viewPath[child.viewPath.length - 1] || '');

    hub.pages[path] = page;
  }

  return hub;
};
