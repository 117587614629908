import _sortBy from 'lodash/sortBy';
import { inject, observer } from 'mobx-react';
import React from 'react';
import axios from 'axios';

import { alert } from '@platform/utils/alert';
import { accessLevels } from '@platform/utils/acl';
import { getExperimentalFeatures } from '@platform/utils/config';

import FormInput from '@platform/components/FormInput';
import FormDropdown from '@platform/components/FormDropdown';
import FormInputLabel from '@platform/components/FormInputLabel';

import OrgSettingsLayout from './_layout';
import PageContent from '@platform/components/PageContent';

const valueOptions = [
  {
    text: 'Disabled',
    value: 'disabled',
  },
  {
    text: 'Everyone',
    value: true,
  },
  {
    text: 'Owners',
    value: accessLevels.OWNER,
  },
  {
    text: 'Admins',
    value: accessLevels.ADMIN,
  },
  {
    text: 'Contributors',
    value: accessLevels.REPORTER,
  },
  {
    text: 'Readers',
    value: accessLevels.GUEST,
  },
];

function toDatetimeLocal(_date) {
  const date = new Date(_date);

  const ten = function(i) {
    return (i < 10 ? '0' : '') + i;
  };

  const YYYY = date.getFullYear();
  const MM = ten(date.getMonth() + 1);
  const DD = ten(date.getDate());
  const HH = ten(date.getHours());
  const II = ten(date.getMinutes());
  const SS = ten(date.getSeconds());

  return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':' + SS;
}

class OrgAdminPage extends React.Component {
  constructor(props) {
    super(props);

    const { feature_flags = {}, subscription = {} } = props.orgService.current || {};

    this.state = {
      featureFlags: feature_flags,
      trialUntil: subscription.trial_until ? toDatetimeLocal(subscription.trial_until) : undefined,
    };
  }

  handleUpdate = async (key, value) => {
    const { orgAttributeService, orgService } = this.props;

    try {
      if (value) {
        await orgAttributeService.update(key, { value }, { orgId: orgService.current.id });
      } else {
        await orgAttributeService.remove(key, { orgId: orgService.current.id });
      }

      alert.success(`Successfully updated ${key}!`);
    } catch (error) {
      console.log(error);
      alert.error(`Error updating ${key}. Check the developer console for more information.`);
    }
  };

  render() {
    const { userService, orgService } = this.props;
    const { featureFlags, trialUntil } = this.state;

    const { authorizedUser } = userService;

    // Don't allow non-admin to view this page
    if (!authorizedUser || !authorizedUser.is_admin || !orgService.current) {
      return (
        <PageContent>
          <div className="flex items-center flex-1 h-10 text-white pl-4 bg-negative">
            Your account does not have the proper permissions to access this page.
          </div>
        </PageContent>
      );
    }

    const experimentalFeatures = _sortBy(getExperimentalFeatures());

    return (
      <OrgSettingsLayout activeTab="admin" className="AdminSettings">
        <FormInput
          label="Trial until"
          type="datetime-local"
          value={trialUntil}
          tip="To remove a trial, just set it to any date in the past."
          onChange={(e, { value }) => {
            this.setState({ trialUntil: value });
          }}
          onBlur={() => {
            this.handleUpdate('trial_until', trialUntil);
          }}
        />

        <div className="FormInputList max-w-md mt-8">
          <FormInputLabel label="Feature Flags" />
          {experimentalFeatures.length ? (
            _sortBy(experimentalFeatures).map(feature => {
              return (
                <div className="flex items-center my-3" key={feature}>
                  <FormInput className="mr-3" value={feature} />

                  <FormDropdown
                    fluid
                    selection
                    className="flex-1"
                    options={valueOptions}
                    value={featureFlags[feature] || 'disabled'}
                    onChange={(e, { value }) => {
                      if (value === featureFlags[feature]) return;

                      this.setState({ featureFlags: { ...featureFlags, [feature]: value } });
                      this.handleUpdate(`feature:${feature}`, value === 'disabled' ? false : value);
                    }}
                  />
                </div>
              );
            })
          ) : (
            <div>No Experimental Features disabled in Stoplight Configuration</div>
          )}
        </div>
      </OrgSettingsLayout>
    );
  }
}

export default inject('orgService', 'userService', 'orgAttributeService')(observer(OrgAdminPage));
