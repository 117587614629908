import * as React from 'react';
import { inject, observer } from 'mobx-react';

const setServiceMethods = (props, currentServiceMethods) => {
  const { id, methods, service } = props;

  if (!service) return currentServiceMethods;

  let serviceMethods = {};
  for (const method in methods) {
    if (!methods.hasOwnProperty(method) || !service[method]) continue;

    // create the method func
    serviceMethods[method] = service[method]({ id, ...methods[method] });

    // did the func change (variables changed, pollInterval changed, etc)
    if (currentServiceMethods[method] !== serviceMethods[method]) {
      // if the old func has an unsubscribe method, call it
      if (currentServiceMethods[method] && currentServiceMethods[method].unsubscribe) {
        currentServiceMethods[method].unsubscribe();
      }

      // subscribe to the new func
      if (serviceMethods[method].subscribe) {
        serviceMethods[method].subscribe();
      }
    }
  }

  // unsubscribe to any old/dangling funcs
  return unsetServiceMethods(serviceMethods, Object.keys(methods));
};

const unsetServiceMethods = (currentServiceMethods, skipMethods = []) => {
  if (!currentServiceMethods) return currentServiceMethods;

  for (const method in currentServiceMethods) {
    if (!currentServiceMethods.hasOwnProperty(method) || skipMethods.indexOf(method) >= 0) continue;
    const methodProps = currentServiceMethods[method];
    if (methodProps.unsubscribe) {
      methodProps.unsubscribe();
    }
    delete currentServiceMethods[method];
  }

  return currentServiceMethods;
};

@inject((stores, { service }) => {
  return { service: stores[service] };
})
@observer
class GqlContainer extends React.Component {
  state = {
    serviceMethods: {},
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    const serviceMethods = setServiceMethods(nextProps, nextState.serviceMethods);
    return {
      serviceMethods,
    };
  }

  componentWillUnmount() {
    unsetServiceMethods(this.state.serviceMethods);
  }

  render() {
    return this.props.renderProp(this.state.serviceMethods);
  }
}

export default GqlContainer;
