import { IDisposable } from '@core/disposable';
import { Event } from '@core/emitter';

/**
 * Unique symbol identifiers, used for IOC.
 */
export const SYMBOLS = {
  CommandRegistry: 'CommandRegistry',
};

export interface ICommandState {
  enabled?: boolean;
  executing?: boolean;
  visible?: boolean;
  toggled?: boolean;
}

/**
 * A command identifies a function. Commands can be can be executed
 * via a keyboard shortcut, a menu action, or directly.
 */
export interface ICommandObj extends ICommandState {
  /**
   * A unique identifier of this command.
   */
  id: string;

  label?: string;
  iconClass?: string;
  shortcut?: string;
}

export interface ICommand extends ICommandObj {
  onDidUpdate: Event<ICommand>;
  update(change: ICommandState): void;
}

/**
 * A command handler is an implementation of a command.
 *
 * A command can have multiple handlers, but they should be active in different contexts.
 * Otherwise the first active command will be executed.
 */
export interface ICommandHandler {
  execute(...args: any[]): any;
  isExecuting?(...args: any[]): boolean;
  isActive?(...args: any[]): boolean;
  isVisible?(...args: any[]): boolean;
}

/**
 * The command service should be used to execute commands.
 */
export interface ICommandService {
  /**
   * Execute the active handler for the given command and arguments.
   *
   * Reject if a command cannot be executed.
   */
  executeCommand<T>(command: string, ...args: any[]): Promise<T | undefined>;
}

export interface IShortcutOptions {
  commandId?: string;
  execute?: (event: any) => Promise<any> | void;
}

export interface ICommandRegistry extends ICommandService {
  registerCommand(command: ICommandObj, handler?: ICommandHandler): IDisposable;
  registerHandler(commandId: string, handler: ICommandHandler): IDisposable;
  registerShortcut(shortcut: string, options: IShortcutOptions): IDisposable;
  isActive(commandId: string, ...args: any[]): boolean;
  isVisible(commandId: string, ...args: any[]): boolean;
  getCommand(commandId: string): ICommand | undefined;
  refreshCommandState(commandId: string, ...args: any[]): void;
}
