import React from 'react';

import PageContent from '../../components/PageContent';
import ProjectCreate from '../../components/ProjectCreate';

const NewOrgProjectPage = props => {
  return (
    <PageContent>
      <div className="w-100 mx-auto my-12 rounded-lg shadow" style={{ maxWidth: 800 }}>
        <ProjectCreate />
      </div>
    </PageContent>
  );
};

export default NewOrgProjectPage;
